import { legacy_createStore as createStore, combineReducers } from 'redux';
import { uxReducer } from './reducers';
import StateLoader from './state.loader';

const rootReducer = combineReducers({
  ux: uxReducer,
});

const stateLoader = new StateLoader();
let store = createStore(
  rootReducer,
  stateLoader.loadState(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
// let store = createStore(rootReducer, composeWithDevTools()); // <- old
store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

export default store;
