import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import App from './frontend/components/app';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import reduxStore from './frontend/components/redux/redux-store';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();

let uri;
if (process.env.NODE_ENV === 'production') {
  uri = 'https://testing.media:3001/graphql';
} else if (process.env.NODE_ENV === 'development') {
  uri = 'http://localhost:3001/graphql';
}

const link = new HttpLink({
  uri: uri,
});

// const link = new HttpLink({
//   uri: 'https://testing.media:3001/graphql',
// });

const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    mutate: {
      // can do network-only as well but no-cache may be more secure...
      fetchPolicy: 'no-cache',
    },
  },
});
const rootEl = document.getElementById('app');

render(
  <Router>
    <ApolloProvider client={client}>
      <Provider store={reduxStore}>
        <App />
      </Provider>
    </ApolloProvider>
  </Router>,
  rootEl,
);

export default App;
