import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Modal, Form, Button, Transition } from 'semantic-ui-react';
import withSnackbar from '../hoc/withSnackbar';
// import styles from './TrendModal.module.css';
const options = [
  { key: 'm', text: 'Male', value: 'male' },
  { key: 'f', text: 'Female', value: 'female' },
  { key: 'o', text: 'Other', value: 'other' },
];

class DeleteModal extends Component {
  static propTypes = {
    dataset: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.string,
      dateAdded: PropTypes.string,
      modifiedAt: PropTypes.string,
      rsvp: PropTypes.arrayOf(PropTypes.string),
    }),
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    snackbar: PropTypes.func.isRequired,
  };

  state = {};

  onDelete = async () => {
    await this.props.onDelete();
    await this.props.toggle();
  };

  render() {
    return (
      <div>
        <Transition.Group animation="fly down" duration={350}>
          {this.props.open && (
            <Modal
              style={{
                height: '100vh',
                width: '100vw',
                padding: '150px',
                backgroundColor: 'rgba(15, 15, 15, 0.95)',
              }}
              size="mini"
              dimmer="inverted"
              open={this.props.open}
              onClose={this.props.toggle}
            >
              <Modal.Header style={{ color: 'red' }}>Delete This Dataset?</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description style={{ textAlign: 'center' }}>
                  <h1 style={{ color: 'red' }}>DELETING: "{this.props.dataset.name}"</h1>

                  <p>
                    <b>Details: </b>
                    <br />
                    {this.props.dataset.details}
                  </p>
                  <hr />
                  <p>
                    <b>Date Added: </b>
                    <br />
                    {this.props.dataset.dateAdded}
                  </p>
                  <hr />
                  <p>
                    <b>Last Modified: </b>
                    <br />
                    {this.props.dataset.modifiedAt}
                  </p>
                  <hr />

                  <p>Tracked Outlets: </p>
                  {this.props.dataset.trackedOutlets &&
                    this.props.dataset.trackedOutlets.map((o, index) => <p key={index}>{o}</p>)}

                  <hr />
                  <Button
                    inverted
                    color="red"
                    content={'DELETE ' + this.props.dataset.name}
                    onClick={e => this.onDelete()}
                  />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button content="Nevermind" onClick={() => this.props.toggle()} />
              </Modal.Actions>
            </Modal>
          )}
        </Transition.Group>
      </div>
    );
  }
}

export default compose(withSnackbar)(DeleteModal);
