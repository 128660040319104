import { connect } from 'react-redux';
import { openSnackbar } from '../redux/actions';
import errorMap from '../uxElements/errors';

//redux stuff:
export default connect(
  //mapStateToProps
  null,
  //mapDispatchToProps"
  { snackbar: openSnackbar },
  //mergeProps:
  (stateProps, dispatchProps, ownProps) => {
    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,

      snackbar: (errorCode, severity, errorArgs) => {
        let error = null;
        if (errorCode instanceof Error) {
          error = errorCode;
          errorCode = errorCode.message;
        }
        const userMessage = errorMap[errorCode];

        if (!userMessage) {
          console.error(
            'There has been an unexpected error unhandled by the regular message handler',
            errorCode,
          );
          if (error) {
            console.error('ERROR', error);
          }
          dispatchProps.snackbar('unhandled-error');
        }
        dispatchProps.snackbar(errorCode, severity, errorArgs);
      },
    };
  },
);
