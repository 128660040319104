import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import STATS_BY_TREND_ID from '../graphql/queries/statsByTrendId.graphql';
import { connect } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import { Modal, Icon, Button, Transition, Table } from 'semantic-ui-react';
import classes from './TrendModal.module.css';

class TrendModal extends Component {
  static propTypes = {
    trend: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.string,
      date: PropTypes.string,
      rsvp: PropTypes.arrayOf(PropTypes.string),
    }),
    toggle: PropTypes.func,
    open: PropTypes.bool,
  };

  state = {
    singleTrendDoughnutValue: null,
    chartOptions: null,
  };

  componentDidMount() {
    this.props.trend && this.setDoughutData();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.trend !== this.props.trend) {
      // console.log('CHANGED -----------------------------------------------------------------');
      await this.props.refetchStats();
      this.setDoughutData();
    }
  };

  shouldComponentUpdate = async (prevProps, prevState) => {
    if (prevProps.trend !== this.props.trend) {
      return true;
    }
  };

  setDoughutData = () => {
    // const trends = this.props.trendsByDatasetId.filter(i => {
    //   if (this.state.singleTrendDoughnutValue.includes(i.id)) {
    //     return i;
    //   }
    // });
    // // console.log('trends selected dropdown', trends);
    const trend = this.props.trend;
    let doughnutData = {
      labels: ['Negative', 'Positive', 'Neutral'],
      datasets: [],
    };
    // for (const trend of trends) {
    const total =
      trend.averageScoreTf?.deviation.negative + trend.averageScoreTf?.deviation.positive;
    let neutral = 0;
    if (total <= 100) {
      neutral = 100 - total;
    } else {
      neutral = 0;
    }
    doughnutData.datasets.push({
      label: trend.name,
      borderWidth: 2,
      data: [
        trend.averageScoreTf?.deviation.negative,
        trend.averageScoreTf?.deviation.positive,
        trend.averageScoreTf?.deviation.neutral,
      ],
      backgroundColor: ['rgba(251, 5, 46, 0.8)', 'rgba(5, 251, 72, 0.8)', 'rgba(18, 92, 226, 0.8)'],
      hoverBorderColor: 'rgba(100, 100, 100, 0.8)',
    });
    // }

    const chartOptions = {
      title: {
        display: true,
        text: 'Per-Trend Sentiment Visualization',
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            title: ttItem => ttItem[0].dataset.label,
          },
        },
      },
    };

    this.setState({
      doughnutData: doughnutData,
      chartOptions: chartOptions,
    });
  };

  timeConverter = UNIX_timestamp => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  };

  returnSentimentItems = () => {
    return this.props.stats[0].sentiment.map((i, index) => (
      <Table.Row key={index}>
        <Table.Cell>{JSON.stringify(this.timeConverter(i.date / 1000))}</Table.Cell>
        <Table.Cell>
          Score: {i.resultTf?.score.toFixed(3)}
          <hr />
          Deviation:
          {i.resultTf?.deviation === 'positive' ? (
            <span style={{ color: '#71eb34' }}>{i.resultTf?.deviation}</span>
          ) : (
            <span style={{ color: '#f22e42' }}>{i.resultTf?.deviation}</span>
          )}
        </Table.Cell>
        <Table.Cell>
          Score: {i.resultStandardDict?.score}
          <hr />
          Deviation: {i.resultStandardDict?.comparative.toFixed(3)}
        </Table.Cell>
        <Table.Cell>{i.resultStandardDict?.positive.map(i => i + ', ')}</Table.Cell>
        <Table.Cell>{i.resultStandardDict?.negative.map(i => i + ', ')}</Table.Cell>
      </Table.Row>
    ));
  };

  render() {
    console.log('trend modal', this.props);
    // console.log('trend modal state', this.state);
    return (
      <div>
        <Transition.Group animation={'zoom'} duration={250}>
          {this.props.open && (
            <Modal
              basic
              size="small"
              dimmer="blurring"
              open={this.props.open}
              onClose={this.props.toggle}
              style={{
                height: 'auto',
                width: '50vw',
                // padding: '50px',
                backgroundColor: 'rgba(15, 15, 15, 0.95)',
              }}
            >
              <Modal.Header size="large">{this.props.trend.name}</Modal.Header>
              {this.state.doughnutData && (
                <div>
                  <h4>Average Sentiment</h4>
                  <Doughnut
                    style={{ maxHeight: '325px' }}
                    width={1}
                    height={1}
                    options={this.state.chartOptions}
                    data={this.state.doughnutData}
                  />
                </div>
              )}

              <Modal.Content scrolling style={{ marginBottom: '10px', textAlign: 'center' }}>
                <Modal.Description style={{ textAlign: 'center' }}>
                  <br />

                  {/* ADD GOOGLE SEARCH METRICS HERE */}

                  <Table style={{ marginBottom: '50px' }} compact="very">
                    {!this.props.loadingStats && (
                      <div className={classes.container_table}>
                        <Table inverted compact="very" celled>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Date</Table.HeaderCell>
                              <Table.HeaderCell>ML Sentiment Value</Table.HeaderCell>
                              <Table.HeaderCell>Dictionary Sentiment Value</Table.HeaderCell>
                              <Table.HeaderCell>Positive Keys</Table.HeaderCell>
                              <Table.HeaderCell>Negative Keys</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          {!this.props.loadingStats && this.props.stats[0] && (
                            <Table.Body>{this.returnSentimentItems()}</Table.Body>
                          )}
                        </Table>
                      </div>
                    )}
                  </Table>

                  <h3>
                    <b>Details:</b>
                  </h3>
                  <hr />
                  <p style={{ margin: '0', padding: '0' }}>
                    <b>Google Search Volume: </b>
                    {this.props.trend.details}
                  </p>
                  <hr />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon="checkmark"
                  labelPosition="right"
                  content="Done"
                  onClick={e => this.props.toggle(this.props.trend)}
                />
              </Modal.Actions>
            </Modal>
          )}
        </Transition.Group>
      </div>
    );
  }
}

export default compose(
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add action functions from redux here
    }),
  ),
  graphql(STATS_BY_TREND_ID, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          trendId: ownProps.trend && ownProps.trend.id,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, statsByTrendId = [] } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        stats: statsByTrendId,
        refetchStats: refetch,
        loadingStats: loading,
      };
      return result;
    },
  }),
)(TrendModal);
