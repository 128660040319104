// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IIg5-qekjErfaN5TgZZxww\\=\\= {\n  max-height: 480px;\n  overflow-y: scroll; /* or auto */\n}\n", "",{"version":3,"sources":["webpack://src/frontend/components/modal/TrendModal.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB,EAAE,YAAY;AAClC","sourcesContent":[".container_table {\n  max-height: 480px;\n  overflow-y: scroll; /* or auto */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_table": "IIg5-qekjErfaN5TgZZxww=="
};
export default ___CSS_LOADER_EXPORT___;
