import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Modal, Form, Button, Transition } from 'semantic-ui-react';
import withSnackbar from '../hoc/withSnackbar';
// import styles from './TrendModal.module.css';
const options = [
  { key: 'm', text: 'Male', value: 'male' },
  { key: 'f', text: 'Female', value: 'female' },
  { key: 'o', text: 'Other', value: 'other' },
];

class DeleteMultiModal extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        details: PropTypes.string,
      }),
    ),
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    snackbar: PropTypes.func.isRequired,
  };

  state = {};

  onDelete = async () => {
    await this.props.onDelete();
    await this.props.toggle();
  };

  render() {
    return (
      <div>
        <Transition.Group animation="fly down" duration={350}>
          {this.props.open && (
            <Modal
              style={{
                height: '100vh',
                width: '100vw',
                padding: '150px',
                backgroundColor: 'rgba(15, 15, 15, 0.95)',
              }}
              size="mini"
              dimmer="inverted"
              open={this.props.open}
              onClose={this.props.toggle}
            >
              <Modal.Header style={{ color: 'red' }}>Delete Multiple Items?</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description style={{ textAlign: 'center' }}>
                  {this.props.items &&
                    this.props.items.map((item, index) => (
                      <div key={index}>
                        <h1 style={{ color: 'red' }}>DELETING: "{item.name}"</h1>
                        <p>
                          <b>Details: </b>
                          <br />
                          {item.details}
                        </p>
                        <hr />
                      </div>
                    ))}

                  <Button
                    inverted
                    color="red"
                    content={'PERMANENTLY DELETE ITEMS'}
                    onClick={() => this.onDelete()}
                  />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button content="Nevermind" onClick={() => this.props.toggle()} />
              </Modal.Actions>
            </Modal>
          )}
        </Transition.Group>
      </div>
    );
  }
}

export default compose(withSnackbar)(DeleteMultiModal);
