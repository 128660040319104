import GET_DATASETS_BY_USER_ID_QUERY from '../graphql/queries/getDatasetsByUserId.graphql';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';

export default compose(
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add action functions from redux here
    }),
  ),
  // Query datasets by userId
  graphql(GET_DATASETS_BY_USER_ID_QUERY, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: ownProps.currentUser,
        },
      };
    },
    skip: ownProps => {
      if (!ownProps.isLoggedIn) {
        return true;
      }
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, datasetsByUserId = [] } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        datasets: (datasetsByUserId || []).map(dataset => ({
          ...dataset,
        })),
        refetchDatasets: refetch,
        loadingDatasets: loading,
      };
      return result;
    },
  }),
);
