import AllRsvpTrends from './AllRsvpTrends';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
  selectTrend as selectTrendAction,
  multiSelectTrend as multiSelectTrendAction,
  shiftSelectTrend as shiftSelectTrendAction,
  deselectTrends as deselectTrendsAction,
  deselectDatasets as deselectDatasetsAction,
  setRefetchTrendsTrue as setRefetchTrendsTrueAction,
  cancelRefetchTrends as cancelRefetchTrendsAction,
} from '../redux/actions';
import STOP_TRACKING_MUTATION from '../graphql/mutations/stopTrackingTrend.graphql';
import DELETE_TREND_MUTATION from '../graphql/mutations/deleteTrend.graphql';
import RSVP_MUTATION from '../graphql/mutations/rsvp.graphql';
import RSVP_TRENDS_QUERY from '../graphql/queries/rsvpTrendsByUserId.graphql';
import RSVP_TRENDS_COUNT_NEXT_TWO_PAGES_QUERY from '../graphql/queries/rsvpTrendCountInNextTwoPages.graphql';
import withSnackbar from '../hoc/withSnackbar';
import platform from 'platform';

export default compose(
  withSnackbar,
  graphql(RSVP_TRENDS_QUERY, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: ownProps.currentUser,
          page: ownProps.page - 1 || 0,
          limit: 15,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, rsvpTrendsByUserId = [] } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        rsvpTrends: rsvpTrendsByUserId,
        refetchRSVPTrends: refetch,
        loadingRsvpTrends: loading,
      };
      return result;
    },
  }),

  graphql(RSVP_TRENDS_COUNT_NEXT_TWO_PAGES_QUERY, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: ownProps.currentUser,
          page: ownProps.page - 1 || 0,
          limit: 15,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, rsvpTrendCountInNextTwoPages = 0 } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        rsvpTrendCountInNextTwoPages: rsvpTrendCountInNextTwoPages,
        refetchRsvpTrendCountInNextTwoPages: refetch,
      };
      return result;
    },
  }),
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isTrendSelected: trendId =>
        state.ux.selectedTrends.find(trend => trend.id === trendId) !== undefined,
      selectedTrendIds: state.ux.selectedTrends.map(trend => trend.id),
      setRefetchTrends: state.ux.setRefetchTrends,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
        selectTrend: trend => dispatch(selectTrendAction(trend)),
        multiSelectTrend: trendId => dispatch(multiSelectTrendAction(trendId)),
        shiftSelectTrend: trendIds => dispatch(shiftSelectTrendAction(trendIds)),
        deselectTrends: () => dispatch(deselectTrendsAction()),
        deselectDatasets: () => dispatch(deselectDatasetsAction()),
        setRefetchTrendsTrue: () => dispatch(setRefetchTrendsTrueAction()),
        cancelRefetchTrends: () => dispatch(cancelRefetchTrendsAction()),
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const {
        selectTrend,
        multiSelectTrend,
        shiftSelectTrend,
        deselectDatasets,
        ...restDispatchProps
      } = dispatchProps;

      ownProps.rsvpTrends &&
        ownProps.rsvpTrends.map(trend => {
          trend.isSelected = stateProps.isTrendSelected(trend.id);
          return trend;
        });

      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
        handleTrendItemClick: (trend, event) => {
          if (platform.os.family === 'OS X' && event.metaKey) {
            // command key for macOS
            multiSelectTrend(trend);
          } else if (event.ctrlKey) {
            // windows
            multiSelectTrend(trend);
          } else {
            selectTrend(trend);
          }
        },
        handleShiftClick: ids => {
          shiftSelectTrend(ids);
        },
        deselectDatasets: () => deselectDatasets(),
      };
    },
  ),

  // Mutation
  graphql(RSVP_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        rsvp: async (userId, trendId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(STOP_TRACKING_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        stopTracking: async (userId, trendId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(DELETE_TREND_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        deleteTrend: async (userId, trendId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
)(AllRsvpTrends);
