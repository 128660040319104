import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import withSnackbar from '../hoc/withSnackbar';
import withTrends from '../hoc/withTrends';
import { Grid, Button, Checkbox, Icon, Table, Menu } from 'semantic-ui-react';
import classes from './list.module.css';

class KeywordsTable extends Component {
  static propTypes = {
    snackbar: PropTypes.func.isRequired,
    insertTrend: PropTypes.func.isRequired,
    inverted: PropTypes.bool,
    listItems: PropTypes.array,
    datatrends: PropTypes.object,
    selectedOnBurst: PropTypes.array,
    addListItem: PropTypes.func,
    saveList: PropTypes.func,
    removeListItem: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      ListItems: props.ListItems,
      cancelledListItems: '',
      title: '',
      counter: 0,
    };
  }

  addListItem = () => {
    // event.preventDefault();
    let trend_keyword = this.props.selectedOnBurst[0];
    let tweet_volume = this.props.selectedOnBurst[1];
    let city = this.props.selectedOnBurst[2];
    // let counter = this.state.counter;
    let ListItem = {
      trend_keyword,
      tweet_volume,
      city,
      // counter,
    };
    // counter += 1;
    // might want to do this with spread operator and new array method
    let ListItems = this.state.ListItems;
    // persons.some(person => person.name === "Peter"
    if (this.state.ListItems.find(item => item.trend_keyword === ListItem.trend_keyword)) {
      this.props.snackbar('add-list-item-failure-duplicate', 'error');
    } else {
      ListItems.push(ListItem);
      this.props.snackbar('add-list-item-success', 'success');
      this.setState({
        ListItems: ListItems,
      });
    }
  };

  removeListItem = index => {
    const ListItems = this.state.ListItems;
    for (let i = ListItems.length - 1; i >= 0; i--) {
      if (ListItems[i].trend_keyword === index) {
        ListItems.splice(i, 1);
      }
    }
    this.setState({
      ListItems: ListItems,
    });
  };

  returnUniqueListItems(arr, comp) {
    if (arr) {
      const unique = arr
        .map(e => e[comp])
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter(e => arr[e])
        .map(e => arr[e]);
      return unique;
    }
  }

  componentDidUpdate(prevProps) {
    this.returnUniqueListItems(this.state.ListItems, 'trend_keyword');
  }

  render() {
    let ListItems = this.props.ListItems;
    let uniqueListItems = ListItems && this.returnUniqueListItems(ListItems, 'trend_keyword');
    // // console.log('LIST ITEMS', this.state.ListItems);
    // console.log('LISTITEMS', this.props.ListItems);

    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <Grid
          style={{
            width: '100%',
            height: '70%',
            overflowY: 'auto',
            margin: 0,
            padding: 0,
          }}
        >
          <Grid.Row>
            <Grid.Column width={16}>
              <Menu
                inverted
                vertical
                className={classes.scroll}
                style={{ width: '100%', paddingBottom: '0', margin: '0' }}
              >
                <Table inverted compact celled>
                  <Table.Header>
                    <Table.Row>
                      {/* <Table.HeaderCell style={{ fontSize: '14px', background: '#333' }}>
                        Select
                      </Table.HeaderCell> */}
                      <Table.HeaderCell style={{ fontSize: '14px' }}>
                        Keyword / Hashtag {this.state.title}
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ fontSize: '14px' }}>Tweet Volume</Table.HeaderCell>
                      <Table.HeaderCell style={{ fontSize: '14px' }}>City</Table.HeaderCell>
                      <Table.HeaderCell style={{ fontSize: '14px' }}>Options</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  {/* here create a looped CRUD list for each keywords ready to be staged and sent to analysis. */}
                  <Table.Body>
                    {uniqueListItems &&
                      uniqueListItems.map((ListItem, index) => (
                        <Table.Row key={index}>
                          {/* <Table.Cell style={{ fontSize: '14px' }} collapsing>
                            <Checkbox />
                          </Table.Cell> */}
                          <Table.Cell style={{ fontSize: '14px' }}>
                            {ListItem.trend_keyword}
                          </Table.Cell>
                          <Table.Cell style={{ fontSize: '14px' }}>
                            {ListItem.tweet_volume} Tweets in Last 48 Hours
                          </Table.Cell>
                          <Table.Cell style={{ fontSize: '14px' }}>{ListItem.city}</Table.Cell>
                          <Table.Cell>
                            <Button
                              floated="left"
                              icon
                              labelPosition="left"
                              inverted
                              color="red"
                              size="mini"
                              onClick={this.removeListItem.bind(null, ListItem.trend_keyword)}
                            >
                              <Icon name="minus" /> Remove
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Menu>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Table inverted compact celled definition>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan="4">
                      <Button
                        floated="right"
                        icon
                        labelPosition="left"
                        inverted
                        color="green"
                        size="tiny"
                        onClick={() => this.props.saveList(this.state.ListItems)}
                      >
                        <Icon name="chart bar outline" /> Export To Sentiment Analysis
                      </Button>
                      {this.props.selectedOnBurst !== null && (
                        <Button
                          onClick={this.addListItem}
                          floated="left"
                          icon
                          labelPosition="left"
                          inverted
                          color="blue"
                          size="tiny"
                        >
                          <Icon name="plus" /> Add Selected Trend To List
                        </Button>
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withSnackbar,
  withTrends,
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add in dispatch action functions here
    }),
  ),
)(KeywordsTable);
