import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import ADD_TRENDS_TO_DATASET_MUTATION from '../graphql/mutations/addTrendsToDataset.graphql';
import { Modal, Form, Input, TextArea, Checkbox, Button, Transition } from 'semantic-ui-react';
import withSnackbar from '../hoc/withSnackbar';

class AddTrendsToDatasetDialog extends Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool,
    snackbar: PropTypes.func.isRequired,
    dataset: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  state = { datasetName: null, datasetDetails: null, trackedOutlets: [] };

  handleAddItemsToDataset = async () => {
    // console.log('this.props.dataset', this.props.dataset);
    // console.log('this.props.currentUSer', this.props.currentUser);
    for (const trend of this.props.selectedTrends) {
      await this.props.AddTrendsToDatasetMutation(
        this.props.currentUser,
        this.props.dataset.id,
        trend.id,
      );
    }
    this.props.toggle();
  };

  render() {
    return (
      <div>
        <Transition.Group animation="fly down" duration={450}>
          {this.props.open && (
            <Modal size="mini" dimmer open={this.props.open} onClose={this.props.toggle}>
              <Modal.Header style={{ color: 'green' }}>Add Trends to Dataset</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description style={{ textAlign: 'center' }}>
                  <h1 style={{ color: 'green' }}>{this.state.datasetName}</h1>

                  {this.props.selectedTrends.map((trend, index) => (
                    <div key={index}>
                      <p>{trend.id}</p>
                      <p>{trend.name}</p>
                      <hr />
                    </div>
                  ))}
                  <br />
                  <Button
                    disabled={!this.props.selectedTrends}
                    inverted
                    color="green"
                    content={`Add Trends to dataset: "${this.props.dataset.name}"`}
                    onClick={() => this.handleAddItemsToDataset()}
                  />
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button content="Nevermind" onClick={() => this.props.toggle()} />
              </Modal.Actions>
            </Modal>
          )}
        </Transition.Group>
      </div>
    );
  }
}

export default compose(
  withSnackbar,
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
      selectedTrends: state.ux.selectedTrends,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const { ...restDispatchProps } = dispatchProps;
      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
      };
    },
  ),
  graphql(ADD_TRENDS_TO_DATASET_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        AddTrendsToDatasetMutation: async (userId, datasetId, trendId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                datasetId: datasetId,
                trendId: trendId,
              },
            });
            if (response) {
              await ownProps.snackbar('add-trends-to-dataset-success', 'success');
              return response;
            }
          } catch (e) {
            await ownProps.snackbar('add-trends-to-dataset-error', 'success');
            console.error(e.message);
          }
        },
      };
    },
  }),
)(AddTrendsToDatasetDialog);
