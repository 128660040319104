import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import RSVP_MUTATION from '../graphql/mutations/rsvp.graphql';
import withSnackbar from '../hoc/withSnackbar';
import withTrends from '../hoc/withTrends';
import axios from 'axios';
import classes from './list.module.css';
import Burst from './Burst';
import { Container, Grid, Menu, Button, Input } from 'semantic-ui-react';
import KeywordsTable from './Table';
import importedData from './sample-data/ListData';

// This is a parent component to 'Burst', 'DiscoverTrends', 'KeywordsTable'

let data = importedData;

class Search2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      data: [],
      selectedcity: '',
      datatrends: {},
      selectedOnBurst: [],
      listView: true,
      ListItems: [],
    };
  }

  selectedOnBurstHandler = anonFunctionToCarryThroughProps => {
    this.setState({
      selectedOnBurst: anonFunctionToCarryThroughProps,
    });
  };

  componentDidMount() {
    this.setState({
      data: data,
    });
    // this.refs.search.focus();
  }

  handleChange = () => {
    this.setState({
      searchString: this.refs.search.value,
    });
  };

  clickCity(value) {
    let uri;
    if (process.env.NODE_ENV === 'production') {
      uri = 'https://testing.media:3001';
    } else if (process.env.NODE_ENV === 'development') {
      uri = 'http://localhost:3001';
    }
    axios
      .post(`${uri}/api/twitter/get_updated_trends_for_visualization`, {
        city: value,
      })
      .then(response => {
        // // console.log('response', response.data);
        this.setState({ datatrends: response.data, listView: false });
      });
  }

  renderChild(city) {
    let showcity = city;
    //// console.log("SHOWCITY", showcity);
    this.setState({ selectedcity: showcity, revolvingtrenddata: showcity });
  }

  back = () => {
    this.setState({ listView: true });
  };

  saveList = async items => {
    // replace with apollo graphql
    let trends = items;
    const userId = this.props.currentUser;
    const date = new Date();
    for (const trend of trends) {
      await this.props
        .insertTrend(userId, trend.trend_keyword, trend.tweet_volume.toString(), date, trend.city)
        .then(async response => {
          // console.log('GRAPHQL RESPONSE INSERT TREND TABLE.JS', response);
          await this.props.rsvp(userId, response.data.insertTrend.id);
        });
    }
    this.props.snackbar('subscribe-to-trend-list-success');
    this.setState({ ListItems: [] });
  };

  addListItem = () => {
    // event.preventDefault();
    let trend_keyword = this.state.selectedOnBurst[0];
    let tweet_volume = this.state.selectedOnBurst[1];
    let city = this.state.selectedOnBurst[2];
    let ListItem = {
      trend_keyword,
      tweet_volume,
      city,
    };
    // might want to do this with spread operator and new array method
    let ListItems = this.state.ListItems;
    // persons.some(person => person.name === "Peter"
    if (this.state.ListItems.find(item => item.trend_keyword === ListItem.trend_keyword)) {
      this.props.snackbar('add-list-item-failure-duplicate', 'error');
    } else {
      ListItems.push(ListItem);
      this.props.snackbar('add-list-item-success', 'success');
      this.setState({
        ListItems: ListItems,
      });
    }
  };

  render() {
    let _data = this.state.data;
    let search = this.state.searchString.trim().toLowerCase();
    if (search.length > 0) {
      _data = _data.filter(function (user) {
        return user.city.toLowerCase().match(search) || user.country.toLowerCase().match(search);
      });
    }
    // console.log('lsititmes', this.state.ListItems);

    return (
      <Container style={{ marginTop: '50px', width: '100%', height: '100%' }}>
        {/* <CardDiscoverTrends /> */}
        <Grid>
          {this.state.listView && (
            <Grid.Column width={7}>
              <input
                inverted
                action={{ icon: 'filter' }}
                style={{
                  margin: '10px',
                  color: '#fff',
                  background: '#111',
                  border: '#000',
                  borderRadius: '5px',
                  height: '35px',
                  width: '100%',
                }}
                type="text"
                value={this.state.searchString}
                ref="search"
                onChange={this.handleChange}
                placeholder=" Start typing to filter for a city: "
              />
              <Menu
                inverted
                vertical
                className={classes.scroll}
                style={{ width: '100%', paddingBottom: '25px', margin: '10px' }}
              >
                <ul className={classes.searchUl}>
                  {_data.map(l => {
                    return (
                      <Menu.Item
                        className={classes.searchLi}
                        key={l.woeid}
                        value={l.woeid}
                        city={l.city}
                        ref="itemValue"
                        onClick={event => {
                          this.clickCity.bind(this, l.woeid)(event);
                          this.renderChild.bind(this, l.city)(event);
                          //this.getdata();
                        }}
                      >
                        <p href="#!" style={{ lineHeight: '20px' }}>
                          {l.city + ', ' + l.country}
                        </p>
                      </Menu.Item>
                    );
                  })}
                </ul>
              </Menu>
            </Grid.Column>
          )}

          {!this.state.listView && (
            <Grid.Column width={7}>
              <h2
                style={{
                  paddingTop: '10px',
                }}
              >
                Discover Trends
              </h2>
              <br />
              <h4>Viewing Trends for: </h4>
              <h2>{this.state.selectedcity}</h2>

              <Burst
                datatrends={this.state.datatrends}
                selectedcity={this.state.selectedcity}
                selectedOnBurstHandler={this.selectedOnBurstHandler}
                add={this.addListItem}
              />
              <Button
                size="huge"
                style={{ marginTop: '100px' }}
                onClick={this.back}
                basic
                color="black"
                inverted
              >
                Back
              </Button>
            </Grid.Column>
          )}

          <Grid.Column width={9}>
            <KeywordsTable
              key={this.state.ListItems}
              datatrends={this.state.datatrends}
              selectedOnBurst={this.state.selectedOnBurst}
              ListItems={this.state.ListItems}
              saveList={this.saveList}
            />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default compose(
  withSnackbar,
  withTrends,
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add in dispatch action functions here
    }),
  ),
  graphql(RSVP_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        rsvp: async trendId => {
          try {
            const response = await mutate({
              variables: {
                userId: ownProps.currentUser,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
)(Search2);
