// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LMuFjBy7eRj3huBug2YM3Q\\=\\= {\n  background-color: #fc454e;\n  width: 66;\n  height: 66;\n  border-radius: 33;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  bottom: 20;\n  right: 20;\n}\n\n.GQY6uwABGrbof5ZXbjlFkg\\=\\= {\n  font-family: 'Titillium Web', sans-serif !important;\n}\n", "",{"version":3,"sources":["webpack://src/frontend/components/uxElements/AssetsSelectedMenu.module.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":["@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);\n\n.sticky {\n  background-color: #fc454e;\n  width: 66;\n  height: 66;\n  border-radius: 33;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  bottom: 20;\n  right: 20;\n}\n\n.font {\n  font-family: 'Titillium Web', sans-serif !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sticky": "LMuFjBy7eRj3huBug2YM3Q==",
	"font": "GQY6uwABGrbof5ZXbjlFkg=="
};
export default ___CSS_LOADER_EXPORT___;
