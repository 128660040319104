export default {
  'unknown-error': 'Something broke',
  'unhandled-error': 'Something broke - see console for details',
  'signup-error':
    'Something went wrong while signing in, check your Username and Password. Make sure to use a valid email and leave no blank fields',
  'blank-fields-signin': 'All fields must have a value. Check your username or password',
  'cancel-account-error': 'Error Cancelling account',
  'success-sign-in': 'Successfully signed in',
  'unhandled-error-app-component': 'Unhandled error in app component',
  'auth-error': 'Sorry, please sign in.',
  'logged-out-successfully': 'You have loggged out.',
  'stopped-tracking-trend': 'You are no longer tracking this trend.',
  'delete-trend-success': 'You have successfully deleted this trend.',
  'thank-you-creating-account': 'Thank you for signing up! We hope you find our app useful.',
  'logging-in-please-wait': 'Logging you in. Please wait...',
  'almost-done': 'Almost done... Do not refresh the page or navigate away from this page.',
  'add-list-item-success': 'Item added to list',
  'add-list-item-failure-duplicate': 'Trend for this area already in list.',
  'subscribe-to-trend-list-success': 'Successfully subscribed to trends list!',
  'subscribe-to-trend-success': 'Subscribed to trend!',
  'logged-out-successfully-cancelled-account':
    'Successfully Cancelled Account. You have been logged out. Sorry to see you go!',
  // backend errors:
  'error: subscription-inactive-reactivate':
    'Looks like we met before! To reactivate your subscription, please sign up again as a new user.',
  'delete-dataset-success': 'Dataset has been deleted.',
  'delete-dataset-error':
    'Error deleting dataset. Contact a system administrator if this problem persists.',
  'create-dataset-success': 'Dataset created',
  'create-dataset-error':
    'Error creating dataset. Contact a system administrator if this problem persists.',
  'add-trends-to-dataset-success': 'Successfully added trends to dataset!',
  'add-trends-to-dataset-error': 'Error adding trends to dataset',
  'deleting-multiple-trends': 'Deleting trends...',
  'deleting-multiple-datasets': 'Deleting datasets...',
  'user-with-that-email-already-exists': 'User with that email already exists, try logging in?',
  done: 'Done.',
  'generate-stats-success': 'Successfully generated stats',
  'generate-stats-error': 'Error generating stats',
  'custom-error': '${message}',
};
