import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Form, Icon, Card, Label, Grid } from 'semantic-ui-react';
import { CardElement } from 'react-stripe-elements';
import classes from './signup.module.css';
import { getDisplayName } from 'recompose';

const Signup = props => {
  const [planSelected, setPlanSelected] = useState(false);

  // useEffect(() => {
  //   if (props.planId) {
  //     setPlanSelected(true);
  //   }
  // });

  const handleSelectPlan = planId => {
    props.selectPlan(planId);
    setPlanSelected(true);
  };

  const getPlanDisplayName = () => {
    if (props.planId === 'price_1Ld6nVJPswRDANVm9oNR0z9l') {
      return 'Bronze: 24.99 / mo.';
    }
    if (props.planId === 'price_1JKPcnJPswRDANVmpJEBEmEF') {
      return 'Gold: 75.00 / mo.';
    }
    if (props.planId === 'price_1Ld6l5JPswRDANVmiE3oQpPC') {
      return 'Platinum: 145.00 / mo.';
    }
  };

  return (
    <div>
      <div>
        <h2>
          <Link to="/signIn">
            <span href="" className={classNames(classes.signInButton, classes.uppercase)}>
              Sign In
            </span>
          </Link>
        </h2>
      </div>
      {!planSelected && (
        <div className={classes.signup_body}>
          <div className={classNames(classes.container, classes.group)}>
            <div className={classes.grid_1_5}>
              <h3>Bronze</h3>
              <h3>
                <sup>$</sup>24.99<span className={classes.small}>/mo</span>
              </h3>
              <p style={{ color: 'gold' }}>
                <b>200</b> Machine Learning Compute Credits ( casual )
              </p>
              <ul>
                <li>Limited Email Support</li>
                <li>Unlimited Dataset Storage</li>
                <li>Access to Twitter stream data</li>
                <li>Multiple Keyword Dataset Tracking</li>
                <li>Advanced Stock Charting</li>
              </ul>
              <a
                href="#"
                onClick={() => handleSelectPlan('price_1Ld6nVJPswRDANVm9oNR0z9l')}
                className={classes.button}
              >
                Sign Up
              </a>
            </div>

            <div className={classes.grid_1_5}>
              <h3>Gold</h3>
              <h3>
                <sup>$</sup>75.00<span className={classes.small}>/mo</span>
              </h3>
              <p style={{ color: 'gold' }}>
                <b>1,000</b> Machine Learning Compute Credits ( data enthusiast )
              </p>
              <ul>
                <li>Email Support</li>
                <li>Unlimited Dataset Storage</li>
                <li>Access to Twitter stream data</li>
                <li>Multiple Keyword Dataset Tracking</li>
                <li>Advanced Stock Charting</li>
                <li>Stock Chart + Dataset Overlays on Same Graph</li>
                <li>Access to Twitter stream data</li>
                <li>Data Exports in CSV format</li>
              </ul>
              <a
                href="#"
                onClick={() => handleSelectPlan('price_1JKPcnJPswRDANVmpJEBEmEF')}
                className={classes.button}
              >
                Sign Up
              </a>
            </div>

            <div className={classes.grid_1_5}>
              <h3>Platinum</h3>
              <h3>
                <sup>$</sup>145.00<span className={classes.small}>/mo</span>
              </h3>
              <p style={{ color: 'gold' }}>
                <b>2,750</b> Machine Learning Compute Credits ( day trader )
              </p>
              <ul>
                <li>Priority Email Support</li>
                <li>Unlimited Dataset Storage</li>
                <li>Access to Twitter stream data</li>
                <li>Multiple Keyword Dataset Tracking</li>
                <li>Advanced Stock Charting</li>
                <li>Stock Charting + Datasets Overlayed on Same Graph</li>
                <li>Access to Twitter stream data</li>
                <li>Access to Reddit data</li>
                <li>Access to Reddit trending data by searchable subreddit</li>
                <li>CryptoCurrency price charts</li>
                <li>CryptoCurrency Charts + Datasets Overlayed on Same Graph</li>
                <li>CryptoCurrency Charts + Datasets Overlayed on Same Graph</li>
                <li>Data Exports in CSV format</li>
              </ul>
              <a
                href="#"
                onClick={() => handleSelectPlan('price_1Ld6l5JPswRDANVmiE3oQpPC')}
                className={classes.button}
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      )}

      {planSelected && (
        <div>
          <div className={classes.signup_body}>
            <div className={classNames(classes.container, classes.group)}>
              {props.planId === 'price_1Ld6nVJPswRDANVm9oNR0z9l' && (
                <div className={classes.grid_1_5}>
                  <h3>Bronze</h3>
                  <h3>
                    <sup>$</sup>24.99<span className={classes.small}>/mo</span>
                  </h3>
                  <p style={{ color: 'gold' }}>
                    <b>200</b> Machine Learning Compute Credits
                  </p>
                  <ul>
                    <li>Limited Email Support</li>
                    <li>Unlimited Dataset Storage</li>
                    <li>Access to Twitter stream data</li>
                    <li>Multiple Keyword Dataset Tracking</li>
                    <li>Advanced Stock Charting</li>
                  </ul>
                </div>
              )}

              {props.planId === 'price_1JKPcnJPswRDANVmpJEBEmEF' && (
                <div className={classes.grid_1_5}>
                  <h3>Gold</h3>
                  <h3>
                    <sup>$</sup>75.00<span className={classes.small}>/mo</span>
                  </h3>
                  <p style={{ color: 'gold' }}>
                    <b>1,000</b> Machine Learning Compute Credits
                  </p>
                  <ul>
                    <li>Email Support</li>
                    <li>Unlimited Dataset Storage</li>
                    <li>Access to Twitter stream data</li>
                    <li>Multiple Keyword Dataset Tracking</li>
                    <li>Advanced Stock Charting</li>
                    <li>Stock Chart + Dataset Overlays on Same Graph</li>
                    <li>Access to Twitter stream data</li>
                    <li>Data Exports in CSV format</li>
                  </ul>
                </div>
              )}

              {props.planId === 'price_1Ld6l5JPswRDANVmiE3oQpPC' && (
                <div className={classes.grid_1_5}>
                  <h3>Platinum</h3>
                  <h3>
                    <sup>$</sup>145.00<span className={classes.small}>/mo</span>
                  </h3>
                  <p style={{ color: 'gold' }}>
                    <b>2,750</b> Machine Learning Compute Credits
                  </p>
                  <ul>
                    <li>Priority Email Support</li>
                    <li>Unlimited Dataset Storage</li>
                    <li>Access to Twitter stream data</li>
                    <li>Multiple Keyword Dataset Tracking</li>
                    <li>Advanced Stock Charting</li>
                    <li>Stock Charting + Datasets Overlayed on Same Graph</li>
                    <li>Access to Twitter stream data</li>
                    <li>Access to Reddit data</li>
                    <li>Access to Reddit trending data by searchable subreddit</li>
                    <li>CryptoCurrency price charts</li>
                    <li>CryptoCurrency Charts + Datasets Overlayed on Same Graph</li>
                    <li>CryptoCurrency Charts + Datasets Overlayed on Same Graph</li>
                    <li>Data Exports in CSV format</li>
                  </ul>
                </div>
              )}

              <Card inverted style={{ padding: '20px', marginTop: '50px', marginLeft: '15px' }}>
                <Form>
                  <h1>Subscribe to QuantEngine:</h1>
                  <Label color="violet" style={{ marginBottom: '15px' }}>
                    Plan: {getPlanDisplayName()}
                  </Label>
                  <br />
                  <Form.Field>
                    <Label ribbon color="purple">
                      First Name:
                    </Label>
                    <input
                      value={props.name}
                      onChange={e => props.changeName(e.target.value)}
                      type="text"
                      placeholder="Name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Label ribbon color="purple">
                      Email:
                    </Label>
                    <input
                      value={props.email}
                      onChange={e => props.changeEmail(e.target.value)}
                      type="text"
                      placeholder="Last Name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Label ribbon color="purple">
                      Password:
                    </Label>
                    <input
                      value={props.password}
                      onChange={e => props.changePassword(e.target.value)}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Field>
                  <Icon size="large" name="stripe" />
                  <Form.Field>
                    <CardElement onReady={el => el.focus()} />
                  </Form.Field>
                  <Form.Field>
                    <Button onClick={props.signup}>Submit</Button>
                  </Form.Field>
                  <hr />
                  <Form.Field>
                    <label>Or,</label>
                    <Link onClick={() => setPlanSelected(false)}>Change Plan</Link>
                    <br />
                    <Link to="/signIn">Sign In</Link>
                  </Form.Field>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Signup.propTypes = {
  name: PropTypes.string,
  changeName: PropTypes.func,
  email: PropTypes.string,
  changeEmail: PropTypes.func,
  password: PropTypes.string,
  changePassword: PropTypes.func,
  signup: PropTypes.func,
  planId: PropTypes.string,
  selectPlan: PropTypes.func,
};

export default Signup;
