import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import CREATE_DATASET_MUTATION from '../graphql/mutations/createDataset.graphql';
import GET_SUBREDDITS from '../graphql/queries/getSubReddits.graphql';
import {
  Modal,
  Form,
  Input,
  TextArea,
  Checkbox,
  Button,
  Transition,
  SearchResults,
  Dropdown,
} from 'semantic-ui-react';
import withSnackbar from '../hoc/withSnackbar';
import graphqlFunctional from '../../utils/graphqlFunctional';
import _ from 'lodash';

class CreateDatasetDialog extends Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool,
    snackbar: PropTypes.func.isRequired,
    refetchDatasets: PropTypes.func.isRequired,
    createDataset: PropTypes.func.isRequired,
  };

  state = {
    datasetName: null,
    datasetDetails: null,
    trackedOutlets: [],
    subreddit: null,
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleCheckbox = outlet => {
    let outlets = [...this.state.trackedOutlets];
    const exists = this.state.trackedOutlets.includes(outlet);
    if (!exists) {
      outlets.push(outlet);
    } else {
      outlets.splice(outlets.indexOf(outlet), 1);
    }
    this.setState({ trackedOutlets: outlets });
  };

  handleCreateDataset = async () => {
    const { datasetName, datasetDetails, trackedOutlets } = this.state;
    const { currentUser } = this.props;
    await this.props.createDataset(
      currentUser,
      datasetName,
      datasetDetails,
      [],
      trackedOutlets,
      this.state.subreddit,
    );
    await this.props.toggle();
    await this.props.refetchDatasets();
  };

  handleSearchChange = _.debounce(async (e, { searchQuery }) => {
    const results = await graphqlFunctional.query({
      query: GET_SUBREDDITS,
      fetchPolicy: 'network-only',
      variables: {
        queryString: searchQuery,
      },
    });
    if (results.loading) {
      this.setState({ loadingSubRedditSearch: true, isFetching: true });
    }
    // console.log('results', results.data.getSubReddits);
    const options = results.data.getSubReddits.map(item => {
      return { key: item, text: item, value: _.snakeCase(item) };
    });
    this.setState({ loadingSubRedditSearch: false, options: options, isFetching: false });
    return results.data.getSubReddits;
  }, 150);

  handleDropdownChange = (e, { value }) => {
    this.setState({ subreddit: value });
  };

  render() {
    // console.log('state createDatasetDialog', this.state);
    return (
      <div>
        <Transition.Group animation="fly down" duration={450}>
          {this.props.open && (
            <Modal size="tiny" dimmer open={this.props.open} onClose={this.props.toggle}>
              <Modal.Header style={{ color: 'green' }}>Create a Dataset</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description style={{ textAlign: 'center' }}>
                  <h1 style={{ color: 'green' }}>{this.state.datasetName}</h1>
                  <Form size="small">
                    <Form.Group widths="equal" grouped>
                      <Form.Field
                        error={
                          this.state.datasetName !== null &&
                          this.state.datasetName.length === 0 && {
                            content: 'You must enter a dataset name',
                            pointing: 'above',
                          }
                        }
                        control={Input}
                        label="Dataset Name"
                        placeholder="Name this dataset"
                        name="datasetName"
                        value={this.state.datasetName || ''}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Form.Field
                      error={
                        this.state.datasetDetails !== null &&
                        this.state.datasetDetails.length === 0 && {
                          content: 'You must enter details about this dataset',
                          pointing: 'above',
                        }
                      }
                      control={TextArea}
                      label="Details / Purpose"
                      placeholder="Dataset details"
                      name="datasetDetails"
                      value={this.state.datasetDetails || ''}
                      onChange={this.handleChange}
                    />
                    {/* <Form.Field
                      control={Checkbox}
                      label="Track on Amazon"
                      checked={this.state.trackedOutlets.includes('amazon')}
                      onChange={() => this.handleCheckbox('amazon')}
                    /> */}
                    <Form.Field
                      control={Checkbox}
                      label="Track on Twitter"
                      checked={this.state.trackedOutlets.includes('twitter')}
                      onChange={() => this.handleCheckbox('twitter')}
                    />
                    <Form.Field
                      control={Checkbox}
                      label="Track on Reddit"
                      checked={this.state.trackedOutlets.includes('reddit')}
                      onChange={() => this.handleCheckbox('reddit')}
                    />
                    {this.state.trackedOutlets.includes('reddit') && (
                      <Dropdown
                        fluid
                        selection
                        // multiple
                        search
                        options={this.state.options}
                        value={this.state.subreddit}
                        placeholder="Search for a sub-Reddit"
                        onChange={this.handleDropdownChange}
                        onSearchChange={this.handleSearchChange}
                        disabled={this.state.isFetching}
                        loading={this.state.isFetching}
                      />
                    )}
                  </Form>
                  <br />
                  <hr />
                  <br />
                  {this.state.datasetName && (
                    <Button
                      disabled={
                        !this.state.datasetDetails ||
                        !this.state.datasetName ||
                        this.state.datasetName.length === 0 ||
                        this.state.datasetDetails.length === 0
                      }
                      inverted
                      color="green"
                      content={`Create Dataset: "${this.state.datasetName}"`}
                      onClick={() => this.handleCreateDataset()}
                    />
                  )}
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button content="Nevermind" onClick={() => this.props.toggle()} />
              </Modal.Actions>
            </Modal>
          )}
        </Transition.Group>
      </div>
    );
  }
}

export default compose(
  withSnackbar,
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const { ...restDispatchProps } = dispatchProps;
      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
      };
    },
  ),
  graphql(CREATE_DATASET_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        createDataset: async (userId, name, details, trendIds, trackedOutlets, subreddit) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                name: name,
                details: details,
                trendIds: trendIds,
                trackedOutlets: trackedOutlets,
                subreddit: subreddit,
              },
            });
            await ownProps.snackbar('create-dataset-success', 'success');
            return response;
          } catch (e) {
            await ownProps.snackbar('create-dataset-error', 'success');
            console.error(e.message);
          }
        },
      };
    },
  }),
)(CreateDatasetDialog);
