import AutoSuggestDatasets from './AutoSuggestDatasets';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
  setCachedDatasets as setCachedDatasetsAction,
  setDashboardItem as setDashboardItemAction,
  removeDashboardItem as removeDashboardItemAction,
  refetchOnRangeOrIntervalChange as refetchOnRangeOrIntervalChangeAction,
  cancelRefetchOnRangeOrIntervalChange as cancelRefetchOnRangeOrIntervalChangeAction,
} from '../redux/actions';
import FUNCTIONAL_GET_STATS_BY_DATASET_ID from '../graphql/mutations/functionalGetStatsByDatasetId.graphql';
import withSnackbar from '../hoc/withSnackbar';
import withDatasets from '../hoc/withDatasets';

export default compose(
  withSnackbar,
  withDatasets,
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
      cachedDatasets: state.ux.setCachedDatasets,
      dashboardItems: state.ux.dashboardItems.dashboardItems,
      refetchDashboardItemsOnRangeOrIntervalChange: state.ux.setCachedDatasets.refetch,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
        setCachedDatasets: datasets => {
          dispatch(setCachedDatasetsAction(datasets));
        },
        setDashboardItem: item => dispatch(setDashboardItemAction(item)),
        removeDashboardItem: item => dispatch(removeDashboardItemAction(item)),
        refetchOnRangeOrIntervalChange: () => dispatch(refetchOnRangeOrIntervalChangeAction()),
        cancelRefetchOnRangeOrIntervalChange: () =>
          dispatch(cancelRefetchOnRangeOrIntervalChangeAction()),
      };
    },
    // merge props
    // (stateProps, dispatchProps, ownProps) => {
    //   const { ...restDispatchProps } = dispatchProps;

    //   return {
    //     ...ownProps,
    //     ...stateProps,
    //     ...restDispatchProps,
    //   };
    // },
  ),
  graphql(FUNCTIONAL_GET_STATS_BY_DATASET_ID, {
    props: ({ mutate, ownProps }) => {
      return {
        getStatsByDatasetIdFunctional: async (datasetId, datasetName) => {
          try {
            // console.log('IM FREAKING SCREAMING', ownProps.currentDashboardObject);
            const response = await mutate({
              variables: {
                datasetId: datasetId,
              },
            });
            ownProps.snackbar('custom-error', 'success', {
              message: `Stats fetched for ${datasetName}...`,
            });
            return response;
          } catch (e) {
            ownProps.snackbar('custom-error', 'error', {
              message: `Error fetching stats for "${datasetName}" with id "${datasetId}"...`,
            });
            console.error(e.message);
          }
        },
      };
    },
  }),
)(AutoSuggestDatasets);
