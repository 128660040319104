import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Form, Button, Icon, Label, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import withUsers from '../hoc/withUsers';
import withSnackbar from '../hoc/withSnackbar';

class Signin extends Component {
  static propTypes = {
    snackbar: PropTypes.func.isRequired,
    signIn: PropTypes.func,
    logIn: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    currentUser: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      redirectToHome: false,
    };
  }

  signin = async () => {
    const { email, password } = this.state;
    if (email.length === 0 || password.length === 0) {
      this.props.snackbar('blank-fields-signin', 'warning');
    } else {
      await this.props.signIn(email, password).then(async response => {
        if (response.signIn) {
          localStorage.setItem('token', response.signIn.token);
          localStorage.setItem('email', email);
          localStorage.setItem('userId', response.signIn.id);
          this.setState({
            token: response.signIn.token,
          });
          await this.props.logIn(true, response.signIn.id);
        }
      });
      // this.setState({ redirectToHome: true });
      this.props.history.push('/');
    }
  };

  render() {
    const { email, password, redirectToHome } = this.state;
    if (redirectToHome) {
      return <Redirect to="/" />;
    }
    return (
      <Card inverted style={{ padding: '20px' }}>
        <Form className={'usersForm'}>
          <h1>Sign in:</h1>
          <Form.Field>
            <Label ribbon color="purple">
              Email
            </Label>
            <input
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              type="text"
              placeholder="Last Name"
            />
          </Form.Field>
          <Form.Field>
            <Label ribbon color="purple">
              Password
            </Label>
            <input
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              type="password"
              placeholder="Password"
            />
          </Form.Field>
          <Form.Field>
            <Button
              onClick={e => {
                this.signin();
              }}
            >
              Submit
            </Button>
          </Form.Field>

          <Form.Field>
            <label>Or,</label>
            <Link to="/signup">
              <h2>Get Access</h2>
            </Link>
          </Form.Field>
        </Form>
      </Card>
    );
  }
}

export default compose(withSnackbar, withUsers)(Signin);
// use redux to trigger signing in
