// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "inputFieldTop {\n  margin-bottom: 3px;\n}\n\ninputSpan {\n  /* float: left; */\n  margin-bottom: 300px;\n}\ninputSpan2 {\n  float: right;\n  z-index: 100;\n}\n\n@media only screen and (max-width: 1212px) {\n  inputSpan2 {\n    float: left;\n    z-index: 100;\n  }\n}\n\n.hTI0VSwspMJypGDV0Dytuw\\=\\= {\n  height: 25px;\n}\n", "",{"version":3,"sources":["webpack://src/frontend/components/dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE,YAAY;AACd","sourcesContent":["inputFieldTop {\n  margin-bottom: 3px;\n}\n\ninputSpan {\n  /* float: left; */\n  margin-bottom: 300px;\n}\ninputSpan2 {\n  float: right;\n  z-index: 100;\n}\n\n@media only screen and (max-width: 1212px) {\n  inputSpan2 {\n    float: left;\n    z-index: 100;\n  }\n}\n\n.react-autosuggest__input {\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"react-autosuggest__input": "hTI0VSwspMJypGDV0Dytuw=="
};
export default ___CSS_LOADER_EXPORT___;
