import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Switch from './switch';
import PropTypes from 'prop-types';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const drawerWidth = 'auto';

export default function TemporaryDrawer(props) {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const items = [
    'ema',
    'sma',
    'macd',
    'rsi',
    'wma',
    'tma',
    'bollingerBand',
    'heikinAshi',
    'kagi',
    'pointAndFigure',
    'renko',
    'atr',
    'stochasticOscillator',
    'forceIndex',
    'sar',
    'elderRay',
    'change',
    'elderImpulse',
    'compare',
  ];

  const list = anchor => (
    <Box
      style={{ padding: '25px' }}
      // sx={{ width: 'auto' }}
      sx={{ flexGrow: 1, width: 'auto' }}
      // role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid responsive container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {items.map((item, key) => (
          <Grid key={key} item xs={3}>
            <Item
              style={{
                width: 'auto',
                height: 'auto',
                padding: 0,
                background: 'rgba(1, 1, 1, 0.4)',
                backdropFilter: 'blur(8px)',
              }}
            >
              <Switch
                marginLeft={'14px'}
                size="small"
                checked={props.switches[item]}
                value={item}
                label={item}
                handleChange={() => props.handleChange(item)}
              />
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const theme = useTheme();

  return (
    <div>
      <React.Fragment>
        <Button onClick={toggleDrawer('right', true)}>Settings</Button>
        <Drawer
          style={{ opacity: 0.9 }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              background: 'rgba(210, 210, 210, 0.1)',
            },
          }}
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          PaperProps={{ style: { position: 'absolute' } }}
          BackdropProps={{ style: { position: 'absolute' } }}
          ModalProps={{
            container: document.getElementById(`drawer-containerid${props.dashboardItemIndex}`),
            style: { position: 'absolute' },
          }}
          variant="temporary"
        >
          <DrawerHeader>
            <IconButton style={{ float: 'left' }} onClick={toggleDrawer('right', false)}>
              {theme.direction === 'ltr' ? (
                <ChevronRightIcon style={{ color: '#fff' }} />
              ) : (
                <ChevronLeftIcon style={{ color: '#fff' }} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

TemporaryDrawer.propTypes = {
  handleChange: PropTypes.func.isRequired,
  switches: PropTypes.object.isRequired,
  dashboardItemIndex: PropTypes.number.isRequired,
};
