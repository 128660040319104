import React, { Component } from 'react';
import withTrends from '../hoc/withTrends';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { deselectTrends as deselectTrendsAction } from '../redux/actions';
import PropTypes from 'prop-types';
import { Grid, Input, Header, Icon } from 'semantic-ui-react';
import styles from './styles';
import AllTrendsContainer from './AllTrendsContainer';

class Home extends Component {
  static propTypes = {
    currentUser: PropTypes.string,
    allTrends: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        name: PropTypes.string,
        details: PropTypes.string,
        date: PropTypes.string,
        // location: PropTypes.string,
      }),
    ),
    rsvp: PropTypes.func,
    loadingTrends: PropTypes.bool,
  };

  state = {
    trendCreatedNotification: { on: false, id: null },
    filter: true,
    allTrendsFiltered: [],
    density: 5,
    activePage: 1,
  };

  toggleFilter = () => {
    this.setState(prevState => ({
      filter: !prevState.filter,
    }));
  };

  // filterList = event => {
  //   let items = this.props.allTrends;
  //   items = items.filter(item => {
  //     return item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
  //   });
  //   this.setState({ allTrendsFiltered: items });
  // };

  handleDensityChange = (e, { name, value }) => this.setState({ [name]: value });

  handlePaginationChange = async (e, { activePage }) => {
    this.setState({ activePage: Math.round(activePage) });
  };

  render() {
    return (
      <div onClick={() => this.props.deselectTrends()}>
        <Grid style={styles.grid}>
          <Grid.Row columns={2}>
            <Header as="h2">
              <Icon style={{ color: '#fff' }} name="book" />
              <Header.Content style={{ color: '#fff' }}>Keyword Library</Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row style={{ display: 'flex' }}>
            <span style={{ display: 'flex-end' }}>
              <form>
                <Input type="search" placeholder="Search" onChange={this.filterList} />
              </form>
            </span>
          </Grid.Row>
        </Grid>
        <Grid style={styles.grid} divided="vertically">
          <AllTrendsContainer
            isLoggedIn={this.props.isLoggedIn}
            currentUser={this.props.currentUser}
            density={this.state.density}
            page={this.state.activePage}
            handlePaginationChange={this.handlePaginationChange}
          />
        </Grid>
      </div>
    );
  }
}

// compose with a HOC for all trends.
export default compose(
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
        deselectTrends: () => dispatch(deselectTrendsAction()),
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const { ...restDispatchProps } = dispatchProps;
      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
      };
    },
  ),
)(Home);
