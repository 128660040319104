import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Modal, Header, Button, Transition } from 'semantic-ui-react';
import withSnackbar from '../hoc/withSnackbar';
// import styles from './TrendModal.module.css';

class DeleteModal extends Component {
  static propTypes = {
    trend: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.string,
      date: PropTypes.string,
      rsvp: PropTypes.arrayOf(PropTypes.string),
    }),
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    snackbar: PropTypes.func.isRequired,
  };

  state = {};

  onDelete = async () => {
    await this.props.onDelete();
    await this.props.toggle();
  };

  render() {
    return (
      <div>
        <Transition.Group animation="fly down" duration={350}>
          {this.props.open && (
            <Modal
              style={{
                height: '100vh',
                width: '100vw',
                padding: '150px',
                backgroundColor: 'rgba(15, 15, 15, 0.95)',
              }}
              size="mini"
              dimmer="inverted"
              open={this.props.open}
              onClose={this.props.toggle}
            >
              <Modal.Header style={{ color: 'red' }}>Delete This Trend?</Modal.Header>
              <Modal.Content scrolling>
                {this.props.trend && (
                  <Modal.Description style={{ textAlign: 'center' }}>
                    <h1 style={{ color: 'red' }}>DELETE: "{this.props.trend.name}"</h1>
                    <h3>
                      <b>Details:</b>
                    </h3>
                    <p>
                      <b>Volume: </b>
                      {this.props.trend.details}
                    </p>
                    <hr />
                    <p>
                      <b>Date Added: </b>
                      {this.props.trend.date}
                    </p>
                    <hr />
                    <p>
                      <b>Attending:</b>
                      {this.props.trend.rsvp ? this.props.trend.rsvp.length : 'no guests yet'}
                    </p>
                    <hr />
                    <p>
                      <b># of trackers in Engine:</b>
                    </p>
                    {this.props.trend &&
                    this.props.trend.rsvp &&
                    this.props.trend.rsvp.length > 0 ? (
                      this.props.trend.rsvp.map(v => <p key={v}>{v}</p>)
                    ) : (
                      <p>no guests yet!</p>
                    )}
                    <hr />
                    <Button
                      inverted
                      color="red"
                      content={'DELETE ' + this.props.trend.name}
                      onClick={e => this.onDelete()}
                    />
                  </Modal.Description>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button content="Nevermind" onClick={e => this.props.toggle()} />
              </Modal.Actions>
            </Modal>
          )}
        </Transition.Group>
      </div>
    );
  }
}

export default compose(withSnackbar)(DeleteModal);
