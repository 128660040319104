import React, { Component } from 'react';
import withTrends from '../hoc/withTrends';
import withSnackbar from '../hoc/withSnackbar';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { deselectTrends as deselectTrendsAction } from '../redux/actions';
import { Grid, Header, Container, Input, Icon } from 'semantic-ui-react';
import styles from './styles';
import AllRsvpTrendsContainer from './AllRsvpTrendsContainer';

class Rsvp extends Component {
  static propTypes = {};

  state = {
    trendModalOpen: false,
    deleteModalOpen: false,
    trendForModal: {},
    density: 5,
    activePage: 1,
  };

  handleDensityChange = (e, { name, value }) => this.setState({ [name]: value });

  handlePaginationChange = async (e, { activePage }) => {
    this.setState({ activePage: Math.round(activePage) });
  };

  render() {
    // console.log('this.state.page', this.state);
    return (
      <div onClick={() => this.props.deselectTrends()}>
        <Grid style={styles.grid} divided="vertically">
          <Grid.Row columns={3}>
            <Grid.Column>
              <Header as="h2">
                <Icon style={{ color: '#fff' }} name="database" />
                <Header.Content style={{ color: '#fff' }}>
                  Everything you're tracking:
                </Header.Content>
              </Header>
              <Container>
                <hr />
                <span>
                  <Input
                    style={{ fontSize: '10px', padding: '5px' }}
                    label={`Density: ${this.state.density} columns`}
                    min={2}
                    max={10}
                    name="density"
                    onChange={this.handleDensityChange}
                    step={1}
                    type="range"
                    value={this.state.density}
                  />
                </span>
                <hr />
              </Container>
            </Grid.Column>
          </Grid.Row>

          {/* <h3>Trends: </h3> */}
          <AllRsvpTrendsContainer
            currentUser={this.props.currentUser}
            isLoggedIn={this.props.isLoggedIn}
            page={this.state.activePage}
            handlePaginationChange={this.handlePaginationChange}
            density={this.state.density}
            rsvpTrends={this.props.rsvpTrends}
            refetchRSVPTrends={this.props.refetchRSVPTrends}
            loadingTrends={this.props.loadingTrends}
          />
        </Grid>
      </div>
    );
  }
}

// compose with a HOC for all trends.
export default compose(
  withSnackbar,
  withTrends,
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
        deselectTrends: () => dispatch(deselectTrendsAction()),
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const { ...restDispatchProps } = dispatchProps;

      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
      };
    },
  ),
)(Rsvp);
