import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SnackbarComponent from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { closeSnackbar as closeSnackbarAction } from '../redux/actions';
import { Button } from 'semantic-ui-react';
import { Alert } from '@material-ui/lab';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import errorMap from './errors';
import template from 'es6-template-strings';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    padding: '0px 30px',
    boxShadow: '0 4px 4px 3px rgba(0, 0, 0, .3)',
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 75,
    marginRight: 120,
    opacity: 1,
  },
};

export class Snackbar extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['success', 'warning', 'info', 'error', '']),
  };

  static defaultProps = {
    message: 'boo',
    severity: 'info',
  };

  onLinkClick = () => {};

  render() {
    return (
      <SnackbarComponent
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.props.isOpen}
        onClose={this.props.onRequestClose}
        message={this.props.message}
        autoHideDuration={this.props.duration ? 800 : undefined}
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="red"
            onClick={this.props.onRequestClose}
          />
        }
      >
        <Alert
          className={this.props.classes.root}
          style={{ width: 'auto', padding: 15, alignItems: 'center' }}
          icon={
            this.props.severity && this.props.severity === 'loading' ? (
              <CircularProgress />
            ) : (
              <ErrorOutlineIcon />
            )
          }
          variant="filled"
          onClose={this.props.onRequestClose}
          severity={this.props.severity === 'loading' ? 'warning' : this.props.severity}
        >
          {this.props.message}
          {this.props.link && (
            <span>
              <Link to={'/mytrends'}>
                {/* change link to take variable from redux yeah */}
                <Button color="green" size="mini" onClick={this.props.onRequestClose}>
                  See it here
                </Button>
              </Link>
            </span>
          )}
        </Alert>
      </SnackbarComponent>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(
    // map state to props
    (state, ownProps) => {
      let message;
      message = errorMap['unknown-error'];
      if (errorMap[state.ux.snackbar.message]) {
        if (state.ux.snackbar.messageArgs) {
          message = template(errorMap[state.ux.snackbar.message], state.ux.snackbar.messageArgs);
        } else {
          message = errorMap[state.ux.snackbar.message];
        }
      }
      return {
        isOpen: state.ux.snackbar.isOpen,
        message,
        severity: state.ux.snackbar.severity,
      };
    },
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      onRequestClose: () => {
        dispatch(closeSnackbarAction());
      },
    }),
  ),
)(Snackbar);
