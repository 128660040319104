import POPULAR_TRENDS_QUERY from '../graphql/queries/returnSortedPopularTrends.graphql';
import POPULAR_TRENDS_COUNT_NEXT_TWO_PAGES_QUERY from '../graphql/queries/sortedPopularTrendCountNextTwoPages.graphql';
import INSERT_TREND from '../graphql/mutations/insertTrend.graphql';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';

export default compose(
  // connect(
  //   // map state to props
  //   (state, ownProps) => ({
  //     isLoggedIn: state.ux.currentUser.isLoggedIn,
  //     currentUser: state.ux.currentUser.currentUser,
  //   }),
  //   //mapDispatchToProps
  //   (dispatch, ownProps) => ({
  //     // add action functions from redux here
  //   }),
  // ),

  // Query
  graphql(POPULAR_TRENDS_QUERY, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: ownProps.currentUser,
          page: ownProps.page - 1 || 0,
          limit: 15,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, returnSortedPopularTrends = [] } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        allTrends: returnSortedPopularTrends.sort((a, b) => b.rsvp.length - a.rsvp.length),
        refetchAllTrends: refetch,
        loadingAllTrends: loading,
      };
      return result;
    },
  }),
  graphql(POPULAR_TRENDS_COUNT_NEXT_TWO_PAGES_QUERY, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: ownProps.currentUser,
          page: ownProps.page - 1 || 0,
          limit: 15,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, sortedPopularTrendCountNextTwoPages = 0 } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        sortedPopularTrendCountNextTwoPages: sortedPopularTrendCountNextTwoPages,
        refetchSortedPopularTrendCountNextTwoPages: refetch,
      };
      return result;
    },
  }),
  // Mutation
  graphql(INSERT_TREND, {
    props: ({ mutate, ownProps }) => {
      return {
        insertTrend: async (userId, name, details, date, location) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                name: name,
                details: details,
                date: date,
                location: location,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
);
