import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();

let uri;
if (process.env.NODE_ENV === 'production') {
  uri = 'https://testing.media:3001/graphql';
} else if (process.env.NODE_ENV === 'development') {
  uri = 'http://localhost:3001/graphql';
}

const link = new HttpLink({
  uri: uri,
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;

// example usage:

// import graphqlFunctional from '../../utils/graphql';
// const result = await graphqlFunctional.query({
//   query: getUsers,
//   fetchPolicy: 'network-only',
//   variables: {
//     districtId: districtId,
//   },
// });
