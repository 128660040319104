import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
  selectTrend as selectTrendAction,
  multiSelectTrend as multiSelectTrendAction,
  shiftSelectTrend as shiftSelectTrendAction,
  deselectTrends as deselectTrendsAction,
  deselectDatasets as deselectDatasetsAction,
  setRefetchTrendsTrue as setRefetchTrendsTrueAction,
  cancelRefetchTrends as cancelRefetchTrendsAction,
} from '../redux/actions';
import STOP_TRACKING_MUTATION from '../graphql/mutations/stopTrackingTrend.graphql';
import DELETE_TREND_MUTATION from '../graphql/mutations/deleteTrend.graphql';
import RSVP_MUTATION from '../graphql/mutations/rsvp.graphql';
import POPULAR_TRENDS_QUERY from '../graphql/queries/returnSortedPopularTrends.graphql';
import TRENDS_BY_USER_ID_QUERY from '../graphql/queries/getTrendsByUserId.graphql';
import POPULAR_TRENDS_COUNT_NEXT_TWO_PAGES_QUERY from '../graphql/queries/sortedPopularTrendCountNextTwoPages.graphql';
import TRENDS_BY_USER_ID_COUNT_NEXT_TWO_PAGES_QUERY from '../graphql/queries/trendsByUserIdCountNextTwoPages.graphql';
import INSERT_TREND from '../graphql/mutations/insertTrend.graphql';
import withTrends from '../hoc/withTrends';
import withSnackbar from '../hoc/withSnackbar';
import platform from 'platform';
import AllTrends from './AllTrends';

export default compose(
  // withTrends,
  withSnackbar,
  // graphql(POPULAR_TRENDS_QUERY, {
  graphql(TRENDS_BY_USER_ID_QUERY, {
    options: ownProps => {
      const userId = localStorage.getItem('currentUser');
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: userId,
          page: ownProps.page - 1 || 0,
          limit: 15,
        },
      };
    },
    props: ({ data, ownProps }) => {
      // let { error, refetch, loading, returnSortedPopularTrends = [] } = data;
      let { error, refetch, loading, trendsByUserId = [] } = data;
      if (error) {
        console.error(error.message);
      }
      // returnSortedPopularTrends.sort((a, b) => b.rsvp.length - a.rsvp.length)
      const result = {
        // allTrends: returnSortedPopularTrends.sort((a, b) => b.rsvp.length - a.rsvp.length),
        allTrends: trendsByUserId,
        // .sort((a, b) => b.rsvp.length - a.rsvp.length),
        refetchAllTrends: refetch,
        loadingAllTrends: loading,
      };
      return result;
    },
  }),
  graphql(TRENDS_BY_USER_ID_COUNT_NEXT_TWO_PAGES_QUERY, {
    options: ownProps => {
      const userId = localStorage.getItem('currentUser');
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: userId,
          page: ownProps.page - 1 || 0,
          limit: 15,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, trendsByUserIdCountInNextTwoPages = 0 } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        sortedPopularTrendCountNextTwoPages: trendsByUserIdCountInNextTwoPages, //doesnt match because no longer using the sort in the query
        refetchSortedPopularTrendCountNextTwoPages: refetch,
      };
      return result;
    },
  }),
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isTrendSelected: trendId =>
        state.ux.selectedTrends.find(trend => trend.id === trendId) !== undefined,
      selectedTrendIds: state.ux.selectedTrends.map(trend => trend.id),
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
      setRefetchTrends: state.ux.setRefetchTrends,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
        selectTrend: trend => dispatch(selectTrendAction(trend)),
        multiSelectTrend: trendId => dispatch(multiSelectTrendAction(trendId)),
        shiftSelectTrend: trendIds => dispatch(shiftSelectTrendAction(trendIds)),
        deselectTrends: () => dispatch(deselectTrendsAction()),
        deselectDatasets: () => dispatch(deselectDatasetsAction()),
        setRefetchTrendsTrue: () => dispatch(setRefetchTrendsTrueAction()),
        cancelRefetchTrends: () => dispatch(cancelRefetchTrendsAction()),
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const {
        selectTrend,
        multiSelectTrend,
        shiftSelectTrend,
        ...restDispatchProps
      } = dispatchProps;

      ownProps.allTrends &&
        ownProps.allTrends.map(trend => {
          trend.isSelected = stateProps.isTrendSelected(trend.id);
          return trend;
        });

      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
        handleTrendItemClick: (trend, event) => {
          if (platform.os.family === 'OS X' && event.metaKey) {
            // command key for macOS
            multiSelectTrend(trend);
          } else if (event.ctrlKey) {
            // windows
            multiSelectTrend(trend);
          } else {
            selectTrend(trend);
          }
        },
        handleShiftClick: ids => {
          shiftSelectTrend(ids);
        },
      };
    },
  ),

  // Mutation
  graphql(INSERT_TREND, {
    props: ({ mutate, ownProps }) => {
      return {
        insertTrend: async (userId, name, details, date, location) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                name: name,
                details: details,
                date: date,
                location: location,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
  // Mutation
  graphql(RSVP_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        rsvp: async trendId => {
          try {
            const response = await mutate({
              variables: {
                userId: ownProps.currentUser,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(STOP_TRACKING_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        stopTracking: async (userId, trendId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(DELETE_TREND_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        deleteTrend: async (userId, trendId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
)(AllTrends);
