import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatasetCard from '../cards/DatasetCard';
import DeleteDatasetModal from '../modal/DeleteDatasetModal';
import CreateDatasetDialog from '../modal/CreateDatasetDialog';
import { Grid, Loader, Icon, Pagination } from 'semantic-ui-react';

class AllDatasets extends Component {
  static propTypes = {
    density: PropTypes.number,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        name: PropTypes.string,
        details: PropTypes.string,
        date: PropTypes.string,
        // location: PropTypes.string,
      }),
    ),
    filter: PropTypes.bool,
    refetchDatasets: PropTypes.func.isRequired,
    loadingDatasets: PropTypes.bool,
  };

  static defaultProps = {
    page: 1,
  };

  constructor(props) {
    super(props);
    this.state = {
      datasetModalOpen: false,
      deleteModalOpen: false,
      transition: false,
    };
  }

  componentDidUpdate = async () => {
    if (this.props.setRefetchDatasets) {
      await this.props.refetchDatasets();
      await this.props.cancelRefetchDatasets();
    }
  };

  toggleDatasetModal = dataset => {
    this.setState(prevState => ({
      datasetForModal: dataset,
      datasetModalOpen: !prevState.datasetModalOpen,
    }));
  };

  toggleDeleteModal = dataset => {
    this.setState(prevState => ({
      datasetForModal: dataset,
      deleteModalOpen: !prevState.deleteModalOpen,
    }));
  };

  deleteDataset = async (currentUser, datasetId) => {
    await this.props.deleteDataset(currentUser, datasetId);
    await this.props.deselectDatasets();
    await this.props.refetchDatasets();
  };

  handleDatasetClick = (selectedDataset, event) => {
    event.stopPropagation();
    this.props.deselectTrends(); // deselect trends
    const id = selectedDataset.id;
    if (this.props.selectedDatasetIds.length === 0 || !event.shiftKey) {
      this.props.handleDatasetItemClick(selectedDataset, event);
    } else {
      let selectedDatasets = [];
      let backSet = [];
      let selectionState = 0;
      this.props.datasets.forEach(dataset => {
        // eslint-disable-next-line default-case
        switch (selectionState) {
          // find for start of selection
          case 0:
            if (dataset.isSelected) {
              selectionState = 1;
            }
            if (dataset.id === id) {
              selectedDatasets.push(dataset);
              selectionState = 2;
            }
            if (dataset.isSelected && dataset.id === id) {
              selectionState = 3;
            }
            break;
          // look for click
          case 1:
            selectedDatasets.push(dataset);
            if (dataset.id === id) {
              selectionState = 3;
            }
            break;
          // look for end of selection
          case 2:
            if (dataset.isSelected === false) {
              backSet.push(dataset);
              selectionState = 3;
              return;
            }
            selectedDatasets.push(dataset);
            break;
          // selection done but keep track of datasets in case user selecting backwards right to left
          case 3:
            backSet.push(dataset);
            if (dataset.isSelected) {
              selectedDatasets = selectedDatasets.concat(backSet);
              backSet = [];
            }
        }
      });
      // dont add ids that already exist in selected datasets:
      selectedDatasets = selectedDatasets.filter(dataset => {
        return this.props.selectedDatasetIds.indexOf(dataset.id) === -1;
      });
      // // console.log('selectedDatasets', selectedDatasets);
      this.props.handleShiftDatasetClick(selectedDatasets);
    }
  };

  filterList = event => {
    let items = this.props.datasets;
    items = items.filter(item => {
      return item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    this.setState({ allDatasetsFiltered: items });
  };

  getTotalPages() {
    const itemsNextTwoPages = this.props.datasetCountInNextTwoPages;
    const pagesLeftCount = itemsNextTwoPages / 15;
    let pagesLeft = 0;

    if (pagesLeftCount > 1) {
      pagesLeft = 2;
    }
    if (pagesLeftCount < 1) {
      pagesLeft = 1;
    }
    if (pagesLeftCount === 1) {
      pagesLeft = 1;
    }
    if (pagesLeftCount === 0) {
      pagesLeft = 0;
    }
    if (itemsNextTwoPages < 15) {
      pagesLeft = 0;
    }
    return this.props.page + pagesLeft;
  }

  componentWillUnmount = async () => {
    await this.props.deselectDatasets();
  };

  render() {
    // // console.log('setRefetchDatasets', this.props.setRefetchDatasets);
    return (
      <React.Fragment>
        <Grid.Row
          columns={this.props.density || 5}
          style={{ justifyContent: 'center', height: '95%' }}
        >
          {this.props.loadingDatasets && <Loader name="spinner" active content="Loading" />}
          {!this.props.loadingDatasets && this.props.datasets.length === 0 && (
            <Loader
              name="spinner"
              active
              content="It seems like you haven't created any datasets"
            />
          )}
          {!this.props.loadingDatasets &&
            this.props.datasets.length > 0 &&
            this.props.datasets.map(dataset => (
              <Grid.Column style={{ marginBottom: '30px' }} key={dataset.id}>
                <DatasetCard
                  dataset={dataset}
                  selectDataset={this.handleDatasetClick}
                  deselectDatasets={this.props.deselectDatasets}
                  isSelected={dataset.isSelected}
                  loadingDatasets={this.props.loadingDatasets}
                  toggleDatasetModal={e => this.toggleDatasetModal(dataset)}
                  toggleDeleteModal={e => this.toggleDeleteModal(dataset)}
                  stopTracking={this.stopTracking}
                  transition={this.state.transition}
                  selectedDatasetIds={this.props.selectedDatasetIds}
                />
              </Grid.Column>
            ))}
        </Grid.Row>
        <Grid.Row style={{ justifyContent: 'center' }}>
          <React.Fragment>
            <Pagination
              size="huge"
              // inverted
              color="blue"
              activePage={this.props.page}
              onPageChange={this.props.handlePaginationChange}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={this.getTotalPages()}
            />
          </React.Fragment>
        </Grid.Row>

        <CreateDatasetDialog
          open={this.props.isCreateDatasetDialogOpen}
          toggle={this.props.toggleCreateDatasetDialog}
          refetchDatasets={this.props.refetchDatasets}
        />
        <DeleteDatasetModal
          dataset={this.state.datasetForModal}
          toggle={this.toggleDeleteModal}
          open={this.state.deleteModalOpen}
          onDelete={() => this.deleteDataset(this.props.currentUser, this.state.datasetForModal.id)}
        />
      </React.Fragment>
    );
  }
}

export default AllDatasets;
