import React, { Component } from 'react';
import {
  Grid,
  Button,
  Modal,
  Form,
  Input,
  Message,
  Accordion,
  Icon,
  Card,
  Label,
  Dropdown,
} from 'semantic-ui-react';
import axios from 'axios';
import withSnackbar from '../hoc/withSnackbar';
import {
  Chart,
  Line,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
} from 'react-chartjs-2';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import AutoSuggestTickers from './AutoSuggest.js';
import AutoSuggestDatasetsContainer from './AutoSuggestDatasetsContainer';
import styles from './Dashboard.module.css';
import StockChart from './stockCharts';
import { getData as parseData } from './stockCharts/utils';
import FUNCTIONAL_GET_CRYPTO_PRICE_ACTION from '../graphql/mutations/functionalGetCryptoPriceAction.graphql';
import {
  setDashboardItem as setDashboardItemAction,
  removeDashboardItem as removeDashboardItemAction,
  refetchOnRangeOrIntervalChange as refetchOnRangeOrIntervalChangeAction,
  cancelRefetchOnRangeOrIntervalChange as cancelRefetchOnRangeOrIntervalChangeAction,
} from '../redux/actions';
import { chart, Client } from 'iexjs';
import Drawer from './Drawer';
import RandomColor from 'randomcolor';
import equal from 'fast-deep-equal/react';
import zoom from 'chartjs-plugin-zoom';
import randomstring from 'randomstring';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import CHART_DATA_BY_TICKER_QUERY from '../graphql/queries/chartDataByTicker.graphql';
import graphqlFunctional from '../../utils/graphqlFunctional';
import { AdvancedChart } from 'react-tradingview-embed';

const strings = [
  'ema',
  'sma',
  'macd',
  'rsi',
  'wma',
  'tma',
  'bollingerBand',
  'heikinAshi',
  'kagi',
  'pointAndFigure',
  'renko',
  'atr',
  'stochasticOscillator',
  'forceIndex',
  'sar',
  'elderRay',
  'change',
  'elderImpulse',
  'compare',
];
const intervalOptions = [
  { key: '1m', text: '1 Minute', value: '1m' },
  { key: '5m', text: '5 Minutes', value: '5m' },
  { key: '15m', text: '15 Minutes', value: '15m' },
  { key: '1d', text: '1 Day', value: '1d' },
  { key: '1w', text: '1 Week', value: '1w' },
];
const rangeOptions = [
  { key: '1d', text: '1 Day', value: '1d' },
  { key: '5d', text: '5 Days', value: '5d' },
  { key: '1m', text: '1 Month', value: '1m' },
  { key: '3m', text: '3 Months', value: '3m' },
  { key: '6m', text: '6 Months', value: '6m' },
  { key: '1y', text: '1 Year', value: '1y' },
  { key: '2y', text: '2 Years', value: '2y' },
  { key: '5y', text: '5 Years', value: '5y' },
  // { key: '10y', text: '10 Years', value: '10y' },
];

class DashboardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Line: false,
      StockChart: true,
      open: false,
      ticker: 'AAPL',
      dropdownLinks: [],
      activeLinkIndex: null,
      width: 0,
      height: 0,
      stockChartData: [],
      range: '3m',
      interval: '1d',
      isCrypto: false,
      switches: strings.reduce((acc, str) => ({ ...acc, [str]: false }), {}),
      // To avoid unnecessary update keep all options in the state.
      chartOptions: {
        type: 'spline',
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%Y-%m}',
          },
        },
        series: [{ data: [1, 2, 3] }],
        plotOptions: {
          series: {
            point: {
              events: {
                // mouseOver: this.setHoverData.bind(this),
              },
            },
          },
        },
      },
      hoverData: null,
    };

    this.container = React.createRef();
    const token = 'pk_71dba0269bf7441ebfd3890b5a712d65';
    const version = 'v1';
    this.client = new Client({ api_token: token, version: version });
  }

  static propTypes = {
    options: PropTypes.object,
    chartData: PropTypes.object,
    dashboardItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        range: PropTypes.string,
        interval: PropTypes.string,
      }),
    ),
    cachedDatasets: PropTypes.arrayOf(
      PropTypes.shape({
        dashboardItemId: PropTypes.string,
        datasetId: PropTypes.string,
        datasetName: PropTypes.string,
        datasetStats: PropTypes.array,
      }),
    ),
  };

  setHoverData = e => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });
  };

  updateSeries = () => {
    // The chart is updated only with new options.
    this.setState({
      chartOptions: {
        series: [{ data: [Math.random() * 5, 2, 1] }],
      },
    });
  };

  formatNumeral(x) {
    return numeral(x).format('0[.]0 a');
  }

  switchChartStyle = () => {
    this.setState(prevState => ({
      Line: !prevState.Line,
      StockChart: !prevState.StockChart,
    }));
  };

  convertToTsv = data => {
    // https://stackoverflow.com/questions/53021604/convert-array-of-objects-to-tsv-format-using-javascript-no-online-tools
    // grab the column headings (separated by tabs)
    const headings = Object.keys(data[0]).join('\t');
    // iterate over the data
    const rows = data
      .reduce((acc, c) => {
        // for each row object get its values and add tabs between them
        // then add them as a new array to the outgoing array
        return acc.concat([Object.values(c).join('\t')]);

        // finally joining each row with a line break
      }, [])
      .join('\n');
    const TSV = `${headings}\n${rows}`;
    // // console.log('TSV: ', TSV);
    return TSV;
  };

  // getData must ALWAYS be called with the range argument, either with a new range, or the
  // current range state as this function is multi-purpose and manages range, crypto data,
  // stonk data, and chart data-mappings:
  getData = async (ticker, range, interval, isCrypto) => {
    // console.log('isCrypto', isCrypto);

    // isCrypto defaults to false
    if (isCrypto) {
      // todo: connect to coinMarketCap api:
      let tickerPairArr = ticker.split('');
      tickerPairArr.splice(3, 0, ',');
      const symbol = tickerPairArr.join('');
      // console.log('symbol: ', symbol);
      // valid intervals:
      // "yearly" "monthly" "weekly" "daily" "hourly" "5m" "10m" "15m" "30m" "45m" "1h" "2h"
      // "3h" "4h" "6h" "12h" "24h" "1d" "2d" "3d" "7d" "14d" "15d" "30d" "60d" "90d" "365d"
      const coon = await this.props.functionalGetCryptoPriceAction(symbol, '7d', 56, null);
      // console.log('COON MARKET CAP RESPONSE', JSON.parse(coon.data.functionalGetCryptoPriceAction));

      this.setState({
        ticker: ticker,
        range: range,
        chartData: {
          labels: labels,
          datasets: [
            {
              label: `${this.state.ticker.toUpperCase()} Price`,
              backgroundColor: '#e8e8e8',
              borderColor: '#1b91f2',
              borderWidth: 1,
              hoverBackgroundColor: '#02ff24',
              hoverBorderColor: '#fff',
              // data: datas,
            },
          ],
        },
        // stockChartData: stockChartData,
      });
      // console.log('data flow looking good if you selected a crypto thing...');
    } else {
      const token = 'pk_71dba0269bf7441ebfd3890b5a712d65';
      const version = 'v1';
      // also look at this if react-stocksharts gives too much trouble https://canvasjs.com/react-stockcharts/stockchart-date-time-axis-react/
      // https://github.com/iexcloud/iexjs/blob/3716b70b29652410b5cdde559cf9641db94c2501/src/js/common/checks.js

      // supported ranges:
      // 1d: 1 day
      // 5d: 5 days
      // 1m: 1 month
      // 3m: 3 months
      // 6m: 6 months
      // 1y: 1 year (default)
      // 2y: 2 years
      // 5y: 5 years
      // 10y: 10 years

      // supported intervals:
      // 1m: 1 minute
      // 5m: 5 minutes
      // 15m: 15 minutes
      // 1d: 1 day (default)
      // 1w: 1 week

      const chartData =
        this.state.range &&
        (await chart(
          { symbol: ticker, range: this.state.range, interval: interval },
          { token, version },
        ).then(res => {
          console.log('charter', res);
          return res;
        }));

      // const chartDataQuery = await graphqlFunctional.query({
      //   query: CHART_DATA_BY_TICKER_QUERY,
      //   variables: {
      //     ticker: ticker,
      //     range: range,
      //   },
      // });

      const tsvConversion = this.convertToTsv(chartData);
      const stockChartData = parseData(tsvConversion);

      const datas = [];
      for (let i = 0; i < stockChartData.length; i++) {
        console.log('chart data DATE =========', chartData[i].date);
        datas.push([Date.parse(chartData[i].date), chartData[i].close]);
      }
      const tickInterval = this.getTickInterval(this.state.interval);
      this.setState({
        ticker: ticker,
        range: range,
        chartOptions: {
          chart: {
            type: 'spline',
            height: 700,
            zoomType: 'x',
          },
          title: {
            text: this.state.ticker,
          },
          subtitle: {
            text: 'Sentiment / Stock Price Coorelation',
          },
          xAxis: {
            type: 'datetime',
            // dateTimeLabelFormats: {
            //   // don't display the year
            // },
            title: {
              text: 'Date',
            },
          },
          yAxis: [
            {
              // Primary yAxis
              labels: {
                format: 'Price: {value}',
                style: {
                  color: Highcharts.getOptions().colors[1],
                },
              },
              title: {
                text: 'Close Price',
                style: {
                  color: Highcharts.getOptions().colors[1],
                },
              },
            },
            {
              // Secondary yAxis
              title: {
                text: 'Sentiment',
                style: {
                  color: '#000000',
                },
              },
              labels: {
                format: '{value}',
                style: {
                  color: '#000000',
                },
              },
              opposite: true,
              min: 0, // set the minimum value
              max: 1,
            },
          ],
          xAxis: [
            {
              title: {
                text: 'Stock Price Date',
                style: {
                  color: '#000000',
                },
              },
              type: 'datetime',
              tickInterval: tickInterval,
              labels: {
                formatter: function () {
                  return Highcharts.dateFormat('%Y-%m-%d', this.value);
                },
              },
              min: this.state.chartOptions.series[0].data[0][0],
              max: this.state.chartOptions.series[0].data[
                this.state.chartOptions.series[0].data.length - 1
              ][0],
            },
            {
              title: {
                text: 'Sentiment Collection Date',
                style: {
                  color: '#000000',
                },
              },
              type: 'datetime',
              opposite: true, // set the second x-axis to be on the opposite side of the chart
              tickInterval: tickInterval,
              labels: {
                formatter: function () {
                  return Highcharts.dateFormat('%Y-%m-%d', this.value);
                },
              },
              min: this.state.chartOptions.series[0].data[0][0],
              max: this.state.chartOptions.series[0].data[
                this.state.chartOptions.series[0].data.length - 1
              ][0],
            },
          ],
          tooltip: {
            formatter: function () {
              const date = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x);
              const value = this.y;
              return '<b>' + date + '</b><br/>' + value;
            },
          },
          plotOptions: {
            series: {
              marker: {
                enabled: true,
                radius: 2.5,
              },
              point: {
                events: {
                  // mouseOver: this.setHoverData.bind(this),
                },
              },
            },
            bar: {
              pointWidth: 25, // Increase the width of the bars
            },
          },
          series: [
            {
              name: ticker,
              yAxis: 0,
              xAxis: 0,
              data: datas,
              color: '#19d14a',
              superType: 'stockData',
            },
          ],
        },
        stockChartData: stockChartData,
      });
      this.getDropdownLinks();
    }
  };

  handleChartRangeChange = async (range, interval) => {
    this.setState({
      Line: true,
      StockChart: false,
      range: range,
      interval: interval,
    });
    const currentDashboardObject = this.props.dashboardItems.find(
      i => i.id === this.state.dashboardItemId,
    );
    // range / interval example:
    // const initialDashboardItem = {
    //   id: "XXXXXXXXXXXXXXXXX",
    //   range: '3m',
    //   interval: '15m',
    // };
    const newDashboardItem = {
      id: this.state.dashboardItemId,
      range: range,
      interval: interval,
    };
    await this.props.removeDashboardItem(this.state.dashboardItemId);
    await this.props.setDashboardItem(newDashboardItem);
    console.log('currentDashboardObject', currentDashboardObject);
    await this.getData(this.state.ticker, range, interval, this.state.isCrypto);
    // set refetch
    this.props.refetchOnRangeOrIntervalChange();
  };

  getDropdownLinks = async () => {
    const req = {
      ticker: this.state.ticker,
    };
    let uri;
    if (process.env.NODE_ENV === 'production') {
      uri = 'https://testing.media:3001';
    } else if (process.env.NODE_ENV === 'development') {
      uri = 'http://localhost:3001';
    }
    const response = await axios.post(`${uri}/api/stocks/getStockNews`, req);
    let arr = [];
    response.data.map(mod => {
      arr.push({
        source: mod.source,
        headline: mod.headline,
        summary: mod.summary,
        url: mod.url,
        image: mod.image,
      });
      return null;
    });

    this.setState({ dropdownLinks: arr });
  };

  show = size => () => this.setState({ size, open: true });
  close = () => this.setState({ open: false });
  showLinks = () => this.setState({ linksOpen: true });
  closeLinks = () => this.setState({ linksOpen: false });

  handleLinkClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeLinkIndex } = this.state;
    const newIndex = activeLinkIndex === index ? -1 : index;
    this.setState({ activeLinkIndex: newIndex });
  };

  handleDismiss = () => {
    this.setState({ changeMessage: false });
  };

  selectSuggestion = (value, isCrypto) => {
    this.getData(value, this.state.range, this.state.interval, isCrypto);
  };

  componentDidMount = async (prevState, nextState) => {
    const generateId = () => {
      return randomstring.generate({
        length: 17,
        charset: '23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz',
      });
    };
    const dashboardItemId = generateId();
    const initialDashboardItem = {
      id: dashboardItemId,
      range: '3m',
      interval: '1d',
    };
    if (!this.state.dashboardItemId) {
      await this.props.setDashboardItem(initialDashboardItem);
    }

    this.getData('AAPL', '1y');
    // todo: maybe debounce the window resize...
    window.addEventListener('resize', () => {
      this.setState({
        width: this.container.clientWidth,
        height: this.container.clientWidth,
      });
    });
    window.onresize = () => {
      this.setState({
        width: this.container.clientWidth,
        height: this.container.clientHeight,
      });
    };

    this.setState({
      width: this.container.clientWidth,
      height: this.container.clientHeight,
      dashboardItemId: dashboardItemId,
    });

    Chart.register(zoom, LinearScale, PointElement, Tooltip, Legend, TimeScale);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.numberOfDashboardItems !== this.props.numberOfDashboardItems) {
      this.setState({
        width: this.container.clientWidth,
        height: this.container.clientWidth,
      });
    }

    if (!equal(prevProps.cachedDatasets, this.props.cachedDatasets)) {
      this.addDatasetToChartDatasets();
    }
  };

  getTickInterval = range => {
    switch (range) {
      case '1m':
        return 60 * 1000; // 1 minute
      case '5m':
        return 5 * 60 * 1000; // 5 minutes
      case '15m':
        return 15 * 60 * 1000; // 15 minutes
      case '1w':
        return 7 * 24 * 3600 * 1000; // 1 week
      default:
        return 24 * 3600 * 1000; // 1 day (default)
    }
  };

  addDatasetToChartDatasets = () => {
    let currentChartDatasets = this.state.chartOptions.series;
    let thisChartCachedDatasetStats = this.props.cachedDatasets.filter(
      i => i.dashboardItemId === this.state.dashboardItemId,
    );
    const cachedDatasetNames = thisChartCachedDatasetStats.map(dataset => dataset.datasetName);

    currentChartDatasets = currentChartDatasets.filter(dataset => {
      return cachedDatasetNames.includes(dataset.name) || dataset.superType === 'stockData';
    });

    for (let i = 0; i < thisChartCachedDatasetStats.length; i++) {
      let newDataPoints = [];
      if (thisChartCachedDatasetStats[i].datasetStats[0]) {
        // the dataset stats need to MATCH the TIME INTERVAL from the main STOCK CHART eg. 5m, 1y, 1w, and return the appropriate number of plots
        for (const item of thisChartCachedDatasetStats[i].datasetStats) {
          const date = parseInt(item.date);
          newDataPoints.push([date, item.avgScore]);
        }
        const currentDatasetName = thisChartCachedDatasetStats[i].datasetName;
        const newDatasetToAdd = {
          type: 'column',
          name: currentDatasetName,
          yAxis: 1,
          xAxis: 1,
          data: newDataPoints,
          color: '#000',
          plotOptions: {
            bar: {
              opacity: 0.8,
              pointWidth: 25,
            },
          },
        };
        if (!currentChartDatasets.find(i => i.label === currentDatasetName)) {
          currentChartDatasets.push(newDatasetToAdd);
        }
      }
    }
    const tickInterval = this.getTickInterval(this.state.interval);
    const sortedChartDatasets = currentChartDatasets.sort((a, b) => {
      return a[0] - b[0];
    });
    this.setState({
      chartOptions: {
        chart: {
          type: 'spline',
          height: 700,
          zoomType: 'x',
        },
        title: {
          text: this.state.ticker,
        },
        subtitle: {
          text: 'Sentiment / Stock Price Coorelation',
        },
        yAxis: [
          {
            // Primary yAxis
            title: {
              text: 'Close Price',
              style: {
                color: Highcharts.getOptions().colors[1],
              },
              labels: {
                format: 'Price: {value}',
                style: {
                  color: Highcharts.getOptions().colors[1],
                },
              },
            },
          },
          {
            // Secondary yAxis
            title: {
              text: 'Sentiment',
              style: {
                color: '#000000',
              },
            },
            labels: {
              format: '{value}',
              style: {
                color: '#000000',
              },
            },
            opposite: true,
            min: 0, // set the minimum value
            max: 1,
          },
        ],
        xAxis: [
          {
            title: {
              text: 'Stock Price Date',
              style: {
                color: '#000000',
              },
            },
            type: 'datetime',
            tickInterval: tickInterval,
            labels: {
              formatter: function () {
                return Highcharts.dateFormat('%Y-%m-%d', this.value);
              },
            },
            min: this.state.chartOptions.series[0].data[0][0],
            max: this.state.chartOptions.series[0].data[
              this.state.chartOptions.series[0].data.length - 1
            ][0],
          },
          {
            title: {
              text: 'Sentiment Collection Date',
              style: {
                color: '#000000',
              },
            },
            type: 'datetime',
            opposite: true, // set the second x-axis to be on the opposite side of the chart
            tickInterval: tickInterval,
            labels: {
              formatter: function () {
                return Highcharts.dateFormat('%Y-%m-%d', this.value);
              },
            },
            min: this.state.chartOptions.series[0].data[0][0],
            max: this.state.chartOptions.series[0].data[
              this.state.chartOptions.series[0].data.length - 1
            ][0],
          },
        ],
        tooltip: {
          formatter: function () {
            const date = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x);
            const value = this.y;
            return '<b>' + date + '</b><br/>' + value;
          },
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true,
              radius: 2.5,
            },
            point: {
              events: {
                // mouseOver: this.setHoverData.bind(this),
              },
            },
          },
          bar: {
            pointWidth: 25, // Increase the width of the bars
          },
        },
        series: sortedChartDatasets,
      },
    });
  };

  componentWillUnmount = async () => {
    await this.props.removeDashboardItem(this.state.dashboardItemId);
    window.removeEventListener('resize', () => {
      this.setState({
        width: this.container.clientWidth,
        height: this.container.clientWidth,
      });
    });
  };

  // Function to toggle the state of a switch
  toggleSwitch = str => {
    this.setState({
      ...this.state,
      switches: {
        ...this.state.switches,
        [str]: !this.state.switches[str],
      },
    });
  };

  timeConverter = UNIX_timestamp => {
    let a = new Date(UNIX_timestamp);
    let months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let monthNumber = a.getMonth() + 1;
    let date = a.getDate().toString().padStart(2, '0');
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time = year + '-' + monthNumber + '-' + date;

    return time;
  };

  render() {
    console.log('this.props dashboarditem', this.props);
    console.log('this.state dashboard item', this.state);
    const { open, linksOpen, activeLinkIndex, width, height, chartOptions, hoverData } = this.state;
    return (
      <React.Fragment>
        {this.state.changeMessage && (
          <Message
            onDismiss={this.handleDismiss}
            success
            header={"You've selected " + this.state.ticker}
            content=""
          />
        )}

        <Grid.Column>
          <Modal size={'fullscreen'} open={open} onClose={this.close}>
            <Modal.Header>
              Visualizing {this.state.ticker && this.state.ticker.toUpperCase()}
            </Modal.Header>
            <Modal.Content>
              <div>
                <HighchartsReact
                  style={{ height: '100vh' }}
                  highcharts={Highcharts}
                  options={chartOptions}
                  // constructorType={'stockChart'}
                />
                <h3>Hovering over {hoverData}</h3>
              </div>
              <div>
                <Button
                  content="News"
                  basic
                  style={{ marginLeft: '45%' }}
                  size="medium"
                  onClick={e => {
                    this.showLinks('fullscreen');
                  }}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              {/* <Button negative>No</Button> */}
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Done"
                onClick={this.close}
              />
            </Modal.Actions>
          </Modal>
          <Modal size={'large'} open={linksOpen} onClose={this.closeLinks}>
            <Modal.Header>
              Popular news articles for: {this.state.ticker && this.state.ticker.toUpperCase()}
            </Modal.Header>
            <Modal.Content style={{ backgroundColor: 'black' }}>
              <Accordion fluid style={{ backgroundColor: 'black' }} inverted styled>
                {this.state.dropdownLinks &&
                  this.state.dropdownLinks.map((link, index) => (
                    <div key={index}>
                      <Accordion.Title
                        active={activeLinkIndex === index}
                        index={index}
                        onClick={this.handleLinkClick}
                      >
                        <Icon name="dropdown" />
                        {link.headline}
                      </Accordion.Title>
                      <Accordion.Content active={activeLinkIndex === index}>
                        <a href={link.url} target="_blank">
                          {'Link ->'}
                        </a>
                        <p style={{ color: '#39FF14', fontweight: '900' }}>
                          Source: ({link.source})
                        </p>
                        <p style={{ color: '#39FF14', fontweight: '900' }}>{link.summary}</p>
                      </Accordion.Content>
                    </div>
                  ))}
              </Accordion>
            </Modal.Content>
            <Modal.Actions>
              {/* <Button negative>No</Button> */}
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Done"
                onClick={this.closeLinks}
              />
            </Modal.Actions>
          </Modal>

          <Card
            id={`drawer-containerid${this.props.dashboardItemIndex}`}
            style={{ position: 'relative', padding: '15px' }}
            fluid
          >
            <div>
              <Drawer
                dashboardItemIndex={this.props.dashboardItemIndex}
                handleChange={this.toggleSwitch}
                switches={this.state.switches}
              />
              {/* <CustomMenu handleChange={e => this.handleIndicatorChange(e)} /> */}
              <Form>
                <Form.Field responsive="true">
                  <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                    <span className={styles.inputSpan}>
                      {/* here implement a debounce method for querying db for datsets */}
                      {/* then, via that datasetID, ask for the associated generated metrics. */}
                      {/* <Input
                    icon="search"
                    placeholder="Overlay a Dataset"
                    inverted
                    style={{ marginBottom: '4px', height: '25px' }}
                  /> */}
                      <AutoSuggestDatasetsContainer dashboardItemId={this.state.dashboardItemId} />
                    </span>
                  </div>
                  <div>
                    <span className={styles.inputSpan2}>
                      <AutoSuggestTickers
                        selectSuggestion={this.selectSuggestion}
                        // updateStats={this.getData}
                      />
                    </span>
                  </div>
                </Form.Field>
              </Form>
            </div>
            <div>
              {this.state.Line && (
                <div>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    // constructorType={'stockChart'}
                  />
                  <h3>Hovering over {hoverData}</h3>
                </div>
              )}
            </div>

            <div style={{ margin: '15px' }}>
              <h2>{this.state.ticker}</h2>
            </div>

            <div ref={e => (this.container = e)} style={{ display: 'flex', width: '100%' }}>
              {this.state.StockChart && (
                <StockChart
                  width={width}
                  data={this.state.stockChartData}
                  switches={this.state.switches}
                />
              )}
            </div>
            <span>
              <AdvancedChart
                widgetProps={{
                  interval: this.state.interval,
                  theme: 'light',
                  width: '100%',
                  symbol: this.state.ticker + 'USD',
                  studies: [
                    'MACD@tv-basicstudies',
                    'StochasticRSI@tv-basicstudies',
                    'TripleEMA@tv-basicstudies',
                  ],
                }}
              />
            </span>

            <Form>
              <Form.Field
                style={{ display: 'flex', justifyContent: 'space-around', paddingRight: '20px' }}
              >
                <span
                  style={{
                    float: 'left',
                  }}
                >
                  <Button
                    onClick={this.switchChartStyle}
                    content="View Datasets"
                    basic
                    compact
                    // inverted
                    size="mini"
                  />
                </span>

                <span
                  style={{
                    float: 'left',
                    display: 'flex',
                  }}
                >
                  <Label>
                    Range{' '}
                    <Dropdown
                      compact
                      selection
                      options={rangeOptions}
                      value={this.state.range}
                      onChange={(e, data) =>
                        this.handleChartRangeChange(data.value, this.state.interval)
                      }
                    />
                  </Label>
                </span>

                <span
                  style={{
                    float: 'left',
                    display: 'flex',
                  }}
                >
                  <Label>
                    Interval{' '}
                    <Dropdown
                      compact
                      selection
                      options={intervalOptions}
                      value={this.state.interval}
                      onChange={(e, data) =>
                        this.handleChartRangeChange(this.state.range, data.value)
                      }
                    />
                  </Label>
                </span>

                <span
                  style={{
                    float: 'right',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Button content="Expand" compact size="mini" onClick={this.show('fullscreen')} />
                </span>
              </Form.Field>
            </Form>
          </Card>
        </Grid.Column>
      </React.Fragment>
    );
  }
}

export default compose(
  withSnackbar,
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
      cachedDatasets: state.ux.setCachedDatasets,
      dashboardItems: state.ux.dashboardItems.dashboardItems,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add in dispatch action functions here
      setDashboardItem: item => dispatch(setDashboardItemAction(item)),
      removeDashboardItem: item => dispatch(removeDashboardItemAction(item)),
      refetchOnRangeOrIntervalChange: () => dispatch(refetchOnRangeOrIntervalChangeAction()),
      cancelRefetchOnRangeOrIntervalChange: () =>
        dispatch(cancelRefetchOnRangeOrIntervalChangeAction()),
    }),
  ),
  graphql(FUNCTIONAL_GET_CRYPTO_PRICE_ACTION, {
    props: ({ mutate, ownProps }) => {
      return {
        functionalGetCryptoPriceAction: async (symbol, interval, count, convert) => {
          try {
            const response = await mutate({
              variables: {
                symbol: symbol,
                interval: interval,
                count: count,
                convert: convert,
              },
            });
            ownProps.snackbar('custom-error', 'success', {
              message: `Price action fetched for ${symbol} - ${interval}...`,
            });
            return response;
          } catch (e) {
            ownProps.snackbar('custom-error', 'error', {
              message: `Error fetching stats for "${symbol}" in "${interval}"...`,
            });
            console.error(e.message);
          }
        },
      };
    },
  }),
)(DashboardItem);
