import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import {
  openSnackbar as openSnackbarAction,
  logIn as logInAction,
  logOut as logOutAction,
} from './redux/actions';
import Home from './home/Home';
import UsersRoot from './users/UsersRoot';
import AddKeyword from './createTrend/addKeyword';
import Navbar from './navigation/navbar.js';
import Rsvp from './rsvp/rsvp';
import Dashboard from './dashboard/Dashboard';
import Snackbar from './uxElements/Snackbar';
import DiscoverTrends from './discoverTrends/DiscoverTrends';
import Signin from './users/Signin';
import LoggedOut from './home/LoggedOut';
import Datasets from './datasets/Datasets';
import Dataset from './datasets/Dataset';
import AssetsSelectedMenu from './uxElements/AssetsSelectedMenu';
import Reddit from './reddit/reddit_api';
import './app.css';

class App extends Component {
  static propTypes = {
    logIn: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const user = localStorage.getItem('currentUser');
    if (user) {
      this.props.logIn(true, user);
    }
  }

  componentWillReceiveProps() {
    const user = localStorage.getItem('currentUser');
    if (user) {
      this.props.logIn(true, user);
    } else if (!user || !this.props.isLoggedIn) {
      this.props.logOut();
    }
  }

  componentDidUpdate(prevProps) {
    const user = localStorage.getItem('currentUser');
    if (prevProps.isLoggedIn !== this.props.isLoggedIn || !user) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div
        style={{
          marginTop: '80px',
          marginLeft: '230px',
          // paddingRight: '3%',
        }}
      >
        <AssetsSelectedMenu />
        <Snackbar />
        {this.props.isLoggedIn && (
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/Dashboard" component={Dashboard} />
              <Route exact path="/datasets" component={Datasets} />
              <Route exact path="/viewDataset" component={Dataset} />
              <Route exact path="/discoverTrends" component={DiscoverTrends} />
              <Route exact path="/newtrend" component={AddKeyword} />
              <Route exact path="/mytrends" component={Rsvp} />
              <Route exact path="/signup" component={UsersRoot} />
              <Route exact path="/reddit" component={Reddit} />
            </Switch>
          </div>
        )}
        {!this.props.isLoggedIn && (
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/" component={LoggedOut} />
              <Route exact path="/Dashboard" component={LoggedOut} />
              <Route exact path="/datasets" component={LoggedOut} />
              <Route exact path="/discoverTrends" component={LoggedOut} />
              <Route exact path="/newtrend" component={LoggedOut} />
              <Route exact path="/mytrends" component={LoggedOut} />
              <Route exact path="/signup" component={UsersRoot} />
              <Route exact path="/signin" component={Signin} />
            </Switch>
          </div>
        )}
      </div>
    );
  }
}

export default // redux
connect(
  //mapStateToProps
  (state, ownProps) => ({
    // application-wide login auth state
    isLoggedIn: state.ux.currentUser.isLoggedIn,
  }),
  // mapDispatchToProps
  (dispatch, ownProps) => ({
    openSnackbar: (message, severity) => {
      if (message instanceof Error) {
        message = message.message;
      }
      dispatch(openSnackbarAction(message, severity));
    },
    logIn: (isLoggedIn, currentUser) => {
      dispatch(logInAction(isLoggedIn, currentUser));
    },
    logOut: () => {
      dispatch(logOutAction());
    },
  }),

  //mergeProps
  (stateProps, dispatchProps, ownProps) => {
    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
    };
  },
)(App);
