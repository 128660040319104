import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import withSnackbar from '../hoc/withSnackbar';
import PropTypes from 'prop-types';
import { clearCurrentlyViewingDataset as clearCurrentlyViewingDatasetAction } from '../redux/actions';
import { Bar, Line, Pie, Polar, Bubble, Radar, Mixed, Doughnut } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import DATASET_BY_ID from '../graphql/queries/getDatasetById.graphql';
import TRENDS_BY_DATASET_ID from '../graphql/queries/trendsByDatasetId.graphql';
import GENERATE_AMAZON_STATS_MUTATION from '../graphql/mutations/generateAmazonStats.graphql';
import CALCULATE_STATS_MUTATION from '../graphql/mutations/calculateStatistics.graphql';
import START_ML_MUTATION from '../graphql/mutations/startMachineLearning.graphql';
import UPDATE_DATASET_SETTINGS_MUTATION from '../graphql/mutations/updateDatasetSettings.graphql';
import UPDATE_DATASET_NOTES_MUTATION from '../graphql/mutations/updateDatasetNotes.graphql';
import STATS_BY_DATASET_ID from '../graphql/queries/twitterStatsByDatasetId.graphql';
import STATS_BY_DATASET_ID_FLAT from '../graphql/queries/statsByDatasetIdFlat.graphql';
import GET_SUBREDDITS from '../graphql/queries/getSubReddits.graphql';
import UPDATE_DATASET_SUBREDDIT_MUTATION from '../graphql/mutations/updateDatasetSubreddit.graphql';
import REMOVE_TREND_FROM_DATASET_MUTATION from '../graphql/mutations/removeTrendFromDataset';
import _ from 'lodash';
import {
  Button,
  Table,
  Label,
  List,
  Segment,
  // Grid,
  Dropdown,
  Loader,
  Portal,
  Header,
  Card,
  Icon,
  Modal,
} from 'semantic-ui-react';
import Terminal from './terminal/terminal';
import classes from './Dataset.css';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Notes from './Notes';
import RandomColor from 'randomcolor';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import graphqlFunctional from '../../utils/graphqlFunctional';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

class Dataset extends Component {
  static propTypes = {
    dataset: PropTypes.shape({
      settings: PropTypes.shape({
        readFrequency: PropTypes.string,
        readCadence: PropTypes.string,
        readDuration: PropTypes.number,
        readStartTime: PropTypes.string,
        readEndTime: PropTypes.string,
      }),
    }),
  };

  notesEditor = React.createRef();
  stickyTableHeaderRef = React.createRef();

  constructor(props) {
    // document.body.style = 'background: #c0c0c0;';
    super(props);
    this.state = {
      startedML: false,
      doughnutData: {
        labels: ['Negative', 'Positive'],
        datasets: [
          {
            label: 'sample_1',
            borderWidth: 2,
            data: [0.35, 0.65],
          },
          {
            label: 'sample_2',
            borderWidth: 2,
            data: [0.4, 0.6],
          },
        ],
      },
      helpOpen: false,
      notesEditMode: false,
      notesEditMode: false,
      areNotesChanged: false,
      notesWaitMode: false,
      isSentimentBarChartModalExpanded: false,
      singleTrendDoughnutValue: null,
    };
  }

  generateStats = async () => {
    await this.props.generateAmazonStatsMutation(this.props.dataset.id);
  };

  startML = async () => {
    this.toggleMLStartedState();
    await this.props.startMachineLearningMutation(this.props.dataset.id, 500);
    await this.props.refetchDataset();
    await this.props.refetchDatasetStats();
  };

  toggleMLStartedState = () => {
    this.setState({ startedML: !this.state.startedML });
  };

  calculateStatistics = async () => {
    await this.props.calculateStatisticsMutation(this.props.dataset.id);
    await this.props.refetchDatasetStats();
    await this.props.refetchDataset();
  };

  returnReadFrequencyOptions = () => {
    let readFrequencyOptions;
    readFrequencyOptions = [
      { key: 'D', value: 'D', text: 'Daily' },
      { key: 'TD', value: 'TD', text: 'Twice Daily (eg. AM + PM)' },
      { key: 'THD', value: 'THD', text: 'Thrice Daily (eg. AM + Noon + PM)' },
      // { key: 'W', value: 'W', text: 'Weekly' },
      // { key: 'TW', value: 'TW', text: 'Twice Weekly' },
    ];
    return readFrequencyOptions;
  };

  returnReadCadenceOptions = () => {
    let readCadenceOptions;
    readCadenceOptions = [
      { key: 'H', value: 'H', text: 'Hourly' },
      { key: '30M', value: '30M', text: 'Every 30 Minutes' },
    ];
    return readCadenceOptions;
  };

  returnReadDurationOptions = () => {
    let readDurationOptions;

    if (
      this.props.dataset.settings?.readCadence &&
      this.props.dataset.settings?.readCadence === '30M'
    ) {
      // duration is in ms
      readDurationOptions = [
        { key: 0, value: 60000, text: '1 minute' },
        { key: 1, value: 300000, text: '5 minutes' },
        { key: 2, value: 900000, text: '15 minutes' },
      ];
    } else if (
      this.props.dataset.settings?.readCadence &&
      this.props.dataset.settings?.readCadence === 'H'
    ) {
      // duration is in ms
      readDurationOptions = [
        { key: 0, value: 30000, text: '30 seconds' },
        { key: 1, value: 60000, text: '1 minute' },
        { key: 2, value: 300000, text: '5 minutes' },
        { key: 3, value: 900000, text: '15 minutes' },
      ];
    } else {
      // duration is in ms
      readDurationOptions = [
        // { key: 0, value: 5000, text: '5 seconds' },
        { key: 0, value: 15000, text: '15 seconds' },
        { key: 1, value: 30000, text: '30 seconds' },
        { key: 2, value: 10000, text: '1 minute' },
        { key: 3, value: 300000, text: '5 minutes' },
        { key: 4, value: 900000, text: '15 minutes' },
        { key: 5, value: 1800000, text: '30 minutes' },
      ];
    }

    return readDurationOptions;
  };

  returnTimeZoneOptions = () => {
    let timeZoneOptions;
    timeZoneOptions = [
      { key: 'UTC-5', value: 'UTC-5', text: 'Eastern Standard Time (NYC)' },
      { key: 'UTC-6', value: 'UTC-6', text: 'Central Standard Time (Chicago)' },
      { key: 'UTC-7', value: 'UTC-7', text: 'Mountain Standard Time (Denver)' },
      { key: 'UTC-8', value: 'UTC-8', text: 'Pacific Standard Time (San Diego / LA)' },
      { key: 'UTC-9', value: 'UTC-9', text: 'Alaska Standard Time' },
      { key: 'UTC-10', value: 'UTC-10', text: 'Hawaii-Aleutian Standard Time' },
    ];
    return timeZoneOptions;
  };

  handleReadFrequencyChange = async (e, { value }) => {
    await this.props.updateDatasetSettingsMutation(this.props.currentUser, this.props.dataset.id, {
      readFrequency: value,
    });
    await this.props.refetchDataset();
  };
  handleReadCadenceChange = async (e, { value }) => {
    await this.props.updateDatasetSettingsMutation(this.props.currentUser, this.props.dataset.id, {
      readCadence: value,
    });
    await this.props.refetchDataset();
  };
  handleReadDurationChange = async (e, { value }) => {
    console.log('value: ', value);
    await this.props.updateDatasetSettingsMutation(this.props.currentUser, this.props.dataset.id, {
      readDuration: value,
    });
    await this.props.refetchDataset();
  };
  handleReadStartTimeChange = async value => {
    await this.props.updateDatasetSettingsMutation(this.props.currentUser, this.props.dataset.id, {
      readStartTime: value,
    });
    await this.props.refetchDataset();
  };
  handleReadEndTimeChange = async value => {
    await this.props.updateDatasetSettingsMutation(this.props.currentUser, this.props.dataset.id, {
      readEndTime: value,
    });
    await this.props.refetchDataset();
  };
  handleTimeZoneChange = async (e, { value }) => {
    await this.props.updateDatasetSettingsMutation(this.props.currentUser, this.props.dataset.id, {
      timeZone: value,
    });
    await this.props.refetchDataset();
  };
  handleSampleSizeChange = async (e, { value }) => {
    await this.props.updateDatasetSettingsMutation(this.props.currentUser, this.props.dataset.id, {
      sampleSize: value,
    });
    await this.props.refetchDataset();
  };

  handleCloseHelpPortal = () => this.setState({ helpOpen: false });
  handleOpenHelpPortal = () => this.setState({ helpOpen: true });

  activateNotesEditing = () => {
    !this.state.notesEditMode &&
      this.setState({
        notesEditMode: true,
      });
  };
  deactivateNotesEditing = () => {
    this.setState({
      notesEditMode: false,
      areNotesChanged: false,
    });
  };
  saveNotes = async () => {
    this.setState(
      {
        notesWaitMode: true,
      },
      async () => {
        const result = await this.props.updateDatasetNotesMutation(
          this.props.dataset.id,
          this.notesEditor.current.value,
        );
        await this.props.refetchDataset();
        this.setState(
          {
            notesWaitMode: false,
          },
          async () => {
            this.deactivateNotesEditing();
            result &&
              (await this.props.snackbar('custom-error', 'success', {
                message: 'Notes Saved.',
              }));
          },
        );
      },
    );
  };
  onNotesTextDiffers = textDiffers => {
    this.setState({
      areNotesChanged: textDiffers,
    });
  };

  removeDatasetTrend = async (trendId, trendName) => {
    await this.props.removeTrendFromDataset(trendId, this.props.dataset.id, trendName);
    await this.props.refetchDataset();
    await this.props.refetchTrendsByDatasetId();
  };

  returnTrendItems = () => {
    return (
      this.props.trendsByDatasetId.length > 0 &&
      this.props.trendsByDatasetId.map((i, index) => (
        <Table.Row key={index}>
          <Table.Cell>{i.name}</Table.Cell>
          <Table.Cell>
            Score: {i.averageScoreStandardDict?.totalScore}
            <br />
            Deviation: {i.averageScoreStandardDict?.deviation}
          </Table.Cell>
          <Table.Cell>
            <Button
              floated="left"
              icon
              labelPosition="left"
              inverted
              color="red"
              size="mini"
              onClick={() => this.removeDatasetTrend(i.id, i.name)}
            >
              <Icon name="minus" /> Remove From Dataset
            </Button>
          </Table.Cell>
        </Table.Row>
      ))
    );
  };

  toggleExpandSentimentBarChart = () => {
    this.setState({
      isSentimentBarChartModalExpanded: !this.state.isSentimentBarChartModalExpanded,
    });
  };

  handleDoughnutSelectionChange = (e, { value }) => {
    this.setState({ singleTrendDoughnutValue: value });
  };

  componentDidMount = async () => {
    await this.setBarChartData();
    this.setDatasetAvgData();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevState.singleTrendDoughnutValue !== this.state.singleTrendDoughnutValue) {
      this.setDoughutData();
    }
    if (prevProps.dataset.id !== this.props.dataset.id) {
      this.setDatasetAvgData();
    }
    if (prevProps.dataset.notes !== this.props.dataset.notes) {
      await this.props.refetchDataset();
      this.setDatasetAvgData();
    }
    if (prevProps.twitterStatsByDatasetId !== this.props.twitterStatsByDatasetId) {
      this.setBarChartData();
      this.setDatasetAvgData();
    }
  };

  setDoughutData = () => {
    const trends = this.props.trendsByDatasetId.filter(i => {
      if (this.state.singleTrendDoughnutValue.includes(i.id)) {
        return i;
      }
    });
    let doughnutData = {
      labels: ['Negative', 'Positive', 'Neutral'],
      datasets: [],
    };
    for (const trend of trends) {
      const total =
        trend.averageScoreTf?.deviation.negative + trend.averageScoreTf?.deviation.positive;
      let neutral = 0;
      if (total <= 100) {
        neutral = 100 - total;
      } else {
        neutral = 0;
      }
      doughnutData.datasets.push({
        label: trend.name,
        borderWidth: 2,
        data: [
          trend.averageScoreTf?.deviation.negative,
          trend.averageScoreTf?.deviation.positive,
          trend.averageScoreTf?.deviation.neutral,
        ],
        backgroundColor: [
          'rgba(251, 5, 46, 0.8)',
          'rgba(5, 251, 72, 0.8)',
          'rgba(18, 92, 226, 0.8)',
        ],
        hoverBorderColor: 'rgba(100, 100, 100, 0.8)',
      });
    }

    const chartOptions = {
      title: {
        display: true,
        text: 'Per-Trend Sentiment Visualization',
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            title: ttItem => ttItem[0].dataset.label,
          },
        },
      },
    };

    this.setState({
      doughnutData: doughnutData,
      chartOptions: chartOptions,
    });
  };

  setDatasetAvgData = () => {
    const total =
      this.props.dataset.averageScoreTf?.deviation.negative +
      this.props.dataset.averageScoreTf?.deviation.positive;
    let neutral = 0;
    if (total <= 100) {
      neutral = 100 - total;
    } else {
      neutral = 0;
    }
    let datasetAvgData = {
      labels: ['Negative', 'Positive', 'Neutral'],
      datasets: [
        {
          label: this.props.dataset.name,
          borderWidth: 2,
          data: [
            this.props.dataset.averageScoreTf?.deviation.negative,
            this.props.dataset.averageScoreTf?.deviation.positive,
            neutral,
          ],
          backgroundColor: [
            'rgba(251, 5, 46, 0.8)',
            'rgba(5, 251, 72, 0.8)',
            'rgba(18, 92, 226, 0.8)',
          ],
          hoverBorderColor: 'rgba(100, 100, 100, 0.8)',
        },
      ],
    };
    // datasetAvgData.push();

    this.setState({ datasetAvgData: datasetAvgData });
  };

  timeConverter = UNIX_timestamp => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min + ':' + sec;

    return time;
  };

  setBarChartData = async () => {
    const average = array => array.reduce((a, b) => a + b) / array.length;
    let chartDatasets = [];
    let barChartData;
    for await (const item of this.props.twitterStatsByDatasetId) {
      let individualDatasetData = [];
      if (item.sentiment) {
        individualDatasetData.push({
          x: `${this.timeConverter(item.date / 1000)}`,
          y: item.avgScore,
        });
        let color = RandomColor();
        chartDatasets.push({
          categoryPercentage: 0.2,
          barPercentage: 0.5,
          label: this.timeConverter(item.date / 1000),
          data: individualDatasetData,
          borderColor: color,
          backgroundColor: color,
          hoverBackgroundColor: '#1b91f2',
        });
      }
    }
    barChartData = {
      // labels,
      datasets: chartDatasets,
    };
    this.setState({ barChartData: barChartData });
  };

  renderLabelDropdown = label => ({
    color: 'blue',
    content: `${label.text}`,
    icon: 'check',
  });

  handleRedditSearchChange = _.debounce(async (e, { searchQuery }) => {
    const results = await graphqlFunctional.query({
      query: GET_SUBREDDITS,
      fetchPolicy: 'network-only',
      variables: {
        queryString: searchQuery,
      },
    });
    if (results.loading) {
      this.setState({ loadingSubRedditSearch: true, isFetching: true });
    }
    // console.log('results', results.data.getSubReddits);
    const options = results.data.getSubReddits.map(item => {
      return { key: item, text: item, value: _.snakeCase(item) };
    });
    this.setState({ loadingSubRedditSearch: false, options: options, isFetching: false });
    return results.data.getSubReddits;
  }, 150);

  handleRedditDropdownChange = async (e, { value }) => {
    await this.updateDatasetSubreddit(value);
  };

  updateDatasetSubreddit = async subreddit => {
    const mutationResult = await graphqlFunctional.mutate({
      mutation: UPDATE_DATASET_SUBREDDIT_MUTATION,
      variables: {
        datasetId: this.props.dataset.id,
        subreddit: subreddit,
      },
    });
    console.log(
      'mutationResult.data.updateDatasetSubreddit',
      mutationResult.data.updateDatasetSubreddit,
    );
    await this.props.refetchDataset();
    return mutationResult.data.updateDatasetSubreddit;
  };

  handleOpenReadStartPicker = () => {
    this.setState({ readStartPickerOpen: true });
  };

  handleCloseReadStartPicker = () => {
    this.setState({ readStartPickerOpen: false });
  };

  handleOpenReadEndPicker = () => {
    this.setState({ readEndPickerOpen: true });
  };

  handleCloseReadEndPicker = () => {
    this.setState({ readEndPickerOpen: false });
  };

  render() {
    console.log('state', this.state);
    console.log('props', this.props);
    const sampleSizeOptions = [
      { key: 100, text: '100', value: 100 },
      { key: 200, text: '200', value: 200 },
      { key: 300, text: '300', value: 300 },
      { key: 400, text: '400', value: 400 },
      { key: 500, text: '400', value: 500 },
    ];
    return (
      <Box sx={{ flexGrow: 1 }} style={{ marginRight: '30px' }}>
        <h4 className={classes.chartlbl}>
          {this.props.dataset.name} {this.props.dataset.id}
        </h4>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <div className={classes.divider}></div>
          <Grid item xs={4}>
            <Item>
              <Terminal
                datasetId={this.props.dataset.id}
                started={this.state.startedML}
                toggleLogger={this.toggleMLStartedState}
              />
              {/* <Button
                disabled={false}
                onClick={this.generateStats}
                basic
                color="black"
                style={{ margin: '3px' }}
              >
                Generate Amazon Product Listings
              </Button> */}
              <Button
                disabled={false}
                onClick={this.startML}
                basic
                color="black"
                style={{ margin: '3px' }}
              >
                Start Machine Learning Ai
              </Button>
              <Button
                disabled={false}
                onClick={this.calculateStatistics}
                basic
                color="black"
                style={{ margin: '3px' }}
              >
                Calculate Stats
              </Button>

              <Button
                disabled={false}
                onClick={() =>
                  console.log(
                    'Clear Dataset Average so more items can be added to the tracking flow',
                  )
                }
                basic
                color="black"
                style={{ margin: '3px' }}
              >
                Generate Reddit Stats
              </Button>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              <div>
                <h1>Per-Keyword Sentiment</h1>
                <Dropdown
                  placeholder="Select Keyword"
                  compact
                  search
                  selection
                  fluid
                  multiple
                  closeOnChange
                  renderLabel={this.renderLabelDropdown}
                  onChange={this.handleDoughnutSelectionChange}
                  value={this.state.singleTrendDoughnutValue && this.state.singleTrendDoughnutValue}
                  options={this.props.keywordOptions}
                />
              </div>
              <div>
                {!this.state.loadingDataset && (
                  <Doughnut
                    style={{ maxHeight: '400px' }}
                    width={1}
                    height={1}
                    options={this.state.chartOptions}
                    data={this.state.doughnutData}
                  />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              {/* TODO: modfiy backend to query time-slot averages like in the
                  main dashboard instead of every single sentiment object */}
              <h1>Sentiment</h1>
              <Modal
                basic
                size="small"
                dimmer="blurring"
                open={this.state.isSentimentBarChartModalExpanded}
                onClose={this.toggleExpandSentimentBarChart}
                style={{
                  height: '100vh',
                  width: '100vw',
                  padding: '150px',
                  backgroundColor: 'rgba(15, 15, 15, 0.95)',
                }}
              >
                <Button onClick={this.toggleExpandSentimentBarChart}>Close</Button>
                {!this.props.loadingDatasetStats && this.state.barChartData && (
                  <Bar options={this.props.barChartOptions} data={this.state.barChartData} />
                )}
              </Modal>
              {!this.props.loadingDatasetStats && this.state.barChartData && (
                <Bar
                  style={{ height: '550px', maxHeight: '425px' }}
                  options={this.props.barChartOptions}
                  data={this.state.barChartData}
                />
              )}

              <div>
                <Button style={{ float: 'right' }} onClick={this.toggleExpandSentimentBarChart}>
                  Expand
                </Button>
              </div>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              <Button
                style={{ float: 'right' }}
                circular
                size="mini"
                icon="help"
                disabled={this.state.helpOpen}
                color="grey"
                onClick={this.handleOpenHelpPortal}
              />

              <Portal onClose={this.handleCloseHelpPortal} open={this.state.helpOpen}>
                <Segment
                  style={{
                    left: '40%',
                    position: 'fixed',
                    top: '15%',
                    zIndex: 1000,
                  }}
                >
                  <Header>About Dataset Settings</Header>
                  <p style={{ color: '#000', fontWeight: 700 }}>
                    Datasets require settings for what time of day and how often you want to
                    automatically fetch data.
                  </p>
                  <p style={{ color: '#000', fontWeight: 700 }}>
                    <b>Read Frequency:</b> Read frequency tells the dataset how often per week you
                    would like the data to be fetched.
                  </p>
                  <p style={{ color: '#000', fontWeight: 700 }}>
                    <b>Read Cadence:</b> Read cadence refers to the intervals within a day that you
                    would like to read data at.
                  </p>
                  <p style={{ color: '#000', fontWeight: 700 }}>
                    <b>Read Duration:</b> Read duration is the actual "data read" time within the
                    window of the read cadence within that particular day set in the read frequency
                  </p>
                  <p style={{ color: '#000', fontWeight: 700 }}>
                    <b>Start Time:</b> Start time is the time of day you would like to begin the{' '}
                    <i>Read Cadence</i> for the day set in the <i>Read Frequency</i>
                  </p>
                  <p style={{ color: '#000', fontWeight: 700 }}>
                    <b>End Time:</b> End time is the time of day you would like to end the{' '}
                    <i>Read Cadence</i> for the day set in the <i>Read Frequency</i>
                  </p>
                  <p style={{ color: '#000', fontWeight: 700 }}>
                    To close, simply click the close button or click away
                  </p>

                  <Button content="Close" negative onClick={this.handleCloseHelpPortal} />
                </Segment>
              </Portal>

              {this.props.loadingDataset ? (
                <Grid item xs={3}>
                  <Item>
                    <Loader name="spinner" active content="Loading settings..." />
                  </Item>
                </Grid>
              ) : (
                <div>
                  <h1>Settings</h1>
                  <List divided selection>
                    <List.Item>
                      {!this.props.dataset.settings?.readFrequency ? (
                        <Label basic color="red" pointing="below">
                          Please select a read frequency
                        </Label>
                      ) : (
                        <Label color="grey" horizontal>
                          Read Frequency: {this.props.dataset.settings?.readFrequency}
                        </Label>
                      )}
                      <br />
                      <Dropdown
                        style={{ margin: '12px' }}
                        onChange={this.handleReadFrequencyChange}
                        options={this.returnReadFrequencyOptions()}
                        selection
                        value={this.props.dataset.settings?.readFrequency}
                      />
                    </List.Item>
                    {/* read cadence is shown depending on if there is a readFrequency higher than daily placed */}
                    {this.props.dataset.settings?.readFrequency &&
                      this.props.dataset.settings?.readFrequency === 'D' && (
                        <List.Item>
                          {!this.props.dataset.settings?.readCadence ? (
                            <Label basic color="red" pointing="below">
                              Please select a read cadence
                            </Label>
                          ) : (
                            <Label color="grey" horizontal>
                              Read Cadence: {this.props.dataset.settings?.readCadence}
                            </Label>
                          )}
                          <br />
                          <Dropdown
                            style={{ margin: '12px' }}
                            onChange={this.handleReadCadenceChange}
                            options={this.returnReadCadenceOptions()}
                            selection
                            value={this.props.dataset.settings?.readCadence}
                          />
                        </List.Item>
                      )}
                    <List.Item>
                      {!this.props.dataset.settings?.readDuration ? (
                        <Label basic color="red" pointing="below">
                          Please select a read duration
                        </Label>
                      ) : (
                        <Label color="grey" horizontal>
                          Read Duration: {this.props.dataset.settings?.readDuration}ms
                        </Label>
                      )}
                      <br />
                      <Dropdown
                        style={{ margin: '12px' }}
                        onChange={this.handleReadDurationChange}
                        options={this.returnReadDurationOptions()}
                        selection
                        value={this.props.dataset.settings?.readDuration}
                      />
                    </List.Item>

                    <List.Item>
                      {!this.props.dataset.settings?.readStartTime ? (
                        <Label basic color="red" pointing="below">
                          Please select a start time
                        </Label>
                      ) : (
                        <Label color="grey" horizontal>
                          Start Time
                        </Label>
                      )}
                      <br />
                      <div style={{ margin: '12px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label=""
                            value={
                              this.props.dataset?.settings?.readStartTime
                                ? this.props.dataset?.settings?.readStartTime
                                : null
                            }
                            onChange={newValue => {
                              this.handleReadStartTimeChange(newValue);
                            }}
                            renderInput={params => <TextField {...params} />}
                            minutesStep={5}
                            open={this.state.readStartPickerOpen}
                            onOpen={this.handleOpenReadStartPicker}
                            onClose={this.handleCloseReadStartPicker}
                          />
                        </LocalizationProvider>
                      </div>
                    </List.Item>

                    <List.Item>
                      {!this.props.dataset.settings?.readEndTime ? (
                        <Label basic color="red" pointing="below">
                          Please select an end time
                        </Label>
                      ) : (
                        <Label color="grey" horizontal>
                          End Time
                        </Label>
                      )}
                      <br />
                      <div style={{ margin: '12px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label=""
                            value={
                              this.props.dataset?.settings?.readEndTime
                                ? this.props.dataset?.settings?.readEndTime
                                : null
                            }
                            onChange={newValue => {
                              this.handleReadEndTimeChange(newValue);
                            }}
                            renderInput={params => <TextField {...params} />}
                            minutesStep={5}
                            open={this.state.readEndPickerOpen}
                            onOpen={this.handleOpenReadEndPicker}
                            onClose={this.handleCloseReadEndPicker}
                          />
                        </LocalizationProvider>
                      </div>
                    </List.Item>
                    <List.Item>
                      {!this.props.dataset.settings?.timeZone ? (
                        <Label basic color="red" pointing="below">
                          Please select a read time zone
                        </Label>
                      ) : (
                        <Label color="grey" horizontal>
                          Time Zone: {this.props.dataset.settings?.timeZone}
                        </Label>
                      )}
                      <br />
                      <Dropdown
                        style={{ margin: '12px' }}
                        onChange={this.handleTimeZoneChange}
                        options={this.returnTimeZoneOptions()}
                        selection
                        value={this.props.dataset.settings?.timeZone}
                      />
                    </List.Item>
                    <List.Item>
                      <Dropdown
                        search
                        searchInput={{ type: 'number' }}
                        selection
                        options={sampleSizeOptions}
                        placeholder="Select Sample Size..."
                        onChange={this.handleSampleSizeChange}
                        value={this.props.dataset.settings?.sampleSize}
                      />
                    </List.Item>
                  </List>
                </div>
              )}
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              <h1>Dataset Average Sentiment</h1>
              <div>
                {this.state.datasetAvgData && (
                  <Doughnut
                    style={{ maxHeight: '650px' }}
                    width={1}
                    height={1}
                    options={this.state.chartOptions}
                    data={this.state.datasetAvgData}
                  />
                )}
              </div>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              <h1>Notes</h1>
              <div className={classes.notesCard}>
                <Notes
                  placeholder="Type your notes here"
                  text={
                    !this.props.loadingDataset && this.props.dataset.notes
                      ? this.props.dataset.notes
                      : ''
                  }
                  onEditStart={this.activateNotesEditing}
                  onTextDiffers={this.onNotesTextDiffers}
                  editMode={this.state.notesEditMode}
                  waitMode={this.state.notesWaitMode}
                  textAreaClass={classes.notesTextArea}
                  editModeClass={classes.notesEditMode}
                  ref={this.notesEditor}
                  disabled={false}
                  // onBlur={this.saveNotes}
                />
              </div>
              <div>
                <Button
                  disabled={!this.state.areNotesChanged}
                  color={this.state.areNotesChanged ? 'green' : 'black'}
                  style={{ margin: '15px', float: 'right' }}
                  onClick={this.saveNotes}
                >
                  S a v e
                </Button>
              </div>
            </Item>
          </Grid>

          {this.props.loadingTrendsByDatasetId || this.props.loadingDatasetStats ? (
            <Grid item xs={4}>
              <Item>
                <Loader name="spinner" active content="Loading dataset items..." />
              </Item>
            </Grid>
          ) : (
            <Grid item xs={5}>
              <Item>
                <h1>In This Dataset: ({this.props.trendsByDatasetId.length} Trends)</h1>
                <div className={classes.container_table}>
                  <Table inverted compact="very" celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Keword Name</Table.HeaderCell>
                        <Table.HeaderCell>Sentiment Value</Table.HeaderCell>
                        <Table.HeaderCell>Delete</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.returnTrendItems()}</Table.Body>
                  </Table>
                </div>
              </Item>
            </Grid>
          )}

          <Grid item xs={3}>
            <Item>
              <h1>Tracking sub-Reddit: {this.props.dataset.subreddit}</h1>
              <Dropdown
                fluid
                selection
                search
                options={this.state.options}
                value={this.props.dataset.subreddit}
                placeholder={this.props.dataset.subreddit}
                onChange={this.handleRedditDropdownChange}
                onSearchChange={this.handleRedditSearchChange}
                disabled={this.state.isFetching}
                loading={this.state.isFetching}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default compose(
  withRouter,
  withSnackbar,
  connect(
    // map state to props
    (state, ownProps) => {
      return {
        selectedTrendIds: state.ux.selectedTrends.map(trend => trend.id),
        selectedDatasetIds: state.ux.selectedDatasets.map(dataset => dataset.id),
        isLoggedIn: state.ux.currentUser.isLoggedIn,
        currentUser: state.ux.currentUser.currentUser,
        currentlyViewingDataset: state.ux.currentlyViewingDataset,
      };
    },
    //mapDispatchToProps
    (dispatch, ownProps) => {
      return {
        // add in dispatch action functions here
        clearCurrentlyViewingDataset: () => {
          dispatch(clearCurrentlyViewingDatasetAction());
        },
      };
    },
    (stateProps, dispatchProps, ownProps) => {
      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
      };
    },
  ),
  graphql(DATASET_BY_ID, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          id: ownProps.currentlyViewingDataset,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, datasetById } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        dataset: datasetById || {},
        refetchDataset: refetch,
        loadingDataset: loading,
      };
      return result;
    },
  }),
  graphql(TRENDS_BY_DATASET_ID, {
    options: ownProps => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          datasetId: ownProps.currentlyViewingDataset,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, trendsByDatasetId } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        trendsByDatasetId: trendsByDatasetId || {},
        refetchTrendsByDatasetId: refetch,
        loadingTrendsByDatasetId: loading,
      };
      return result;
    },
  }),
  graphql(GENERATE_AMAZON_STATS_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        generateAmazonStatsMutation: async datasetId => {
          try {
            const response = await mutate({
              variables: {
                datasetId: datasetId,
              },
            });
            ownProps.snackbar('custom-error', 'info', {
              message: 'Generating Amazon producs listings...',
            });
            return response;
          } catch (e) {
            await ownProps.snackbar('generate-stats-error', 'error');
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(START_ML_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        startMachineLearningMutation: async (datasetId, datasetSize) => {
          try {
            const response = await mutate({
              variables: {
                datasetId: datasetId,
                datasetSize: datasetSize,
              },
            });
            ownProps.snackbar('custom-error', 'info', {
              message: `Machine Learning Ai Started on dataset: "${ownProps.dataset.name}"`,
            });
            return response;
          } catch (e) {
            ownProps.snackbar('custom-error', 'error', {
              message: 'Error activating neural network...',
            });
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(CALCULATE_STATS_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        calculateStatisticsMutation: async datasetId => {
          try {
            const response = await mutate({
              variables: {
                datasetId: datasetId,
              },
            });
            ownProps.snackbar('custom-error', 'info', {
              message: `Calculation started on dataset: "${ownProps.dataset.name}"`,
            });
            return response;
          } catch (e) {
            ownProps.snackbar('custom-error', 'error', {
              message: 'Error calculating stats...',
            });
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(UPDATE_DATASET_SETTINGS_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        updateDatasetSettingsMutation: async (userId, datasetId, object) => {
          try {
            const response = await mutate({
              variables: {
                userId,
                datasetId,
                settings: object,
              },
            });
            ownProps.snackbar('custom-error', 'success', {
              message: 'Successfully saved dataset settings.',
            });
            return response;
          } catch (e) {
            ownProps.snackbar('custom-error', 'error', {
              message: 'Error saving dataset settings...',
            });
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(UPDATE_DATASET_NOTES_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        updateDatasetNotesMutation: async (datasetId, notes) => {
          try {
            const response = await mutate({
              variables: {
                datasetId: datasetId,
                notes: notes,
              },
            });
            ownProps.snackbar('custom-error', 'success', {
              message: 'Successfully saved dataset notes.',
            });
            return response;
          } catch (e) {
            ownProps.snackbar('custom-error', 'error', {
              message: 'Error saving dataset notes...',
            });
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(REMOVE_TREND_FROM_DATASET_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        removeTrendFromDataset: async (trendId, datasetId, trendName) => {
          try {
            const response = await mutate({
              variables: {
                datasetId: datasetId,
                trendId: trendId,
              },
            });
            ownProps.snackbar('custom-error', 'success', {
              message: `Removed ${trendName} from dataset.`,
            });
            return response;
          } catch (e) {
            ownProps.snackbar('custom-error', 'error', {
              message: 'Error removing trend from dataset...',
            });
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(STATS_BY_DATASET_ID, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          datasetId: ownProps.currentlyViewingDataset,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, twitterStatsByDatasetId } = data;
      if (error) {
        console.error(error.message);
        ownProps.snackbar('custom-error', 'error', {
          message: 'Error fetching flat dataset stats...',
        });
      }

      const result = {
        twitterStatsByDatasetId: twitterStatsByDatasetId || [],
        refetchDatasetStats: refetch,
        loadingDatasetStats: loading,
      };
      return result;
    },
  }),
  graphql(STATS_BY_DATASET_ID_FLAT, {
    options: ownProps => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          datasetId: ownProps.currentlyViewingDataset,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, statsByDatasetIdFlat } = data;
      if (error) {
        console.error(error.message);
        ownProps.snackbar('custom-error', 'error', {
          message: 'Error fetching dataset stats...',
        });
      }

      const result = {
        statsByDatasetIdFlat: statsByDatasetIdFlat || [],
        refetchDatasetStatsFlat: refetch,
        loadingDatasetStatsFlat: loading,
      };
      return result;
    },
  }),
  connect(
    // map state to props
    (state, ownProps) => {
      const keywordOptions =
        !ownProps.loadingTrendsByDatasetId &&
        ownProps.trendsByDatasetId.map(item => {
          return {
            key: item.id,
            text:
              item.name + ` (Sentiment Score: ${item.averageScoreTf?.totalScore || 'No Score'})`,
            value: item.id,
          };
        });
      return {
        selectedTrendIds: state.ux.selectedTrends.map(trend => trend.id),
        selectedDatasetIds: state.ux.selectedDatasets.map(dataset => dataset.id),
        isLoggedIn: state.ux.currentUser.isLoggedIn,
        currentUser: state.ux.currentUser.currentUser,
        currentlyViewingDataset: state.ux.currentlyViewingDataset,
        keywordOptions: keywordOptions,
      };
    },
    //mapDispatchToProps
    (dispatch, ownProps) => {
      // project data here from ownProps queries to reshape into usable chart data since connect
      // is now called AFTER the queries have loaded into the higher order component function...

      const barChartOptions = {
        indexAxis: 'x',
        backgroundColor: '#000',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        barThickness: 8,
        elements: {
          bar: {
            borderWidth: 1,
          },
        },
        scaleBeginAtZero: false,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 0,
        },
        tooltips: {
          xAlign: 'left',
          yAlign: 'top',
        },
        scales: {
          xAxes: {
            // min: 0,
            // max: 1,
            ticks: {
              beginAtZero: false,
              min: 0,
              max: 1,
              stepSize: 0.1,
              suggestedMax: 1,
              suggestedMin: 0,
            },
          },
          yAxes: {
            min: 0,
            max: 1,
          },
        },
        plugins: {
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: '(1 is positive, 0 is negative)',
          },
        },
      };
      const trendNames = ownProps.statsByDatasetIdFlat.map(item => item.trendName);
      // const uniqueTrendNames = new Set(trendNames);

      function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min + ':' + sec;

        return time;
      }

      let chartDatasets = [];
      for (const item of ownProps.twitterStatsByDatasetId) {
        let individualDatasetData = [];
        for (const item2 of item.sentiment) {
          individualDatasetData.push({
            x: item2.resultTf.score,
            y: `${timeConverter(item2.date / 1000)}`,
          });
        }
        let color = RandomColor();
        chartDatasets.push({
          label: item.trendName,
          data: individualDatasetData,
          borderColor: color,
          backgroundColor: color,
          hoverBackgroundColor: '#1b91f2',
        });
      }

      const barChartData = {
        // labels,
        datasets: chartDatasets,
      };

      return {
        // add in dispatch action functions here
        clearCurrentlyViewingDataset: () => {
          dispatch(clearCurrentlyViewingDatasetAction());
        },
        barChartOptions: barChartOptions,
        barChartData: barChartData,
        timeConverter: timeConverter,
      };
    },
    (stateProps, dispatchProps, ownProps) => {
      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
      };
    },
  ),
)(Dataset);
