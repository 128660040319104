let data = [
  {
    "city": "Worldwide",
    "country": "",
    "woeid": 1
  },{
    "city": "Winnipeg",
    "country": "Canada",
    "woeid": 2972
  },{
    "city": "Ottawa",
    "country": "Canada",
    "woeid": 3369
  },{
    "city": "Quebec",
    "country": "Canada",
    "woeid": 3444
  },{
    "city": "Montreal",
    "country": "Canada",
    "woeid": 3534
  },{
    "city": "Toronto",
    "country": "Canada",
    "woeid": 4118
  },{
    "city": "Edmonton",
    "country": "Canada",
    "woeid": 8676
  },{
    "city": "Calgary",
    "country": "Canada",
    "woeid": 8775
  },{
    "city": "Vancouver",
    "country": "Canada",
    "woeid": 9807
  },{
    "city": "Birmingham",
    "country": "United Kingdom",
    "woeid": 12723
  },{
    "city": "Blackpool",
    "country": "United Kingdom",
    "woeid": 12903
  },{
    "city": "Bournemouth",
    "country": "United Kingdom",
    "woeid": 13383
  },{
    "city": "Brighton",
    "country": "United Kingdom",
    "woeid": 13911
  },{
    "city": "Bristol",
    "country": "United Kingdom",
    "woeid": 13963
  },{
    "city": "Cardiff",
    "country": "United Kingdom",
    "woeid": 15127
  },{
    "city": "Coventry",
    "country": "United Kingdom",
    "woeid": 17044
  },{
    "city": "Derby",
    "country": "United Kingdom",
    "woeid": 18114
  },{
    "city": "Edinburgh",
    "country": "United Kingdom",
    "woeid": 19344
  },{
    "city": "Glasgow",
    "country": "United Kingdom",
    "woeid": 21125
  },{
    "city": "Hull",
    "country": "United Kingdom",
    "woeid": 25211
  },{
    "city": "Leeds",
    "country": "United Kingdom",
    "woeid": 26042
  },{
    "city": "Leicester",
    "country": "United Kingdom",
    "woeid": 26062
  },{
    "city": "Liverpool",
    "country": "United Kingdom",
    "woeid": 26734
  },{
    "city": "Manchester",
    "country": "United Kingdom",
    "woeid": 28218
  },{
    "city": "Middlesbrough",
    "country": "United Kingdom",
    "woeid": 28869
  },{
    "city": "Newcastle",
    "country": "United Kingdom",
    "woeid": 30079
  },{
    "city": "Nottingham",
    "country": "United Kingdom",
    "woeid": 30720
  },{
    "city": "Plymouth",
    "country": "United Kingdom",
    "woeid": 32185
  },{
    "city": "Portsmouth",
    "country": "United Kingdom",
    "woeid": 32452
  },{
    "city": "Preston",
    "country": "United Kingdom",
    "woeid": 32566
  },{
    "city": "Sheffield",
    "country": "United Kingdom",
    "woeid": 34503
  },{
    "city": "Stoke-on-Trent",
    "country": "United Kingdom",
    "woeid": 36240
  },{
    "city": "Swansea",
    "country": "United Kingdom",
    "woeid": 36758
  },{
    "city": "London",
    "country": "United Kingdom",
    "woeid": 44418
  },{
    "city": "Belfast",
    "country": "United Kingdom",
    "woeid": 44544
  },{
    "city": "Santo Domingo",
    "country": "Dominican Republic",
    "woeid": 76456
  },{
    "city": "Guatemala City",
    "country": "Guatemala",
    "woeid": 83123
  },{
    "city": "Acapulco",
    "country": "Mexico",
    "woeid": 110978
  },{
    "city": "Aguascalientes",
    "country": "Mexico",
    "woeid": 111579
  },{
    "city": "Chihuahua",
    "country": "Mexico",
    "woeid": 115958
  },{
    "city": "Mexico City",
    "country": "Mexico",
    "woeid": 116545
  },{
    "city": "Ciudad Juarez",
    "country": "Mexico",
    "woeid": 116556
  },{
    "city": "Nezahualcóyotl",
    "country": "Mexico",
    "woeid": 116564
  },{
    "city": "Culiacán",
    "country": "Mexico",
    "woeid": 117994
  },{
    "city": "Ecatepec de Morelos",
    "country": "Mexico",
    "woeid": 118466
  },{
    "city": "Guadalajara",
    "country": "Mexico",
    "woeid": 124162
  },{
    "city": "Hermosillo",
    "country": "Mexico",
    "woeid": 124785
  },{
    "city": "León",
    "country": "Mexico",
    "woeid": 131068
  },{
    "city": "Mérida",
    "country": "Mexico",
    "woeid": 133327
  },{
    "city": "Mexicali",
    "country": "Mexico",
    "woeid": 133475
  },{
    "city": "Monterrey",
    "country": "Mexico",
    "woeid": 134047
  },{
    "city": "Morelia",
    "country": "Mexico",
    "woeid": 134091
  },{
    "city": "Naucalpan de Juárez",
    "country": "Mexico",
    "woeid": 134395
  },{
    "city": "Puebla",
    "country": "Mexico",
    "woeid": 137612
  },{
    "city": "Querétaro",
    "country": "Mexico",
    "woeid": 138045
  },{
    "city": "Saltillo",
    "country": "Mexico",
    "woeid": 141272
  },{
    "city": "San Luis Potosí",
    "country": "Mexico",
    "woeid": 144265
  },{
    "city": "Tijuana",
    "country": "Mexico",
    "woeid": 149361
  },{
    "city": "Toluca",
    "country": "Mexico",
    "woeid": 149769
  },{
    "city": "Zapopan",
    "country": "Mexico",
    "woeid": 151582
  },{
    "city": "Mendoza",
    "country": "Argentina",
    "woeid": 332471
  },{
    "city": "Santiago",
    "country": "Chile",
    "woeid": 349859
  },{
    "city": "Concepcion",
    "country": "Chile",
    "woeid": 349860
  },{
    "city": "Valparaiso",
    "country": "Chile",
    "woeid": 349861
  },{
    "city": "Bogotá",
    "country": "Colombia",
    "woeid": 368148
  },{
    "city": "Cali",
    "country": "Colombia",
    "woeid": 368149
  },{
    "city": "Medellín",
    "country": "Colombia",
    "woeid": 368150
  },{
    "city": "Barranquilla",
    "country": "Colombia",
    "woeid": 368151
  },{
    "city": "Quito",
    "country": "Ecuador",
    "woeid": 375732
  },{
    "city": "Guayaquil",
    "country": "Ecuador",
    "woeid": 375733
  },{
    "city": "Caracas",
    "country": "Venezuela",
    "woeid": 395269
  },{
    "city": "Maracaibo",
    "country": "Venezuela",
    "woeid": 395270
  },{
    "city": "Maracay",
    "country": "Venezuela",
    "woeid": 395271
  },{
    "city": "Valencia",
    "country": "Venezuela",
    "woeid": 395272
  },{
    "city": "Barcelona",
    "country": "Venezuela",
    "woeid": 395273
  },{
    "city": "Ciudad Guayana",
    "country": "Venezuela",
    "woeid": 395275
  },{
    "city": "Turmero",
    "country": "Venezuela",
    "woeid": 395277
  },{
    "city": "Lima",
    "country": "Peru",
    "woeid": 418440
  },{
    "city": "Brasília",
    "country": "Brazil",
    "woeid": 455819
  },{
    "city": "Belém",
    "country": "Brazil",
    "woeid": 455820
  },{
    "city": "Belo Horizonte",
    "country": "Brazil",
    "woeid": 455821
  },{
    "city": "Curitiba",
    "country": "Brazil",
    "woeid": 455822
  },{
    "city": "Porto Alegre",
    "country": "Brazil",
    "woeid": 455823
  },{
    "city": "Recife",
    "country": "Brazil",
    "woeid": 455824
  },{
    "city": "Rio de Janeiro",
    "country": "Brazil",
    "woeid": 455825
  },{
    "city": "Salvador",
    "country": "Brazil",
    "woeid": 455826
  },{
    "city": "São Paulo",
    "country": "Brazil",
    "woeid": 455827
  },{
    "city": "Campinas",
    "country": "Brazil",
    "woeid": 455828
  },{
    "city": "Fortaleza",
    "country": "Brazil",
    "woeid": 455830
  },{
    "city": "Goiânia",
    "country": "Brazil",
    "woeid": 455831
  },{
    "city": "Manaus",
    "country": "Brazil",
    "woeid": 455833
  },{
    "city": "São Luís",
    "country": "Brazil",
    "woeid": 455834
  },{
    "city": "Guarulhos",
    "country": "Brazil",
    "woeid": 455867
  },{
    "city": "Córdoba",
    "country": "Argentina",
    "woeid": 466861
  },{
    "city": "Rosario",
    "country": "Argentina",
    "woeid": 466862
  },{
    "city": "Barquisimeto",
    "country": "Venezuela",
    "woeid": 468382
  },{
    "city": "Maturín",
    "country": "Venezuela",
    "woeid": 468384
  },{
    "city": "Buenos Aires",
    "country": "Argentina",
    "woeid": 468739
  },{
    "city": "Gdańsk",
    "country": "Poland",
    "woeid": 493417
  },{
    "city": "Kraków",
    "country": "Poland",
    "woeid": 502075
  },{
    "city": "Lodz",
    "country": "Poland",
    "woeid": 505120
  },{
    "city": "Poznań",
    "country": "Poland",
    "woeid": 514048
  },{
    "city": "Warsaw",
    "country": "Poland",
    "woeid": 523920
  },{
    "city": "Wroclaw",
    "country": "Poland",
    "woeid": 526363
  },{
    "city": "Vienna",
    "country": "Austria",
    "woeid": 551801
  },{
    "city": "Cork",
    "country": "Ireland",
    "woeid": 560472
  },{
    "city": "Dublin",
    "country": "Ireland",
    "woeid": 560743
  },{
    "city": "Galway",
    "country": "Ireland",
    "woeid": 560912
  },{
    "city": "Bordeaux",
    "country": "France",
    "woeid": 580778
  },{
    "city": "Lille",
    "country": "France",
    "woeid": 608105
  },{
    "city": "Lyon",
    "country": "France",
    "woeid": 609125
  },{
    "city": "Marseille",
    "country": "France",
    "woeid": 610264
  },{
    "city": "Montpellier",
    "country": "France",
    "woeid": 612977
  },{
    "city": "Nantes",
    "country": "France",
    "woeid": 613858
  },{
    "city": "Paris",
    "country": "France",
    "woeid": 615702
  },{
    "city": "Rennes",
    "country": "France",
    "woeid": 619163
  },{
    "city": "Strasbourg",
    "country": "France",
    "woeid": 627791
  },{
    "city": "Toulouse",
    "country": "France",
    "woeid": 628886
  },{
    "city": "Berlin",
    "country": "Germany",
    "woeid": 638242
  },{
    "city": "Bremen",
    "country": "Germany",
    "woeid": 641142
  },{
    "city": "Dortmund",
    "country": "Germany",
    "woeid": 645458
  },{
    "city": "Dresden",
    "country": "Germany",
    "woeid": 645686
  },{
    "city": "Dusseldorf",
    "country": "Germany",
    "woeid": 646099
  },{
    "city": "Essen",
    "country": "Germany",
    "woeid": 648820
  },{
    "city": "Frankfurt",
    "country": "Germany",
    "woeid": 650272
  },{
    "city": "Hamburg",
    "country": "Germany",
    "woeid": 656958
  },{
    "city": "Cologne",
    "country": "Germany",
    "woeid": 667931
  },{
    "city": "Leipzig",
    "country": "Germany",
    "woeid": 671072
  },{
    "city": "Munich",
    "country": "Germany",
    "woeid": 676757
  },{
    "city": "Stuttgart",
    "country": "Germany",
    "woeid": 698064
  },{
    "city": "Bologna",
    "country": "Italy",
    "woeid": 711080
  },{
    "city": "Genoa",
    "country": "Italy",
    "woeid": 716085
  },{
    "city": "Milan",
    "country": "Italy",
    "woeid": 718345
  },{
    "city": "Naples",
    "country": "Italy",
    "woeid": 719258
  },{
    "city": "Palermo",
    "country": "Italy",
    "woeid": 719846
  },{
    "city": "Rome",
    "country": "Italy",
    "woeid": 721943
  },{
    "city": "Turin",
    "country": "Italy",
    "woeid": 725003
  },{
    "city": "Den Haag",
    "country": "Netherlands",
    "woeid": 726874
  },{
    "city": "Amsterdam",
    "country": "Netherlands",
    "woeid": 727232
  },{
    "city": "Rotterdam",
    "country": "Netherlands",
    "woeid": 733075
  },{
    "city": "Utrecht",
    "country": "Netherlands",
    "woeid": 734047
  },{
    "city": "Barcelona",
    "country": "Spain",
    "woeid": 753692
  },{
    "city": "Bilbao",
    "country": "Spain",
    "woeid": 754542
  },{
    "city": "Las Palmas",
    "country": "Spain",
    "woeid": 764814
  },{
    "city": "Madrid",
    "country": "Spain",
    "woeid": 766273
  },{
    "city": "Malaga",
    "country": "Spain",
    "woeid": 766356
  },{
    "city": "Murcia",
    "country": "Spain",
    "woeid": 768026
  },{
    "city": "Palma",
    "country": "Spain",
    "woeid": 769293
  },{
    "city": "Seville",
    "country": "Spain",
    "woeid": 774508
  },{
    "city": "Valencia",
    "country": "Spain",
    "woeid": 776688
  },{
    "city": "Zaragoza",
    "country": "Spain",
    "woeid": 779063
  },{
    "city": "Geneva",
    "country": "Switzerland",
    "woeid": 782538
  },{
    "city": "Lausanne",
    "country": "Switzerland",
    "woeid": 783058
  },{
    "city": "Zurich",
    "country": "Switzerland",
    "woeid": 784794
  },{
    "city": "Brest",
    "country": "Belarus",
    "woeid": 824382
  },{
    "city": "Grodno",
    "country": "Belarus",
    "woeid": 825848
  },{
    "city": "Gomel",
    "country": "Belarus",
    "woeid": 825978
  },{
    "city": "Minsk",
    "country": "Belarus",
    "woeid": 834463
  },{
    "city": "Riga",
    "country": "Latvia",
    "woeid": 854823
  },{
    "city": "Bergen",
    "country": "Norway",
    "woeid": 857105
  },{
    "city": "Oslo",
    "country": "Norway",
    "woeid": 862592
  },{
    "city": "Gothenburg",
    "country": "Sweden",
    "woeid": 890869
  },{
    "city": "Stockholm",
    "country": "Sweden",
    "woeid": 906057
  },{
    "city": "Dnipropetrovsk",
    "country": "Ukraine",
    "woeid": 918981
  },{
    "city": "Donetsk",
    "country": "Ukraine",
    "woeid": 919163
  },{
    "city": "Kharkiv",
    "country": "Ukraine",
    "woeid": 922137
  },{
    "city": "Kyiv",
    "country": "Ukraine",
    "woeid": 924938
  },{
    "city": "Lviv",
    "country": "Ukraine",
    "woeid": 924943
  },{
    "city": "Odesa",
    "country": "Ukraine",
    "woeid": 929398
  },{
    "city": "Zaporozhye",
    "country": "Ukraine",
    "woeid": 939628
  },{
    "city": "Athens",
    "country": "Greece",
    "woeid": 946738
  },{
    "city": "Thessaloniki",
    "country": "Greece",
    "woeid": 963291
  },{
    "city": "Bekasi",
    "country": "Indonesia",
    "woeid": 1030077
  },{
    "city": "Depok",
    "country": "Indonesia",
    "woeid": 1032539
  },{
    "city": "Pekanbaru",
    "country": "Indonesia",
    "woeid": 1040779
  },{
    "city": "Surabaya",
    "country": "Indonesia",
    "woeid": 1044316
  },{
    "city": "Makassar",
    "country": "Indonesia",
    "woeid": 1046138
  },{
    "city": "Bandung",
    "country": "Indonesia",
    "woeid": 1047180
  },{
    "city": "Jakarta",
    "country": "Indonesia",
    "woeid": 1047378
  },{
    "city": "Medan",
    "country": "Indonesia",
    "woeid": 1047908
  },{
    "city": "Palembang",
    "country": "Indonesia",
    "woeid": 1048059
  },{
    "city": "Semarang",
    "country": "Indonesia",
    "woeid": 1048324
  },{
    "city": "Tangerang",
    "country": "Indonesia",
    "woeid": 1048536
  },{
    "city": "Singapore",
    "country": "Singapore",
    "woeid": 1062617
  },{
    "city": "Perth",
    "country": "Australia",
    "woeid": 1098081
  },{
    "city": "Adelaide",
    "country": "Australia",
    "woeid": 1099805
  },{
    "city": "Brisbane",
    "country": "Australia",
    "woeid": 1100661
  },{
    "city": "Canberra",
    "country": "Australia",
    "woeid": 1100968
  },{
    "city": "Darwin",
    "country": "Australia",
    "woeid": 1101597
  },{
    "city": "Melbourne",
    "country": "Australia",
    "woeid": 1103816
  },{
    "city": "Sydney",
    "country": "Australia",
    "woeid": 1105779
  },{
    "city": "Kitakyushu",
    "country": "Japan",
    "woeid": 1110809
  },{
    "city": "Saitama",
    "country": "Japan",
    "woeid": 1116753
  },{
    "city": "Chiba",
    "country": "Japan",
    "woeid": 1117034
  },{
    "city": "Fukuoka",
    "country": "Japan",
    "woeid": 1117099
  },{
    "city": "Hamamatsu",
    "country": "Japan",
    "woeid": 1117155
  },{
    "city": "Hiroshima",
    "country": "Japan",
    "woeid": 1117227
  },{
    "city": "Kawasaki",
    "country": "Japan",
    "woeid": 1117502
  },{
    "city": "Kobe",
    "country": "Japan",
    "woeid": 1117545
  },{
    "city": "Kumamoto",
    "country": "Japan",
    "woeid": 1117605
  },{
    "city": "Nagoya",
    "country": "Japan",
    "woeid": 1117817
  },{
    "city": "Niigata",
    "country": "Japan",
    "woeid": 1117881
  },{
    "city": "Sagamihara",
    "country": "Japan",
    "woeid": 1118072
  },{
    "city": "Sapporo",
    "country": "Japan",
    "woeid": 1118108
  },{
    "city": "Sendai",
    "country": "Japan",
    "woeid": 1118129
  },{
    "city": "Takamatsu",
    "country": "Japan",
    "woeid": 1118285
  },{
    "city": "Tokyo",
    "country": "Japan",
    "woeid": 1118370
  },{
    "city": "Yokohama",
    "country": "Japan",
    "woeid": 1118550
  },{
    "city": "Goyang",
    "country": "Korea",
    "woeid": 1130853
  },{
    "city": "Yongin",
    "country": "Korea",
    "woeid": 1132094
  },{
    "city": "Ansan",
    "country": "Korea",
    "woeid": 1132444
  },{
    "city": "Bucheon",
    "country": "Korea",
    "woeid": 1132445
  },{
    "city": "Busan",
    "country": "Korea",
    "woeid": 1132447
  },{
    "city": "Changwon",
    "country": "Korea",
    "woeid": 1132449
  },{
    "city": "Daegu",
    "country": "Korea",
    "woeid": 1132466
  },{
    "city": "Gwangju",
    "country": "Korea",
    "woeid": 1132481
  },{
    "city": "Incheon",
    "country": "Korea",
    "woeid": 1132496
  },{
    "city": "Seongnam",
    "country": "Korea",
    "woeid": 1132559
  },{
    "city": "Suwon",
    "country": "Korea",
    "woeid": 1132567
  },{
    "city": "Ulsan",
    "country": "Korea",
    "woeid": 1132578
  },{
    "city": "Seoul",
    "country": "Korea",
    "woeid": 1132599
  },{
    "city": "Kajang",
    "country": "Malaysia",
    "woeid": 1141268
  },{
    "city": "Ipoh",
    "country": "Malaysia",
    "woeid": 1154679
  },{
    "city": "Johor Bahru",
    "country": "Malaysia",
    "woeid": 1154698
  },{
    "city": "Klang",
    "country": "Malaysia",
    "woeid": 1154726
  },{
    "city": "Kuala Lumpur",
    "country": "Malaysia",
    "woeid": 1154781
  },{
    "city": "Calocan",
    "country": "Philippines",
    "woeid": 1167715
  },{
    "city": "Makati",
    "country": "Philippines",
    "woeid": 1180689
  },{
    "city": "Pasig",
    "country": "Philippines",
    "woeid": 1187115
  },{
    "city": "Taguig",
    "country": "Philippines",
    "woeid": 1195098
  },{
    "city": "Antipolo",
    "country": "Philippines",
    "woeid": 1198785
  },{
    "city": "Cagayan de Oro",
    "country": "Philippines",
    "woeid": 1199002
  },{
    "city": "Cebu City",
    "country": "Philippines",
    "woeid": 1199079
  },{
    "city": "Davao City",
    "country": "Philippines",
    "woeid": 1199136
  },{
    "city": "Manila",
    "country": "Philippines",
    "woeid": 1199477
  },{
    "city": "Quezon City",
    "country": "Philippines",
    "woeid": 1199682
  },{
    "city": "Zamboanga City",
    "country": "Philippines",
    "woeid": 1199980
  },{
    "city": "Bangkok",
    "country": "Thailand",
    "woeid": 1225448
  },{
    "city": "Hanoi",
    "country": "Vietnam",
    "woeid": 1236594
  },{
    "city": "Hai Phong",
    "country": "Vietnam",
    "woeid": 1236690
  },{
    "city": "Can Tho",
    "country": "Vietnam",
    "woeid": 1252351
  },{
    "city": "Da Nang",
    "country": "Vietnam",
    "woeid": 1252376
  },{
    "city": "Ho Chi Minh City",
    "country": "Vietnam",
    "woeid": 1252431
  },{
    "city": "Algiers",
    "country": "Algeria",
    "woeid": 1253079
  },{
    "city": "Accra",
    "country": "Ghana",
    "woeid": 1326075
  },{
    "city": "Kumasi",
    "country": "Ghana",
    "woeid": 1330595
  },{
    "city": "Benin City",
    "country": "Nigeria",
    "woeid": 1387660
  },{
    "city": "Ibadan",
    "country": "Nigeria",
    "woeid": 1393672
  },{
    "city": "Kaduna",
    "country": "Nigeria",
    "woeid": 1396439
  },{
    "city": "Kano",
    "country": "Nigeria",
    "woeid": 1396803
  },{
    "city": "Lagos",
    "country": "Nigeria",
    "woeid": 1398823
  },{
    "city": "Port Harcourt",
    "country": "Nigeria",
    "woeid": 1404447
  },{
    "city": "Giza",
    "country": "Egypt",
    "woeid": 1521643
  },{
    "city": "Cairo",
    "country": "Egypt",
    "woeid": 1521894
  },{
    "city": "Alexandria",
    "country": "Egypt",
    "woeid": 1522006
  },{
    "city": "Mombasa",
    "country": "Kenya",
    "woeid": 1528335
  },{
    "city": "Nairobi",
    "country": "Kenya",
    "woeid": 1528488
  },{
    "city": "Durban",
    "country": "South Africa",
    "woeid": 1580913
  },{
    "city": "Johannesburg",
    "country": "South Africa",
    "woeid": 1582504
  },{
    "city": "Port Elizabeth",
    "country": "South Africa",
    "woeid": 1586614
  },{
    "city": "Pretoria",
    "country": "South Africa",
    "woeid": 1586638
  },{
    "city": "Soweto",
    "country": "South Africa",
    "woeid": 1587677
  },{
    "city": "Cape Town",
    "country": "South Africa",
    "woeid": 1591691
  },{
    "city": "Medina",
    "country": "Saudi Arabia",
    "woeid": 1937801
  },{
    "city": "Dammam",
    "country": "Saudi Arabia",
    "woeid": 1939574
  },{
    "city": "Riyadh",
    "country": "Saudi Arabia",
    "woeid": 1939753
  },{
    "city": "Jeddah",
    "country": "Saudi Arabia",
    "woeid": 1939873
  },{
    "city": "Mecca",
    "country": "Saudi Arabia",
    "woeid": 1939897
  },{
    "city": "Sharjah",
    "country": "United Arab Emirates",
    "woeid": 1940119
  },{
    "city": "Abu Dhabi",
    "country": "United Arab Emirates",
    "woeid": 1940330
  },{
    "city": "Dubai",
    "country": "United Arab Emirates",
    "woeid": 1940345
  },{
    "city": "Haifa",
    "country": "Israel",
    "woeid": 1967449
  },{
    "city": "Tel Aviv",
    "country": "Israel",
    "woeid": 1968212
  },{
    "city": "Jerusalem",
    "country": "Israel",
    "woeid": 1968222
  },{
    "city": "Amman",
    "country": "Jordan",
    "woeid": 1968902
  },{
    "city": "Chelyabinsk",
    "country": "Russia",
    "woeid": 1997422
  },{
    "city": "Khabarovsk",
    "country": "Russia",
    "woeid": 2018708
  },{
    "city": "Krasnodar",
    "country": "Russia",
    "woeid": 2028717
  },{
    "city": "Krasnoyarsk",
    "country": "Russia",
    "woeid": 2029043
  },{
    "city": "Samara",
    "country": "Russia",
    "woeid": 2077746
  },{
    "city": "Voronezh",
    "country": "Russia",
    "woeid": 2108210
  },{
    "city": "Yekaterinburg",
    "country": "Russia",
    "woeid": 2112237
  },{
    "city": "Irkutsk",
    "country": "Russia",
    "woeid": 2121040
  },{
    "city": "Kazan",
    "country": "Russia",
    "woeid": 2121267
  },{
    "city": "Moscow",
    "country": "Russia",
    "woeid": 2122265
  },{
    "city": "Nizhny Novgorod",
    "country": "Russia",
    "woeid": 2122471
  },{
    "city": "Novosibirsk",
    "country": "Russia",
    "woeid": 2122541
  },{
    "city": "Omsk",
    "country": "Russia",
    "woeid": 2122641
  },{
    "city": "Perm",
    "country": "Russia",
    "woeid": 2122814
  },{
    "city": "Rostov-on-Don",
    "country": "Russia",
    "woeid": 2123177
  },{
    "city": "Saint Petersburg",
    "country": "Russia",
    "woeid": 2123260
  },{
    "city": "Ufa",
    "country": "Russia",
    "woeid": 2124045
  },{
    "city": "Vladivostok",
    "country": "Russia",
    "woeid": 2124288
  },{
    "city": "Volgograd",
    "country": "Russia",
    "woeid": 2124298
  },{
    "city": "Karachi",
    "country": "Pakistan",
    "woeid": 2211096
  },{
    "city": "Lahore",
    "country": "Pakistan",
    "woeid": 2211177
  },{
    "city": "Multan",
    "country": "Pakistan",
    "woeid": 2211269
  },{
    "city": "Rawalpindi",
    "country": "Pakistan",
    "woeid": 2211387
  },{
    "city": "Faisalabad",
    "country": "Pakistan",
    "woeid": 2211574
  },{
    "city": "Muscat",
    "country": "Oman",
    "woeid": 2268284
  },{
    "city": "Nagpur",
    "country": "India",
    "woeid": 2282863
  },{
    "city": "Lucknow",
    "country": "India",
    "woeid": 2295377
  },{
    "city": "Kanpur",
    "country": "India",
    "woeid": 2295378
  },{
    "city": "Patna",
    "country": "India",
    "woeid": 2295381
  },{
    "city": "Ranchi",
    "country": "India",
    "woeid": 2295383
  },{
    "city": "Kolkata",
    "country": "India",
    "woeid": 2295386
  },{
    "city": "Srinagar",
    "country": "India",
    "woeid": 2295387
  },{
    "city": "Amritsar",
    "country": "India",
    "woeid": 2295388
  },{
    "city": "Jaipur",
    "country": "India",
    "woeid": 2295401
  },{
    "city": "Ahmedabad",
    "country": "India",
    "woeid": 2295402
  },{
    "city": "Rajkot",
    "country": "India",
    "woeid": 2295404
  },{
    "city": "Surat",
    "country": "India",
    "woeid": 2295405
  },{
    "city": "Bhopal",
    "country": "India",
    "woeid": 2295407
  },{
    "city": "Indore",
    "country": "India",
    "woeid": 2295408
  },{
    "city": "Thane",
    "country": "India",
    "woeid": 2295410
  },{
    "city": "Mumbai",
    "country": "India",
    "woeid": 2295411
  },{
    "city": "Pune",
    "country": "India",
    "woeid": 2295412
  },{
    "city": "Hyderabad",
    "country": "India",
    "woeid": 2295414
  },{
    "city": "Bangalore",
    "country": "India",
    "woeid": 2295420
  },{
    "city": "Chennai",
    "country": "India",
    "woeid": 2295424
  },{
    "city": "Mersin",
    "country": "Turkey",
    "woeid": 2323778
  },{
    "city": "Adana",
    "country": "Turkey",
    "woeid": 2343678
  },{
    "city": "Ankara",
    "country": "Turkey",
    "woeid": 2343732
  },{
    "city": "Antalya",
    "country": "Turkey",
    "woeid": 2343733
  },{
    "city": "Bursa",
    "country": "Turkey",
    "woeid": 2343843
  },{
    "city": "Diyarbakır",
    "country": "Turkey",
    "woeid": 2343932
  },{
    "city": "Eskişehir",
    "country": "Turkey",
    "woeid": 2343980
  },{
    "city": "Gaziantep",
    "country": "Turkey",
    "woeid": 2343999
  },{
    "city": "Istanbul",
    "country": "Turkey",
    "woeid": 2344116
  },{
    "city": "Izmir",
    "country": "Turkey",
    "woeid": 2344117
  },{
    "city": "Kayseri",
    "country": "Turkey",
    "woeid": 2344174
  },{
    "city": "Konya",
    "country": "Turkey",
    "woeid": 2344210
  },{
    "city": "Okinawa",
    "country": "Japan",
    "woeid": 2345896
  },{
    "city": "Daejeon",
    "country": "Korea",
    "woeid": 2345975
  },{
    "city": "Auckland",
    "country": "New Zealand",
    "woeid": 2348079
  },{
    "city": "Albuquerque",
    "country": "United States",
    "woeid": 2352824
  },{
    "city": "Atlanta",
    "country": "United States",
    "woeid": 2357024
  },{
    "city": "Austin",
    "country": "United States",
    "woeid": 2357536
  },{
    "city": "Baltimore",
    "country": "United States",
    "woeid": 2358820
  },{
    "city": "Baton Rouge",
    "country": "United States",
    "woeid": 2359991
  },{
    "city": "Birmingham",
    "country": "United States",
    "woeid": 2364559
  },{
    "city": "Boston",
    "country": "United States",
    "woeid": 2367105
  },{
    "city": "Charlotte",
    "country": "United States",
    "woeid": 2378426
  },{
    "city": "Chicago",
    "country": "United States",
    "woeid": 2379574
  },{
    "city": "Cincinnati",
    "country": "United States",
    "woeid": 2380358
  },{
    "city": "Cleveland",
    "country": "United States",
    "woeid": 2381475
  },{
    "city": "Colorado Springs",
    "country": "United States",
    "woeid": 2383489
  },{
    "city": "Columbus",
    "country": "United States",
    "woeid": 2383660
  },{
    "city": "Dallas-Ft. Worth",
    "country": "United States",
    "woeid": 2388929
  },{
    "city": "Denver",
    "country": "United States",
    "woeid": 2391279
  },{
    "city": "Detroit",
    "country": "United States",
    "woeid": 2391585
  },{
    "city": "El Paso",
    "country": "United States",
    "woeid": 2397816
  },{
    "city": "Fresno",
    "country": "United States",
    "woeid": 2407517
  },{
    "city": "Greensboro",
    "country": "United States",
    "woeid": 2414469
  },{
    "city": "Harrisburg",
    "country": "United States",
    "woeid": 2418046
  },{
    "city": "Honolulu",
    "country": "United States",
    "woeid": 2423945
  },{
    "city": "Houston",
    "country": "United States",
    "woeid": 2424766
  },{
    "city": "Indianapolis",
    "country": "United States",
    "woeid": 2427032
  },{
    "city": "Jackson",
    "country": "United States",
    "woeid": 2428184
  },{
    "city": "Jacksonville",
    "country": "United States",
    "woeid": 2428344
  },{
    "city": "Kansas City",
    "country": "United States",
    "woeid": 2430683
  },{
    "city": "Las Vegas",
    "country": "United States",
    "woeid": 2436704
  },{
    "city": "Long Beach",
    "country": "United States",
    "woeid": 2441472
  },{
    "city": "Los Angeles",
    "country": "United States",
    "woeid": 2442047
  },{
    "city": "Louisville",
    "country": "United States",
    "woeid": 2442327
  },{
    "city": "Memphis",
    "country": "United States",
    "woeid": 2449323
  },{
    "city": "Mesa",
    "country": "United States",
    "woeid": 2449808
  },{
    "city": "Miami",
    "country": "United States",
    "woeid": 2450022
  },{
    "city": "Milwaukee",
    "country": "United States",
    "woeid": 2451822
  },{
    "city": "Minneapolis",
    "country": "United States",
    "woeid": 2452078
  },{
    "city": "Nashville",
    "country": "United States",
    "woeid": 2457170
  },{
    "city": "New Haven",
    "country": "United States",
    "woeid": 2458410
  },{
    "city": "New Orleans",
    "country": "United States",
    "woeid": 2458833
  },{
    "city": "New York",
    "country": "United States",
    "woeid": 2459115
  },{
    "city": "Norfolk",
    "country": "United States",
    "woeid": 2460389
  },{
    "city": "Oklahoma City",
    "country": "United States",
    "woeid": 2464592
  },{
    "city": "Omaha",
    "country": "United States",
    "woeid": 2465512
  },{
    "city": "Orlando",
    "country": "United States",
    "woeid": 2466256
  },{
    "city": "Philadelphia",
    "country": "United States",
    "woeid": 2471217
  },{
    "city": "Phoenix",
    "country": "United States",
    "woeid": 2471390
  },{
    "city": "Pittsburgh",
    "country": "United States",
    "woeid": 2473224
  },{
    "city": "Portland",
    "country": "United States",
    "woeid": 2475687
  },{
    "city": "Providence",
    "country": "United States",
    "woeid": 2477058
  },{
    "city": "Raleigh",
    "country": "United States",
    "woeid": 2478307
  },{
    "city": "Richmond",
    "country": "United States",
    "woeid": 2480894
  },{
    "city": "Sacramento",
    "country": "United States",
    "woeid": 2486340
  },{
    "city": "St. Louis",
    "country": "United States",
    "woeid": 2486982
  },{
    "city": "Salt Lake City",
    "country": "United States",
    "woeid": 2487610
  },{
    "city": "San Antonio",
    "country": "United States",
    "woeid": 2487796
  },{
    "city": "San Diego",
    "country": "United States",
    "woeid": 2487889
  },{
    "city": "San Francisco",
    "country": "United States",
    "woeid": 2487956
  },{
    "city": "San Jose",
    "country": "United States",
    "woeid": 2488042
  },{
    "city": "Seattle",
    "country": "United States",
    "woeid": 2490383
  },{
    "city": "Tallahassee",
    "country": "United States",
    "woeid": 2503713
  },{
    "city": "Tampa",
    "country": "United States",
    "woeid": 2503863
  },{
    "city": "Tucson",
    "country": "United States",
    "woeid": 2508428
  },{
    "city": "Virginia Beach",
    "country": "United States",
    "woeid": 2512636
  },{
    "city": "Washington",
    "country": "United States",
    "woeid": 2514815
  },{
    "city": "Osaka",
    "country": "Japan",
    "woeid": 15015370
  },{
    "city": "Kyoto",
    "country": "Japan",
    "woeid": 15015372
  },{
    "city": "Delhi",
    "country": "India",
    "woeid": 20070458
  },{
    "city": "United Arab Emirates",
    "country": "United Arab Emirates",
    "woeid": 23424738
  },{
    "city": "Algeria",
    "country": "Algeria",
    "woeid": 23424740
  },{
    "city": "Argentina",
    "country": "Argentina",
    "woeid": 23424747
  },{
    "city": "Australia",
    "country": "Australia",
    "woeid": 23424748
  },{
    "city": "Austria",
    "country": "Austria",
    "woeid": 23424750
  },{
    "city": "Bahrain",
    "country": "Bahrain",
    "woeid": 23424753
  },{
    "city": "Belgium",
    "country": "Belgium",
    "woeid": 23424757
  },{
    "city": "Belarus",
    "country": "Belarus",
    "woeid": 23424765
  },{
    "city": "Brazil",
    "country": "Brazil",
    "woeid": 23424768
  },{
    "city": "Canada",
    "country": "Canada",
    "woeid": 23424775
  },{
    "city": "Chile",
    "country": "Chile",
    "woeid": 23424782
  },{
    "city": "Colombia",
    "country": "Colombia",
    "woeid": 23424787
  },{
    "city": "Denmark",
    "country": "Denmark",
    "woeid": 23424796
  },{
    "city": "Dominican Republic",
    "country": "Dominican Republic",
    "woeid": 23424800
  },{
    "city": "Ecuador",
    "country": "Ecuador",
    "woeid": 23424801
  },{
    "city": "Egypt",
    "country": "Egypt",
    "woeid": 23424802
  },{
    "city": "Ireland",
    "country": "Ireland",
    "woeid": 23424803
  },{
    "city": "France",
    "country": "France",
    "woeid": 23424819
  },{
    "city": "Ghana",
    "country": "Ghana",
    "woeid": 23424824
  },{
    "city": "Germany",
    "country": "Germany",
    "woeid": 23424829
  },{
    "city": "Greece",
    "country": "Greece",
    "woeid": 23424833
  },{
    "city": "Guatemala",
    "country": "Guatemala",
    "woeid": 23424834
  },{
    "city": "Indonesia",
    "country": "Indonesia",
    "woeid": 23424846
  },{
    "city": "India",
    "country": "India",
    "woeid": 23424848
  },{
    "city": "Israel",
    "country": "Israel",
    "woeid": 23424852
  },{
    "city": "Italy",
    "country": "Italy",
    "woeid": 23424853
  },{
    "city": "Japan",
    "country": "Japan",
    "woeid": 23424856
  },{
    "city": "Jordan",
    "country": "Jordan",
    "woeid": 23424860
  },{
    "city": "Kenya",
    "country": "Kenya",
    "woeid": 23424863
  },{
    "city": "Korea",
    "country": "Korea",
    "woeid": 23424868
  },{
    "city": "Kuwait",
    "country": "Kuwait",
    "woeid": 23424870
  },{
    "city": "Lebanon",
    "country": "Lebanon",
    "woeid": 23424873
  },{
    "city": "Latvia",
    "country": "Latvia",
    "woeid": 23424874
  },{
    "city": "Oman",
    "country": "Oman",
    "woeid": 23424898
  },{
    "city": "Mexico",
    "country": "Mexico",
    "woeid": 23424900
  },{
    "city": "Malaysia",
    "country": "Malaysia",
    "woeid": 23424901
  },{
    "city": "Nigeria",
    "country": "Nigeria",
    "woeid": 23424908
  },{
    "city": "Netherlands",
    "country": "Netherlands",
    "woeid": 23424909
  },{
    "city": "Norway",
    "country": "Norway",
    "woeid": 23424910
  },{
    "city": "New Zealand",
    "country": "New Zealand",
    "woeid": 23424916
  },{
    "city": "Peru",
    "country": "Peru",
    "woeid": 23424919
  },{
    "city": "Pakistan",
    "country": "Pakistan",
    "woeid": 23424922
  },{
    "city": "Poland",
    "country": "Poland",
    "woeid": 23424923
  },{
    "city": "Panama",
    "country": "Panama",
    "woeid": 23424924
  },{
    "city": "Portugal",
    "country": "Portugal",
    "woeid": 23424925
  },{
    "city": "Qatar",
    "country": "Qatar",
    "woeid": 23424930
  },{
    "city": "Philippines",
    "country": "Philippines",
    "woeid": 23424934
  },{
    "city": "Puerto Rico",
    "country": "Puerto Rico",
    "woeid": 23424935
  },{
    "city": "Russia",
    "country": "Russia",
    "woeid": 23424936
  },{
    "city": "Saudi Arabia",
    "country": "Saudi Arabia",
    "woeid": 23424938
  },{
    "city": "South Africa",
    "country": "South Africa",
    "woeid": 23424942
  },{
    "city": "Singapore",
    "country": "Singapore",
    "woeid": 23424948
  },{
    "city": "Spain",
    "country": "Spain",
    "woeid": 23424950
  },{
    "city": "Sweden",
    "country": "Sweden",
    "woeid": 23424954
  },{
    "city": "Switzerland",
    "country": "Switzerland",
    "woeid": 23424957
  },{
    "city": "Thailand",
    "country": "Thailand",
    "woeid": 23424960
  },{
    "city": "Turkey",
    "country": "Turkey",
    "woeid": 23424969
  },{
    "city": "United Kingdom",
    "country": "United Kingdom",
    "woeid": 23424975
  },{
    "city": "Ukraine",
    "country": "Ukraine",
    "woeid": 23424976
  },{
    "city": "United States",
    "country": "United States",
    "woeid": 23424977
  },{
    "city": "Venezuela",
    "country": "Venezuela",
    "woeid": 23424982
  },{
    "city": "Vietnam",
    "country": "Vietnam",
    "woeid": 23424984
  },{
    "city": "Petaling",
    "country": "Malaysia",
    "woeid": 56013632
  },{
    "city": "Hulu Langat",
    "country": "Malaysia",
    "woeid": 56013645
  },{
    "city": "Ahsa",
    "country": "Saudi Arabia",
    "woeid": 56120136
  },{
    "city": "Okayama",
    "country": "Japan",
    "woeid": 90036018
  }

];

export default data;