import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';

import React from 'react';
import PropTypes from 'prop-types';

import { ChartCanvas, Chart, ZoomButtons } from 'react-stockcharts';
import {
  BarSeries,
  AreaSeries,
  CandlestickSeries,
  LineSeries,
  MACDSeries,
} from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import {
  CrossHairCursor,
  EdgeIndicator,
  CurrentCoordinate,
  MouseCoordinateX,
  MouseCoordinateY,
} from 'react-stockcharts/lib/coordinates';

import { discontinuousTimeScaleProviderBuilder } from 'react-stockcharts/lib/scale';
import { OHLCTooltip, MovingAverageTooltip, MACDTooltip } from 'react-stockcharts/lib/tooltip';
import {
  ema,
  sma,
  macd,
  rsi,
  wma,
  tma,
  bollingerBand,
  heikinAshi,
  kagi,
  pointAndFigure,
  renko,
  atr,
  stochasticOscillator,
  forceIndex,
  sar,
  elderRay,
  change,
  elderImpulse,
  compare,
} from 'react-stockcharts/lib/indicator';
import { fitWidth } from 'react-stockcharts/lib/helper';
import equal from 'fast-deep-equal/react';
import './chart.css';

function getMaxUndefined(calculators) {
  return calculators.map(each => each.undefinedLength()).reduce((a, b) => Math.max(a, b));
}
const LENGTH_TO_SHOW = 40;

const macdAppearance = {
  stroke: {
    macd: '#FF0000',
    signal: '#00F300',
  },
  fill: {
    divergence: '#4682B4',
  },
};

class CandleStickChartPanToLoadMore extends React.Component {
  constructor(props) {
    super(props);
    const { data: inputData } = props;

    const ema26 = ema()
      .id(0)
      .options({ windowSize: 26 })
      .merge((d, c) => {
        // console.log('d1', d);
        d.ema26 = c;
      })
      .accessor(d => d.ema26);

    const ema12 = ema()
      .id(1)
      .options({ windowSize: 12 })
      .merge((d, c) => {
        // console.log('d2', d);
        d.ema12 = c;
      })
      .accessor(d => d.ema12);

    const macdCalculator = macd()
      .options({
        fast: 12,
        slow: 26,
        signal: 9,
      })
      .merge((d, c) => {
        // console.log('d3', d);
        d.macd = c;
      })
      .accessor(d => d.macd);

    const smaVolume50 = sma()
      .id(3)
      .options({
        windowSize: 50,
        sourcePath: 'volume',
      })
      .merge((d, c) => {
        // console.log('d4', d);
        d.smaVolume50 = c;
      })
      .accessor(d => d.smaVolume50);

    const maxWindowSize = getMaxUndefined([ema26, ema12, macdCalculator, smaVolume50]);
    /* SERVER - START */
    const dataToCalculate = inputData.slice(-LENGTH_TO_SHOW - maxWindowSize);

    const calculatedData = ema26(ema12(macdCalculator(smaVolume50(dataToCalculate))));
    const indexCalculator = discontinuousTimeScaleProviderBuilder().indexCalculator();

    // console.log(inputData.length, dataToCalculate.length, maxWindowSize)
    const { index } = indexCalculator(calculatedData);
    /* SERVER - END */

    const xScaleProvider = discontinuousTimeScaleProviderBuilder().withIndex(index);
    const { data: linearData, xScale, xAccessor, displayXAccessor } = xScaleProvider(
      calculatedData.slice(-LENGTH_TO_SHOW),
    );

    // console.log(head(linearData), last(linearData))
    // console.log(linearData.length)

    this.state = {
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
      linearData,
      data: linearData,
      xScale,
      xAccessor,
      displayXAccessor,
    };
  }

  handleDownloadMore = (start, end) => {
    if (Math.ceil(start) === end) return;
    // console.log("rows to download", rowsToDownload, start, end)
    const { data: prevData, ema26, ema12, macdCalculator, smaVolume50 } = this.state;
    const { data: inputData } = this.props;

    if (inputData.length === prevData.length) return;

    const rowsToDownload = end - Math.ceil(start);

    const maxWindowSize = getMaxUndefined([ema26, ema12, macdCalculator, smaVolume50]);

    /* SERVER - START */
    const dataToCalculate = inputData.slice(
      -rowsToDownload - maxWindowSize - prevData.length,
      -prevData.length,
    );

    const calculatedData = ema26(ema12(macdCalculator(smaVolume50(dataToCalculate))));
    const indexCalculator = discontinuousTimeScaleProviderBuilder()
      .initialIndex(Math.ceil(start))
      .indexCalculator();
    const { index } = indexCalculator(calculatedData.slice(-rowsToDownload).concat(prevData));
    /* SERVER - END */

    const xScaleProvider = discontinuousTimeScaleProviderBuilder()
      .initialIndex(Math.ceil(start))
      .withIndex(index);

    const { data: linearData, xScale, xAccessor, displayXAccessor } = xScaleProvider(
      calculatedData.slice(-rowsToDownload).concat(prevData),
    );

    // console.log(linearData.length)
    setTimeout(() => {
      // simulate a lag for ajax
      this.setState({
        data: linearData,
        xScale,
        xAccessor,
        displayXAccessor,
      });
    }, 300);
  };

  shouldComponentUpdate = (prevProps, prevState) => {
    if (!equal(prevProps, this.props)) {
      return true;
    }
    if (!equal(prevState, this.state)) {
      return true;
    }
  };

  render() {
    const { type, width, ratio } = this.props;
    const {
      ema,
      sma,
      macd,
      rsi,
      wma,
      tma,
      bollingerBand,
      heikinAshi,
      kagi,
      pointAndFigure,
      renko,
      atr,
      stochasticOscillator,
      forceIndex,
      sar,
      elderRay,
      change,
      elderImpulse,
      compare,
    } = this.props.switches;

    // console.log('this.props.switches', this.props.switches);

    const {
      data,
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
      xScale,
      xAccessor,
      displayXAccessor,
    } = this.state;

    // console.log('THIS.State STOCK CHART ', this.state);

    return (
      <ChartCanvas
        ratio={ratio}
        width={width}
        height={500}
        margin={{ left: 70, right: 70, top: 20, bottom: 30 }}
        type="hybrid"
        seriesName="MSFT"
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        onLoadMore={this.handleDownloadMore}
        useCrossHairStyleCursor
      >
        <Chart
          id={1}
          height={400}
          yExtents={[d => [d.high, d.low], ema26.accessor(), ema12.accessor()]}
          padding={{ top: 10, bottom: 20 }}
          yZoomWidth={100}
        >
          <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} />
          <YAxis axisAt="right" orient="right" ticks={5} />

          <MouseCoordinateY at="right" orient="right" displayFormat={format('.2f')} />

          <CandlestickSeries
            stroke={d => (d.close > d.open ? '#09ff00' : '#DB0000')}
            wickStroke={d => (d.close > d.open ? '#09ff00' : '#DB0000')}
            fill={d => (d.close > d.open ? '#09ff00' : '#DB0000')}
          />

          {ema && <LineSeries yAccessor={ema26.accessor()} stroke={ema26.stroke()} />}

          {ema && <LineSeries yAccessor={ema12.accessor()} stroke={ema12.stroke()} />}

          {ema && <CurrentCoordinate yAccessor={ema26.accessor()} fill={ema26.stroke()} />}

          {ema && <CurrentCoordinate yAccessor={ema12.accessor()} fill={ema12.stroke()} />}

          <EdgeIndicator
            itemType="last"
            orient="right"
            edgeAt="right"
            yAccessor={d => d.close}
            fill={d => (d.close > d.open ? '#00b770' : '#FF0000')}
          />

          <OHLCTooltip origin={[-40, 0]} />

          <ZoomButtons onReset={this.handleReset} />

          {ema && (
            <MovingAverageTooltip
              onClick={e => console.log(e)}
              origin={[-38, 15]}
              options={[
                {
                  yAccessor: ema26.accessor(),
                  type: ema26.type(),
                  stroke: ema26.stroke(),
                  ...ema26.options(),
                },
                {
                  yAccessor: ema12.accessor(),
                  type: ema12.type(),
                  stroke: ema12.stroke(),
                  ...ema12.options(),
                },
              ]}
            />
          )}
        </Chart>

        {sma && (
          <Chart
            id={2}
            height={150}
            yExtents={[d => d.volume, smaVolume50.accessor()]}
            origin={(w, h) => [0, h - 300]}
          >
            <YAxis axisAt="left" orient="left" ticks={5} tickFormat={format('.2s')} />

            <MouseCoordinateY at="left" orient="left" displayFormat={format('.4s')} />

            <BarSeries
              yAccessor={d => d.volume}
              fill={d => (d.close > d.open ? '#00b770' : '#FF0000')}
            />
            <AreaSeries
              yAccessor={smaVolume50.accessor()}
              stroke={smaVolume50.stroke()}
              fill={smaVolume50.fill()}
            />
          </Chart>
        )}

        {macd && (
          <Chart
            id={3}
            height={150}
            yExtents={macdCalculator.accessor()}
            origin={(w, h) => [0, h - 150]}
            padding={{ top: 10, bottom: 10 }}
          >
            <XAxis axisAt="bottom" orient="bottom" />
            <YAxis axisAt="right" orient="right" ticks={2} />

            <MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d')} />
            <MouseCoordinateY at="right" orient="right" displayFormat={format('.2f')} />

            <MACDSeries yAccessor={d => d.macd} {...macdAppearance} />
            <MACDTooltip
              origin={[-38, 15]}
              yAccessor={d => d.macd}
              options={macdCalculator.options()}
              appearance={macdAppearance}
            />
          </Chart>
        )}
        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}

CandleStickChartPanToLoadMore.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
  switches: PropTypes.oneOf([
    'ema',
    'sma',
    'macd',
    'rsi',
    'wma',
    'tma',
    'bollingerBand',
    'heikinAshi',
    'kagi',
    'pointAndFigure',
    'renko',
    'atr',
    'stochasticOscillator',
    'forceIndex',
    'sar',
    'elderRay',
    'change',
    'elderImpulse',
    'compare',
  ]),
};

CandleStickChartPanToLoadMore.defaultProps = {
  type: 'svg',
};

CandleStickChartPanToLoadMore = fitWidth(CandleStickChartPanToLoadMore);

export default CandleStickChartPanToLoadMore;
