import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectCurrentlyViewingDataset as selectCurrentlyViewingDatasetAction } from '../redux/actions';
import { Icon, Card, Button, Checkbox, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import classes from './DatasetCard.module.css';
import LinkButton from '../uxElements/LinkButton';
import withSnackbar from '../hoc/withSnackbar';

class DatasetCard extends Component {
  static propTypes = {
    expandTrend: PropTypes.func,
    dataset: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.string,
      date: PropTypes.string,
    }).isRequired,
    snackbar: PropTypes.func,
    rsvp: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    currentUser: PropTypes.string,
    refetch: PropTypes.func,
    stopTracking: PropTypes.func,
    toggleDeleteModal: PropTypes.func,
    deleteDataset: PropTypes.func,
    selectDataset: PropTypes.func,
    deselectDatasets: PropTypes.func,
    isSelected: PropTypes.bool,
    selectedDatasetIds: PropTypes.array,
  };

  state = {
    deleteModalOpen: false,
  };

  handleCurrentlyViewingDatasetChange = async () => {
    await this.props.selectCurrentlyViewingDataset(this.props.dataset.id);
    this.props.history.push(`/viewDataset`);
  };

  render() {
    // // console.log('props dataset card', this.props);
    return (
      <React.Fragment>
        <div
          className={classes.datasetCards}
          onClick={event => {
            !this.props.isSelected && this.props.selectDataset(this.props.dataset, event);
          }}
        >
          <Card
            className={classNames(
              classes.datasetCard,
              this.props.isSelected ? classes.selected : classes.unselected,
            )}
          >
            <Checkbox
              onClick={event => {
                !this.props.isSelected
                  ? this.props.selectDataset(this.props.dataset, event)
                  : this.props.deselectDatasets();
              }}
              onDoubleClick={this.handleCurrentlyViewingDatasetChange}
              checked={this.props.isSelected}
              className={classes.cardReusableCheckbox}
            />
            <Card.Content
              style={{ fontSize: '10px', padding: '3px', textAlign: 'center' }}
              header={
                this.props.dataset.name.length > 10
                  ? this.props.dataset.name.slice(0, 35) + '...'
                  : this.props.dataset.name
              }
            />

            <Card.Content style={{ textAlign: 'center' }}>
              {!this.props.isSelected && (
                <Icon
                  style={{
                    color: 'rgba(44, 178, 255, 0.5)',
                    fontSize: '50px',
                    // marginLeft: '40%',
                    paddingTop: '10px',
                    marginBottom: '0',
                  }}
                  name="folder outline"
                />
              )}

              {this.props.isSelected && (
                <Icon
                  style={{
                    color: 'rgba(44, 178, 255, 0.5)',
                    fontSize: '50px',
                    // marginLeft: '40%',
                    paddingTop: '10px',
                    marginBottom: '0',
                  }}
                  name="folder open outline"
                ></Icon>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '0',
                  marginTop: '0',
                  textAlign: 'center',
                }}
              >
                {this.props.loadingDatasets && <Loader name="spinner" active />}

                <LinkButton
                  size="mini"
                  compact
                  inverted
                  color="blue"
                  style={{
                    width: '40%',
                    margin: '2px',
                  }}
                  onClick={this.handleCurrentlyViewingDatasetChange}
                  to={`/viewDataset`}
                  title={'Open'}
                />

                {this.props.isSelected && this.props.selectedDatasetIds.length === 1 && (
                  <Button
                    size="mini"
                    compact
                    inverted
                    color="red"
                    animated="fade"
                    style={{
                      width: '40%',
                      margin: '2px',
                    }}
                    onClick={e => this.props.toggleDeleteModal()}
                    disabled={this.props.currentUser !== this.props.dataset.userId}
                  >
                    <Button.Content visible>Delete</Button.Content>

                    <Button.Content hidden>
                      <Icon name="trash" />
                    </Button.Content>
                  </Button>
                )}
              </div>
            </Card.Content>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withSnackbar,
  withRouter,
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add in dispatch action functions here
      selectCurrentlyViewingDataset: id => {
        dispatch(selectCurrentlyViewingDatasetAction(id));
      },
    }),
  ),
)(DatasetCard);
