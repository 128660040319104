// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5YwRriTQqs0gx8HUJt3sDw\\=\\= {\n  animation: _5YwRriTQqs0gx8HUJt3sDw\\=\\= 0.75s linear infinite;\n}\n\n@keyframes _5YwRriTQqs0gx8HUJt3sDw\\=\\= {\n  0%,\n  16% {\n    transform: rotate(45deg);\n  }\n  17%,\n  33% {\n    transform: rotate(180deg);\n  }\n  34%,\n  50% {\n    transform: rotate(235deg);\n  }\n  51%,\n  67% {\n    transform: rotate(270deg);\n  }\n  68%,\n  82% {\n    transform: rotate(315deg);\n  }\n  83%,\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://src/frontend/components/datasets/terminal/terminal.module.css"],"names":[],"mappings":"AAAA;EACE,4DAAgD;AAClD;;AAEA;EACE;;IAEE,wBAAwB;EAC1B;EACA;;IAEE,yBAAyB;EAC3B;EACA;;IAEE,yBAAyB;EAC3B;EACA;;IAEE,yBAAyB;EAC3B;EACA;;IAEE,yBAAyB;EAC3B;EACA;;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading-stepped {\n  animation: loading-stepped 0.75s linear infinite;\n}\n\n@keyframes loading-stepped {\n  0%,\n  16% {\n    transform: rotate(45deg);\n  }\n  17%,\n  33% {\n    transform: rotate(180deg);\n  }\n  34%,\n  50% {\n    transform: rotate(235deg);\n  }\n  51%,\n  67% {\n    transform: rotate(270deg);\n  }\n  68%,\n  82% {\n    transform: rotate(315deg);\n  }\n  83%,\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-stepped": "_5YwRriTQqs0gx8HUJt3sDw=="
};
export default ___CSS_LOADER_EXPORT___;
