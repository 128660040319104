const TickerData = [
  {
    Ticker: 'AA',
    Company: 'Alcoa Corp'
  },
  {
    Ticker: 'AAN',
    Company: "Aaron's Inc"
  },
  {
    Ticker: 'AAP',
    Company: 'Advance Auto Parts Inc'
  },
  {
    Ticker: 'AAT',
    Company: 'American Assets Trust'
  },
  {
    Ticker: 'AB',
    Company: 'Alliancebernstein Holding LP'
  },
  {
    Ticker: 'ABB',
    Company: 'Abb Ltd'
  },
  {
    Ticker: 'ABBV',
    Company: 'Abbvie Inc'
  },
  {
    Ticker: 'ABC',
    Company: 'Amerisourcebergen Corp'
  },
  {
    Ticker: 'ABEV',
    Company: 'Ambev S.A.'
  },
  {
    Ticker: 'ABG',
    Company: 'Asbury Automotive Group Inc'
  },
  {
    Ticker: 'ABM',
    Company: 'ABM Industries Incorporated'
  },
  {
    Ticker: 'ABR',
    Company: 'Arbor Realty Trust'
  },
  {
    Ticker: 'ABR-A',
    Company: 'Arbor Rlty TR Inc [Abr/Pa]'
  },
  {
    Ticker: 'ABR-B',
    Company: 'Arbor Rlty TR Inc [Abr/Pb]'
  },
  {
    Ticker: 'ABR-C',
    Company: 'Arbor Rlty TR Inc [Abr/Pc]'
  },
  {
    Ticker: 'ABT',
    Company: 'Abbott Laboratories'
  },
  {
    Ticker: 'AC',
    Company: 'Associated Capital Group Inc'
  },
  {
    Ticker: 'ACA',
    Company: 'Arcosa Inc'
  },
  {
    Ticker: 'ACB',
    Company: 'Aurora Cannabis Inc'
  },
  {
    Ticker: 'ACC',
    Company: 'American Campus Communities Inc'
  },
  {
    Ticker: 'ACCO',
    Company: 'Acco Brands Corp'
  },
  {
    Ticker: 'ACEL',
    Company: 'Accel Entertainment Inc.'
  },
  {
    Ticker: 'ACH',
    Company: 'Aluminum Corp of China Ltd'
  },
  {
    Ticker: 'ACM',
    Company: 'Aecom Technology Corp'
  },
  {
    Ticker: 'ACN',
    Company: 'Accenture Plc'
  },
  {
    Ticker: 'ACP',
    Company: 'Avenue Income Credit Strategies'
  },
  {
    Ticker: 'ACRE',
    Company: 'Ares Commercial Real Estate Cor'
  },
  {
    Ticker: 'ACV',
    Company: 'Allianzgi Diversified Income &'
  },
  {
    Ticker: 'ADC',
    Company: 'Agree Realty Corp'
  },
  {
    Ticker: 'ADM',
    Company: 'Archer Daniels Midland'
  },
  {
    Ticker: 'ADNT',
    Company: 'Adient Plc'
  },
  {
    Ticker: 'ADS',
    Company: 'Alliance Data Systems Corp'
  },
  {
    Ticker: 'ADSW',
    Company: 'Advanced Disposal Services Inc'
  },
  {
    Ticker: 'ADT',
    Company: 'ADT Inc'
  },
  {
    Ticker: 'ADX',
    Company: 'Adams Express Company'
  },
  {
    Ticker: 'AEB',
    Company: 'Aegon N.V. Perp Cap Secs Floating Rate [Ne]'
  },
  {
    Ticker: 'AEE',
    Company: 'Ameren Corp'
  },
  {
    Ticker: 'AEFC',
    Company: 'Aegon Funding Company Llc 5.10% Subordinated Not'
  },
  {
    Ticker: 'AEG',
    Company: 'Aegon N.V.'
  },
  {
    Ticker: 'AEH',
    Company: 'Aegon N.V. Perp Cap Secs'
  },
  {
    Ticker: 'AEL',
    Company: 'American Equity Investment Life'
  },
  {
    Ticker: 'AEL-A',
    Company: 'American Equity Investment Life Holding CO 5.95%'
  },
  {
    Ticker: 'AEM',
    Company: 'Agnico-Eagle Mines Ltd'
  },
  {
    Ticker: 'AEO',
    Company: 'American Eagle Outfitters'
  },
  {
    Ticker: 'AEP',
    Company: 'American Electric Power Company'
  },
  {
    Ticker: 'AEP-B',
    Company: 'American Elec Pwr Co. Inc [Aep/Pb]'
  },
  {
    Ticker: 'AER',
    Company: 'Aercap Holdings N.V.'
  },
  {
    Ticker: 'AES',
    Company: 'The Aes Corp'
  },
  {
    Ticker: 'AFB',
    Company: 'Alliance National Municipal'
  },
  {
    Ticker: 'AFC',
    Company: 'Allied Capital Corp'
  },
  {
    Ticker: 'AFG',
    Company: 'American Financial Group'
  },
  {
    Ticker: 'AFGB',
    Company: 'American Financial Group Inc 5.875% Subordinat'
  },
  {
    Ticker: 'AFGC',
    Company: 'American Financial Group Inc 5.125% Subordinate'
  },
  {
    Ticker: 'AFGE',
    Company: 'American Financial Group Inc'
  },
  {
    Ticker: 'AFGH',
    Company: 'American Financial Group Inc'
  },
  {
    Ticker: 'AFI',
    Company: 'Armstrong Flooring Inc'
  },
  {
    Ticker: 'AFL',
    Company: 'Aflac Incorporated'
  },
  {
    Ticker: 'AFT',
    Company: 'Apollo Senior Floating Rate Fund Inc'
  },
  {
    Ticker: 'AG',
    Company: 'First Majestic Silver'
  },
  {
    Ticker: 'AGCO',
    Company: 'Agco Corp'
  },
  {
    Ticker: 'AGD',
    Company: 'Alpine Global Dynamic Dividend Fund'
  },
  {
    Ticker: 'AGI',
    Company: 'Alamos Gold Inc'
  },
  {
    Ticker: 'AGM',
    Company: 'Federal Agricultural Mortgage Corp'
  },
  {
    Ticker: 'AGM-A',
    Company: 'Federal Agricultural Mortgage Corp [Agm/Pa]'
  },
  {
    Ticker: 'AGM-C',
    Company: 'Federal Agricultural Mortgage Corp [Agm/Pc]'
  },
  {
    Ticker: 'AGM-D',
    Company: 'Federal Agricultural Mortgage Corp [Agm/Pd]'
  },
  {
    Ticker: 'AGM.A',
    Company: 'Federal Agricultural Mortgage Corp'
  },
  {
    Ticker: 'AGN',
    Company: 'Allergan Plc'
  },
  {
    Ticker: 'AGO',
    Company: 'Assured Guaranty Ltd'
  },
  {
    Ticker: 'AGO-B',
    Company: 'Assured Guaranty Municipal Hlds IN [Ago/Pb]'
  },
  {
    Ticker: 'AGO-E',
    Company: 'Assured Guaranty Municipal Hlds IN [Ago/Pe]'
  },
  {
    Ticker: 'AGO-F',
    Company: 'Assured Guaranty Municipal Hlds IN [Ago/Pf]'
  },
  {
    Ticker: 'AGR',
    Company: 'Avangrid Inc'
  },
  {
    Ticker: 'AGRO',
    Company: 'Adecoagro S.A.'
  },
  {
    Ticker: 'AGS',
    Company: 'Playags Inc'
  },
  {
    Ticker: 'AGX',
    Company: 'Argan Inc'
  },
  {
    Ticker: 'AHC',
    Company: 'A.H. Belo Corp'
  },
  {
    Ticker: 'AHH',
    Company: 'Armada Hoffler Properties Inc'
  },
  {
    Ticker: 'AHH-A',
    Company: 'Armada Hoffler Pptys Inc [Ahh/Pa]'
  },
  {
    Ticker: 'AHL-C',
    Company: 'Aspen Insurance Holdings Ltd [Ahl/Pc]'
  },
  {
    Ticker: 'AHL-D',
    Company: 'Aspen Insurance Holdings Ltd [Ahl/Pd]'
  },
  {
    Ticker: 'AHL-E',
    Company: 'Aspen Insurance Holdings Ltd [Ahl/Pe]'
  },
  {
    Ticker: 'AHT',
    Company: 'Ashford Hospitality Trust Inc'
  },
  {
    Ticker: 'AHT-D',
    Company: 'Ashford Hospitality TR Inc [Aht/Pd]'
  },
  {
    Ticker: 'AHT-F',
    Company: 'Ashford Hospitality TR Inc [Aht/Pf]'
  },
  {
    Ticker: 'AHT-G',
    Company: 'Ashford Hospitality TR Inc [Aht/Pg]'
  },
  {
    Ticker: 'AHT-H',
    Company: 'Ashford Hospitality TR Inc [Aht/Ph]'
  },
  {
    Ticker: 'AHT-I',
    Company: 'Ashford Hospitality TR Inc [Aht/Pi]'
  },
  {
    Ticker: 'AI',
    Company: 'Arlington Asset Investment Corp'
  },
  {
    Ticker: 'AI-B',
    Company: 'Arlington Asset Investment Corp [Ai/Pb]'
  },
  {
    Ticker: 'AI-C',
    Company: 'Arlington Asset Investment Corp [Ai/Pc]'
  },
  {
    Ticker: 'AIC',
    Company: 'Arlington Asset Investment Cor'
  },
  {
    Ticker: 'AIF',
    Company: 'Apollo Tactical Income Fund Inc'
  },
  {
    Ticker: 'AIG',
    Company: 'American International Group'
  },
  {
    Ticker: 'AIG-A',
    Company: 'American International Group Inc [Aig/Pa]'
  },
  {
    Ticker: 'AIG.W',
    Company: 'American Intl Group Inc WT [Aig/W]'
  },
  {
    Ticker: 'AIN',
    Company: 'Albany International Corp'
  },
  {
    Ticker: 'AIO',
    Company: 'Allianzgi Artificial Intelligence & Technology O'
  },
  {
    Ticker: 'AIR',
    Company: 'AAR Corp'
  },
  {
    Ticker: 'AIT',
    Company: 'Applied Industrial Technologies'
  },
  {
    Ticker: 'AIV',
    Company: 'Apartment Investment and Management'
  },
  {
    Ticker: 'AIW',
    Company: 'Arlington Asset Investment Cor'
  },
  {
    Ticker: 'AIZ',
    Company: 'Assurant Inc'
  },
  {
    Ticker: 'AIZP',
    Company: 'Assurant Inc 6.50% Series D Mandatory Converti'
  },
  {
    Ticker: 'AJG',
    Company: 'Arthur J. Gallagher & Company'
  },
  {
    Ticker: 'AJRD',
    Company: 'Aerojet Rocketdyne Holdings'
  },
  {
    Ticker: 'AJX',
    Company: 'Great Ajax Corp'
  },
  {
    Ticker: 'AJXA',
    Company: 'Great Ajax Corp 7.25% Convertible Senior Notes'
  },
  {
    Ticker: 'AKO.A',
    Company: 'Embotell Andina Sa Cl A'
  },
  {
    Ticker: 'AKO.B',
    Company: 'Embotell Andna Sa Cl B'
  },
  {
    Ticker: 'AKR',
    Company: 'Acadia Realty Trust'
  },
  {
    Ticker: 'AKS',
    Company: 'AK Steel Holding Corp'
  },
  {
    Ticker: 'AL',
    Company: 'Air Lease Corp Cl A'
  },
  {
    Ticker: 'AL-A',
    Company: 'Air Lease Corp [Al/Pa]'
  },
  {
    Ticker: 'ALB',
    Company: 'Albemarle Corp'
  },
  {
    Ticker: 'ALC',
    Company: 'Alcon Inc'
  },
  {
    Ticker: 'ALE',
    Company: 'Allete Inc'
  },
  {
    Ticker: 'ALEX',
    Company: 'Alexander and Baldwin Inc'
  },
  {
    Ticker: 'ALG',
    Company: 'Alamo Group'
  },
  {
    Ticker: 'ALK',
    Company: 'Alaska Air Group'
  },
  {
    Ticker: 'ALL',
    Company: 'Allstate Corp'
  },
  {
    Ticker: 'ALL-A',
    Company: 'Allstate Corp [All/Pa]'
  },
  {
    Ticker: 'ALL-B',
    Company: 'Allstate Corp [All/Pb]'
  },
  {
    Ticker: 'ALL-G',
    Company: 'Allstate Corp [All/Pg]'
  },
  {
    Ticker: 'ALL-H',
    Company: 'Allstate Corp [All/Ph]'
  },
  {
    Ticker: 'ALL-I',
    Company: 'Allstate Corp 4.75% Prf Perpetual USD 25'
  },
  {
    Ticker: 'ALL-Y',
    Company: 'GMAC Capital Trust I [Ally/Pa]'
  },
  {
    Ticker: 'ALLE',
    Company: 'Allegion Plc'
  },
  {
    Ticker: 'ALLY',
    Company: 'Ally Financial'
  },
  {
    Ticker: 'ALP-Q',
    Company: 'Alabama Power Co. [Alp/Pq]'
  },
  {
    Ticker: 'ALSN',
    Company: 'Allison Transmission Holdings'
  },
  {
    Ticker: 'ALV',
    Company: 'Autoliv Inc'
  },
  {
    Ticker: 'ALX',
    Company: "Alexander's Inc"
  },
  {
    Ticker: 'AM',
    Company: 'Antero Midstream Corp'
  },
  {
    Ticker: 'AMC',
    Company: 'Amc Entertainment Holdings Inc'
  },
  {
    Ticker: 'AMCR',
    Company: 'Amcor Plc'
  },
  {
    Ticker: 'AME',
    Company: 'Amtek Inc'
  },
  {
    Ticker: 'AMG',
    Company: 'Affiliated Managers Group'
  },
  {
    Ticker: 'AMH',
    Company: 'American Homes 4 Rent'
  },
  {
    Ticker: 'AMH-D',
    Company: 'American Homes 4 Rent [Amh/Pd]'
  },
  {
    Ticker: 'AMH-E',
    Company: 'American Homes 4 Rent [Amh/Pe]'
  },
  {
    Ticker: 'AMH-F',
    Company: 'American Homes 4 Rent [Amh/Pf]'
  },
  {
    Ticker: 'AMH-G',
    Company: 'American Homes 4 Rent [Amh/Pg]'
  },
  {
    Ticker: 'AMH-H',
    Company: 'American Homes 4 Rent [Amh/Ph]'
  },
  {
    Ticker: 'AMK',
    Company: 'Assetmark Financial Holdings Inc'
  },
  {
    Ticker: 'AMN',
    Company: 'Amn Healthcare Services Inc'
  },
  {
    Ticker: 'AMOV',
    Company: 'America Movil A ADR'
  },
  {
    Ticker: 'AMP',
    Company: 'Ameriprise Financial Services'
  },
  {
    Ticker: 'AMPY',
    Company: 'Amplify Energy Corp'
  },
  {
    Ticker: 'AMRC',
    Company: 'Ameresco Inc'
  },
  {
    Ticker: 'AMRX',
    Company: 'Amneal Pharmaceuticals Inc'
  },
  {
    Ticker: 'AMT',
    Company: 'American Tower Corp'
  },
  {
    Ticker: 'AMX',
    Company: 'America Movil S.A.B. DE C.V.'
  },
  {
    Ticker: 'AN',
    Company: 'Autonation Inc'
  },
  {
    Ticker: 'ANET',
    Company: 'Arista Networks Inc'
  },
  {
    Ticker: 'ANF',
    Company: 'Abercrombie & Fitch Company'
  },
  {
    Ticker: 'ANFI',
    Company: 'Amira Nature Foods Ltd'
  },
  {
    Ticker: 'ANH',
    Company: 'Anworth Mortgage Asset Corp'
  },
  {
    Ticker: 'ANH-A',
    Company: 'Anworth Mortgage Asset Cp [Anh/Pa]'
  },
  {
    Ticker: 'ANH-B',
    Company: 'Anworth Mortgage Asset Cp [Anh/Pb]'
  },
  {
    Ticker: 'ANH-C',
    Company: 'Anworth Mortgage Asset Cp [Anh/Pc]'
  },
  {
    Ticker: 'ANTM',
    Company: 'Anthem Inc'
  },
  {
    Ticker: 'AOD',
    Company: 'Alpine Total Dynamic Dividend'
  },
  {
    Ticker: 'AON',
    Company: 'AON Plc'
  },
  {
    Ticker: 'AOS',
    Company: 'Smith [A.O.] Corp'
  },
  {
    Ticker: 'AP',
    Company: 'Ampco-Pittsburgh Corp'
  },
  {
    Ticker: 'APA',
    Company: 'Apache Corp'
  },
  {
    Ticker: 'APAM',
    Company: 'Artisan Partners Asset Mgmt'
  },
  {
    Ticker: 'APD',
    Company: 'Air Products and Chemicals'
  },
  {
    Ticker: 'APH',
    Company: 'Amphenol Corp'
  },
  {
    Ticker: 'APHA',
    Company: 'Aphria Inc'
  },
  {
    Ticker: 'APLE',
    Company: 'Apple Hospitality REIT Inc'
  },
  {
    Ticker: 'APO',
    Company: 'Apollo Global Management Llc C'
  },
  {
    Ticker: 'APO-A',
    Company: 'Apollo Global Mgmt Inc [Apo/Pa]'
  },
  {
    Ticker: 'APO-B',
    Company: 'Apollo Global Mgmt Inc [Apo/Pb]'
  },
  {
    Ticker: 'APRN',
    Company: 'Blue Apron Holdings Inc'
  },
  {
    Ticker: 'APTS',
    Company: 'Preferred Apartment Communities'
  },
  {
    Ticker: 'APTV',
    Company: 'Aptiv Plc'
  },
  {
    Ticker: 'APY',
    Company: 'Apergy Corp'
  },
  {
    Ticker: 'AQN',
    Company: 'Algonquin Pwr & Util'
  },
  {
    Ticker: 'AQNA',
    Company: 'Algonquin Power & Utilities Corp'
  },
  {
    Ticker: 'AQNB',
    Company: 'Algonquin Power & Utilities Corp 6.20% Fixed-To'
  },
  {
    Ticker: 'AQUA',
    Company: 'Evoqua Water Technologies Corp'
  },
  {
    Ticker: 'AR',
    Company: 'Antero Resources Corp'
  },
  {
    Ticker: 'ARA',
    Company: 'American Renal Associates'
  },
  {
    Ticker: 'ARC',
    Company: 'American Reprographics Company'
  },
  {
    Ticker: 'ARCH',
    Company: 'Arch Coal Inc'
  },
  {
    Ticker: 'ARCO',
    Company: 'Arcos Dorados Holdings Inc'
  },
  {
    Ticker: 'ARD',
    Company: 'Ardagh Group S.A.'
  },
  {
    Ticker: 'ARDC',
    Company: 'Ares Dynamic Credit Allocation'
  },
  {
    Ticker: 'ARE',
    Company: 'Alexandria Real Estate Equities'
  },
  {
    Ticker: 'ARE-A',
    Company: 'Ares Management Corp [Ares/Pa]'
  },
  {
    Ticker: 'ARES',
    Company: 'Ares Management LP'
  },
  {
    Ticker: 'ARGD',
    Company: 'Argo Grp Itl Snr NTS'
  },
  {
    Ticker: 'ARGO',
    Company: 'Argo Group Intl Hlds'
  },
  {
    Ticker: 'ARI',
    Company: 'Apollo Commercial Real Estate'
  },
  {
    Ticker: 'ARL',
    Company: 'American Realty Investors'
  },
  {
    Ticker: 'ARLO',
    Company: 'Arlo Technologies Inc'
  },
  {
    Ticker: 'ARMK',
    Company: 'Aramark Holdings Corp'
  },
  {
    Ticker: 'ARNC',
    Company: 'Arconic Inc'
  },
  {
    Ticker: 'AROC',
    Company: 'Archrock Inc'
  },
  {
    Ticker: 'ARR',
    Company: 'Armour Residential R'
  },
  {
    Ticker: 'ARR-B',
    Company: 'Armour Residential REIT Inc [Arr/Pb]'
  },
  {
    Ticker: 'ARW',
    Company: 'Arrow Electronics'
  },
  {
    Ticker: 'ASA',
    Company: 'ASA Gold and Precious Metals'
  },
  {
    Ticker: 'ASB',
    Company: 'Associated Banc-Corp'
  },
  {
    Ticker: 'ASB-C',
    Company: 'Associated Banc-Corp [Asb/Pc]'
  },
  {
    Ticker: 'ASB-D',
    Company: 'Associated Banc-Corp [Asb/Pd]'
  },
  {
    Ticker: 'ASB-E',
    Company: 'Associated Banc-Corp [Asb/Pe]'
  },
  {
    Ticker: 'ASC',
    Company: 'Ardmore Shipping Corp'
  },
  {
    Ticker: 'ASG',
    Company: 'Liberty All-Star Growth Fund'
  },
  {
    Ticker: 'ASGN',
    Company: 'On Assignment'
  },
  {
    Ticker: 'ASH',
    Company: 'Ashland Global Holdings Inc'
  },
  {
    Ticker: 'ASIX',
    Company: 'Advansix Inc'
  },
  {
    Ticker: 'ASPN',
    Company: 'Aspen Aerogels Inc'
  },
  {
    Ticker: 'ASR',
    Company: 'Grupo Aeroportuario Del Sureste'
  },
  {
    Ticker: 'ASX',
    Company: 'Ase Industrial Holding Co. Ltd'
  },
  {
    Ticker: 'AT',
    Company: 'Atlantic Power Corp'
  },
  {
    Ticker: 'ATEN',
    Company: 'A10 Networks Inc'
  },
  {
    Ticker: 'ATGE',
    Company: 'Adtalem Global Education Inc'
  },
  {
    Ticker: 'ATH',
    Company: 'Athene Holding Ltd'
  },
  {
    Ticker: 'ATH-A',
    Company: 'Athene Hld Ltd [Ath/Pa]'
  },
  {
    Ticker: 'ATH-B',
    Company: 'Antah Hlds Ltd'
  },
  {
    Ticker: 'ATHM',
    Company: 'Autohome Inc'
  },
  {
    Ticker: 'ATI',
    Company: 'Allegheny Technologies Inc'
  },
  {
    Ticker: 'ATKR',
    Company: 'Atkore International Group'
  },
  {
    Ticker: 'ATO',
    Company: 'Atmos Energy Corp'
  },
  {
    Ticker: 'ATR',
    Company: 'Aptargroup'
  },
  {
    Ticker: 'ATTO',
    Company: 'Atento S.A.'
  },
  {
    Ticker: 'ATUS',
    Company: 'Altice USA Inc Cl A'
  },
  {
    Ticker: 'ATV',
    Company: 'Acorn International'
  },
  {
    Ticker: 'AU',
    Company: 'Anglogold Ashanti Ltd'
  },
  {
    Ticker: 'AUY',
    Company: 'Yamana Gold'
  },
  {
    Ticker: 'AVA',
    Company: 'Avista Corp'
  },
  {
    Ticker: 'AVAL',
    Company: 'Grupo Aval Acciones Y Valores S'
  },
  {
    Ticker: 'AVB',
    Company: 'Avalonbay Communities'
  },
  {
    Ticker: 'AVD',
    Company: 'American Vanguard Corp'
  },
  {
    Ticker: 'AVH',
    Company: 'Avianca Holdings S.A.'
  },
  {
    Ticker: 'AVK',
    Company: 'Advent Claymore Convertible Securities'
  },
  {
    Ticker: 'AVLR',
    Company: 'Avalara Inc'
  },
  {
    Ticker: 'AVNS',
    Company: 'Avanos Medical Inc'
  },
  {
    Ticker: 'AVP',
    Company: 'Avon Products'
  },
  {
    Ticker: 'AVT-A',
    Company: 'Avantor Inc [Avtr/Pa]'
  },
  {
    Ticker: 'AVTR',
    Company: 'Avantor Inc'
  },
  {
    Ticker: 'AVX',
    Company: 'Avx Corp'
  },
  {
    Ticker: 'AVY',
    Company: 'Avery Dennison Corp'
  },
  {
    Ticker: 'AVYA',
    Company: 'Avaya Holdings Corp'
  },
  {
    Ticker: 'AWF',
    Company: 'Alliancebernstein Global High Income Fund'
  },
  {
    Ticker: 'AWI',
    Company: 'Armstrong World Industries Inc'
  },
  {
    Ticker: 'AWK',
    Company: 'American Water Works'
  },
  {
    Ticker: 'AWP',
    Company: 'Alpine Global Premier Propertie'
  },
  {
    Ticker: 'AWR',
    Company: 'American States Water Company'
  },
  {
    Ticker: 'AX',
    Company: 'Axos Financial Inc'
  },
  {
    Ticker: 'AXE',
    Company: 'Anixter International Inc'
  },
  {
    Ticker: 'AXL',
    Company: 'American Axle & Manufacturing'
  },
  {
    Ticker: 'AXO',
    Company: 'Axos Financial Inc'
  },
  {
    Ticker: 'AXP',
    Company: 'American Express Company'
  },
  {
    Ticker: 'AXR',
    Company: 'Amrep Corp'
  },
  {
    Ticker: 'AXS',
    Company: 'Axis Capital Holdings'
  },
  {
    Ticker: 'AXS-D',
    Company: 'Axis Capital Holdings Ltd [Axs/Pd]'
  },
  {
    Ticker: 'AXS-E',
    Company: 'Axis Capital Holdings Ltd [Axs/Pe]'
  },
  {
    Ticker: 'AXTA',
    Company: 'Axalta Coating Systems Ltd'
  },
  {
    Ticker: 'AYI',
    Company: 'Acuity Brands Inc'
  },
  {
    Ticker: 'AYR',
    Company: 'Aircastle Ltd'
  },
  {
    Ticker: 'AYX',
    Company: 'Alteryx Inc'
  },
  {
    Ticker: 'AZN',
    Company: 'Astrazeneca Plc'
  },
  {
    Ticker: 'AZO',
    Company: 'Autozone'
  },
  {
    Ticker: 'AZRE',
    Company: 'Azure Power Global Ltd'
  },
  {
    Ticker: 'AZUL',
    Company: 'Azul S.A. ADR'
  },
  {
    Ticker: 'AZZ',
    Company: 'Azz Inc'
  },
  {
    Ticker: 'B',
    Company: 'Barnes Group'
  },
  {
    Ticker: 'BA',
    Company: 'Boeing Company'
  },
  {
    Ticker: 'BABA',
    Company: 'Alibaba Group Holding'
  },
  {
    Ticker: 'BAC',
    Company: 'Bank of America Corp'
  },
  {
    Ticker: 'BAC-A',
    Company: 'Bank of America Corp [Bac/Pa]'
  },
  {
    Ticker: 'BAC-B',
    Company: 'Bank of America Corp [Bac/Pb]'
  },
  {
    Ticker: 'BAC-C',
    Company: 'Bank of America Corp [Bac/Pc]'
  },
  {
    Ticker: 'BAC-E',
    Company: 'Bank of America Corp [Bac/Pe]'
  },
  {
    Ticker: 'BAC-K',
    Company: 'Bank of America Corp [Bac/Pk]'
  },
  {
    Ticker: 'BAC-L',
    Company: 'Bank of America Corp [Bac/Pl]'
  },
  {
    Ticker: 'BAC-M',
    Company: 'Bank of America Corp [Bac/Pm]'
  },
  {
    Ticker: 'BAC-N',
    Company: 'Bank of America Corp Prf Perpetual USD Ser LL D'
  },
  {
    Ticker: 'BAC-Y',
    Company: 'Bank of America Corp [Bac/Py]'
  },
  {
    Ticker: 'BAF',
    Company: 'Blackrock Income Inv Quality Trust'
  },
  {
    Ticker: 'BAH',
    Company: 'Booz Allen Hamilton Holding Corp'
  },
  {
    Ticker: 'BAK',
    Company: 'Braskem S.A.'
  },
  {
    Ticker: 'BAM',
    Company: 'Brookfield Asset Management Inc'
  },
  {
    Ticker: 'BAN-D',
    Company: 'Banc of California Inc [Banc/Pd]'
  },
  {
    Ticker: 'BAN-E',
    Company: 'Banc of California Inc [Banc/Pe]'
  },
  {
    Ticker: 'BANC',
    Company: 'First Pactrust Bancorp'
  },
  {
    Ticker: 'BAP',
    Company: 'Credicorp Ltd'
  },
  {
    Ticker: 'BAS',
    Company: 'Basic Energy Services'
  },
  {
    Ticker: 'BAX',
    Company: 'Baxter International Inc'
  },
  {
    Ticker: 'BB',
    Company: 'Blackberry Ltd'
  },
  {
    Ticker: 'BBAR',
    Company: 'Bbva Banco Frances S.A.'
  },
  {
    Ticker: 'BBD',
    Company: 'Banco Bradesco S.A.'
  },
  {
    Ticker: 'BBDC',
    Company: 'Barings Bdc Inc'
  },
  {
    Ticker: 'BBDO',
    Company: 'Banco Bradesco S.A.'
  },
  {
    Ticker: 'BBF',
    Company: 'Blackrock Muni Income Trust'
  },
  {
    Ticker: 'BBK',
    Company: 'Blackrock Muni Trust'
  },
  {
    Ticker: 'BBL',
    Company: 'Bhp Billiton Plc'
  },
  {
    Ticker: 'BBN',
    Company: 'Balckrock Build America Trust'
  },
  {
    Ticker: 'BBT',
    Company: 'BB&T Corp'
  },
  {
    Ticker: 'BBT-F',
    Company: 'BB&T Corp [Bbt/Pf]'
  },
  {
    Ticker: 'BBT-G',
    Company: 'BB&T Corp [Bbt/Pg]'
  },
  {
    Ticker: 'BBT-H',
    Company: 'BB&T Corp [Bbt/Ph]'
  },
  {
    Ticker: 'BBU',
    Company: 'Brookfield Business Partners LP'
  },
  {
    Ticker: 'BBVA',
    Company: 'Banco Bilbao Viscaya Argentaria S.A.'
  },
  {
    Ticker: 'BBW',
    Company: 'Build-A-Bear Workshop'
  },
  {
    Ticker: 'BBX',
    Company: 'Bbx Capital Corp'
  },
  {
    Ticker: 'BBY',
    Company: 'Best Buy Company'
  },
  {
    Ticker: 'BC',
    Company: 'Brunswick Corp'
  },
  {
    Ticker: 'BC-A',
    Company: 'Brunswick Corp [Bc/Pa]'
  },
  {
    Ticker: 'BC-B',
    Company: 'Brunswick Corp [Bc/Pb]'
  },
  {
    Ticker: 'BC-C',
    Company: 'Brunswick Corp [Bc/Pc]'
  },
  {
    Ticker: 'BCC',
    Company: 'Boise Cascade L.L.C.'
  },
  {
    Ticker: 'BCE',
    Company: 'BCE Inc'
  },
  {
    Ticker: 'BCEI',
    Company: 'Bonanza Creek Energy Inc'
  },
  {
    Ticker: 'BCH',
    Company: 'Banco DE Chile'
  },
  {
    Ticker: 'BCO',
    Company: "Brink's Company"
  },
  {
    Ticker: 'BCRH',
    Company: 'Blue Capital Reinsurance'
  },
  {
    Ticker: 'BCS',
    Company: 'Barclays Plc'
  },
  {
    Ticker: 'BCSF',
    Company: 'Bain Capital Specialty Finance Inc'
  },
  {
    Ticker: 'BCX',
    Company: 'Blackrock Resources'
  },
  {
    Ticker: 'BDC',
    Company: 'Belden Inc'
  },
  {
    Ticker: 'BDJ',
    Company: 'Blackrock Enhanced Dividend Achievers'
  },
  {
    Ticker: 'BDN',
    Company: 'Brandywine Realty Trust'
  },
  {
    Ticker: 'BDX',
    Company: 'Becton Dickinson and Company'
  },
  {
    Ticker: 'BDXA',
    Company: 'Becton Dickinson & Company'
  },
  {
    Ticker: 'BE',
    Company: 'Bloom Energy Corp Cl A'
  },
  {
    Ticker: 'BEDU',
    Company: 'Bright Scholar Education Holdings Ltd'
  },
  {
    Ticker: 'BEN',
    Company: 'Franklin Resources'
  },
  {
    Ticker: 'BEP',
    Company: 'Brookfield Renewable'
  },
  {
    Ticker: 'BERY',
    Company: 'Berry Global Group'
  },
  {
    Ticker: 'BEST',
    Company: 'Best Inc'
  },
  {
    Ticker: 'BF.A',
    Company: 'Brown Forman Inc Cl A'
  },
  {
    Ticker: 'BF.B',
    Company: 'Brown Forman Inc Cl B'
  },
  {
    Ticker: 'BFAM',
    Company: 'Bright Horizons Family Solutions Inc'
  },
  {
    Ticker: 'BFK',
    Company: 'Blackrock Muni Income Trust'
  },
  {
    Ticker: 'BFO',
    Company: 'Blackrock Florida Muni 2020 Trust'
  },
  {
    Ticker: 'BFS',
    Company: 'Saul Centers'
  },
  {
    Ticker: 'BFS-D',
    Company: 'Saul Ctrs Inc [Bfs/Pd]'
  },
  {
    Ticker: 'BFS-E',
    Company: 'Saul Centers Inc Prf Perpetual USD Ser E Rep 1/1'
  },
  {
    Ticker: 'BFY',
    Company: 'Blackrock New York Muni Trust II'
  },
  {
    Ticker: 'BFZ',
    Company: 'Blackrock California Muni Trust'
  },
  {
    Ticker: 'BG',
    Company: 'Bunge Ltd'
  },
  {
    Ticker: 'BGB',
    Company: 'Blackstone / Gso Strategic Cre'
  },
  {
    Ticker: 'BGG',
    Company: 'Briggs & Stratton Corp'
  },
  {
    Ticker: 'BGH',
    Company: 'Babson Capital Global Short Du'
  },
  {
    Ticker: 'BGIO',
    Company: 'Blackrock 2022 Global Income Opportunity Trust'
  },
  {
    Ticker: 'BGR',
    Company: 'Blackrock Energy and Resources Trust'
  },
  {
    Ticker: 'BGS',
    Company: 'B&G Foods Holdings'
  },
  {
    Ticker: 'BGSF',
    Company: 'Bg Staffing'
  },
  {
    Ticker: 'BGT',
    Company: 'Blackrock Global'
  },
  {
    Ticker: 'BGX',
    Company: 'Blackstone Gso Long Short Credit Fund'
  },
  {
    Ticker: 'BGY',
    Company: 'Blackrock International'
  },
  {
    Ticker: 'BH',
    Company: 'Biglari Holdings Inc'
  },
  {
    Ticker: 'BH.A',
    Company: 'Biglari Holdings Inc Cl A'
  },
  {
    Ticker: 'BHC',
    Company: 'Bausch Health Companies Inc'
  },
  {
    Ticker: 'BHE',
    Company: 'Benchmark Electronics'
  },
  {
    Ticker: 'BHK',
    Company: 'Blackrock Core Trust'
  },
  {
    Ticker: 'BHLB',
    Company: 'Berkshire Hills Bancorp'
  },
  {
    Ticker: 'BHP',
    Company: 'Bhp Billiton Ltd'
  },
  {
    Ticker: 'BHR',
    Company: 'Braemar Hotels & Resorts Inc'
  },
  {
    Ticker: 'BHR-B',
    Company: 'Braemar Hotels & Resorts Inc [Bhr/Pb]'
  },
  {
    Ticker: 'BHR-D',
    Company: 'Braemar Hotels & Resorts Inc [Bhr/Pd]'
  },
  {
    Ticker: 'BHV',
    Company: 'Blackrock Virginia Muni Trust'
  },
  {
    Ticker: 'BHVN',
    Company: 'Biohaven Pharmaceutical Holding Company Ltd'
  },
  {
    Ticker: 'BIF',
    Company: 'Boulder Growth & Income Fund Inc'
  },
  {
    Ticker: 'BIG',
    Company: 'Big Lots'
  },
  {
    Ticker: 'BIO',
    Company: 'Bio-Rad Laboratories'
  },
  {
    Ticker: 'BIO.B',
    Company: 'Bio Rad Labs Cl B'
  },
  {
    Ticker: 'BIP',
    Company: 'Brookfield Infrastructure Partners LP'
  },
  {
    Ticker: 'BIT',
    Company: 'Blackrock Multi-Sector Income T'
  },
  {
    Ticker: 'BITA',
    Company: 'Bitauto Holdings Ltd'
  },
  {
    Ticker: 'BJ',
    Company: "Bj's Wholesale Club Holdings Inc"
  },
  {
    Ticker: 'BK',
    Company: 'Bank of New York Mellon Corp'
  },
  {
    Ticker: 'BK-C',
    Company: 'Bank of New York Mellon Corp [Bk/Pc]'
  },
  {
    Ticker: 'BKD',
    Company: 'Brookdale Senior Living Inc'
  },
  {
    Ticker: 'BKE',
    Company: 'Buckle Inc'
  },
  {
    Ticker: 'BKH',
    Company: 'Black Hills Corp'
  },
  {
    Ticker: 'BKI',
    Company: 'Black Knight Inc'
  },
  {
    Ticker: 'BKK',
    Company: 'Blackrock Muni 2020 Trust'
  },
  {
    Ticker: 'BKN',
    Company: 'Blackrock Investment Quality Municipal'
  },
  {
    Ticker: 'BKR',
    Company: 'Baker Hughes A Ge Co. Cl A'
  },
  {
    Ticker: 'BKT',
    Company: 'Blackrock Income Trust Inc'
  },
  {
    Ticker: 'BKU',
    Company: 'BankUnited Inc'
  },
  {
    Ticker: 'BLD',
    Company: 'Topbuild Corp'
  },
  {
    Ticker: 'BLE',
    Company: 'Blackrock Muni Income Trust II'
  },
  {
    Ticker: 'BLK',
    Company: 'Blackrock'
  },
  {
    Ticker: 'BLL',
    Company: 'Ball Corp'
  },
  {
    Ticker: 'BLW',
    Company: 'Blackrock Ltd Duration Income Trust'
  },
  {
    Ticker: 'BLX',
    Company: 'Banco Latinoamericano DE Comercio'
  },
  {
    Ticker: 'BMA',
    Company: 'Banco Macro S.A.'
  },
  {
    Ticker: 'BME',
    Company: 'Blackrock Health Sciences Trust'
  },
  {
    Ticker: 'BMI',
    Company: 'Badger Meter'
  },
  {
    Ticker: 'BML-G',
    Company: 'Bank of America Corp [Bml/Pg]'
  },
  {
    Ticker: 'BML-H',
    Company: 'Bank of America Corp [Bml/Ph]'
  },
  {
    Ticker: 'BML-J',
    Company: 'Bank of America Corp [Bml/Pj]'
  },
  {
    Ticker: 'BML-L',
    Company: 'Bank of America Corp [Bml/Pl]'
  },
  {
    Ticker: 'BMO',
    Company: 'Bank of Montreal'
  },
  {
    Ticker: 'BMY',
    Company: 'Bristol-Myers Squibb Company'
  },
  {
    Ticker: 'BMY.P',
    Company: 'Bristol-Myers Squibb Co. Rights'
  },
  {
    Ticker: 'BNED',
    Company: 'Barnes & Noble Education Inc C'
  },
  {
    Ticker: 'BNS',
    Company: 'Bank of Nova Scotia'
  },
  {
    Ticker: 'BNY',
    Company: 'Blackrock New York Muni Trust Inc'
  },
  {
    Ticker: 'BOE',
    Company: 'Blackrock Global'
  },
  {
    Ticker: 'BOH',
    Company: 'Bank of Hawaii Corp'
  },
  {
    Ticker: 'BOOT',
    Company: 'Boot Barn Holdings Inc'
  },
  {
    Ticker: 'BORR',
    Company: 'Borr Drilling Ltd'
  },
  {
    Ticker: 'BOX',
    Company: 'Box Inc'
  },
  {
    Ticker: 'BP',
    Company: 'BP Plc'
  },
  {
    Ticker: 'BPMP',
    Company: 'BP Midstream Partners LP'
  },
  {
    Ticker: 'BPT',
    Company: 'BP Prudhoe Bay Royalty Trust'
  },
  {
    Ticker: 'BQH',
    Company: 'Blackrock New York Muni Trust'
  },
  {
    Ticker: 'BR',
    Company: 'Broadridge Financial Solutions Llc'
  },
  {
    Ticker: 'BRBR',
    Company: 'Bellring Brands Inc Cl A'
  },
  {
    Ticker: 'BRC',
    Company: 'Brady Corp'
  },
  {
    Ticker: 'BRFS',
    Company: 'Brf-Brasil Foods S.A.'
  },
  {
    Ticker: 'BRK.A',
    Company: 'Berkshire Hathaway Cl A'
  },
  {
    Ticker: 'BRK.B',
    Company: 'Berkshire Hathaway Cl B'
  },
  {
    Ticker: 'BRMK',
    Company: 'Broadmark Realty Capital Inc'
  },
  {
    Ticker: 'BRO',
    Company: 'Brown & Brown'
  },
  {
    Ticker: 'BRP.U',
    Company: 'B Riley Principal Merger Corp [Brpm.U]'
  },
  {
    Ticker: 'BRP.W',
    Company: 'B Riley Principal Merger Corp WT [Brpm/W]'
  },
  {
    Ticker: 'BRPM',
    Company: 'B. Riley Principal Merger Corp'
  },
  {
    Ticker: 'BRT',
    Company: 'BRT Realty Trust'
  },
  {
    Ticker: 'BRX',
    Company: 'Brixmor Property Group Inc'
  },
  {
    Ticker: 'BSA',
    Company: 'Brightsphere Investment Group Plc'
  },
  {
    Ticker: 'BSAC',
    Company: 'Banco Santander Chile ADR'
  },
  {
    Ticker: 'BSBR',
    Company: 'Banco Santander Brasil S.A.'
  },
  {
    Ticker: 'BSD',
    Company: 'Blackrock Strategic Muni Trust Inc'
  },
  {
    Ticker: 'BSE',
    Company: 'Blackrock New York Muni Income Trust'
  },
  {
    Ticker: 'BSIG',
    Company: 'Brightsphere Investment Group Plc'
  },
  {
    Ticker: 'BSL',
    Company: 'Blackstone Gso Senior Floating Rate'
  },
  {
    Ticker: 'BSM',
    Company: 'Black Stone Minerals LP'
  },
  {
    Ticker: 'BSMX',
    Company: 'Grupo Financiero Santander Mexico'
  },
  {
    Ticker: 'BST',
    Company: 'Blackrock Science and Technolo'
  },
  {
    Ticker: 'BSTZ',
    Company: 'Blackrock Science and Technology Trust II'
  },
  {
    Ticker: 'BSX',
    Company: 'Boston Scientific Corp'
  },
  {
    Ticker: 'BTA',
    Company: 'Blackrock Long-Term Muni Advantage Trust'
  },
  {
    Ticker: 'BTE',
    Company: 'Baytex Energy Corp'
  },
  {
    Ticker: 'BTI',
    Company: 'British American Tobacco Industries'
  },
  {
    Ticker: 'BTO',
    Company: 'John Hancock Bank and Thrift Fund'
  },
  {
    Ticker: 'BTT',
    Company: 'Blackrock Municipal Target Term'
  },
  {
    Ticker: 'BTU',
    Company: 'Peabody Energy Corp'
  },
  {
    Ticker: 'BTZ',
    Company: 'Blackrock Preferred'
  },
  {
    Ticker: 'BUD',
    Company: 'Anheuser-Busch Inbev S.A.'
  },
  {
    Ticker: 'BUI',
    Company: 'Blackrock Utility and Infrastr'
  },
  {
    Ticker: 'BURL',
    Company: 'Burlington Stores Inc'
  },
  {
    Ticker: 'BV',
    Company: 'Brightview Holdings Inc'
  },
  {
    Ticker: 'BVN',
    Company: 'Compania Mina Buenaventura S.A.'
  },
  {
    Ticker: 'BW',
    Company: 'Babcock & Wilcox Enterprises'
  },
  {
    Ticker: 'BWA',
    Company: 'Borgwarner Inc'
  },
  {
    Ticker: 'BWG',
    Company: 'Legg Mason Bw Global Income'
  },
  {
    Ticker: 'BWXT',
    Company: 'Bwx Technologies Inc'
  },
  {
    Ticker: 'BX',
    Company: 'The Blackstone Group LP'
  },
  {
    Ticker: 'BXC',
    Company: 'Bluelinx Holdings Inc'
  },
  {
    Ticker: 'BXG',
    Company: 'Bluegreen Vacations Corp'
  },
  {
    Ticker: 'BXMT',
    Company: 'Blackstone Mortgage Trust Inc Cl A'
  },
  {
    Ticker: 'BXMX',
    Company: 'Nuveen Equity Premium'
  },
  {
    Ticker: 'BXP',
    Company: 'Boston Properties'
  },
  {
    Ticker: 'BXP-B',
    Company: 'Boston Properties Inc [Bxp/Pb]'
  },
  {
    Ticker: 'BXS',
    Company: 'Bancorpsouth Inc'
  },
  {
    Ticker: 'BXS-A',
    Company: 'Bancorpsouth Bank 5.50% Prf Perpetual USD'
  },
  {
    Ticker: 'BY',
    Company: 'Byline Bancorp Inc'
  },
  {
    Ticker: 'BYD',
    Company: 'Boyd Gaming Corp'
  },
  {
    Ticker: 'BYM',
    Company: 'Blackrock Muni Income Quality Trust'
  },
  {
    Ticker: 'BZH',
    Company: 'Beazer Homes USA'
  },
  {
    Ticker: 'BZM',
    Company: 'Blackrock Maryland Muni Trust'
  },
  {
    Ticker: 'C',
    Company: 'Citigroup Inc'
  },
  {
    Ticker: 'C-J',
    Company: 'Citigroup Inc [C/Pj]'
  },
  {
    Ticker: 'C-K',
    Company: 'Citigroup Inc [C/Pk]'
  },
  {
    Ticker: 'C-N',
    Company: 'Citigroup Cap XiII [C/Pn]'
  },
  {
    Ticker: 'C-S',
    Company: 'Citigroup Inc [C/Ps]'
  },
  {
    Ticker: 'CAAP',
    Company: 'Corporacion America Airports Sa'
  },
  {
    Ticker: 'CABO',
    Company: 'Cable One Inc'
  },
  {
    Ticker: 'CACI',
    Company: 'Caci International'
  },
  {
    Ticker: 'CADE',
    Company: 'Cadence Bancorp Cl A'
  },
  {
    Ticker: 'CAE',
    Company: 'Cae Inc'
  },
  {
    Ticker: 'CAF',
    Company: 'Morgan Stanley China A Share Fund Inc'
  },
  {
    Ticker: 'CAG',
    Company: 'Conagra Brands Inc'
  },
  {
    Ticker: 'CAH',
    Company: 'Cardinal Health'
  },
  {
    Ticker: 'CAI',
    Company: 'Cai International'
  },
  {
    Ticker: 'CAI-A',
    Company: 'Cai International Inc [Cai/Pa]'
  },
  {
    Ticker: 'CAI-B',
    Company: 'Cai International Inc [Cai/Pb]'
  },
  {
    Ticker: 'CAJ',
    Company: 'Canon Inc'
  },
  {
    Ticker: 'CAL',
    Company: 'Caleres Inc'
  },
  {
    Ticker: 'CALX',
    Company: 'Calix Inc'
  },
  {
    Ticker: 'CANG',
    Company: 'Cango Inc ADR'
  },
  {
    Ticker: 'CAPL',
    Company: 'Crossamerica Partners LP'
  },
  {
    Ticker: 'CARS',
    Company: 'Cars.com Inc'
  },
  {
    Ticker: 'CAT',
    Company: 'Caterpillar Inc'
  },
  {
    Ticker: 'CATO',
    Company: 'Cato Corp'
  },
  {
    Ticker: 'CB',
    Company: 'Chubb Ltd'
  },
  {
    Ticker: 'CBB',
    Company: 'Cincinnati Bell Inc'
  },
  {
    Ticker: 'CBB-B',
    Company: 'Cincinnati Bell Inc [Cbb/Pb]'
  },
  {
    Ticker: 'CBD',
    Company: 'Companhia Brasileira DE Distribuicao'
  },
  {
    Ticker: 'CBH',
    Company: 'Allianzgi Convertible Income 2024 Target Term Fu'
  },
  {
    Ticker: 'CBL',
    Company: 'Cbl & Associates Properties'
  },
  {
    Ticker: 'CBL-D',
    Company: 'Cbl & Assoc Pptys Inc [Cbl/Pd]'
  },
  {
    Ticker: 'CBL-E',
    Company: 'Cbl & Assoc Pptys Inc [Cbl/Pe]'
  },
  {
    Ticker: 'CBM',
    Company: 'Cambrex Corp'
  },
  {
    Ticker: 'CBPX',
    Company: 'Continental Building Products'
  },
  {
    Ticker: 'CBRE',
    Company: 'CBRE Group Inc'
  },
  {
    Ticker: 'CBS',
    Company: 'CBS Corp'
  },
  {
    Ticker: 'CBS.A',
    Company: 'CBS Corp Cl A'
  },
  {
    Ticker: 'CBT',
    Company: 'Cabot Corp'
  },
  {
    Ticker: 'CBU',
    Company: 'Community Bank System'
  },
  {
    Ticker: 'CBZ',
    Company: 'Cbiz Inc'
  },
  {
    Ticker: 'CC',
    Company: 'Chemours Company'
  },
  {
    Ticker: 'CCC',
    Company: 'Clarivate Analytics Plc'
  },
  {
    Ticker: 'CCEP',
    Company: 'Coca Cola European Partners'
  },
  {
    Ticker: 'CCH',
    Company: 'Collier Creek Holdings Cl A'
  },
  {
    Ticker: 'CCH.U',
    Company: 'Collier Creek Holdings'
  },
  {
    Ticker: 'CCH.W',
    Company: 'Collier Creek Holdings WT [Cch/W]'
  },
  {
    Ticker: 'CCI',
    Company: 'Crown Castle International Corp'
  },
  {
    Ticker: 'CCI-A',
    Company: 'Crown Castle Intl Corp [Cci/Pa]'
  },
  {
    Ticker: 'CCJ',
    Company: 'Cameco Corp'
  },
  {
    Ticker: 'CCK',
    Company: 'Crown Cork & Seal Company'
  },
  {
    Ticker: 'CCL',
    Company: 'Carnival Corp'
  },
  {
    Ticker: 'CCM',
    Company: 'Concord Medical Services'
  },
  {
    Ticker: 'CCO',
    Company: 'Clear Channel Outdoor Holdings'
  },
  {
    Ticker: 'CCR',
    Company: 'Consol Coal Resources LP'
  },
  {
    Ticker: 'CCS',
    Company: 'Century Communities Inc'
  },
  {
    Ticker: 'CCU',
    Company: 'Compania Cervecerias Unidas S.A.'
  },
  {
    Ticker: 'CCX',
    Company: 'Churchill Capital Corp II'
  },
  {
    Ticker: 'CCX.U',
    Company: 'Churchill Capital Corp II'
  },
  {
    Ticker: 'CCX.W',
    Company: 'Churchill Cap Corp II WT [Ccx/W]'
  },
  {
    Ticker: 'CCZ',
    Company: 'Comcast Corp'
  },
  {
    Ticker: 'CDAY',
    Company: 'Ceridian Hcm Holding Inc'
  },
  {
    Ticker: 'CDE',
    Company: 'Coeur Mining Inc'
  },
  {
    Ticker: 'CDR',
    Company: 'Cedar Shopping Centers Inc'
  },
  {
    Ticker: 'CDR-B',
    Company: 'Cedar Realty Trust Inc [Cdr/Pb]'
  },
  {
    Ticker: 'CDR-C',
    Company: 'Cedar Realty Trust Inc [Cdr/Pc]'
  },
  {
    Ticker: 'CE',
    Company: 'Celanese Corp'
  },
  {
    Ticker: 'CEA',
    Company: 'China Eastern Airlines Corp Ltd'
  },
  {
    Ticker: 'CEE',
    Company: 'Central Europe and Russia Fund'
  },
  {
    Ticker: 'CEIX',
    Company: 'Consol Energy Inc'
  },
  {
    Ticker: 'CEL',
    Company: 'Cellcom Israel'
  },
  {
    Ticker: 'CELP',
    Company: 'Cypress Energy Partners LP'
  },
  {
    Ticker: 'CEM',
    Company: 'Clearbridge Energy MLP Fund Inc'
  },
  {
    Ticker: 'CEN',
    Company: 'Center Coast MLP & Infrastructure'
  },
  {
    Ticker: 'CEO',
    Company: 'Cnooc Ltd'
  },
  {
    Ticker: 'CEPU',
    Company: 'Central Puerto S.A. ADR'
  },
  {
    Ticker: 'CEQ.P',
    Company: 'Crestwood Equity Partners LP [Ceqp/P]'
  },
  {
    Ticker: 'CEQP',
    Company: 'Crestwood Equity Partners LP'
  },
  {
    Ticker: 'CF',
    Company: 'Cf Industries Holdings'
  },
  {
    Ticker: 'CFG',
    Company: 'Citizens Financial Group Inc/Ri'
  },
  {
    Ticker: 'CFG-D',
    Company: 'RBS Citizens Finl Group Inc [Cfg/Pd]'
  },
  {
    Ticker: 'CFG-E',
    Company: 'Citizens Financial Group Inc Prf Perpetual USD 2'
  },
  {
    Ticker: 'CFR',
    Company: 'Cullen/Frost Bankers'
  },
  {
    Ticker: 'CFR-A',
    Company: 'Cullen Frost Bankers [Cfr/Pa]'
  },
  {
    Ticker: 'CFX',
    Company: 'Colfax Corp'
  },
  {
    Ticker: 'CFXA',
    Company: 'Colfax Corp Tangible Equity Unit'
  },
  {
    Ticker: 'CGA',
    Company: 'China Green Agriculture'
  },
  {
    Ticker: 'CGC',
    Company: 'Canopy Growth Corp'
  },
  {
    Ticker: 'CHA',
    Company: 'China Telecom Corp Ltd'
  },
  {
    Ticker: 'CHAP',
    Company: 'Chaparral Energy Inc'
  },
  {
    Ticker: 'CHCT',
    Company: 'Community Healthcare Trust Inc'
  },
  {
    Ticker: 'CHD',
    Company: 'Church & Dwight Company'
  },
  {
    Ticker: 'CHE',
    Company: 'Chemed Inc'
  },
  {
    Ticker: 'CHGG',
    Company: 'Chegg Inc'
  },
  {
    Ticker: 'CHH',
    Company: 'Choice Hotels International'
  },
  {
    Ticker: 'CHK',
    Company: 'Chesapeake Energy Corp'
  },
  {
    Ticker: 'CHK-D',
    Company: 'Chesapeake Energy Corp [Chk/Pd]'
  },
  {
    Ticker: 'CHKR',
    Company: 'Chesapeake Granite Wash Trust'
  },
  {
    Ticker: 'CHL',
    Company: 'China Mobile [Hong Kong] Ltd'
  },
  {
    Ticker: 'CHM-A',
    Company: 'Cherry Hill Mtg Invt Corp [Chmi/Pa]'
  },
  {
    Ticker: 'CHM-B',
    Company: 'Cherry Hill Mtg Invt Corp [Chmi/Pb]'
  },
  {
    Ticker: 'CHMI',
    Company: 'Cherry Hill Mortgage Investmen'
  },
  {
    Ticker: 'CHN',
    Company: 'China Fund'
  },
  {
    Ticker: 'CHRA',
    Company: 'Charah Solutions Inc'
  },
  {
    Ticker: 'CHS',
    Company: "Chico's Fas"
  },
  {
    Ticker: 'CHT',
    Company: 'Chunghwa Telecom Co. Ltd'
  },
  {
    Ticker: 'CHU',
    Company: 'China Unicom [Hong Kong] Ltd'
  },
  {
    Ticker: 'CHWY',
    Company: 'Chewy Inc'
  },
  {
    Ticker: 'CI',
    Company: 'Cigna Corp'
  },
  {
    Ticker: 'CIA',
    Company: 'Citizens Inc'
  },
  {
    Ticker: 'CIB',
    Company: 'Bancolombia S.A.'
  },
  {
    Ticker: 'CIEN',
    Company: 'Ciena Corp'
  },
  {
    Ticker: 'CIF',
    Company: 'Colonial Intermediate High'
  },
  {
    Ticker: 'CIG',
    Company: 'Comp En DE MN Cemig ADR'
  },
  {
    Ticker: 'CIG.C',
    Company: 'Comp En DE MN Cemig ADR Cl C'
  },
  {
    Ticker: 'CII',
    Company: 'Blackrock Capital and Income Strategies'
  },
  {
    Ticker: 'CIM',
    Company: 'Chimera Investment Corp'
  },
  {
    Ticker: 'CIM-A',
    Company: 'Chimera Invt Corp [Cim/Pa]'
  },
  {
    Ticker: 'CIM-B',
    Company: 'Chimera Invt Corp [Cim/Pb]'
  },
  {
    Ticker: 'CIM-C',
    Company: 'Chimera Invt Corp [Cim/Pc]'
  },
  {
    Ticker: 'CIM-D',
    Company: 'Chimera Invt Corp [Cim/Pd]'
  },
  {
    Ticker: 'CINR',
    Company: 'Ciner Resources LP'
  },
  {
    Ticker: 'CIO',
    Company: 'City Office REIT Inc'
  },
  {
    Ticker: 'CIO-A',
    Company: 'City Office REIT Inc [Cio/Pa]'
  },
  {
    Ticker: 'CIR',
    Company: 'Circor International'
  },
  {
    Ticker: 'CISN',
    Company: 'Cision Ltd'
  },
  {
    Ticker: 'CIT',
    Company: 'Cit Group Inc'
  },
  {
    Ticker: 'CIT-B',
    Company: 'Cit Group Inc 5.625% Prf Perpetual USD 25 Ser B'
  },
  {
    Ticker: 'CKH',
    Company: 'Seacor Smit Inc'
  },
  {
    Ticker: 'CL',
    Company: 'Colgate-Palmolive Company'
  },
  {
    Ticker: 'CLB',
    Company: 'Core Laboratories N.V.'
  },
  {
    Ticker: 'CLDR',
    Company: 'Cloudera Inc'
  },
  {
    Ticker: 'CLDT',
    Company: 'Chatham Lodging Trust [Reit]'
  },
  {
    Ticker: 'CLF',
    Company: 'Cleveland-Cliffs Inc'
  },
  {
    Ticker: 'CLGX',
    Company: 'Corelogic'
  },
  {
    Ticker: 'CLH',
    Company: 'Clean Harbors'
  },
  {
    Ticker: 'CLI',
    Company: 'Mack-Cali Realty Corp'
  },
  {
    Ticker: 'CLN-B',
    Company: 'Colony Cap Inc [Clny/Pb]'
  },
  {
    Ticker: 'CLN-E',
    Company: 'Colony Cap Inc [Clny/Pe]'
  },
  {
    Ticker: 'CLN-G',
    Company: 'Colony Cap Inc [Clny/Pg]'
  },
  {
    Ticker: 'CLN-H',
    Company: 'Colony Cap Inc [Clny/Ph]'
  },
  {
    Ticker: 'CLN-I',
    Company: 'Colony Cap Inc [Clny/Pi]'
  },
  {
    Ticker: 'CLN-J',
    Company: 'Colony Cap Inc [Clny/Pj]'
  },
  {
    Ticker: 'CLNC',
    Company: 'Colony Northstar Credit Real Estate Inc Class'
  },
  {
    Ticker: 'CLNY',
    Company: 'Colony Capital Inc'
  },
  {
    Ticker: 'CLPR',
    Company: 'Clipper Realty Inc'
  },
  {
    Ticker: 'CLR',
    Company: 'Continental Resources'
  },
  {
    Ticker: 'CLS',
    Company: 'Celestica Inc'
  },
  {
    Ticker: 'CLW',
    Company: 'Clearwater Paper Corp'
  },
  {
    Ticker: 'CLX',
    Company: 'Clorox Company'
  },
  {
    Ticker: 'CM',
    Company: 'Canadian Imperial Bank of Commerce'
  },
  {
    Ticker: 'CMA',
    Company: 'Comerica Inc'
  },
  {
    Ticker: 'CMC',
    Company: 'Commercial Metals Company'
  },
  {
    Ticker: 'CMCM',
    Company: 'Cheetah Mobile Inc'
  },
  {
    Ticker: 'CMD',
    Company: 'Cantel Medical'
  },
  {
    Ticker: 'CMG',
    Company: 'Chipotle Mexican Grill'
  },
  {
    Ticker: 'CMI',
    Company: 'Cummins Inc'
  },
  {
    Ticker: 'CMO',
    Company: 'Capstead Mortgage Corp'
  },
  {
    Ticker: 'CMO-E',
    Company: 'Capstead Mtge.Corp [Cmo/Pe]'
  },
  {
    Ticker: 'CMP',
    Company: 'Compass Minerals Intl Inc'
  },
  {
    Ticker: 'CMR-B',
    Company: 'Costamare Inc [Cmre/Pb]'
  },
  {
    Ticker: 'CMR-C',
    Company: 'Costamare Inc [Cmre/Pc]'
  },
  {
    Ticker: 'CMR-D',
    Company: 'Costamare Inc [Cmre/Pd]'
  },
  {
    Ticker: 'CMR-E',
    Company: 'Costamare Inc [Cmre/Pe]'
  },
  {
    Ticker: 'CMRE',
    Company: 'Costamare Inc'
  },
  {
    Ticker: 'CMS',
    Company: 'Cms Energy Corp'
  },
  {
    Ticker: 'CMS-B',
    Company: 'Consumers Energy Company [Cms/Pb]'
  },
  {
    Ticker: 'CMSA',
    Company: 'Cms Energy Corp 5.625% Junior Subordinate'
  },
  {
    Ticker: 'CMSC',
    Company: 'Cms Energy Corp'
  },
  {
    Ticker: 'CMSD',
    Company: 'Cms Energy Corp 5.875% Junior Subordinate'
  },
  {
    Ticker: 'CMU',
    Company: 'Colonial Muni Income Trust'
  },
  {
    Ticker: 'CNA',
    Company: 'Cna Financial Corp'
  },
  {
    Ticker: 'CNC',
    Company: 'Centene Corp'
  },
  {
    Ticker: 'CNDT',
    Company: 'Conduent Inc'
  },
  {
    Ticker: 'CNF',
    Company: 'Cnfinance Holdings Ltd ADR'
  },
  {
    Ticker: 'CNHI',
    Company: 'CNH Industrial N.V.'
  },
  {
    Ticker: 'CNI',
    Company: 'Canadian National Railway'
  },
  {
    Ticker: 'CNK',
    Company: 'Cinemark Holdings Inc'
  },
  {
    Ticker: 'CNNE',
    Company: 'Cannae Holdings Inc'
  },
  {
    Ticker: 'CNO',
    Company: 'Cno Financial Group'
  },
  {
    Ticker: 'CNP',
    Company: 'Centerpoint Energy Inc'
  },
  {
    Ticker: 'CNP-B',
    Company: 'Centerpoint Energy Inc [Cnp/Pb]'
  },
  {
    Ticker: 'CNQ',
    Company: 'Canadian Natural Resources'
  },
  {
    Ticker: 'CNR',
    Company: 'Cornerstone Building Brands Inc'
  },
  {
    Ticker: 'CNS',
    Company: 'Cohn & Steers Inc'
  },
  {
    Ticker: 'CNX',
    Company: 'CNX Resources Corp'
  },
  {
    Ticker: 'CNXM',
    Company: 'CNX Midstream Partners LP'
  },
  {
    Ticker: 'CO',
    Company: 'Global Cord Blood Corp'
  },
  {
    Ticker: 'COD-A',
    Company: 'Compass Div Holdings [Codi/Pa]'
  },
  {
    Ticker: 'COD-B',
    Company: 'Compass Div Holdings [Codi/Pb]'
  },
  {
    Ticker: 'COD-C',
    Company: 'Compass Diversified Holdings 7.875% Prf Perpetua'
  },
  {
    Ticker: 'CODI',
    Company: 'Compass Diversified Holdings'
  },
  {
    Ticker: 'COE',
    Company: 'China Online Education Group'
  },
  {
    Ticker: 'COF',
    Company: 'Capital One Financial Corp'
  },
  {
    Ticker: 'COF-C',
    Company: 'Capital One Financial Corp [Cof/Pc]'
  },
  {
    Ticker: 'COF-D',
    Company: 'Capital One Financial Corp [Cof/Pd]'
  },
  {
    Ticker: 'COF-F',
    Company: 'Capital One Financial Corp [Cof/Pf]'
  },
  {
    Ticker: 'COF-G',
    Company: 'Capital One Financial Corp [Cof/Pg]'
  },
  {
    Ticker: 'COF-H',
    Company: 'Capital One Financial Corp [Cof/Ph]'
  },
  {
    Ticker: 'COF-I',
    Company: 'Capital One Financial Corp Prf Perpetual USD'
  },
  {
    Ticker: 'COF-P',
    Company: 'Capital One Financial Corp [Cof/Pp]'
  },
  {
    Ticker: 'COG',
    Company: 'Cabot Oil & Gas Corp'
  },
  {
    Ticker: 'COLD',
    Company: 'Americold Realty Trust'
  },
  {
    Ticker: 'COO',
    Company: 'Cooper Companies'
  },
  {
    Ticker: 'COP',
    Company: 'Conocophillips'
  },
  {
    Ticker: 'COR',
    Company: 'Coresite Realty Corp'
  },
  {
    Ticker: 'COR-Z',
    Company: 'Corenergy Infrastructure TR IN [Corr/Pa]'
  },
  {
    Ticker: 'CORR',
    Company: 'Corenergy Infrastructure Trust Inc'
  },
  {
    Ticker: 'COT',
    Company: 'Cott Corp'
  },
  {
    Ticker: 'COTY',
    Company: 'Coty Inc'
  },
  {
    Ticker: 'CP',
    Company: 'Canadian Pacific Railway'
  },
  {
    Ticker: 'CPA',
    Company: 'Copa Holdings S.A.'
  },
  {
    Ticker: 'CPAC',
    Company: 'Cementos Pacasmayo S.A.A'
  },
  {
    Ticker: 'CPB',
    Company: 'Campbell Soup Company'
  },
  {
    Ticker: 'CPE',
    Company: 'Callon Petroleum Company'
  },
  {
    Ticker: 'CPF',
    Company: 'Central Pacific Financial Company'
  },
  {
    Ticker: 'CPG',
    Company: 'Crescent Pt Energy'
  },
  {
    Ticker: 'CPK',
    Company: 'Chesapeake Utilities Corp'
  },
  {
    Ticker: 'CPL',
    Company: 'Cpfl Energia S.A.'
  },
  {
    Ticker: 'CPLG',
    Company: 'Corepoint Lodging Inc'
  },
  {
    Ticker: 'CPRI',
    Company: 'Capri Holdings Ltd'
  },
  {
    Ticker: 'CPS',
    Company: 'Cooper Std Hld Inc'
  },
  {
    Ticker: 'CPT',
    Company: 'Camden Property Trust'
  },
  {
    Ticker: 'CR',
    Company: 'Crane Company'
  },
  {
    Ticker: 'CRC',
    Company: 'California Resources Corp'
  },
  {
    Ticker: 'CRCM',
    Company: 'Care.com Inc'
  },
  {
    Ticker: 'CRD.A',
    Company: 'Crawford Co. Cl A'
  },
  {
    Ticker: 'CRD.B',
    Company: 'Crawford Co. Cl B'
  },
  {
    Ticker: 'CRH',
    Company: 'CRH Plc'
  },
  {
    Ticker: 'CRI',
    Company: "Carter's Inc"
  },
  {
    Ticker: 'CRK',
    Company: 'Comstock Resources'
  },
  {
    Ticker: 'CRL',
    Company: 'Charles River Laboratories Intl'
  },
  {
    Ticker: 'CRM',
    Company: 'Salesforce.com Inc'
  },
  {
    Ticker: 'CRR',
    Company: 'Carbo Ceramics'
  },
  {
    Ticker: 'CRS',
    Company: 'Carpenter Technology Corp'
  },
  {
    Ticker: 'CRT',
    Company: 'Cross Timbers Royalty Trust'
  },
  {
    Ticker: 'CRY',
    Company: 'Cryolife'
  },
  {
    Ticker: 'CS',
    Company: 'Credit Suisse Group'
  },
  {
    Ticker: 'CSL',
    Company: 'Carlisle Companies Inc'
  },
  {
    Ticker: 'CSLT',
    Company: 'Castlight Health Inc'
  },
  {
    Ticker: 'CSS',
    Company: 'Css Industries'
  },
  {
    Ticker: 'CSTM',
    Company: 'Constellium N.V.'
  },
  {
    Ticker: 'CSU',
    Company: 'Capital Senior Living Corp'
  },
  {
    Ticker: 'CSV',
    Company: 'Carriage Services'
  },
  {
    Ticker: 'CTA-A',
    Company: 'Du Pont[E.I.]De Nemours & Co. [Cta/Pa]'
  },
  {
    Ticker: 'CTA-B',
    Company: 'Du Pont[E.I.]De Nemours & Co. [Cta/Pb]'
  },
  {
    Ticker: 'CTAA',
    Company: 'Qwest Corp'
  },
  {
    Ticker: 'CTB',
    Company: 'Cooper Tire & Rubber Company'
  },
  {
    Ticker: 'CTBB',
    Company: 'Qwest Corp'
  },
  {
    Ticker: 'CTDD',
    Company: 'Qwest Corp 6.75% Notes Due 2057'
  },
  {
    Ticker: 'CTK',
    Company: 'Cootek [Cayman] Inc'
  },
  {
    Ticker: 'CTL',
    Company: 'Centurylink'
  },
  {
    Ticker: 'CTLT',
    Company: 'Catalent Inc'
  },
  {
    Ticker: 'CTR',
    Company: 'Clearbridge Energy MLP Fund IN'
  },
  {
    Ticker: 'CTRA',
    Company: 'Contura Energy Inc'
  },
  {
    Ticker: 'CTS',
    Company: 'Cts Corp'
  },
  {
    Ticker: 'CTST',
    Company: 'Canntrust Holdings Inc'
  },
  {
    Ticker: 'CTT',
    Company: 'Catchmark Timber Trust Inc'
  },
  {
    Ticker: 'CTV',
    Company: 'Qwest Corp'
  },
  {
    Ticker: 'CTVA',
    Company: 'Corteva Inc'
  },
  {
    Ticker: 'CTY',
    Company: 'Qwest Corp'
  },
  {
    Ticker: 'CTZ',
    Company: 'Qwest Corp 6.625% Notes'
  },
  {
    Ticker: 'CUB',
    Company: 'Cubic Corp'
  },
  {
    Ticker: 'CUB-C',
    Company: 'Customers Bancorp Inc [Cubi/Pc]'
  },
  {
    Ticker: 'CUB-D',
    Company: 'Customers Bancorp Inc [Cubi/Pd]'
  },
  {
    Ticker: 'CUB-E',
    Company: 'Customers Bancorp Inc [Cubi/Pe]'
  },
  {
    Ticker: 'CUB-F',
    Company: 'Customers Bancorp Inc [Cubi/Pf]'
  },
  {
    Ticker: 'CUBE',
    Company: 'Cubesmart'
  },
  {
    Ticker: 'CUBI',
    Company: 'Customers Bancorp'
  },
  {
    Ticker: 'CUK',
    Company: 'Carnival Plc ADR'
  },
  {
    Ticker: 'CULP',
    Company: 'Culp Inc'
  },
  {
    Ticker: 'CURO',
    Company: 'Curo Group Holdings Corp'
  },
  {
    Ticker: 'CUZ',
    Company: 'Cousins Properties Inc'
  },
  {
    Ticker: 'CVA',
    Company: 'Covanta Holding Corp'
  },
  {
    Ticker: 'CVE',
    Company: 'Cenovus Energy Inc'
  },
  {
    Ticker: 'CVEO',
    Company: 'Civeo Corp'
  },
  {
    Ticker: 'CVI',
    Company: 'Cvr Energy Inc'
  },
  {
    Ticker: 'CVIA',
    Company: 'Covia Holdings Corp'
  },
  {
    Ticker: 'CVNA',
    Company: 'Carvana Co. Cl A'
  },
  {
    Ticker: 'CVS',
    Company: 'CVS Corp'
  },
  {
    Ticker: 'CVX',
    Company: 'Chevron Corp'
  },
  {
    Ticker: 'CW',
    Company: 'Curtiss-Wright Corp'
  },
  {
    Ticker: 'CWE.A',
    Company: 'Clearway Energy Inc Cl A'
  },
  {
    Ticker: 'CWEN',
    Company: 'Clearway Energy Inc Cl C'
  },
  {
    Ticker: 'CWH',
    Company: 'Camping World Holdings Inc'
  },
  {
    Ticker: 'CWK',
    Company: 'Cushman & Wakefield Plc'
  },
  {
    Ticker: 'CWT',
    Company: 'California Water Service Group Holding'
  },
  {
    Ticker: 'CX',
    Company: 'Cemex S.A.B. DE C.V.'
  },
  {
    Ticker: 'CXE',
    Company: 'Colonial High Income Muni Trust'
  },
  {
    Ticker: 'CXH',
    Company: 'Colonial Investment Grade Muni Trust'
  },
  {
    Ticker: 'CXO',
    Company: 'Concho Resources Inc'
  },
  {
    Ticker: 'CXP',
    Company: 'Columbia Property Trust Inc'
  },
  {
    Ticker: 'CXW',
    Company: 'Corecivic Inc'
  },
  {
    Ticker: 'CYD',
    Company: 'China Yuchai International'
  },
  {
    Ticker: 'CYH',
    Company: 'Community Health Systems'
  },
  {
    Ticker: 'CZZ',
    Company: 'Cosan Ltd'
  },
  {
    Ticker: 'D',
    Company: 'Dominion Resources'
  },
  {
    Ticker: 'DAC',
    Company: 'Danaos Corp'
  },
  {
    Ticker: 'DAL',
    Company: 'Delta Air Lines Inc'
  },
  {
    Ticker: 'DAN',
    Company: 'Dana Inc'
  },
  {
    Ticker: 'DAO',
    Company: 'Youdao Inc ADR'
  },
  {
    Ticker: 'DAR',
    Company: 'Darling International Inc'
  },
  {
    Ticker: 'DAVA',
    Company: 'Endava Plc'
  },
  {
    Ticker: 'DB',
    Company: 'Deutsche Bank Ag'
  },
  {
    Ticker: 'DBD',
    Company: 'Diebold Nixdorf Inc'
  },
  {
    Ticker: 'DBI',
    Company: 'Designer Brands Inc'
  },
  {
    Ticker: 'DBL',
    Company: 'Doubleline Opportunistic Credi'
  },
  {
    Ticker: 'DCF',
    Company: 'Dreyfus Alcentra Global Credit Income 2024 Targe'
  },
  {
    Ticker: 'DCI',
    Company: 'Donaldson Company'
  },
  {
    Ticker: 'DCO',
    Company: 'Ducommun Inc'
  },
  {
    Ticker: 'DCP',
    Company: 'Dcp Midstream LP'
  },
  {
    Ticker: 'DCP-B',
    Company: 'Dcp Midstream LP [Dcp/Pb]'
  },
  {
    Ticker: 'DCP-C',
    Company: 'Dcp Midstream LP [Dcp/Pc]'
  },
  {
    Ticker: 'DCUE',
    Company: 'Dominion Energy Inc 2019 Series A'
  },
  {
    Ticker: 'DD',
    Company: 'Du Pont De.Nemours Inc'
  },
  {
    Ticker: 'DDD',
    Company: '3D Systems Corp'
  },
  {
    Ticker: 'DDF',
    Company: 'Delaware Dividend & Income'
  },
  {
    Ticker: 'DDS',
    Company: "Dillard's"
  },
  {
    Ticker: 'DDT',
    Company: "Dillard's"
  },
  {
    Ticker: 'DE',
    Company: 'Deere & Company'
  },
  {
    Ticker: 'DEA',
    Company: 'Easterly Government Properties'
  },
  {
    Ticker: 'DECK',
    Company: 'Deckers Outdoor Corp'
  },
  {
    Ticker: 'DEI',
    Company: 'Douglas Emmett'
  },
  {
    Ticker: 'DELL',
    Company: 'Dell Technologies Inc'
  },
  {
    Ticker: 'DEO',
    Company: 'Diageo Plc'
  },
  {
    Ticker: 'DESP',
    Company: 'Despegar.com Corp'
  },
  {
    Ticker: 'DEX',
    Company: 'Delaware Enhanced Global Dividend'
  },
  {
    Ticker: 'DFIN',
    Company: 'Donnelly Financial Solutions Inc'
  },
  {
    Ticker: 'DFP',
    Company: 'Flaherty & Crumrine Dynamic'
  },
  {
    Ticker: 'DFS',
    Company: 'Discover Financial Services'
  },
  {
    Ticker: 'DG',
    Company: 'Dollar General Corp'
  },
  {
    Ticker: 'DGX',
    Company: 'Quest Diagnostics Inc'
  },
  {
    Ticker: 'DHF',
    Company: 'Dreyfus High Yield Strategies Fund'
  },
  {
    Ticker: 'DHI',
    Company: 'D.R. Horton'
  },
  {
    Ticker: 'DHR',
    Company: 'Danaher Corp'
  },
  {
    Ticker: 'DHR-A',
    Company: 'Danaher Corp [Dhr/Pa]'
  },
  {
    Ticker: 'DHT',
    Company: 'Dht Holdings'
  },
  {
    Ticker: 'DHX',
    Company: 'Dice Holdings'
  },
  {
    Ticker: 'DIAX',
    Company: 'Nuveen Dow'
  },
  {
    Ticker: 'DIN',
    Company: 'Dineequity Inc'
  },
  {
    Ticker: 'DIS',
    Company: 'Walt Disney Company'
  },
  {
    Ticker: 'DK',
    Company: 'Delek US Holdings'
  },
  {
    Ticker: 'DKL',
    Company: 'Delek Logistics Partners LP'
  },
  {
    Ticker: 'DKS',
    Company: "Dick's Sporting Goods Inc"
  },
  {
    Ticker: 'DKT',
    Company: 'Deutsch Bk Contingent Cap TR V'
  },
  {
    Ticker: 'DL',
    Company: 'China Distance Education Holdings'
  },
  {
    Ticker: 'DLB',
    Company: 'Dolby Laboratories'
  },
  {
    Ticker: 'DLN-A',
    Company: 'Dynagas Lng Partners LP [Dlng/Pa]'
  },
  {
    Ticker: 'DLN-B',
    Company: 'Dynagas Lng Partners LP [Dlng/Pb]'
  },
  {
    Ticker: 'DLNG',
    Company: 'Dynagas Lng Partners LP'
  },
  {
    Ticker: 'DLPH',
    Company: 'Delphi Technologies Plc'
  },
  {
    Ticker: 'DLR',
    Company: 'Digital Realty Trust'
  },
  {
    Ticker: 'DLR-C',
    Company: 'Digital Rlty TR Inc [Dlr/Pc]'
  },
  {
    Ticker: 'DLR-G',
    Company: 'Digital Rlty TR Inc [Dlr/Pg]'
  },
  {
    Ticker: 'DLR-I',
    Company: 'Digital Rlty TR Inc [Dlr/Pi]'
  },
  {
    Ticker: 'DLR-J',
    Company: 'Digital Rlty TR Inc [Dlr/Pj]'
  },
  {
    Ticker: 'DLR-K',
    Company: 'Digital Rlty TR Inc [Dlr/Pk]'
  },
  {
    Ticker: 'DLR-L',
    Company: 'Digital Realty Trust Inc 5.20% Prf Perpetual USD'
  },
  {
    Ticker: 'DLX',
    Company: 'Deluxe Corp'
  },
  {
    Ticker: 'DMB',
    Company: 'Dreyfus Municipal Bond Infrastr'
  },
  {
    Ticker: 'DMO',
    Company: 'Western Asset Mortgage Defined Opp'
  },
  {
    Ticker: 'DNI',
    Company: 'Chartwell Dividend & Income Fund'
  },
  {
    Ticker: 'DNOW',
    Company: 'Now Inc'
  },
  {
    Ticker: 'DNP',
    Company: 'Duff & Phelps Utilities Income'
  },
  {
    Ticker: 'DNR',
    Company: 'Denbury Resources'
  },
  {
    Ticker: 'DO',
    Company: 'Diamond Offshore Drilling'
  },
  {
    Ticker: 'DOC',
    Company: 'Physicians Realty Trust'
  },
  {
    Ticker: 'DOOR',
    Company: 'Masonite Worldwide Hld'
  },
  {
    Ticker: 'DOV',
    Company: 'Dover Corp'
  },
  {
    Ticker: 'DOW',
    Company: 'Dow Inc'
  },
  {
    Ticker: 'DPG',
    Company: 'Duff & Phelps Global Utility'
  },
  {
    Ticker: 'DPLO',
    Company: 'Diplomat Pharmacy Inc'
  },
  {
    Ticker: 'DPZ',
    Company: "Domino's Pizza Inc"
  },
  {
    Ticker: 'DQ',
    Company: 'Daqo New Energy'
  },
  {
    Ticker: 'DRD',
    Company: 'Drdgold Ltd'
  },
  {
    Ticker: 'DRE',
    Company: 'Duke Realty Corp'
  },
  {
    Ticker: 'DRH',
    Company: 'Diamondrock Hospitality Company'
  },
  {
    Ticker: 'DRI',
    Company: 'Darden Restaurants'
  },
  {
    Ticker: 'DRQ',
    Company: 'Dril-Quip'
  },
  {
    Ticker: 'DRUA',
    Company: 'Dominion Resources Inc'
  },
  {
    Ticker: 'DS',
    Company: 'Drive Shack Inc'
  },
  {
    Ticker: 'DS-B',
    Company: 'Drive Shack Inc [Ds/Pb]'
  },
  {
    Ticker: 'DS-C',
    Company: 'Drive Shack Inc [Ds/Pc]'
  },
  {
    Ticker: 'DS-D',
    Company: 'Drive Shack Inc [Ds/Pd]'
  },
  {
    Ticker: 'DSE',
    Company: 'Duff & Phelps Select Energy ML'
  },
  {
    Ticker: 'DSL',
    Company: 'Doubleline Income Solutions Fun'
  },
  {
    Ticker: 'DSM',
    Company: 'Dreyfus Strategic Muni Bond Fund'
  },
  {
    Ticker: 'DSSI',
    Company: 'Diamond S Shipping Inc'
  },
  {
    Ticker: 'DSU',
    Company: 'Blackrock Debt Strategies Fund'
  },
  {
    Ticker: 'DSX',
    Company: 'Diana Shipping Inc'
  },
  {
    Ticker: 'DSX-B',
    Company: 'Diana Shipping Inc [Dsx/Pb]'
  },
  {
    Ticker: 'DT',
    Company: 'Dynatrace Inc'
  },
  {
    Ticker: 'DTE',
    Company: 'Dte Energy Company'
  },
  {
    Ticker: 'DTF',
    Company: 'Duff & Phelps Utilities Tax-Free Income'
  },
  {
    Ticker: 'DTJ',
    Company: 'Dte Energy Company 2016 Series'
  },
  {
    Ticker: 'DTL.P',
    Company: 'Brookfield Dtla Fd Office TR I [Dtla/P]'
  },
  {
    Ticker: 'DTP',
    Company: 'Dte Energy Co. Units'
  },
  {
    Ticker: 'DTQ',
    Company: 'Dte Energy Company Sub Deb 62'
  },
  {
    Ticker: 'DTW',
    Company: 'Dte Energy Company 2017 Series E 5.25% Jr'
  },
  {
    Ticker: 'DTY',
    Company: 'Dignity Plc'
  },
  {
    Ticker: 'DUC',
    Company: 'Duff & Phelps Utility & Corporate Trust'
  },
  {
    Ticker: 'DUK',
    Company: 'Duke Energy Corp'
  },
  {
    Ticker: 'DUK-A',
    Company: 'Duke Energy Corp [Duk/Pa]'
  },
  {
    Ticker: 'DUKB',
    Company: 'Duke Energy Corp 5.625%'
  },
  {
    Ticker: 'DUKH',
    Company: 'Duke Energy Corp 5.125%'
  },
  {
    Ticker: 'DVA',
    Company: 'Davita Healthcare Partners Inc'
  },
  {
    Ticker: 'DVD',
    Company: 'Dover Downs Entertainment'
  },
  {
    Ticker: 'DVN',
    Company: 'Devon Energy Corp'
  },
  {
    Ticker: 'DX',
    Company: 'Dynex Capital'
  },
  {
    Ticker: 'DX-A',
    Company: 'Dynex Cap Inc [Dx/Pa]'
  },
  {
    Ticker: 'DX-B',
    Company: 'Dynex Cap Inc [Dx/Pb]'
  },
  {
    Ticker: 'DXB',
    Company: 'Deutsche Bk Contingent Cap TR'
  },
  {
    Ticker: 'DXC',
    Company: 'Dxc Technology Company'
  },
  {
    Ticker: 'DY',
    Company: 'Dycom Industries'
  },
  {
    Ticker: 'E',
    Company: 'Eni S.P.A.'
  },
  {
    Ticker: 'EAB',
    Company: 'First Mortgage Bonds'
  },
  {
    Ticker: 'EAE',
    Company: 'Entergy Arkansas Inc'
  },
  {
    Ticker: 'EAF',
    Company: 'Graftech International Ltd'
  },
  {
    Ticker: 'EAI',
    Company: 'Entergy Arkansas Inc'
  },
  {
    Ticker: 'EARN',
    Company: 'Ellington Residential Mortgage'
  },
  {
    Ticker: 'EAT',
    Company: 'Brinker International'
  },
  {
    Ticker: 'EB',
    Company: 'Eventbrite Inc'
  },
  {
    Ticker: 'EBF',
    Company: 'Ennis Inc'
  },
  {
    Ticker: 'EBR',
    Company: 'Centrais Electricas Brazil'
  },
  {
    Ticker: 'EBR.B',
    Company: 'Centrais Eletricas Brasileiras ADR'
  },
  {
    Ticker: 'EBS',
    Company: 'Emergent Biosolutions'
  },
  {
    Ticker: 'EC',
    Company: 'Ecopetrol S.A.'
  },
  {
    Ticker: 'ECA',
    Company: 'Encana Corp'
  },
  {
    Ticker: 'ECC',
    Company: 'Eagle Point Credit Co. Inc'
  },
  {
    Ticker: 'ECCA',
    Company: 'Eagle Point Credit Company Inc'
  },
  {
    Ticker: 'ECCB',
    Company: 'Eagle Point Credit Company Inc'
  },
  {
    Ticker: 'ECCX',
    Company: 'Eagle Point Credit Company Inc 6.6875% Notes Du'
  },
  {
    Ticker: 'ECCY',
    Company: 'Eagle Point Credit Company Inc'
  },
  {
    Ticker: 'ECL',
    Company: 'Ecolab Inc'
  },
  {
    Ticker: 'ECOM',
    Company: 'Channeladvisor Corp'
  },
  {
    Ticker: 'ECT',
    Company: 'Eca Marcellus Trust'
  },
  {
    Ticker: 'ED',
    Company: 'Consolidated Edison Company of New York'
  },
  {
    Ticker: 'EDD',
    Company: 'Emerging Markets Domestic Debt Fund'
  },
  {
    Ticker: 'EDF',
    Company: 'Stone Harbor Emerging Markets'
  },
  {
    Ticker: 'EDI',
    Company: 'Stone Harbor Emerging Markets'
  },
  {
    Ticker: 'EDN',
    Company: 'Empresa Distribuidora Y Comercializadora'
  },
  {
    Ticker: 'EDU',
    Company: 'New Oriental Education & Technology Group'
  },
  {
    Ticker: 'EE',
    Company: 'El Paso Electric Company'
  },
  {
    Ticker: 'EEA',
    Company: 'European Equity Fund'
  },
  {
    Ticker: 'EEX',
    Company: 'Emerald Expositions Events Inc'
  },
  {
    Ticker: 'EFC',
    Company: 'Ellington Financial Llc'
  },
  {
    Ticker: 'EFC-A',
    Company: 'Ellington Financial Inc Pfd.'
  },
  {
    Ticker: 'EFF',
    Company: 'Eaton Vance Floating-Rate Inco'
  },
  {
    Ticker: 'EFL',
    Company: 'Eaton Vance Floating-Rate 2022 Target Term Trust'
  },
  {
    Ticker: 'EFR',
    Company: 'Eaton Vance Senior Floating-Rate Fund'
  },
  {
    Ticker: 'EFT',
    Company: 'Eaton Vance Floating Rate Income Trust'
  },
  {
    Ticker: 'EFX',
    Company: 'Equifax Inc'
  },
  {
    Ticker: 'EGF',
    Company: 'Blackrock Enhanced Government Fund'
  },
  {
    Ticker: 'EGHT',
    Company: '8X8 Inc'
  },
  {
    Ticker: 'EGIF',
    Company: 'Eagle Growth and Income Opportunity'
  },
  {
    Ticker: 'EGO',
    Company: 'Eldorado Gold Corp'
  },
  {
    Ticker: 'EGP',
    Company: 'Eastgroup Properties'
  },
  {
    Ticker: 'EGY',
    Company: 'Vaalco Energy Inc'
  },
  {
    Ticker: 'EHC',
    Company: 'Encompass Health Corp'
  },
  {
    Ticker: 'EHI',
    Company: 'Western Asset Global High'
  },
  {
    Ticker: 'EHT',
    Company: 'Eaton Vance High Income 2021 Ta'
  },
  {
    Ticker: 'EIC',
    Company: 'Eagle Point Income Company Inc'
  },
  {
    Ticker: 'EIG',
    Company: 'Employers Holdings Inc'
  },
  {
    Ticker: 'EIX',
    Company: 'Edison International'
  },
  {
    Ticker: 'EL',
    Company: 'Estee Lauder Companies'
  },
  {
    Ticker: 'ELAN',
    Company: 'Elanco Animal Health Inc'
  },
  {
    Ticker: 'ELC',
    Company: 'Entergy Louisiana Llc'
  },
  {
    Ticker: 'ELF',
    Company: 'E.L.F. Beauty Inc'
  },
  {
    Ticker: 'ELJ',
    Company: 'Entergy Louisiana Llc'
  },
  {
    Ticker: 'ELP',
    Company: 'Companhia Paranaense DE Energia'
  },
  {
    Ticker: 'ELS',
    Company: 'Equity Lifestyle Properties'
  },
  {
    Ticker: 'ELU',
    Company: 'Entergy Louisiana Llc First M'
  },
  {
    Ticker: 'ELVT',
    Company: 'Elevate Credit Inc'
  },
  {
    Ticker: 'ELY',
    Company: 'Callaway Golf Company'
  },
  {
    Ticker: 'EMD',
    Company: 'Western Asset Emerging Market Debt Fund Inc'
  },
  {
    Ticker: 'EME',
    Company: 'Emcor Group'
  },
  {
    Ticker: 'EMF',
    Company: 'Templeton Emerging Markets Fund'
  },
  {
    Ticker: 'EMN',
    Company: 'Eastman Chemical Company'
  },
  {
    Ticker: 'EMO',
    Company: 'Clearbridge Energy MLP Opportu'
  },
  {
    Ticker: 'EMP',
    Company: 'Entergy Mississippi Inc 4.9% Bonds'
  },
  {
    Ticker: 'EMR',
    Company: 'Emerson Electric Company'
  },
  {
    Ticker: 'ENB',
    Company: 'Enbridge Inc'
  },
  {
    Ticker: 'ENBA',
    Company: 'Enbridge Inc 6.375% Fixed-To-Floating Rate'
  },
  {
    Ticker: 'ENBL',
    Company: 'Enable Midstream Partners LP'
  },
  {
    Ticker: 'ENIA',
    Company: 'Enersis Americas S.A. American'
  },
  {
    Ticker: 'ENIC',
    Company: 'Enersis Chile S.A. ADR'
  },
  {
    Ticker: 'ENJ',
    Company: 'Entergy New Orleans Inc'
  },
  {
    Ticker: 'ENLC',
    Company: 'Enlink Midstream Llc'
  },
  {
    Ticker: 'ENO',
    Company: 'Entergy New Orleans Incfirst'
  },
  {
    Ticker: 'ENR',
    Company: 'Energizer Holdings Inc'
  },
  {
    Ticker: 'ENR-A',
    Company: 'Energizer Holdings Inc [Enr/Pa]'
  },
  {
    Ticker: 'ENS',
    Company: 'Enersys Inc'
  },
  {
    Ticker: 'ENV',
    Company: 'Envestnet Inc'
  },
  {
    Ticker: 'ENVA',
    Company: 'Enova International Inc'
  },
  {
    Ticker: 'ENZ',
    Company: 'Enzo Biochem'
  },
  {
    Ticker: 'EOD',
    Company: 'Wells Fargo Global Dividend Opportunity'
  },
  {
    Ticker: 'EOG',
    Company: 'Eog Resources'
  },
  {
    Ticker: 'EOI',
    Company: 'Eaton Vance Enhance Equity'
  },
  {
    Ticker: 'EOS',
    Company: 'Eaton Vance Enhanced Equity II'
  },
  {
    Ticker: 'EOT',
    Company: 'Eaton Vance Muni Income Trust'
  },
  {
    Ticker: 'EP-C',
    Company: 'El Paso Energy Capital [Ep/Pc]'
  },
  {
    Ticker: 'EPAC',
    Company: 'Actuant Corp'
  },
  {
    Ticker: 'EPAM',
    Company: 'Epam Systems Inc'
  },
  {
    Ticker: 'EPC',
    Company: 'Edgewell Personal Care'
  },
  {
    Ticker: 'EPD',
    Company: 'Enterprise Products Partners LP'
  },
  {
    Ticker: 'EPR',
    Company: 'Entertainment Properties Trust'
  },
  {
    Ticker: 'EPR-C',
    Company: 'Epr Pptys [Epr/Pc]'
  },
  {
    Ticker: 'EPR-E',
    Company: 'Epr Pptys [Epr/Pe]'
  },
  {
    Ticker: 'EPR-G',
    Company: 'Epr Pptys [Epr/Pg]'
  },
  {
    Ticker: 'EPRT',
    Company: 'Essential Properties Realty Trust Inc'
  },
  {
    Ticker: 'EQC',
    Company: 'Equity Commonwealth'
  },
  {
    Ticker: 'EQC-D',
    Company: 'Equity Comwlth [Eqc/Pd]'
  },
  {
    Ticker: 'EQH',
    Company: 'Axa Equitable Holdings Inc'
  },
  {
    Ticker: 'EQH-A',
    Company: 'Axa Equitable Holdings Inc 5.25% Prf Perpetual U'
  },
  {
    Ticker: 'EQM',
    Company: 'Eqm Midstream Partners LP'
  },
  {
    Ticker: 'EQNR',
    Company: 'Equinor ASA'
  },
  {
    Ticker: 'EQR',
    Company: 'Equity Residential'
  },
  {
    Ticker: 'EQS',
    Company: 'Equus Total Return'
  },
  {
    Ticker: 'EQT',
    Company: 'Eqt Corp'
  },
  {
    Ticker: 'ERA',
    Company: 'Era Group Inc'
  },
  {
    Ticker: 'ERF',
    Company: 'Enerplus Corp'
  },
  {
    Ticker: 'ERJ',
    Company: 'Embraer-Empresa Brasileira DE Aeronautica'
  },
  {
    Ticker: 'EROS',
    Company: 'Eros International Plc'
  },
  {
    Ticker: 'ES',
    Company: 'Eversource Energy'
  },
  {
    Ticker: 'ESE',
    Company: 'Esco Technologies Inc'
  },
  {
    Ticker: 'ESI',
    Company: 'Element Solutions Inc'
  },
  {
    Ticker: 'ESNT',
    Company: 'Essent Group Ltd'
  },
  {
    Ticker: 'ESRT',
    Company: 'Empire State Realty Trust Inc'
  },
  {
    Ticker: 'ESS',
    Company: 'Essex Property Trust'
  },
  {
    Ticker: 'ESTC',
    Company: 'Elastic N.V.'
  },
  {
    Ticker: 'ESTE',
    Company: 'Earthstone Energy'
  },
  {
    Ticker: 'ET',
    Company: 'Energy Transfer LP'
  },
  {
    Ticker: 'ETB',
    Company: 'Eaton Vance Tax-Managed Buy-Write'
  },
  {
    Ticker: 'ETG',
    Company: 'Eaton Vance Tax-Advantaged Global Dividend'
  },
  {
    Ticker: 'ETH',
    Company: 'Ethan Allen Interiors Inc'
  },
  {
    Ticker: 'ETI.P',
    Company: 'Entergy Texas Inc 5.375% Series A Preferred Stoc'
  },
  {
    Ticker: 'ETJ',
    Company: 'Eaton Vance Risk-Managed Diversified Equity'
  },
  {
    Ticker: 'ETM',
    Company: 'Entercom Communications Corp'
  },
  {
    Ticker: 'ETN',
    Company: 'Eaton Corp'
  },
  {
    Ticker: 'ETO',
    Company: 'Eaton Vance Tax-Advantage Global Dividend Opp'
  },
  {
    Ticker: 'ETP-C',
    Company: 'Energy Transfer Operating L P [Etp/Pc]'
  },
  {
    Ticker: 'ETP-D',
    Company: 'Energy Transfer Operating L P [Etp/Pd]'
  },
  {
    Ticker: 'ETP-E',
    Company: 'Energy Transfer Operating L P [Etp/Pe]'
  },
  {
    Ticker: 'ETR',
    Company: 'Entergy Corp'
  },
  {
    Ticker: 'ETRN',
    Company: 'Equitrans Midstream Corp'
  },
  {
    Ticker: 'ETV',
    Company: 'Eaton Vance Corp'
  },
  {
    Ticker: 'ETW',
    Company: 'Eaton Vance Corp'
  },
  {
    Ticker: 'ETX',
    Company: 'Eaton Vance Municipal Income T'
  },
  {
    Ticker: 'ETY',
    Company: 'Eaton Vance Tax-Managed Diversified Equity'
  },
  {
    Ticker: 'EURN',
    Company: 'Euronav NV'
  },
  {
    Ticker: 'EV',
    Company: 'Eaton Vance Corp'
  },
  {
    Ticker: 'EVA',
    Company: 'Enviva Partners LP'
  },
  {
    Ticker: 'EVC',
    Company: 'Entravision Communications Corp'
  },
  {
    Ticker: 'EVF',
    Company: 'Eaton Vance Senior Income Trust'
  },
  {
    Ticker: 'EVG',
    Company: 'Eaton Vance Short Diversified'
  },
  {
    Ticker: 'EVH',
    Company: 'Evolent Health Inc Cl A Com'
  },
  {
    Ticker: 'EVN',
    Company: 'Eaton Vance Muni Income Trust'
  },
  {
    Ticker: 'EVR',
    Company: 'Evercore Partners Inc'
  },
  {
    Ticker: 'EVRG',
    Company: 'Evergy Inc'
  },
  {
    Ticker: 'EVRI',
    Company: 'Everi Holdings Inc'
  },
  {
    Ticker: 'EVT',
    Company: 'Eaton Vance Tax Advantaged Dividend'
  },
  {
    Ticker: 'EVTC',
    Company: 'Evertec Inc'
  },
  {
    Ticker: 'EW',
    Company: 'Edwards Lifesciences Corp'
  },
  {
    Ticker: 'EXD',
    Company: 'Eaton Vance Tax-Advantaged Bond'
  },
  {
    Ticker: 'EXG',
    Company: 'Eaton Vance Tax-Managed Global Diversified Equit'
  },
  {
    Ticker: 'EXK',
    Company: 'Endeavour Silver Corp'
  },
  {
    Ticker: 'EXP',
    Company: 'Eagle Materials Inc'
  },
  {
    Ticker: 'EXPR',
    Company: 'Express Inc'
  },
  {
    Ticker: 'EXR',
    Company: 'Extra Space Storage Inc'
  },
  {
    Ticker: 'EXTN',
    Company: 'Exterran Corp'
  },
  {
    Ticker: 'EZT',
    Company: 'First Mortgage Bonds'
  },
  {
    Ticker: 'F',
    Company: 'Ford Motor Company'
  },
  {
    Ticker: 'F-B',
    Company: 'Ford Mtr Co. Del [F/Pb]'
  },
  {
    Ticker: 'FAF',
    Company: 'First American Corp'
  },
  {
    Ticker: 'FAM',
    Company: 'First Trust/Aberdeen Global Opportunity'
  },
  {
    Ticker: 'FBC',
    Company: 'Flagstar Bancorp'
  },
  {
    Ticker: 'FBHS',
    Company: 'Fortune Brands Home & Security'
  },
  {
    Ticker: 'FBK',
    Company: 'Fb Financial Corp'
  },
  {
    Ticker: 'FBM',
    Company: 'Foundation Building Materials Inc'
  },
  {
    Ticker: 'FBP',
    Company: 'First Bancorp'
  },
  {
    Ticker: 'FC',
    Company: 'Franklin Covey Company'
  },
  {
    Ticker: 'FCAU',
    Company: 'Fiat Chrysler Automobiles N.V.'
  },
  {
    Ticker: 'FCF',
    Company: 'First Commonwealth Financial Corp'
  },
  {
    Ticker: 'FCN',
    Company: 'Fti Consulting'
  },
  {
    Ticker: 'FCPT',
    Company: 'Four Corners Property Trust IN'
  },
  {
    Ticker: 'FCT',
    Company: 'Senior Floating Rate II'
  },
  {
    Ticker: 'FCX',
    Company: 'Freeport-Mcmoran Inc'
  },
  {
    Ticker: 'FDEU',
    Company: 'First Trust Dynamic Europe Equi'
  },
  {
    Ticker: 'FDP',
    Company: 'Fresh Del Monte Produce'
  },
  {
    Ticker: 'FDS',
    Company: 'Factset Research Systems Inc'
  },
  {
    Ticker: 'FDX',
    Company: 'Fedex Corp'
  },
  {
    Ticker: 'FE',
    Company: 'Firstenergy Corp'
  },
  {
    Ticker: 'FEDU',
    Company: 'Four Seasons Education [Cayman] Inc'
  },
  {
    Ticker: 'FEI',
    Company: 'First Trust MLP and Energy Inc'
  },
  {
    Ticker: 'FENG',
    Company: 'Phoenix New Media Ltd'
  },
  {
    Ticker: 'FEO',
    Company: 'First Trust/Aberdeen Emerging Opportunity Fund'
  },
  {
    Ticker: 'FET',
    Company: 'Forum Energy Technologies Inc'
  },
  {
    Ticker: 'FF',
    Company: 'Futurefuel Corp'
  },
  {
    Ticker: 'FFA',
    Company: 'First Trust Enhanced Equity Income Fund'
  },
  {
    Ticker: 'FFC',
    Company: 'Flaherty Crumrine/Claymore Preferred Securities'
  },
  {
    Ticker: 'FFG',
    Company: 'Fbl Financial Group'
  },
  {
    Ticker: 'FG',
    Company: 'Fgl Holdings'
  },
  {
    Ticker: 'FG.W',
    Company: 'Fgl Holdings WT [Fg/W]'
  },
  {
    Ticker: 'FGB',
    Company: 'Specialty Finance and Financial Fund'
  },
  {
    Ticker: 'FGP',
    Company: 'Ferrellgas Partners LP'
  },
  {
    Ticker: 'FHN',
    Company: 'First Horizon National Corp'
  },
  {
    Ticker: 'FHN-A',
    Company: 'First Horizon National Corp [Fhn/Pa]'
  },
  {
    Ticker: 'FI',
    Company: "Frank's International N.V."
  },
  {
    Ticker: 'FICO',
    Company: 'Fair Isaac and Company Inc'
  },
  {
    Ticker: 'FIF',
    Company: 'First Trust Energy Infrastruct'
  },
  {
    Ticker: 'FII',
    Company: 'Federated Investors'
  },
  {
    Ticker: 'FINS',
    Company: 'Angel Oak Financial Strategies Income Term Trust'
  },
  {
    Ticker: 'FINV',
    Company: 'Finvolution Group'
  },
  {
    Ticker: 'FIS',
    Company: 'Fidelity National Information Services'
  },
  {
    Ticker: 'FIT',
    Company: 'Fitbit Inc'
  },
  {
    Ticker: 'FIV',
    Company: 'First Trust Senior Floating Rate 2022 Target Ter'
  },
  {
    Ticker: 'FIX',
    Company: 'Comfort Systems USA'
  },
  {
    Ticker: 'FL',
    Company: 'Footlocker Inc'
  },
  {
    Ticker: 'FLC',
    Company: 'Flaherty & Crumrine/Claymore Total Return'
  },
  {
    Ticker: 'FLNG',
    Company: 'Flex Lng Ltd'
  },
  {
    Ticker: 'FLO',
    Company: 'Flowers Foods'
  },
  {
    Ticker: 'FLOW',
    Company: 'SPX Flow Inc'
  },
  {
    Ticker: 'FLR',
    Company: 'Fluor Corp'
  },
  {
    Ticker: 'FLS',
    Company: 'Flowserve Corp'
  },
  {
    Ticker: 'FLT',
    Company: 'Fleetcor Technologies'
  },
  {
    Ticker: 'FLY',
    Company: 'Fly Leasing Ltd'
  },
  {
    Ticker: 'FMC',
    Company: 'FMC Corp'
  },
  {
    Ticker: 'FMN',
    Company: 'Federated Premier Muni'
  },
  {
    Ticker: 'FMO',
    Company: 'Fiduciary/Claymore MLP Opportunity Fund'
  },
  {
    Ticker: 'FMS',
    Company: 'Fresenius Medical Care Corp'
  },
  {
    Ticker: 'FMX',
    Company: 'Fomento Economico Mexicano S.A.B. DE C.V.'
  },
  {
    Ticker: 'FMY',
    Company: 'First Trust/Fidac Mortgage Income Fund'
  },
  {
    Ticker: 'FN',
    Company: 'Fabrinet'
  },
  {
    Ticker: 'FNB',
    Company: 'F.N.B. Corp'
  },
  {
    Ticker: 'FNB-E',
    Company: 'Fnb Corp Florida [Fnb/Pe]'
  },
  {
    Ticker: 'FND',
    Company: 'Floor & Decor Holdings Inc'
  },
  {
    Ticker: 'FNF',
    Company: 'Fidelity National Financial'
  },
  {
    Ticker: 'FNV',
    Company: 'Franco Nev Corp'
  },
  {
    Ticker: 'FOE',
    Company: 'Ferro Corp'
  },
  {
    Ticker: 'FOF',
    Company: 'Cohen & Steers Closed-End Opportunity Fund'
  },
  {
    Ticker: 'FOR',
    Company: 'Forestar Group Inc'
  },
  {
    Ticker: 'FPA.U',
    Company: 'Far Pt Acquisition Corp [Fpac.U]'
  },
  {
    Ticker: 'FPA.W',
    Company: 'Far Point Acquisition Cl A WT'
  },
  {
    Ticker: 'FPAC',
    Company: 'Far Point Acquisition Corp Cl A'
  },
  {
    Ticker: 'FPF',
    Company: 'First Trust Intermediate Durat'
  },
  {
    Ticker: 'FPH',
    Company: 'Five Point Holdings Llc Cl A'
  },
  {
    Ticker: 'FPI',
    Company: 'Farmland Partners Inc'
  },
  {
    Ticker: 'FPI-B',
    Company: 'Farmland Partners Inc [Fpi/Pb]'
  },
  {
    Ticker: 'FPL',
    Company: 'First Trust New Opportunities M'
  },
  {
    Ticker: 'FR',
    Company: 'First Industrial Realty Trust'
  },
  {
    Ticker: 'FRA',
    Company: 'Blackrock Floating Rate Income Fund'
  },
  {
    Ticker: 'FRC',
    Company: 'First Republic Bank'
  },
  {
    Ticker: 'FRC-F',
    Company: 'First Republic Bank San Francisco [Frc/Pf]'
  },
  {
    Ticker: 'FRC-G',
    Company: 'First Republic Bank San Francisco [Frc/Pg]'
  },
  {
    Ticker: 'FRC-H',
    Company: 'First Republic Bank San Francisco [Frc/Ph]'
  },
  {
    Ticker: 'FRC-I',
    Company: 'First Rep Bk San Francisco [Frc/Pi]'
  },
  {
    Ticker: 'FRC-J',
    Company: 'First Republic Bank Prf Perpetual USD Dp Sh Rp1/'
  },
  {
    Ticker: 'FRO',
    Company: 'Frontline Ltd'
  },
  {
    Ticker: 'FRT',
    Company: 'Federal Realty Investment Trust'
  },
  {
    Ticker: 'FRT-C',
    Company: 'Federal Realty Invt TR [Frt/Pc]'
  },
  {
    Ticker: 'FSB',
    Company: 'Franklin Financial Network Inc'
  },
  {
    Ticker: 'FSD',
    Company: 'High Income Long Short Fund'
  },
  {
    Ticker: 'FSK',
    Company: 'FS KKR Capital Corp'
  },
  {
    Ticker: 'FSLY',
    Company: 'Fastly Inc'
  },
  {
    Ticker: 'FSM',
    Company: 'Fortuna Silver Mines'
  },
  {
    Ticker: 'FSS',
    Company: 'Federal Signal Corp'
  },
  {
    Ticker: 'FT',
    Company: 'Franklin Universal Trust'
  },
  {
    Ticker: 'FTA-A',
    Company: 'Fortress Transportation and Infrastructure Inves'
  },
  {
    Ticker: 'FTA-B',
    Company: 'Fortress Transportation and Infrastructure Inves'
  },
  {
    Ticker: 'FTAI',
    Company: 'Fortress Transportation & Infra'
  },
  {
    Ticker: 'FTCH',
    Company: 'Farfetch Ltd Cl A'
  },
  {
    Ticker: 'FTI',
    Company: 'Technipfmc Plc'
  },
  {
    Ticker: 'FTK',
    Company: 'Flotek Industries'
  },
  {
    Ticker: 'FTS',
    Company: 'Fortis Inc'
  },
  {
    Ticker: 'FTSI',
    Company: 'Fts International Inc'
  },
  {
    Ticker: 'FTV',
    Company: 'Fortive Corp'
  },
  {
    Ticker: 'FTV-A',
    Company: 'Fortive Corp [Ftv/Pa]'
  },
  {
    Ticker: 'FUL',
    Company: 'H. B. Fuller Company'
  },
  {
    Ticker: 'FUN',
    Company: 'Cedar Fair LP'
  },
  {
    Ticker: 'FVRR',
    Company: 'Fiverr International Ltd'
  },
  {
    Ticker: 'G',
    Company: 'Genpact Ltd'
  },
  {
    Ticker: 'GAB',
    Company: 'Gabelli Equity Trust'
  },
  {
    Ticker: 'GAB-D',
    Company: 'Gabelli Equity Trust Inc [Gab/Pd]'
  },
  {
    Ticker: 'GAB-G',
    Company: 'Gabelli Equity Trust Inc [Gab/Pg]'
  },
  {
    Ticker: 'GAB-H',
    Company: 'Gabelli Equity Trust Inc [Gab/Ph]'
  },
  {
    Ticker: 'GAB-J',
    Company: 'Gabelli Equity Trust Inc [Gab/Pj]'
  },
  {
    Ticker: 'GAM',
    Company: 'General American Investors'
  },
  {
    Ticker: 'GAM-B',
    Company: 'General Amern Invs Inc [Gam/Pb]'
  },
  {
    Ticker: 'GATX',
    Company: 'GATX Corp'
  },
  {
    Ticker: 'GBAB',
    Company: 'Guggenheim Build America Bonds'
  },
  {
    Ticker: 'GBL',
    Company: 'Gamco Investors'
  },
  {
    Ticker: 'GBX',
    Company: 'Greenbrier Companies'
  },
  {
    Ticker: 'GCAP',
    Company: 'Gain Capital Holdings'
  },
  {
    Ticker: 'GCI',
    Company: 'Gannett Co. Inc.'
  },
  {
    Ticker: 'GCO',
    Company: 'Genesco Inc'
  },
  {
    Ticker: 'GCP',
    Company: 'Gcp Applied Technologies Inc C'
  },
  {
    Ticker: 'GCV',
    Company: 'Gabelli Convertible and Income Securities'
  },
  {
    Ticker: 'GCV-B',
    Company: 'Gabelli Conv&Incom Secs Fd Inc [Gcv/Pb]'
  },
  {
    Ticker: 'GD',
    Company: 'General Dynamics Corp'
  },
  {
    Ticker: 'GDDY',
    Company: 'Godaddy Inc'
  },
  {
    Ticker: 'GDI',
    Company: 'Gardner Denver Holdings Inc'
  },
  {
    Ticker: 'GDL',
    Company: 'The Gdl Fund'
  },
  {
    Ticker: 'GDL-C',
    Company: 'Gdl Fund [Gdl/Pc]'
  },
  {
    Ticker: 'GDO',
    Company: 'Western Asset Global Corporate Defined'
  },
  {
    Ticker: 'GDOT',
    Company: 'Green Dot Corp'
  },
  {
    Ticker: 'GDV',
    Company: 'Gabelli Dividend'
  },
  {
    Ticker: 'GDV-A',
    Company: 'Gabelli Dividend & Income Trust [Gdv/Pa]'
  },
  {
    Ticker: 'GDV-D',
    Company: 'Gabelli Dividend & Income Trust [Gdv/Pd]'
  },
  {
    Ticker: 'GDV-G',
    Company: 'Gabelli Dividend & Income Trust [Gdv/Pg]'
  },
  {
    Ticker: 'GDV-H',
    Company: 'Gabelli Dividend & Income Trust [Gdv/Ph]'
  },
  {
    Ticker: 'GE',
    Company: 'General Electric Company'
  },
  {
    Ticker: 'GEF',
    Company: 'Greif Bros. Corp'
  },
  {
    Ticker: 'GEF.B',
    Company: 'Greif Bros. Corp Cl B'
  },
  {
    Ticker: 'GEL',
    Company: 'Genesis Energy LP'
  },
  {
    Ticker: 'GEN',
    Company: 'Genesis Healthcare Inc'
  },
  {
    Ticker: 'GEO',
    Company: 'Geo Group Inc'
  },
  {
    Ticker: 'GER',
    Company: 'Goldman Sachs MLP Energy Renai'
  },
  {
    Ticker: 'GES',
    Company: 'Guess Inc'
  },
  {
    Ticker: 'GF',
    Company: 'New Germany Fund'
  },
  {
    Ticker: 'GFF',
    Company: 'Griffon Corp'
  },
  {
    Ticker: 'GFI',
    Company: 'Gold Fields Ltd'
  },
  {
    Ticker: 'GFY',
    Company: 'Western Asset Variable Rate Strategic'
  },
  {
    Ticker: 'GGB',
    Company: 'Gerdau S.A.'
  },
  {
    Ticker: 'GGG',
    Company: 'Graco Inc'
  },
  {
    Ticker: 'GGM',
    Company: 'Guggenheim Credit Allocation F'
  },
  {
    Ticker: 'GGT',
    Company: 'Gabelli Global Multi-Media Trust'
  },
  {
    Ticker: 'GGT-B',
    Company: 'Gabelli Multimedia Trust Inc [Ggt/Pb]'
  },
  {
    Ticker: 'GGT-E',
    Company: 'Gabelli Multimedia Trust Inc [Ggt/Pe]'
  },
  {
    Ticker: 'GGZ',
    Company: 'Gabelli Global Small and Mid CA'
  },
  {
    Ticker: 'GGZ-A',
    Company: 'Gabelli Global Small & Mid Cap Val [Ggz/Pa]'
  },
  {
    Ticker: 'GHC',
    Company: 'Graham Holdings Company'
  },
  {
    Ticker: 'GHG',
    Company: 'Greentree Hospitality Group Ltd ADR'
  },
  {
    Ticker: 'GHL',
    Company: 'Greenhill & Co. Inc'
  },
  {
    Ticker: 'GHM',
    Company: 'Graham Corp'
  },
  {
    Ticker: 'GHY',
    Company: 'Prudential Global Short Durati'
  },
  {
    Ticker: 'GIB',
    Company: 'CGI Group'
  },
  {
    Ticker: 'GIG',
    Company: 'Gigcapital Inc'
  },
  {
    Ticker: 'GIG.P',
    Company: 'Gigcapital Inc [Gig/R]'
  },
  {
    Ticker: 'GIG.U',
    Company: 'Gigcapital Inc'
  },
  {
    Ticker: 'GIG.W',
    Company: 'Gigcapital Inc WT [Gig/W]'
  },
  {
    Ticker: 'GIL',
    Company: 'Gildan Activewear'
  },
  {
    Ticker: 'GIM',
    Company: 'Templeton Global'
  },
  {
    Ticker: 'GIS',
    Company: 'General Mills'
  },
  {
    Ticker: 'GIX',
    Company: 'Gigcapital2 Inc'
  },
  {
    Ticker: 'GIX.P',
    Company: 'Gigcapital2 Inc [Gix/R]'
  },
  {
    Ticker: 'GIX.U',
    Company: 'Gigcapital2 Inc'
  },
  {
    Ticker: 'GIX.W',
    Company: 'Gigcapital2 Inc WT [Gix/W]'
  },
  {
    Ticker: 'GJH',
    Company: 'Strats Sm Trust For United States Cellular Corp'
  },
  {
    Ticker: 'GJO',
    Company: 'Strats Sm Trust For Wal-Mart Stores Inc Securiti'
  },
  {
    Ticker: 'GJP',
    Company: 'Strats Sm Trust For Dominion Resources Inc Secur'
  },
  {
    Ticker: 'GJR',
    Company: 'Strats Sm Trust For The Procter & Gamble Co. Sec'
  },
  {
    Ticker: 'GJS',
    Company: 'Strats Sm Trust For Goldman Sachs Group Securiti'
  },
  {
    Ticker: 'GJT',
    Company: 'Strats Sm Trust For Allstate Corp Securities Ser'
  },
  {
    Ticker: 'GJV',
    Company: 'Strats Sm Trust For News Corp Securities Series'
  },
  {
    Ticker: 'GKOS',
    Company: 'Glaukos Corp'
  },
  {
    Ticker: 'GL',
    Company: 'Globe Life Inc'
  },
  {
    Ticker: 'GL-C',
    Company: 'Globe Life Inc [Gl/Pc]'
  },
  {
    Ticker: 'GLE.U',
    Company: 'Galileo Acquisition Corp [Gleo.U]'
  },
  {
    Ticker: 'GLEO',
    Company: 'Galileo Acquisition Corp'
  },
  {
    Ticker: 'GLO-A',
    Company: 'Gaslog Partners LP [Glop/Pa]'
  },
  {
    Ticker: 'GLO-B',
    Company: 'Gaslog Partners LP [Glop/Pb]'
  },
  {
    Ticker: 'GLO-C',
    Company: 'Gaslog Partners LP [Glop/Pc]'
  },
  {
    Ticker: 'GLO-G',
    Company: 'Gaslog Ltd [Glog/Pa]'
  },
  {
    Ticker: 'GLOB',
    Company: 'Globant S.A.'
  },
  {
    Ticker: 'GLOG',
    Company: 'Gaslog Ltd'
  },
  {
    Ticker: 'GLOP',
    Company: 'Gaslog Partners LP'
  },
  {
    Ticker: 'GLP',
    Company: 'Global Partners LP'
  },
  {
    Ticker: 'GLP-A',
    Company: 'Global Partners LP [Glp/Pa]'
  },
  {
    Ticker: 'GLT',
    Company: 'Glatfelter'
  },
  {
    Ticker: 'GLW',
    Company: 'Corning Inc'
  },
  {
    Ticker: 'GM',
    Company: 'General Motors Company'
  },
  {
    Ticker: 'GME',
    Company: 'Gamestop Corp'
  },
  {
    Ticker: 'GMED',
    Company: 'Globus Medical Inc'
  },
  {
    Ticker: 'GMR-A',
    Company: 'Global Med REIT Inc [Gmre/Pa]'
  },
  {
    Ticker: 'GMRE',
    Company: 'Global Medical REIT Inc'
  },
  {
    Ticker: 'GMS',
    Company: 'Gms Inc'
  },
  {
    Ticker: 'GMTA',
    Company: 'GATX Corp 5.625% Senior'
  },
  {
    Ticker: 'GMZ',
    Company: 'Goldman Sachs MLP Income Oppor'
  },
  {
    Ticker: 'GNC',
    Company: 'GNC Holdings Inc'
  },
  {
    Ticker: 'GNE',
    Company: 'Genie Energy Ltd Cl B'
  },
  {
    Ticker: 'GNE-A',
    Company: 'Genie Energy Ltd [Gne/Pa]'
  },
  {
    Ticker: 'GNK',
    Company: 'Genco Shipping & Trading Ltd'
  },
  {
    Ticker: 'GNL',
    Company: 'Global Net Lease Inc'
  },
  {
    Ticker: 'GNL-A',
    Company: 'Global Net Lease Inc [Gnl/Pa]'
  },
  {
    Ticker: 'GNL-B',
    Company: 'Global Net Lease Inc 6.875% Prf Perpetual USD 25'
  },
  {
    Ticker: 'GNRC',
    Company: 'Generac Holdings Inc'
  },
  {
    Ticker: 'GNT',
    Company: 'Gabelli Natural Resources Gold'
  },
  {
    Ticker: 'GNT-A',
    Company: 'Gamco Nat Res Gold & Income TR [Gnt/Pa]'
  },
  {
    Ticker: 'GNW',
    Company: 'Genworth Financial Inc'
  },
  {
    Ticker: 'GOF',
    Company: 'Claymore/Guggenheim Strategic Fund'
  },
  {
    Ticker: 'GOL',
    Company: 'Gol Linhas Aereas Inteligentes S.A.'
  },
  {
    Ticker: 'GOLD',
    Company: 'Barrick Gold Corp'
  },
  {
    Ticker: 'GOLF',
    Company: 'Acushnet Holdings Corp'
  },
  {
    Ticker: 'GOOS',
    Company: 'Canada Goose Holdings Inc Subordinate Voting Sh'
  },
  {
    Ticker: 'GPC',
    Company: 'Genuine Parts Company'
  },
  {
    Ticker: 'GPI',
    Company: 'Group 1 Automotive'
  },
  {
    Ticker: 'GPJA',
    Company: 'Georgia Power Company Series 2017A 5.00% Jr'
  },
  {
    Ticker: 'GPK',
    Company: 'Graphic Packaging Holding Company'
  },
  {
    Ticker: 'GPM',
    Company: 'Guggenheim Enhanced Equity'
  },
  {
    Ticker: 'GPMT',
    Company: 'Granite Point Mortgage Trust Inc'
  },
  {
    Ticker: 'GPN',
    Company: 'Global Payments Inc'
  },
  {
    Ticker: 'GPRK',
    Company: 'Geopark Hlds Lmtd'
  },
  {
    Ticker: 'GPS',
    Company: 'Gap Inc'
  },
  {
    Ticker: 'GPX',
    Company: 'Gp Strategies Corp'
  },
  {
    Ticker: 'GRA',
    Company: 'W.R. Grace & Company'
  },
  {
    Ticker: 'GRA.U',
    Company: 'Graf Indl Corp [Graf.U]'
  },
  {
    Ticker: 'GRA.W',
    Company: 'Graf Indl Corp WT [Graf/W]'
  },
  {
    Ticker: 'GRAF',
    Company: 'Graf Industrial Corp'
  },
  {
    Ticker: 'GRAM',
    Company: 'Grana Y Montero S.A.A.'
  },
  {
    Ticker: 'GRC',
    Company: 'Gorman-Rupp Company'
  },
  {
    Ticker: 'GRP.U',
    Company: 'Granite Real Estate Invt TR'
  },
  {
    Ticker: 'GRUB',
    Company: 'Grubhub Inc'
  },
  {
    Ticker: 'GRX',
    Company: 'The Gabelli Healthcare & Wellness Trust'
  },
  {
    Ticker: 'GRX-A',
    Company: 'Gabelli Healthcare & Wellness TR [Grx/Pa]'
  },
  {
    Ticker: 'GRX-B',
    Company: 'Gabelli Healthcare & Wellness TR [Grx/Pb]'
  },
  {
    Ticker: 'GS',
    Company: 'Goldman Sachs Group'
  },
  {
    Ticker: 'GS-A',
    Company: 'Goldman Sachs Group Inc [Gs/Pa]'
  },
  {
    Ticker: 'GS-C',
    Company: 'Goldman Sachs Group Inc [Gs/Pc]'
  },
  {
    Ticker: 'GS-D',
    Company: 'Goldman Sachs Group Inc [Gs/Pd]'
  },
  {
    Ticker: 'GS-J',
    Company: 'Goldman Sachs Group Inc [Gs/Pj]'
  },
  {
    Ticker: 'GS-K',
    Company: 'Goldman Sachs Group Inc [Gs/Pk]'
  },
  {
    Ticker: 'GS-N',
    Company: 'Goldman Sachs Group Inc [Gs/Pn]'
  },
  {
    Ticker: 'GSA.U',
    Company: 'GS Acquisition Hlds Corp [Gsah.U]'
  },
  {
    Ticker: 'GSA.W',
    Company: 'GS Acquisition Hlds Corp WT [Gsah/W]'
  },
  {
    Ticker: 'GSAH',
    Company: 'GS Acquisition Holdings Corp Cl A'
  },
  {
    Ticker: 'GSBD',
    Company: 'Goldman Sachs Bdc Inc'
  },
  {
    Ticker: 'GSH',
    Company: 'Guangshen Railway'
  },
  {
    Ticker: 'GSK',
    Company: 'Glaxosmithkline Plc'
  },
  {
    Ticker: 'GSL',
    Company: 'Global Ship Lease Inc'
  },
  {
    Ticker: 'GSL-B',
    Company: 'Global Ship Lease Inc [Gsl/Pb]'
  },
  {
    Ticker: 'GSLD',
    Company: 'Global Ship Lease Inc 8.00% Senior Notes Due 20'
  },
  {
    Ticker: 'GSX',
    Company: 'Gsx Techedu Inc ADR'
  },
  {
    Ticker: 'GTES',
    Company: 'Gates Industrial Corp Plc'
  },
  {
    Ticker: 'GTN',
    Company: 'Gray Television'
  },
  {
    Ticker: 'GTN.A',
    Company: 'Gray Television Inc Cl A'
  },
  {
    Ticker: 'GTS',
    Company: 'Triple-S Management Corp'
  },
  {
    Ticker: 'GTT',
    Company: 'Global Telcom & Technology Inc'
  },
  {
    Ticker: 'GTX',
    Company: 'Garrett Motion Inc'
  },
  {
    Ticker: 'GTY',
    Company: 'Getty Realty Corp'
  },
  {
    Ticker: 'GUT',
    Company: 'Gabelli Utility Trust'
  },
  {
    Ticker: 'GUT-A',
    Company: 'Gabelli Utility Trust [Gut/Pa]'
  },
  {
    Ticker: 'GUT-C',
    Company: 'Gabelli Utility Trust [Gut/Pc]'
  },
  {
    Ticker: 'GVA',
    Company: 'Granite Construction Inc'
  },
  {
    Ticker: 'GWB',
    Company: 'Great Western Bancorp Inc'
  },
  {
    Ticker: 'GWR',
    Company: 'Genesee & Wyoming'
  },
  {
    Ticker: 'GWRE',
    Company: 'Guidewire Software Inc'
  },
  {
    Ticker: 'GWW',
    Company: 'W.W. Grainger'
  },
  {
    Ticker: 'GYB',
    Company: 'Cabco Series 2004-101 Trust'
  },
  {
    Ticker: 'GYC',
    Company: 'Corporate Asset Backed Corp Cabco'
  },
  {
    Ticker: 'H',
    Company: 'Hyatt Hotels Corp'
  },
  {
    Ticker: 'HAE',
    Company: 'Haemonetics Corp'
  },
  {
    Ticker: 'HAL',
    Company: 'Halliburton Company'
  },
  {
    Ticker: 'HASI',
    Company: 'Hannon Armstrong Sustainable IN'
  },
  {
    Ticker: 'HBB',
    Company: 'Hamilton Beach Brands Holding Company Cl A'
  },
  {
    Ticker: 'HBI',
    Company: 'Hanesbrands Inc'
  },
  {
    Ticker: 'HBM',
    Company: 'Hudbay Minerals Inc'
  },
  {
    Ticker: 'HCA',
    Company: 'Hca Holdings Inc'
  },
  {
    Ticker: 'HCC',
    Company: 'Warrior Met Coal Inc'
  },
  {
    Ticker: 'HCFT',
    Company: 'Hunt Companies Finance Trust Inc'
  },
  {
    Ticker: 'HCHC',
    Company: 'Hc2 Holdings Inc'
  },
  {
    Ticker: 'HCI',
    Company: 'Homeowners Choice'
  },
  {
    Ticker: 'HCR',
    Company: 'Hi-Crush Inc'
  },
  {
    Ticker: 'HCXY',
    Company: 'Hercules Capital Inc 6.25% Notes Due 2033'
  },
  {
    Ticker: 'HCXZ',
    Company: 'Hercules Capital Inc 5.25% Notes Due 2025'
  },
  {
    Ticker: 'HD',
    Company: 'Home Depot'
  },
  {
    Ticker: 'HDB',
    Company: 'Hdfc Bank Ltd'
  },
  {
    Ticker: 'HE',
    Company: 'Hawaiian Electric Industries'
  },
  {
    Ticker: 'HEI',
    Company: 'Heico Corp'
  },
  {
    Ticker: 'HEI.A',
    Company: 'Heico Cp Cl A'
  },
  {
    Ticker: 'HEP',
    Company: 'Holly Energy Partners LP'
  },
  {
    Ticker: 'HEQ',
    Company: 'John Hancock Hedged Eqty & Inc'
  },
  {
    Ticker: 'HES',
    Company: 'Hess Corp'
  },
  {
    Ticker: 'HESM',
    Company: 'Hess Midstream Partners LP'
  },
  {
    Ticker: 'HEXO',
    Company: 'Hexo Corp'
  },
  {
    Ticker: 'HFC',
    Company: 'Hollyfrontier Corp'
  },
  {
    Ticker: 'HFR-A',
    Company: 'Highland Income Fund [Hfro/Pa]'
  },
  {
    Ticker: 'HFRO',
    Company: 'Highland Floating Rate Opportunities Fund'
  },
  {
    Ticker: 'HGH',
    Company: 'The Hartford Financial Srvcs G'
  },
  {
    Ticker: 'HGLB',
    Company: 'Highland Global Allocation Fund'
  },
  {
    Ticker: 'HGV',
    Company: 'Hilton Grand Vacations Inc'
  },
  {
    Ticker: 'HHC',
    Company: 'Howard Hughes Corp'
  },
  {
    Ticker: 'HHS',
    Company: 'Harte-Hanks'
  },
  {
    Ticker: 'HI',
    Company: 'Hillenbrand Inc'
  },
  {
    Ticker: 'HIE',
    Company: 'Miller/Howard High Income Eqty'
  },
  {
    Ticker: 'HIG',
    Company: 'Hartford Financial Services Group'
  },
  {
    Ticker: 'HIG-G',
    Company: 'Hartford Financial Services Grp Inc [Hig/Pg]'
  },
  {
    Ticker: 'HII',
    Company: 'Huntington Ingalls Industries'
  },
  {
    Ticker: 'HIL',
    Company: 'Hill International Inc'
  },
  {
    Ticker: 'HIO',
    Company: 'Western Asset High'
  },
  {
    Ticker: 'HIW',
    Company: 'Highwoods Properties'
  },
  {
    Ticker: 'HIX',
    Company: 'Western Asset High Income Fund II Inc'
  },
  {
    Ticker: 'HJV',
    Company: 'MS S.A.C. Saturns Ge Series 2002-14'
  },
  {
    Ticker: 'HKIB',
    Company: 'Amtd International Inc'
  },
  {
    Ticker: 'HL',
    Company: 'Hecla Mining Company'
  },
  {
    Ticker: 'HL-B',
    Company: 'Hecla Mining Co. [Hl/Pb]'
  },
  {
    Ticker: 'HLF',
    Company: 'Herbalife Ltd'
  },
  {
    Ticker: 'HLI',
    Company: 'Houlihan Lokey'
  },
  {
    Ticker: 'HLT',
    Company: 'Hilton Inc'
  },
  {
    Ticker: 'HLX',
    Company: 'Helix Energy Solutions Group'
  },
  {
    Ticker: 'HMC',
    Company: 'Honda Motor Company'
  },
  {
    Ticker: 'HMI',
    Company: 'Huami Corp ADR'
  },
  {
    Ticker: 'HML-A',
    Company: 'Hoegh Lng Partners LP [Hmlp/Pa]'
  },
  {
    Ticker: 'HMLP',
    Company: 'Hoegh Lng Partners LP'
  },
  {
    Ticker: 'HMN',
    Company: 'Horace Mann Educators Corp'
  },
  {
    Ticker: 'HMY',
    Company: 'Harmony Gold Mining Co. Ltd'
  },
  {
    Ticker: 'HNGR',
    Company: 'Hanger Inc'
  },
  {
    Ticker: 'HNI',
    Company: 'Hon Industries Inc'
  },
  {
    Ticker: 'HNP',
    Company: 'Huaneng Power Intl'
  },
  {
    Ticker: 'HOG',
    Company: 'Harley-Davidson Inc'
  },
  {
    Ticker: 'HOME',
    Company: 'At Home Group Inc'
  },
  {
    Ticker: 'HON',
    Company: 'Honeywell International Inc'
  },
  {
    Ticker: 'HOS',
    Company: 'Hornbeck Offshore Services'
  },
  {
    Ticker: 'HOV',
    Company: 'Hovnanian Enterprises Inc'
  },
  {
    Ticker: 'HP',
    Company: 'Helmerich & Payne'
  },
  {
    Ticker: 'HPE',
    Company: 'Hewlett Packard Enterprise Comp'
  },
  {
    Ticker: 'HPF',
    Company: 'John Hancock Pfd II'
  },
  {
    Ticker: 'HPI',
    Company: 'John Hancock Preferred'
  },
  {
    Ticker: 'HPP',
    Company: 'Hudson Pacific Properties'
  },
  {
    Ticker: 'HPQ',
    Company: 'Hp Inc'
  },
  {
    Ticker: 'HPR',
    Company: 'Highpoint Resources Corp'
  },
  {
    Ticker: 'HPS',
    Company: 'John Hancock Preferred Income Fund III'
  },
  {
    Ticker: 'HQH',
    Company: 'Tekla Healthcare Investors'
  },
  {
    Ticker: 'HQL',
    Company: 'Tekla Life Sciences Investors'
  },
  {
    Ticker: 'HR',
    Company: 'Healthcare Realty Trust Inc'
  },
  {
    Ticker: 'HRB',
    Company: 'H&R Block'
  },
  {
    Ticker: 'HRC',
    Company: 'Hill-Rom Holdings Inc'
  },
  {
    Ticker: 'HRI',
    Company: 'Herc Holdings Inc'
  },
  {
    Ticker: 'HRL',
    Company: 'Hormel Foods Corp'
  },
  {
    Ticker: 'HRTG',
    Company: 'Heritage Insurance Holdings'
  },
  {
    Ticker: 'HSB-A',
    Company: 'HSBC Hlds Plc [Hsbc/Pa]'
  },
  {
    Ticker: 'HSBC',
    Company: 'HSBC Holdings Plc'
  },
  {
    Ticker: 'HSC',
    Company: 'Harsco Corp'
  },
  {
    Ticker: 'HST',
    Company: 'Host Marriott Financial Trust'
  },
  {
    Ticker: 'HSY',
    Company: 'Hershey Foods Corp'
  },
  {
    Ticker: 'HT',
    Company: 'Hersha Hospitality Trust'
  },
  {
    Ticker: 'HT-C',
    Company: 'Hersha Hospitality TR [Ht/Pc]'
  },
  {
    Ticker: 'HT-D',
    Company: 'Hersha Hospitality TR [Ht/Pd]'
  },
  {
    Ticker: 'HT-E',
    Company: 'Hersha Hospitality TR [Ht/Pe]'
  },
  {
    Ticker: 'HTA',
    Company: 'Healthcare Trust of America'
  },
  {
    Ticker: 'HTD',
    Company: 'John Hancock Tax Advantaged Dividend'
  },
  {
    Ticker: 'HTFA',
    Company: 'Horizon Technology Finance 6.25% Notes Due 2022'
  },
  {
    Ticker: 'HTGC',
    Company: 'Hercules Technology Growth Capital'
  },
  {
    Ticker: 'HTH',
    Company: 'Hilltop Holdings Inc'
  },
  {
    Ticker: 'HTY',
    Company: 'John Hancock Tax-Advantaged Global'
  },
  {
    Ticker: 'HTZ',
    Company: 'Hertz Global Holdings Inc'
  },
  {
    Ticker: 'HUBB',
    Company: 'Hubbell Inc B'
  },
  {
    Ticker: 'HUBS',
    Company: 'Hubspot Inc'
  },
  {
    Ticker: 'HUD',
    Company: 'Hudson Ltd Cl A'
  },
  {
    Ticker: 'HUM',
    Company: 'Humana Inc'
  },
  {
    Ticker: 'HUN',
    Company: 'Huntsman Corp'
  },
  {
    Ticker: 'HUYA',
    Company: 'Huya Inc'
  },
  {
    Ticker: 'HVT',
    Company: 'Haverty Furniture Companies'
  },
  {
    Ticker: 'HVT.A',
    Company: 'Haverty Furn Cl A SC'
  },
  {
    Ticker: 'HXL',
    Company: 'Hexcel Corp'
  },
  {
    Ticker: 'HY',
    Company: 'Hyster-Yale Materials Handling'
  },
  {
    Ticker: 'HYB',
    Company: 'New America High'
  },
  {
    Ticker: 'HYI',
    Company: 'Western Asset High Yield Defined'
  },
  {
    Ticker: 'HYT',
    Company: 'Blackrock High Yield Fund Vi Inc'
  },
  {
    Ticker: 'HZN',
    Company: 'Horizon Global Corp Comm'
  },
  {
    Ticker: 'HZO',
    Company: 'Marinemax Inc'
  },
  {
    Ticker: 'I',
    Company: 'Intelsat S.A.'
  },
  {
    Ticker: 'IAA',
    Company: 'Iaa Inc'
  },
  {
    Ticker: 'IAE',
    Company: 'VOYA Asia Pacific High Dividend Equity'
  },
  {
    Ticker: 'IAG',
    Company: 'Iamgold Corp'
  },
  {
    Ticker: 'IBA',
    Company: 'Industrias Bachoco S.A. DE C.V.'
  },
  {
    Ticker: 'IBM',
    Company: 'International Business Machines'
  },
  {
    Ticker: 'IBN',
    Company: 'Icici Bank Ltd'
  },
  {
    Ticker: 'IBP',
    Company: 'Installed Building Products'
  },
  {
    Ticker: 'ICD',
    Company: 'Independence Contract Drilling Inc'
  },
  {
    Ticker: 'ICE',
    Company: 'Intercontinental Exchange'
  },
  {
    Ticker: 'ICL',
    Company: 'Israel Chemicals Ltd'
  },
  {
    Ticker: 'IDA',
    Company: 'Idacorp Inc'
  },
  {
    Ticker: 'IDE',
    Company: 'VOYA Infrastructure Industrial'
  },
  {
    Ticker: 'IDT',
    Company: 'IDT Corp'
  },
  {
    Ticker: 'IEX',
    Company: 'Idex Corp'
  },
  {
    Ticker: 'IFF',
    Company: 'International Flavors & Fragrances'
  },
  {
    Ticker: 'IFFT',
    Company: 'International Flavors & Fragrances Inc'
  },
  {
    Ticker: 'IFN',
    Company: 'India Fund'
  },
  {
    Ticker: 'IFS',
    Company: 'Intercorp Financial Services Inc'
  },
  {
    Ticker: 'IGA',
    Company: 'VOYA Global Advantage and Premium'
  },
  {
    Ticker: 'IGD',
    Company: 'VOYA Global Equity Dividend and Premium'
  },
  {
    Ticker: 'IGI',
    Company: 'Western Asset Investment Grade Defined'
  },
  {
    Ticker: 'IGR',
    Company: 'VOYA Clarion Global Real Estate'
  },
  {
    Ticker: 'IGT',
    Company: 'International Game Technology'
  },
  {
    Ticker: 'IHC',
    Company: 'Independence Holding Company'
  },
  {
    Ticker: 'IHD',
    Company: 'VOYA Emerging Markets High Dividend'
  },
  {
    Ticker: 'IHG',
    Company: 'Intercontinental Hotels Group'
  },
  {
    Ticker: 'IHIT',
    Company: 'Invesco High Income 2023 Target Term Fund'
  },
  {
    Ticker: 'IHTA',
    Company: 'Invesco High Income 2024 Target Term Fund'
  },
  {
    Ticker: 'IID',
    Company: 'VOYA International High Dividend Equity'
  },
  {
    Ticker: 'IIF',
    Company: 'Morgan Stanley India Investment Fund Inc'
  },
  {
    Ticker: 'IIM',
    Company: 'Invesco Insured Muni Income Trust'
  },
  {
    Ticker: 'IIP-A',
    Company: 'Innovative Indl Pptys Inc [Iipr/Pa]'
  },
  {
    Ticker: 'IIPR',
    Company: 'Innovative Industrial Properties'
  },
  {
    Ticker: 'IMAX',
    Company: 'Imax Corp'
  },
  {
    Ticker: 'INB',
    Company: 'Cohen & Steers Global Income Builder'
  },
  {
    Ticker: 'INF',
    Company: 'Brookfield Global Listed Infra'
  },
  {
    Ticker: 'INFO',
    Company: 'IHS Markit Ltd'
  },
  {
    Ticker: 'INFY',
    Company: 'Infosys Ltd'
  },
  {
    Ticker: 'ING',
    Company: 'ING Groep N.V. ADR'
  },
  {
    Ticker: 'INGR',
    Company: 'Ingredion Inc'
  },
  {
    Ticker: 'INN',
    Company: 'Summit Hotel Properties'
  },
  {
    Ticker: 'INN-D',
    Company: 'Summit Hotel Pptys Inc [Inn/Pd]'
  },
  {
    Ticker: 'INN-E',
    Company: 'Summit Hotel Pptys Inc [Inn/Pe]'
  },
  {
    Ticker: 'INS-A',
    Company: 'International Seaways Inc [Insw/Pa]'
  },
  {
    Ticker: 'INSI',
    Company: 'Insight Select Income Fund'
  },
  {
    Ticker: 'INSP',
    Company: 'Inspire Medical Systems Inc'
  },
  {
    Ticker: 'INST',
    Company: 'Instructure Inc'
  },
  {
    Ticker: 'INSW',
    Company: 'International Seaways Inc'
  },
  {
    Ticker: 'INT',
    Company: 'World Fuel Services Corp'
  },
  {
    Ticker: 'INVH',
    Company: 'Invitation Homes Inc'
  },
  {
    Ticker: 'INXN',
    Company: 'Interxion Holding N.V.'
  },
  {
    Ticker: 'IO',
    Company: 'ION Geophysical Corp'
  },
  {
    Ticker: 'IP',
    Company: 'International Paper Company'
  },
  {
    Ticker: 'IPG',
    Company: 'Interpublic Group of Companies'
  },
  {
    Ticker: 'IPHI',
    Company: 'Inphi Corp'
  },
  {
    Ticker: 'IPI',
    Company: 'Intrepid Potash Inc'
  },
  {
    Ticker: 'IQI',
    Company: 'Invesco Quality Muni Income Trust'
  },
  {
    Ticker: 'IQV',
    Company: 'Iqvia Holdings Inc'
  },
  {
    Ticker: 'IR',
    Company: 'Ingersoll-Rand Plc [Ireland]'
  },
  {
    Ticker: 'IRE-C',
    Company: 'Investors Real Estate TR [Iret/Pc]'
  },
  {
    Ticker: 'IRET',
    Company: 'Investors Real Estate Trust'
  },
  {
    Ticker: 'IRL',
    Company: 'Irish Investment Fund'
  },
  {
    Ticker: 'IRM',
    Company: 'Iron Mountain Inc'
  },
  {
    Ticker: 'IRR',
    Company: 'VOYA Risk Managed Natural Resources Fund'
  },
  {
    Ticker: 'IRS',
    Company: 'Irsa Inversiones Y Representaciones S.A.'
  },
  {
    Ticker: 'IRT',
    Company: 'Independence Realty Trust Inc'
  },
  {
    Ticker: 'ISD',
    Company: 'Prudential Short Duration High'
  },
  {
    Ticker: 'ISG',
    Company: 'VOYA Group N.V.'
  },
  {
    Ticker: 'IT',
    Company: 'Gartner Inc'
  },
  {
    Ticker: 'ITCB',
    Company: 'Itau Corpbanca'
  },
  {
    Ticker: 'ITGR',
    Company: 'Integer Holdings Corp'
  },
  {
    Ticker: 'ITT',
    Company: 'ITT Inc'
  },
  {
    Ticker: 'ITUB',
    Company: 'Itau Unibanco Banco Holding S.A.'
  },
  {
    Ticker: 'ITW',
    Company: 'Illinois Tool Works Inc'
  },
  {
    Ticker: 'IVC',
    Company: 'Invacare Corp'
  },
  {
    Ticker: 'IVH',
    Company: 'Ivy High Income Opportunities'
  },
  {
    Ticker: 'IVR',
    Company: 'Invesco Mortgage Capital Inc'
  },
  {
    Ticker: 'IVR-A',
    Company: 'Invesco Mortgage Capital Inc [Ivr/Pa]'
  },
  {
    Ticker: 'IVR-B',
    Company: 'Invesco Mortgage Capital Inc [Ivr/Pb]'
  },
  {
    Ticker: 'IVR-C',
    Company: 'Invesco Mortgage Capital Inc [Ivr/Pc]'
  },
  {
    Ticker: 'IVZ',
    Company: 'Invesco Plc'
  },
  {
    Ticker: 'IX',
    Company: 'Orix Corp ADR'
  },
  {
    Ticker: 'J',
    Company: 'Jacobs Engineering Group Inc'
  },
  {
    Ticker: 'JAG',
    Company: 'Jagged Peak Energy Inc'
  },
  {
    Ticker: 'JAX',
    Company: "J. Alexander's Holdings Inc C"
  },
  {
    Ticker: 'JBGS',
    Company: 'Jbg Smith Properties'
  },
  {
    Ticker: 'JBK',
    Company: 'Lehman Abs Corp'
  },
  {
    Ticker: 'JBL',
    Company: 'Jabil Circuit'
  },
  {
    Ticker: 'JBN',
    Company: 'Select Asset Inc'
  },
  {
    Ticker: 'JBR',
    Company: 'Select Asset Inc'
  },
  {
    Ticker: 'JBT',
    Company: 'John Bean Technologies Corp'
  },
  {
    Ticker: 'JCA-B',
    Company: 'Jernigan Cap Inc [Jcap/Pb]'
  },
  {
    Ticker: 'JCAP',
    Company: 'Jernigan Capital Inc'
  },
  {
    Ticker: 'JCE',
    Company: 'Nuveen Core Equity Alpha Fund'
  },
  {
    Ticker: 'JCI',
    Company: 'Johnson Controls Intl'
  },
  {
    Ticker: 'JCO',
    Company: 'Nuveen Credit Opportunities 2022 Target Term Fun'
  },
  {
    Ticker: 'JCP',
    Company: 'J.C. Penney Company Inc'
  },
  {
    Ticker: 'JDD',
    Company: 'Nuveen Diversified Dividend and'
  },
  {
    Ticker: 'JE',
    Company: 'Just Energy Group Inc'
  },
  {
    Ticker: 'JE-A',
    Company: 'Just Energy Group Inc [Je/Pa]'
  },
  {
    Ticker: 'JEC',
    Company: 'Jacobs Engineering Group Inc'
  },
  {
    Ticker: 'JEF',
    Company: 'Jefferies Financial Group Inc'
  },
  {
    Ticker: 'JELD',
    Company: 'Jeld-Wen Holding Inc'
  },
  {
    Ticker: 'JEMD',
    Company: 'Nuveen Emerging Markets Debt 2022 Target Term Fu'
  },
  {
    Ticker: 'JEQ',
    Company: 'Japan Equity Fund'
  },
  {
    Ticker: 'JFR',
    Company: 'Nuveen Floating Rate'
  },
  {
    Ticker: 'JGH',
    Company: 'Nuveen Global High Income Fund'
  },
  {
    Ticker: 'JHAA',
    Company: 'Nuveen High Income 2023 Target Term Fund'
  },
  {
    Ticker: 'JHB',
    Company: 'Nuveen High Income November 2021 Target Term Fun'
  },
  {
    Ticker: 'JHD',
    Company: 'Nuveen High Income December 2019 Target Term Fun'
  },
  {
    Ticker: 'JHG',
    Company: 'Janus Henderson Group Plc'
  },
  {
    Ticker: 'JHI',
    Company: 'John Hancock Investors Trust'
  },
  {
    Ticker: 'JHS',
    Company: 'John Hancock Income Securities Trust'
  },
  {
    Ticker: 'JHX',
    Company: 'James Hardie Industries Se'
  },
  {
    Ticker: 'JHY',
    Company: 'Nuveen High Income 2020 Target'
  },
  {
    Ticker: 'JIH.U',
    Company: 'Juniper Industrial Holdings Inc Units Each Con'
  },
  {
    Ticker: 'JILL',
    Company: 'J. Jill Inc'
  },
  {
    Ticker: 'JKS',
    Company: 'Jinkosolar Holding Company Ltd'
  },
  {
    Ticker: 'JLL',
    Company: 'Jones Lang Lasalle Inc'
  },
  {
    Ticker: 'JLS',
    Company: 'Nuveen Mortgage Opportunity Term Fund'
  },
  {
    Ticker: 'JMEI',
    Company: 'Jumei International Holding Ltd'
  },
  {
    Ticker: 'JMF',
    Company: 'Nuveen Energy MLP Total Return Fund'
  },
  {
    Ticker: 'JMIA',
    Company: 'Jumia Technologies Ag ADR'
  },
  {
    Ticker: 'JMLP',
    Company: 'Nuveen All Cap Energy MLP Oppo'
  },
  {
    Ticker: 'JMM',
    Company: 'Nuveen Multi-Market Income Fund Inc'
  },
  {
    Ticker: 'JMP',
    Company: 'JMP Group Inc'
  },
  {
    Ticker: 'JMPD',
    Company: 'JMP Group Llc 7.25% Senior Notes Due 2027'
  },
  {
    Ticker: 'JMPE',
    Company: 'JMP Group Llc 6.875% Senior Notes Due 2029'
  },
  {
    Ticker: 'JMT',
    Company: 'Nuven Mortgage Opportunity Term Fund 2'
  },
  {
    Ticker: 'JNJ',
    Company: 'Johnson & Johnson'
  },
  {
    Ticker: 'JNPR',
    Company: 'Juniper Networks'
  },
  {
    Ticker: 'JOE',
    Company: 'St. Joe Company'
  },
  {
    Ticker: 'JOF',
    Company: 'Japan Smaller Capitalization Fund Inc'
  },
  {
    Ticker: 'JP',
    Company: 'Jupai Holdings Ltd'
  },
  {
    Ticker: 'JPC',
    Company: 'Nuveen Preferred and Convertible'
  },
  {
    Ticker: 'JPI',
    Company: 'Nuveen Preferred and Income Term Fund'
  },
  {
    Ticker: 'JPM',
    Company: 'JP Morgan Chase & Company'
  },
  {
    Ticker: 'JPM-A',
    Company: 'Jpmorgan Chase & Co. [Jpm/Pa]'
  },
  {
    Ticker: 'JPM-C',
    Company: 'Jpmorgan Chase & Co. [Jpm/Pc]'
  },
  {
    Ticker: 'JPM-D',
    Company: 'Jpmorgan Chase & Co. [Jpm/Pd]'
  },
  {
    Ticker: 'JPM-F',
    Company: 'Jpmorgan Chase & Co. [Jpm/Pf]'
  },
  {
    Ticker: 'JPM-G',
    Company: 'Jpmorgan Chase & Co. [Jpm/Pg]'
  },
  {
    Ticker: 'JPM-H',
    Company: 'Jpmorgan Chase & Co. [Jpm/Ph]'
  },
  {
    Ticker: 'JPM-J',
    Company: 'Jpmorgan Chase & Co. Prf Perpetual USD 1/400Th I'
  },
  {
    Ticker: 'JPS',
    Company: 'Nuveen Quality Preferred 2'
  },
  {
    Ticker: 'JPT',
    Company: 'Nuveen Preferred and Income 2022 Term Fund'
  },
  {
    Ticker: 'JQC',
    Company: 'Nuveen Preferred and Convertible 2'
  },
  {
    Ticker: 'JRI',
    Company: 'Nuveen Real Asset Income and Gr'
  },
  {
    Ticker: 'JRO',
    Company: 'Nuveen Floating Rate Income Opportuntiy'
  },
  {
    Ticker: 'JRS',
    Company: 'Nuveen Real Estate Fund'
  },
  {
    Ticker: 'JSD',
    Company: 'Nuveen Short Duration Credit O'
  },
  {
    Ticker: 'JT',
    Company: 'Jianpu Technology Inc'
  },
  {
    Ticker: 'JTA',
    Company: 'Nuveen Tax-Advantaged Total Return'
  },
  {
    Ticker: 'JTD',
    Company: 'Nuveen Tax-Advantaged Dividend Growth'
  },
  {
    Ticker: 'JW.A',
    Company: 'John Wiley Sons Cl A'
  },
  {
    Ticker: 'JW.B',
    Company: 'John Wiley Sons Cl B'
  },
  {
    Ticker: 'JWN',
    Company: 'Nordstrom'
  },
  {
    Ticker: 'K',
    Company: 'Kellogg Company'
  },
  {
    Ticker: 'KAI',
    Company: 'Kadant Inc'
  },
  {
    Ticker: 'KAMN',
    Company: 'Kaman Corp'
  },
  {
    Ticker: 'KAR',
    Company: 'Kar Auction Services Inc'
  },
  {
    Ticker: 'KB',
    Company: 'KB Financial Group Inc'
  },
  {
    Ticker: 'KBH',
    Company: 'KB Home'
  },
  {
    Ticker: 'KBR',
    Company: 'KBR Inc'
  },
  {
    Ticker: 'KDMN',
    Company: 'Kadmon Holdings Llc'
  },
  {
    Ticker: 'KDP',
    Company: 'Keurig Dr Pepper Inc'
  },
  {
    Ticker: 'KEG',
    Company: 'Key Energy Services Inc'
  },
  {
    Ticker: 'KEM',
    Company: 'Kemet Corp'
  },
  {
    Ticker: 'KEN',
    Company: 'Kenon Holdings Ltd'
  },
  {
    Ticker: 'KEP',
    Company: 'Korea Electric Power Corp'
  },
  {
    Ticker: 'KEX',
    Company: 'Kirby Corp'
  },
  {
    Ticker: 'KEY',
    Company: 'Keycorp'
  },
  {
    Ticker: 'KEY-I',
    Company: 'Keycorp [Key/Pi]'
  },
  {
    Ticker: 'KEY-J',
    Company: 'Keycorp [Key/Pj]'
  },
  {
    Ticker: 'KEY-K',
    Company: 'Keycorp [Key/Pk]'
  },
  {
    Ticker: 'KEYS',
    Company: 'Keysight Technologies Inc Comm'
  },
  {
    Ticker: 'KF',
    Company: 'Korea Fund'
  },
  {
    Ticker: 'KFS',
    Company: 'Kingsway Financial Services'
  },
  {
    Ticker: 'KFY',
    Company: 'Korn/Ferry International'
  },
  {
    Ticker: 'KGC',
    Company: 'Kinross Gold Corp'
  },
  {
    Ticker: 'KIM',
    Company: 'Kimco Realty Corp'
  },
  {
    Ticker: 'KIM-J',
    Company: 'Kimco Rlty Corp [Kim/Pj]'
  },
  {
    Ticker: 'KIM-L',
    Company: 'Kimco Rlty Corp [Kim/Pl]'
  },
  {
    Ticker: 'KIM-M',
    Company: 'Kimco Realty Corp Dep Shs Repstg 1/1000 Shs Cl M'
  },
  {
    Ticker: 'KIO',
    Company: 'KKR Income Opportunities Fund'
  },
  {
    Ticker: 'KKR',
    Company: 'KKR & Co. LP'
  },
  {
    Ticker: 'KKR-A',
    Company: 'KKR & Co. Inc [Kkr/Pa]'
  },
  {
    Ticker: 'KKR-B',
    Company: 'KKR & Co. Inc [Kkr/Pb]'
  },
  {
    Ticker: 'KL',
    Company: 'Kirkland Lake Gold Ltd'
  },
  {
    Ticker: 'KMB',
    Company: 'Kimberly-Clark Corp'
  },
  {
    Ticker: 'KMF',
    Company: 'Kayne Anderson Midstream Energy Fund'
  },
  {
    Ticker: 'KMI',
    Company: 'Kinder Morgan'
  },
  {
    Ticker: 'KMPR',
    Company: 'Kemper Corp'
  },
  {
    Ticker: 'KMT',
    Company: 'Kennametal Inc'
  },
  {
    Ticker: 'KMX',
    Company: 'Carmax Inc'
  },
  {
    Ticker: 'KN',
    Company: 'Knowles Corp'
  },
  {
    Ticker: 'KNL',
    Company: 'Knoll Inc'
  },
  {
    Ticker: 'KNOP',
    Company: 'Knot Offshore Partners LP'
  },
  {
    Ticker: 'KNX',
    Company: 'Knight-Swift Transporation Inc'
  },
  {
    Ticker: 'KO',
    Company: 'Coca-Cola Company'
  },
  {
    Ticker: 'KODK',
    Company: 'Eastman Kodak'
  },
  {
    Ticker: 'KOF',
    Company: 'Coca Cola Femsa S.A.B. DE C.V.'
  },
  {
    Ticker: 'KOP',
    Company: 'Koppers Holdings Inc'
  },
  {
    Ticker: 'KOS',
    Company: 'Kosmos Energy Ltd'
  },
  {
    Ticker: 'KR',
    Company: 'Kroger Company'
  },
  {
    Ticker: 'KRA',
    Company: 'Kraton Performance Polymers Inc'
  },
  {
    Ticker: 'KRC',
    Company: 'Kilroy Realty Corp'
  },
  {
    Ticker: 'KREF',
    Company: 'KKR Real Estate Finance Trust Inc'
  },
  {
    Ticker: 'KRG',
    Company: 'Kite Realty Group Trust'
  },
  {
    Ticker: 'KRO',
    Company: 'Kronos Worldwide Inc'
  },
  {
    Ticker: 'KRP',
    Company: 'Kimbell Royalty Partners'
  },
  {
    Ticker: 'KSM',
    Company: 'Scudder Strategic Municiple Income Trust'
  },
  {
    Ticker: 'KSS',
    Company: "Kohl's Corp"
  },
  {
    Ticker: 'KSU',
    Company: 'Kansas City Southern'
  },
  {
    Ticker: 'KSU.P',
    Company: 'Kansas City Southern [Ksu/P]'
  },
  {
    Ticker: 'KT',
    Company: 'Korea Telecom Corp'
  },
  {
    Ticker: 'KTB',
    Company: 'Kontoor Brands Inc [Ktb/I]'
  },
  {
    Ticker: 'KTF',
    Company: 'Scudder Municiple Income Trust'
  },
  {
    Ticker: 'KTH',
    Company: 'Lehman Abs Corp'
  },
  {
    Ticker: 'KTN',
    Company: 'Lehman Abs Corp'
  },
  {
    Ticker: 'KTP',
    Company: 'Lehman Abs Corp'
  },
  {
    Ticker: 'KW',
    Company: 'Kennedy-Wilson Holdings Inc'
  },
  {
    Ticker: 'KWR',
    Company: 'Quaker Chemical Corp'
  },
  {
    Ticker: 'KYN',
    Company: 'Kayne Anderson MLP Investment Company'
  },
  {
    Ticker: 'KYN-F',
    Company: 'Kayne Anderson MLP Midstream Invest [Kyn/Pf]'
  },
  {
    Ticker: 'L',
    Company: 'Loews Corp'
  },
  {
    Ticker: 'LAC',
    Company: 'Lithium Americas Corp'
  },
  {
    Ticker: 'LAD',
    Company: 'Lithia Motors'
  },
  {
    Ticker: 'LADR',
    Company: 'Ladder Capital Corp'
  },
  {
    Ticker: 'LAIX',
    Company: 'Laix Inc ADR'
  },
  {
    Ticker: 'LAZ',
    Company: 'Lazard Ltd'
  },
  {
    Ticker: 'LB',
    Company: 'L Brands Inc'
  },
  {
    Ticker: 'LBRT',
    Company: 'Liberty Oilfield Services Inc'
  },
  {
    Ticker: 'LC',
    Company: 'Lendingclub Corp'
  },
  {
    Ticker: 'LCI',
    Company: 'Lannett Co. Inc'
  },
  {
    Ticker: 'LCII',
    Company: 'Lci Industries'
  },
  {
    Ticker: 'LDL',
    Company: 'Lydall Inc'
  },
  {
    Ticker: 'LDOS',
    Company: 'Leidos Holdings Inc'
  },
  {
    Ticker: 'LDP',
    Company: 'Cohen & Steers Ltd Duration Prfd Income Fund'
  },
  {
    Ticker: 'LEA',
    Company: 'Lear Corp'
  },
  {
    Ticker: 'LEAF',
    Company: 'Leaf Group Ltd'
  },
  {
    Ticker: 'LEE',
    Company: 'Lee Enterprises Inc'
  },
  {
    Ticker: 'LEG',
    Company: 'Leggett & Platt Inc'
  },
  {
    Ticker: 'LEJU',
    Company: 'Leju Holdings Ltd'
  },
  {
    Ticker: 'LEN',
    Company: 'Lennar Corp'
  },
  {
    Ticker: 'LEN.B',
    Company: 'Lennar Corp Cl B'
  },
  {
    Ticker: 'LEO',
    Company: 'Dreyfus Strategic Municipals'
  },
  {
    Ticker: 'LEVI',
    Company: 'Levi Strauss & Co. Cl A'
  },
  {
    Ticker: 'LFC',
    Company: 'China Life Insurance Company Ltd'
  },
  {
    Ticker: 'LGC',
    Company: 'Legacy Acquisition Corp'
  },
  {
    Ticker: 'LGC.U',
    Company: 'Legacy Acquisition Corp [Lgc/U]'
  },
  {
    Ticker: 'LGC.W',
    Company: 'Legacy Acquisition Corp WT [Lgc/W]'
  },
  {
    Ticker: 'LGF.A',
    Company: 'Lions Gate Entertainment Corp Cl A'
  },
  {
    Ticker: 'LGF.B',
    Company: 'Lions Gate Entertainment Corp Cl B'
  },
  {
    Ticker: 'LGI',
    Company: 'Lazard Global Total Return and'
  },
  {
    Ticker: 'LH',
    Company: 'Laboratory Corp of America Holdings'
  },
  {
    Ticker: 'LHC',
    Company: 'Leo Holdings Corp Cl A'
  },
  {
    Ticker: 'LHC.W',
    Company: 'Leo Holdings Corp WT [Lhc/W]'
  },
  {
    Ticker: 'LHX',
    Company: 'L3Harris Technologies Inc'
  },
  {
    Ticker: 'LII',
    Company: 'Lennox International'
  },
  {
    Ticker: 'LIN',
    Company: 'Linde Plc'
  },
  {
    Ticker: 'LINX',
    Company: 'Linx S.A. ADR'
  },
  {
    Ticker: 'LITB',
    Company: 'Lightinthebox Holding Co. Ltd'
  },
  {
    Ticker: 'LKSD',
    Company: 'Lsc Communications Inc'
  },
  {
    Ticker: 'LL',
    Company: 'Lumber Liquidators Holdings Inc'
  },
  {
    Ticker: 'LLY',
    Company: 'Eli Lilly and Company'
  },
  {
    Ticker: 'LM',
    Company: 'Legg Mason Inc'
  },
  {
    Ticker: 'LMHA',
    Company: 'Legg Mason Inc'
  },
  {
    Ticker: 'LMHB',
    Company: 'Legg Mason Inc'
  },
  {
    Ticker: 'LMT',
    Company: 'Lockheed Martin Corp'
  },
  {
    Ticker: 'LN',
    Company: 'Line Corp'
  },
  {
    Ticker: 'LNC',
    Company: 'Lincoln National Corp'
  },
  {
    Ticker: 'LND',
    Company: 'Brasilagro ADR'
  },
  {
    Ticker: 'LNN',
    Company: 'Lindsay Corp'
  },
  {
    Ticker: 'LOMA',
    Company: 'Loma Negra Comp Indu Argentina Sociedad ADR'
  },
  {
    Ticker: 'LOR',
    Company: 'Lazard World Dividend &'
  },
  {
    Ticker: 'LOW',
    Company: "Lowe's Companies"
  },
  {
    Ticker: 'LPG',
    Company: 'Dorian Lpg Ltd'
  },
  {
    Ticker: 'LPI',
    Company: 'Laredo Petroleum Holdings Inc'
  },
  {
    Ticker: 'LPL',
    Company: 'Lg Display Co. Ltd'
  },
  {
    Ticker: 'LPT',
    Company: 'Liberty Property Trust'
  },
  {
    Ticker: 'LPX',
    Company: 'Louisiana-Pacific Corp'
  },
  {
    Ticker: 'LRN',
    Company: 'K12 Inc'
  },
  {
    Ticker: 'LSI',
    Company: 'Life Storage'
  },
  {
    Ticker: 'LTC',
    Company: 'Ltc Properties'
  },
  {
    Ticker: 'LTHM',
    Company: 'Livent Corp'
  },
  {
    Ticker: 'LTM',
    Company: 'Latam Airlines Group S.A.'
  },
  {
    Ticker: 'LUB',
    Company: "Luby's Inc"
  },
  {
    Ticker: 'LUV',
    Company: 'Southwest Airlines Company'
  },
  {
    Ticker: 'LVS',
    Company: 'Las Vegas Sands'
  },
  {
    Ticker: 'LW',
    Company: 'Lamb Weston Holdings Inc'
  },
  {
    Ticker: 'LXFR',
    Company: 'Luxfer Holdings Plc'
  },
  {
    Ticker: 'LXP',
    Company: 'Lexington Realty Trust'
  },
  {
    Ticker: 'LXP-C',
    Company: 'Lexington Realty Trust [Lxp/Pc]'
  },
  {
    Ticker: 'LXU',
    Company: 'Lsb Industries Inc'
  },
  {
    Ticker: 'LYB',
    Company: 'Lyondellbasell Industries NV'
  },
  {
    Ticker: 'LYG',
    Company: 'Lloyds Banking Group Plc'
  },
  {
    Ticker: 'LYV',
    Company: 'Live Nation Entertainment'
  },
  {
    Ticker: 'LZB',
    Company: 'La-Z-Boy Inc'
  },
  {
    Ticker: 'M',
    Company: "Macy's Inc"
  },
  {
    Ticker: 'MA',
    Company: 'Mastercard Inc'
  },
  {
    Ticker: 'MAA',
    Company: 'Mid-America Apartment Communities'
  },
  {
    Ticker: 'MAA-I',
    Company: 'Mid Amer Apt Cmntys Inc [Maa/Pi]'
  },
  {
    Ticker: 'MAC',
    Company: 'Macerich Company'
  },
  {
    Ticker: 'MAIN',
    Company: 'Main Street Capital Corp'
  },
  {
    Ticker: 'MAN',
    Company: 'Manpower Inc'
  },
  {
    Ticker: 'MANU',
    Company: 'Manchester United Ltd'
  },
  {
    Ticker: 'MAS',
    Company: 'Masco Corp'
  },
  {
    Ticker: 'MATX',
    Company: 'Matson Inc'
  },
  {
    Ticker: 'MAV',
    Company: 'Pioneer Muni High Income Advantage Trust'
  },
  {
    Ticker: 'MAXR',
    Company: 'Maxar Technologies Ltd'
  },
  {
    Ticker: 'MBI',
    Company: 'MBIA Inc'
  },
  {
    Ticker: 'MBT',
    Company: 'Mobile Telesystems'
  },
  {
    Ticker: 'MC',
    Company: 'Moelis'
  },
  {
    Ticker: 'MCA',
    Company: 'Blackrock Muniyield California Insured Fund'
  },
  {
    Ticker: 'MCB',
    Company: 'Metropolitan Bank Holding Corp'
  },
  {
    Ticker: 'MCC',
    Company: 'Medley Capital Corp'
  },
  {
    Ticker: 'MCD',
    Company: "McDonald's Corp"
  },
  {
    Ticker: 'MCI',
    Company: 'Massmutual Corporate Investors'
  },
  {
    Ticker: 'MCK',
    Company: 'Mckesson Corp'
  },
  {
    Ticker: 'MCN',
    Company: 'Madison/Claymore Covered Call & Equity Strategy'
  },
  {
    Ticker: 'MCO',
    Company: "Moody's Corp"
  },
  {
    Ticker: 'MCR',
    Company: 'MFS Charter Income Trust'
  },
  {
    Ticker: 'MCRN',
    Company: 'Milacron Holdings Corp'
  },
  {
    Ticker: 'MCS',
    Company: 'Marcus Corp'
  },
  {
    Ticker: 'MCV',
    Company: 'Medley Capital Corp'
  },
  {
    Ticker: 'MCX',
    Company: 'Medley Capital Corp'
  },
  {
    Ticker: 'MCY',
    Company: 'Mercury General Corp'
  },
  {
    Ticker: 'MD',
    Company: 'Mednax Inc'
  },
  {
    Ticker: 'MDC',
    Company: 'M.D.C. Holdings'
  },
  {
    Ticker: 'MDLA',
    Company: 'Medallia Inc'
  },
  {
    Ticker: 'MDLQ',
    Company: 'Medley Llc 7.25% Notes Due 2024'
  },
  {
    Ticker: 'MDLX',
    Company: 'Medley Llc'
  },
  {
    Ticker: 'MDLY',
    Company: 'Medley Management Inc'
  },
  {
    Ticker: 'MDP',
    Company: 'Meredith Corp'
  },
  {
    Ticker: 'MDR',
    Company: 'McDermott International'
  },
  {
    Ticker: 'MDT',
    Company: 'Medtronic Inc'
  },
  {
    Ticker: 'MDU',
    Company: 'Mdu Res Group Inc'
  },
  {
    Ticker: 'MEC',
    Company: 'Mayville Engineering Company Inc'
  },
  {
    Ticker: 'MED',
    Company: 'Medifast Inc'
  },
  {
    Ticker: 'MEI',
    Company: 'Methode Electronics'
  },
  {
    Ticker: 'MEN',
    Company: 'Blackrock Munienhanced Fund'
  },
  {
    Ticker: 'MER-K',
    Company: 'Bank of America Corp [Mer/Pk]'
  },
  {
    Ticker: 'MET',
    Company: 'Metlife Inc'
  },
  {
    Ticker: 'MET-A',
    Company: 'Metlife Inc [Met/Pa]'
  },
  {
    Ticker: 'MET-E',
    Company: 'Metlife Inc [Met/Pe]'
  },
  {
    Ticker: 'MFA',
    Company: 'MFA Financial Inc'
  },
  {
    Ticker: 'MFA-B',
    Company: 'MFA Finl Inc [Mfa/Pb]'
  },
  {
    Ticker: 'MFA.U',
    Company: 'Megalith Finl Acquisition Corp [Mfac.U]'
  },
  {
    Ticker: 'MFA.W',
    Company: 'Megalith Finl Acquisition Corp WT [Mfac/W]'
  },
  {
    Ticker: 'MFAC',
    Company: 'Megalith Financial Acquisition Corp Cl A'
  },
  {
    Ticker: 'MFC',
    Company: 'Manulife Financial Corp'
  },
  {
    Ticker: 'MFD',
    Company: 'Macquarie/First Trust Global'
  },
  {
    Ticker: 'MFG',
    Company: 'Mizuho Financial Group'
  },
  {
    Ticker: 'MFGP',
    Company: 'Micro Focus Intl Plc'
  },
  {
    Ticker: 'MFL',
    Company: 'Blackrock Muniholdings Investment Quality Fund'
  },
  {
    Ticker: 'MFM',
    Company: 'MFS Muni Income Trust'
  },
  {
    Ticker: 'MFO',
    Company: 'MFA Financial Inc'
  },
  {
    Ticker: 'MFT',
    Company: 'Blackrock Muniyield Investment Qualityfund'
  },
  {
    Ticker: 'MFV',
    Company: 'MFS Special Value Trust'
  },
  {
    Ticker: 'MG',
    Company: 'Mistras Group Inc'
  },
  {
    Ticker: 'MGA',
    Company: 'Magna International'
  },
  {
    Ticker: 'MGF',
    Company: 'MFS Government Markets Income Trust'
  },
  {
    Ticker: 'MGM',
    Company: 'MGM Resorts International'
  },
  {
    Ticker: 'MGP',
    Company: 'MGM Growth Properties Llc'
  },
  {
    Ticker: 'MGR',
    Company: 'Affiliated Managers Group Inc'
  },
  {
    Ticker: 'MGU',
    Company: 'Macquarie Global Infrastructure Total Return Fun'
  },
  {
    Ticker: 'MGY',
    Company: 'Magnolia Oil & Gas Corp'
  },
  {
    Ticker: 'MH-A',
    Company: 'Maiden Holdings Ltd [Mh/Pa]'
  },
  {
    Ticker: 'MH-C',
    Company: 'Maiden Holdings Ltd [Mh/Pc]'
  },
  {
    Ticker: 'MH-D',
    Company: 'Maiden Holdings Ltd [Mh/Pd]'
  },
  {
    Ticker: 'MHD',
    Company: 'Blackrock Muniholdings Fund'
  },
  {
    Ticker: 'MHE',
    Company: 'Massachusetts Health and Education Tax-Exempt TR'
  },
  {
    Ticker: 'MHF',
    Company: 'Western Asset Muni High'
  },
  {
    Ticker: 'MHI',
    Company: 'Pioneer Muni High Income Trust'
  },
  {
    Ticker: 'MHK',
    Company: 'Mohawk Industries'
  },
  {
    Ticker: 'MHLA',
    Company: 'Maiden Holdings Ltd 6.625% No'
  },
  {
    Ticker: 'MHN',
    Company: 'Blackrock Muniholdings New York Quality Fund'
  },
  {
    Ticker: 'MHNC',
    Company: 'Maiden Holdings North America'
  },
  {
    Ticker: 'MHO',
    Company: 'M/I Homes'
  },
  {
    Ticker: 'MIC',
    Company: 'Macquarie Infrastructure Company Trust'
  },
  {
    Ticker: 'MIE',
    Company: 'Cohen & Steers MLP Income and'
  },
  {
    Ticker: 'MIN',
    Company: 'MFS Intermediate Income Trust'
  },
  {
    Ticker: 'MIT-A',
    Company: 'Ag Mtg Invt TR Inc [Mitt/Pa]'
  },
  {
    Ticker: 'MIT-B',
    Company: 'Ag Mtg Invt TR Inc [Mitt/Pb]'
  },
  {
    Ticker: 'MIT-C',
    Company: 'Ag Mtg Invt TR Inc [Mitt/Pc]'
  },
  {
    Ticker: 'MITT',
    Company: 'Ag Mortgage Investment Trust'
  },
  {
    Ticker: 'MIXT',
    Company: 'Mix Telematics Ltd'
  },
  {
    Ticker: 'MIY',
    Company: 'Blackrock Muniyield Michigan Quality Fund'
  },
  {
    Ticker: 'MKC',
    Company: 'Mccormick & Company Inc'
  },
  {
    Ticker: 'MKC.V',
    Company: 'Mccormick & Co. Inc'
  },
  {
    Ticker: 'MKL',
    Company: 'Markel Corp'
  },
  {
    Ticker: 'MLI',
    Company: 'Mueller Industries'
  },
  {
    Ticker: 'MLM',
    Company: 'Martin Marietta Materials'
  },
  {
    Ticker: 'MLP',
    Company: 'Maui Land & Pineapple Company'
  },
  {
    Ticker: 'MLR',
    Company: 'Miller Industries'
  },
  {
    Ticker: 'MMC',
    Company: 'Marsh & Mclennan Companies'
  },
  {
    Ticker: 'MMD',
    Company: 'Mainstay Defined Muni Opp Fund'
  },
  {
    Ticker: 'MMI',
    Company: 'Marcus & Millichap'
  },
  {
    Ticker: 'MMM',
    Company: '3M Company'
  },
  {
    Ticker: 'MMP',
    Company: 'Magellan Midstream Partners LP'
  },
  {
    Ticker: 'MMS',
    Company: 'Maximus Inc'
  },
  {
    Ticker: 'MMT',
    Company: 'MFS Multimarket Income Trust'
  },
  {
    Ticker: 'MMU',
    Company: 'Western Asset Managed Municipals Fund'
  },
  {
    Ticker: 'MN',
    Company: 'Manning & Napier Inc'
  },
  {
    Ticker: 'MNE',
    Company: 'Blackrock Muni New York Intermediate Duration Fu'
  },
  {
    Ticker: 'MNK',
    Company: 'Mallinckrodt Plc'
  },
  {
    Ticker: 'MNP',
    Company: 'Western Asset Muni Partners Fund'
  },
  {
    Ticker: 'MNR',
    Company: 'Monmouth Real Estate Investment Corp'
  },
  {
    Ticker: 'MNR-C',
    Company: 'Monmouth Real Estate Invt Corp [Mnr/Pc]'
  },
  {
    Ticker: 'MNRL',
    Company: 'Brigham Minerals Inc'
  },
  {
    Ticker: 'MO',
    Company: 'Altria Group'
  },
  {
    Ticker: 'MOD',
    Company: 'Modine Manufacturing Company'
  },
  {
    Ticker: 'MODN',
    Company: 'Model N Inc'
  },
  {
    Ticker: 'MOG.A',
    Company: 'Moog Inc Cl A'
  },
  {
    Ticker: 'MOG.B',
    Company: 'Moog Inc Cl B'
  },
  {
    Ticker: 'MOGU',
    Company: 'Mogu Inc ADR'
  },
  {
    Ticker: 'MOH',
    Company: 'Molina Healthcare Inc'
  },
  {
    Ticker: 'MOS',
    Company: 'Mosaic Company'
  },
  {
    Ticker: 'MOS.U',
    Company: 'Mosaic Acquisition Corp [Mosc.U]'
  },
  {
    Ticker: 'MOS.W',
    Company: 'Mosaic Acquisition Corp WT [Mosc/W]'
  },
  {
    Ticker: 'MOSC',
    Company: 'Mosaic Acquisition Corp'
  },
  {
    Ticker: 'MOV',
    Company: 'Movado Group Inc'
  },
  {
    Ticker: 'MPA',
    Company: 'Blackrock Muniyield Pennsylvania Quality Fund'
  },
  {
    Ticker: 'MPC',
    Company: 'Marathon Petroleum Corp'
  },
  {
    Ticker: 'MPLX',
    Company: 'Mplx LP'
  },
  {
    Ticker: 'MPV',
    Company: 'Massmutual Participation Investors'
  },
  {
    Ticker: 'MPW',
    Company: 'Medical Properties Trust'
  },
  {
    Ticker: 'MPX',
    Company: 'Marine Products Corp'
  },
  {
    Ticker: 'MQT',
    Company: 'Blackrock Muniyield Quality Fund II'
  },
  {
    Ticker: 'MQY',
    Company: 'Blackrock Muniyield Quality Fund'
  },
  {
    Ticker: 'MR',
    Company: 'Montage Resources Corp'
  },
  {
    Ticker: 'MRC',
    Company: 'Mrc Global Inc'
  },
  {
    Ticker: 'MRK',
    Company: 'Merck & Company'
  },
  {
    Ticker: 'MRO',
    Company: 'Marathon Oil Corp'
  },
  {
    Ticker: 'MS',
    Company: 'Morgan Stanley'
  },
  {
    Ticker: 'MS-A',
    Company: 'Morgan Stanley [Ms/Pa]'
  },
  {
    Ticker: 'MS-E',
    Company: 'Morgan Stanley [Ms/Pe]'
  },
  {
    Ticker: 'MS-F',
    Company: 'Morgan Stanley [Ms/Pf]'
  },
  {
    Ticker: 'MS-G',
    Company: 'Morgan Stanley [Ms/Pg]'
  },
  {
    Ticker: 'MS-I',
    Company: 'Morgan Stanley [Ms/Pi]'
  },
  {
    Ticker: 'MS-K',
    Company: 'Morgan Stanley [Ms/Pk]'
  },
  {
    Ticker: 'MS-L',
    Company: 'Morgan Stanley 4.875% Prf Perpetual USD 25 1/100'
  },
  {
    Ticker: 'MSA',
    Company: 'Msa Safety Inc'
  },
  {
    Ticker: 'MSB',
    Company: 'Mesabi Trust'
  },
  {
    Ticker: 'MSC',
    Company: 'Studio City Intl Holdings Ltd ADR'
  },
  {
    Ticker: 'MSCI',
    Company: 'MSCI Inc'
  },
  {
    Ticker: 'MSD',
    Company: 'Morgan Stanley Emerging Markets Debt'
  },
  {
    Ticker: 'MSG',
    Company: 'The Madison Square Garden Comp'
  },
  {
    Ticker: 'MSGN',
    Company: 'Msg Networks Inc'
  },
  {
    Ticker: 'MSI',
    Company: 'Motorola Solutions'
  },
  {
    Ticker: 'MSM',
    Company: 'Msc Industrial Direct Company'
  },
  {
    Ticker: 'MT',
    Company: 'Arcelormittal'
  },
  {
    Ticker: 'MTB',
    Company: 'M&T Bank Corp'
  },
  {
    Ticker: 'MTD',
    Company: 'Mettler-Toledo International'
  },
  {
    Ticker: 'MTDR',
    Company: 'Matador Resources Company'
  },
  {
    Ticker: 'MTG',
    Company: 'Mgic Investment Corp'
  },
  {
    Ticker: 'MTH',
    Company: 'Meritage Corp'
  },
  {
    Ticker: 'MTL',
    Company: 'Mechel Oao'
  },
  {
    Ticker: 'MTL.P',
    Company: 'Mechel Pao [Mtl/P]'
  },
  {
    Ticker: 'MTN',
    Company: 'Vail Resorts'
  },
  {
    Ticker: 'MTOR',
    Company: 'Meritor Inc'
  },
  {
    Ticker: 'MTR',
    Company: 'Mesa Royalty Trust'
  },
  {
    Ticker: 'MTRN',
    Company: 'Materion Corp'
  },
  {
    Ticker: 'MTT',
    Company: 'Western Asset Muni Defined Opportunity Trust Inc'
  },
  {
    Ticker: 'MTW',
    Company: 'Manitowoc Company'
  },
  {
    Ticker: 'MTX',
    Company: 'Minerals Technologies Inc'
  },
  {
    Ticker: 'MTZ',
    Company: 'Mastec Inc'
  },
  {
    Ticker: 'MUA',
    Company: 'Blackrock Muniassets Fund'
  },
  {
    Ticker: 'MUC',
    Company: 'Blackrock Muniholdings California Quality Fund'
  },
  {
    Ticker: 'MUE',
    Company: 'Blackrock Muniholdings Quality Fund II'
  },
  {
    Ticker: 'MUFG',
    Company: 'Mitsubishi Ufj Financial Group Inc'
  },
  {
    Ticker: 'MUH',
    Company: 'Blackrock Muniholdings Fund II'
  },
  {
    Ticker: 'MUI',
    Company: 'Blackrock Muni Intermediate Duration Fund Inc'
  },
  {
    Ticker: 'MUJ',
    Company: 'Blackrock Muniholdings New Jersey Insured Fund'
  },
  {
    Ticker: 'MUR',
    Company: 'Murphy Oil Corp'
  },
  {
    Ticker: 'MUS',
    Company: 'Blackrock Muniholdings Quality Fund'
  },
  {
    Ticker: 'MUSA',
    Company: 'Murphy USA Inc'
  },
  {
    Ticker: 'MUX',
    Company: 'Mcewen Mining Inc'
  },
  {
    Ticker: 'MVC',
    Company: 'MVC Capital'
  },
  {
    Ticker: 'MVCD',
    Company: 'MVC Capital Inc 6.25% Senior Notes Due 2022'
  },
  {
    Ticker: 'MVF',
    Company: 'Munivest Fund'
  },
  {
    Ticker: 'MVO',
    Company: 'Mv Oil Trust'
  },
  {
    Ticker: 'MVT',
    Company: 'Blackrock Munivest Fund II'
  },
  {
    Ticker: 'MWA',
    Company: 'Mueller Water Products'
  },
  {
    Ticker: 'MX',
    Company: 'Magnachip Semiconductor Corp'
  },
  {
    Ticker: 'MXE',
    Company: 'Mexico Equity and'
  },
  {
    Ticker: 'MXF',
    Company: 'Mexico Fund'
  },
  {
    Ticker: 'MXL',
    Company: 'Maxlinear Inc'
  },
  {
    Ticker: 'MYC',
    Company: 'Blackrock Muniyield California Fund'
  },
  {
    Ticker: 'MYD',
    Company: 'Blackrock Muniyield Fund'
  },
  {
    Ticker: 'MYE',
    Company: 'Myers Industries'
  },
  {
    Ticker: 'MYF',
    Company: 'Blackrock Muniyield Investment Fund'
  },
  {
    Ticker: 'MYI',
    Company: 'Blackrock Muniyield Quality Fund III'
  },
  {
    Ticker: 'MYJ',
    Company: 'Blackrock Muniyield New Jersey Fund'
  },
  {
    Ticker: 'MYN',
    Company: 'Blackrock Muniyield New York Quality Fund'
  },
  {
    Ticker: 'MYOV',
    Company: 'Myovant Sciences Ltd'
  },
  {
    Ticker: 'MZA',
    Company: 'Muniyield Arizona Fund'
  },
  {
    Ticker: 'NAC',
    Company: 'Nuveen California Divadv Fund'
  },
  {
    Ticker: 'NAD',
    Company: 'Nuveen Divadv Fund'
  },
  {
    Ticker: 'NAN',
    Company: 'Nuveen New York Divadv Fund'
  },
  {
    Ticker: 'NAT',
    Company: 'Nordic American Tanker Shipping Ltd'
  },
  {
    Ticker: 'NAV',
    Company: 'Navistar International Corp'
  },
  {
    Ticker: 'NAV-D',
    Company: 'Navistar International Corp [Nav/Pd]'
  },
  {
    Ticker: 'NAZ',
    Company: 'Nuveen Arizona Premium Fund'
  },
  {
    Ticker: 'NBB',
    Company: 'Nuveen Build America Bond Fund'
  },
  {
    Ticker: 'NBHC',
    Company: 'National Bank Holdings Corp'
  },
  {
    Ticker: 'NBL',
    Company: 'Noble Energy Inc'
  },
  {
    Ticker: 'NBLX',
    Company: 'Noble Midstream Partners LP'
  },
  {
    Ticker: 'NBR',
    Company: 'Nabors Industries'
  },
  {
    Ticker: 'NBR-A',
    Company: 'Nabors Industries Inc [Nbr/Pa]'
  },
  {
    Ticker: 'NC',
    Company: 'Nacco Industries'
  },
  {
    Ticker: 'NCA',
    Company: 'Nuveen California Muni Value Fund'
  },
  {
    Ticker: 'NCB',
    Company: 'Nuveen California Muni Value Fund 2'
  },
  {
    Ticker: 'NCLH',
    Company: 'Norwegian Cruise Ord'
  },
  {
    Ticker: 'NCR',
    Company: 'NCR Corp'
  },
  {
    Ticker: 'NCV',
    Company: 'Agic Convertible &'
  },
  {
    Ticker: 'NCV-A',
    Company: 'Allianzgi Conv Income Fd [Ncv/Pa]'
  },
  {
    Ticker: 'NCZ',
    Company: 'Agic Convertible & II'
  },
  {
    Ticker: 'NCZ-A',
    Company: 'Allianzgi Conv Income Fd II [Ncz/Pa]'
  },
  {
    Ticker: 'NDP',
    Company: 'Tortoise Energy Independence F'
  },
  {
    Ticker: 'NE',
    Company: 'Noble Corp'
  },
  {
    Ticker: 'NEA',
    Company: 'Nuveen Insured Tax-Free Advantage Fund'
  },
  {
    Ticker: 'NEE',
    Company: 'Nextera Energy'
  },
  {
    Ticker: 'NEE-I',
    Company: 'Nextera Energy Capital Hlds Inc [Nee/Pi]'
  },
  {
    Ticker: 'NEE-J',
    Company: 'Nextera Energy Capital Hlds Inc [Nee/Pj]'
  },
  {
    Ticker: 'NEE-K',
    Company: 'Nextera Energy Capital Hlds Inc [Nee/Pk]'
  },
  {
    Ticker: 'NEE-N',
    Company: 'Nextera Energy Capital Hlds Inc [Nee/Pn]'
  },
  {
    Ticker: 'NEE-O',
    Company: 'Nextera Energy Inc 4.872% Corporate Units'
  },
  {
    Ticker: 'NEM',
    Company: 'Newmont Mining Corp'
  },
  {
    Ticker: 'NEP',
    Company: 'Nextera Energy Partners LP'
  },
  {
    Ticker: 'NET',
    Company: 'Cloudflare Inc Cl A'
  },
  {
    Ticker: 'NEU',
    Company: 'Newmarket Corp'
  },
  {
    Ticker: 'NEV',
    Company: 'Nuveen Enhanced Muni Value Fund'
  },
  {
    Ticker: 'NEW',
    Company: 'Puxin Ltd'
  },
  {
    Ticker: 'NEWM',
    Company: 'New Media Investment Group Inc'
  },
  {
    Ticker: 'NEWR',
    Company: 'New Relic Inc'
  },
  {
    Ticker: 'NEX',
    Company: 'Nextier Oilfield Solutions Inc.'
  },
  {
    Ticker: 'NEXA',
    Company: 'Nexa Resources S.A.'
  },
  {
    Ticker: 'NFC',
    Company: 'New Frontier Corp'
  },
  {
    Ticker: 'NFC.U',
    Company: 'New Frontier Corp'
  },
  {
    Ticker: 'NFC.W',
    Company: 'New Frontier Corp WT [Nfc/W]'
  },
  {
    Ticker: 'NFG',
    Company: 'National Fuel Gas Company'
  },
  {
    Ticker: 'NFJ',
    Company: 'Nfj Dividend Interest & Premium Strategy Fund'
  },
  {
    Ticker: 'NGG',
    Company: 'National Grid Transco Plc'
  },
  {
    Ticker: 'NGL',
    Company: 'Ngl Energy Partners LP'
  },
  {
    Ticker: 'NGL-A',
    Company: 'Targa Resources Partners LP [Ngls/Pa]'
  },
  {
    Ticker: 'NGL-B',
    Company: 'Ngl Energy Partners LP [Ngl/Pb]'
  },
  {
    Ticker: 'NGL-C',
    Company: 'Ngl Energy Partners LP Perp Pfd Unit Cl C Fixed'
  },
  {
    Ticker: 'NGS',
    Company: 'Natural Gas Services Group'
  },
  {
    Ticker: 'NGVC',
    Company: 'Natural Grocers By Vitamin Cottage Inc'
  },
  {
    Ticker: 'NGVT',
    Company: 'Ingevity Corp'
  },
  {
    Ticker: 'NHA',
    Company: 'Nuveen Municipal'
  },
  {
    Ticker: 'NHF',
    Company: 'Nexpoint Credit Strategies Fund'
  },
  {
    Ticker: 'NHI',
    Company: 'National Health Investors'
  },
  {
    Ticker: 'NI',
    Company: 'NiSource Inc'
  },
  {
    Ticker: 'NI-B',
    Company: 'NiSource Inc [Ni/Pb]'
  },
  {
    Ticker: 'NID',
    Company: 'Nuveen Intermediate Duration M'
  },
  {
    Ticker: 'NIE',
    Company: 'Agic Equity & Convertible'
  },
  {
    Ticker: 'NIM',
    Company: 'Nuveen Maturities Fund'
  },
  {
    Ticker: 'NINE',
    Company: 'Nine Energy Service Inc'
  },
  {
    Ticker: 'NIO',
    Company: 'Nio Inc'
  },
  {
    Ticker: 'NIQ',
    Company: 'Nuveen Intermediate Duration Q'
  },
  {
    Ticker: 'NJR',
    Company: 'Newjersey Resources Corp'
  },
  {
    Ticker: 'NJV',
    Company: 'Nuveen New Jersey Muni Value Fund'
  },
  {
    Ticker: 'NKE',
    Company: 'Nike Inc'
  },
  {
    Ticker: 'NKG',
    Company: 'Nuveen Georgia Divadv Fund 2'
  },
  {
    Ticker: 'NKX',
    Company: 'Nuveen Insured California Tax-Free'
  },
  {
    Ticker: 'NL',
    Company: 'NL Industries'
  },
  {
    Ticker: 'NLS',
    Company: 'Nautilus Group'
  },
  {
    Ticker: 'NLSN',
    Company: 'Nielsen Holdings Plc'
  },
  {
    Ticker: 'NLY',
    Company: 'Annaly Capital Management Inc'
  },
  {
    Ticker: 'NLY-D',
    Company: 'Annaly Cap Mgmt Inc [Nly/Pd]'
  },
  {
    Ticker: 'NLY-F',
    Company: 'Annaly Cap Mgmt Inc [Nly/Pf]'
  },
  {
    Ticker: 'NLY-G',
    Company: 'Annaly Cap Mgmt Inc [Nly/Pg]'
  },
  {
    Ticker: 'NLY-I',
    Company: 'Annaly Cap Mgmt Inc [Nly/Pi]'
  },
  {
    Ticker: 'NM',
    Company: 'Navios Maritime Holdings Inc'
  },
  {
    Ticker: 'NM-G',
    Company: 'Navios Maritime Hlds Inc [Nm/Pg]'
  },
  {
    Ticker: 'NM-H',
    Company: 'Navios Maritime Hlds Inc [Nm/Ph]'
  },
  {
    Ticker: 'NMCO',
    Company: 'Nuveen Municipal Credit Opportunities Fund'
  },
  {
    Ticker: 'NMFC',
    Company: 'New Mountain Finance Corp'
  },
  {
    Ticker: 'NMFX',
    Company: 'New Mountain Finance Corp'
  },
  {
    Ticker: 'NMI',
    Company: 'Nuveen Muni'
  },
  {
    Ticker: 'NMK-B',
    Company: 'Niagara Mohawk Power Corp [Nmk/Pb]'
  },
  {
    Ticker: 'NMK-C',
    Company: 'Niagara Mohawk Power Corp [Nmk/Pc]'
  },
  {
    Ticker: 'NMM',
    Company: 'Navios Maritime Partners LP'
  },
  {
    Ticker: 'NMR',
    Company: 'Nomura Holdings Inc ADR'
  },
  {
    Ticker: 'NMS',
    Company: 'Nuveen Minnesota Municipal Inco'
  },
  {
    Ticker: 'NMT',
    Company: 'Nuveen Massachusetts Premium Fund'
  },
  {
    Ticker: 'NMY',
    Company: 'Nuveen Maryland Premium Fund'
  },
  {
    Ticker: 'NMZ',
    Company: 'Nuveen Muni High Income Opportunity'
  },
  {
    Ticker: 'NNA',
    Company: 'Navios Maritime Acquisition Corp'
  },
  {
    Ticker: 'NNC',
    Company: 'Nuveen North Carolina Premium Fund'
  },
  {
    Ticker: 'NNI',
    Company: 'Nelnet Inc'
  },
  {
    Ticker: 'NNN',
    Company: 'National Retail Properties'
  },
  {
    Ticker: 'NNN-F',
    Company: 'National Retail Pptys Inc [NNn/Pf]'
  },
  {
    Ticker: 'NNY',
    Company: 'Nuveen New York Muni Value Fund'
  },
  {
    Ticker: 'NOA',
    Company: 'North American Energy Partners'
  },
  {
    Ticker: 'NOAH',
    Company: 'Noah Holdings Ltd'
  },
  {
    Ticker: 'NOC',
    Company: 'Northrop Grumman Corp'
  },
  {
    Ticker: 'NOK',
    Company: 'Nokia Corp'
  },
  {
    Ticker: 'NOM',
    Company: 'Nuveen Missouri Premium Fund'
  },
  {
    Ticker: 'NOMD',
    Company: 'Nomad Foods Ltd'
  },
  {
    Ticker: 'NOV',
    Company: 'National-Oilwell'
  },
  {
    Ticker: 'NOVA',
    Company: 'Sunnova Energy International Inc'
  },
  {
    Ticker: 'NOW',
    Company: 'Servicenow Inc'
  },
  {
    Ticker: 'NP',
    Company: 'Neenah Paper'
  },
  {
    Ticker: 'NPK',
    Company: 'National Presto Industries'
  },
  {
    Ticker: 'NPN',
    Company: 'Nuveen Pennsylvania Muni Value Fund'
  },
  {
    Ticker: 'NPO',
    Company: 'Enpro Industries'
  },
  {
    Ticker: 'NPTN',
    Company: 'Neophotonics Corp'
  },
  {
    Ticker: 'NPV',
    Company: 'Nuveen Virginia Premium Fund'
  },
  {
    Ticker: 'NQP',
    Company: 'Nuveen Pennsylvania Investment'
  },
  {
    Ticker: 'NR',
    Company: 'Newpark Resources'
  },
  {
    Ticker: 'NRG',
    Company: 'NRG Energy'
  },
  {
    Ticker: 'NRGX',
    Company: 'Neurologix Inc'
  },
  {
    Ticker: 'NRK',
    Company: 'Nuveen Insured New York Tax-Free'
  },
  {
    Ticker: 'NRP',
    Company: 'Natural Resource Partners LP'
  },
  {
    Ticker: 'NRT',
    Company: 'North European Oil Royality Trust'
  },
  {
    Ticker: 'NRUC',
    Company: 'National Rural Utilities Coop Finance Corp 5.500'
  },
  {
    Ticker: 'NRZ',
    Company: 'New Residential Investment Corp'
  },
  {
    Ticker: 'NRZ-A',
    Company: 'New Residential Invt Corp [Nrz/Pa]'
  },
  {
    Ticker: 'NRZ-B',
    Company: 'New Residential Invt Corp [Nrz/Pb]'
  },
  {
    Ticker: 'NS',
    Company: 'Nustar Energy LP'
  },
  {
    Ticker: 'NS-A',
    Company: 'Nustar Energy LP [Ns/Pa]'
  },
  {
    Ticker: 'NS-B',
    Company: 'Nustar Energy LP [Ns/Pb]'
  },
  {
    Ticker: 'NS-C',
    Company: 'Nustar Energy LP [Ns/Pc]'
  },
  {
    Ticker: 'NSA',
    Company: 'National Storage Affiliates Tru'
  },
  {
    Ticker: 'NSA-A',
    Company: 'National Storage Affiliates TR [Nsa/Pa]'
  },
  {
    Ticker: 'NSC',
    Company: 'Norfolk Southern Corp'
  },
  {
    Ticker: 'NSC.W',
    Company: 'Nesco Hlds Inc WT [Nsco/W]'
  },
  {
    Ticker: 'NSCO',
    Company: 'Nesco Holdings Inc'
  },
  {
    Ticker: 'NSL',
    Company: 'Nuveen Senior'
  },
  {
    Ticker: 'NSP',
    Company: 'Insperity Inc'
  },
  {
    Ticker: 'NSS',
    Company: 'Nustar Logistics LP'
  },
  {
    Ticker: 'NTB',
    Company: 'Bank of Butterfield Ltd'
  },
  {
    Ticker: 'NTC',
    Company: 'Nuveen Connecticut Premium Fund'
  },
  {
    Ticker: 'NTEST.I',
    Company: ''
  },
  {
    Ticker: 'NTEST.J',
    Company: ''
  },
  {
    Ticker: 'NTEST.K',
    Company: ''
  },
  {
    Ticker: 'NTG',
    Company: 'Tortoise MLP Fund'
  },
  {
    Ticker: 'NTP',
    Company: 'Nam Tai Electronics'
  },
  {
    Ticker: 'NTR',
    Company: 'Nutrien Ltd'
  },
  {
    Ticker: 'NTX',
    Company: 'Nuveen Texas Quality'
  },
  {
    Ticker: 'NTZ',
    Company: 'Natuzzi S.P.A.'
  },
  {
    Ticker: 'NUE',
    Company: 'Nucor Corp'
  },
  {
    Ticker: 'NUM',
    Company: 'Nuveen Michigan Quality'
  },
  {
    Ticker: 'NUO',
    Company: 'Nuveen Ohio Quality'
  },
  {
    Ticker: 'NUS',
    Company: 'Nu Skin Enterprises'
  },
  {
    Ticker: 'NUV',
    Company: 'Nuveen Muni Value Fund'
  },
  {
    Ticker: 'NUW',
    Company: 'Nuveen Muni Value Fund'
  },
  {
    Ticker: 'NVG',
    Company: 'Nuveen Insured Divadv Fund'
  },
  {
    Ticker: 'NVGS',
    Company: 'Navigator Holdings'
  },
  {
    Ticker: 'NVO',
    Company: 'Novo Nordisk A/S'
  },
  {
    Ticker: 'NVR',
    Company: 'NVR Inc'
  },
  {
    Ticker: 'NVRO',
    Company: 'Nevro Corp'
  },
  {
    Ticker: 'NVS',
    Company: 'Novartis Ag'
  },
  {
    Ticker: 'NVST',
    Company: 'Envista Holdings Corp'
  },
  {
    Ticker: 'NVT',
    Company: 'Nvent Electric Plc'
  },
  {
    Ticker: 'NVTA',
    Company: 'Invitae Corp'
  },
  {
    Ticker: 'NWE',
    Company: 'Northwestern Corp'
  },
  {
    Ticker: 'NWHM',
    Company: 'New Home Co. Llc'
  },
  {
    Ticker: 'NWN',
    Company: 'Northwest Natural Gas Company'
  },
  {
    Ticker: 'NX',
    Company: 'Quanex Building Products Corp'
  },
  {
    Ticker: 'NXC',
    Company: 'Nuveen California Tax-Free Income'
  },
  {
    Ticker: 'NXJ',
    Company: 'Nuveen New Jersey Divadv Fund'
  },
  {
    Ticker: 'NXN',
    Company: 'Nuveen Insured New York Tax-Free'
  },
  {
    Ticker: 'NXP',
    Company: 'Nuveen Tax Free Income Portfolio'
  },
  {
    Ticker: 'NXQ',
    Company: 'Nuveen Tax Free Income Portfolio II'
  },
  {
    Ticker: 'NXR',
    Company: 'Nuveen Tax Free Income Portfolio III'
  },
  {
    Ticker: 'NXRT',
    Company: 'Nexpoint Residential Trust Inc'
  },
  {
    Ticker: 'NYC-A',
    Company: 'New York Community Bancorp Inc [Nycb/Pa]'
  },
  {
    Ticker: 'NYC-U',
    Company: 'New York Cmnty Cap TR V [Nycb/Pu]'
  },
  {
    Ticker: 'NYCB',
    Company: 'New York Community Bancorp'
  },
  {
    Ticker: 'NYT',
    Company: 'New York Times Company'
  },
  {
    Ticker: 'NYV',
    Company: 'Nuveen New York Muni Value Fund 2'
  },
  {
    Ticker: 'NZF',
    Company: 'Nuveen Divadv Fund 3'
  },
  {
    Ticker: 'O',
    Company: 'Realty Income Corp'
  },
  {
    Ticker: 'OAC',
    Company: 'Oaktree Acquisition Corp Cl A'
  },
  {
    Ticker: 'OAC.U',
    Company: 'Oaktree Acquisition Corp'
  },
  {
    Ticker: 'OAC.W',
    Company: 'Oaktree Acquisition Corp WT'
  },
  {
    Ticker: 'OAK-A',
    Company: 'Oaktree Cap Group Llc [Oak/Pa]'
  },
  {
    Ticker: 'OAK-B',
    Company: 'Oaktree Cap Group Llc [Oak/Pb]'
  },
  {
    Ticker: 'OAS',
    Company: 'Oasis Petroleum Inc'
  },
  {
    Ticker: 'OBE',
    Company: 'Obsidian Energy Ltd'
  },
  {
    Ticker: 'OC',
    Company: 'Owens Corning Inc'
  },
  {
    Ticker: 'OCN',
    Company: 'Ocwen Financial Corp'
  },
  {
    Ticker: 'ODC',
    Company: 'Oil-Dri Corp of America'
  },
  {
    Ticker: 'OEC',
    Company: 'Orion Engineered Carbons S.A R'
  },
  {
    Ticker: 'OFC',
    Company: 'Corporate Office Properties'
  },
  {
    Ticker: 'OFG',
    Company: 'Oriental Financial Group'
  },
  {
    Ticker: 'OFG-A',
    Company: 'Ofg Bancorp [Ofg/Pa]'
  },
  {
    Ticker: 'OFG-B',
    Company: 'Ofg Bancorp [Ofg/Pb]'
  },
  {
    Ticker: 'OFG-D',
    Company: 'Ofg Bancorp [Ofg/Pd]'
  },
  {
    Ticker: 'OGE',
    Company: 'Oge Energy Corp'
  },
  {
    Ticker: 'OGS',
    Company: 'One Gas Inc'
  },
  {
    Ticker: 'OHI',
    Company: 'Omega Healthcare Investors'
  },
  {
    Ticker: 'OI',
    Company: 'Owens-Illinois'
  },
  {
    Ticker: 'OIA',
    Company: 'Invesco Muni Income Trust'
  },
  {
    Ticker: 'OIB.C',
    Company: 'Oi S.A. [Oibr.C]'
  },
  {
    Ticker: 'OII',
    Company: 'Oceaneering International'
  },
  {
    Ticker: 'OIS',
    Company: 'Oil States International'
  },
  {
    Ticker: 'OKE',
    Company: 'Oneok Inc'
  },
  {
    Ticker: 'OLN',
    Company: 'Olin Corp'
  },
  {
    Ticker: 'OLP',
    Company: 'One Liberty Properties'
  },
  {
    Ticker: 'OMC',
    Company: 'Omnicom Group Inc'
  },
  {
    Ticker: 'OMF',
    Company: 'Onemain Holdings Inc'
  },
  {
    Ticker: 'OMI',
    Company: 'Owens & Minor'
  },
  {
    Ticker: 'OMN',
    Company: 'Omnova Solutions Inc'
  },
  {
    Ticker: 'OMP',
    Company: 'Oasis Midstream Partners LP'
  },
  {
    Ticker: 'ONDK',
    Company: 'On Deck Capital Inc'
  },
  {
    Ticker: 'ONE',
    Company: 'Onesmart International Education Group Ltd A'
  },
  {
    Ticker: 'ONTO',
    Company: 'Onto Innovation Inc.'
  },
  {
    Ticker: 'OOMA',
    Company: 'Ooma Inc'
  },
  {
    Ticker: 'OPP',
    Company: 'Rivernorth/Doubleline Strategic Opportunity Fund'
  },
  {
    Ticker: 'OPY',
    Company: 'Oppenheimer Holdings'
  },
  {
    Ticker: 'OR',
    Company: 'Osisko Gold Royalties Ltd'
  },
  {
    Ticker: 'ORA',
    Company: 'Ormat Technologies'
  },
  {
    Ticker: 'ORAN',
    Company: 'Orange ADR'
  },
  {
    Ticker: 'ORC',
    Company: 'Orchid Island Capital Inc'
  },
  {
    Ticker: 'ORCC',
    Company: 'Owl Rock Capital Corp'
  },
  {
    Ticker: 'ORCL',
    Company: 'Oracle Corp'
  },
  {
    Ticker: 'ORI',
    Company: 'Old Republic International Corp'
  },
  {
    Ticker: 'ORN',
    Company: 'Orion Group Holdings Inc'
  },
  {
    Ticker: 'OSB',
    Company: 'Norbord Inc'
  },
  {
    Ticker: 'OSG',
    Company: 'Overseas Shipholding Group Inc'
  },
  {
    Ticker: 'OSK',
    Company: 'Oshkosh Truck Corp'
  },
  {
    Ticker: 'OSLE',
    Company: 'Oaktree Specialty Lending Corp'
  },
  {
    Ticker: 'OUT',
    Company: 'Outfront Media Inc'
  },
  {
    Ticker: 'OXM',
    Company: 'Oxford Industries'
  },
  {
    Ticker: 'OXY',
    Company: 'Occidental Petroleum Corp'
  },
  {
    Ticker: 'PAA',
    Company: 'Plains All American Pipeline LP'
  },
  {
    Ticker: 'PAC',
    Company: 'Grupo Aeroportuario Del Pacifico'
  },
  {
    Ticker: 'PAC.W',
    Company: 'Ranpak Hlds Corp  Wt[Pack/W]'
  },
  {
    Ticker: 'PACD',
    Company: 'Pacific Drilling S.A.'
  },
  {
    Ticker: 'PACK',
    Company: 'Ranpak Holdings Corp'
  },
  {
    Ticker: 'PAG',
    Company: 'Penske Automotive Group'
  },
  {
    Ticker: 'PAGP',
    Company: 'Plains Gp Holdings LP'
  },
  {
    Ticker: 'PAGS',
    Company: 'Pagseguro Digital Ltd Cl A'
  },
  {
    Ticker: 'PAI',
    Company: 'Pacific American Income Shares'
  },
  {
    Ticker: 'PAM',
    Company: 'Pampa Energia S.A.'
  },
  {
    Ticker: 'PANW',
    Company: 'Palo Alto Networks Inc'
  },
  {
    Ticker: 'PAR',
    Company: 'Par Technology Corp'
  },
  {
    Ticker: 'PARR',
    Company: 'Par Petroleum Corp'
  },
  {
    Ticker: 'PAYC',
    Company: 'Paycom Software Inc'
  },
  {
    Ticker: 'PB',
    Company: 'Prosperity Bancshares'
  },
  {
    Ticker: 'PBA',
    Company: 'Pembina Pipeline Cor'
  },
  {
    Ticker: 'PBB',
    Company: 'Prospect Capital Corp'
  },
  {
    Ticker: 'PBC',
    Company: 'Prospect Capital Corp 6.875% Notes Due 20'
  },
  {
    Ticker: 'PBF',
    Company: 'PBF Energy Inc'
  },
  {
    Ticker: 'PBFX',
    Company: 'PBF Logistics LP'
  },
  {
    Ticker: 'PBH',
    Company: 'Prestige Brand Holdings'
  },
  {
    Ticker: 'PBI',
    Company: 'Pitney Bowes Inc'
  },
  {
    Ticker: 'PBI-B',
    Company: 'Pitney Bowes Inc [Pbi/Pb]'
  },
  {
    Ticker: 'PBR',
    Company: 'Petroleo Brasileiro S.A. Petrobras'
  },
  {
    Ticker: 'PBR.A',
    Company: 'Petroleo Brasileiro Sa Petrobr ADR'
  },
  {
    Ticker: 'PBT',
    Company: 'Permian Basin Royalty Trust'
  },
  {
    Ticker: 'PBY',
    Company: 'Prospect Capital Corp 6.25% Notes Due 202'
  },
  {
    Ticker: 'PCF',
    Company: 'Putnam High Income Bond Fund'
  },
  {
    Ticker: 'PCG',
    Company: 'Pacific Gas & Electric Company'
  },
  {
    Ticker: 'PCI',
    Company: 'Pimco Dynamic Credit Income Fun'
  },
  {
    Ticker: 'PCK',
    Company: 'Pimco California Muni II'
  },
  {
    Ticker: 'PCM',
    Company: 'Pimco Commercial Mortgage Securities'
  },
  {
    Ticker: 'PCN',
    Company: 'Pimco Corporate'
  },
  {
    Ticker: 'PCQ',
    Company: 'Pimco California Muni'
  },
  {
    Ticker: 'PD',
    Company: 'Pagerduty Inc'
  },
  {
    Ticker: 'PDI',
    Company: 'Pimco Dynamic Income Fund'
  },
  {
    Ticker: 'PDM',
    Company: 'Piedmont Office Realty Trust'
  },
  {
    Ticker: 'PDS',
    Company: 'Precision Drilling Corp'
  },
  {
    Ticker: 'PDT',
    Company: 'John Hancock Premium Dividend Fund'
  },
  {
    Ticker: 'PE',
    Company: 'Parsley Energy'
  },
  {
    Ticker: 'PEAK',
    Company: 'Healthpeak Properties Inc.'
  },
  {
    Ticker: 'PEB',
    Company: 'Pebblebrook Hotel Trust'
  },
  {
    Ticker: 'PEB-C',
    Company: 'Pebblebrook Hotel TR [Peb/Pc]'
  },
  {
    Ticker: 'PEB-D',
    Company: 'Pebblebrook Hotel TR [Peb/Pd]'
  },
  {
    Ticker: 'PEB-E',
    Company: 'Pebblebrook Hotel TR [Peb/Pe]'
  },
  {
    Ticker: 'PEB-F',
    Company: 'Pebblebrook Hotel TR [Peb/Pf]'
  },
  {
    Ticker: 'PEG',
    Company: 'Public Service Enterprise Group Inc'
  },
  {
    Ticker: 'PEI',
    Company: 'Pennsylvania Real Estate Investment'
  },
  {
    Ticker: 'PEI-B',
    Company: 'Pennsylvania Rl Estate Invt TR [Pei/Pb]'
  },
  {
    Ticker: 'PEI-C',
    Company: 'Pennsylvania Rl Estate Invt TR [Pei/Pc]'
  },
  {
    Ticker: 'PEI-D',
    Company: 'Pennsylvania Rl Estate Invt TR [Pei/Pd]'
  },
  {
    Ticker: 'PEN',
    Company: 'Penumbra Inc'
  },
  {
    Ticker: 'PEO',
    Company: 'Adams Natural Resources Fund Inc'
  },
  {
    Ticker: 'PER',
    Company: 'Sandridge Permian Trust'
  },
  {
    Ticker: 'PFD',
    Company: 'Flaherty & Crumrine Preferred Inc'
  },
  {
    Ticker: 'PFE',
    Company: 'Pfizer Inc'
  },
  {
    Ticker: 'PFGC',
    Company: 'Performance Food Group Company'
  },
  {
    Ticker: 'PFH',
    Company: 'Cabco TR Jcp 7.625'
  },
  {
    Ticker: 'PFL',
    Company: 'Pimco Income Strategy Fund'
  },
  {
    Ticker: 'PFN',
    Company: 'Pimco Income Strategy Fund II'
  },
  {
    Ticker: 'PFO',
    Company: 'Flaherty & Crumrine Preferred Fund'
  },
  {
    Ticker: 'PFS',
    Company: 'Provident Financial Services'
  },
  {
    Ticker: 'PFSI',
    Company: 'Pennymac Financial Services IN'
  },
  {
    Ticker: 'PG',
    Company: 'Procter & Gamble Company'
  },
  {
    Ticker: 'PGP',
    Company: 'Pimco Global Stocksplus & Income'
  },
  {
    Ticker: 'PGR',
    Company: 'Progressive Corp'
  },
  {
    Ticker: 'PGRE',
    Company: 'Paramount Group Inc'
  },
  {
    Ticker: 'PGTI',
    Company: 'Pgt Inc'
  },
  {
    Ticker: 'PGZ',
    Company: 'Principal Real Estate Income F'
  },
  {
    Ticker: 'PH',
    Company: 'Parker-Hannifin Corp'
  },
  {
    Ticker: 'PHD',
    Company: 'Pioneer Floating Rate Trust'
  },
  {
    Ticker: 'PHG',
    Company: 'Koninklijke Philips Electronics'
  },
  {
    Ticker: 'PHI',
    Company: 'Philippine Long Distance Telephone'
  },
  {
    Ticker: 'PHK',
    Company: 'Pimco High Income Fund'
  },
  {
    Ticker: 'PHM',
    Company: 'Pultegroup'
  },
  {
    Ticker: 'PHR',
    Company: 'Phreesia Inc'
  },
  {
    Ticker: 'PHT',
    Company: 'Pioneer High Income Trust'
  },
  {
    Ticker: 'PHX',
    Company: 'Panhandle Royalty Company'
  },
  {
    Ticker: 'PIC',
    Company: 'Pivotal Investment Corp II Cl A'
  },
  {
    Ticker: 'PIC.U',
    Company: 'Pivotal Invt Corp II'
  },
  {
    Ticker: 'PIC.W',
    Company: 'Pivotal Invt Corp II WT [Pic/W]'
  },
  {
    Ticker: 'PII',
    Company: 'Polaris Industries Inc'
  },
  {
    Ticker: 'PIM',
    Company: 'Putnam Master Intermediate Income'
  },
  {
    Ticker: 'PINE',
    Company: 'Alpine Income Property Trust Inc'
  },
  {
    Ticker: 'PING',
    Company: 'Ping Identity Holding Corp'
  },
  {
    Ticker: 'PINS',
    Company: 'Pinterest Inc'
  },
  {
    Ticker: 'PIR',
    Company: 'Pier 1 Imports'
  },
  {
    Ticker: 'PIY',
    Company: 'Merrill Lynch Depositor'
  },
  {
    Ticker: 'PJC',
    Company: 'Piper Jaffray Companies'
  },
  {
    Ticker: 'PJH',
    Company: 'Prudential Financial Inc'
  },
  {
    Ticker: 'PJT',
    Company: 'Pjt Partners Inc Cl A'
  },
  {
    Ticker: 'PK',
    Company: 'Park Hotels & Resorts Inc'
  },
  {
    Ticker: 'PKD',
    Company: 'Parker Drilling Company'
  },
  {
    Ticker: 'PKE',
    Company: 'Park Electrochemical Corp'
  },
  {
    Ticker: 'PKG',
    Company: 'Packaging Corp of America'
  },
  {
    Ticker: 'PKI',
    Company: 'Perkinelmer'
  },
  {
    Ticker: 'PKO',
    Company: 'Pimco Income Opportunity Fund'
  },
  {
    Ticker: 'PKX',
    Company: 'Posco ADR'
  },
  {
    Ticker: 'PLAN',
    Company: 'Anaplan Inc'
  },
  {
    Ticker: 'PLD',
    Company: 'Prologis Inc'
  },
  {
    Ticker: 'PLNT',
    Company: 'Planet Fitness'
  },
  {
    Ticker: 'PLOW',
    Company: 'Douglas Dynamics'
  },
  {
    Ticker: 'PLT',
    Company: 'Plantronics'
  },
  {
    Ticker: 'PM',
    Company: 'Philip Morris International Inc'
  },
  {
    Ticker: 'PMF',
    Company: 'Pimco Muni'
  },
  {
    Ticker: 'PML',
    Company: 'Pimco Muni II'
  },
  {
    Ticker: 'PMM',
    Company: 'Putnam Managed Muni Income Trust'
  },
  {
    Ticker: 'PMO',
    Company: 'Putnam Muni Opportunities Trust'
  },
  {
    Ticker: 'PMT',
    Company: 'Pennymac Mortgage Investment Trust'
  },
  {
    Ticker: 'PMT-A',
    Company: 'Pennymac Mtg Invt TR [Pmt/Pa]'
  },
  {
    Ticker: 'PMT-B',
    Company: 'Pennymac Mtg Invt TR [Pmt/Pb]'
  },
  {
    Ticker: 'PMX',
    Company: 'Pimco Muni Income Fund III'
  },
  {
    Ticker: 'PNC',
    Company: 'PNC Bank'
  },
  {
    Ticker: 'PNC-P',
    Company: 'PNC Financial Services Group [Pnc/Pp]'
  },
  {
    Ticker: 'PNC-Q',
    Company: 'PNC Financial Services Group [Pnc/Pq]'
  },
  {
    Ticker: 'PNF',
    Company: 'Pimco New York Muni'
  },
  {
    Ticker: 'PNI',
    Company: 'Pimco New York Muni II'
  },
  {
    Ticker: 'PNM',
    Company: 'PNM Resources Inc'
  },
  {
    Ticker: 'PNR',
    Company: 'Pentair Ltd'
  },
  {
    Ticker: 'PNW',
    Company: 'Pinnacle West Capital Corp'
  },
  {
    Ticker: 'POL',
    Company: 'Polyone Corp'
  },
  {
    Ticker: 'POR',
    Company: 'Portland General Electric Company'
  },
  {
    Ticker: 'POST',
    Company: 'Post Holdings Inc'
  },
  {
    Ticker: 'PPDF',
    Company: 'Ppdai Group Inc'
  },
  {
    Ticker: 'PPG',
    Company: 'PPG Industries'
  },
  {
    Ticker: 'PPL',
    Company: 'PPL Corp'
  },
  {
    Ticker: 'PPR',
    Company: 'VOYA Prime Rate Trust'
  },
  {
    Ticker: 'PPT',
    Company: 'Putnam Premier Income Trust'
  },
  {
    Ticker: 'PPX',
    Company: 'PPL Capital Funding Inc'
  },
  {
    Ticker: 'PQG',
    Company: 'Pq Group Holdings Inc'
  },
  {
    Ticker: 'PRA',
    Company: 'Pro-Assurance Corp'
  },
  {
    Ticker: 'PRE-F',
    Company: 'Partnerre Ltd [Pre/Pf]'
  },
  {
    Ticker: 'PRE-G',
    Company: 'Partnerre Ltd [Pre/Pg]'
  },
  {
    Ticker: 'PRE-H',
    Company: 'Partnerre Ltd [Pre/Ph]'
  },
  {
    Ticker: 'PRE-I',
    Company: 'Partnerre Ltd [Pre/Pi]'
  },
  {
    Ticker: 'PRGO',
    Company: 'Perrigo Company'
  },
  {
    Ticker: 'PRH',
    Company: 'Prudential Financial Inc'
  },
  {
    Ticker: 'PRI',
    Company: 'Primerica Inc'
  },
  {
    Ticker: 'PRI-A',
    Company: 'Priority Income Fund Inc [Prif/Pa]'
  },
  {
    Ticker: 'PRI-B',
    Company: 'Priority Income Fund Inc [Prif/Pb]'
  },
  {
    Ticker: 'PRI-C',
    Company: 'Priority Income Fund Inc [Prif/Pc]'
  },
  {
    Ticker: 'PRI-D',
    Company: 'Priority Income Fund Inc [Prif/Pd]'
  },
  {
    Ticker: 'PRI-E',
    Company: 'Priority Income Fund Inc 6.375% Prf Redeem 31/12'
  },
  {
    Ticker: 'PRLB',
    Company: 'Proto Labs Inc'
  },
  {
    Ticker: 'PRO',
    Company: 'Pros Holdings'
  },
  {
    Ticker: 'PROS',
    Company: 'Prosight Global Inc'
  },
  {
    Ticker: 'PRS',
    Company: 'Prudential Financial Inc 5.625% Junior'
  },
  {
    Ticker: 'PRSP',
    Company: 'Perspecta Inc'
  },
  {
    Ticker: 'PRT',
    Company: 'Permrock Royalty Trust Trust Units'
  },
  {
    Ticker: 'PRTY',
    Company: 'Party City Holdco Inc'
  },
  {
    Ticker: 'PRU',
    Company: 'Prudential Financial Inc'
  },
  {
    Ticker: 'PSA',
    Company: 'Public Storage'
  },
  {
    Ticker: 'PSA-A',
    Company: 'Public Storage [Psa/Pa]'
  },
  {
    Ticker: 'PSA-B',
    Company: 'Public Storage [Psa/Pb]'
  },
  {
    Ticker: 'PSA-C',
    Company: 'Public Storage [Psa/Pc]'
  },
  {
    Ticker: 'PSA-D',
    Company: 'Public Storage [Psa/Pd]'
  },
  {
    Ticker: 'PSA-E',
    Company: 'Public Storage [Psa/Pe]'
  },
  {
    Ticker: 'PSA-F',
    Company: 'Public Storage [Psa/Pf]'
  },
  {
    Ticker: 'PSA-G',
    Company: 'Public Storage [Psa/Pg]'
  },
  {
    Ticker: 'PSA-H',
    Company: 'Public Storage [Psa/Ph]'
  },
  {
    Ticker: 'PSA-I',
    Company: 'Public Storage [Psa/Pi]'
  },
  {
    Ticker: 'PSA-J',
    Company: 'Public Storage 4.70% Prf Perpetual USD 25 Ser J'
  },
  {
    Ticker: 'PSA-V',
    Company: 'Public Storage [Psa/Pv]'
  },
  {
    Ticker: 'PSA-W',
    Company: 'Public Storage [Psa/Pw]'
  },
  {
    Ticker: 'PSA-X',
    Company: 'Public Storage [Psa/Px]'
  },
  {
    Ticker: 'PSB',
    Company: 'Business Parks'
  },
  {
    Ticker: 'PSB-U',
    Company: 'PS Business Pks Inc Calif [Psb/Pu]'
  },
  {
    Ticker: 'PSB-V',
    Company: 'PS Business Pks Inc Calif [Psb/Pv]'
  },
  {
    Ticker: 'PSB-W',
    Company: 'PS Business Pks Inc Calif [Psb/Pw]'
  },
  {
    Ticker: 'PSB-X',
    Company: 'PS Business Pks Inc Calif [Psb/Px]'
  },
  {
    Ticker: 'PSB-Y',
    Company: 'PS Business Pks Inc Calif [Psb/Py]'
  },
  {
    Ticker: 'PSB-Z',
    Company: 'PS Business Parks Inc Prf Perpetual USD 25 Dp Sh'
  },
  {
    Ticker: 'PSF',
    Company: 'Cohen & Steers Preferred Fund'
  },
  {
    Ticker: 'PSN',
    Company: 'Parsons Corp'
  },
  {
    Ticker: 'PSO',
    Company: 'Pearson Plc'
  },
  {
    Ticker: 'PSTG',
    Company: 'Pure Storage Inc'
  },
  {
    Ticker: 'PSTL',
    Company: 'Postal Realty Trust Cl A'
  },
  {
    Ticker: 'PSV',
    Company: 'Hermitage Offshore Services Ltd'
  },
  {
    Ticker: 'PSX',
    Company: 'Phillips 66'
  },
  {
    Ticker: 'PSXP',
    Company: 'Phillips 66 Partners LP'
  },
  {
    Ticker: 'PTR',
    Company: 'Petrochina Company Ltd'
  },
  {
    Ticker: 'PTY',
    Company: 'Pimco Corporate Opportunity Fund'
  },
  {
    Ticker: 'PUK',
    Company: 'Prudential Public Ltd Company'
  },
  {
    Ticker: 'PUK-A',
    Company: 'Prudential [Puk/Pa]'
  },
  {
    Ticker: 'PUK.P',
    Company: 'Prudential [Puk/P]'
  },
  {
    Ticker: 'PUMP',
    Company: 'Propetro Holding Corp'
  },
  {
    Ticker: 'PVG',
    Company: 'Pretium Res Inc'
  },
  {
    Ticker: 'PVH',
    Company: 'Phillips-Van Heusen Corp'
  },
  {
    Ticker: 'PVL',
    Company: 'Permianville Royalty Trust'
  },
  {
    Ticker: 'PVT',
    Company: 'Pivotal Acquisition Corp Cl A'
  },
  {
    Ticker: 'PVT.U',
    Company: 'Pivotal Acquisition Corp'
  },
  {
    Ticker: 'PVT.W',
    Company: 'Pivotal Acquisition Corp WT [Pvt/W]'
  },
  {
    Ticker: 'PVTL',
    Company: 'Pivotal Software Inc'
  },
  {
    Ticker: 'PWR',
    Company: 'Quanta Services'
  },
  {
    Ticker: 'PXD',
    Company: 'Pioneer Natural Resources Company'
  },
  {
    Ticker: 'PYN',
    Company: 'Pimco New York Muni Income Fund III'
  },
  {
    Ticker: 'PYS',
    Company: 'Pplus Trust'
  },
  {
    Ticker: 'PYT',
    Company: 'Pplus Trust'
  },
  {
    Ticker: 'PYX',
    Company: 'Pyxus International Inc'
  },
  {
    Ticker: 'PZC',
    Company: 'Pimco California Muni Income Fund III'
  },
  {
    Ticker: 'PZN',
    Company: 'Pzena Investment Management Inc'
  },
  {
    Ticker: 'QD',
    Company: 'Qudian Inc ADR'
  },
  {
    Ticker: 'QEP',
    Company: 'Qep Resources'
  },
  {
    Ticker: 'QES',
    Company: 'Quintana Energy Services Inc'
  },
  {
    Ticker: 'QGEN',
    Company: 'Qiagen N.V.'
  },
  {
    Ticker: 'QHC',
    Company: 'Quorum Health Corp'
  },
  {
    Ticker: 'QSR',
    Company: 'Restaurant Brands International'
  },
  {
    Ticker: 'QTS',
    Company: 'Qts Realty Trust Inc'
  },
  {
    Ticker: 'QTS-A',
    Company: 'Qts Rlty TR Inc [Qts/Pa]'
  },
  {
    Ticker: 'QTS-B',
    Company: 'Qts Rlty TR Inc [Qts/Pb]'
  },
  {
    Ticker: 'QTWO',
    Company: 'Q2 Holdings Inc'
  },
  {
    Ticker: 'QUAD',
    Company: 'Quad Graphics Inc'
  },
  {
    Ticker: 'QUOT',
    Company: 'Quotient Technology Inc'
  },
  {
    Ticker: 'QVCC',
    Company: 'Qvc Inc 6.250% Senior Secured Notes Due 2068'
  },
  {
    Ticker: 'QVCD',
    Company: 'Qvc Inc 6.375% Senior Secured Notes Due 2067'
  },
  {
    Ticker: 'R',
    Company: 'Ryder System'
  },
  {
    Ticker: 'RA',
    Company: 'Brookfield Real Assets Income Fund Inc'
  },
  {
    Ticker: 'RACE',
    Company: 'Ferrari N.V.'
  },
  {
    Ticker: 'RAD',
    Company: 'Rite Aid Corp'
  },
  {
    Ticker: 'RAMP',
    Company: 'Liveramp Holdings Inc'
  },
  {
    Ticker: 'RBA',
    Company: 'Ritchie Bros. Auctioneers Inc'
  },
  {
    Ticker: 'RBC',
    Company: 'Regal-Beloit Corp'
  },
  {
    Ticker: 'RBS',
    Company: 'Royal Bank Scotland Group Plc'
  },
  {
    Ticker: 'RC',
    Company: 'Ready Capital Corp'
  },
  {
    Ticker: 'RCA',
    Company: 'Ready Capital Corp 7.00%'
  },
  {
    Ticker: 'RCB',
    Company: 'Ready Capital Corp 6.20% Senior Notes Due'
  },
  {
    Ticker: 'RCI',
    Company: 'Rogers Communication'
  },
  {
    Ticker: 'RCL',
    Company: 'Royal Caribbean Cruises Ltd'
  },
  {
    Ticker: 'RCP',
    Company: 'Ready Capital Corp 6.50% Series'
  },
  {
    Ticker: 'RCS',
    Company: 'Rcm Strategic Global Government Fund'
  },
  {
    Ticker: 'RCUS',
    Company: 'Arcus Biosciences Inc'
  },
  {
    Ticker: 'RDN',
    Company: 'Radian Group Inc'
  },
  {
    Ticker: 'RDS.A',
    Company: 'Royal Dutch Shell ADR Cl A'
  },
  {
    Ticker: 'RDS.B',
    Company: 'Royal Dutch Shell Cl B'
  },
  {
    Ticker: 'RDY',
    Company: "Dr. Reddy's Laboratories Ltd"
  },
  {
    Ticker: 'RE',
    Company: 'Everest Re Group'
  },
  {
    Ticker: 'RELX',
    Company: 'Relx Plc'
  },
  {
    Ticker: 'RENN',
    Company: 'Renren Inc'
  },
  {
    Ticker: 'RES',
    Company: 'RPC Inc'
  },
  {
    Ticker: 'RESI',
    Company: 'Altisource Residential Corp'
  },
  {
    Ticker: 'REV',
    Company: 'Revlon Inc'
  },
  {
    Ticker: 'REVG',
    Company: 'Rev Group Inc'
  },
  {
    Ticker: 'REX',
    Company: 'Rex American Resources Corp'
  },
  {
    Ticker: 'REX-A',
    Company: 'Rexford Indl Rlty Inc [Rexr/Pa]'
  },
  {
    Ticker: 'REX-B',
    Company: 'Rexford Indl Rlty Inc [Rexr/Pb]'
  },
  {
    Ticker: 'REX-C',
    Company: 'Rexford Industrial Realty Inc 5.625% Prf Perpetu'
  },
  {
    Ticker: 'REXR',
    Company: 'Rexford Industrial Realty Inc'
  },
  {
    Ticker: 'REZI',
    Company: 'Resideo Technologies Inc'
  },
  {
    Ticker: 'RF',
    Company: 'Regions Financial Corp'
  },
  {
    Ticker: 'RF-A',
    Company: 'Regions Financial Corp [Rf/Pa]'
  },
  {
    Ticker: 'RF-B',
    Company: 'Regions Financial Corp [Rf/Pb]'
  },
  {
    Ticker: 'RF-C',
    Company: 'Regions Financial Corp [Rf/Pc]'
  },
  {
    Ticker: 'RFI',
    Company: 'Cohen & Steers Total Return Realty Fund'
  },
  {
    Ticker: 'RFL',
    Company: 'Rafael Holdings Inc'
  },
  {
    Ticker: 'RFP',
    Company: 'Resolute Forest Products Inc'
  },
  {
    Ticker: 'RGA',
    Company: 'Reinsurance Group of America Inc'
  },
  {
    Ticker: 'RGR',
    Company: 'Sturm Ruger & Company'
  },
  {
    Ticker: 'RGS',
    Company: 'Regis Corp'
  },
  {
    Ticker: 'RGT',
    Company: 'Royce Global Value Trust Inc'
  },
  {
    Ticker: 'RH',
    Company: 'Restoration Hardware Holdings'
  },
  {
    Ticker: 'RHI',
    Company: 'Robert Half International Inc'
  },
  {
    Ticker: 'RHP',
    Company: 'Ryman Hospitality Properties REIT'
  },
  {
    Ticker: 'RIG',
    Company: 'Transocean Inc'
  },
  {
    Ticker: 'RIO',
    Company: 'Rio Tinto Plc'
  },
  {
    Ticker: 'RIV',
    Company: 'Rivernorth Opportunities Fund'
  },
  {
    Ticker: 'RJF',
    Company: 'Raymond James Financial'
  },
  {
    Ticker: 'RL',
    Company: 'Ralph Lauren Corp'
  },
  {
    Ticker: 'RLGY',
    Company: 'Realogy Holdings Corp'
  },
  {
    Ticker: 'RLH',
    Company: 'Red Lion Hotels Corp'
  },
  {
    Ticker: 'RLI',
    Company: 'Rli Corp'
  },
  {
    Ticker: 'RLJ',
    Company: 'Rlj Lodging Trust'
  },
  {
    Ticker: 'RLJ-A',
    Company: 'Rlj Lodging TR [Rlj/Pa]'
  },
  {
    Ticker: 'RM',
    Company: 'Regional Managment Corp'
  },
  {
    Ticker: 'RMAX',
    Company: 'Re/Max Holdings Inc'
  },
  {
    Ticker: 'RMD',
    Company: 'Resmed Inc'
  },
  {
    Ticker: 'RMED',
    Company: 'Ra Medical Systems Inc'
  },
  {
    Ticker: 'RMG',
    Company: 'Rmg Acquisition Corp Cl A'
  },
  {
    Ticker: 'RMG.U',
    Company: 'Rmg Acquisition Corp'
  },
  {
    Ticker: 'RMG.W',
    Company: 'Rmg Acquisition Corp WT [Rmg/W]'
  },
  {
    Ticker: 'RMI',
    Company: 'Rivernorth Opportunistic Municipal Income Fund'
  },
  {
    Ticker: 'RMM',
    Company: 'Rivernorth Managed Duration Municipal Income Fun'
  },
  {
    Ticker: 'RMP.P',
    Company: 'Rivernorth Marketplace Lending Corp [Rmpl/P]'
  },
  {
    Ticker: 'RMT',
    Company: 'Royce Micro-Cap Trust'
  },
  {
    Ticker: 'RNG',
    Company: 'Ringcentral Inc'
  },
  {
    Ticker: 'RNGR',
    Company: 'Ranger Energy Services Inc Cl A'
  },
  {
    Ticker: 'RNP',
    Company: 'Cohen & Steers REIT and Preferred'
  },
  {
    Ticker: 'RNR',
    Company: 'Renaissancere Holdings Ltd'
  },
  {
    Ticker: 'RNR-C',
    Company: 'Renaissancere Holdings Ltd [Rnr/Pc]'
  },
  {
    Ticker: 'RNR-E',
    Company: 'Renaissancere Holdings Ltd [Rnr/Pe]'
  },
  {
    Ticker: 'RNR-F',
    Company: 'Renaissancere Holdings Ltd [Rnr/Pf]'
  },
  {
    Ticker: 'ROAN',
    Company: 'Roan Resources Inc'
  },
  {
    Ticker: 'ROG',
    Company: 'Rogers Corp'
  },
  {
    Ticker: 'ROK',
    Company: 'Rockwell Automation Inc'
  },
  {
    Ticker: 'ROL',
    Company: 'Rollins Inc'
  },
  {
    Ticker: 'ROP',
    Company: 'Roper Industries'
  },
  {
    Ticker: 'ROYT',
    Company: 'Pacific Coast Oil Trust'
  },
  {
    Ticker: 'RPAI',
    Company: 'Retail Properties of America'
  },
  {
    Ticker: 'RPL.U',
    Company: 'Replay Acquisition Corp [Rpla.U]'
  },
  {
    Ticker: 'RPL.W',
    Company: 'Replay Acquisition Corp WT [Rpla/W]'
  },
  {
    Ticker: 'RPLA',
    Company: 'Replay Acquisition Corp'
  },
  {
    Ticker: 'RPM',
    Company: 'RPM International Inc'
  },
  {
    Ticker: 'RPT',
    Company: 'Ramco-Gershenson Properties Trust'
  },
  {
    Ticker: 'RPT-D',
    Company: 'Rpt Realty [Rpt/Pd]'
  },
  {
    Ticker: 'RQI',
    Company: 'Cohen & Steers Quality Income Realty Fund Inc'
  },
  {
    Ticker: 'RRC',
    Company: 'Range Resources Corp'
  },
  {
    Ticker: 'RRD',
    Company: 'Donnelley [R.R.] & Sons Company'
  },
  {
    Ticker: 'RRTS',
    Company: 'Roadrunner Transportation Systems Inc'
  },
  {
    Ticker: 'RS',
    Company: 'Reliance Steel & Aluminum Company'
  },
  {
    Ticker: 'RSF',
    Company: 'Rivernorth Marketplace Lending Corp'
  },
  {
    Ticker: 'RSG',
    Company: 'Republic Services'
  },
  {
    Ticker: 'RST',
    Company: 'Rosetta Stone'
  },
  {
    Ticker: 'RTN',
    Company: 'Raytheon Company'
  },
  {
    Ticker: 'RTW',
    Company: 'Rtw Retailwinds Inc'
  },
  {
    Ticker: 'RUBI',
    Company: 'The Rubicon Project Inc'
  },
  {
    Ticker: 'RVI',
    Company: 'Retail Value Inc'
  },
  {
    Ticker: 'RVLV',
    Company: 'Revolve Group Inc'
  },
  {
    Ticker: 'RVT',
    Company: 'Royce Value Trust'
  },
  {
    Ticker: 'RWG.U',
    Company: 'Regalwood Global Energy Ltd [Rwge.U]'
  },
  {
    Ticker: 'RWG.W',
    Company: 'Regalwood Global Energy Ltd WT [Rwge/W]'
  },
  {
    Ticker: 'RWGE',
    Company: 'Regalwood Global Energy Ltd Cl A'
  },
  {
    Ticker: 'RWT',
    Company: 'Redwood Trust'
  },
  {
    Ticker: 'RXN',
    Company: 'Rexnord Corp'
  },
  {
    Ticker: 'RY',
    Company: 'Royal Bank of Canada'
  },
  {
    Ticker: 'RY-T',
    Company: 'Royal Bank of Canada [Ry/Pt]'
  },
  {
    Ticker: 'RYAM',
    Company: 'Rayonier Advanced Materials Inc'
  },
  {
    Ticker: 'RYB',
    Company: 'Ryb Education Inc Aps'
  },
  {
    Ticker: 'RYI',
    Company: 'Ryerson Holding Corp'
  },
  {
    Ticker: 'RYN',
    Company: 'Rayonier Inc REIT'
  },
  {
    Ticker: 'RZA',
    Company: 'Reinsurance Group of America'
  },
  {
    Ticker: 'RZB',
    Company: 'Reinsurance Group of America'
  },
  {
    Ticker: 'S',
    Company: 'Sprint Corp'
  },
  {
    Ticker: 'SA',
    Company: 'Seabridge Gold'
  },
  {
    Ticker: 'SAB',
    Company: 'Saratoga Investment Corp 6.75% Notes Due 2023'
  },
  {
    Ticker: 'SAF',
    Company: 'Saratoga Investment Corp'
  },
  {
    Ticker: 'SAFE',
    Company: 'Safehold Inc'
  },
  {
    Ticker: 'SAH',
    Company: 'Sonic Automotive'
  },
  {
    Ticker: 'SAIC',
    Company: 'Science Applications International'
  },
  {
    Ticker: 'SAIL',
    Company: 'Sailpoint Technologies Holdings Inc'
  },
  {
    Ticker: 'SALT',
    Company: 'Scorpio Bulkers Inc'
  },
  {
    Ticker: 'SAM',
    Company: 'Boston Beer Company'
  },
  {
    Ticker: 'SAN',
    Company: 'Banco Santander'
  },
  {
    Ticker: 'SAN-B',
    Company: 'Banco Santander Sa [San/Pb]'
  },
  {
    Ticker: 'SAP',
    Company: 'SAP Ag'
  },
  {
    Ticker: 'SAR',
    Company: 'Saratoga Investment Corp'
  },
  {
    Ticker: 'SAVE',
    Company: 'Spirit Airlines Inc'
  },
  {
    Ticker: 'SB',
    Company: 'Safe Bulkers Inc'
  },
  {
    Ticker: 'SB-C',
    Company: 'Safe Bulkers Inc [Sb/Pc]'
  },
  {
    Ticker: 'SB-D',
    Company: 'Safe Bulkers Inc [Sb/Pd]'
  },
  {
    Ticker: 'SBE',
    Company: 'Switchback Energy Acquisition Corp Cl A'
  },
  {
    Ticker: 'SBE.U',
    Company: 'Switchback Energy Acquisition Corp'
  },
  {
    Ticker: 'SBE.W',
    Company: 'Switchback Energy Acquisition Corp WT'
  },
  {
    Ticker: 'SBGL',
    Company: 'Sibanye Gold Ltd ADR'
  },
  {
    Ticker: 'SBH',
    Company: 'Sally Beauty Holdings'
  },
  {
    Ticker: 'SBI',
    Company: 'Western Asset Intermediate Fund Inc'
  },
  {
    Ticker: 'SBNA',
    Company: 'Scorpio Tankers Inc'
  },
  {
    Ticker: 'SBOW',
    Company: 'Silverbow Resources Inc'
  },
  {
    Ticker: 'SBR',
    Company: 'Sabine Royalty Trust'
  },
  {
    Ticker: 'SBS',
    Company: 'Cia DE Saneamento Basico Do Estado'
  },
  {
    Ticker: 'SC',
    Company: 'Santander Consumer USA Holdings'
  },
  {
    Ticker: 'SCA',
    Company: 'Stellus Capital Investment 5.75% Notes Due 2022'
  },
  {
    Ticker: 'SCCO',
    Company: 'Southern Peru Copper Corp'
  },
  {
    Ticker: 'SCD',
    Company: 'Lmp Capital and Income Fund Inc'
  },
  {
    Ticker: 'SCE-G',
    Company: 'Sce Trust II [Sce/Pg]'
  },
  {
    Ticker: 'SCE-H',
    Company: 'Sce TR III [Sce/Ph]'
  },
  {
    Ticker: 'SCE-J',
    Company: 'Sce TR IV [Sce/Pj]'
  },
  {
    Ticker: 'SCE-K',
    Company: 'Sce Trust V [Sce/Pk]'
  },
  {
    Ticker: 'SCE-L',
    Company: 'Sce Trust Vi [Sce/Pl]'
  },
  {
    Ticker: 'SCH-C',
    Company: 'Charles Schwab Corp [Schw/Pc]'
  },
  {
    Ticker: 'SCH-D',
    Company: 'Charles Schwab Corp [Schw/Pd]'
  },
  {
    Ticker: 'SCHW',
    Company: 'The Charles Schwab Corp'
  },
  {
    Ticker: 'SCI',
    Company: 'Service Corp International'
  },
  {
    Ticker: 'SCL',
    Company: 'Stepan Company'
  },
  {
    Ticker: 'SCM',
    Company: 'Stellus Capital Investment Cor'
  },
  {
    Ticker: 'SCP.U',
    Company: 'SC Health Corp [Scpe.U]'
  },
  {
    Ticker: 'SCP.W',
    Company: 'SC Health Corp WT [Scpe/W]'
  },
  {
    Ticker: 'SCPE',
    Company: 'SC Health Corp Cl A'
  },
  {
    Ticker: 'SCS',
    Company: 'Steelcase Inc'
  },
  {
    Ticker: 'SCU',
    Company: 'Sculptor Capital Management Inc'
  },
  {
    Ticker: 'SCX',
    Company: 'L.S. Starrett Company'
  },
  {
    Ticker: 'SD',
    Company: 'Sandridge Energy Inc'
  },
  {
    Ticker: 'SDRL',
    Company: 'Seadrill Ltd'
  },
  {
    Ticker: 'SDT',
    Company: 'Sandridge Mississippian Trust'
  },
  {
    Ticker: 'SE',
    Company: 'Sea Ltd ADR'
  },
  {
    Ticker: 'SEAS',
    Company: 'Seaworld Entertainment Inc Company'
  },
  {
    Ticker: 'SEE',
    Company: 'Sealed Air Corp'
  },
  {
    Ticker: 'SEM',
    Company: 'Select Medical Holdings Corp'
  },
  {
    Ticker: 'SEMG',
    Company: 'Semgroup Corp'
  },
  {
    Ticker: 'SERV',
    Company: 'Servicemaster Global Holdings'
  },
  {
    Ticker: 'SF',
    Company: 'Stifel Financial Corp'
  },
  {
    Ticker: 'SF-A',
    Company: 'Stifel Financial Corp [Sf/Pa]'
  },
  {
    Ticker: 'SF-B',
    Company: 'Stifel Financial Corp [Sf/Pb]'
  },
  {
    Ticker: 'SFB',
    Company: 'Stifel Financial Corp 5.20% Senior Notes'
  },
  {
    Ticker: 'SFE',
    Company: 'Safeguard Scientifics'
  },
  {
    Ticker: 'SFL',
    Company: 'Ship Finance International'
  },
  {
    Ticker: 'SFT.U',
    Company: 'Osprey Technology Acquisition Corp [Sftw.U]'
  },
  {
    Ticker: 'SFUN',
    Company: 'Soufun Holdings'
  },
  {
    Ticker: 'SGU',
    Company: 'Star Gas Partners LP'
  },
  {
    Ticker: 'SHAK',
    Company: 'Shake Shack Inc'
  },
  {
    Ticker: 'SHG',
    Company: 'Shinhan Financial Group Co. Ltd'
  },
  {
    Ticker: 'SHI',
    Company: 'Sinopec Shangai Petrochemical Company Ltd'
  },
  {
    Ticker: 'SHL.U',
    Company: 'Tortoise Acquisition Corp [Shll.U]'
  },
  {
    Ticker: 'SHL.W',
    Company: 'Tortoise Acquisition Corp WT [Shll/W]'
  },
  {
    Ticker: 'SHLL',
    Company: 'Tortoise Acquisition Corp Cl A'
  },
  {
    Ticker: 'SHLX',
    Company: 'Shell Midstream Partners LP'
  },
  {
    Ticker: 'SHO',
    Company: 'Sunstone Hotel Investors'
  },
  {
    Ticker: 'SHO-E',
    Company: 'Sunstone Hotel Invs Inc [Sho/Pe]'
  },
  {
    Ticker: 'SHO-F',
    Company: 'Sunstone Hotel Invs Inc [Sho/Pf]'
  },
  {
    Ticker: 'SHOP',
    Company: 'Shopify Inc'
  },
  {
    Ticker: 'SHW',
    Company: 'Sherwin-Williams Company'
  },
  {
    Ticker: 'SI',
    Company: 'Silvergate Capital Corporation Cl A'
  },
  {
    Ticker: 'SID',
    Company: 'Companhia Siderurgica Nacional'
  },
  {
    Ticker: 'SIG',
    Company: 'Signet Jewelers Ltd'
  },
  {
    Ticker: 'SIT-A',
    Company: 'Site Centers Corp Shs Pfd Cl A'
  },
  {
    Ticker: 'SIT-J',
    Company: 'Site Centers Corp [Sitc/Pj]'
  },
  {
    Ticker: 'SIT-K',
    Company: 'Site Centers Corp [Sitc/Pk]'
  },
  {
    Ticker: 'SITC',
    Company: 'Site Centers Corp'
  },
  {
    Ticker: 'SITE',
    Company: 'Siteone Landscape Supply'
  },
  {
    Ticker: 'SIX',
    Company: 'Six Flags Entertainment Corp'
  },
  {
    Ticker: 'SJI',
    Company: 'South Jersey Industries'
  },
  {
    Ticker: 'SJIJ',
    Company: 'South Jersey Industries Inc 5.625% Junior Subor'
  },
  {
    Ticker: 'SJIU',
    Company: 'South Jersey Industries Inc'
  },
  {
    Ticker: 'SJM',
    Company: 'J.M. Smucker Company'
  },
  {
    Ticker: 'SJR',
    Company: 'Shaw Communications Inc'
  },
  {
    Ticker: 'SJT',
    Company: 'San Juan Basin Royalty Trust'
  },
  {
    Ticker: 'SJW',
    Company: 'SJW Corp'
  },
  {
    Ticker: 'SKM',
    Company: 'Sk Telecom Corp'
  },
  {
    Ticker: 'SKT',
    Company: 'Tanger Factory Outlet Centers'
  },
  {
    Ticker: 'SKX',
    Company: 'Skechers U.S.A.'
  },
  {
    Ticker: 'SKY',
    Company: 'Skyline Corp'
  },
  {
    Ticker: 'SLB',
    Company: 'Schlumberger N.V.'
  },
  {
    Ticker: 'SLCA',
    Company: 'U.S. Silica Holdings Inc'
  },
  {
    Ticker: 'SLF',
    Company: 'Sun Life Financial Inc'
  },
  {
    Ticker: 'SLG',
    Company: 'SL Green Realty Corp'
  },
  {
    Ticker: 'SLG-I',
    Company: 'SL Green Rlty Corp [Slg/Pi]'
  },
  {
    Ticker: 'SM',
    Company: 'Sm Energy Company'
  },
  {
    Ticker: 'SMAR',
    Company: 'Smartsheet Inc Cl A'
  },
  {
    Ticker: 'SMFG',
    Company: 'Sumitomo Mitsui Financial Group Inc'
  },
  {
    Ticker: 'SMG',
    Company: 'Scotts Miracle-Gro Company'
  },
  {
    Ticker: 'SMHI',
    Company: 'Seacor Marine Holdings Inc'
  },
  {
    Ticker: 'SMLP',
    Company: 'Summit Midstream Partners LP'
  },
  {
    Ticker: 'SMM',
    Company: 'Salient Midstream & MLP Fund'
  },
  {
    Ticker: 'SMP',
    Company: 'Standard Motor Products'
  },
  {
    Ticker: 'SMTA',
    Company: 'Spirit Mta REIT'
  },
  {
    Ticker: 'SNA',
    Company: 'Snap-On Inc'
  },
  {
    Ticker: 'SNAP',
    Company: 'Snap Inc'
  },
  {
    Ticker: 'SNDR',
    Company: 'Schneider National Inc'
  },
  {
    Ticker: 'SNE',
    Company: 'Sony Corp'
  },
  {
    Ticker: 'SNN',
    Company: 'Smith & Nephew Snats'
  },
  {
    Ticker: 'SNP',
    Company: 'China Petroleum & Chemical Corp'
  },
  {
    Ticker: 'SNR',
    Company: 'New Senior Investment Group Inc'
  },
  {
    Ticker: 'SNV',
    Company: 'Synovus Financial Corp'
  },
  {
    Ticker: 'SNV-D',
    Company: 'Synovus Financial Corp [Snv/Pd]'
  },
  {
    Ticker: 'SNV-E',
    Company: 'Synovus Finl Corp [Snv/Pe]'
  },
  {
    Ticker: 'SNX',
    Company: 'Synnex Corp'
  },
  {
    Ticker: 'SO',
    Company: 'Southern Company'
  },
  {
    Ticker: 'SOGO',
    Company: 'Sogou Inc'
  },
  {
    Ticker: 'SOI',
    Company: 'Solaris Oilfield Infrastructure Inc Cl A'
  },
  {
    Ticker: 'SOJA',
    Company: 'The Southern Co. Series'
  },
  {
    Ticker: 'SOJB',
    Company: 'Southern Company'
  },
  {
    Ticker: 'SOJC',
    Company: 'Southern Company Series 2017B 5.25% Junior Subor'
  },
  {
    Ticker: 'SOL',
    Company: 'Renesola Ltd'
  },
  {
    Ticker: 'SOLN',
    Company: 'Southern Company'
  },
  {
    Ticker: 'SON',
    Company: 'Sonoco Products Company'
  },
  {
    Ticker: 'SOR',
    Company: 'Source Capital'
  },
  {
    Ticker: 'SPA.U',
    Company: 'Spartan Energy Acquisiton Corp [Spaq.U]'
  },
  {
    Ticker: 'SPA.W',
    Company: 'Spartan Energy Acquisiton Corp WT [Spaq/W]'
  },
  {
    Ticker: 'SPAQ',
    Company: 'Spartan Energy Acquisition Corp Cl A'
  },
  {
    Ticker: 'SPB',
    Company: 'Spectrum Brands Holdings Inc'
  },
  {
    Ticker: 'SPC.U',
    Company: 'Virgin Galactic Hlds Inc [Spce.U]'
  },
  {
    Ticker: 'SPC.W',
    Company: 'Virgin Galactic Hlds Inc [Spce/W]'
  },
  {
    Ticker: 'SPCE',
    Company: 'Virgin Galactic Holdings Inc'
  },
  {
    Ticker: 'SPE',
    Company: 'Special Opportunities Fund Inc'
  },
  {
    Ticker: 'SPE-B',
    Company: 'Special Opportunities Fd Inc [Spe/Pb]'
  },
  {
    Ticker: 'SPG',
    Company: 'Simon Property Group'
  },
  {
    Ticker: 'SPG-J',
    Company: 'Simon Ppty Group Inc [Spg/Pj]'
  },
  {
    Ticker: 'SPGI',
    Company: 'S&P Global Inc'
  },
  {
    Ticker: 'SPH',
    Company: 'Suburban Propane Partners LP'
  },
  {
    Ticker: 'SPL-A',
    Company: 'Steel Partners Holdings LP [Splp/Pa]'
  },
  {
    Ticker: 'SPLP',
    Company: 'Steel Partners Hlds'
  },
  {
    Ticker: 'SPOT',
    Company: 'Spotify Technology S.A.'
  },
  {
    Ticker: 'SPR',
    Company: 'Spirit Aerosystems Holdings'
  },
  {
    Ticker: 'SPXC',
    Company: 'SPX Corp'
  },
  {
    Ticker: 'SPXX',
    Company: 'Nuveen Equity Premium and Growth Fund'
  },
  {
    Ticker: 'SQ',
    Company: 'Square'
  },
  {
    Ticker: 'SQM',
    Company: 'Sociedad Quimica Y Minera S.A.'
  },
  {
    Ticker: 'SQNS',
    Company: 'Sequans Communications S A'
  },
  {
    Ticker: 'SR',
    Company: 'Spire Inc'
  },
  {
    Ticker: 'SR-A',
    Company: 'Spire Inc [Sr/Pa]'
  },
  {
    Ticker: 'SRC',
    Company: 'Spirit Realty Capital Inc'
  },
  {
    Ticker: 'SRC-A',
    Company: 'Spirit Rlty Cap Inc [Src/Pa]'
  },
  {
    Ticker: 'SRE',
    Company: 'Sempra Energy'
  },
  {
    Ticker: 'SRE-A',
    Company: 'Sempra Energy [Sre/Pa]'
  },
  {
    Ticker: 'SRE-B',
    Company: 'Sempra Energy [Sre/Pb]'
  },
  {
    Ticker: 'SREA',
    Company: 'Sempra Energy 5.750% Junior Subordinated Notes D'
  },
  {
    Ticker: 'SRF',
    Company: 'The Cushing Royalty & Income Fund'
  },
  {
    Ticker: 'SRG',
    Company: 'Seritage Growth Properties'
  },
  {
    Ticker: 'SRG-A',
    Company: 'Seritage Growth Pptys [Srg/Pa]'
  },
  {
    Ticker: 'SRI',
    Company: 'Stoneridge Inc'
  },
  {
    Ticker: 'SRL',
    Company: 'Scully Royalty Ltd'
  },
  {
    Ticker: 'SRLP',
    Company: 'Sprague Resources LP'
  },
  {
    Ticker: 'SRT',
    Company: 'Startek Inc'
  },
  {
    Ticker: 'SRV',
    Company: 'The Cushing MLP Total Return Fund'
  },
  {
    Ticker: 'SSD',
    Company: 'Simpson Manufacturing Company'
  },
  {
    Ticker: 'SSI',
    Company: 'Stage Stores'
  },
  {
    Ticker: 'SSL',
    Company: 'Sasol Ltd'
  },
  {
    Ticker: 'SSTK',
    Company: 'Shutterstock Inc'
  },
  {
    Ticker: 'SSW',
    Company: 'Seaspan Corp'
  },
  {
    Ticker: 'SSW-D',
    Company: 'Seaspan Corp [Ssw/Pd]'
  },
  {
    Ticker: 'SSW-E',
    Company: 'Seaspan Corp [Ssw/Pe]'
  },
  {
    Ticker: 'SSW-G',
    Company: 'Seaspan Corp [Ssw/Pg]'
  },
  {
    Ticker: 'SSW-H',
    Company: 'Seaspan Corp [Ssw/Ph]'
  },
  {
    Ticker: 'SSW-I',
    Company: 'Seaspan Corp [Ssw/Pi]'
  },
  {
    Ticker: 'SSWA',
    Company: 'Seaspan Corp 7.125% Notes Due 2027'
  },
  {
    Ticker: 'ST',
    Company: 'Sensata Technologies Holding N.V.'
  },
  {
    Ticker: 'STA-C',
    Company: 'Stag Indl Inc [Stag/Pc]'
  },
  {
    Ticker: 'STA-D',
    Company: 'Istar Inc [Star/Pd]'
  },
  {
    Ticker: 'STA-G',
    Company: 'Istar Inc [Star/Pg]'
  },
  {
    Ticker: 'STA-I',
    Company: 'Istar Inc [Star/Pi]'
  },
  {
    Ticker: 'STAG',
    Company: 'STI Inc'
  },
  {
    Ticker: 'STAR',
    Company: 'Istar Financial Inc'
  },
  {
    Ticker: 'STC',
    Company: 'Stewart Information Services Corp'
  },
  {
    Ticker: 'STE',
    Company: 'Steris Corp'
  },
  {
    Ticker: 'STG',
    Company: 'Sunlands Online Education Group ADR'
  },
  {
    Ticker: 'STI',
    Company: 'Suntrust Banks'
  },
  {
    Ticker: 'STI-A',
    Company: 'Suntrust Banks Inc [Sti/Pa]'
  },
  {
    Ticker: 'STK',
    Company: 'Columbia Seligman Premium Technology'
  },
  {
    Ticker: 'STL',
    Company: 'Sterling Bancorp'
  },
  {
    Ticker: 'STL-A',
    Company: 'Sterling Bancorp D [Stl/Pa]'
  },
  {
    Ticker: 'STM',
    Company: 'Stmicroelectronics N.V.'
  },
  {
    Ticker: 'STN',
    Company: 'Stantec Inc'
  },
  {
    Ticker: 'STNG',
    Company: 'Scorpio Tankers Inc'
  },
  {
    Ticker: 'STON',
    Company: 'Stonemor Partners LP'
  },
  {
    Ticker: 'STOR',
    Company: 'Store Capital Corpstore Capital'
  },
  {
    Ticker: 'STT',
    Company: 'State Street Corp'
  },
  {
    Ticker: 'STT-C',
    Company: 'State Street Corp [Stt/Pc]'
  },
  {
    Ticker: 'STT-D',
    Company: 'State Street Corp [Stt/Pd]'
  },
  {
    Ticker: 'STT-E',
    Company: 'State Street Corp [Stt/Pe]'
  },
  {
    Ticker: 'STT-G',
    Company: 'State Street Corp [Stt/Pg]'
  },
  {
    Ticker: 'STWD',
    Company: 'Starwood Property Trust'
  },
  {
    Ticker: 'STZ',
    Company: 'Constellation Brands Inc'
  },
  {
    Ticker: 'STZ.B',
    Company: 'Constellation Brd Cl B'
  },
  {
    Ticker: 'SU',
    Company: 'Suncor Energy Inc'
  },
  {
    Ticker: 'SUI',
    Company: 'Sun Communities'
  },
  {
    Ticker: 'SUM',
    Company: 'Summit Materials Inc'
  },
  {
    Ticker: 'SUN',
    Company: 'Sunoco LP'
  },
  {
    Ticker: 'SUP',
    Company: 'Superior Industries International'
  },
  {
    Ticker: 'SUPV',
    Company: 'Grupo Supervielle S.A.'
  },
  {
    Ticker: 'SUZ',
    Company: 'Suzano Papel E Celul'
  },
  {
    Ticker: 'SWCH',
    Company: 'Switch Inc'
  },
  {
    Ticker: 'SWI',
    Company: 'Solarwinds Corp'
  },
  {
    Ticker: 'SWJ',
    Company: 'Stanley Black & Decker Inc'
  },
  {
    Ticker: 'SWK',
    Company: 'Stanley Black & Decker Inc'
  },
  {
    Ticker: 'SWM',
    Company: 'Schweitzer-Mauduit International'
  },
  {
    Ticker: 'SWN',
    Company: 'Southwestern Energy Company'
  },
  {
    Ticker: 'SWP',
    Company: 'Stanley Black & Decker Inc'
  },
  {
    Ticker: 'SWT',
    Company: 'Stanley Black & Decker Inc'
  },
  {
    Ticker: 'SWX',
    Company: 'Southwest Gas Corp'
  },
  {
    Ticker: 'SWZ',
    Company: 'Swiss Helvetia Fund'
  },
  {
    Ticker: 'SXC',
    Company: 'Suncoke Energy Inc'
  },
  {
    Ticker: 'SXI',
    Company: 'Standex International Corp'
  },
  {
    Ticker: 'SXT',
    Company: 'Sensient Technologies Corp'
  },
  {
    Ticker: 'SYF',
    Company: 'Synchrony Financial'
  },
  {
    Ticker: 'SYF-A',
    Company: 'Synchrony Financial 5.625% Prf Perpetual USD 25'
  },
  {
    Ticker: 'SYK',
    Company: 'Stryker Corp'
  },
  {
    Ticker: 'SYX',
    Company: 'Systemax Inc'
  },
  {
    Ticker: 'SYY',
    Company: 'Sysco Corp'
  },
  {
    Ticker: 'SZC',
    Company: 'The Cushing Renaissance Fund'
  },
  {
    Ticker: 'T',
    Company: 'AT&T Inc'
  },
  {
    Ticker: 'TAC',
    Company: 'Transalta Corp'
  },
  {
    Ticker: 'TAK',
    Company: 'Takeda Pharmaceutical Co. Ltd'
  },
  {
    Ticker: 'TAL',
    Company: 'Tal Education Group'
  },
  {
    Ticker: 'TALO',
    Company: 'Talos Energy Inc'
  },
  {
    Ticker: 'TAP',
    Company: 'Molson Coors Brewing Company'
  },
  {
    Ticker: 'TAP.A',
    Company: 'Molson Coors Brewing Cl A'
  },
  {
    Ticker: 'TARO',
    Company: 'Taro Pharm Inds'
  },
  {
    Ticker: 'TBB',
    Company: 'AT&T Inc 5.350% Global Notes Due 2066'
  },
  {
    Ticker: 'TBC',
    Company: 'AT&T Inc 5.625% Global Notes Due 2067'
  },
  {
    Ticker: 'TBI',
    Company: 'Trueblue Inc'
  },
  {
    Ticker: 'TCI',
    Company: 'Transcontinental Realty Investors'
  },
  {
    Ticker: 'TCO',
    Company: 'Taubman Centers'
  },
  {
    Ticker: 'TCO-J',
    Company: 'Taubman Ctrs Inc [Tco/Pj]'
  },
  {
    Ticker: 'TCO-K',
    Company: 'Taubman Ctrs Inc [Tco/Pk]'
  },
  {
    Ticker: 'TCP',
    Company: 'TCP Pipelines'
  },
  {
    Ticker: 'TCRW',
    Company: 'THL Credit Inc 6.125% Notes Due 2023'
  },
  {
    Ticker: 'TCRZ',
    Company: 'THL Credit Inc'
  },
  {
    Ticker: 'TCS',
    Company: 'The Container Store Group Inc'
  },
  {
    Ticker: 'TD',
    Company: 'Toronto Dominion Bank'
  },
  {
    Ticker: 'TDA',
    Company: 'Telephone and Data Systems Inc'
  },
  {
    Ticker: 'TDC',
    Company: 'Teradata Corp'
  },
  {
    Ticker: 'TDE',
    Company: 'Telephone and Data Systems'
  },
  {
    Ticker: 'TDF',
    Company: 'Templeton Dragon Fund'
  },
  {
    Ticker: 'TDG',
    Company: 'Transdigm Group Inc'
  },
  {
    Ticker: 'TDI',
    Company: 'Telephone and Data Systems'
  },
  {
    Ticker: 'TDJ',
    Company: 'Telephone and Data Systems Inc'
  },
  {
    Ticker: 'TDOC',
    Company: 'Teladoc Health Inc'
  },
  {
    Ticker: 'TDS',
    Company: 'Telephone and Data Systems'
  },
  {
    Ticker: 'TDW',
    Company: 'Tidewater Inc'
  },
  {
    Ticker: 'TDW.A',
    Company: 'Tidewater Inc [Tdw/Wa]'
  },
  {
    Ticker: 'TDW.B',
    Company: 'Tidewater Inc [Tdw/Wb]'
  },
  {
    Ticker: 'TDY',
    Company: 'Teledyne Technologies Inc'
  },
  {
    Ticker: 'TEAF',
    Company: 'Tortoise Essential Assets Income Term Fund'
  },
  {
    Ticker: 'TECK',
    Company: 'Teck Resources Ltd'
  },
  {
    Ticker: 'TEF',
    Company: 'Telefonica S.A.'
  },
  {
    Ticker: 'TEI',
    Company: 'Templeton Emerging Markets'
  },
  {
    Ticker: 'TEL',
    Company: 'Te Connectivity Ltd'
  },
  {
    Ticker: 'TEN',
    Company: 'Tenneco Automotive'
  },
  {
    Ticker: 'TEO',
    Company: 'Telecom Argentina Stet France Telecom S.A.'
  },
  {
    Ticker: 'TEVA',
    Company: 'Teva Pharmaceutical Industries Ltd'
  },
  {
    Ticker: 'TEX',
    Company: 'Terex Corp'
  },
  {
    Ticker: 'TFC',
    Company: 'Truist Financial Corp.'
  },
  {
    Ticker: 'TFC-F',
    Company: 'Truist Financial Corp [Tfc/Pf]'
  },
  {
    Ticker: 'TFC-G',
    Company: 'Truist Financial Corp. [Tfc/Pg]'
  },
  {
    Ticker: 'TFC-H',
    Company: 'Truist Financial Corp. [Tfc/Ph]'
  },
  {
    Ticker: 'TFC-I',
    Company: 'Truist Financial Corporation Depositary Shares'
  },
  {
    Ticker: 'TFX',
    Company: 'Teleflex Inc'
  },
  {
    Ticker: 'TG',
    Company: 'Tredegar Corp'
  },
  {
    Ticker: 'TGE',
    Company: 'Tallgrass Energy Gp LP'
  },
  {
    Ticker: 'TGH',
    Company: 'Textainer Group Holdings'
  },
  {
    Ticker: 'TGI',
    Company: 'Triumph Group'
  },
  {
    Ticker: 'TGNA',
    Company: 'Tegna Inc'
  },
  {
    Ticker: 'TGP',
    Company: 'Teekay Lng Partners LP'
  },
  {
    Ticker: 'TGP-A',
    Company: 'Teekay Lng Partners LP [Tgp/Pa]'
  },
  {
    Ticker: 'TGP-B',
    Company: 'Teekay Lng Partners LP [Tgp/Pb]'
  },
  {
    Ticker: 'TGS',
    Company: 'Transportadora DE Gas Sa Ord B'
  },
  {
    Ticker: 'TGT',
    Company: 'Target Corp'
  },
  {
    Ticker: 'THC',
    Company: 'Tenet Healthcare Corp'
  },
  {
    Ticker: 'THG',
    Company: 'The Hanover Insurance Group'
  },
  {
    Ticker: 'THGA',
    Company: 'The Hanover Insurance Group'
  },
  {
    Ticker: 'THO',
    Company: 'Thor Industries'
  },
  {
    Ticker: 'THQ',
    Company: 'Tekla Healthcare Opportunities'
  },
  {
    Ticker: 'THR',
    Company: 'Thermon Group Holdings Inc'
  },
  {
    Ticker: 'THS',
    Company: 'Treehouse Foods'
  },
  {
    Ticker: 'THW',
    Company: 'Tekla World Healthcare Fund'
  },
  {
    Ticker: 'TIF',
    Company: 'Tiffany & Company'
  },
  {
    Ticker: 'TISI',
    Company: 'Team Inc'
  },
  {
    Ticker: 'TJX',
    Company: 'TJX Companies'
  },
  {
    Ticker: 'TK',
    Company: 'Teekay Shipping Corp'
  },
  {
    Ticker: 'TKC',
    Company: 'Turkcell Iletisim Hizmetleri As'
  },
  {
    Ticker: 'TKR',
    Company: 'Timken Company'
  },
  {
    Ticker: 'TLI',
    Company: 'Lmp Corporate Loan Fund Inc'
  },
  {
    Ticker: 'TLK',
    Company: 'P.T. Telekomunikasi Indonesia Tbk'
  },
  {
    Ticker: 'TLRA',
    Company: 'Telaria Inc'
  },
  {
    Ticker: 'TLRD',
    Company: 'Tailored Brands Inc'
  },
  {
    Ticker: 'TLYS',
    Company: "Tilly's Inc"
  },
  {
    Ticker: 'TM',
    Company: 'Toyota Motor Corp Ltd Ord'
  },
  {
    Ticker: 'TME',
    Company: 'Tencent Music Entertainment Group ADR'
  },
  {
    Ticker: 'TMHC',
    Company: 'Taylor Morrison Home Corp'
  },
  {
    Ticker: 'TMO',
    Company: 'Thermo Fisher Scientific Inc'
  },
  {
    Ticker: 'TMST',
    Company: 'Timken Steel Corp'
  },
  {
    Ticker: 'TNC',
    Company: 'Tennant Company'
  },
  {
    Ticker: 'TNET',
    Company: 'Trinet Group Inc'
  },
  {
    Ticker: 'TNK',
    Company: 'Teekay Tankers Ltd'
  },
  {
    Ticker: 'TNP',
    Company: 'Tsakos Energy Navigation Ltd'
  },
  {
    Ticker: 'TNP-C',
    Company: 'Tsakos Energy Navigation [Tnp/Pc]'
  },
  {
    Ticker: 'TNP-D',
    Company: 'Tsakos Energy Navigation [Tnp/Pd]'
  },
  {
    Ticker: 'TNP-E',
    Company: 'Tsakos Energy Navigation [Tnp/Pe]'
  },
  {
    Ticker: 'TNP-F',
    Company: 'Tsakos Energy Navigation [Tnp/Pf]'
  },
  {
    Ticker: 'TOL',
    Company: 'Toll Brothers Inc'
  },
  {
    Ticker: 'TOO',
    Company: 'Teekay Offshore Partners LP'
  },
  {
    Ticker: 'TOO-A',
    Company: 'Teekay Offshore Partners LP [Too/Pa]'
  },
  {
    Ticker: 'TOO-B',
    Company: 'Teekay Offshore Partners LP [Too/Pb]'
  },
  {
    Ticker: 'TOO-E',
    Company: 'Teekay Offshore Partners LP [Too/Pe]'
  },
  {
    Ticker: 'TOT',
    Company: 'Totalfinaelf S.A.'
  },
  {
    Ticker: 'TPB',
    Company: 'Turning Point Brands'
  },
  {
    Ticker: 'TPC',
    Company: 'Tutor Perini Corp'
  },
  {
    Ticker: 'TPG.H',
    Company: 'Tpg Pace Hlds Corp [Tpgh.U]'
  },
  {
    Ticker: 'TPG.I',
    Company: 'Tpg Pace Hlds Corp [Tpgh/W]'
  },
  {
    Ticker: 'TPGH',
    Company: 'Tpg Pace Holdings Corp Cl A'
  },
  {
    Ticker: 'TPH',
    Company: 'Tri Pointe Homes Inc'
  },
  {
    Ticker: 'TPL',
    Company: 'Texas Pacific Land Trust'
  },
  {
    Ticker: 'TPR',
    Company: 'Tapestry Inc'
  },
  {
    Ticker: 'TPRE',
    Company: 'Third Point Reinsurance Ltd Company'
  },
  {
    Ticker: 'TPVG',
    Company: 'Triplepoint Venture Growth Bdc'
  },
  {
    Ticker: 'TPVY',
    Company: 'Triplepoint Venture Growth Bdc Corp 5.75% Notes'
  },
  {
    Ticker: 'TPX',
    Company: 'Tempur-Pedic International Inc'
  },
  {
    Ticker: 'TPZ',
    Company: 'Tortoise Power and Energy'
  },
  {
    Ticker: 'TR',
    Company: 'Tootsie Roll Industries'
  },
  {
    Ticker: 'TRC',
    Company: 'Tejon Ranch Company'
  },
  {
    Ticker: 'TREC',
    Company: 'Trecora Resources'
  },
  {
    Ticker: 'TREX',
    Company: 'Trex Company'
  },
  {
    Ticker: 'TRGP',
    Company: 'Targa Resources'
  },
  {
    Ticker: 'TRI',
    Company: 'Thomson Reuters Corp'
  },
  {
    Ticker: 'TRN',
    Company: 'Trinity Industries'
  },
  {
    Ticker: 'TRN.U',
    Company: 'Trine Acquisition Corp [Trne.U]'
  },
  {
    Ticker: 'TRN.W',
    Company: 'Trine Acquisition Corp WT [Trne/W]'
  },
  {
    Ticker: 'TRNE',
    Company: 'Trine Acquisition Corp Cl A'
  },
  {
    Ticker: 'TRNO',
    Company: 'Terreno Realty Corp'
  },
  {
    Ticker: 'TROX',
    Company: 'Tronox Inc'
  },
  {
    Ticker: 'TRP',
    Company: 'Transcananda Pipelines'
  },
  {
    Ticker: 'TRQ',
    Company: 'Turquoise Hill Resources Ltd'
  },
  {
    Ticker: 'TRT-A',
    Company: 'Triton Intl Ltd [Trtn/Pa]'
  },
  {
    Ticker: 'TRT-B',
    Company: 'Triton Intl Ltd [Trtn/Pb]'
  },
  {
    Ticker: 'TRT-C',
    Company: 'Triton International Ltd 7.375% Prf Perpetual US'
  },
  {
    Ticker: 'TRTN',
    Company: 'Triton International Ltd'
  },
  {
    Ticker: 'TRTX',
    Company: 'Tpg Re Finance Trust Inc'
  },
  {
    Ticker: 'TRU',
    Company: 'Transunion'
  },
  {
    Ticker: 'TRV',
    Company: 'The Travelers Companies Inc'
  },
  {
    Ticker: 'TRWH',
    Company: 'Twin River Worldwide Holdings Inc'
  },
  {
    Ticker: 'TS',
    Company: 'Tenaris S.A.'
  },
  {
    Ticker: 'TSE',
    Company: 'Trinseo S.A.'
  },
  {
    Ticker: 'TSI',
    Company: 'Tcw Strategic'
  },
  {
    Ticker: 'TSLF',
    Company: 'THL Credit Senior Loan Fund'
  },
  {
    Ticker: 'TSLX',
    Company: 'Tpg Specialty Lending Inc'
  },
  {
    Ticker: 'TSM',
    Company: 'Taiwan Semiconductor Manufacturing'
  },
  {
    Ticker: 'TSN',
    Company: 'Tyson Foods'
  },
  {
    Ticker: 'TSQ',
    Company: 'Townsquare Media Llc'
  },
  {
    Ticker: 'TSU',
    Company: 'Tim Participacoes S.A.'
  },
  {
    Ticker: 'TTC',
    Company: 'Toro Company'
  },
  {
    Ticker: 'TTI',
    Company: 'Tetra Technologies'
  },
  {
    Ticker: 'TTM',
    Company: 'Tata Motors Ltd'
  },
  {
    Ticker: 'TTP',
    Company: 'Tortoise Pipeline & Energy Fund'
  },
  {
    Ticker: 'TU',
    Company: 'Telus Corp Non Voting Shares'
  },
  {
    Ticker: 'TUFN',
    Company: 'Tufin Software Technologies Ltd'
  },
  {
    Ticker: 'TUP',
    Company: 'Tupperware Corp'
  },
  {
    Ticker: 'TV',
    Company: 'Grupo Televisa S.A.'
  },
  {
    Ticker: 'TVC',
    Company: 'Tennessee Valley Authority'
  },
  {
    Ticker: 'TVE',
    Company: 'Tennessee Valley Authority'
  },
  {
    Ticker: 'TWI',
    Company: 'Titan International'
  },
  {
    Ticker: 'TWLO',
    Company: 'Twilio'
  },
  {
    Ticker: 'TWN',
    Company: 'Taiwan Fund'
  },
  {
    Ticker: 'TWO',
    Company: 'Two Harbors Investments Corp'
  },
  {
    Ticker: 'TWO-A',
    Company: 'Two Hbrs Invt Corp [Two/Pa]'
  },
  {
    Ticker: 'TWO-B',
    Company: 'Two Hbrs Invt Corp [Two/Pb]'
  },
  {
    Ticker: 'TWO-C',
    Company: 'Two Hbrs Invt Corp [Two/Pc]'
  },
  {
    Ticker: 'TWO-D',
    Company: 'Two Hbrs Invt Corp [Two/Pd]'
  },
  {
    Ticker: 'TWO-E',
    Company: 'Two Hbrs Invt Corp [Two/Pe]'
  },
  {
    Ticker: 'TWTR',
    Company: 'Twitter Inc'
  },
  {
    Ticker: 'TX',
    Company: 'Ternium S.A.'
  },
  {
    Ticker: 'TXT',
    Company: 'Textron Inc'
  },
  {
    Ticker: 'TY',
    Company: 'Tri Continental Corp'
  },
  {
    Ticker: 'TY.P',
    Company: 'Tri Contl Corp [Ty/P]'
  },
  {
    Ticker: 'TYG',
    Company: 'Tortoise Energy Infrastructure Corp'
  },
  {
    Ticker: 'TYL',
    Company: 'Tyler Technologies'
  },
  {
    Ticker: 'UA',
    Company: 'Under Armour Inc Cl C'
  },
  {
    Ticker: 'UAA',
    Company: 'Under Armour'
  },
  {
    Ticker: 'UAN',
    Company: 'Cvr Partners LP'
  },
  {
    Ticker: 'UBA',
    Company: 'Urstadt Biddle Properties Inc'
  },
  {
    Ticker: 'UBER',
    Company: 'Uber Technologies Inc'
  },
  {
    Ticker: 'UBP',
    Company: 'Urstadt Biddle Properties Inc'
  },
  {
    Ticker: 'UBP-H',
    Company: 'Urstadt Biddle Pptys Inc [Ubp/Ph]'
  },
  {
    Ticker: 'UBP-K',
    Company: 'Urstadt Biddle Properties Inc 5.875% Prf Perpetu'
  },
  {
    Ticker: 'UBS',
    Company: 'UBS Group Ag'
  },
  {
    Ticker: 'UDR',
    Company: 'United Dominion Realty Trust'
  },
  {
    Ticker: 'UE',
    Company: 'Urban Edge Properties'
  },
  {
    Ticker: 'UFI',
    Company: 'Unifi Inc'
  },
  {
    Ticker: 'UFS',
    Company: 'Domtar Corp'
  },
  {
    Ticker: 'UGI',
    Company: 'Ugi Corp'
  },
  {
    Ticker: 'UGP',
    Company: 'Ultrapar Participacoes S.A.'
  },
  {
    Ticker: 'UHS',
    Company: 'Universal Health Services'
  },
  {
    Ticker: 'UHT',
    Company: 'Universal Health Realty Income Trust'
  },
  {
    Ticker: 'UI',
    Company: 'Ubiquiti Networks'
  },
  {
    Ticker: 'UIS',
    Company: 'Unisys Corp'
  },
  {
    Ticker: 'UL',
    Company: 'Unilever Plc'
  },
  {
    Ticker: 'UMC',
    Company: 'United Microelectronics Corp'
  },
  {
    Ticker: 'UMH',
    Company: 'Umh Properties'
  },
  {
    Ticker: 'UMH-B',
    Company: 'Umh Pptys Inc [Umh/Pb]'
  },
  {
    Ticker: 'UMH-C',
    Company: 'Umh Pptys Inc [Umh/Pc]'
  },
  {
    Ticker: 'UMH-D',
    Company: 'Umh Pptys Inc [Umh/Pd]'
  },
  {
    Ticker: 'UN',
    Company: 'Unilever NV'
  },
  {
    Ticker: 'UNF',
    Company: 'Unifirst Corp'
  },
  {
    Ticker: 'UNFI',
    Company: 'United Nat Foods'
  },
  {
    Ticker: 'UNH',
    Company: 'Unitedhealth Group Inc'
  },
  {
    Ticker: 'UNM',
    Company: 'Unumprovident Corp'
  },
  {
    Ticker: 'UNMA',
    Company: 'Unum Group 6.250% Junior Subordinated Notes Due'
  },
  {
    Ticker: 'UNP',
    Company: 'Union Pacific Corp'
  },
  {
    Ticker: 'UNT',
    Company: 'Unit Corp'
  },
  {
    Ticker: 'UNVR',
    Company: 'Univar Inc'
  },
  {
    Ticker: 'UPS',
    Company: 'United Parcel Service'
  },
  {
    Ticker: 'URI',
    Company: 'United Rentals'
  },
  {
    Ticker: 'USA',
    Company: 'Liberty All-Star Equity Fund'
  },
  {
    Ticker: 'USAC',
    Company: 'USA Compression Partners LP'
  },
  {
    Ticker: 'USB',
    Company: 'U.S. Bancorp'
  },
  {
    Ticker: 'USB-A',
    Company: 'US Bancorp [Usb/Pa]'
  },
  {
    Ticker: 'USB-H',
    Company: 'US Bancorp [Usb/Ph]'
  },
  {
    Ticker: 'USB-M',
    Company: 'US Bancorp [Usb/Pm]'
  },
  {
    Ticker: 'USB-O',
    Company: 'US Bancorp [Usb/Po]'
  },
  {
    Ticker: 'USB-P',
    Company: 'US Bancorp Del [Usb/Pp]'
  },
  {
    Ticker: 'USDP',
    Company: 'USD Partners LP'
  },
  {
    Ticker: 'USFD',
    Company: 'US Foods Holding'
  },
  {
    Ticker: 'USM',
    Company: 'United States Cellular Corp'
  },
  {
    Ticker: 'USNA',
    Company: 'Usana Health Sciences Inc'
  },
  {
    Ticker: 'USPH',
    Company: 'U.S. Physical Therapy'
  },
  {
    Ticker: 'USX',
    Company: 'U.S. Xpress Enterprises Inc Cl A'
  },
  {
    Ticker: 'UTF',
    Company: 'Cohen & Steers Infrastructure Fund'
  },
  {
    Ticker: 'UTI',
    Company: 'Universal Technical Institute Inc'
  },
  {
    Ticker: 'UTL',
    Company: 'Unitil Corp'
  },
  {
    Ticker: 'UTX',
    Company: 'United Technologies Corp'
  },
  {
    Ticker: 'UVE',
    Company: 'Universal Insurance Holdings Inc'
  },
  {
    Ticker: 'UVV',
    Company: 'Universal Corp'
  },
  {
    Ticker: 'UZA',
    Company: 'United States Cellular Corp'
  },
  {
    Ticker: 'UZB',
    Company: 'United States Cellular Corp'
  },
  {
    Ticker: 'UZC',
    Company: 'United States Cellular Corp'
  },
  {
    Ticker: 'V',
    Company: 'Visa Inc'
  },
  {
    Ticker: 'VAC',
    Company: 'Marriot Vacations Worldwide Cor'
  },
  {
    Ticker: 'VAL',
    Company: 'Valaris Plc'
  },
  {
    Ticker: 'VALE',
    Company: 'Vale S.A.'
  },
  {
    Ticker: 'VAM',
    Company: 'The Vivaldi Opportunities Fund'
  },
  {
    Ticker: 'VAPO',
    Company: 'Vapotherm Inc'
  },
  {
    Ticker: 'VAR',
    Company: 'Varian Medical Systems'
  },
  {
    Ticker: 'VBF',
    Company: 'Invesco Van Kampen Bond Fund'
  },
  {
    Ticker: 'VCIF',
    Company: 'Vertical Capital Income Fund Common Shares of BE'
  },
  {
    Ticker: 'VCRA',
    Company: 'Vocera Communications Inc'
  },
  {
    Ticker: 'VCV',
    Company: 'Invesco California Value Muni Income Trust'
  },
  {
    Ticker: 'VEC',
    Company: 'Vectrus Inc'
  },
  {
    Ticker: 'VEDL',
    Company: 'Vedanta Ltd'
  },
  {
    Ticker: 'VEEV',
    Company: 'Veeva Systems Inc'
  },
  {
    Ticker: 'VER',
    Company: 'Vereit Inc'
  },
  {
    Ticker: 'VER-F',
    Company: 'Vereit Inc [Ver/Pf]'
  },
  {
    Ticker: 'VET',
    Company: 'Vermilion Energy Inc'
  },
  {
    Ticker: 'VFC',
    Company: 'V.F. Corp'
  },
  {
    Ticker: 'VG',
    Company: 'Vonage Holdings'
  },
  {
    Ticker: 'VGI',
    Company: 'Virtus Global Multi-Sector Inc'
  },
  {
    Ticker: 'VGM',
    Company: 'Invesco Trust For Investment Grade Municipals'
  },
  {
    Ticker: 'VGR',
    Company: 'Vector Group Ltd'
  },
  {
    Ticker: 'VHI',
    Company: 'Valhi Inc'
  },
  {
    Ticker: 'VICI',
    Company: 'Vici Properties Inc'
  },
  {
    Ticker: 'VIPS',
    Company: 'Vipshop Holdings Ltd'
  },
  {
    Ticker: 'VIST',
    Company: 'Vista Oil & Gas S.A.B. DE C.V.'
  },
  {
    Ticker: 'VIV',
    Company: 'Telecomunicacoes DE Sao Paulo ADR'
  },
  {
    Ticker: 'VJET',
    Company: 'Voxeljet Ag'
  },
  {
    Ticker: 'VKQ',
    Company: 'Invesco Muni Trust'
  },
  {
    Ticker: 'VLO',
    Company: 'Valero Energy Corp'
  },
  {
    Ticker: 'VLRS',
    Company: 'Controladora Vuela Compaia DE'
  },
  {
    Ticker: 'VLT',
    Company: 'Invesco High'
  },
  {
    Ticker: 'VMC',
    Company: 'Vulcan Materials Company'
  },
  {
    Ticker: 'VMI',
    Company: 'Valmont Industries'
  },
  {
    Ticker: 'VMO',
    Company: 'Invesco Muni Opportunity Trust'
  },
  {
    Ticker: 'VMW',
    Company: 'Vmware Inc'
  },
  {
    Ticker: 'VNCE',
    Company: 'Vince Holding Corp'
  },
  {
    Ticker: 'VNE',
    Company: 'Veoneer Inc'
  },
  {
    Ticker: 'VNO',
    Company: 'Vornado Realty Trust'
  },
  {
    Ticker: 'VNO-K',
    Company: 'Vornado Rlty TR [Vno/Pk]'
  },
  {
    Ticker: 'VNO-L',
    Company: 'Vornado Rlty TR [Vno/Pl]'
  },
  {
    Ticker: 'VNO-M',
    Company: 'Vornado Rlty TR [Vno/Pm]'
  },
  {
    Ticker: 'VNTR',
    Company: 'Venator Materials Plc'
  },
  {
    Ticker: 'VOC',
    Company: 'Voc Energy Trust'
  },
  {
    Ticker: 'VOY-B',
    Company: 'VOYA Finl Inc [Voya/Pb]'
  },
  {
    Ticker: 'VOYA',
    Company: 'VOYA Financial Inc'
  },
  {
    Ticker: 'VPG',
    Company: 'Vishay Precision Group'
  },
  {
    Ticker: 'VPV',
    Company: 'Invesco Pennsylvania Muni'
  },
  {
    Ticker: 'VRS',
    Company: 'Verso Corp'
  },
  {
    Ticker: 'VRTV',
    Company: 'Veritiv Corp'
  },
  {
    Ticker: 'VSH',
    Company: 'Vishay Intertechnology'
  },
  {
    Ticker: 'VSI',
    Company: 'Vitamin Shoppe Inc'
  },
  {
    Ticker: 'VSLR',
    Company: 'Vivint Solar Inc'
  },
  {
    Ticker: 'VST',
    Company: 'Vistra Energy Corp'
  },
  {
    Ticker: 'VST.A',
    Company: 'Vistra Energy Corp [Vst/Wa]'
  },
  {
    Ticker: 'VSTO',
    Company: 'Vista Outdoor Inc'
  },
  {
    Ticker: 'VTA',
    Company: 'Invesco Dynamic Credit Fund'
  },
  {
    Ticker: 'VTN',
    Company: 'Invesco Trust New York Muni'
  },
  {
    Ticker: 'VTR',
    Company: 'Ventas Inc'
  },
  {
    Ticker: 'VVI',
    Company: 'Viad Corp'
  },
  {
    Ticker: 'VVR',
    Company: 'Invesco Senior Income Trust'
  },
  {
    Ticker: 'VVV',
    Company: 'Valvoline Inc'
  },
  {
    Ticker: 'VZ',
    Company: 'Verizon Communications Inc'
  },
  {
    Ticker: 'W',
    Company: 'Wayfair Inc'
  },
  {
    Ticker: 'WAAS',
    Company: 'Aquaventure Holdings Ltd'
  },
  {
    Ticker: 'WAB',
    Company: 'Wabtec Corp'
  },
  {
    Ticker: 'WAIR',
    Company: 'Wesco Aircraft Holdings Inc'
  },
  {
    Ticker: 'WAL',
    Company: 'Western Alliance Bancorp'
  },
  {
    Ticker: 'WALA',
    Company: 'Western Alliance Bancorp'
  },
  {
    Ticker: 'WAT',
    Company: 'Waters Corp'
  },
  {
    Ticker: 'WBAI',
    Company: '500Wan.com Ltd'
  },
  {
    Ticker: 'WBC',
    Company: 'Wabco Holdings Inc'
  },
  {
    Ticker: 'WBK',
    Company: 'Westpac Banking Corp'
  },
  {
    Ticker: 'WBS',
    Company: 'Webster Financial Corp'
  },
  {
    Ticker: 'WBS-F',
    Company: 'Webster Financial Corp [Wbs/Pf]'
  },
  {
    Ticker: 'WBT',
    Company: 'Welbilt Inc'
  },
  {
    Ticker: 'WCC',
    Company: 'Wesco International'
  },
  {
    Ticker: 'WCG',
    Company: 'Wellcare Group'
  },
  {
    Ticker: 'WCN',
    Company: 'Waste Connections Inc'
  },
  {
    Ticker: 'WD',
    Company: 'Walker & Dunlop'
  },
  {
    Ticker: 'WDR',
    Company: 'Waddell & Reed Financial'
  },
  {
    Ticker: 'WEA',
    Company: 'Western Asset Bond Fund'
  },
  {
    Ticker: 'WEC',
    Company: 'Wisconsin Energy Corp'
  },
  {
    Ticker: 'WEI',
    Company: 'Weidai Ltd ADR'
  },
  {
    Ticker: 'WELL',
    Company: 'Welltower Inc'
  },
  {
    Ticker: 'WES',
    Company: 'Western Midstream Partners LP'
  },
  {
    Ticker: 'WEX',
    Company: 'Wex Inc'
  },
  {
    Ticker: 'WF',
    Company: 'Woori Finance Holdings Co. Ltd'
  },
  {
    Ticker: 'WFC',
    Company: 'Wells Fargo & Company'
  },
  {
    Ticker: 'WFC-L',
    Company: 'Wells Fargo & Company [Wfc/Pl]'
  },
  {
    Ticker: 'WFC-N',
    Company: 'Wells Fargo & Company [Wfc/Pn]'
  },
  {
    Ticker: 'WFC-O',
    Company: 'Wells Fargo & Company [Wfc/Po]'
  },
  {
    Ticker: 'WFC-P',
    Company: 'Wells Fargo & Company [Wfc/Pp]'
  },
  {
    Ticker: 'WFC-Q',
    Company: 'Wells Fargo & Company [Wfc/Pq]'
  },
  {
    Ticker: 'WFC-R',
    Company: 'Wells Fargo & Company [Wfc/Pr]'
  },
  {
    Ticker: 'WFC-T',
    Company: 'Wells Fargo & Company [Wfc/Pt]'
  },
  {
    Ticker: 'WFC-V',
    Company: 'Wells Fargo & Company [Wfc/Pv]'
  },
  {
    Ticker: 'WFC-W',
    Company: 'Wells Fargo & Company [Wfc/Pw]'
  },
  {
    Ticker: 'WFC-X',
    Company: 'Wells Fargo & Company [Wfc/Px]'
  },
  {
    Ticker: 'WFC-Y',
    Company: 'Wells Fargo & Company [Wfc/Py]'
  },
  {
    Ticker: 'WFE-A',
    Company: 'Wells Fargo Real Estate Invt Corp [Wfe/Pa]'
  },
  {
    Ticker: 'WGO',
    Company: 'Winnebago Industries'
  },
  {
    Ticker: 'WH',
    Company: 'Wyndham Hotels & Resorts Inc'
  },
  {
    Ticker: 'WHD',
    Company: 'Cactus Inc Cl A'
  },
  {
    Ticker: 'WHG',
    Company: 'Westwood Holdings Group Inc'
  },
  {
    Ticker: 'WHR',
    Company: 'Whirlpool Corp'
  },
  {
    Ticker: 'WIA',
    Company: 'U.S. Treasury Inflation Prot Secs Fd'
  },
  {
    Ticker: 'WIT',
    Company: 'Wipro Ltd'
  },
  {
    Ticker: 'WIW',
    Company: 'U.S Treasury Inflation Prot Secs Fd 2'
  },
  {
    Ticker: 'WK',
    Company: 'Workiva Llc'
  },
  {
    Ticker: 'WLH',
    Company: 'William Lyon Homes'
  },
  {
    Ticker: 'WLK',
    Company: 'Westlake Chemical Corp'
  },
  {
    Ticker: 'WLKP',
    Company: 'Westlake Chemical Partners LP'
  },
  {
    Ticker: 'WLL',
    Company: 'Whiting Petroleum Corp'
  },
  {
    Ticker: 'WM',
    Company: 'Waste Management'
  },
  {
    Ticker: 'WMB',
    Company: 'Williams Companies'
  },
  {
    Ticker: 'WMC',
    Company: 'Western Asset Mortgage Capital'
  },
  {
    Ticker: 'WMK',
    Company: 'Weis Markets'
  },
  {
    Ticker: 'WMS',
    Company: 'Advanced Drainage Systems Inc'
  },
  {
    Ticker: 'WMT',
    Company: 'Wal-Mart Stores'
  },
  {
    Ticker: 'WNC',
    Company: 'Wabash National Corp'
  },
  {
    Ticker: 'WNS',
    Company: 'Wns Ltd'
  },
  {
    Ticker: 'WOR',
    Company: 'Worthington Industries'
  },
  {
    Ticker: 'WORK',
    Company: 'Slack Technologies Inc'
  },
  {
    Ticker: 'WOW',
    Company: 'Wideopenwest Inc'
  },
  {
    Ticker: 'WPC',
    Company: 'W.P. Carey & Co. Llc'
  },
  {
    Ticker: 'WPG',
    Company: 'Washington Prime Group Inc'
  },
  {
    Ticker: 'WPG-H',
    Company: 'Washington Prime Group [Wpg/Ph]'
  },
  {
    Ticker: 'WPG-I',
    Company: 'Washington Prime Group [Wpg/Pi]'
  },
  {
    Ticker: 'WPM',
    Company: 'Wheaton Precious Metals'
  },
  {
    Ticker: 'WPP',
    Company: 'Wpp Plc ADR'
  },
  {
    Ticker: 'WPX',
    Company: 'Wpx Energy Inc'
  },
  {
    Ticker: 'WRB',
    Company: 'W.R. Berkley Corp'
  },
  {
    Ticker: 'WRB-B',
    Company: 'Berkley[W.R.]Corp [Wrb/Pb]'
  },
  {
    Ticker: 'WRB-C',
    Company: 'Berkley[W.R.]Corp [Wrb/Pc]'
  },
  {
    Ticker: 'WRB-D',
    Company: 'Berkley[W.R.]Corp [Wrb/Pd]'
  },
  {
    Ticker: 'WRB-E',
    Company: 'Berkley[W.R.]Corp [Wrb/Pe]'
  },
  {
    Ticker: 'WRE',
    Company: 'Washington Real Estate Investment'
  },
  {
    Ticker: 'WRI',
    Company: 'Weingarten Realty Investors'
  },
  {
    Ticker: 'WRK',
    Company: 'Westrock Company'
  },
  {
    Ticker: 'WSM',
    Company: 'Williams-Sonoma'
  },
  {
    Ticker: 'WSO',
    Company: 'Watsco Inc'
  },
  {
    Ticker: 'WSO.B',
    Company: 'Watsco Inc Cl B'
  },
  {
    Ticker: 'WSR',
    Company: 'Whitestone REIT'
  },
  {
    Ticker: 'WST',
    Company: 'West Pharmaceutical Services'
  },
  {
    Ticker: 'WTI',
    Company: 'W&T Offshore'
  },
  {
    Ticker: 'WTM',
    Company: 'White Mountains Insurance Group'
  },
  {
    Ticker: 'WTR',
    Company: 'Aqua America'
  },
  {
    Ticker: 'WTRU',
    Company: 'Aqua America Inc 6.00% Tangible Equity Units'
  },
  {
    Ticker: 'WTS',
    Company: 'Watts Water Technologies'
  },
  {
    Ticker: 'WTTR',
    Company: 'Select Energy Services Inc'
  },
  {
    Ticker: 'WU',
    Company: 'Western Union Company'
  },
  {
    Ticker: 'WUBA',
    Company: '58.com Inc'
  },
  {
    Ticker: 'WWE',
    Company: 'World Wrestling Entertainment'
  },
  {
    Ticker: 'WWW',
    Company: 'Wolverine World Wide'
  },
  {
    Ticker: 'WY',
    Company: 'Weyerhaeuser Company'
  },
  {
    Ticker: 'WYND',
    Company: 'Wyndham Destinations Inc'
  },
  {
    Ticker: 'X',
    Company: 'United States Steel Corp'
  },
  {
    Ticker: 'XAN',
    Company: 'Exantas Capital Corp'
  },
  {
    Ticker: 'XAN-C',
    Company: 'Exantas Cap Corp [Xan/Pc]'
  },
  {
    Ticker: 'XEC',
    Company: 'Cimarex Energy Company'
  },
  {
    Ticker: 'XFLT',
    Company: 'Xai Octagon Floating Alt Income Term Trust Compa'
  },
  {
    Ticker: 'XHR',
    Company: 'Xenia Hotels & Resorts Inc'
  },
  {
    Ticker: 'XIN',
    Company: 'Xinyuan Real Estate Co. Ltd'
  },
  {
    Ticker: 'XOM',
    Company: 'Exxon Mobil Corp'
  },
  {
    Ticker: 'XPO',
    Company: 'Xpo Logistics Inc'
  },
  {
    Ticker: 'XRF',
    Company: 'China Rapid Finance Ltd ADR'
  },
  {
    Ticker: 'XRX',
    Company: 'Xerox Corp'
  },
  {
    Ticker: 'XYF',
    Company: 'X Financial'
  },
  {
    Ticker: 'XYL',
    Company: 'Xylem Inc'
  },
  {
    Ticker: 'Y',
    Company: 'Alleghany Corp'
  },
  {
    Ticker: 'YELP',
    Company: 'Yelp Inc'
  },
  {
    Ticker: 'YETI',
    Company: 'Yeti Holdings Inc'
  },
  {
    Ticker: 'YEXT',
    Company: 'Yext Inc'
  },
  {
    Ticker: 'YPF',
    Company: 'Ypf Sociedad Anonima'
  },
  {
    Ticker: 'YRD',
    Company: 'Yirendai Ltd'
  },
  {
    Ticker: 'YUM',
    Company: 'Yum! Brands'
  },
  {
    Ticker: 'YUMC',
    Company: 'Yum China Holdings Inc'
  },
  {
    Ticker: 'ZAYO',
    Company: 'Zayo Group Holdings Inc'
  },
  {
    Ticker: 'ZB-A',
    Company: 'Zions Bancorp N A [Zb/Pa]'
  },
  {
    Ticker: 'ZB-G',
    Company: 'Zions Bancorp N A [Zb/Pg]'
  },
  {
    Ticker: 'ZB-H',
    Company: 'Zions Bancorp N A [Zb/Ph]'
  },
  {
    Ticker: 'ZBH',
    Company: 'Zimmer Biomet Holdings'
  },
  {
    Ticker: 'ZBK',
    Company: 'Zion Bancorp'
  },
  {
    Ticker: 'ZEN',
    Company: 'Zendesk Inc'
  },
  {
    Ticker: 'ZF',
    Company: 'Zweig Fund'
  },
  {
    Ticker: 'ZNH',
    Company: 'China Southern Airlines Company'
  },
  {
    Ticker: 'ZTO',
    Company: 'Zto Express [Cayman] Inc'
  },
  {
    Ticker: 'ZTR',
    Company: 'Virtus Global Divd & Income Fund'
  },
  {
    Ticker: 'ZTS',
    Company: 'Zoetis Inc Cl A'
  },
  {
    Ticker: 'ZUO',
    Company: 'Zuora Inc'
  },
  {
    Ticker: 'ZYME',
    Company: 'Zymeworks Inc'
  },
  {
    Ticker: 'AAL',
    Company: 'American Airlines Gp'
  },
  {
    Ticker: 'AAME',
    Company: 'Atlantic Amer Cp'
  },
  {
    Ticker: 'AAOI',
    Company: 'Applied Optoelect'
  },
  {
    Ticker: 'AAON',
    Company: 'Aaon Inc'
  },
  {
    Ticker: 'AAPL',
    Company: 'Apple Inc'
  },
  {
    Ticker: 'AAWW',
    Company: 'Atlas Air Ww'
  },
  {
    Ticker: 'AAXJ',
    Company: 'All Country Asia Ex Japan Ishares MSCI ETF'
  },
  {
    Ticker: 'AAXN',
    Company: 'Axon Inc'
  },
  {
    Ticker: 'ABCB',
    Company: 'Ameris Bancorp'
  },
  {
    Ticker: 'ABDC',
    Company: 'Alcentra Capital Com'
  },
  {
    Ticker: 'ABEO',
    Company: 'Abeona Therapeutics'
  },
  {
    Ticker: 'ABEOW',
    Company: 'Abeona Thera WT'
  },
  {
    Ticker: 'ABIL',
    Company: 'Ability Inc'
  },
  {
    Ticker: 'ABIO',
    Company: 'Arca Biopharma Inc'
  },
  {
    Ticker: 'ABMD',
    Company: 'Abiomed Inc'
  },
  {
    Ticker: 'ABTX',
    Company: 'Allegiance Banc CS'
  },
  {
    Ticker: 'ABUS',
    Company: 'Arbutus Biopharma Cp'
  },
  {
    Ticker: 'ACAD',
    Company: 'Acadia Pharmaceutica'
  },
  {
    Ticker: 'ACAM',
    Company: 'Acamar Partners Acquisition Corp Cl A'
  },
  {
    Ticker: 'ACAMU',
    Company: 'Acamar Partners Acquisition Corp Units'
  },
  {
    Ticker: 'ACAMW',
    Company: 'Acamar Partners Acquisition Corp WT'
  },
  {
    Ticker: 'ACBI',
    Company: 'Atlantic Capital'
  },
  {
    Ticker: 'ACER',
    Company: 'Acer Therapeutics Inc'
  },
  {
    Ticker: 'ACGL',
    Company: 'Arch Capital Grp Ltd'
  },
  {
    Ticker: 'ACGLO',
    Company: 'Arch Capital Group Ltd ADR'
  },
  {
    Ticker: 'ACGLP',
    Company: 'Arch Capital Group Ltd'
  },
  {
    Ticker: 'ACHC',
    Company: 'Acadia Healthcr Company'
  },
  {
    Ticker: 'ACHN',
    Company: 'Achillion Pharmaceuticals'
  },
  {
    Ticker: 'ACHV',
    Company: 'Achieve Life Sciences Inc'
  },
  {
    Ticker: 'ACIA',
    Company: 'Acacia Communica'
  },
  {
    Ticker: 'ACIU',
    Company: 'AC Immune S.A.'
  },
  {
    Ticker: 'ACIW',
    Company: 'Aci Worldwide Inc'
  },
  {
    Ticker: 'ACLS',
    Company: 'Axcelis Tech Inc'
  },
  {
    Ticker: 'ACMR',
    Company: 'Acm Research Inc'
  },
  {
    Ticker: 'ACNB',
    Company: 'Acnb Corp'
  },
  {
    Ticker: 'ACOR',
    Company: 'Acorda Therapeutics'
  },
  {
    Ticker: 'ACRS',
    Company: 'Aclaris Therapts'
  },
  {
    Ticker: 'ACRX',
    Company: 'Acelrx Pharmaceutica'
  },
  {
    Ticker: 'ACST',
    Company: 'Acasti Pharma'
  },
  {
    Ticker: 'ACT',
    Company: 'Advisorshares Vice ETF'
  },
  {
    Ticker: 'ACTG',
    Company: 'Acacia Res-Acacia'
  },
  {
    Ticker: 'ACTT',
    Company: 'Act II Global Acquisition Corp Cl A'
  },
  {
    Ticker: 'ACTTU',
    Company: 'Act II Global Acquisition Corp Unit'
  },
  {
    Ticker: 'ACTTW',
    Company: 'Act II Global Acquisition Corp WT'
  },
  {
    Ticker: 'ACWI',
    Company: 'ACWI Ishares MSCI ETF'
  },
  {
    Ticker: 'ACWX',
    Company: 'ACWI Ex US Ishares MSCI ETF'
  },
  {
    Ticker: 'ADAP',
    Company: 'Adaptimmune Ther Ads'
  },
  {
    Ticker: 'ADBE',
    Company: 'Adobe Systems Inc'
  },
  {
    Ticker: 'ADES',
    Company: 'Advanced Emissions Solutions Inc'
  },
  {
    Ticker: 'ADI',
    Company: 'Analog Devices'
  },
  {
    Ticker: 'ADIL',
    Company: 'Adial Pharmaceuticals Inc'
  },
  {
    Ticker: 'ADILW',
    Company: 'Adial Pharmaceuticals Inc WT'
  },
  {
    Ticker: 'ADMA',
    Company: 'Adma Biologics'
  },
  {
    Ticker: 'ADMP',
    Company: 'Adamis Pharmaceuticl'
  },
  {
    Ticker: 'ADMS',
    Company: 'Adamas Pharma'
  },
  {
    Ticker: 'ADP',
    Company: 'Automatic Data Procs'
  },
  {
    Ticker: 'ADPT',
    Company: 'Adaptive Biotechnologies Corp'
  },
  {
    Ticker: 'ADRA',
    Company: 'Bldrs Asia 50 ADR Index Fund Invesco'
  },
  {
    Ticker: 'ADRD',
    Company: 'Bldrs Developed Markets 100 ADR Index Fund Inves'
  },
  {
    Ticker: 'ADRE',
    Company: 'Bldrs Emerging Markets 50 ADR Index Fund Invesco'
  },
  {
    Ticker: 'ADRO',
    Company: 'Aduro Biotech'
  },
  {
    Ticker: 'ADRU',
    Company: 'Bldrs Europe Select ADR Index Fund Invesco'
  },
  {
    Ticker: 'ADSK',
    Company: 'Autodesk Inc'
  },
  {
    Ticker: 'ADTN',
    Company: 'Adtran Inc'
  },
  {
    Ticker: 'ADUS',
    Company: 'Addus Homecare Corp'
  },
  {
    Ticker: 'ADVM',
    Company: 'Adverum Biotechnlgs'
  },
  {
    Ticker: 'ADXS',
    Company: 'Advaxis Inc'
  },
  {
    Ticker: 'AEGN',
    Company: 'Aegion Corp'
  },
  {
    Ticker: 'AEHR',
    Company: 'Aehr Test Systems'
  },
  {
    Ticker: 'AEIS',
    Company: 'Advanced Energy'
  },
  {
    Ticker: 'AEMD',
    Company: 'Aethlon Medical'
  },
  {
    Ticker: 'AERI',
    Company: 'Aerie Pharmaceuticals'
  },
  {
    Ticker: 'AESE',
    Company: 'Allied Esports Entertainment Inc'
  },
  {
    Ticker: 'AEY',
    Company: 'Addvantage Techs Group'
  },
  {
    Ticker: 'AEYE',
    Company: 'Audioeye Inc'
  },
  {
    Ticker: 'AEZS',
    Company: 'Aeterna Zentaris'
  },
  {
    Ticker: 'AFH',
    Company: 'Atlas Financial Hlds'
  },
  {
    Ticker: 'AFIN',
    Company: 'American Finance Trust Inc Cl A'
  },
  {
    Ticker: 'AFINP',
    Company: 'American Finance Trust Inc Pfd Ser A'
  },
  {
    Ticker: 'AFMD',
    Company: 'Affimed N.V.'
  },
  {
    Ticker: 'AFYA',
    Company: 'Afya Ltd Cl A'
  },
  {
    Ticker: 'AGBA',
    Company: 'Agba Acquisition Limited'
  },
  {
    Ticker: 'AGBAU',
    Company: 'Agba Acquisition Ltd Unit'
  },
  {
    Ticker: 'AGBAW',
    Company: 'Agba Acquisition Ltd WT'
  },
  {
    Ticker: 'AGEN',
    Company: 'Agenus Inc'
  },
  {
    Ticker: 'AGFS',
    Company: 'Agrofresh Solutions'
  },
  {
    Ticker: 'AGFSW',
    Company: 'Agrofresh Sol Wrrnts'
  },
  {
    Ticker: 'AGIO',
    Company: 'Agios Pharmaceuticals'
  },
  {
    Ticker: 'AGLE',
    Company: 'Aeglea Biothera'
  },
  {
    Ticker: 'AGMH',
    Company: 'Agm Group Holdings Inc Cl A'
  },
  {
    Ticker: 'AGNC',
    Company: 'American Capital Age'
  },
  {
    Ticker: 'AGNCB',
    Company: 'American Cap Agy Cor'
  },
  {
    Ticker: 'AGNCM',
    Company: 'Agnc Investment Corp Cum Red Pfd Ser D Fixed To'
  },
  {
    Ticker: 'AGNCN',
    Company: 'Agnc Investment Corp'
  },
  {
    Ticker: 'AGNCO',
    Company: 'Agnc Investment Corp Fxdfr Prf Perpetual USD 25'
  },
  {
    Ticker: 'AGND',
    Company: 'Wisdomtree Neg Duration US Agg Bond Fund'
  },
  {
    Ticker: 'AGRX',
    Company: 'Agile Therap'
  },
  {
    Ticker: 'AGTC',
    Company: 'Applied Genetic Tech'
  },
  {
    Ticker: 'AGYS',
    Company: 'Agilysys Inc'
  },
  {
    Ticker: 'AGZD',
    Company: 'Wisdomtree Int Rate US Agg Bond Fund'
  },
  {
    Ticker: 'AHCO',
    Company: 'Adapthealth Corp. Cl. A'
  },
  {
    Ticker: 'AHCOW',
    Company: 'Adapthealth Corp. WT'
  },
  {
    Ticker: 'AHPI',
    Company: 'Allied Healthcare'
  },
  {
    Ticker: 'AIA',
    Company: 'Asia 50 Ishares ETF'
  },
  {
    Ticker: 'AIH',
    Company: 'Aesthetic Medical International Holdings Group L'
  },
  {
    Ticker: 'AIHS',
    Company: 'Senmiao Technology Ltd'
  },
  {
    Ticker: 'AIMC',
    Company: 'Altra Indtl Mtn'
  },
  {
    Ticker: 'AIMT',
    Company: 'Aimmune Therap'
  },
  {
    Ticker: 'AINV',
    Company: 'Apollo Investment Company'
  },
  {
    Ticker: 'AIQ',
    Company: 'Global X Future Analytics Tech ETF'
  },
  {
    Ticker: 'AIRG',
    Company: 'Airgain Inc'
  },
  {
    Ticker: 'AIRR',
    Company: 'Rba American Industrial Renaissance ETF FT'
  },
  {
    Ticker: 'AIRT',
    Company: 'Air T Inc'
  },
  {
    Ticker: 'AIRTP',
    Company: 'Air T Inc Funding Alpha Income Trust Preferred'
  },
  {
    Ticker: 'AIRTW',
    Company: 'Air T Inc Funding WT'
  },
  {
    Ticker: 'AKAM',
    Company: 'Akamai Technologies'
  },
  {
    Ticker: 'AKBA',
    Company: 'Akebia Therapeutics'
  },
  {
    Ticker: 'AKCA',
    Company: 'Akcea Therapeutics Inc'
  },
  {
    Ticker: 'AKER',
    Company: 'Akers Biosciences'
  },
  {
    Ticker: 'AKRO',
    Company: 'Akero Therapeutics Inc'
  },
  {
    Ticker: 'AKRX',
    Company: 'Akorn Inc'
  },
  {
    Ticker: 'AKTS',
    Company: 'Akoustis Technologies Inc'
  },
  {
    Ticker: 'AKTX',
    Company: 'Akari Therapeutics'
  },
  {
    Ticker: 'ALAC',
    Company: 'Alberton Acquisition Corp'
  },
  {
    Ticker: 'ALACR',
    Company: 'Alberton Acquisition Corp Rights'
  },
  {
    Ticker: 'ALACU',
    Company: 'Alberton Acquisition Corp Unit'
  },
  {
    Ticker: 'ALACW',
    Company: 'Alberton Acquisition Corp WT'
  },
  {
    Ticker: 'ALBO',
    Company: 'Albireo Pharma Inc'
  },
  {
    Ticker: 'ALCO',
    Company: 'Alico Inc'
  },
  {
    Ticker: 'ALDX',
    Company: 'Aldeyra Therapeu'
  },
  {
    Ticker: 'ALEC',
    Company: 'Alector Inc'
  },
  {
    Ticker: 'ALGN',
    Company: 'Align Technology'
  },
  {
    Ticker: 'ALGR',
    Company: 'Allegro Merger Corp'
  },
  {
    Ticker: 'ALGRR',
    Company: 'Allegro Merger Corp Right'
  },
  {
    Ticker: 'ALGRU',
    Company: 'Allegro Merger Corp Unit'
  },
  {
    Ticker: 'ALGRW',
    Company: 'Allegro Merger Corp WT'
  },
  {
    Ticker: 'ALGT',
    Company: 'Allegiant Travel Com'
  },
  {
    Ticker: 'ALIM',
    Company: 'Alimera Sciences Inc'
  },
  {
    Ticker: 'ALJJ',
    Company: 'Alj Regional Hold'
  },
  {
    Ticker: 'ALKS',
    Company: 'Alkermes Plc'
  },
  {
    Ticker: 'ALLK',
    Company: 'Allakos Inc'
  },
  {
    Ticker: 'ALLO',
    Company: 'Allogene Therapeutics Inc'
  },
  {
    Ticker: 'ALLT',
    Company: 'Allot Communications'
  },
  {
    Ticker: 'ALNA',
    Company: 'Allena Pharmaceuticals Inc'
  },
  {
    Ticker: 'ALNY',
    Company: 'Alnylam Pharmaceuticals'
  },
  {
    Ticker: 'ALOT',
    Company: 'Astronova Inc'
  },
  {
    Ticker: 'ALPN',
    Company: 'Alpine Immune Sciences Inc'
  },
  {
    Ticker: 'ALRM',
    Company: 'Alarm.com'
  },
  {
    Ticker: 'ALRN',
    Company: 'Aileron Therapeutics Inc'
  },
  {
    Ticker: 'ALRS',
    Company: 'Alerus Financial Corp'
  },
  {
    Ticker: 'ALSK',
    Company: 'Alaska Commun Sys'
  },
  {
    Ticker: 'ALT',
    Company: 'Altimmune Inc'
  },
  {
    Ticker: 'ALTM',
    Company: 'Altus Midstream Company'
  },
  {
    Ticker: 'ALTR',
    Company: 'Altair Engineering Inc Cl A'
  },
  {
    Ticker: 'ALTY',
    Company: 'G-X Super Dividend Alternatives ETF'
  },
  {
    Ticker: 'ALXN',
    Company: 'Alexion Pharm Inc'
  },
  {
    Ticker: 'ALYA',
    Company: 'Alithya Group Inc Cl A'
  },
  {
    Ticker: 'AMAG',
    Company: 'Amag Pharmaceuticals'
  },
  {
    Ticker: 'AMAL',
    Company: 'Amalgamated Bk'
  },
  {
    Ticker: 'AMAT',
    Company: 'Applied Materials'
  },
  {
    Ticker: 'AMBA',
    Company: 'Ambarella Inc'
  },
  {
    Ticker: 'AMBC',
    Company: 'Ambac Financial Group'
  },
  {
    Ticker: 'AMBCW',
    Company: 'Ambac Financial Group'
  },
  {
    Ticker: 'AMCA',
    Company: 'Russell 1000 Pure US Rev Ishares ETF'
  },
  {
    Ticker: 'AMCI',
    Company: 'Amci Acquisition Corp Cl A'
  },
  {
    Ticker: 'AMCIU',
    Company: 'Amci Acquisition Corp Unit'
  },
  {
    Ticker: 'AMCIW',
    Company: 'Amci Acquisition Corp WT'
  },
  {
    Ticker: 'AMCX',
    Company: 'Amc Networks Cl A'
  },
  {
    Ticker: 'AMD',
    Company: 'Adv Micro Devices'
  },
  {
    Ticker: 'AMED',
    Company: 'Amedisys Inc'
  },
  {
    Ticker: 'AMEH',
    Company: 'Apollo Medical Holdings Inc'
  },
  {
    Ticker: 'AMGN',
    Company: 'Amgen Inc'
  },
  {
    Ticker: 'AMHCU',
    Company: 'Amplitude Healthcare Acquisition Corp'
  },
  {
    Ticker: 'AMKR',
    Company: 'Amkor Technology'
  },
  {
    Ticker: 'AMNB',
    Company: 'American Natl Bksh'
  },
  {
    Ticker: 'AMOT',
    Company: 'Allied Motion Tech'
  },
  {
    Ticker: 'AMPH',
    Company: 'Amphastar Pharma'
  },
  {
    Ticker: 'AMRB',
    Company: 'American River Bkshs'
  },
  {
    Ticker: 'AMRH',
    Company: 'Ameri Holdings Inc'
  },
  {
    Ticker: 'AMRHW',
    Company: 'Ameri Holdings Inc WT'
  },
  {
    Ticker: 'AMRK',
    Company: 'A-Mark Precious Meta'
  },
  {
    Ticker: 'AMRN',
    Company: 'Amarin Corp Ads'
  },
  {
    Ticker: 'AMRS',
    Company: 'Amyris Inc'
  },
  {
    Ticker: 'AMSC',
    Company: 'Amer Superconductor'
  },
  {
    Ticker: 'AMSF',
    Company: 'Amerisafe Inc'
  },
  {
    Ticker: 'AMSWA',
    Company: 'Amer Software Inc'
  },
  {
    Ticker: 'AMTB',
    Company: 'Mercantil Bank Holding Cl A'
  },
  {
    Ticker: 'AMTBB',
    Company: 'Mercantil Bank Holding Cl B'
  },
  {
    Ticker: 'AMTD',
    Company: 'TD Ameritrade HD'
  },
  {
    Ticker: 'AMTX',
    Company: 'Aemetis Inc'
  },
  {
    Ticker: 'AMWD',
    Company: 'Amer Woodmark Cp'
  },
  {
    Ticker: 'AMZN',
    Company: 'Amazon.com Inc'
  },
  {
    Ticker: 'ANAB',
    Company: 'Anaptysbio Inc'
  },
  {
    Ticker: 'ANAT',
    Company: 'Amer Natl Insurance'
  },
  {
    Ticker: 'ANCN',
    Company: 'Anchiano Therapeutics Ltd ADR'
  },
  {
    Ticker: 'ANDA',
    Company: 'Andina Acquisition Corp III'
  },
  {
    Ticker: 'ANDAR',
    Company: 'Andina Acquisition Corp III Right'
  },
  {
    Ticker: 'ANDAU',
    Company: 'Andina Acquisition Corp III Unit'
  },
  {
    Ticker: 'ANDAW',
    Company: 'Andina Acquisition Corp III WT'
  },
  {
    Ticker: 'ANDE',
    Company: 'Andersons Inc'
  },
  {
    Ticker: 'ANGI',
    Company: 'Homeservices Inc'
  },
  {
    Ticker: 'ANGL',
    Company: 'Fallen Angel HY Bond ETF Vaneck'
  },
  {
    Ticker: 'ANGO',
    Company: 'Angiodynamics Inc'
  },
  {
    Ticker: 'ANIK',
    Company: 'Anika Therapeutics'
  },
  {
    Ticker: 'ANIP',
    Company: 'ANI Pharma Inc'
  },
  {
    Ticker: 'ANIX',
    Company: 'Anixa Biosciences Inc'
  },
  {
    Ticker: 'ANSS',
    Company: 'Ansys Inc'
  },
  {
    Ticker: 'ANTE',
    Company: 'Airnet Technology Inc'
  },
  {
    Ticker: 'ANY',
    Company: 'Sphere 3D Corp'
  },
  {
    Ticker: 'AOBC',
    Company: 'American Outdoor Brands Corp'
  },
  {
    Ticker: 'AOSL',
    Company: 'Alpha and Omega Semi'
  },
  {
    Ticker: 'APDN',
    Company: 'Applied Dna Scns'
  },
  {
    Ticker: 'APDNW',
    Company: 'Applied Dna Sci WT'
  },
  {
    Ticker: 'APEI',
    Company: 'American Public Eduducation'
  },
  {
    Ticker: 'APEN',
    Company: 'Apollo Endosurgery Inc'
  },
  {
    Ticker: 'APEX',
    Company: 'Apex Global Brands Inc'
  },
  {
    Ticker: 'APLS',
    Company: 'Apellis Pharmaceuticals Inc'
  },
  {
    Ticker: 'APLT',
    Company: 'Applied Therapeutics Inc'
  },
  {
    Ticker: 'APM',
    Company: 'Aptorum Group Ltd Cl A'
  },
  {
    Ticker: 'APOG',
    Company: 'Apogee Entrpr Inc'
  },
  {
    Ticker: 'APOP',
    Company: 'Cellect Biotechnology Ltd'
  },
  {
    Ticker: 'APOPW',
    Company: 'Cellect Biotechnology Ltd'
  },
  {
    Ticker: 'APPF',
    Company: 'Appfolio Cl A'
  },
  {
    Ticker: 'APPN',
    Company: 'Appian Corp Cl A'
  },
  {
    Ticker: 'APPS',
    Company: 'Digital Turbine'
  },
  {
    Ticker: 'APRE',
    Company: 'Aprea Therapeutics Inc'
  },
  {
    Ticker: 'APTO',
    Company: 'Aptose Bioscns'
  },
  {
    Ticker: 'APTX',
    Company: 'Aptinyx Inc'
  },
  {
    Ticker: 'APVO',
    Company: 'Aptevo Therapeutics Inc'
  },
  {
    Ticker: 'APWC',
    Company: 'Asia Pacific Wire & Cable'
  },
  {
    Ticker: 'APXT',
    Company: 'Apex Technology Acquisition Corporation Cl A'
  },
  {
    Ticker: 'APXTU',
    Company: 'Apex Technology Acquisition Corp'
  },
  {
    Ticker: 'APXTW',
    Company: 'Apex Technology Acquisition Corporation WT'
  },
  {
    Ticker: 'APYX',
    Company: 'Apyx Medical Corp'
  },
  {
    Ticker: 'AQB',
    Company: 'Aquabounty Technologies Inc'
  },
  {
    Ticker: 'AQMS',
    Company: 'Aqua Metals Inc'
  },
  {
    Ticker: 'AQST',
    Company: 'Aquestive Therapeutics Inc'
  },
  {
    Ticker: 'ARAV',
    Company: 'Aravive Inc'
  },
  {
    Ticker: 'ARAY',
    Company: 'Accuray Incorporated'
  },
  {
    Ticker: 'ARCB',
    Company: 'Arcbest Corp'
  },
  {
    Ticker: 'ARCC',
    Company: 'Ares Capital Corp'
  },
  {
    Ticker: 'ARCE',
    Company: 'Arco Platform Ltd Cl A'
  },
  {
    Ticker: 'ARCT',
    Company: 'Arcturus Therapeutics Ltd'
  },
  {
    Ticker: 'ARDS',
    Company: 'Aridis Pharmaceuticals Inc'
  },
  {
    Ticker: 'ARDX',
    Company: 'Ardelyx Inc'
  },
  {
    Ticker: 'AREC',
    Company: 'American Resources Corp'
  },
  {
    Ticker: 'ARGX',
    Company: 'Argenx Se Ads'
  },
  {
    Ticker: 'ARKR',
    Company: 'Ark Restaurants Cp'
  },
  {
    Ticker: 'ARLP',
    Company: 'Alliance Resource Pt'
  },
  {
    Ticker: 'ARNA',
    Company: 'Arena Pharmaceuticals'
  },
  {
    Ticker: 'AROW',
    Company: 'Arrow Financial Corp'
  },
  {
    Ticker: 'ARPO',
    Company: 'Aerpio Pharmaceuticals Inc'
  },
  {
    Ticker: 'ARQL',
    Company: 'Arqule Inc'
  },
  {
    Ticker: 'ARTL',
    Company: 'Artelo Biosciences Inc'
  },
  {
    Ticker: 'ARTLW',
    Company: 'Artelo Biosciences Inc WT'
  },
  {
    Ticker: 'ARTNA',
    Company: 'Artesian Res Cp A'
  },
  {
    Ticker: 'ARTW',
    Company: 'Art S Way MFG Company'
  },
  {
    Ticker: 'ARTX',
    Company: 'Arotech Corp'
  },
  {
    Ticker: 'ARVN',
    Company: 'Arvinas Inc'
  },
  {
    Ticker: 'ARWR',
    Company: 'Arrowhead Pharma'
  },
  {
    Ticker: 'ARYA',
    Company: 'Arya Sciences Acquisiton Corp Cl A'
  },
  {
    Ticker: 'ARYAU',
    Company: 'Arya Sciences Acquisiton Corp Unit'
  },
  {
    Ticker: 'ARYAW',
    Company: 'Arya Sciences Acquisiton Corp WT'
  },
  {
    Ticker: 'ASET',
    Company: 'Flexshares Real Assets Allocation Index Fund'
  },
  {
    Ticker: 'ASFI',
    Company: 'Asta Funding Inc'
  },
  {
    Ticker: 'ASLN',
    Company: 'Aslan Pharmaceuticals Ltd ADR'
  },
  {
    Ticker: 'ASMB',
    Company: 'Assembly Biosciences'
  },
  {
    Ticker: 'ASML',
    Company: 'Asml Hld NY Reg'
  },
  {
    Ticker: 'ASNA',
    Company: 'Ascena Retail Group'
  },
  {
    Ticker: 'ASND',
    Company: 'Ascendis Pharma Ads'
  },
  {
    Ticker: 'ASPS',
    Company: 'Altisource Portfolio'
  },
  {
    Ticker: 'ASPU',
    Company: 'Aspen Group Inc'
  },
  {
    Ticker: 'ASRT',
    Company: 'Assertio Therapeutics Inc'
  },
  {
    Ticker: 'ASRV',
    Company: 'Ameriserv Financial'
  },
  {
    Ticker: 'ASRVP',
    Company: 'Ameriserv Fin Cap'
  },
  {
    Ticker: 'ASTC',
    Company: 'Astrotech Corp'
  },
  {
    Ticker: 'ASTE',
    Company: 'Astec Inds Inc'
  },
  {
    Ticker: 'ASUR',
    Company: 'Asure Software'
  },
  {
    Ticker: 'ASYS',
    Company: 'Amtech Systems Inc'
  },
  {
    Ticker: 'ATAX',
    Company: 'Amer First Mf Inv'
  },
  {
    Ticker: 'ATEC',
    Company: 'Alphatec Holdings'
  },
  {
    Ticker: 'ATEX',
    Company: 'Pdvwireless'
  },
  {
    Ticker: 'ATHE',
    Company: 'Alterity Therapeutics Ltd'
  },
  {
    Ticker: 'ATHX',
    Company: 'Athersys Inc'
  },
  {
    Ticker: 'ATIF',
    Company: 'Atif Holdings Limited'
  },
  {
    Ticker: 'ATIS',
    Company: 'Attis Industries Inc'
  },
  {
    Ticker: 'ATLC',
    Company: 'Atlanticus Hld Cp'
  },
  {
    Ticker: 'ATLO',
    Company: 'Ames Natl Corp'
  },
  {
    Ticker: 'ATNI',
    Company: 'Atn International'
  },
  {
    Ticker: 'ATNX',
    Company: 'Athenex Inc'
  },
  {
    Ticker: 'ATOM',
    Company: 'Atomera Inc'
  },
  {
    Ticker: 'ATOS',
    Company: 'Atossa Genetics Inc'
  },
  {
    Ticker: 'ATRA',
    Company: 'Atara Biotherap'
  },
  {
    Ticker: 'ATRC',
    Company: 'Atricure Inc'
  },
  {
    Ticker: 'ATRI',
    Company: 'Atrion Corp'
  },
  {
    Ticker: 'ATRO',
    Company: 'Astronics Cp'
  },
  {
    Ticker: 'ATRS',
    Company: 'Antares Pharma'
  },
  {
    Ticker: 'ATSG',
    Company: 'Air Transport'
  },
  {
    Ticker: 'ATVI',
    Company: 'Activision Blizzard'
  },
  {
    Ticker: 'ATXI',
    Company: 'Avenue Therapeutics Inc'
  },
  {
    Ticker: 'AUB',
    Company: 'Atlantic Union Bancshares Corp'
  },
  {
    Ticker: 'AUBN',
    Company: 'Auburn Natl Bncp'
  },
  {
    Ticker: 'AUDC',
    Company: 'Audiocodes Ltd'
  },
  {
    Ticker: 'AUPH',
    Company: 'Aurinia Pharm Ord'
  },
  {
    Ticker: 'AUTL',
    Company: 'Autolus Therapeutics Plc ADR'
  },
  {
    Ticker: 'AUTO',
    Company: 'Autoweb Inc'
  },
  {
    Ticker: 'AVAV',
    Company: 'Aerovironment Inc'
  },
  {
    Ticker: 'AVCO',
    Company: 'Avalon Globocare'
  },
  {
    Ticker: 'AVDL',
    Company: 'Avadel Pharmaceuticals Plc'
  },
  {
    Ticker: 'AVDR',
    Company: 'Avedro Inc'
  },
  {
    Ticker: 'AVEO',
    Company: 'Aveo Pharmaceuticals'
  },
  {
    Ticker: 'AVGO',
    Company: 'Broadcom Ltd'
  },
  {
    Ticker: 'AVGOP',
    Company: 'Broadcom Inc 8% Prf Undated USD 1000 Ser A'
  },
  {
    Ticker: 'AVGR',
    Company: 'Avinger Inc'
  },
  {
    Ticker: 'AVID',
    Company: 'Avid Tech Inc'
  },
  {
    Ticker: 'AVNW',
    Company: 'Aviat Networks Inc'
  },
  {
    Ticker: 'AVRO',
    Company: 'Avrobio Inc'
  },
  {
    Ticker: 'AVT',
    Company: 'Avnet Inc'
  },
  {
    Ticker: 'AVXL',
    Company: 'Anavex Lf SC'
  },
  {
    Ticker: 'AWRE',
    Company: 'Aware Inc'
  },
  {
    Ticker: 'AXAS',
    Company: 'Abraxas Petro Corp'
  },
  {
    Ticker: 'AXDX',
    Company: 'Accelerate Diagnosti'
  },
  {
    Ticker: 'AXGN',
    Company: 'Axogen Inc'
  },
  {
    Ticker: 'AXGT',
    Company: 'Axovant Sciences Ltd'
  },
  {
    Ticker: 'AXLA',
    Company: 'Axcella Health Inc'
  },
  {
    Ticker: 'AXNX',
    Company: 'Axonics Modulation Technologies Inc'
  },
  {
    Ticker: 'AXSM',
    Company: 'Axsome Thera'
  },
  {
    Ticker: 'AXTI',
    Company: 'Axt Inc'
  },
  {
    Ticker: 'AY',
    Company: 'Atlantica Yield Plc'
  },
  {
    Ticker: 'AYTU',
    Company: 'Aytu Bioscience Inc'
  },
  {
    Ticker: 'AZPN',
    Company: 'Aspen Technology'
  },
  {
    Ticker: 'AZRX',
    Company: 'Azurrx Biopharma Inc'
  },
  {
    Ticker: 'BAND',
    Company: 'Bandwidth Inc'
  },
  {
    Ticker: 'BANF',
    Company: 'Bancfirst Corp'
  },
  {
    Ticker: 'BANFP',
    Company: 'Bfc Capital Trust II'
  },
  {
    Ticker: 'BANR',
    Company: 'Banner Corp'
  },
  {
    Ticker: 'BANX',
    Company: 'Stonecastle Fncl Cp'
  },
  {
    Ticker: 'BASI',
    Company: 'Bioanalytical Syst'
  },
  {
    Ticker: 'BATRA',
    Company: 'Liberty Braves CS A'
  },
  {
    Ticker: 'BATRK',
    Company: 'Liberty Braves CS C'
  },
  {
    Ticker: 'BBBY',
    Company: 'Bed Bath & Beyond'
  },
  {
    Ticker: 'BBCP',
    Company: 'Concrete Pumping Holdings Inc'
  },
  {
    Ticker: 'BBGI',
    Company: 'Beasley Brdcst Gr'
  },
  {
    Ticker: 'BBH',
    Company: 'Biotech Vaneck ETF'
  },
  {
    Ticker: 'BBI',
    Company: 'Brickell Biotech Inc'
  },
  {
    Ticker: 'BBIO',
    Company: 'Bridgebio Pharma Inc'
  },
  {
    Ticker: 'BBQ',
    Company: 'Bbq Holdings Inc'
  },
  {
    Ticker: 'BBSI',
    Company: 'Barrett Business S'
  },
  {
    Ticker: 'BCBP',
    Company: 'Bcb Bancorp Inc'
  },
  {
    Ticker: 'BCDA',
    Company: 'Biocardia Inc'
  },
  {
    Ticker: 'BCDAW',
    Company: 'Biocardia Inc WT'
  },
  {
    Ticker: 'BCEL',
    Company: 'Atreca Inc Cl A'
  },
  {
    Ticker: 'BCLI',
    Company: 'Brainstorm Cell'
  },
  {
    Ticker: 'BCML',
    Company: 'Bay Commercial Bk CA'
  },
  {
    Ticker: 'BCOM',
    Company: 'B Communications Ltd'
  },
  {
    Ticker: 'BCOR',
    Company: 'Blucora Inc'
  },
  {
    Ticker: 'BCOV',
    Company: 'Brightcove Inc'
  },
  {
    Ticker: 'BCOW',
    Company: '1895 Bancorp of Wisconsin Inc'
  },
  {
    Ticker: 'BCPC',
    Company: 'Balchem Cp'
  },
  {
    Ticker: 'BCRX',
    Company: 'Biocryst Pharma Inc'
  },
  {
    Ticker: 'BCTF',
    Company: 'Bancorp 34 Inc'
  },
  {
    Ticker: 'BCYC',
    Company: 'Bicycle Therapeutics Plc ADR'
  },
  {
    Ticker: 'BDGE',
    Company: 'Bridge Bancorp Inc'
  },
  {
    Ticker: 'BDSI',
    Company: 'Biodelivery Sci Intl'
  },
  {
    Ticker: 'BEAT',
    Company: 'Biotelemetry Inc'
  },
  {
    Ticker: 'BECN',
    Company: 'Beacon Roofing Suppl'
  },
  {
    Ticker: 'BELFA',
    Company: 'Bel Fuse Cl A'
  },
  {
    Ticker: 'BELFB',
    Company: 'Bel Fuse Inc Cl B'
  },
  {
    Ticker: 'BFC',
    Company: 'Bank First National Corp [Wisconsin]'
  },
  {
    Ticker: 'BFIN',
    Company: 'Bankfinancial Corp'
  },
  {
    Ticker: 'BFIT',
    Company: 'G-X Health & Wellness Thematic Et'
  },
  {
    Ticker: 'BFRA',
    Company: 'Biofrontera Ag ADR'
  },
  {
    Ticker: 'BFST',
    Company: 'Business First Bancshares Inc'
  },
  {
    Ticker: 'BGCP',
    Company: 'Bgc Partners Cl A'
  },
  {
    Ticker: 'BGFV',
    Company: 'Big 5 Sporting'
  },
  {
    Ticker: 'BGNE',
    Company: 'Beigene Ltd Ads'
  },
  {
    Ticker: 'BGRN',
    Company: 'Ishares Global Green Bond ETF'
  },
  {
    Ticker: 'BHAT',
    Company: 'Blue Hat Interactive Entertainment Tech'
  },
  {
    Ticker: 'BHF',
    Company: 'Brighthouse Financial Inc'
  },
  {
    Ticker: 'BHFAL',
    Company: 'Brighthouse Financial Inc 6.25% Junior Subordi'
  },
  {
    Ticker: 'BHFAP',
    Company: 'Brighthouse Financial Inc Dep Shs Repstg 1/1000T'
  },
  {
    Ticker: 'BHTG',
    Company: 'Biohitech Global Inc'
  },
  {
    Ticker: 'BIB',
    Company: 'Ultra Nasdaq Biotech Proshares'
  },
  {
    Ticker: 'BICK',
    Company: 'BICK Index Fund FT'
  },
  {
    Ticker: 'BIDU',
    Company: 'Baidu Inc'
  },
  {
    Ticker: 'BIIB',
    Company: 'Biogen Inc'
  },
  {
    Ticker: 'BILI',
    Company: 'Bilibili Inc ADR'
  },
  {
    Ticker: 'BIMI',
    Company: 'NF Energy Saving Corp'
  },
  {
    Ticker: 'BIOC',
    Company: 'Biocept Inc'
  },
  {
    Ticker: 'BIOL',
    Company: 'Biolase Inc'
  },
  {
    Ticker: 'BIOS',
    Company: 'Option Care Health Inc'
  },
  {
    Ticker: 'BIS',
    Company: 'Ultrashort Biotechnology Proshares'
  },
  {
    Ticker: 'BJK',
    Company: 'Gaming ETF Vaneck'
  },
  {
    Ticker: 'BJRI',
    Company: "Bj's Restaurants Inc"
  },
  {
    Ticker: 'BKCC',
    Company: 'Blackrock Cptl Invt'
  },
  {
    Ticker: 'BKEP',
    Company: 'Blueknight Energy LP'
  },
  {
    Ticker: 'BKEPP',
    Company: 'Blueknight Srs A Uts'
  },
  {
    Ticker: 'BKNG',
    Company: 'Booking Holdings Inc'
  },
  {
    Ticker: 'BKSC',
    Company: 'Bank of So Car Cp'
  },
  {
    Ticker: 'BKYI',
    Company: 'Bio-Key Intl Inc'
  },
  {
    Ticker: 'BL',
    Company: 'Blackline Inc'
  },
  {
    Ticker: 'BLBD',
    Company: 'Blue Bird Corp'
  },
  {
    Ticker: 'BLCM',
    Company: 'Bellicum Pharma Comm'
  },
  {
    Ticker: 'BLCN',
    Company: 'Reality Shares Nasdaq Nextgen Economy ETF'
  },
  {
    Ticker: 'BLDP',
    Company: 'Ballard Power Sys'
  },
  {
    Ticker: 'BLDR',
    Company: 'Builders Firstsource'
  },
  {
    Ticker: 'BLFS',
    Company: 'Biolife Solutions'
  },
  {
    Ticker: 'BLIN',
    Company: 'Bridgeline Digital'
  },
  {
    Ticker: 'BLKB',
    Company: 'Blackbaud Inc'
  },
  {
    Ticker: 'BLMN',
    Company: "Bloomin' Brands Inc"
  },
  {
    Ticker: 'BLNK',
    Company: 'Blink Charging Company'
  },
  {
    Ticker: 'BLNKW',
    Company: 'Blink Charging Co. WT'
  },
  {
    Ticker: 'BLPH',
    Company: 'Bellerophon Ther Com'
  },
  {
    Ticker: 'BLRX',
    Company: 'Biolinerx Ltd'
  },
  {
    Ticker: 'BLU',
    Company: 'Bellus Health Inc'
  },
  {
    Ticker: 'BLUE',
    Company: 'Bluebird Bio'
  },
  {
    Ticker: 'BMCH',
    Company: 'Bmc Stock Holdings'
  },
  {
    Ticker: 'BMLP',
    Company: 'Dorsey Wright MLP Index ETN'
  },
  {
    Ticker: 'BMRA',
    Company: 'Biomerica Inc'
  },
  {
    Ticker: 'BMRC',
    Company: 'Bank of Marin Bancrp'
  },
  {
    Ticker: 'BMRN',
    Company: 'Biomarin Pharmaceuticals'
  },
  {
    Ticker: 'BMTC',
    Company: 'Bryn Mawr Bank Corp'
  },
  {
    Ticker: 'BND',
    Company: 'Total Bond Market ETF Vanguard'
  },
  {
    Ticker: 'BNDW',
    Company: 'Total World Bond Vanguard ETF'
  },
  {
    Ticker: 'BNDX',
    Company: 'Total Intl Bond ETF Vanguard'
  },
  {
    Ticker: 'BNFT',
    Company: 'Benefitfocus'
  },
  {
    Ticker: 'BNGO',
    Company: 'Bionano Genomics Inc'
  },
  {
    Ticker: 'BNGOW',
    Company: 'Bionano Genomics Inc WT'
  },
  {
    Ticker: 'BNSO',
    Company: 'Bonso Elec Intl IN'
  },
  {
    Ticker: 'BNTC',
    Company: 'Benitec Biopharma Ltd'
  },
  {
    Ticker: 'BNTCW',
    Company: 'Benitec Biopharm Wrt'
  },
  {
    Ticker: 'BNTX',
    Company: 'Biontech Se ADR'
  },
  {
    Ticker: 'BOCH',
    Company: 'Bank of Commerce Hld'
  },
  {
    Ticker: 'BOKF',
    Company: 'Bok Financial Corp'
  },
  {
    Ticker: 'BOKFL',
    Company: 'Bok Financial Corp'
  },
  {
    Ticker: 'BOLD',
    Company: 'Audentes Therapeutics Inc'
  },
  {
    Ticker: 'BOMN',
    Company: 'Boston Omaha Corp Cl A'
  },
  {
    Ticker: 'BOOM',
    Company: 'Dynamic Materials'
  },
  {
    Ticker: 'BOSC',
    Company: 'Bos Better Online'
  },
  {
    Ticker: 'BOTJ',
    Company: 'Bank of The Jame Fnl'
  },
  {
    Ticker: 'BOTZ',
    Company: 'G-X Robotics & Artificial Intel Thmtc ETF'
  },
  {
    Ticker: 'BOXL',
    Company: 'Boxlight Corp Cl A'
  },
  {
    Ticker: 'BPFH',
    Company: 'Boston Pvt Finl Hld'
  },
  {
    Ticker: 'BPMC',
    Company: 'Blueprint Medicines Corp'
  },
  {
    Ticker: 'BPOP',
    Company: 'Popular Inc'
  },
  {
    Ticker: 'BPOPM',
    Company: 'Popular Inc Trust II'
  },
  {
    Ticker: 'BPOPN',
    Company: 'Popular Inc Trust'
  },
  {
    Ticker: 'BPR',
    Company: 'Brookfield Property REIT Inc'
  },
  {
    Ticker: 'BPRAP',
    Company: 'Brookfield Property REIT Inc 6.375% Series A'
  },
  {
    Ticker: 'BPRN',
    Company: 'Bank of Princeton'
  },
  {
    Ticker: 'BPTH',
    Company: 'Bio-Path Holdings'
  },
  {
    Ticker: 'BPY',
    Company: 'Brookfield Property Partners L'
  },
  {
    Ticker: 'BPYPO',
    Company: 'Brookfield Property Partners L.P. 6.375% Prf P'
  },
  {
    Ticker: 'BPYPP',
    Company: 'Brookfield Property Partners LP Pfd Unit Ltd Par'
  },
  {
    Ticker: 'BREW',
    Company: 'Craft Brew Alliance'
  },
  {
    Ticker: 'BRID',
    Company: 'Bridgford Foods Cp'
  },
  {
    Ticker: 'BRKL',
    Company: 'Brookline Bancorp'
  },
  {
    Ticker: 'BRKR',
    Company: 'Bruker Corp'
  },
  {
    Ticker: 'BRKS',
    Company: 'Brooks Automation'
  },
  {
    Ticker: 'BROG',
    Company: 'Twelve Seas Investment Company'
  },
  {
    Ticker: 'BROGR',
    Company: 'Twelve Seas Investment Company Rights'
  },
  {
    Ticker: 'BROGU',
    Company: 'Twelve Seas Investment Company Units'
  },
  {
    Ticker: 'BROGW',
    Company: 'Twelve Seas Investment Company WT'
  },
  {
    Ticker: 'BRP',
    Company: 'Brp Group Inc Cl A'
  },
  {
    Ticker: 'BRPA',
    Company: 'Big Rock Partners Acquisition Corp'
  },
  {
    Ticker: 'BRPAR',
    Company: 'Big Rock Partners Acquisition Corp Right'
  },
  {
    Ticker: 'BRPAU',
    Company: 'Big Rock Partners Acquisition Corp Unit'
  },
  {
    Ticker: 'BRPAW',
    Company: 'Big Rock Partners Acquisition Corp WT'
  },
  {
    Ticker: 'BRY',
    Company: 'Berry Pete Corp'
  },
  {
    Ticker: 'BSET',
    Company: 'Bassett Furniture'
  },
  {
    Ticker: 'BSGM',
    Company: 'Biosig Technologies'
  },
  {
    Ticker: 'BSML',
    Company: 'Invesco Bulletshares 2021 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMM',
    Company: 'Invesco Bulletshares 2022 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMN',
    Company: 'Invesco Bulletshares 2023 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMO',
    Company: 'Invesco Bulletshares 2024 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMP',
    Company: 'Invesco Bulletshares 2025 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMQ',
    Company: 'Invesco Bulletshares 2026 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMR',
    Company: 'Invesco Bulletshares 2027 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMS',
    Company: 'Invesco Bulletshares 2028 Municipal Bond ETF'
  },
  {
    Ticker: 'BSMT',
    Company: 'Invesco Bulletshares 2029 Municipal Bond ETF'
  },
  {
    Ticker: 'BSQR',
    Company: 'Bsquare Corp'
  },
  {
    Ticker: 'BSRR',
    Company: 'Sierra Bancorp'
  },
  {
    Ticker: 'BSTC',
    Company: 'Biospecifics Tech Cp'
  },
  {
    Ticker: 'BSVN',
    Company: 'Bank7 Corp'
  },
  {
    Ticker: 'BTAI',
    Company: 'Bioxcel Therapeutics Inc'
  },
  {
    Ticker: 'BTEC',
    Company: 'Principal Healthcare Innovators Index'
  },
  {
    Ticker: 'BUG',
    Company: 'Global X Cybersecurity ETF'
  },
  {
    Ticker: 'BURG',
    Company: 'Chanticleer Hlds'
  },
  {
    Ticker: 'BUSE',
    Company: 'First Busey Corp'
  },
  {
    Ticker: 'BVSN',
    Company: 'Broadvision Inc'
  },
  {
    Ticker: 'BVXV',
    Company: 'Biondvax Pharma Ads'
  },
  {
    Ticker: 'BVXVW',
    Company: 'Biondvax Pharma WT'
  },
  {
    Ticker: 'BWAY',
    Company: 'Brainsway Ltd ADR'
  },
  {
    Ticker: 'BWB',
    Company: 'Bridgewater Bancshares Inc'
  },
  {
    Ticker: 'BWEN',
    Company: 'Broadwind Energy Inc'
  },
  {
    Ticker: 'BWFG',
    Company: 'Bankwell Financial'
  },
  {
    Ticker: 'BWMC',
    Company: 'Boxwood Merger Corp Cl A'
  },
  {
    Ticker: 'BWMCU',
    Company: 'Boxwood Merger Corp Unit'
  },
  {
    Ticker: 'BWMCW',
    Company: 'Boxwood Merger Corp WT'
  },
  {
    Ticker: 'BXRX',
    Company: 'Baudax Bio Inc'
  },
  {
    Ticker: 'BXRXV',
    Company: 'Baudax Bio Inc'
  },
  {
    Ticker: 'BYFC',
    Company: 'Broadway Fin Cp'
  },
  {
    Ticker: 'BYND',
    Company: 'Beyond Meat Inc'
  },
  {
    Ticker: 'BYSI',
    Company: 'Beyondspring Inc'
  },
  {
    Ticker: 'BZUN',
    Company: 'Baozun Inc Ads'
  },
  {
    Ticker: 'CAAS',
    Company: 'China Automotive Sys'
  },
  {
    Ticker: 'CABA',
    Company: 'Cabaletta Bio Inc'
  },
  {
    Ticker: 'CAC',
    Company: 'Camden Natl Cp'
  },
  {
    Ticker: 'CACC',
    Company: 'Credit Acceptance'
  },
  {
    Ticker: 'CACG',
    Company: 'Legg Clearbridge All Cap Growth ETF'
  },
  {
    Ticker: 'CAKE',
    Company: 'Cheesecake Fact'
  },
  {
    Ticker: 'CALA',
    Company: 'Calithera Biosci Com'
  },
  {
    Ticker: 'CALM',
    Company: 'Cal-Maine Foods IN'
  },
  {
    Ticker: 'CAMP',
    Company: 'Calamp Corp'
  },
  {
    Ticker: 'CAMT',
    Company: 'Camtek Ltd'
  },
  {
    Ticker: 'CAN',
    Company: 'Canaan Inc American Depositary Shares'
  },
  {
    Ticker: 'CAPR',
    Company: 'Capricor Therap'
  },
  {
    Ticker: 'CAR',
    Company: 'Avis Budget Group'
  },
  {
    Ticker: 'CARA',
    Company: 'Cara Therapeutics'
  },
  {
    Ticker: 'CARB',
    Company: 'Carbonite Inc'
  },
  {
    Ticker: 'CARE',
    Company: 'Carter Bank&Tr [Va]'
  },
  {
    Ticker: 'CARG',
    Company: 'Cargurus Inc Cl A'
  },
  {
    Ticker: 'CARO',
    Company: 'Carolina Financial Corp'
  },
  {
    Ticker: 'CART',
    Company: 'Carolina Trust Bank'
  },
  {
    Ticker: 'CARV',
    Company: 'Carver Bancorp'
  },
  {
    Ticker: 'CARZ',
    Company: 'Nasdaq Global Auto Index Fund FT'
  },
  {
    Ticker: 'CASA',
    Company: 'Casa Systems Inc'
  },
  {
    Ticker: 'CASH',
    Company: 'Meta Financial Group'
  },
  {
    Ticker: 'CASI',
    Company: 'Casi Phrmactcls Inc'
  },
  {
    Ticker: 'CASS',
    Company: 'Cass Information Sys'
  },
  {
    Ticker: 'CASY',
    Company: "Casey's General Stor"
  },
  {
    Ticker: 'CATB',
    Company: 'Catabasis Pharma'
  },
  {
    Ticker: 'CATC',
    Company: 'Cambridge Bancorp'
  },
  {
    Ticker: 'CATH',
    Company: 'G-X S&P 500 Catholic Values ETF'
  },
  {
    Ticker: 'CATM',
    Company: 'Cardtronics Inc'
  },
  {
    Ticker: 'CATS',
    Company: 'Catasys Inc'
  },
  {
    Ticker: 'CATY',
    Company: 'Cathay Genl Bncp'
  },
  {
    Ticker: 'CBAN',
    Company: 'Colony Bankcorp Inc'
  },
  {
    Ticker: 'CBAT',
    Company: 'Cbak Energy Technology Inc'
  },
  {
    Ticker: 'CBAY',
    Company: 'Cymabay Therapeutics'
  },
  {
    Ticker: 'CBFV',
    Company: 'Cb Financial Svc'
  },
  {
    Ticker: 'CBIO',
    Company: 'Catalyst Bio'
  },
  {
    Ticker: 'CBLI',
    Company: 'Cleveland Biolabs'
  },
  {
    Ticker: 'CBMB',
    Company: 'Cbm Bancorp Inc'
  },
  {
    Ticker: 'CBMG',
    Company: 'Cellular Biomedicine'
  },
  {
    Ticker: 'CBNK',
    Company: 'Capital Bancorp Inc'
  },
  {
    Ticker: 'CBPO',
    Company: 'China Biologic Produ'
  },
  {
    Ticker: 'CBRL',
    Company: 'Cracker Barrel'
  },
  {
    Ticker: 'CBSH',
    Company: 'Commerce Bancshares'
  },
  {
    Ticker: 'CBSHP',
    Company: 'Commerce BNC B Dep'
  },
  {
    Ticker: 'CBTX',
    Company: 'Cbtx Inc'
  },
  {
    Ticker: 'CCB',
    Company: 'Coastal Financial Corp'
  },
  {
    Ticker: 'CCBG',
    Company: 'Capital City Bank Gr'
  },
  {
    Ticker: 'CCCL',
    Company: 'China Ceramics Company'
  },
  {
    Ticker: 'CCD',
    Company: 'Calams Dy Cnv IN'
  },
  {
    Ticker: 'CCLP',
    Company: 'CSI Compressco'
  },
  {
    Ticker: 'CCMP',
    Company: 'Cabot Microelectron'
  },
  {
    Ticker: 'CCNE',
    Company: 'Cnb Financial Corp'
  },
  {
    Ticker: 'CCOI',
    Company: 'Cogent Comm Hlds'
  },
  {
    Ticker: 'CCRC',
    Company: 'China Customer Ord'
  },
  {
    Ticker: 'CCRN',
    Company: 'Cross Ctry Hlthcr'
  },
  {
    Ticker: 'CCXI',
    Company: 'Chemocentryx Inc'
  },
  {
    Ticker: 'CDC',
    Company: 'Victory US Eqty Income Ehnd Vol Wtd ETF'
  },
  {
    Ticker: 'CDEV',
    Company: 'Centennial Resource Development Inc'
  },
  {
    Ticker: 'CDK',
    Company: 'Cdk Global Inc'
  },
  {
    Ticker: 'CDL',
    Company: 'Victory US Largecap HI Div Vol Wtd ETF'
  },
  {
    Ticker: 'CDLX',
    Company: 'Cardlytics Inc'
  },
  {
    Ticker: 'CDMO',
    Company: 'Avid Bioservices Inc'
  },
  {
    Ticker: 'CDMOP',
    Company: 'Avid Bioservices Inc'
  },
  {
    Ticker: 'CDNA',
    Company: 'Caredx Inc'
  },
  {
    Ticker: 'CDNS',
    Company: 'Cadence Design Sys'
  },
  {
    Ticker: 'CDTX',
    Company: 'Cidara Thera'
  },
  {
    Ticker: 'CDW',
    Company: 'CDW Corp'
  },
  {
    Ticker: 'CDXC',
    Company: 'Chromadex Corp CS'
  },
  {
    Ticker: 'CDXS',
    Company: 'Codexis Inc'
  },
  {
    Ticker: 'CDZI',
    Company: 'Cadiz Inc'
  },
  {
    Ticker: 'CECE',
    Company: 'C E C O Envir'
  },
  {
    Ticker: 'CECO',
    Company: 'Career Education'
  },
  {
    Ticker: 'CELC',
    Company: 'Celcuity Inc'
  },
  {
    Ticker: 'CELG',
    Company: 'Celgene Corp'
  },
  {
    Ticker: 'CELGZ',
    Company: 'Celgene Cp Cvr'
  },
  {
    Ticker: 'CELH',
    Company: 'Celsius Holdings Inc'
  },
  {
    Ticker: 'CEMI',
    Company: 'Chembio Diagnostics'
  },
  {
    Ticker: 'CENT',
    Company: 'Central Garden'
  },
  {
    Ticker: 'CENTA',
    Company: 'Central Garden & Pet'
  },
  {
    Ticker: 'CENX',
    Company: 'Century Aluminum C'
  },
  {
    Ticker: 'CERC',
    Company: 'Cerecor Inc'
  },
  {
    Ticker: 'CERN',
    Company: 'Cerner Corp'
  },
  {
    Ticker: 'CERS',
    Company: 'Cerus Corp'
  },
  {
    Ticker: 'CETV',
    Company: 'Central EUR Med A'
  },
  {
    Ticker: 'CETX',
    Company: 'Cemtrex Inc'
  },
  {
    Ticker: 'CETXP',
    Company: 'Cemtrex Inc Series 1 Pfd'
  },
  {
    Ticker: 'CETXW',
    Company: 'Cemtrex Inc Series 1 WT'
  },
  {
    Ticker: 'CEVA',
    Company: 'Ceva Inc'
  },
  {
    Ticker: 'CEY',
    Company: 'Victoryshares Emerging Market High Div Vol Wtd E'
  },
  {
    Ticker: 'CEZ',
    Company: 'Victory EM Vol Wtd ETF'
  },
  {
    Ticker: 'CFA',
    Company: 'Victory US 500 Vol ETF'
  },
  {
    Ticker: 'CFB',
    Company: 'Crossfirst Bankshares Inc'
  },
  {
    Ticker: 'CFBI',
    Company: 'Community First Bancshares Inc'
  },
  {
    Ticker: 'CFBK',
    Company: 'Central Fed Cp'
  },
  {
    Ticker: 'CFFA',
    Company: 'Cf Finance Acquisition Corp Cl A'
  },
  {
    Ticker: 'CFFAU',
    Company: 'Cf Finance Acquisition Corp Unit'
  },
  {
    Ticker: 'CFFAW',
    Company: 'Cf Finance Acquisition Corp WT'
  },
  {
    Ticker: 'CFFI',
    Company: 'C&F Financial Corp'
  },
  {
    Ticker: 'CFFN',
    Company: 'Capitol Fed Finl Inc'
  },
  {
    Ticker: 'CFMS',
    Company: 'Conformis Inc CS'
  },
  {
    Ticker: 'CFO',
    Company: 'Victory US 500 Ehnd Vol Wtd ETF'
  },
  {
    Ticker: 'CFRX',
    Company: 'Contrafect'
  },
  {
    Ticker: 'CG',
    Company: 'The Carlyle Group'
  },
  {
    Ticker: 'CGBD',
    Company: 'Tcg Bdc Inc'
  },
  {
    Ticker: 'CGEN',
    Company: 'Compugen Ltd'
  },
  {
    Ticker: 'CGIX',
    Company: 'Cancer Genetics Inc'
  },
  {
    Ticker: 'CGNX',
    Company: 'Cognex Cp'
  },
  {
    Ticker: 'CGO',
    Company: 'Calamos Glb Ttl Rtn'
  },
  {
    Ticker: 'CHCI',
    Company: 'Comstock Hlds Cos'
  },
  {
    Ticker: 'CHCO',
    Company: 'City Holding Company'
  },
  {
    Ticker: 'CHDN',
    Company: 'Churchill Downs IN'
  },
  {
    Ticker: 'CHEF',
    Company: 'The Chefs Warehouse'
  },
  {
    Ticker: 'CHEK',
    Company: 'Check-Cap Ltd Ord'
  },
  {
    Ticker: 'CHEKW',
    Company: 'Check-Cap Ltd A WT'
  },
  {
    Ticker: 'CHEKZ',
    Company: 'Check-Cap Ltd Series C WT'
  },
  {
    Ticker: 'CHFS',
    Company: 'Chf Solutions Inc'
  },
  {
    Ticker: 'CHI',
    Company: 'Calamos Cv Opp & Inc'
  },
  {
    Ticker: 'CHKP',
    Company: 'Check Point Software'
  },
  {
    Ticker: 'CHMA',
    Company: 'Chiasma Inc'
  },
  {
    Ticker: 'CHMG',
    Company: 'Chemung Financial Cp'
  },
  {
    Ticker: 'CHNA',
    Company: 'Loncar China Biopharma ETF'
  },
  {
    Ticker: 'CHNG',
    Company: 'Change Healthcare Inc'
  },
  {
    Ticker: 'CHNGU',
    Company: 'Change Healthcare Inc Tangible Equity Units'
  },
  {
    Ticker: 'CHNR',
    Company: 'China Natural Res'
  },
  {
    Ticker: 'CHPMU',
    Company: 'Chp Merger Corp'
  },
  {
    Ticker: 'CHRS',
    Company: 'Coherus Bio'
  },
  {
    Ticker: 'CHRW',
    Company: 'C.H. Robinson Ww'
  },
  {
    Ticker: 'CHSCL',
    Company: 'CHS B Cum Pfd Srs 4'
  },
  {
    Ticker: 'CHSCM',
    Company: 'CHS Pfd Clb Ser3'
  },
  {
    Ticker: 'CHSCN',
    Company: 'CHS Inc Pfd B Srs 2'
  },
  {
    Ticker: 'CHSCO',
    Company: 'CHS Inc Cl B Pfd'
  },
  {
    Ticker: 'CHSCP',
    Company: 'CHS Inc'
  },
  {
    Ticker: 'CHTR',
    Company: 'Charter Communicatio'
  },
  {
    Ticker: 'CHUY',
    Company: "Chuy's Holdings Inc"
  },
  {
    Ticker: 'CHW',
    Company: 'Calamos Gbl Dyn Inc'
  },
  {
    Ticker: 'CHY',
    Company: 'Calamos Cv & High IN'
  },
  {
    Ticker: 'CIBR',
    Company: 'Nasdaq Cybersecurity ETF FT'
  },
  {
    Ticker: 'CID',
    Company: 'Victory Intl HI Div Vol Wtd ETF'
  },
  {
    Ticker: 'CIDM',
    Company: 'Cinedigm Corp'
  },
  {
    Ticker: 'CIFS',
    Company: 'China Internet Nationwide Financial Services Inc'
  },
  {
    Ticker: 'CIGI',
    Company: 'Colliers Intl Grp IN'
  },
  {
    Ticker: 'CIH',
    Company: 'China Index Holdings Ltd'
  },
  {
    Ticker: 'CIL',
    Company: 'Victory Intl Vol Wtd ETF'
  },
  {
    Ticker: 'CINF',
    Company: 'Cincinnati Financial'
  },
  {
    Ticker: 'CIVB',
    Company: 'Civista Bncshrs'
  },
  {
    Ticker: 'CIVBP',
    Company: 'Civista Bancshrs Dep'
  },
  {
    Ticker: 'CIZ',
    Company: 'Victory Dev Enhd Vol Wtd ETF'
  },
  {
    Ticker: 'CIZN',
    Company: 'Citizens Hld Company'
  },
  {
    Ticker: 'CJJD',
    Company: 'China Jojo Drugstore'
  },
  {
    Ticker: 'CKPT',
    Company: 'Checkpoint Therapeutics Inc'
  },
  {
    Ticker: 'CLAR',
    Company: 'Clarus Corp'
  },
  {
    Ticker: 'CLBK',
    Company: 'Columbia Financial Inc'
  },
  {
    Ticker: 'CLBS',
    Company: 'Caladrius Bio'
  },
  {
    Ticker: 'CLCT',
    Company: 'Collectors Universe'
  },
  {
    Ticker: 'CLDB',
    Company: 'Cortland Bncp Crtld Ohio'
  },
  {
    Ticker: 'CLDX',
    Company: 'Celldex Therapeutics'
  },
  {
    Ticker: 'CLFD',
    Company: 'Clearfield Inc'
  },
  {
    Ticker: 'CLGN',
    Company: 'Collplant Biotechnologies Ltd'
  },
  {
    Ticker: 'CLIR',
    Company: 'Clearsign Combustion'
  },
  {
    Ticker: 'CLLS',
    Company: 'Cellectis S.A. ADR'
  },
  {
    Ticker: 'CLMT',
    Company: 'Calumet Specialty Pr'
  },
  {
    Ticker: 'CLNE',
    Company: 'Clean Energy Fuels'
  },
  {
    Ticker: 'CLOU',
    Company: 'Global X Cloud Computing ETF'
  },
  {
    Ticker: 'CLPS',
    Company: 'Clps Incorp'
  },
  {
    Ticker: 'CLRB',
    Company: 'Cellectar Biosc'
  },
  {
    Ticker: 'CLRBZ',
    Company: 'Cellectar Bio Sra WT'
  },
  {
    Ticker: 'CLRG',
    Company: 'IQ Chaikin U.S. Large Cap ETF'
  },
  {
    Ticker: 'CLRO',
    Company: 'Clearone Inc'
  },
  {
    Ticker: 'CLSD',
    Company: 'Clearside Biomedi'
  },
  {
    Ticker: 'CLSN',
    Company: 'Celsion Corp'
  },
  {
    Ticker: 'CLUB',
    Company: 'Town Sports International'
  },
  {
    Ticker: 'CLVS',
    Company: 'Clovis Oncology Inc'
  },
  {
    Ticker: 'CLWT',
    Company: 'Euro Tech Hld'
  },
  {
    Ticker: 'CLXT',
    Company: 'Calyxt Inc'
  },
  {
    Ticker: 'CMBM',
    Company: 'Cambium Networks Corp'
  },
  {
    Ticker: 'CMCO',
    Company: 'Columbus Mckinnon'
  },
  {
    Ticker: 'CMCSA',
    Company: 'Comcast Corp A'
  },
  {
    Ticker: 'CMCT',
    Company: 'Cim Commercial TR Cp'
  },
  {
    Ticker: 'CMCTP',
    Company: 'Cim Commercial Trust Corp Series L'
  },
  {
    Ticker: 'CME',
    Company: 'CME Group Inc'
  },
  {
    Ticker: 'CMFNL',
    Company: 'Cm Finance Inc 6.125% Notes Due 2023'
  },
  {
    Ticker: 'CMLS',
    Company: 'Cumulus Media Inc Cl A'
  },
  {
    Ticker: 'CMPR',
    Company: 'Cimpress N.V. Ord'
  },
  {
    Ticker: 'CMRX',
    Company: 'Chimerix Inc'
  },
  {
    Ticker: 'CMTL',
    Company: 'Comtech Telecom Company'
  },
  {
    Ticker: 'CNAT',
    Company: 'Conatus Pharmaceutic'
  },
  {
    Ticker: 'CNBKA',
    Company: 'Century Bancorp Inc'
  },
  {
    Ticker: 'CNCE',
    Company: 'Concert Pharmaceutic'
  },
  {
    Ticker: 'CNCR',
    Company: 'Loncar Cancer Immunotherapy ETF'
  },
  {
    Ticker: 'CNET',
    Company: 'Chinanet Online Hld'
  },
  {
    Ticker: 'CNFR',
    Company: 'Conifer Holdings'
  },
  {
    Ticker: 'CNFRL',
    Company: 'Conifer Holdings Inc 6.75% Senior Unsecured No'
  },
  {
    Ticker: 'CNMD',
    Company: 'C O N M E D Cp'
  },
  {
    Ticker: 'CNOB',
    Company: 'Connectone Bancorp'
  },
  {
    Ticker: 'CNSL',
    Company: 'Consolidated Communications Holdings Inc'
  },
  {
    Ticker: 'CNSP',
    Company: 'Cns Pharmaceuticals Inc'
  },
  {
    Ticker: 'CNST',
    Company: 'Constellation Pharmaceuticals Inc'
  },
  {
    Ticker: 'CNTG',
    Company: 'Centogene N.V.'
  },
  {
    Ticker: 'CNTY',
    Company: 'Century Casinos IN'
  },
  {
    Ticker: 'CNXN',
    Company: 'PC Connection Inc'
  },
  {
    Ticker: 'COCP',
    Company: 'Cocrystal Pharma Inc'
  },
  {
    Ticker: 'CODA',
    Company: 'Coda Octopus Group'
  },
  {
    Ticker: 'CODX',
    Company: 'Co-Diagnostics Inc'
  },
  {
    Ticker: 'COHR',
    Company: 'Coherent Inc'
  },
  {
    Ticker: 'COHU',
    Company: 'Cohu Inc'
  },
  {
    Ticker: 'COKE',
    Company: 'Coca Cola Bot Cons'
  },
  {
    Ticker: 'COLB',
    Company: 'Columbia Banking Sys'
  },
  {
    Ticker: 'COLL',
    Company: 'Collegium Pharma'
  },
  {
    Ticker: 'COLM',
    Company: 'Columbia Sprtswr'
  },
  {
    Ticker: 'COMM',
    Company: 'Commscope Holding Company'
  },
  {
    Ticker: 'COMT',
    Company: 'Commodities Select Strategy Ishares ETF'
  },
  {
    Ticker: 'CONE',
    Company: 'Cyrusone Inc'
  },
  {
    Ticker: 'CONN',
    Company: "Conn's Inc"
  },
  {
    Ticker: 'COOP',
    Company: 'Mr.Cooper Group Inc'
  },
  {
    Ticker: 'CORE',
    Company: 'Core Mark Holding'
  },
  {
    Ticker: 'CORT',
    Company: 'Corcept Therapeutics'
  },
  {
    Ticker: 'CORV',
    Company: 'Correvio Pharma Corp'
  },
  {
    Ticker: 'COST',
    Company: 'Costco Wholesale'
  },
  {
    Ticker: 'COUP',
    Company: 'Coupa Software Inc'
  },
  {
    Ticker: 'COWN',
    Company: 'Cowen Group Inc'
  },
  {
    Ticker: 'COWNL',
    Company: 'Cowen Inc 7.75% Senior Notes Due 2033'
  },
  {
    Ticker: 'COWNZ',
    Company: 'Cowen Inc 7.35% Senior Notes Due 2027'
  },
  {
    Ticker: 'CPAA',
    Company: 'Conyers Park II Acquisition Corp Cl A'
  },
  {
    Ticker: 'CPAAU',
    Company: 'Conyers Park II Acquisition Corp Unit'
  },
  {
    Ticker: 'CPAAW',
    Company: 'Conyers Park II Acquisition Corp WT'
  },
  {
    Ticker: 'CPAH',
    Company: 'Counterpath Corp'
  },
  {
    Ticker: 'CPHC',
    Company: 'Canterbury Park Hl'
  },
  {
    Ticker: 'CPIX',
    Company: 'Cumberland Pharmaceu'
  },
  {
    Ticker: 'CPLP',
    Company: 'Capital Product Part'
  },
  {
    Ticker: 'CPRT',
    Company: 'Copart Inc'
  },
  {
    Ticker: 'CPRX',
    Company: 'Catalyst Pharm Inc'
  },
  {
    Ticker: 'CPSH',
    Company: 'Cps Technologies'
  },
  {
    Ticker: 'CPSI',
    Company: 'Computer Programs'
  },
  {
    Ticker: 'CPSS',
    Company: 'Consumer Portfol'
  },
  {
    Ticker: 'CPST',
    Company: 'Capstone Turbine Cp'
  },
  {
    Ticker: 'CPTA',
    Company: 'Capitala Finance Crp'
  },
  {
    Ticker: 'CPTAG',
    Company: 'Capitala Finance Corp 5.75% Convertible Notes D'
  },
  {
    Ticker: 'CPTAL',
    Company: 'Capitala Finance Corp 6% Notes Due 2022'
  },
  {
    Ticker: 'CPZ',
    Company: 'Calamos Long/Short Equity & Dynamic Income Trust'
  },
  {
    Ticker: 'CRAI',
    Company: 'Cra International'
  },
  {
    Ticker: 'CRBP',
    Company: 'Corbus Pharma'
  },
  {
    Ticker: 'CREE',
    Company: 'Cree Inc'
  },
  {
    Ticker: 'CREG',
    Company: 'China Recycling Ener'
  },
  {
    Ticker: 'CRESY',
    Company: 'Cresud Sacif ADR'
  },
  {
    Ticker: 'CREX',
    Company: 'Creative Realities Inc'
  },
  {
    Ticker: 'CREXW',
    Company: 'Creative Realities Inc'
  },
  {
    Ticker: 'CRIS',
    Company: 'Curis Inc'
  },
  {
    Ticker: 'CRMT',
    Company: "America's Car-Mart"
  },
  {
    Ticker: 'CRNC',
    Company: 'Cerence Inc'
  },
  {
    Ticker: 'CRNT',
    Company: 'Ceragon Networks Ltd'
  },
  {
    Ticker: 'CRNX',
    Company: 'Crinetics Pharmaceuticals Inc'
  },
  {
    Ticker: 'CRON',
    Company: 'Cronos Group Inc'
  },
  {
    Ticker: 'CROX',
    Company: 'Crocs Inc'
  },
  {
    Ticker: 'CRSA',
    Company: 'Crescent Acquisition Corp Cl A'
  },
  {
    Ticker: 'CRSAU',
    Company: 'Crescent Acquisition Corp Unit'
  },
  {
    Ticker: 'CRSAW',
    Company: 'Crescent Acquisition Corp WT'
  },
  {
    Ticker: 'CRSP',
    Company: 'Crispr Therapeutics Ag'
  },
  {
    Ticker: 'CRTO',
    Company: 'Criteo S.A. Ads'
  },
  {
    Ticker: 'CRTX',
    Company: 'Cortexyme Inc'
  },
  {
    Ticker: 'CRUS',
    Company: 'Cirrus Logic Inc'
  },
  {
    Ticker: 'CRVL',
    Company: 'Corvel Cp'
  },
  {
    Ticker: 'CRVS',
    Company: 'Corvus Pharma Com'
  },
  {
    Ticker: 'CRWD',
    Company: 'Crowdstrike Holdings Inc'
  },
  {
    Ticker: 'CRWS',
    Company: 'Crown Crafts Inc'
  },
  {
    Ticker: 'CRZO',
    Company: 'Carrizo Oil & Gas'
  },
  {
    Ticker: 'CSA',
    Company: 'Victory US Smallcap Vol Wtd ETF'
  },
  {
    Ticker: 'CSB',
    Company: 'Victory US Smallcap HI Div Vol Wtd ETF'
  },
  {
    Ticker: 'CSBR',
    Company: 'Champions Oncolog'
  },
  {
    Ticker: 'CSCO',
    Company: 'Cisco Systems Inc'
  },
  {
    Ticker: 'CSF',
    Company: 'Victory US Disc Enhd Vol Wtd ETF'
  },
  {
    Ticker: 'CSFL',
    Company: 'Centerstate Bank Corp'
  },
  {
    Ticker: 'CSGP',
    Company: 'Costar Group Inc'
  },
  {
    Ticker: 'CSGS',
    Company: 'C S G Sys Intl'
  },
  {
    Ticker: 'CSII',
    Company: 'Cardiovascular Syst'
  },
  {
    Ticker: 'CSIQ',
    Company: 'Canadian Solar Inc'
  },
  {
    Ticker: 'CSML',
    Company: 'IQ Chaikin U.S. Small Cap ETF'
  },
  {
    Ticker: 'CSOD',
    Company: 'Cornerstone Ondemand'
  },
  {
    Ticker: 'CSPI',
    Company: 'C S P Inc'
  },
  {
    Ticker: 'CSQ',
    Company: 'Calamos Strgc Ttl Rt'
  },
  {
    Ticker: 'CSSE',
    Company: 'Chicken Soup For The Soul Entertainment Inc'
  },
  {
    Ticker: 'CSSEP',
    Company: 'Chicken Soup For The Soul Entertainment Inc 9.'
  },
  {
    Ticker: 'CSTE',
    Company: 'Caesarstone Sdot-Yam'
  },
  {
    Ticker: 'CSTL',
    Company: 'Castle Biosciences Inc'
  },
  {
    Ticker: 'CSTR',
    Company: 'Capstar Financial Holdings Inc'
  },
  {
    Ticker: 'CSWC',
    Company: 'Capital Southwest'
  },
  {
    Ticker: 'CSWCL',
    Company: 'Capital Southwest Corp 5.95% Notes Due 20'
  },
  {
    Ticker: 'CSWI',
    Company: 'Csw Industrials Inc'
  },
  {
    Ticker: 'CSX',
    Company: 'CSX Corp'
  },
  {
    Ticker: 'CTAC',
    Company: 'Chaserg Technology Acquisition Corp Cl A'
  },
  {
    Ticker: 'CTACU',
    Company: 'Chaserg Technology Acquisition Corp Unit'
  },
  {
    Ticker: 'CTACW',
    Company: 'Chaserg Technology Acquisition Corp WT'
  },
  {
    Ticker: 'CTAS',
    Company: 'Cintas Corp'
  },
  {
    Ticker: 'CTBI',
    Company: 'Community TR Bancp'
  },
  {
    Ticker: 'CTG',
    Company: 'Computer Task Gp Inc'
  },
  {
    Ticker: 'CTHR',
    Company: 'Charles & Colvard'
  },
  {
    Ticker: 'CTIB',
    Company: 'Cti Inds Corp'
  },
  {
    Ticker: 'CTIC',
    Company: 'Cti Biopharm Corp'
  },
  {
    Ticker: 'CTMX',
    Company: 'Cytomx Thera'
  },
  {
    Ticker: 'CTRC',
    Company: 'Centric Brands Inc'
  },
  {
    Ticker: 'CTRE',
    Company: 'Caretrust REIT Inc'
  },
  {
    Ticker: 'CTRM',
    Company: 'Castor Maritime Inc'
  },
  {
    Ticker: 'CTRN',
    Company: 'Citi Trends Inc'
  },
  {
    Ticker: 'CTSH',
    Company: 'Cognizant Tech Sol'
  },
  {
    Ticker: 'CTSO',
    Company: 'Cytosorbents Cor'
  },
  {
    Ticker: 'CTXR',
    Company: 'Citius Pharmaceuticals Inc'
  },
  {
    Ticker: 'CTXRW',
    Company: 'Citius Pharmaceuticals Inc WT'
  },
  {
    Ticker: 'CTXS',
    Company: 'Citrix Systems Inc'
  },
  {
    Ticker: 'CUBA',
    Company: 'Herzfeld Caribbean'
  },
  {
    Ticker: 'CUE',
    Company: 'Cue Biopharma Inc'
  },
  {
    Ticker: 'CUI',
    Company: 'Cui Global Inc'
  },
  {
    Ticker: 'CUTR',
    Company: 'Cutera Inc'
  },
  {
    Ticker: 'CVBF',
    Company: 'Cvb Financial Corp'
  },
  {
    Ticker: 'CVCO',
    Company: 'Cavco Inds Inc'
  },
  {
    Ticker: 'CVCY',
    Company: 'Central Vly Cmty Bcp'
  },
  {
    Ticker: 'CVET',
    Company: 'Covetrus Inc'
  },
  {
    Ticker: 'CVGI',
    Company: 'Commercial Vehicle G'
  },
  {
    Ticker: 'CVGW',
    Company: 'Calavo Growers Inc'
  },
  {
    Ticker: 'CVLT',
    Company: 'Commvault Systems'
  },
  {
    Ticker: 'CVLY',
    Company: 'Codorus Valley Bncp'
  },
  {
    Ticker: 'CVTI',
    Company: 'Covenant Transportn'
  },
  {
    Ticker: 'CVV',
    Company: 'CVD Equipment Corp'
  },
  {
    Ticker: 'CWBC',
    Company: 'Community West Bncsh'
  },
  {
    Ticker: 'CWBR',
    Company: 'Cohbar Inc'
  },
  {
    Ticker: 'CWCO',
    Company: 'Cons Water Co. Inc'
  },
  {
    Ticker: 'CWST',
    Company: 'Casella Waste Sys'
  },
  {
    Ticker: 'CXDC',
    Company: 'China Xd Plastics Company'
  },
  {
    Ticker: 'CXSE',
    Company: 'Wisdomtree China Ex-Cso Fund'
  },
  {
    Ticker: 'CY',
    Company: 'Cypress Semiconductor'
  },
  {
    Ticker: 'CYAD',
    Company: 'Celyad Sa Ads'
  },
  {
    Ticker: 'CYAN',
    Company: 'Cyanotech Corp'
  },
  {
    Ticker: 'CYBE',
    Company: 'Cyberoptics Cp'
  },
  {
    Ticker: 'CYBR',
    Company: 'Cyberark Soft Ord'
  },
  {
    Ticker: 'CYCC',
    Company: 'Cyclacel Pharmaceuti'
  },
  {
    Ticker: 'CYCCP',
    Company: 'Cyclacel Pharmaceuti'
  },
  {
    Ticker: 'CYCN',
    Company: 'Cyclerion Therapeutics Inc'
  },
  {
    Ticker: 'CYOU',
    Company: 'Changyou.com Ltd'
  },
  {
    Ticker: 'CYRN',
    Company: 'Cyren Ltd'
  },
  {
    Ticker: 'CYRX',
    Company: 'Cryoport Inc'
  },
  {
    Ticker: 'CYRXW',
    Company: 'Cryoport Inc WT'
  },
  {
    Ticker: 'CYTK',
    Company: 'Cytokinetics'
  },
  {
    Ticker: 'CZNC',
    Company: 'Citizens Nrthn Cp'
  },
  {
    Ticker: 'CZR',
    Company: 'Caesars Entertainment Corp'
  },
  {
    Ticker: 'CZWI',
    Company: 'Citizens Community'
  },
  {
    Ticker: 'DAIO',
    Company: 'Data I O Cp'
  },
  {
    Ticker: 'DAKT',
    Company: 'Daktronics Inc'
  },
  {
    Ticker: 'DALI',
    Company: 'First Trust Dorseywright Dali 1 ETF'
  },
  {
    Ticker: 'DARE',
    Company: 'Dare Bioscience Inc'
  },
  {
    Ticker: 'DAX',
    Company: 'DAX Germany ETF'
  },
  {
    Ticker: 'DBVT',
    Company: 'Dbv Technologies Ads'
  },
  {
    Ticker: 'DBX',
    Company: 'Dropbox Inc'
  },
  {
    Ticker: 'DCAR',
    Company: 'Dropcar Inc'
  },
  {
    Ticker: 'DCIX',
    Company: 'Diana Containerships Inc'
  },
  {
    Ticker: 'DCOM',
    Company: 'Dime Community Bncsh'
  },
  {
    Ticker: 'DCPH',
    Company: 'Deciphera Pharmaceuticals Inc'
  },
  {
    Ticker: 'DDIV',
    Company: 'First Trust High Income ETF'
  },
  {
    Ticker: 'DDMX',
    Company: 'Dd3 Acquisition Corp'
  },
  {
    Ticker: 'DDMXU',
    Company: 'Dd3 Acquisition Corp Units'
  },
  {
    Ticker: 'DDMXW',
    Company: 'Dd3 Acquisition Corp WT'
  },
  {
    Ticker: 'DDOG',
    Company: 'Datadog Inc Cl A'
  },
  {
    Ticker: 'DEAC',
    Company: 'Diamond Eagle Acquisition Cl A'
  },
  {
    Ticker: 'DEACU',
    Company: 'Diamond Eagle Acquisition Corp Unit'
  },
  {
    Ticker: 'DEACW',
    Company: 'Diamond Eagle Acquisition Corp WT'
  },
  {
    Ticker: 'DENN',
    Company: 'Dennys Corp'
  },
  {
    Ticker: 'DERM',
    Company: 'Dermira Inc'
  },
  {
    Ticker: 'DFFN',
    Company: 'Diffusion Pharmaceuticals Inc'
  },
  {
    Ticker: 'DFNL',
    Company: 'Davis Select Financial ETF'
  },
  {
    Ticker: 'DFNSU',
    Company: 'LGL Systems Acquisition Corp. Units'
  },
  {
    Ticker: 'DGICA',
    Company: 'Donegal Group Cl A'
  },
  {
    Ticker: 'DGICB',
    Company: 'Donegal Group Cl B'
  },
  {
    Ticker: 'DGII',
    Company: 'Digi Intl Inc'
  },
  {
    Ticker: 'DGLD',
    Company: 'VS 3X Inv Gold'
  },
  {
    Ticker: 'DGLY',
    Company: 'Digital Ally Inc'
  },
  {
    Ticker: 'DGRE',
    Company: 'Wisdomtree EM Quality Divd Growth Fund'
  },
  {
    Ticker: 'DGRS',
    Company: 'Wisdomtree US Smallcap Quality Dvid Fund'
  },
  {
    Ticker: 'DGRW',
    Company: 'Wisdomtree US Quality Divd Fund'
  },
  {
    Ticker: 'DHIL',
    Company: 'Diamond Hill Inv'
  },
  {
    Ticker: 'DHXM',
    Company: 'Dhx Media Varbl Vtg'
  },
  {
    Ticker: 'DINT',
    Company: 'Davis Select International ETF'
  },
  {
    Ticker: 'DIOD',
    Company: 'Diodes Inc'
  },
  {
    Ticker: 'DISCA',
    Company: 'Discovery Comm A'
  },
  {
    Ticker: 'DISCB',
    Company: 'Discovery Comm B'
  },
  {
    Ticker: 'DISCK',
    Company: 'Discovery Comm Inc'
  },
  {
    Ticker: 'DISH',
    Company: 'Dish Network Corp'
  },
  {
    Ticker: 'DISHR',
    Company: 'Dish Network Corporation Subscription Rights'
  },
  {
    Ticker: 'DISHV',
    Company: 'Dish Network Corp Cl A'
  },
  {
    Ticker: 'DJCO',
    Company: 'Daily Journal Cp'
  },
  {
    Ticker: 'DLHC',
    Company: 'Dlh Holdings Corp'
  },
  {
    Ticker: 'DLPN',
    Company: 'Dolphin Entertainment Inc'
  },
  {
    Ticker: 'DLPNW',
    Company: 'Dolphin Entertainment Inc WT'
  },
  {
    Ticker: 'DLTH',
    Company: 'Duluth Hld Cl B'
  },
  {
    Ticker: 'DLTR',
    Company: 'Dollar Tree Inc'
  },
  {
    Ticker: 'DMAC',
    Company: 'Diamedica Therapeutics Inc'
  },
  {
    Ticker: 'DMLP',
    Company: 'Dorchester Minls'
  },
  {
    Ticker: 'DMPI',
    Company: 'Delmar Pharmaceuticals Inc'
  },
  {
    Ticker: 'DMRC',
    Company: 'Digimarc Corp'
  },
  {
    Ticker: 'DMTK',
    Company: 'Dermtech Inc'
  },
  {
    Ticker: 'DNBF',
    Company: 'Dnb Finl Corp'
  },
  {
    Ticker: 'DNKN',
    Company: "Dunkin' Brands Group"
  },
  {
    Ticker: 'DNLI',
    Company: 'Denali Therapeutics Inc'
  },
  {
    Ticker: 'DOCU',
    Company: 'Docusign Inc'
  },
  {
    Ticker: 'DOGZ',
    Company: 'Dogness Corp Cl A'
  },
  {
    Ticker: 'DOMO',
    Company: 'Domo Inc Cl B'
  },
  {
    Ticker: 'DOOO',
    Company: 'Brp Inc'
  },
  {
    Ticker: 'DORM',
    Company: 'Dorman Products Inc'
  },
  {
    Ticker: 'DOX',
    Company: 'Amdocs Ltd Ord'
  },
  {
    Ticker: 'DOYU',
    Company: 'Douyu International Holdings Ltd Ads'
  },
  {
    Ticker: 'DPHC',
    Company: 'Diamondpeak Holdings Corp Cl A'
  },
  {
    Ticker: 'DPHCU',
    Company: 'Diamondpeak Holdings Corp Units'
  },
  {
    Ticker: 'DPHCW',
    Company: 'Diamondpeak Holdings Corp Wt6'
  },
  {
    Ticker: 'DRAD',
    Company: 'Digirad Corp'
  },
  {
    Ticker: 'DRADP',
    Company: 'Digirad Corp Series A Cumulative Perpetua'
  },
  {
    Ticker: 'DRIO',
    Company: 'Dario Health Corp'
  },
  {
    Ticker: 'DRIOW',
    Company: 'Labstyle Innovati WT'
  },
  {
    Ticker: 'DRIV',
    Company: 'Global X Autonomous & Electric Vehicles ETF'
  },
  {
    Ticker: 'DRNA',
    Company: 'Dicerna Pharmaceutic'
  },
  {
    Ticker: 'DRRX',
    Company: 'Durect Corp'
  },
  {
    Ticker: 'DRTT',
    Company: 'Dirtt Environmental Solutions Ltd'
  },
  {
    Ticker: 'DSGX',
    Company: 'Descartes Sys Group'
  },
  {
    Ticker: 'DSKE',
    Company: 'Daseke Inc'
  },
  {
    Ticker: 'DSKEW',
    Company: 'Daseke Inc'
  },
  {
    Ticker: 'DSLV',
    Company: 'VS 3X Inv Silver'
  },
  {
    Ticker: 'DSPG',
    Company: 'D S P Group Inc'
  },
  {
    Ticker: 'DSWL',
    Company: 'Deswell Inds Inc'
  },
  {
    Ticker: 'DTEA',
    Company: 'Davidstea Inc'
  },
  {
    Ticker: 'DTIL',
    Company: 'Precision Biosciences Inc'
  },
  {
    Ticker: 'DTSS',
    Company: 'Datasea Inc'
  },
  {
    Ticker: 'DUO',
    Company: 'Fangdd Network Group Ltd American Depositary Sha'
  },
  {
    Ticker: 'DUSA',
    Company: 'Davis Select USA ETF'
  },
  {
    Ticker: 'DVAX',
    Company: 'Dynavax Technologies'
  },
  {
    Ticker: 'DVLU',
    Company: 'First Trust Momentum & Value ETF'
  },
  {
    Ticker: 'DVOL',
    Company: 'First Trust Momentum & Low Volatility ETF'
  },
  {
    Ticker: 'DVY',
    Company: 'Select Dividend Ishares ETF'
  },
  {
    Ticker: 'DWAQ',
    Company: 'DWA Nasdaq Momentum Invesco ETF'
  },
  {
    Ticker: 'DWAS',
    Company: 'DWA Smallcap Momentum Invesco ETF'
  },
  {
    Ticker: 'DWAT',
    Company: 'Arrow DWA Tactic ETF'
  },
  {
    Ticker: 'DWCR',
    Company: 'Arrow DWA Country Rotation ETF'
  },
  {
    Ticker: 'DWFI',
    Company: 'DW Fixed Income Allocation ETF SPDR'
  },
  {
    Ticker: 'DWIN',
    Company: 'DWA Tactical Multi-Asset Income Invesco ETF'
  },
  {
    Ticker: 'DWLD',
    Company: 'Davis Select World Opportunities ETF'
  },
  {
    Ticker: 'DWMC',
    Company: 'Advisorshares D.W. Micro-Cap ETF'
  },
  {
    Ticker: 'DWPP',
    Company: "First Trust Dorsey Wright People's Portfolio ETF"
  },
  {
    Ticker: 'DWSH',
    Company: 'Advisorshares D.W. Short ETF'
  },
  {
    Ticker: 'DWSN',
    Company: 'Dawson Geophscl'
  },
  {
    Ticker: 'DWTR',
    Company: 'DWA Tactical Sector Rotation Invesco ETF'
  },
  {
    Ticker: 'DXCM',
    Company: 'Dexcom'
  },
  {
    Ticker: 'DXGE',
    Company: 'Wisdomtree Germany Hedged Eqty Fund'
  },
  {
    Ticker: 'DXJS',
    Company: 'Wisdomtree Japan Smallcap Eqty Hedged Fund'
  },
  {
    Ticker: 'DXLG',
    Company: 'Destination XL Group'
  },
  {
    Ticker: 'DXPE',
    Company: 'Dxp Enterprise'
  },
  {
    Ticker: 'DXYN',
    Company: 'The Dixie Group'
  },
  {
    Ticker: 'DYAI',
    Company: 'Dyadic International'
  },
  {
    Ticker: 'DYNT',
    Company: 'Dynatronics Cp'
  },
  {
    Ticker: 'DZSI',
    Company: 'Dasan Zhone Solutions Inc'
  },
  {
    Ticker: 'EA',
    Company: 'Electronic Arts Inc'
  },
  {
    Ticker: 'EARS',
    Company: 'Auris Medical'
  },
  {
    Ticker: 'EAST',
    Company: 'Eastside Distilling Inc'
  },
  {
    Ticker: 'EBAY',
    Company: 'Ebay Inc'
  },
  {
    Ticker: 'EBAYL',
    Company: 'Ebay Inc 6.0% NTS'
  },
  {
    Ticker: 'EBIX',
    Company: 'Ebix Inc'
  },
  {
    Ticker: 'EBIZ',
    Company: 'Global X E-Commerce ETF'
  },
  {
    Ticker: 'EBMT',
    Company: 'Eagle Bancorp [Mt]'
  },
  {
    Ticker: 'EBSB',
    Company: 'Meridian Bncp'
  },
  {
    Ticker: 'EBTC',
    Company: 'Enterprise Bancorp'
  },
  {
    Ticker: 'ECHO',
    Company: 'Echo Global Logistic'
  },
  {
    Ticker: 'ECOL',
    Company: 'US Ecology Inc'
  },
  {
    Ticker: 'ECOLW',
    Company: 'US Ecology Inc WT'
  },
  {
    Ticker: 'ECOR',
    Company: 'Electrocore Inc'
  },
  {
    Ticker: 'ECOW',
    Company: 'Pacer Emerging Markets Cash Cows 100 ETF'
  },
  {
    Ticker: 'ECPG',
    Company: 'Encore Capital Group'
  },
  {
    Ticker: 'EDAP',
    Company: 'Edap Tms Sa ADR'
  },
  {
    Ticker: 'EDIT',
    Company: 'Editas Medicine'
  },
  {
    Ticker: 'EDNT',
    Company: 'Edison Nation Inc'
  },
  {
    Ticker: 'EDRY',
    Company: 'Eurodry Ltd'
  },
  {
    Ticker: 'EDSA',
    Company: 'Edesa Biotech Inc'
  },
  {
    Ticker: 'EDTX',
    Company: 'Edtechx Holdings Acquisition Corp'
  },
  {
    Ticker: 'EDTXU',
    Company: 'Edtechx Holdings Acquisition Corp Unit'
  },
  {
    Ticker: 'EDTXW',
    Company: 'Edtechx Holdings Acquisition Corp WT'
  },
  {
    Ticker: 'EDUC',
    Company: 'Educational Dev Cp'
  },
  {
    Ticker: 'EEFT',
    Company: 'Euronet Worldwide'
  },
  {
    Ticker: 'EEI',
    Company: 'Ecology Environment'
  },
  {
    Ticker: 'EEMA',
    Company: 'Emrg Mkts Asia Ishares MSCI ETF'
  },
  {
    Ticker: 'EFAS',
    Company: 'G-X MSCI Superdividend EAFE ETF'
  },
  {
    Ticker: 'EFBI',
    Company: 'Eagle Financial Bancorp Inc'
  },
  {
    Ticker: 'EFOI',
    Company: 'Energy Focus'
  },
  {
    Ticker: 'EFSC',
    Company: 'Enterprises Finl Svc'
  },
  {
    Ticker: 'EGAN',
    Company: 'Egain Corp'
  },
  {
    Ticker: 'EGBN',
    Company: 'Eagle Bancorp Inc'
  },
  {
    Ticker: 'EGLE',
    Company: 'Eagle Bulk Ship'
  },
  {
    Ticker: 'EGOV',
    Company: 'Nic Inc'
  },
  {
    Ticker: 'EGRX',
    Company: 'Eagle Pharmaceuticals'
  },
  {
    Ticker: 'EHTH',
    Company: 'Ehealth Inc'
  },
  {
    Ticker: 'EIDX',
    Company: 'Eidos Therapeutics Inc'
  },
  {
    Ticker: 'EIGI',
    Company: 'Endurance Int Group'
  },
  {
    Ticker: 'EIGR',
    Company: 'Eiger Biopharma'
  },
  {
    Ticker: 'EKSO',
    Company: 'Ekso Bionics Holdings Inc'
  },
  {
    Ticker: 'ELGX',
    Company: 'Endologix Inc'
  },
  {
    Ticker: 'ELOX',
    Company: 'Eloxx Pharmaceuticals Inc'
  },
  {
    Ticker: 'ELSE',
    Company: 'Electro Sensors IN'
  },
  {
    Ticker: 'ELTK',
    Company: 'Eltek Ltd'
  },
  {
    Ticker: 'EMB',
    Company: 'USD Emrg Mkts Bond Ishares JPM ETF'
  },
  {
    Ticker: 'EMCB',
    Company: 'Wisdomtree EM Corp Bond Fund'
  },
  {
    Ticker: 'EMCF',
    Company: 'Emclaire Finl Cor'
  },
  {
    Ticker: 'EMCG',
    Company: 'Wisdomtree EM Consumer Growth'
  },
  {
    Ticker: 'EMIF',
    Company: 'Emrg Mkts Infrastructure Ishares ETF'
  },
  {
    Ticker: 'EMKR',
    Company: 'Emcore Corp'
  },
  {
    Ticker: 'EML',
    Company: 'Eastern Company'
  },
  {
    Ticker: 'EMMS',
    Company: 'Emmis Commun Cl A'
  },
  {
    Ticker: 'EMXC',
    Company: 'Emrg Mkts Ex China Ishares MSCI ETF'
  },
  {
    Ticker: 'ENDP',
    Company: "Endo Int'l Plc"
  },
  {
    Ticker: 'ENFC',
    Company: 'Entegra Financial Corp'
  },
  {
    Ticker: 'ENG',
    Company: 'Englobal Corp'
  },
  {
    Ticker: 'ENLV',
    Company: 'Enlivex Therapeutics Ltd'
  },
  {
    Ticker: 'ENOB',
    Company: 'Enochian Biosciences Inc'
  },
  {
    Ticker: 'ENPH',
    Company: 'Enphase Energy Inc'
  },
  {
    Ticker: 'ENSG',
    Company: 'The Ensign Group IN'
  },
  {
    Ticker: 'ENT',
    Company: 'Global Eagle Entrtn'
  },
  {
    Ticker: 'ENTA',
    Company: 'Enanta Pharmaceutica'
  },
  {
    Ticker: 'ENTG',
    Company: 'Entegris Inc'
  },
  {
    Ticker: 'ENTX',
    Company: 'Entera Bio Ltd'
  },
  {
    Ticker: 'ENTXW',
    Company: 'Entera Bio Ltd WT'
  },
  {
    Ticker: 'ENZL',
    Company: 'New Zealand Ishares MSCI ETF'
  },
  {
    Ticker: 'EOLS',
    Company: 'Evolus Inc'
  },
  {
    Ticker: 'EPAY',
    Company: 'Bottomline Tech IN'
  },
  {
    Ticker: 'EPIX',
    Company: 'Essa Pharma'
  },
  {
    Ticker: 'EPSN',
    Company: 'Epsilon Energy'
  },
  {
    Ticker: 'EPZM',
    Company: 'Epizyme Inc'
  },
  {
    Ticker: 'EQ',
    Company: 'Equillium Inc'
  },
  {
    Ticker: 'EQBK',
    Company: 'Equity Bncsrs Cm A'
  },
  {
    Ticker: 'EQIX',
    Company: 'Equinix Inc'
  },
  {
    Ticker: 'EQRR',
    Company: 'Proshares Trust Proshares Equities For Rising Ra'
  },
  {
    Ticker: 'ERI',
    Company: 'Eldorado Resorts'
  },
  {
    Ticker: 'ERIC',
    Company: 'Ericsson ADR'
  },
  {
    Ticker: 'ERIE',
    Company: 'Erie Indemnity Company'
  },
  {
    Ticker: 'ERII',
    Company: 'Energy Recovery Inc'
  },
  {
    Ticker: 'ERYP',
    Company: 'Erytech Pharma'
  },
  {
    Ticker: 'ESBK',
    Company: 'Elmira Sav Bk'
  },
  {
    Ticker: 'ESCA',
    Company: 'Escalade Inc'
  },
  {
    Ticker: 'ESEA',
    Company: 'Euroseas Ltd'
  },
  {
    Ticker: 'ESGD',
    Company: 'EAFE ESG Optimized Ishares MSCI ETF'
  },
  {
    Ticker: 'ESGE',
    Company: 'Emrg Mkts ESG Optimized Ishares MSCI ETF'
  },
  {
    Ticker: 'ESGR',
    Company: 'Enstar Group Ltd'
  },
  {
    Ticker: 'ESGRO',
    Company: 'Enstar Group Ltd Depository Shares 7.00% Per'
  },
  {
    Ticker: 'ESGRP',
    Company: 'Enstar Group Ltd'
  },
  {
    Ticker: 'ESGU',
    Company: 'USA ESG Optimized Ishares MSCI ETF'
  },
  {
    Ticker: 'ESLT',
    Company: 'Elbit Systems Ltd'
  },
  {
    Ticker: 'ESPO',
    Company: 'Vaneck Vectors Video Gaming and Esports ETF'
  },
  {
    Ticker: 'ESPR',
    Company: 'Esperion Theraptc'
  },
  {
    Ticker: 'ESQ',
    Company: 'Esquire Financial Holdings Inc'
  },
  {
    Ticker: 'ESSA',
    Company: 'Essa Bancorp Inc'
  },
  {
    Ticker: 'ESTA',
    Company: 'Establishment Labs Holdings Inc'
  },
  {
    Ticker: 'ESTR',
    Company: 'Estre Ambiental Inc'
  },
  {
    Ticker: 'ESTRW',
    Company: 'Estre Ambiental Inc WT'
  },
  {
    Ticker: 'ESXB',
    Company: 'Community Bankers'
  },
  {
    Ticker: 'ETFC',
    Company: 'E*Trade Finl Corp'
  },
  {
    Ticker: 'ETNB',
    Company: '89Bio Inc'
  },
  {
    Ticker: 'ETON',
    Company: 'Eton Pharmaceutcials Inc'
  },
  {
    Ticker: 'ETSY',
    Company: 'Etsy Inc'
  },
  {
    Ticker: 'ETTX',
    Company: 'Entasis Therapeutics Holdings Inc'
  },
  {
    Ticker: 'EUFN',
    Company: 'Europe Financials Ishares MSCI ETF'
  },
  {
    Ticker: 'EVBG',
    Company: 'Everbridge Inc'
  },
  {
    Ticker: 'EVER',
    Company: 'Everquote Inc Cl A'
  },
  {
    Ticker: 'EVFM',
    Company: 'Evofem Biosciences Inc'
  },
  {
    Ticker: 'EVGBC',
    Company: 'Eaton Vance Nextshares Trust Eaton Vance Global'
  },
  {
    Ticker: 'EVGN',
    Company: 'Evogene Ltd Ord'
  },
  {
    Ticker: 'EVK',
    Company: 'Ever Glory Intl Group'
  },
  {
    Ticker: 'EVLMC',
    Company: 'Eaton Vance Nextshares Trust II Eaton Vance Tabs'
  },
  {
    Ticker: 'EVLO',
    Company: 'Evelo Biosciences Inc'
  },
  {
    Ticker: 'EVOK',
    Company: 'Evoke Pharma'
  },
  {
    Ticker: 'EVOL',
    Company: 'Evolving Systems Inc'
  },
  {
    Ticker: 'EVOP',
    Company: 'Evo Payments Inc Cl A'
  },
  {
    Ticker: 'EVSI',
    Company: 'Envision Solar International Inc'
  },
  {
    Ticker: 'EVSIW',
    Company: 'Envision Solar Intl Inc WT'
  },
  {
    Ticker: 'EVSTC',
    Company: 'Eaton Vance Nextshares Trust Eaton Vance Stock N'
  },
  {
    Ticker: 'EWBC',
    Company: 'East West Bancorp'
  },
  {
    Ticker: 'EWJE',
    Company: 'Ishares MSCI Japan Equal Weighted ETF'
  },
  {
    Ticker: 'EWJV',
    Company: 'Ishares MSCI Japan Value ETF'
  },
  {
    Ticker: 'EWZS',
    Company: 'Brazil Small-Cap Ishares MSCI ETF'
  },
  {
    Ticker: 'EXAS',
    Company: 'Exact Sciences Cor'
  },
  {
    Ticker: 'EXC',
    Company: 'Exelon Corp'
  },
  {
    Ticker: 'EXEL',
    Company: 'Exelixis Inc'
  },
  {
    Ticker: 'EXFO',
    Company: 'Exfo Inc'
  },
  {
    Ticker: 'EXLS',
    Company: 'Exlservice Holdings'
  },
  {
    Ticker: 'EXPC',
    Company: 'Experience Investment Corp. Cl A'
  },
  {
    Ticker: 'EXPCU',
    Company: 'Experience Investment Corp'
  },
  {
    Ticker: 'EXPD',
    Company: 'Expeditors Intl'
  },
  {
    Ticker: 'EXPE',
    Company: 'Expedia Group Inc'
  },
  {
    Ticker: 'EXPI',
    Company: 'Exp Realty International'
  },
  {
    Ticker: 'EXPO',
    Company: 'Exponent Inc'
  },
  {
    Ticker: 'EXTR',
    Company: 'Extreme Networks'
  },
  {
    Ticker: 'EYE',
    Company: 'National Vision Holdings Inc'
  },
  {
    Ticker: 'EYEG',
    Company: 'Eyegate Pharma'
  },
  {
    Ticker: 'EYEGW',
    Company: 'Eyegate Pharm WT'
  },
  {
    Ticker: 'EYEN',
    Company: 'Eyenovia Inc'
  },
  {
    Ticker: 'EYES',
    Company: 'Second Sight'
  },
  {
    Ticker: 'EYESW',
    Company: 'Second Sight Medical Products Inc WT'
  },
  {
    Ticker: 'EYPT',
    Company: 'Eyepoint Pharmaceuticals Inc'
  },
  {
    Ticker: 'EZPW',
    Company: 'Ezcorp Inc'
  },
  {
    Ticker: 'FAAR',
    Company: 'Alternative Absolute Return Strategy ETF FT'
  },
  {
    Ticker: 'FAB',
    Company: 'Multi Cap Value Alphadex Fund FT'
  },
  {
    Ticker: 'FAD',
    Company: 'Multicap Growth Alphadex Fund FT'
  },
  {
    Ticker: 'FALN',
    Company: 'US Fallen Angels Ishares ETF'
  },
  {
    Ticker: 'FAMI',
    Company: 'Farmmi Inc'
  },
  {
    Ticker: 'FANG',
    Company: 'Diamondback Energy'
  },
  {
    Ticker: 'FANH',
    Company: 'Fanhua Inc'
  },
  {
    Ticker: 'FARM',
    Company: 'Farmer Brothers'
  },
  {
    Ticker: 'FARO',
    Company: 'Faro Tech Inc'
  },
  {
    Ticker: 'FAST',
    Company: 'Fastenal Company'
  },
  {
    Ticker: 'FAT',
    Company: 'Fat Brands Inc'
  },
  {
    Ticker: 'FATE',
    Company: 'Fate Therapeutics'
  },
  {
    Ticker: 'FB',
    Company: 'Facebook Inc'
  },
  {
    Ticker: 'FBIO',
    Company: 'Fortress Biotech Inc'
  },
  {
    Ticker: 'FBIOP',
    Company: 'Fortress Biotech Inc 9.375% Series A Cumulativ'
  },
  {
    Ticker: 'FBIZ',
    Company: 'First Business Finan'
  },
  {
    Ticker: 'FBMS',
    Company: 'First Bancshs [Ms]'
  },
  {
    Ticker: 'FBNC',
    Company: 'First Bancorp [Nc]'
  },
  {
    Ticker: 'FBSS',
    Company: 'Fauquier Bankshares'
  },
  {
    Ticker: 'FBZ',
    Company: 'Brazil Alphadex Fund FT'
  },
  {
    Ticker: 'FCA',
    Company: 'China Alphadex Fund FT'
  },
  {
    Ticker: 'FCAL',
    Company: 'First Trust Exchange-Traded Fund III First Trust'
  },
  {
    Ticker: 'FCAN',
    Company: 'Canada Alphadex Fund FT'
  },
  {
    Ticker: 'FCAP',
    Company: 'First Capital Inc'
  },
  {
    Ticker: 'FCBC',
    Company: 'First Community Bksh'
  },
  {
    Ticker: 'FCBP',
    Company: 'First Choice Bancorp'
  },
  {
    Ticker: 'FCCO',
    Company: 'First Community Corp'
  },
  {
    Ticker: 'FCCY',
    Company: '1st Constitution Bcp'
  },
  {
    Ticker: 'FCEF',
    Company: 'CEF Income Opportunity ETF FT'
  },
  {
    Ticker: 'FCEL',
    Company: 'Fuelcell Energy Inc'
  },
  {
    Ticker: 'FCFS',
    Company: 'First Cash Fin Svcs'
  },
  {
    Ticker: 'FCNCA',
    Company: 'First Citizens Bancs'
  },
  {
    Ticker: 'FCSC',
    Company: 'Fibrocell Science'
  },
  {
    Ticker: 'FCVT',
    Company: 'Ssi Strategic Convertible Securities ETF FT'
  },
  {
    Ticker: 'FDBC',
    Company: 'Fidelity D & D Bancorp Inc'
  },
  {
    Ticker: 'FDEF',
    Company: 'First Defiance Finl'
  },
  {
    Ticker: 'FDIV',
    Company: 'Strategic Income ETF FT'
  },
  {
    Ticker: 'FDNI',
    Company: 'FT DJ International Internet ETF'
  },
  {
    Ticker: 'FDT',
    Company: 'Dev Mkts Ex-US Alphadex Fund FT'
  },
  {
    Ticker: 'FDTS',
    Company: 'Dev Mkts Ex-US Small Cap Alphadex Fund FT'
  },
  {
    Ticker: 'FDUS',
    Company: 'Fidus Investment Cor'
  },
  {
    Ticker: 'FDUSG',
    Company: 'Fidus Investment Corp 5.375% Notes Due 20'
  },
  {
    Ticker: 'FDUSL',
    Company: 'Fidus Investment Corp 5.875% Notes Due 20'
  },
  {
    Ticker: 'FDUSZ',
    Company: 'Fidus Investment Corp 6% Notes Due 2024'
  },
  {
    Ticker: 'FEIM',
    Company: 'Frequency Elcts Inc'
  },
  {
    Ticker: 'FELE',
    Company: 'Franklin Electric Company'
  },
  {
    Ticker: 'FEM',
    Company: 'Emrg Mkts Alphadex Fund FT'
  },
  {
    Ticker: 'FEMB',
    Company: 'Emrg Mkts Local Currency Bond ETF FT'
  },
  {
    Ticker: 'FEMS',
    Company: 'Emrg Mkts Small Cap Alphadex Fund FT'
  },
  {
    Ticker: 'FENC',
    Company: 'Fennec Pharmaceuticals Inc'
  },
  {
    Ticker: 'FEP',
    Company: 'Europe Alphadex Fund FT'
  },
  {
    Ticker: 'FEUZ',
    Company: 'Eurozone Alphadex Fund FT'
  },
  {
    Ticker: 'FEX',
    Company: 'Largecap Core Alphadex Fund FT'
  },
  {
    Ticker: 'FEYE',
    Company: 'Fireeye Inc'
  },
  {
    Ticker: 'FFBC',
    Company: 'First Finl Bncp [Oh]'
  },
  {
    Ticker: 'FFBW',
    Company: 'Ffbw Inc'
  },
  {
    Ticker: 'FFHL',
    Company: 'Fuwei Films [Holding'
  },
  {
    Ticker: 'FFIC',
    Company: 'Flushing Finl Corp'
  },
  {
    Ticker: 'FFIN',
    Company: 'First Finl Bkshs Inc'
  },
  {
    Ticker: 'FFIV',
    Company: 'F5 Networks Inc'
  },
  {
    Ticker: 'FFNW',
    Company: 'First Financial Nort'
  },
  {
    Ticker: 'FFWM',
    Company: 'First Foundation'
  },
  {
    Ticker: 'FGBI',
    Company: 'First Gurty Banc'
  },
  {
    Ticker: 'FGEN',
    Company: 'Fibrogen Inc CS'
  },
  {
    Ticker: 'FGM',
    Company: 'Germany Alphadex Fund FT'
  },
  {
    Ticker: 'FHB',
    Company: 'First Hawaiian Inc'
  },
  {
    Ticker: 'FHK',
    Company: 'Hong Kong Alphadex Fund FT'
  },
  {
    Ticker: 'FIBK',
    Company: 'First Interstate Ban'
  },
  {
    Ticker: 'FID',
    Company: 'First Trust S&P Intl Dividend Aristocrats ETF'
  },
  {
    Ticker: 'FINX',
    Company: 'G-X Fintech Thematic ETF'
  },
  {
    Ticker: 'FISI',
    Company: 'Financial Institut'
  },
  {
    Ticker: 'FISV',
    Company: 'Fiserv Inc'
  },
  {
    Ticker: 'FITB',
    Company: 'Fifth Third Bncp'
  },
  {
    Ticker: 'FITBI',
    Company: 'Fifth Third Bancorp'
  },
  {
    Ticker: 'FITBO',
    Company: 'Fifth Third Bancorp 4.95% Prf Perpetual USD 25 S'
  },
  {
    Ticker: 'FITBP',
    Company: 'Fifth Third Bancorp Depositary Shares 1/40Th'
  },
  {
    Ticker: 'FIVE',
    Company: 'Five Below Inc'
  },
  {
    Ticker: 'FIVN',
    Company: 'Five9 Inc'
  },
  {
    Ticker: 'FIXD',
    Company: 'Tcw Opportunistic Fixed Income ETF FT'
  },
  {
    Ticker: 'FIXX',
    Company: 'Homology Medicines Inc'
  },
  {
    Ticker: 'FIZZ',
    Company: 'Natl Beverage Cp'
  },
  {
    Ticker: 'FJP',
    Company: 'Japan Alphadex Fund FT'
  },
  {
    Ticker: 'FKO',
    Company: 'South Korea Alphadex Fund FT'
  },
  {
    Ticker: 'FKU',
    Company: 'UK Alphadex Fund FT'
  },
  {
    Ticker: 'FLDM',
    Company: 'Fluidigm Corp'
  },
  {
    Ticker: 'FLEX',
    Company: 'Flextronics Intl Ltd'
  },
  {
    Ticker: 'FLGT',
    Company: 'Fulgent Genetics Inc'
  },
  {
    Ticker: 'FLIC',
    Company: 'First of Long Isla'
  },
  {
    Ticker: 'FLIR',
    Company: 'Flir Systems Inc'
  },
  {
    Ticker: 'FLL',
    Company: 'Full House Rsts'
  },
  {
    Ticker: 'FLLC',
    Company: 'Fellazo Inc'
  },
  {
    Ticker: 'FLLCR',
    Company: 'Fellazo Inc Right'
  },
  {
    Ticker: 'FLLCU',
    Company: 'Fellazo Inc Unit'
  },
  {
    Ticker: 'FLLCW',
    Company: 'Fellazo Inc WT'
  },
  {
    Ticker: 'FLMN',
    Company: 'Falcon Minerals Corp Cl A'
  },
  {
    Ticker: 'FLMNW',
    Company: 'Falcon Minerals Corp WT'
  },
  {
    Ticker: 'FLN',
    Company: 'Latin America Alphadex Fund FT'
  },
  {
    Ticker: 'FLNT',
    Company: 'Fluent Inc'
  },
  {
    Ticker: 'FLWS',
    Company: '1-800-Flowers.com'
  },
  {
    Ticker: 'FLXN',
    Company: 'Flexion Therapeutics'
  },
  {
    Ticker: 'FLXS',
    Company: 'Flexsteel Inds'
  },
  {
    Ticker: 'FMAO',
    Company: 'Farmers & Merch Banc'
  },
  {
    Ticker: 'FMB',
    Company: 'Managed Municipal ETF FT'
  },
  {
    Ticker: 'FMBH',
    Company: 'First Mid Ill Bncshr'
  },
  {
    Ticker: 'FMBI',
    Company: 'First Midwest Bncp'
  },
  {
    Ticker: 'FMCI',
    Company: 'Forum Merger II Corp Cl A'
  },
  {
    Ticker: 'FMCIU',
    Company: 'Forum Merger II Corp Unit'
  },
  {
    Ticker: 'FMCIW',
    Company: 'Forum Merger II Corp WT'
  },
  {
    Ticker: 'FMHI',
    Company: 'FT Municipal High Income ETF'
  },
  {
    Ticker: 'FMK',
    Company: 'Mega Cap Alphadex Fund FT'
  },
  {
    Ticker: 'FMNB',
    Company: 'Farmers Natl Banc Cp'
  },
  {
    Ticker: 'FNCB',
    Company: 'FNCB Bancorp Inc'
  },
  {
    Ticker: 'FNHC',
    Company: 'Federated Natl Hld'
  },
  {
    Ticker: 'FNJN',
    Company: 'Finjan Holdings'
  },
  {
    Ticker: 'FNK',
    Company: 'Mid Cap Value Alphadex Fund FT'
  },
  {
    Ticker: 'FNKO',
    Company: 'Funko Inc Cl A'
  },
  {
    Ticker: 'FNLC',
    Company: 'First Bancorp Inc'
  },
  {
    Ticker: 'FNWB',
    Company: 'First Nw Banc'
  },
  {
    Ticker: 'FNX',
    Company: 'Mid Cap Core Alphadex Fund FT'
  },
  {
    Ticker: 'FNY',
    Company: 'Mid Cap Growth Alphadex Fund FT'
  },
  {
    Ticker: 'FOCS',
    Company: 'Focus Financial Partners Inc Cl A'
  },
  {
    Ticker: 'FOLD',
    Company: 'Amicus Therapeutics'
  },
  {
    Ticker: 'FOMX',
    Company: 'Foamix Pharma Ord'
  },
  {
    Ticker: 'FONR',
    Company: 'Fonar Corp'
  },
  {
    Ticker: 'FORD',
    Company: 'Forward Inds Inc'
  },
  {
    Ticker: 'FORK',
    Company: 'Fuling Global Ord Sh'
  },
  {
    Ticker: 'FORM',
    Company: 'Formfactor Inc'
  },
  {
    Ticker: 'FORR',
    Company: 'Forrester Resrch'
  },
  {
    Ticker: 'FORTY',
    Company: 'Formula Sys [1985]'
  },
  {
    Ticker: 'FOSL',
    Company: 'Fossil Group'
  },
  {
    Ticker: 'FOX',
    Company: 'Fox Corp Cl B'
  },
  {
    Ticker: 'FOXA',
    Company: 'Fox Corp Cl A'
  },
  {
    Ticker: 'FOXF',
    Company: 'Fox Factory Cp'
  },
  {
    Ticker: 'FPA',
    Company: 'Asia Pacific Ex-Japan Alphadex Fund FT'
  },
  {
    Ticker: 'FPAY',
    Company: 'Flexshopper Inc'
  },
  {
    Ticker: 'FPAYW',
    Company: 'Flexshopper Inc WT'
  },
  {
    Ticker: 'FPRX',
    Company: 'Five Prime Thera'
  },
  {
    Ticker: 'FPXE',
    Company: 'First Trust Ipox Europe Equity Opp ETF'
  },
  {
    Ticker: 'FPXI',
    Company: 'International IPO Fund FT'
  },
  {
    Ticker: 'FRAF',
    Company: 'Franklin Finl Svcs Corp'
  },
  {
    Ticker: 'FRAN',
    Company: "Francesca's Holdings"
  },
  {
    Ticker: 'FRBA',
    Company: 'First Bank'
  },
  {
    Ticker: 'FRBK',
    Company: 'Republic First Bcp'
  },
  {
    Ticker: 'FREQ',
    Company: 'Frequency Therapeutics Inc'
  },
  {
    Ticker: 'FRG',
    Company: 'Franchise Group Inc'
  },
  {
    Ticker: 'FRGI',
    Company: 'Fiesta Restaurant Gp'
  },
  {
    Ticker: 'FRHC',
    Company: 'Freedom Holding Corp'
  },
  {
    Ticker: 'FRME',
    Company: 'First Merchants Corp'
  },
  {
    Ticker: 'FRPH',
    Company: 'Frp Holdings Inc'
  },
  {
    Ticker: 'FRPT',
    Company: 'Freshpet Inc CS'
  },
  {
    Ticker: 'FRSX',
    Company: 'Foresight Autonomous Holdings Ltd ADR'
  },
  {
    Ticker: 'FRTA',
    Company: 'Forterra Inc'
  },
  {
    Ticker: 'FSBC',
    Company: 'Fsb Community Bnksh'
  },
  {
    Ticker: 'FSBW',
    Company: 'FS Bancorp Inc'
  },
  {
    Ticker: 'FSCT',
    Company: 'Forescout Technologies Inc'
  },
  {
    Ticker: 'FSEA',
    Company: 'First Seacoast Bancorp'
  },
  {
    Ticker: 'FSFG',
    Company: 'First Savings Financ'
  },
  {
    Ticker: 'FSLR',
    Company: 'First Solar Inc'
  },
  {
    Ticker: 'FSRVU',
    Company: 'Finserv Acquisition Corp'
  },
  {
    Ticker: 'FSTR',
    Company: 'Foster L B Company'
  },
  {
    Ticker: 'FSV',
    Company: 'Firstsrvce Sub VT Sh'
  },
  {
    Ticker: 'FSZ',
    Company: 'Switzerland Alphadex Fund FT'
  },
  {
    Ticker: 'FTA',
    Company: 'Largecap Value Alphadex Fund FT'
  },
  {
    Ticker: 'FTAC',
    Company: 'Fintech Acquisition Corp III Cl A'
  },
  {
    Ticker: 'FTACU',
    Company: 'Fintech Acquisition Corp III Unit'
  },
  {
    Ticker: 'FTACW',
    Company: 'Fintech Acquisition Corp III WT'
  },
  {
    Ticker: 'FTAG',
    Company: 'Indxx Global Agriculture ETF FT'
  },
  {
    Ticker: 'FTC',
    Company: 'Largecap Growth Alphadex Fund FT'
  },
  {
    Ticker: 'FTCS',
    Company: 'Capital Strength ETF FT'
  },
  {
    Ticker: 'FTDR',
    Company: 'Frontdoor Inc'
  },
  {
    Ticker: 'FTEK',
    Company: 'Fuel Tech Inc'
  },
  {
    Ticker: 'FTEO',
    Company: 'Fronteo Inc'
  },
  {
    Ticker: 'FTFT',
    Company: 'Future Fintech Group Inc'
  },
  {
    Ticker: 'FTGC',
    Company: 'Global Tactical Commodity Strategy Fund FT'
  },
  {
    Ticker: 'FTHI',
    Company: 'High Income ETF FT'
  },
  {
    Ticker: 'FTLB',
    Company: 'Low Beta Income ETF FT'
  },
  {
    Ticker: 'FTNT',
    Company: 'Fortinet Inc'
  },
  {
    Ticker: 'FTR',
    Company: 'Frontier Commun Cp'
  },
  {
    Ticker: 'FTRI',
    Company: 'Indxx Global Natural Resources Income ETF FT'
  },
  {
    Ticker: 'FTSL',
    Company: 'Senior Loan ETF FT'
  },
  {
    Ticker: 'FTSM',
    Company: 'Enhanced Short Maturity ETF FT'
  },
  {
    Ticker: 'FTSV',
    Company: 'Forty Seven Inc'
  },
  {
    Ticker: 'FTXD',
    Company: 'Nasdaq Retail ETF FT'
  },
  {
    Ticker: 'FTXG',
    Company: 'Nasdaq Food & Beverage ETF FT'
  },
  {
    Ticker: 'FTXH',
    Company: 'Nasdaq Pharmaceuticals ETF FT'
  },
  {
    Ticker: 'FTXL',
    Company: 'Nasdaq Semiconductor ETF FT'
  },
  {
    Ticker: 'FTXN',
    Company: 'Nasdaq Oil & Gas ETF FT'
  },
  {
    Ticker: 'FTXO',
    Company: 'Nasdaq Bank ETF FT'
  },
  {
    Ticker: 'FTXR',
    Company: 'Nasdaq Transportation ETF FT'
  },
  {
    Ticker: 'FULC',
    Company: 'Fulcrum Therapeutics Inc'
  },
  {
    Ticker: 'FULT',
    Company: 'Fulton Financial Cor'
  },
  {
    Ticker: 'FUNC',
    Company: 'First United Corp'
  },
  {
    Ticker: 'FUND',
    Company: 'Sprott Focus Trust'
  },
  {
    Ticker: 'FUSB',
    Company: 'First US Bancshares Inc'
  },
  {
    Ticker: 'FUTU',
    Company: 'Futu Holdings Ltd'
  },
  {
    Ticker: 'FUV',
    Company: 'Arcimoto Inc'
  },
  {
    Ticker: 'FV',
    Company: 'Dorsey Wright Focus 5 ETF FT'
  },
  {
    Ticker: 'FVC',
    Company: 'Dorsey Wright Dynamic Focus 5 ETF FT'
  },
  {
    Ticker: 'FVCB',
    Company: 'Fvcbankcorp Inc'
  },
  {
    Ticker: 'FVE',
    Company: 'Five Star Quality Care'
  },
  {
    Ticker: 'FWONA',
    Company: 'Liberty Media F1 Series A'
  },
  {
    Ticker: 'FWONK',
    Company: 'Liberty Media F1 Series K'
  },
  {
    Ticker: 'FWP',
    Company: 'Forward Pharma Ads'
  },
  {
    Ticker: 'FWRD',
    Company: 'Foward Air Corp'
  },
  {
    Ticker: 'FXNC',
    Company: 'First Natl Corp Strasburg VA'
  },
  {
    Ticker: 'FYC',
    Company: 'Smallcap Growth Alphadex Fund FT'
  },
  {
    Ticker: 'FYT',
    Company: 'Smallcap Value Alphadex Fund FT'
  },
  {
    Ticker: 'FYX',
    Company: 'Smallcap Core Alphadex Fund FT'
  },
  {
    Ticker: 'GABC',
    Company: 'German Amer Bncp Inc'
  },
  {
    Ticker: 'GAIA',
    Company: 'Gaiam Inc'
  },
  {
    Ticker: 'GAIN',
    Company: 'Gladstone Investment'
  },
  {
    Ticker: 'GAINL',
    Company: 'Gladstone Investment Corp Term Pfd Ser E %'
  },
  {
    Ticker: 'GAINM',
    Company: 'Gladstone Investment Corp'
  },
  {
    Ticker: 'GALT',
    Company: 'Galectin Therapeutic'
  },
  {
    Ticker: 'GARS',
    Company: 'Garrison Capital Inc'
  },
  {
    Ticker: 'GASS',
    Company: 'Stealthgas Inc'
  },
  {
    Ticker: 'GBCI',
    Company: 'Glacier Bancorp Inc'
  },
  {
    Ticker: 'GBDC',
    Company: 'Golub Capital Bdc'
  },
  {
    Ticker: 'GBLI',
    Company: 'Global Indemnity Plc'
  },
  {
    Ticker: 'GBLIL',
    Company: 'Global Indemnity Ltd 7.875% Subordinated Not'
  },
  {
    Ticker: 'GBLIZ',
    Company: 'Global Indmnty 7.75'
  },
  {
    Ticker: 'GBT',
    Company: 'Global Blood Ther'
  },
  {
    Ticker: 'GCBC',
    Company: 'Greene County Bncp'
  },
  {
    Ticker: 'GCVRZ',
    Company: 'Sanofi Cvr'
  },
  {
    Ticker: 'GDEN',
    Company: 'Golden Entmt'
  },
  {
    Ticker: 'GDS',
    Company: 'Gds Holdings Ltd'
  },
  {
    Ticker: 'GEC',
    Company: 'Great Elm Capital Group Inc'
  },
  {
    Ticker: 'GECC',
    Company: 'Great Elm Capital Corp'
  },
  {
    Ticker: 'GECCL',
    Company: 'Great Elm Capital Corp 6.50% Notes Due 2022'
  },
  {
    Ticker: 'GECCM',
    Company: 'Great Elm Capital Corp 6.75% Notes Due 2025'
  },
  {
    Ticker: 'GECCN',
    Company: 'Great Elm Capital Corp 6.5% Notes Due 2024'
  },
  {
    Ticker: 'GEMP',
    Company: 'Gemphire Therapeutics Inc'
  },
  {
    Ticker: 'GENC',
    Company: 'Gencor Industries IN'
  },
  {
    Ticker: 'GENE',
    Company: 'Genetic Tech Spn Ads'
  },
  {
    Ticker: 'GENY',
    Company: 'Principal Millennials Index ETF'
  },
  {
    Ticker: 'GEOS',
    Company: 'Geospace Technologie'
  },
  {
    Ticker: 'GERN',
    Company: 'Geron Corp'
  },
  {
    Ticker: 'GEVO',
    Company: 'Gevo Inc'
  },
  {
    Ticker: 'GFED',
    Company: 'Guaranty Fedl Bcshs'
  },
  {
    Ticker: 'GFN',
    Company: 'General Finance Corp'
  },
  {
    Ticker: 'GFNCP',
    Company: 'General Fn Cum Pfd C'
  },
  {
    Ticker: 'GFNSL',
    Company: 'Genrl Fin Sr Nt 2021'
  },
  {
    Ticker: 'GGAL',
    Company: 'Grupo Fin Galicia'
  },
  {
    Ticker: 'GH',
    Company: 'Guardant Health Inc'
  },
  {
    Ticker: 'GHSI',
    Company: 'Guardion Health Sciences Inc'
  },
  {
    Ticker: 'GIFI',
    Company: 'Gulf Island Fab'
  },
  {
    Ticker: 'GIGE',
    Company: 'Sofi Gig Economy ETF'
  },
  {
    Ticker: 'GIGM',
    Company: 'Gigamedia Ltd'
  },
  {
    Ticker: 'GIII',
    Company: 'G-III Apparel Gp'
  },
  {
    Ticker: 'GILD',
    Company: 'Gilead Sciences Inc'
  },
  {
    Ticker: 'GILT',
    Company: 'Gilat Satellite Ntwk'
  },
  {
    Ticker: 'GLAD',
    Company: 'Gladstone Cap Corp'
  },
  {
    Ticker: 'GLADD',
    Company: 'Gladstone Capital Corp 6.125% Notes Due 2'
  },
  {
    Ticker: 'GLADL',
    Company: 'Gladstone Capital Corp 5.375% Notes Due 2'
  },
  {
    Ticker: 'GLBS',
    Company: 'Globus Maritime Limi'
  },
  {
    Ticker: 'GLBZ',
    Company: 'Glen Burnie Bancorp'
  },
  {
    Ticker: 'GLDD',
    Company: 'Great Lakes Drg Dock'
  },
  {
    Ticker: 'GLDI',
    Company: 'CS X-Links Gold Share'
  },
  {
    Ticker: 'GLG',
    Company: 'China Bat Group Inc'
  },
  {
    Ticker: 'GLIBA',
    Company: 'Gci Liberty Inc Cl A'
  },
  {
    Ticker: 'GLIBP',
    Company: 'Gci Liberty Inc Ser A Pfd'
  },
  {
    Ticker: 'GLMD',
    Company: 'Galmed Pharmaceutica'
  },
  {
    Ticker: 'GLNG',
    Company: 'Golar Lng Ltd'
  },
  {
    Ticker: 'GLPG',
    Company: 'Galapagos NV'
  },
  {
    Ticker: 'GLPI',
    Company: 'Gaming & Leisure'
  },
  {
    Ticker: 'GLRE',
    Company: 'Greenlight Cap. Re.'
  },
  {
    Ticker: 'GLUU',
    Company: 'Glu Mobile Inc'
  },
  {
    Ticker: 'GLYC',
    Company: 'Glycomimetics Inc'
  },
  {
    Ticker: 'GMAB',
    Company: 'Genmab A/S'
  },
  {
    Ticker: 'GMDA',
    Company: 'Gamida Cell Ltd'
  },
  {
    Ticker: 'GMHI',
    Company: 'Gores Metropoulos Cl A'
  },
  {
    Ticker: 'GMHIU',
    Company: 'Gores Metropoulos Inc Unit'
  },
  {
    Ticker: 'GMHIW',
    Company: 'Gores Metropoulos WT'
  },
  {
    Ticker: 'GMLP',
    Company: 'Golar Lng Partners'
  },
  {
    Ticker: 'GMLPP',
    Company: 'Golar Lng Partners LP'
  },
  {
    Ticker: 'GNCA',
    Company: 'Genocea Biosciences'
  },
  {
    Ticker: 'GNFT',
    Company: 'Genfit S.A. ADR'
  },
  {
    Ticker: 'GNLN',
    Company: 'Greenlane Holdings Inc Cl A'
  },
  {
    Ticker: 'GNMA',
    Company: 'GNMA Bond Ishares ETF'
  },
  {
    Ticker: 'GNMK',
    Company: 'Genmark Diagnostics'
  },
  {
    Ticker: 'GNMX',
    Company: 'Aevi Genomic Medicine'
  },
  {
    Ticker: 'GNOM',
    Company: 'Global X Genomics & Biotechnology ETF'
  },
  {
    Ticker: 'GNPX',
    Company: 'Genprex Inc'
  },
  {
    Ticker: 'GNSS',
    Company: 'Lrad Corp'
  },
  {
    Ticker: 'GNTX',
    Company: 'Gentex Corp'
  },
  {
    Ticker: 'GNTY',
    Company: 'Guaranty Bancshares Inc'
  },
  {
    Ticker: 'GNUS',
    Company: 'Genius Brands International Inc'
  },
  {
    Ticker: 'GO',
    Company: 'Grocery Outlet Holding Corp'
  },
  {
    Ticker: 'GOGL',
    Company: 'Golden Ocean Gp'
  },
  {
    Ticker: 'GOGO',
    Company: 'Gogo Inc'
  },
  {
    Ticker: 'GOOD',
    Company: 'Gladstone Comml'
  },
  {
    Ticker: 'GOODM',
    Company: 'Gladstone Commercial Corp'
  },
  {
    Ticker: 'GOODN',
    Company: 'Gladstone Commercial Corp Prf Perpetual USD 25 S'
  },
  {
    Ticker: 'GOOG',
    Company: 'Alphabet Cl C'
  },
  {
    Ticker: 'GOOGL',
    Company: 'Alphabet Cl A'
  },
  {
    Ticker: 'GOSS',
    Company: 'Gossamer Bio Inc'
  },
  {
    Ticker: 'GPAQ',
    Company: 'Gordon Pointe Acquisition Corp Cl A'
  },
  {
    Ticker: 'GPAQU',
    Company: 'Gordon Pointe Acquisition Corp Unit'
  },
  {
    Ticker: 'GPAQW',
    Company: 'Gordon Pointe Acquisition Corp WT'
  },
  {
    Ticker: 'GPOR',
    Company: 'Gulfport Energy Corp'
  },
  {
    Ticker: 'GPP',
    Company: 'Green Plains Partners LP'
  },
  {
    Ticker: 'GPRE',
    Company: 'Green Plains Inc'
  },
  {
    Ticker: 'GPRO',
    Company: 'Gopro Inc Cl A'
  },
  {
    Ticker: 'GRBK',
    Company: 'Green Brick Partners'
  },
  {
    Ticker: 'GRFS',
    Company: 'Grifols S.A.'
  },
  {
    Ticker: 'GRID',
    Company: 'Nasdaq Clean Edge Smart Grid Infr Index Fund FT'
  },
  {
    Ticker: 'GRIF',
    Company: 'Griffin Industrial'
  },
  {
    Ticker: 'GRIN',
    Company: 'Grindrod Shipping Holdings Ltd'
  },
  {
    Ticker: 'GRMN',
    Company: 'Garmin Ltd'
  },
  {
    Ticker: 'GRNQ',
    Company: 'Greenpro Capital Corp'
  },
  {
    Ticker: 'GRNV',
    Company: 'Greenvision Acquisition Corp'
  },
  {
    Ticker: 'GRNVR',
    Company: 'Greenvision Acquisition Corp Rights'
  },
  {
    Ticker: 'GRNVU',
    Company: 'Greenvision Acquisition Corp'
  },
  {
    Ticker: 'GRNVW',
    Company: 'Greenvision Acquisition Corp WT'
  },
  {
    Ticker: 'GROW',
    Company: 'U S Global Inv Inc'
  },
  {
    Ticker: 'GRPN',
    Company: 'Groupon Cl A'
  },
  {
    Ticker: 'GRSH',
    Company: 'Gores Holdings III Inc Cl A'
  },
  {
    Ticker: 'GRSHU',
    Company: 'Gores Holdings III Inc'
  },
  {
    Ticker: 'GRSHW',
    Company: 'Gores Holdings III Inc WT'
  },
  {
    Ticker: 'GRTS',
    Company: 'Gritstone Oncology Inc'
  },
  {
    Ticker: 'GRTX',
    Company: 'Galera Therapeutics Inc'
  },
  {
    Ticker: 'GRVY',
    Company: 'Gravity Co. Ltd'
  },
  {
    Ticker: 'GRWG',
    Company: 'Growgeneration Corp'
  },
  {
    Ticker: 'GSBC',
    Company: 'Great Southern Bncp'
  },
  {
    Ticker: 'GSHD',
    Company: 'Goosehead Insurance Inc Cl A'
  },
  {
    Ticker: 'GSIT',
    Company: 'Gsi Technology Inc'
  },
  {
    Ticker: 'GSKY',
    Company: 'Greensky Inc Cl A'
  },
  {
    Ticker: 'GSM',
    Company: 'Ferroglobe Plc Os'
  },
  {
    Ticker: 'GSUM',
    Company: 'Gridsum Holding Inc'
  },
  {
    Ticker: 'GT',
    Company: 'Goodyear Tire Rubber'
  },
  {
    Ticker: 'GTEC',
    Company: 'Greenland Technologies Holding Corp.'
  },
  {
    Ticker: 'GTHX',
    Company: 'G1 Therapeutics Inc'
  },
  {
    Ticker: 'GTIM',
    Company: 'Good Times Rest'
  },
  {
    Ticker: 'GTLS',
    Company: 'Chart Industries IN'
  },
  {
    Ticker: 'GTYH',
    Company: 'Gty Technology Holdings Inc'
  },
  {
    Ticker: 'GULF',
    Company: 'Wisdomtree Middle East Dividend Fund'
  },
  {
    Ticker: 'GURE',
    Company: 'Gulf Resources Inc'
  },
  {
    Ticker: 'GVP',
    Company: 'GSE Systems'
  },
  {
    Ticker: 'GWGH',
    Company: 'Gwg Holdings'
  },
  {
    Ticker: 'GWPH',
    Company: 'Gw Pharma ADR'
  },
  {
    Ticker: 'GWRS',
    Company: 'Global Water Reso'
  },
  {
    Ticker: 'GXGX',
    Company: 'Gx Acquisiton Corp Cl A'
  },
  {
    Ticker: 'GXGXU',
    Company: 'Gx Acquisiton Corp Unit'
  },
  {
    Ticker: 'GXGXW',
    Company: 'Gx Acquisiton Corp WT'
  },
  {
    Ticker: 'GXTG',
    Company: 'Global X Thematic Growth ETF'
  },
  {
    Ticker: 'GYRO',
    Company: 'Gyrodyne Llc'
  },
  {
    Ticker: 'HA',
    Company: 'Hawaiian Hlds Inc'
  },
  {
    Ticker: 'HABT',
    Company: 'Habit Rest'
  },
  {
    Ticker: 'HAFC',
    Company: 'Hanmi Financial Cp'
  },
  {
    Ticker: 'HAIN',
    Company: 'Hain Celestial Group'
  },
  {
    Ticker: 'HALL',
    Company: 'Hallmark Fin Svc'
  },
  {
    Ticker: 'HALO',
    Company: 'Halozyme Therapeutic'
  },
  {
    Ticker: 'HAPP',
    Company: 'Happiness Biotech Group Limited'
  },
  {
    Ticker: 'HARP',
    Company: 'Harpoon Therapeutics Inc'
  },
  {
    Ticker: 'HAS',
    Company: 'Hasbro Inc'
  },
  {
    Ticker: 'HAYN',
    Company: 'Haynes Intl Inc'
  },
  {
    Ticker: 'HBAN',
    Company: 'Huntington Bcshs'
  },
  {
    Ticker: 'HBANN',
    Company: 'Huntington Bancshares Incorporated ADR'
  },
  {
    Ticker: 'HBANO',
    Company: 'Huntington Banc. Dep'
  },
  {
    Ticker: 'HBCP',
    Company: 'Home Bancorp Inc'
  },
  {
    Ticker: 'HBIO',
    Company: 'Harvard Bioscience'
  },
  {
    Ticker: 'HBMD',
    Company: 'Howard Bancorp MD'
  },
  {
    Ticker: 'HBNC',
    Company: 'Horizon Bancorp'
  },
  {
    Ticker: 'HBP',
    Company: 'Huttig Bldg Products'
  },
  {
    Ticker: 'HBT',
    Company: 'Hbt Financial Inc'
  },
  {
    Ticker: 'HCAC',
    Company: 'Hennessy Capital Acquisition Corp IV Cl A'
  },
  {
    Ticker: 'HCACU',
    Company: 'Hennessy Capital Acquisition Corp IV Unit'
  },
  {
    Ticker: 'HCACW',
    Company: 'Hennessy Capital Acquisition Corp IV WT'
  },
  {
    Ticker: 'HCAP',
    Company: 'Harvest Capital Cred'
  },
  {
    Ticker: 'HCAPZ',
    Company: 'Harvest Capital Credit Corp 6.125% Notes'
  },
  {
    Ticker: 'HCAT',
    Company: 'Health Catalyst Inc'
  },
  {
    Ticker: 'HCCH',
    Company: 'Hl Acquisitions Corp'
  },
  {
    Ticker: 'HCCHR',
    Company: 'Hl Acquisitions Corp Right'
  },
  {
    Ticker: 'HCCHU',
    Company: 'Hl Acquisitions Corp Unit'
  },
  {
    Ticker: 'HCCHW',
    Company: 'Hl Acquisitions Corp WT'
  },
  {
    Ticker: 'HCCI',
    Company: 'Heritage-Crystal'
  },
  {
    Ticker: 'HCKT',
    Company: 'Hackett Grp Inc'
  },
  {
    Ticker: 'HCM',
    Company: 'Hutchison China Ads'
  },
  {
    Ticker: 'HCSG',
    Company: 'Healthcare Svcs Gp'
  },
  {
    Ticker: 'HDS',
    Company: 'HD Supply Hlds Cmm'
  },
  {
    Ticker: 'HDSN',
    Company: 'Hudson Tech Inc'
  },
  {
    Ticker: 'HEAR',
    Company: 'Turtle Beach Corp'
  },
  {
    Ticker: 'HEBT',
    Company: 'Hebron Technology Co. Ltd'
  },
  {
    Ticker: 'HEES',
    Company: 'H&E Equip Services'
  },
  {
    Ticker: 'HELE',
    Company: 'Helen of Troy Ltd'
  },
  {
    Ticker: 'HEPA',
    Company: 'Hepion Pharmaceuticals Inc'
  },
  {
    Ticker: 'HERD',
    Company: 'Pacer Cash Cows Fund of Funds ETF'
  },
  {
    Ticker: 'HERO',
    Company: 'Global X Video Games & Esports ETF'
  },
  {
    Ticker: 'HEWG',
    Company: 'Germany Curr Hedged Ishares MSCI ETF'
  },
  {
    Ticker: 'HFBL',
    Company: 'Home Federal'
  },
  {
    Ticker: 'HFFG',
    Company: 'HF Foods Group Inc'
  },
  {
    Ticker: 'HFWA',
    Company: 'Heritage Financial'
  },
  {
    Ticker: 'HGSH',
    Company: 'China Hgs Real Est'
  },
  {
    Ticker: 'HHHH',
    Company: 'Wealthbridge Acquisition Ltd'
  },
  {
    Ticker: 'HHHHR',
    Company: 'Wealthbridge Acquisition Ltd Rights'
  },
  {
    Ticker: 'HHHHU',
    Company: 'Wealthbridge Acquisition Ltd Units'
  },
  {
    Ticker: 'HHHHW',
    Company: 'Wealthbridge Acquisition Ltd WT'
  },
  {
    Ticker: 'HHR',
    Company: 'Headhunter Group Plc ADR'
  },
  {
    Ticker: 'HHT',
    Company: 'Huitao Technology Co. Ltd'
  },
  {
    Ticker: 'HIBB',
    Company: 'Hibbett Sports Inc'
  },
  {
    Ticker: 'HIFS',
    Company: 'Hingham Inst For Svg'
  },
  {
    Ticker: 'HIHO',
    Company: 'Highway Hlds Ltd'
  },
  {
    Ticker: 'HIIQ',
    Company: 'Health Insurance Inn'
  },
  {
    Ticker: 'HIMX',
    Company: 'Himax Technologies'
  },
  {
    Ticker: 'HJLI',
    Company: 'Hancock Jaffe Laboratories Inc'
  },
  {
    Ticker: 'HJLIW',
    Company: 'Hancock Jaffe Laboratories Inc WT'
  },
  {
    Ticker: 'HLAL',
    Company: 'Wahed FTSE USA Shariah ETF'
  },
  {
    Ticker: 'HLG',
    Company: 'Hailiang Edu Grp Ads'
  },
  {
    Ticker: 'HLIO',
    Company: 'Helios Technologies Inc'
  },
  {
    Ticker: 'HLIT',
    Company: 'Harmonic Inc'
  },
  {
    Ticker: 'HLNE',
    Company: 'Hamilton Lane Incorporated Cl A'
  },
  {
    Ticker: 'HMHC',
    Company: 'Houghton Mifflin Har'
  },
  {
    Ticker: 'HMNF',
    Company: 'Hmn Financial Inc'
  },
  {
    Ticker: 'HMST',
    Company: 'Homestreet Inc'
  },
  {
    Ticker: 'HMSY',
    Company: 'Hms Hlds Cp'
  },
  {
    Ticker: 'HMTV',
    Company: 'Hemisphere Media A'
  },
  {
    Ticker: 'HNDL',
    Company: 'Strategy Shares Nasdaq 7Handl Index ETF'
  },
  {
    Ticker: 'HNNA',
    Company: 'Hennessy Advisors'
  },
  {
    Ticker: 'HNRG',
    Company: 'Hallador Energy Company'
  },
  {
    Ticker: 'HOFT',
    Company: 'Hooker Furniture'
  },
  {
    Ticker: 'HOLI',
    Company: 'Hollysys Automation'
  },
  {
    Ticker: 'HOLX',
    Company: 'Hologic Inc'
  },
  {
    Ticker: 'HOMB',
    Company: 'Home Bancshares Inc'
  },
  {
    Ticker: 'HONE',
    Company: 'Harborone Bancorp Inc'
  },
  {
    Ticker: 'HOOK',
    Company: 'Hookipa Pharma Inc'
  },
  {
    Ticker: 'HOPE',
    Company: 'Hope Bancorp Inc'
  },
  {
    Ticker: 'HOTH',
    Company: 'Hoth Therapeutics Inc'
  },
  {
    Ticker: 'HOVNP',
    Company: 'Hovnanian Entr Dep A'
  },
  {
    Ticker: 'HQI',
    Company: 'Hirequest Inc'
  },
  {
    Ticker: 'HQY',
    Company: 'Healthequity'
  },
  {
    Ticker: 'HROW',
    Company: 'Harrow Health Inc'
  },
  {
    Ticker: 'HRTX',
    Company: 'Heron Therapeutics'
  },
  {
    Ticker: 'HRZN',
    Company: 'Horizon Technology F'
  },
  {
    Ticker: 'HSAC',
    Company: 'Health Sciences Acquisitions Corp'
  },
  {
    Ticker: 'HSACU',
    Company: 'Health Sciences Acquisitions Corp Units'
  },
  {
    Ticker: 'HSACW',
    Company: 'Health Sciences Acquisitions Corp WT'
  },
  {
    Ticker: 'HSDT',
    Company: 'Helius Medical Technologies Inc'
  },
  {
    Ticker: 'HSIC',
    Company: 'Henry Schein Inc'
  },
  {
    Ticker: 'HSII',
    Company: 'Heidrick & Struggl'
  },
  {
    Ticker: 'HSKA',
    Company: 'Heska Corp'
  },
  {
    Ticker: 'HSON',
    Company: 'Hudson Global'
  },
  {
    Ticker: 'HSTM',
    Company: 'Healthstream Inc'
  },
  {
    Ticker: 'HTBI',
    Company: 'Hometrust Bancshares'
  },
  {
    Ticker: 'HTBK',
    Company: 'Heritage Commerce'
  },
  {
    Ticker: 'HTBX',
    Company: 'Heat Biologics Inc'
  },
  {
    Ticker: 'HTGM',
    Company: 'Htg Molecular Dia'
  },
  {
    Ticker: 'HTHT',
    Company: 'Huazhu Group Ltd'
  },
  {
    Ticker: 'HTIA',
    Company: 'Healthcare TR Inc'
  },
  {
    Ticker: 'HTLD',
    Company: 'Heartland Express'
  },
  {
    Ticker: 'HTLF',
    Company: 'Heartland Finl USA'
  },
  {
    Ticker: 'HUBG',
    Company: 'Hub Group Inc A'
  },
  {
    Ticker: 'HURC',
    Company: 'Hurco Cos Inc'
  },
  {
    Ticker: 'HURN',
    Company: 'Huron Consulting'
  },
  {
    Ticker: 'HVBC',
    Company: 'Hv Bancorp Inc'
  },
  {
    Ticker: 'HWBK',
    Company: 'Hawthorn Bancshares'
  },
  {
    Ticker: 'HWC',
    Company: 'Hancock Whitney Corp'
  },
  {
    Ticker: 'HWCC',
    Company: 'Houston Wire Cable'
  },
  {
    Ticker: 'HWCPL',
    Company: 'Hancock Whitney Corp 5.95 Nt'
  },
  {
    Ticker: 'HWKN',
    Company: 'Hawkins Inc'
  },
  {
    Ticker: 'HX',
    Company: 'Hexindai Inc'
  },
  {
    Ticker: 'HYAC',
    Company: 'Haymaker Acquisition Corp II Cl A'
  },
  {
    Ticker: 'HYACU',
    Company: 'Haymaker Acquisition Corp Unit'
  },
  {
    Ticker: 'HYACW',
    Company: 'Haymaker Acquisition Corp II WT'
  },
  {
    Ticker: 'HYLS',
    Company: 'Tactical High Yield ETF FT'
  },
  {
    Ticker: 'HYND',
    Company: 'Wisdomtree Neg Duration HI Yld Bond Fund'
  },
  {
    Ticker: 'HYRE',
    Company: 'Hyrecar Inc'
  },
  {
    Ticker: 'HYXE',
    Company: 'High Yield Ex Oil and Gas Corp Bond Ishares Ibox'
  },
  {
    Ticker: 'HYZD',
    Company: 'Wisdomtree Int Rate HI Yld Bond Hedged Fund'
  },
  {
    Ticker: 'HZNP',
    Company: 'Horizon Therapeutics'
  },
  {
    Ticker: 'IAC',
    Company: 'Iac Interactive'
  },
  {
    Ticker: 'IART',
    Company: 'Integra Lifesciences'
  },
  {
    Ticker: 'IBB',
    Company: 'Nasdaq Biotechnology Ishares ETF'
  },
  {
    Ticker: 'IBCP',
    Company: 'Independent Bk Cp'
  },
  {
    Ticker: 'IBKC',
    Company: 'Iberiabank Corp'
  },
  {
    Ticker: 'IBKCN',
    Company: 'Iberiabank Corp Dep Shs Repstg'
  },
  {
    Ticker: 'IBKCO',
    Company: 'Iberiabank Ds Rep C'
  },
  {
    Ticker: 'IBKCP',
    Company: 'Iberiabank Dp Sh B'
  },
  {
    Ticker: 'IBKR',
    Company: 'Interactive Brokers'
  },
  {
    Ticker: 'IBOC',
    Company: 'Intl Bancshares'
  },
  {
    Ticker: 'IBTX',
    Company: 'Independent Bank Group'
  },
  {
    Ticker: 'IBUY',
    Company: 'Online Retail Amplify ETF'
  },
  {
    Ticker: 'ICAD',
    Company: 'Icad Inc'
  },
  {
    Ticker: 'ICBK',
    Company: 'County Bancorp'
  },
  {
    Ticker: 'ICCC',
    Company: 'Immucell Cp'
  },
  {
    Ticker: 'ICCH',
    Company: 'Icc Holdings Inc'
  },
  {
    Ticker: 'ICFI',
    Company: 'Icf International'
  },
  {
    Ticker: 'ICHR',
    Company: 'Ichor Holdings Ltd'
  },
  {
    Ticker: 'ICLK',
    Company: 'Iclick Interactive Asia Group Ltd ADR'
  },
  {
    Ticker: 'ICLN',
    Company: 'Global Clean Energy Ishares ETF'
  },
  {
    Ticker: 'ICLR',
    Company: 'Icon Plc'
  },
  {
    Ticker: 'ICMB',
    Company: 'Investcorp Credit Management Bdc Inc'
  },
  {
    Ticker: 'ICON',
    Company: 'Iconix Brand Group'
  },
  {
    Ticker: 'ICPT',
    Company: 'Intercept Pharmaceuticals'
  },
  {
    Ticker: 'ICUI',
    Company: 'I C U Medical Inc'
  },
  {
    Ticker: 'IDCC',
    Company: 'Interdigital Inc'
  },
  {
    Ticker: 'IDEX',
    Company: 'Ideanomics Inc'
  },
  {
    Ticker: 'IDLB',
    Company: 'FTSE Intl Low Beta EW Invesco ETF'
  },
  {
    Ticker: 'IDRA',
    Company: 'Idera Pharmaceuticals'
  },
  {
    Ticker: 'IDSA',
    Company: 'Industrial Svcs Am'
  },
  {
    Ticker: 'IDXG',
    Company: 'Interpace Diag Group'
  },
  {
    Ticker: 'IDXX',
    Company: 'Idexx Laboratories'
  },
  {
    Ticker: 'IDYA',
    Company: 'Ideaya Biosciences Inc'
  },
  {
    Ticker: 'IEA',
    Company: 'Infrastructure and Energy Alternatives Inc'
  },
  {
    Ticker: 'IEAWW',
    Company: 'Infrastructure and Energy Alternatives Inc WT'
  },
  {
    Ticker: 'IEF',
    Company: '7-10 Year Treas Bond Ishares ETF'
  },
  {
    Ticker: 'IEI',
    Company: '3-7 Year Treas Bond Ishares ETF'
  },
  {
    Ticker: 'IEP',
    Company: 'Icahn Enterprises'
  },
  {
    Ticker: 'IESC',
    Company: 'Ies Holdings'
  },
  {
    Ticker: 'IEUS',
    Company: 'Europe Small-Cap Ishares MSCI ETF'
  },
  {
    Ticker: 'IFEU',
    Company: 'Europe Developed Real Estate Ishares ETF'
  },
  {
    Ticker: 'IFGL',
    Company: 'Intl Developed Real Estate Ishares ETF'
  },
  {
    Ticker: 'IFMK',
    Company: 'Ifresh Inc'
  },
  {
    Ticker: 'IFRX',
    Company: 'Fireman B.V.'
  },
  {
    Ticker: 'IFV',
    Company: 'Dorsey Wright Intl Focus 5 ETF FT'
  },
  {
    Ticker: 'IGF',
    Company: 'Global Infrastructure Ishares ETF'
  },
  {
    Ticker: 'IGIB',
    Company: 'Intermediate-Term Corp Bond Ishares ETF'
  },
  {
    Ticker: 'IGLD',
    Company: 'Internet Gold-Golden'
  },
  {
    Ticker: 'IGMS',
    Company: 'Igm Biosciences Inc'
  },
  {
    Ticker: 'IGOV',
    Company: 'Intl Treasury Bond Ishares ETF'
  },
  {
    Ticker: 'IGSB',
    Company: 'Short-Term Corp Bond Ishares ETF'
  },
  {
    Ticker: 'IHRT',
    Company: 'Iheartmedia Inc'
  },
  {
    Ticker: 'III',
    Company: 'Information Svcs Group'
  },
  {
    Ticker: 'IIIN',
    Company: 'Insteel Industries'
  },
  {
    Ticker: 'IIIV',
    Company: 'I3 Verticals Inc Cl A'
  },
  {
    Ticker: 'IIN',
    Company: 'Intricon Corp'
  },
  {
    Ticker: 'IIVI',
    Company: 'I I V I Inc'
  },
  {
    Ticker: 'IJT',
    Company: 'S&P Small-Cap 600 Growth Ishares ETF'
  },
  {
    Ticker: 'IKNX',
    Company: 'Ikonics Corp'
  },
  {
    Ticker: 'ILMN',
    Company: 'Illumina Inc'
  },
  {
    Ticker: 'ILPT',
    Company: 'Industrial Logistics Properties Trust'
  },
  {
    Ticker: 'IMAC',
    Company: 'Imac Holdings Inc'
  },
  {
    Ticker: 'IMACW',
    Company: 'Imac Holdings Inc WT'
  },
  {
    Ticker: 'IMBI',
    Company: 'Imedia Brands Inc'
  },
  {
    Ticker: 'IMGN',
    Company: 'Immunogen Inc'
  },
  {
    Ticker: 'IMKTA',
    Company: 'Ingles Markets Inc'
  },
  {
    Ticker: 'IMMP',
    Company: 'Immutep Ltd ADR'
  },
  {
    Ticker: 'IMMR',
    Company: 'Immersion Corp'
  },
  {
    Ticker: 'IMMU',
    Company: 'Immunomedics Inc'
  },
  {
    Ticker: 'IMOS',
    Company: 'Chipmos Tech'
  },
  {
    Ticker: 'IMRN',
    Company: 'Immuron Ltd'
  },
  {
    Ticker: 'IMRNW',
    Company: 'Immuron Ltd WT'
  },
  {
    Ticker: 'IMTE',
    Company: 'Integrated Media Technology'
  },
  {
    Ticker: 'IMUX',
    Company: 'Immunic Inc'
  },
  {
    Ticker: 'IMV',
    Company: 'Imv Inc'
  },
  {
    Ticker: 'IMXI',
    Company: 'International Money Express Inc'
  },
  {
    Ticker: 'INAP',
    Company: 'Internap Corp'
  },
  {
    Ticker: 'INBK',
    Company: 'First Internet Bcp'
  },
  {
    Ticker: 'INBKL',
    Company: 'First Internet Bancorp'
  },
  {
    Ticker: 'INBKZ',
    Company: 'First Internet Bancorp 6.0% Fixed-To-Floating Ra'
  },
  {
    Ticker: 'INCY',
    Company: 'Incyte Corp'
  },
  {
    Ticker: 'INDB',
    Company: 'Independent Bk Corp'
  },
  {
    Ticker: 'INDY',
    Company: 'India 50 Ishares ETF'
  },
  {
    Ticker: 'INFI',
    Company: 'Infinity Pharmaceuti'
  },
  {
    Ticker: 'INFN',
    Company: 'Infinera Corp'
  },
  {
    Ticker: 'INFR',
    Company: 'Legg Global Infrastructure ETF'
  },
  {
    Ticker: 'INGN',
    Company: 'Inogen Inc'
  },
  {
    Ticker: 'INMB',
    Company: 'Inmune Bio Inc'
  },
  {
    Ticker: 'INMD',
    Company: 'Inmode Ltd'
  },
  {
    Ticker: 'INNT',
    Company: 'Innovate Biopharmaceuticals Inc'
  },
  {
    Ticker: 'INO',
    Company: 'Inovio Pharma'
  },
  {
    Ticker: 'INOD',
    Company: 'Innodata Inc'
  },
  {
    Ticker: 'INOV',
    Company: 'Inovalon Hlds Cm A'
  },
  {
    Ticker: 'INPX',
    Company: 'Inpixon'
  },
  {
    Ticker: 'INSE',
    Company: 'Inspired Entertainment Inc'
  },
  {
    Ticker: 'INSG',
    Company: 'Inseego Corp'
  },
  {
    Ticker: 'INSM',
    Company: 'Insmed Inc'
  },
  {
    Ticker: 'INSU',
    Company: 'Insurance Acquisition Corp Cl A'
  },
  {
    Ticker: 'INSUU',
    Company: 'Insurance Acquisition Corp Unit'
  },
  {
    Ticker: 'INSUW',
    Company: 'Insurance Acquisition Corp WT'
  },
  {
    Ticker: 'INTC',
    Company: 'Intel Corp'
  },
  {
    Ticker: 'INTG',
    Company: 'Intergroup Cp The'
  },
  {
    Ticker: 'INTL',
    Company: 'Intl Fcstone Inc'
  },
  {
    Ticker: 'INTU',
    Company: 'Intuit Inc'
  },
  {
    Ticker: 'INVA',
    Company: 'Innoviva Inc'
  },
  {
    Ticker: 'INVE',
    Company: 'Identiv Inc'
  },
  {
    Ticker: 'INWK',
    Company: 'Innerworkings Inc'
  },
  {
    Ticker: 'IONS',
    Company: 'Ionis Pharmaceuticals'
  },
  {
    Ticker: 'IOSP',
    Company: 'Innospec Inc'
  },
  {
    Ticker: 'IOTS',
    Company: 'Adesto Tech'
  },
  {
    Ticker: 'IOVA',
    Company: 'Iovance Biotherapeutics Inc'
  },
  {
    Ticker: 'IPAR',
    Company: 'Inter Parfums Inc'
  },
  {
    Ticker: 'IPDN',
    Company: 'Professional Diversi'
  },
  {
    Ticker: 'IPGP',
    Company: 'Ipg Photonics Corp'
  },
  {
    Ticker: 'IPHA',
    Company: 'Innate Pharma S.A. Ads'
  },
  {
    Ticker: 'IPHS',
    Company: 'Innophos Hlds Inc'
  },
  {
    Ticker: 'IPKW',
    Company: 'Intl Buyback Achievers Invesco ETF'
  },
  {
    Ticker: 'IPLDP',
    Company: 'Interstate Power & Light Company Perp Prd Se'
  },
  {
    Ticker: 'IPWR',
    Company: 'Ideal Power Inc'
  },
  {
    Ticker: 'IQ',
    Company: 'Iqiyi Inc ADR'
  },
  {
    Ticker: 'IRBT',
    Company: 'Irobot Corp'
  },
  {
    Ticker: 'IRCP',
    Company: 'Irsa Propiedades Ads'
  },
  {
    Ticker: 'IRDM',
    Company: 'Iridium Comm Inc'
  },
  {
    Ticker: 'IRIX',
    Company: 'Iridex Cp'
  },
  {
    Ticker: 'IRMD',
    Company: 'Iradimed Cp'
  },
  {
    Ticker: 'IROQ',
    Company: 'If Bancorp Inc'
  },
  {
    Ticker: 'IRTC',
    Company: 'Irhythm Technologies Inc'
  },
  {
    Ticker: 'IRWD',
    Company: 'Ironwood Pharmaceuti'
  },
  {
    Ticker: 'ISBC',
    Company: 'Investors Bancorp'
  },
  {
    Ticker: 'ISDS',
    Company: 'Invesco Developed Ex-US Small Company ETF'
  },
  {
    Ticker: 'ISDX',
    Company: 'Invesco Developed Ex-US ETF'
  },
  {
    Ticker: 'ISEE',
    Company: 'Iveric Bio Inc'
  },
  {
    Ticker: 'ISEM',
    Company: 'Invesco Emerging Markets ETF'
  },
  {
    Ticker: 'ISHG',
    Company: '1-3 Year Intl Treas Bond Ishares ETF'
  },
  {
    Ticker: 'ISIG',
    Company: 'Insignia Systems'
  },
  {
    Ticker: 'ISNS',
    Company: 'Image Sensing Syst'
  },
  {
    Ticker: 'ISRG',
    Company: 'Intuitive Surg Inc'
  },
  {
    Ticker: 'ISSC',
    Company: 'Innovative Sol&Sup'
  },
  {
    Ticker: 'ISTB',
    Company: '1-5 Year USD Bond Ishares Core ETF'
  },
  {
    Ticker: 'ISTR',
    Company: 'Investar Holding'
  },
  {
    Ticker: 'ITCI',
    Company: 'Intra-Cellular Ther'
  },
  {
    Ticker: 'ITI',
    Company: 'Iteris Inc'
  },
  {
    Ticker: 'ITIC',
    Company: 'Investors Title Company'
  },
  {
    Ticker: 'ITMR',
    Company: 'Itamar Medical Ltd ADR'
  },
  {
    Ticker: 'ITRI',
    Company: 'Itron Inc'
  },
  {
    Ticker: 'ITRM',
    Company: 'Iterum Therapeutics Plc'
  },
  {
    Ticker: 'ITRN',
    Company: 'Ituran Location'
  },
  {
    Ticker: 'IUS',
    Company: 'Invesco Strategic US ETF'
  },
  {
    Ticker: 'IUSB',
    Company: 'USD Bond Market Total Ishares Core ETF'
  },
  {
    Ticker: 'IUSG',
    Company: 'S&P US Growth Ishares Core ETF'
  },
  {
    Ticker: 'IUSS',
    Company: 'Invesco US Small Company ETF'
  },
  {
    Ticker: 'IUSV',
    Company: 'S&P US Value Ishares Core ETF'
  },
  {
    Ticker: 'IVAC',
    Company: 'Intevac Inc'
  },
  {
    Ticker: 'IXUS',
    Company: 'Total Intl Stock Ishares Core MSCI ETF'
  },
  {
    Ticker: 'IZEA',
    Company: 'Izea Inc'
  },
  {
    Ticker: 'JACK',
    Company: 'Jack IN The Box Inc'
  },
  {
    Ticker: 'JAGX',
    Company: 'Jaguar Health Inc'
  },
  {
    Ticker: 'JAKK',
    Company: 'Jakks Pacific Inc'
  },
  {
    Ticker: 'JAN',
    Company: 'Janone Inc'
  },
  {
    Ticker: 'JASN',
    Company: 'Jason Indst'
  },
  {
    Ticker: 'JAZZ',
    Company: 'Jazz Pharma Plc'
  },
  {
    Ticker: 'JBHT',
    Company: 'J B Hunt Transport'
  },
  {
    Ticker: 'JBLU',
    Company: 'Jetblue Airways Cp'
  },
  {
    Ticker: 'JBSS',
    Company: 'John B Sanfilippo'
  },
  {
    Ticker: 'JCOM',
    Company: 'J2 Global Inc'
  },
  {
    Ticker: 'JCS',
    Company: 'Commun Systems Inc'
  },
  {
    Ticker: 'JCTCF',
    Company: 'Jewett-Cameron Trd'
  },
  {
    Ticker: 'JD',
    Company: 'Jd.com Inc Ads'
  },
  {
    Ticker: 'JFIN',
    Company: 'Jiayin Group Inc ADR'
  },
  {
    Ticker: 'JFK',
    Company: '8I Enterprises Acquisition Corp'
  },
  {
    Ticker: 'JFKKR',
    Company: '8I Enterprises Acquisition Corp Right'
  },
  {
    Ticker: 'JFKKU',
    Company: '8I Enterprises Acquisition Corp Unit'
  },
  {
    Ticker: 'JFKKW',
    Company: '8I Enterprises Acquisition Corp WT'
  },
  {
    Ticker: 'JFU',
    Company: '9F Inc ADR'
  },
  {
    Ticker: 'JG',
    Company: 'Aurora Mobile Ltd ADR'
  },
  {
    Ticker: 'JJSF',
    Company: 'J J Snack Foods'
  },
  {
    Ticker: 'JKHY',
    Company: 'Jack Henry & Assoc'
  },
  {
    Ticker: 'JKI',
    Company: 'Mstar Mid-Cap Value Ishares ETF'
  },
  {
    Ticker: 'JMU',
    Company: 'Jmu Ltd'
  },
  {
    Ticker: 'JNCE',
    Company: 'Jounce Therapeutics Inc'
  },
  {
    Ticker: 'JOBS',
    Company: '51Job Inc'
  },
  {
    Ticker: 'JOUT',
    Company: 'Johnson Outdoors'
  },
  {
    Ticker: 'JRJC',
    Company: 'China Finance Online'
  },
  {
    Ticker: 'JRSH',
    Company: 'Jerash Holdings Inc'
  },
  {
    Ticker: 'JRVR',
    Company: 'James River Gp HD'
  },
  {
    Ticker: 'JSM',
    Company: 'SLM Cp 6% Sr Nt'
  },
  {
    Ticker: 'JSMD',
    Company: 'Janus Small/Midcap Growth ETF'
  },
  {
    Ticker: 'JSML',
    Company: 'Janus Smallcap Growth ETF'
  },
  {
    Ticker: 'JVA',
    Company: 'Coffee Holding Company'
  },
  {
    Ticker: 'JYNT',
    Company: 'The Joint Corp'
  },
  {
    Ticker: 'KALA',
    Company: 'Kala Pharmaceuticals Inc'
  },
  {
    Ticker: 'KALU',
    Company: 'Kaiser Aluminum'
  },
  {
    Ticker: 'KALV',
    Company: 'Kalvista Pharmaceuticals Inc'
  },
  {
    Ticker: 'KBAL',
    Company: 'Kimball Intl Cl B'
  },
  {
    Ticker: 'KBLM',
    Company: 'Kbl Merger Corp IV'
  },
  {
    Ticker: 'KBLMR',
    Company: 'Kbl Merger Corp IV Right'
  },
  {
    Ticker: 'KBLMU',
    Company: 'Kbl Merger Corp. IV'
  },
  {
    Ticker: 'KBLMW',
    Company: 'Kbl Merger Corp IV WT'
  },
  {
    Ticker: 'KBSF',
    Company: 'Kbs Fashion Gp Ltd'
  },
  {
    Ticker: 'KBWB',
    Company: 'KBW Bank Invesco ETF'
  },
  {
    Ticker: 'KBWD',
    Company: 'KBW High Dividend Yield Financial Invesco ETF'
  },
  {
    Ticker: 'KBWP',
    Company: 'KBW Property & Casualty Insurance Invesco ETF'
  },
  {
    Ticker: 'KBWR',
    Company: 'KBW Regional Banking Invesco ETF'
  },
  {
    Ticker: 'KBWY',
    Company: 'KBW Premium Yield Equity REIT Invesco ETF'
  },
  {
    Ticker: 'KCAPL',
    Company: 'Kcap Financial Inc 6.125% Notes Due 2022'
  },
  {
    Ticker: 'KE',
    Company: 'Kimball Electrnc'
  },
  {
    Ticker: 'KELYA',
    Company: 'Kelly Svcs Cl A'
  },
  {
    Ticker: 'KELYB',
    Company: 'Kelly Services Inc Cl B'
  },
  {
    Ticker: 'KEQU',
    Company: 'Kewaunee Scientifi'
  },
  {
    Ticker: 'KERN',
    Company: 'Akerna Corp Cl A'
  },
  {
    Ticker: 'KERNW',
    Company: 'Akerna Corp WT'
  },
  {
    Ticker: 'KFFB',
    Company: 'Kentucky First Fed'
  },
  {
    Ticker: 'KFRC',
    Company: 'Kforce Inc'
  },
  {
    Ticker: 'KGJI',
    Company: 'Kingold Jewelry Inc'
  },
  {
    Ticker: 'KHC',
    Company: 'Kraft Heinz Company'
  },
  {
    Ticker: 'KIDS',
    Company: 'Orthopediatrics Corp'
  },
  {
    Ticker: 'KIN',
    Company: 'Kindred Biosciences'
  },
  {
    Ticker: 'KINS',
    Company: 'Kingstone Cos Inc'
  },
  {
    Ticker: 'KIRK',
    Company: "Kirkland's Inc"
  },
  {
    Ticker: 'KLAC',
    Company: 'K L A-Tencor Corp'
  },
  {
    Ticker: 'KLDO',
    Company: 'Kaleido Biosciences Inc'
  },
  {
    Ticker: 'KLIC',
    Company: 'Kulicke and Soffa'
  },
  {
    Ticker: 'KLXE',
    Company: 'Klx Energy Services Holdings Inc'
  },
  {
    Ticker: 'KMDA',
    Company: 'Kamada Ltd'
  },
  {
    Ticker: 'KMPH',
    Company: 'Kempharm Inc'
  },
  {
    Ticker: 'KNDI',
    Company: 'Kandi Techs Group'
  },
  {
    Ticker: 'KNSA',
    Company: 'Kiniksa Pharmaceuticals Ltd'
  },
  {
    Ticker: 'KNSL',
    Company: 'Kinsale Capital Group Inc'
  },
  {
    Ticker: 'KOD',
    Company: 'Kodiak Sciences Inc'
  },
  {
    Ticker: 'KOPN',
    Company: 'Kopin Cp'
  },
  {
    Ticker: 'KOSS',
    Company: 'Koss Cp'
  },
  {
    Ticker: 'KPTI',
    Company: 'Karyopharm Therapeut'
  },
  {
    Ticker: 'KRKR',
    Company: '36Kr Holdings Inc American Depositary Shares'
  },
  {
    Ticker: 'KRMA',
    Company: 'G-X Conscious Companies ETF'
  },
  {
    Ticker: 'KRMD',
    Company: 'Repro Med Sys Inc'
  },
  {
    Ticker: 'KRNT',
    Company: 'Kornit Digital Ord'
  },
  {
    Ticker: 'KRNY',
    Company: 'Kearny Financial'
  },
  {
    Ticker: 'KRTX',
    Company: 'Karuna Therapeutics Inc'
  },
  {
    Ticker: 'KRUS',
    Company: 'Kura Sushi USA Inc Cl A'
  },
  {
    Ticker: 'KRYS',
    Company: 'Krystal Biotech Inc'
  },
  {
    Ticker: 'KTCC',
    Company: 'Key Tronic Cp'
  },
  {
    Ticker: 'KTOS',
    Company: 'Kratos Defns'
  },
  {
    Ticker: 'KTOV',
    Company: 'Kitov Pharamceut Ads'
  },
  {
    Ticker: 'KTOVW',
    Company: 'Kitov Pharamceuti WT'
  },
  {
    Ticker: 'KURA',
    Company: 'Kura Oncology'
  },
  {
    Ticker: 'KVHI',
    Company: 'K V H Inds Inc'
  },
  {
    Ticker: 'KXIN',
    Company: 'Kaixin Auto Holdings'
  },
  {
    Ticker: 'KZIA',
    Company: 'Kazia Therapeutics Ltd'
  },
  {
    Ticker: 'KZR',
    Company: 'Kezar Life Sciences Inc'
  },
  {
    Ticker: 'LACQ',
    Company: 'Leisure Acquisition Corp'
  },
  {
    Ticker: 'LACQU',
    Company: 'Leisure Acquisition Corp Unit'
  },
  {
    Ticker: 'LACQW',
    Company: 'Leisure Acquisition Corp WT'
  },
  {
    Ticker: 'LAKE',
    Company: 'Lakeland Inds Inc'
  },
  {
    Ticker: 'LAMR',
    Company: 'Lamar Advertis A'
  },
  {
    Ticker: 'LANC',
    Company: 'Lancaster Colony Cor'
  },
  {
    Ticker: 'LAND',
    Company: 'Gladstone Land Corp'
  },
  {
    Ticker: 'LANDP',
    Company: 'Gladstone Land Corp'
  },
  {
    Ticker: 'LARK',
    Company: 'Landmark Bancorp Inc'
  },
  {
    Ticker: 'LASR',
    Company: 'Nlight Inc'
  },
  {
    Ticker: 'LATNU',
    Company: 'Union Acquisition Corp II Units'
  },
  {
    Ticker: 'LAUR',
    Company: 'Laureate Education Inc'
  },
  {
    Ticker: 'LAWS',
    Company: 'Lawson Products IN'
  },
  {
    Ticker: 'LAZY',
    Company: 'Lazydays Holdings Inc'
  },
  {
    Ticker: 'LBAI',
    Company: 'Lakeland Bancorp Inc'
  },
  {
    Ticker: 'LBC',
    Company: 'Luther Burbank Corp'
  },
  {
    Ticker: 'LBRDA',
    Company: 'Liberty Broadbd A'
  },
  {
    Ticker: 'LBRDK',
    Company: 'Liberty Broadbd C'
  },
  {
    Ticker: 'LBTYA',
    Company: 'Liberty Global Ord A'
  },
  {
    Ticker: 'LBTYB',
    Company: 'Liberty Global Ord B'
  },
  {
    Ticker: 'LBTYK',
    Company: 'Liberty Global Ord C'
  },
  {
    Ticker: 'LCA',
    Company: 'Landcadia Holdings II Inc Cl A'
  },
  {
    Ticker: 'LCAHU',
    Company: 'Landcadia Holdings II Inc Unit'
  },
  {
    Ticker: 'LCAHW',
    Company: 'Landcadia Holdings II Inc WT'
  },
  {
    Ticker: 'LCNB',
    Company: 'Lcnb Corp'
  },
  {
    Ticker: 'LCUT',
    Company: 'Lifetime Brands Inc'
  },
  {
    Ticker: 'LDRI',
    Company: 'Ladderrite 0-5 Year Corp Bond Invesco ETF'
  },
  {
    Ticker: 'LDSF',
    Company: 'FT Low Duration Strategic Focus ETF'
  },
  {
    Ticker: 'LE',
    Company: "Lands' End Inc"
  },
  {
    Ticker: 'LECO',
    Company: 'Lincoln Elec Hld'
  },
  {
    Ticker: 'LEDS',
    Company: 'Semileds Corp'
  },
  {
    Ticker: 'LEGH',
    Company: 'Legacy Housing Corp'
  },
  {
    Ticker: 'LEGR',
    Company: 'First Trust Indxx Innovative Transaction & Proce'
  },
  {
    Ticker: 'LEVL',
    Company: 'Level One Bancorp Inc'
  },
  {
    Ticker: 'LFAC',
    Company: 'Lf Capital Acquistion Corp Cl A'
  },
  {
    Ticker: 'LFACU',
    Company: 'Lf Capital Acquistion Corp Unit'
  },
  {
    Ticker: 'LFACW',
    Company: 'Lf Capital Acquistion Corp WT'
  },
  {
    Ticker: 'LFUS',
    Company: 'Littelfuse Inc'
  },
  {
    Ticker: 'LFVN',
    Company: 'Lifevantage Cp'
  },
  {
    Ticker: 'LGIH',
    Company: 'LGI Homes Inc'
  },
  {
    Ticker: 'LGND',
    Company: 'Ligand Pharm'
  },
  {
    Ticker: 'LHCG',
    Company: 'LHC Group Inc'
  },
  {
    Ticker: 'LIFE',
    Company: 'Atyr Pharma Inc'
  },
  {
    Ticker: 'LILA',
    Company: 'Liberty Latin America Cl A'
  },
  {
    Ticker: 'LILAK',
    Company: 'Liberty Latin America Cl C'
  },
  {
    Ticker: 'LINC',
    Company: 'Lincoln Educational'
  },
  {
    Ticker: 'LIND',
    Company: 'Lindblad Expd Hld'
  },
  {
    Ticker: 'LIQT',
    Company: 'Liqtech International Inc'
  },
  {
    Ticker: 'LITE',
    Company: 'Lumentum Hld'
  },
  {
    Ticker: 'LIVE',
    Company: 'Live Ventures'
  },
  {
    Ticker: 'LIVKU',
    Company: 'Liv Capital Acquisition Corp'
  },
  {
    Ticker: 'LIVN',
    Company: 'Livanova Plc OR Sh'
  },
  {
    Ticker: 'LIVX',
    Company: 'Livexlive Media Inc'
  },
  {
    Ticker: 'LJPC',
    Company: 'LA Jolla Pharma'
  },
  {
    Ticker: 'LK',
    Company: 'Luckin Coffee Inc ADR'
  },
  {
    Ticker: 'LKCO',
    Company: 'Luokung Technology Corp'
  },
  {
    Ticker: 'LKFN',
    Company: 'Lakeland Finl Corp'
  },
  {
    Ticker: 'LKQ',
    Company: 'LKQ Corp'
  },
  {
    Ticker: 'LLIT',
    Company: 'Lianluo Smart Ltd'
  },
  {
    Ticker: 'LLNW',
    Company: 'Limelight Networks'
  },
  {
    Ticker: 'LMAT',
    Company: 'Lemaitre Vascular'
  },
  {
    Ticker: 'LMB',
    Company: 'Limbach Holdings Inc'
  },
  {
    Ticker: 'LMBS',
    Company: 'Low Duration Opportunities ETF FT'
  },
  {
    Ticker: 'LMFA',
    Company: 'Lm Funding America Inc'
  },
  {
    Ticker: 'LMFAW',
    Company: 'Lm Funding WT'
  },
  {
    Ticker: 'LMNL',
    Company: 'Liminal Biosciences Inc.'
  },
  {
    Ticker: 'LMNR',
    Company: 'Limoneira Company'
  },
  {
    Ticker: 'LMNX',
    Company: 'Luminex Corp'
  },
  {
    Ticker: 'LMPX',
    Company: 'Lmp Automotive Holdings Inc'
  },
  {
    Ticker: 'LMRK',
    Company: 'Landmark Common Units'
  },
  {
    Ticker: 'LMRKN',
    Company: 'Landmark Infrastructure Partners LP'
  },
  {
    Ticker: 'LMRKO',
    Company: 'Landmark Infrastructure Partners LP'
  },
  {
    Ticker: 'LMRKP',
    Company: 'Landmark Inf Prf'
  },
  {
    Ticker: 'LMST',
    Company: 'Limestone Bancorp Inc'
  },
  {
    Ticker: 'LNDC',
    Company: 'Landec Cp'
  },
  {
    Ticker: 'LNGR',
    Company: 'G-X Longevity Thematic Et'
  },
  {
    Ticker: 'LNT',
    Company: 'Alliant Energy Corp'
  },
  {
    Ticker: 'LNTH',
    Company: 'Lantheus Holdings'
  },
  {
    Ticker: 'LOAC',
    Company: 'Longevity Acquisition Corp'
  },
  {
    Ticker: 'LOACR',
    Company: 'Longevity Acquisition Corp Right'
  },
  {
    Ticker: 'LOACU',
    Company: 'Longevity Acquisition Corp Units'
  },
  {
    Ticker: 'LOACW',
    Company: 'Longevity Acquisition Corp WT'
  },
  {
    Ticker: 'LOAN',
    Company: 'Manhattan Bridge'
  },
  {
    Ticker: 'LOB',
    Company: 'Live Oak Bancsh'
  },
  {
    Ticker: 'LOCO',
    Company: 'El Pollo Loco'
  },
  {
    Ticker: 'LOGC',
    Company: 'Logicbio Therapeutics Inc'
  },
  {
    Ticker: 'LOGI',
    Company: 'Logitech Int S.A.'
  },
  {
    Ticker: 'LOGM',
    Company: 'Logmein Inc'
  },
  {
    Ticker: 'LONE',
    Company: 'Lonestar Resources US Inc'
  },
  {
    Ticker: 'LOOP',
    Company: 'Loop Industries Inc'
  },
  {
    Ticker: 'LOPE',
    Company: 'Grand Canyon Educati'
  },
  {
    Ticker: 'LORL',
    Company: 'Loral Space Comm'
  },
  {
    Ticker: 'LOVE',
    Company: 'The Lovesac Company'
  },
  {
    Ticker: 'LPCN',
    Company: 'Lipocine Inc'
  },
  {
    Ticker: 'LPLA',
    Company: 'Lpl Financial Hld'
  },
  {
    Ticker: 'LPSN',
    Company: 'Liveperson Inc'
  },
  {
    Ticker: 'LPTH',
    Company: 'Lightpath Tech Inc'
  },
  {
    Ticker: 'LPTX',
    Company: 'Leap Therapeutics Inc'
  },
  {
    Ticker: 'LQDA',
    Company: 'Liquidia Technologies Inc'
  },
  {
    Ticker: 'LQDT',
    Company: 'Liquidity Services'
  },
  {
    Ticker: 'LRCX',
    Company: 'Lam Research Corp'
  },
  {
    Ticker: 'LRGE',
    Company: 'Legg Clearbridge Large Cap Groowth ETF'
  },
  {
    Ticker: 'LSBK',
    Company: 'Lake Shore Bancorp'
  },
  {
    Ticker: 'LSCC',
    Company: 'Lattice Semicond'
  },
  {
    Ticker: 'LSTR',
    Company: 'Landstar System'
  },
  {
    Ticker: 'LSXMA',
    Company: 'Liberty Srs XM CS A'
  },
  {
    Ticker: 'LSXMB',
    Company: 'Liberty Srs XM CS B'
  },
  {
    Ticker: 'LSXMK',
    Company: 'Liberty Srs XM CS C'
  },
  {
    Ticker: 'LTBR',
    Company: 'Lightbridge Corp'
  },
  {
    Ticker: 'LTRPA',
    Company: 'Liberty Tripadv A'
  },
  {
    Ticker: 'LTRPB',
    Company: 'Liberty Tripadv B'
  },
  {
    Ticker: 'LTRX',
    Company: 'Lantronix Inc'
  },
  {
    Ticker: 'LULU',
    Company: 'Lululemon Athletica'
  },
  {
    Ticker: 'LUNA',
    Company: 'Luna Innovations Inc'
  },
  {
    Ticker: 'LVGO',
    Company: 'Livongo Health Inc'
  },
  {
    Ticker: 'LVHD',
    Company: 'Legg Low Vol HI Div ETF'
  },
  {
    Ticker: 'LWAY',
    Company: 'Lifeway Foods Inc'
  },
  {
    Ticker: 'LX',
    Company: 'Lexinfintech Holdings Ltd'
  },
  {
    Ticker: 'LXRX',
    Company: 'Lexicon Pharmaceutcl'
  },
  {
    Ticker: 'LYFT',
    Company: 'Lyft Inc Cl A'
  },
  {
    Ticker: 'LYL',
    Company: 'Dragon Victory Intl Ltd'
  },
  {
    Ticker: 'LYTS',
    Company: 'L S I Industries'
  },
  {
    Ticker: 'MACK',
    Company: 'Merrimack Pharmaceuticals Inc'
  },
  {
    Ticker: 'MAGS',
    Company: 'Magal Security Syst'
  },
  {
    Ticker: 'MANH',
    Company: 'Manhattan Assoc'
  },
  {
    Ticker: 'MANT',
    Company: 'Mantech Intl Cp A'
  },
  {
    Ticker: 'MAR',
    Company: 'Marriot Int Cl A'
  },
  {
    Ticker: 'MARA',
    Company: 'Marathon Patent Grou'
  },
  {
    Ticker: 'MARK',
    Company: 'Remark Holdings Inc'
  },
  {
    Ticker: 'MARPS',
    Company: 'Marine Petroleum U'
  },
  {
    Ticker: 'MASI',
    Company: 'Masimo Corp'
  },
  {
    Ticker: 'MAT',
    Company: 'Mattel Inc'
  },
  {
    Ticker: 'MATW',
    Company: 'Matthews Intl Corp'
  },
  {
    Ticker: 'MAYS',
    Company: 'J W Mays Inc'
  },
  {
    Ticker: 'MBB',
    Company: 'MBS Ishares ETF'
  },
  {
    Ticker: 'MBCN',
    Company: 'Middlefield Banc'
  },
  {
    Ticker: 'MBII',
    Company: 'Marrone Bio Innov'
  },
  {
    Ticker: 'MBIN',
    Company: 'Merchants Bancorp'
  },
  {
    Ticker: 'MBINO',
    Company: 'Merchants Bancorp Depositary Shares Each Represe'
  },
  {
    Ticker: 'MBINP',
    Company: 'Merchants Bancorp [Indiana] Perp Pfd Ser A'
  },
  {
    Ticker: 'MBIO',
    Company: 'Mustang Bio Inc'
  },
  {
    Ticker: 'MBOT',
    Company: 'Microbot Medical Inc'
  },
  {
    Ticker: 'MBRX',
    Company: 'Moleculin Biotech CS'
  },
  {
    Ticker: 'MBSD',
    Company: 'Flexshares Disciplined Duration MBS Index Fund'
  },
  {
    Ticker: 'MBUU',
    Company: 'Malibu Boats Inc'
  },
  {
    Ticker: 'MBWM',
    Company: 'Mercantile Bank Corp'
  },
  {
    Ticker: 'MCBC',
    Company: 'Macatawa Bank Corp'
  },
  {
    Ticker: 'MCBS',
    Company: 'Metrocity Bankshares Inc'
  },
  {
    Ticker: 'MCEF',
    Company: 'Municipal CEF Income Opportunity ETF FT'
  },
  {
    Ticker: 'MCEP',
    Company: 'Mid-Con Energy Partn'
  },
  {
    Ticker: 'MCFT',
    Company: 'Mcbc Holdings'
  },
  {
    Ticker: 'MCHI',
    Company: 'China Ishares MSCI ETF'
  },
  {
    Ticker: 'MCHP',
    Company: 'Microchip Technology'
  },
  {
    Ticker: 'MCHX',
    Company: 'Marchex Inc'
  },
  {
    Ticker: 'MCMJU',
    Company: 'Merida Merger Corp I'
  },
  {
    Ticker: 'MCRB',
    Company: 'Seres Theraptc'
  },
  {
    Ticker: 'MCRI',
    Company: 'Monarch Casino'
  },
  {
    Ticker: 'MDB',
    Company: 'Mongodb Inc Cl A'
  },
  {
    Ticker: 'MDCA',
    Company: 'Mdc Partners Cl A'
  },
  {
    Ticker: 'MDCO',
    Company: 'Medicines Company'
  },
  {
    Ticker: 'MDGL',
    Company: 'Madrigal Pharmaceuticals Inc'
  },
  {
    Ticker: 'MDGS',
    Company: 'Medigus Ltd Ads'
  },
  {
    Ticker: 'MDIV',
    Company: 'Multi-Asset Diversified Income Index Fund FT'
  },
  {
    Ticker: 'MDJH',
    Company: 'Mdjm Ltd'
  },
  {
    Ticker: 'MDLZ',
    Company: 'Mondelez Intl Inc'
  },
  {
    Ticker: 'MDRR',
    Company: 'Medalist Diversified REIT Inc'
  },
  {
    Ticker: 'MDRX',
    Company: 'Allscripts Hlthcare'
  },
  {
    Ticker: 'MDWD',
    Company: 'Mediwound Ltd Ord Sh'
  },
  {
    Ticker: 'MEDP',
    Company: 'Medpace Holdings Inc'
  },
  {
    Ticker: 'MEET',
    Company: 'The Meet Group'
  },
  {
    Ticker: 'MEIP',
    Company: 'Mei Pharma Inc'
  },
  {
    Ticker: 'MELI',
    Company: 'Mercadolibre Inc'
  },
  {
    Ticker: 'MEOH',
    Company: 'Methanex Cp'
  },
  {
    Ticker: 'MERC',
    Company: 'Mercer Intl Inc'
  },
  {
    Ticker: 'MESA',
    Company: 'Mesa Air Group Inc'
  },
  {
    Ticker: 'MESO',
    Company: 'Mesoblast Ltd Ads'
  },
  {
    Ticker: 'METC',
    Company: 'Ramaco Resources Inc'
  },
  {
    Ticker: 'MFIN',
    Company: 'Medallion Fin Cp'
  },
  {
    Ticker: 'MFINL',
    Company: 'Medallion Fin Notes'
  },
  {
    Ticker: 'MFNC',
    Company: 'Mackinac Financial'
  },
  {
    Ticker: 'MFSF',
    Company: 'Mutualfirst Finl Inc'
  },
  {
    Ticker: 'MGEE',
    Company: 'Mge Energy Inc'
  },
  {
    Ticker: 'MGEN',
    Company: 'Miragen Therapeutics Inc'
  },
  {
    Ticker: 'MGI',
    Company: 'Moneygram Intl'
  },
  {
    Ticker: 'MGIC',
    Company: 'Magic Software Entpr'
  },
  {
    Ticker: 'MGLN',
    Company: 'Magellan Health'
  },
  {
    Ticker: 'MGNX',
    Company: 'Macrogenics'
  },
  {
    Ticker: 'MGPI',
    Company: 'MGP Ingredients Inc'
  },
  {
    Ticker: 'MGRC',
    Company: 'Mcgrath Rentcorp'
  },
  {
    Ticker: 'MGTA',
    Company: 'Magenta Therapeutics Inc'
  },
  {
    Ticker: 'MGTX',
    Company: 'Meiragtx Holdings Plc'
  },
  {
    Ticker: 'MGYR',
    Company: 'Magyar Bancorp Inc'
  },
  {
    Ticker: 'MHLD',
    Company: 'Maiden Holdings Ltd'
  },
  {
    Ticker: 'MICT',
    Company: 'Micronet Enertec Tec'
  },
  {
    Ticker: 'MIDD',
    Company: 'Middleby Corp'
  },
  {
    Ticker: 'MIK',
    Company: 'The Michaels Comp'
  },
  {
    Ticker: 'MILN',
    Company: 'G-X Millennials Thematic ETF'
  },
  {
    Ticker: 'MIME',
    Company: 'Mimecast Ltd Ord'
  },
  {
    Ticker: 'MIND',
    Company: 'Mitcham Industries Inc'
  },
  {
    Ticker: 'MINDP',
    Company: 'Mitcham Industries Inc'
  },
  {
    Ticker: 'MINI',
    Company: 'Mobile Mini Inc'
  },
  {
    Ticker: 'MIRM',
    Company: 'Mirum Pharmaceuticals Inc'
  },
  {
    Ticker: 'MIST',
    Company: 'Milestone Pharmaceuticals Inc'
  },
  {
    Ticker: 'MITK',
    Company: 'Mitek Systems Inc'
  },
  {
    Ticker: 'MITO',
    Company: 'Stealth Biotherapeutics Corp Ads'
  },
  {
    Ticker: 'MJCO',
    Company: 'Majesco'
  },
  {
    Ticker: 'MKGI',
    Company: 'Monaker Group Inc'
  },
  {
    Ticker: 'MKSI',
    Company: 'Mks Instruments Inc'
  },
  {
    Ticker: 'MKTX',
    Company: 'Marketaxess Holdings'
  },
  {
    Ticker: 'MLAB',
    Company: 'Mesa Labs Inc'
  },
  {
    Ticker: 'MLCO',
    Company: 'Melco Resorts & Entertainment'
  },
  {
    Ticker: 'MLHR',
    Company: 'Miller Herman Inc'
  },
  {
    Ticker: 'MLND',
    Company: 'Millendo Therapeutics Inc'
  },
  {
    Ticker: 'MLNT',
    Company: 'Melinta Therapeutics Inc'
  },
  {
    Ticker: 'MLNX',
    Company: 'Mellanox Technologies'
  },
  {
    Ticker: 'MLVF',
    Company: 'Malvern Bncp'
  },
  {
    Ticker: 'MMAC',
    Company: 'Mma Capital Mngt'
  },
  {
    Ticker: 'MMLP',
    Company: 'Martin Midstrm LP'
  },
  {
    Ticker: 'MMSI',
    Company: 'Merit Medical Sys'
  },
  {
    Ticker: 'MMYT',
    Company: 'Makemytrip Ltd'
  },
  {
    Ticker: 'MNCL',
    Company: 'Monocle Acquisition Corp'
  },
  {
    Ticker: 'MNCLU',
    Company: 'Monocle Acquisition Corp Units'
  },
  {
    Ticker: 'MNCLW',
    Company: 'Monocle Acquisition Corp WT'
  },
  {
    Ticker: 'MNDO',
    Company: 'Mind C.T.I. Ltd'
  },
  {
    Ticker: 'MNKD',
    Company: 'Mannkind Corp'
  },
  {
    Ticker: 'MNLO',
    Company: 'Menlo Therapeutics Inc'
  },
  {
    Ticker: 'MNOV',
    Company: 'Medicinova Inc'
  },
  {
    Ticker: 'MNRO',
    Company: 'Monro Muffler Brak'
  },
  {
    Ticker: 'MNSB',
    Company: 'Mainstreet Bank'
  },
  {
    Ticker: 'MNST',
    Company: 'Monster Beverage Cp'
  },
  {
    Ticker: 'MNTA',
    Company: 'Momenta Pharma'
  },
  {
    Ticker: 'MNTX',
    Company: 'Manitex Intl Inc'
  },
  {
    Ticker: 'MOBL',
    Company: 'Mobileiron Inc'
  },
  {
    Ticker: 'MOFG',
    Company: 'Midwestone Fnl Gp'
  },
  {
    Ticker: 'MOGO',
    Company: 'Mogo Inc'
  },
  {
    Ticker: 'MOHO',
    Company: 'Ecmoho Limited American Depositary Shares'
  },
  {
    Ticker: 'MOMO',
    Company: 'Momo Inc Ads'
  },
  {
    Ticker: 'MOR',
    Company: 'Morphosys Ag ADR'
  },
  {
    Ticker: 'MORF',
    Company: 'Morphic Holding Inc'
  },
  {
    Ticker: 'MORN',
    Company: 'Morningstar Inc'
  },
  {
    Ticker: 'MOSY',
    Company: 'Mosys Inc'
  },
  {
    Ticker: 'MOTS',
    Company: 'Motus Gi Holdings Inc'
  },
  {
    Ticker: 'MOXC',
    Company: 'Moxian Inc'
  },
  {
    Ticker: 'MPAA',
    Company: 'Motorcar Parts Amer'
  },
  {
    Ticker: 'MPB',
    Company: 'Mid Penn Bancorp Inc'
  },
  {
    Ticker: 'MPVD',
    Company: 'Mountain Prv Dmds'
  },
  {
    Ticker: 'MPWR',
    Company: 'Monolithic Power Sys'
  },
  {
    Ticker: 'MRAM',
    Company: 'Everspin Technologies Inc'
  },
  {
    Ticker: 'MRBK',
    Company: 'Meridian Bank'
  },
  {
    Ticker: 'MRCC',
    Company: 'Monroe Capital Corp'
  },
  {
    Ticker: 'MRCCL',
    Company: 'Monroe Capital Corp 5.75% Notes Due 2023'
  },
  {
    Ticker: 'MRCY',
    Company: 'Mercury Sys Inc'
  },
  {
    Ticker: 'MREO',
    Company: 'Mereo Biopharma Group Plc ADR'
  },
  {
    Ticker: 'MRIC',
    Company: 'Mri Interventions'
  },
  {
    Ticker: 'MRIN',
    Company: 'Marin Sotfware Incorporated'
  },
  {
    Ticker: 'MRKR',
    Company: 'Marker Therapeutics Inc'
  },
  {
    Ticker: 'MRLN',
    Company: 'Marlin Business Serv'
  },
  {
    Ticker: 'MRNA',
    Company: 'Moderna Inc'
  },
  {
    Ticker: 'MRNS',
    Company: 'Marinus Pharma CS'
  },
  {
    Ticker: 'MRSN',
    Company: 'Mersana Therapeutics Inc'
  },
  {
    Ticker: 'MRTN',
    Company: 'Marten Transport L'
  },
  {
    Ticker: 'MRTX',
    Company: 'Mirati Therapeutics'
  },
  {
    Ticker: 'MRUS',
    Company: 'Merus N.V. CS'
  },
  {
    Ticker: 'MRVL',
    Company: 'Marvell Tech Group'
  },
  {
    Ticker: 'MSBF',
    Company: 'MSB Financial'
  },
  {
    Ticker: 'MSBI',
    Company: 'Midland Sts BNC'
  },
  {
    Ticker: 'MSEX',
    Company: 'Middlesex Water Company'
  },
  {
    Ticker: 'MSFT',
    Company: 'Microsoft Corp'
  },
  {
    Ticker: 'MSON',
    Company: 'Misonix Inc'
  },
  {
    Ticker: 'MSTR',
    Company: 'Microstrategy Cl A'
  },
  {
    Ticker: 'MSVB',
    Company: 'Mid-Southn Svg Bk IN'
  },
  {
    Ticker: 'MTBC',
    Company: 'Medical Transc Bl'
  },
  {
    Ticker: 'MTBCP',
    Company: 'Mtbc 11% Sr A Prf ST'
  },
  {
    Ticker: 'MTC',
    Company: 'Mmtec Inc'
  },
  {
    Ticker: 'MTCH',
    Company: 'Match Group Inc CS'
  },
  {
    Ticker: 'MTEM',
    Company: 'Molecular Templates Inc'
  },
  {
    Ticker: 'MTEX',
    Company: 'Mannatech Inc'
  },
  {
    Ticker: 'MTFB',
    Company: 'Motif Bio Plc'
  },
  {
    Ticker: 'MTFBW',
    Company: 'Motif Bio Plc WT'
  },
  {
    Ticker: 'MTLS',
    Company: 'Materialise NV Ads'
  },
  {
    Ticker: 'MTP',
    Company: 'Midatech Pharma Ads'
  },
  {
    Ticker: 'MTRX',
    Company: 'Matrix Service Company'
  },
  {
    Ticker: 'MTSC',
    Company: 'M T S Systems Cp'
  },
  {
    Ticker: 'MTSI',
    Company: 'Macom Technology S'
  },
  {
    Ticker: 'MTSL',
    Company: 'Mer Telemanagement Solutions Ltd'
  },
  {
    Ticker: 'MU',
    Company: 'Micron Technology'
  },
  {
    Ticker: 'MUDS',
    Company: 'Mudrick Capital Acquisition Corp Cl A'
  },
  {
    Ticker: 'MUDSU',
    Company: 'Mudrick Capital Acquisition Corp Unit'
  },
  {
    Ticker: 'MUDSW',
    Company: 'Mudrick Capital Acquisition Corp WT'
  },
  {
    Ticker: 'MVBF',
    Company: 'Mvb Financial Corp'
  },
  {
    Ticker: 'MVIS',
    Company: 'Microvision'
  },
  {
    Ticker: 'MWK',
    Company: 'Mohawk Group Holdings Inc'
  },
  {
    Ticker: 'MXIM',
    Company: 'Maxim Integrated'
  },
  {
    Ticker: 'MYFW',
    Company: 'First Western Financial Inc'
  },
  {
    Ticker: 'MYGN',
    Company: 'Myriad Genetics Inc'
  },
  {
    Ticker: 'MYL',
    Company: 'Mylan NV Ord Shs'
  },
  {
    Ticker: 'MYOK',
    Company: 'Myokardia Inc'
  },
  {
    Ticker: 'MYOS',
    Company: 'Myos Rens Tech'
  },
  {
    Ticker: 'MYRG',
    Company: 'MYR Group Inc'
  },
  {
    Ticker: 'MYSZ',
    Company: 'My Size Inc'
  },
  {
    Ticker: 'MYT',
    Company: 'Urban Tea Inc'
  },
  {
    Ticker: 'NAII',
    Company: 'Natural Alternativ'
  },
  {
    Ticker: 'NAKD',
    Company: 'Naked Brand Group Inc'
  },
  {
    Ticker: 'NAOV',
    Company: 'Nanovibronix Inc'
  },
  {
    Ticker: 'NATH',
    Company: "Nathan's Famous IN"
  },
  {
    Ticker: 'NATI',
    Company: 'Natl Instruments Cor'
  },
  {
    Ticker: 'NATR',
    Company: 'Nature S Sunshine'
  },
  {
    Ticker: 'NAVI',
    Company: 'Navient Cp'
  },
  {
    Ticker: 'NBEV',
    Company: 'New Age Beverages Corp'
  },
  {
    Ticker: 'NBIX',
    Company: 'Neurocrine Bioscienc'
  },
  {
    Ticker: 'NBN',
    Company: 'Northeast Bncp'
  },
  {
    Ticker: 'NBRV',
    Company: 'Nabriva Therape Ads'
  },
  {
    Ticker: 'NBSE',
    Company: 'Neu Base Therapeutics Inc'
  },
  {
    Ticker: 'NBTB',
    Company: 'NBT Bancorp Inc'
  },
  {
    Ticker: 'NCBS',
    Company: 'Nicolet Bnkshr'
  },
  {
    Ticker: 'NCMI',
    Company: 'National Cinemedia'
  },
  {
    Ticker: 'NCNA',
    Company: 'Nucana Plc ADR'
  },
  {
    Ticker: 'NCSM',
    Company: 'Ncs Multistage Holdings Inc'
  },
  {
    Ticker: 'NCTY',
    Company: 'The9 Ltd'
  },
  {
    Ticker: 'NDAQ',
    Company: 'Nasdaq Inc'
  },
  {
    Ticker: 'NDLS',
    Company: 'Noodles & Company'
  },
  {
    Ticker: 'NDRA',
    Company: 'Endra Life Sciences Inc'
  },
  {
    Ticker: 'NDRAW',
    Company: 'Endra Life Sciences Inc WT'
  },
  {
    Ticker: 'NDSN',
    Company: 'Nordson Corp'
  },
  {
    Ticker: 'NEBU',
    Company: 'Nebula Acquisition Corp Cl A'
  },
  {
    Ticker: 'NEBUU',
    Company: 'Nebula Acquisition Corp Unit'
  },
  {
    Ticker: 'NEBUW',
    Company: 'Nebula Acquisition Corp WT'
  },
  {
    Ticker: 'NEO',
    Company: 'Neogenomics Inc'
  },
  {
    Ticker: 'NEOG',
    Company: 'Neogen Cp'
  },
  {
    Ticker: 'NEON',
    Company: 'Neonode Inc'
  },
  {
    Ticker: 'NEOS',
    Company: 'Neos Therapeutics'
  },
  {
    Ticker: 'NEPH',
    Company: 'Nephros Inc'
  },
  {
    Ticker: 'NEPT',
    Company: 'Neptune Wellness Solutions Inc'
  },
  {
    Ticker: 'NERV',
    Company: 'Minerva Neuroscie'
  },
  {
    Ticker: 'NESR',
    Company: 'National Energy Services Reunited Corp'
  },
  {
    Ticker: 'NESRW',
    Company: 'National Energy Services Reunited Corp WT'
  },
  {
    Ticker: 'NETE',
    Company: 'Net Element Inc'
  },
  {
    Ticker: 'NEWA',
    Company: 'Newater Technology Inc'
  },
  {
    Ticker: 'NEWT',
    Company: 'Newtek Bus Svcs'
  },
  {
    Ticker: 'NEWTI',
    Company: 'Newtek Business Services Corp 6.25% Notes Due 2'
  },
  {
    Ticker: 'NEWTL',
    Company: 'Newtek Business Services Corp 5.75% Notes Due 2'
  },
  {
    Ticker: 'NEXT',
    Company: 'Nextdecade Corp'
  },
  {
    Ticker: 'NFBK',
    Company: 'Northfield Bncrp Del'
  },
  {
    Ticker: 'NFE',
    Company: 'New Fortress Energy Llc'
  },
  {
    Ticker: 'NFIN',
    Company: 'Netfin Acquisition Corp Cl A'
  },
  {
    Ticker: 'NFINU',
    Company: 'Netfin Acquisition Corp Unit'
  },
  {
    Ticker: 'NFINW',
    Company: 'Netfin Acquisition Corp WT'
  },
  {
    Ticker: 'NFLX',
    Company: 'Netflix Inc'
  },
  {
    Ticker: 'NFTY',
    Company: 'First Trust India Nifty 50 EW ETF'
  },
  {
    Ticker: 'NGHC',
    Company: 'National General Hld'
  },
  {
    Ticker: 'NGHCN',
    Company: 'National General Holdings Corp'
  },
  {
    Ticker: 'NGHCO',
    Company: 'National Gnl Hld Ds'
  },
  {
    Ticker: 'NGHCP',
    Company: 'National Gen Srs A'
  },
  {
    Ticker: 'NGHCZ',
    Company: 'National Gen Sub NTS'
  },
  {
    Ticker: 'NGM',
    Company: 'Ngm Biopharmaceuticals Inc'
  },
  {
    Ticker: 'NH',
    Company: 'Nanthealth Inc'
  },
  {
    Ticker: 'NHLD',
    Company: 'National Holdings'
  },
  {
    Ticker: 'NHLDW',
    Company: 'National Holdings Corp WT'
  },
  {
    Ticker: 'NHTC',
    Company: 'Natural Hlth Trd'
  },
  {
    Ticker: 'NICE',
    Company: 'Nice Ltd'
  },
  {
    Ticker: 'NICK',
    Company: 'Nicholas Fincl Inc'
  },
  {
    Ticker: 'NIHD',
    Company: 'NII Holdings'
  },
  {
    Ticker: 'NIU',
    Company: 'Niu Technologies ADR'
  },
  {
    Ticker: 'NK',
    Company: 'Nantkwest Inc CS'
  },
  {
    Ticker: 'NKSH',
    Company: 'Natl Bankshares Inc'
  },
  {
    Ticker: 'NKTR',
    Company: 'Nektar Therapeutics'
  },
  {
    Ticker: 'NLNK',
    Company: 'Newlink Genetics Cor'
  },
  {
    Ticker: 'NLOK',
    Company: 'Nortonlifelock Inc.'
  },
  {
    Ticker: 'NLTX',
    Company: 'Neoleukin Therapeutics Inc'
  },
  {
    Ticker: 'NMCI',
    Company: 'Navios Maritime Containers L.P.'
  },
  {
    Ticker: 'NMIH',
    Company: 'Nmi Holdings Inc'
  },
  {
    Ticker: 'NMRD',
    Company: 'Nemaura Med Inc'
  },
  {
    Ticker: 'NMRK',
    Company: 'Newmark Group Inc Cl A'
  },
  {
    Ticker: 'NNBR',
    Company: 'NN Inc'
  },
  {
    Ticker: 'NNDM',
    Company: 'Nano Dimension Ads'
  },
  {
    Ticker: 'NODK',
    Company: 'Ni Holdings Inc'
  },
  {
    Ticker: 'NOVN',
    Company: 'Novan Inc'
  },
  {
    Ticker: 'NOVT',
    Company: 'Novanta Inc'
  },
  {
    Ticker: 'NPA',
    Company: 'New Providence Acquisition Corp. Cl A'
  },
  {
    Ticker: 'NPAUU',
    Company: 'New Providence Acquisition Corp'
  },
  {
    Ticker: 'NPAWW',
    Company: 'New Providence Acquisition Corp. WT'
  },
  {
    Ticker: 'NRC',
    Company: 'National Research Corp'
  },
  {
    Ticker: 'NRIM',
    Company: 'Northrim Bancorp Inc'
  },
  {
    Ticker: 'NSEC',
    Company: 'Natl Security Gp'
  },
  {
    Ticker: 'NSIT',
    Company: 'Insight Enterpr'
  },
  {
    Ticker: 'NSSC',
    Company: 'Napco Sec Tech Inc'
  },
  {
    Ticker: 'NSTG',
    Company: 'Nanostring Tech'
  },
  {
    Ticker: 'NSYS',
    Company: 'Nortech Systems IN'
  },
  {
    Ticker: 'NTAP',
    Company: 'Netapp Inc'
  },
  {
    Ticker: 'NTCT',
    Company: 'Netscout Systems'
  },
  {
    Ticker: 'NTEC',
    Company: 'Intec Pharma Ord Shr'
  },
  {
    Ticker: 'NTES',
    Company: 'Netease Inc Ads'
  },
  {
    Ticker: 'NTGN',
    Company: 'Neon Therapeutics Inc'
  },
  {
    Ticker: 'NTGR',
    Company: 'Netgear Inc'
  },
  {
    Ticker: 'NTIC',
    Company: 'Northern Technologies Intl Corp'
  },
  {
    Ticker: 'NTLA',
    Company: 'Intellia Thera CS'
  },
  {
    Ticker: 'NTNX',
    Company: 'Nutanix Inc'
  },
  {
    Ticker: 'NTRA',
    Company: 'Natera Inc'
  },
  {
    Ticker: 'NTRP',
    Company: 'Neurotrope Inc'
  },
  {
    Ticker: 'NTRS',
    Company: 'Northern Trust Corp'
  },
  {
    Ticker: 'NTRSO',
    Company: 'Northern Trust Prf Perpetual USD Dpsh Rp1/1000Th'
  },
  {
    Ticker: 'NTRSP',
    Company: 'Northern TR Dep Shs'
  },
  {
    Ticker: 'NTUS',
    Company: 'Natus Medical Inc'
  },
  {
    Ticker: 'NTWK',
    Company: 'Netsol Tech Inc'
  },
  {
    Ticker: 'NUAN',
    Company: 'Nuance Communicatns'
  },
  {
    Ticker: 'NURO',
    Company: 'Neurometrix Inc'
  },
  {
    Ticker: 'NUROW',
    Company: 'Neurometrix Inc WT'
  },
  {
    Ticker: 'NUVA',
    Company: 'Nuvasive Inc'
  },
  {
    Ticker: 'NVAX',
    Company: 'Novavax Inc'
  },
  {
    Ticker: 'NVCN',
    Company: 'Neovasc Inc'
  },
  {
    Ticker: 'NVCR',
    Company: 'Novocure Ltd Ord Sh'
  },
  {
    Ticker: 'NVDA',
    Company: 'Nvidia Corp'
  },
  {
    Ticker: 'NVEC',
    Company: 'Nve Corp'
  },
  {
    Ticker: 'NVEE',
    Company: 'Nv5 Global'
  },
  {
    Ticker: 'NVFY',
    Company: 'Nova Lifestyle Inc'
  },
  {
    Ticker: 'NVIV',
    Company: 'Invivo Therapeutics Holdings Corp'
  },
  {
    Ticker: 'NVMI',
    Company: 'Nova Measuring Instr'
  },
  {
    Ticker: 'NVTR',
    Company: 'Nuvectra Corp'
  },
  {
    Ticker: 'NVUS',
    Company: 'Novus Therapeutics Inc'
  },
  {
    Ticker: 'NWBI',
    Company: 'Northwest Bancshares'
  },
  {
    Ticker: 'NWFL',
    Company: 'Norwood Financial Cp'
  },
  {
    Ticker: 'NWL',
    Company: 'Newell Rubbermaid Inc'
  },
  {
    Ticker: 'NWLI',
    Company: 'Natl Wstrn Life Gp A'
  },
  {
    Ticker: 'NWPX',
    Company: 'Northwest Pipe Company'
  },
  {
    Ticker: 'NWS',
    Company: 'News Cp Cl B'
  },
  {
    Ticker: 'NWSA',
    Company: 'News Cp Cl A'
  },
  {
    Ticker: 'NXGN',
    Company: 'Nextgen Healthcare Inc'
  },
  {
    Ticker: 'NXPI',
    Company: 'Nxp Semiconductors'
  },
  {
    Ticker: 'NXST',
    Company: 'Nexstar Broadcasting'
  },
  {
    Ticker: 'NXTC',
    Company: 'Nextcure Inc'
  },
  {
    Ticker: 'NXTD',
    Company: 'Nxt-Id Inc'
  },
  {
    Ticker: 'NXTG',
    Company: 'Nasdaq Smartphone Index Fund FT'
  },
  {
    Ticker: 'NYMT',
    Company: 'New York Mtge Trust'
  },
  {
    Ticker: 'NYMTM',
    Company: 'New York Mortgage Trust Inc Fxdfr Prf Perpetual'
  },
  {
    Ticker: 'NYMTN',
    Company: 'New York Mortgage Trust Inc 8.00% Series D'
  },
  {
    Ticker: 'NYMTO',
    Company: 'New York Mort 7.875'
  },
  {
    Ticker: 'NYMTP',
    Company: 'New York Mrtg 7.75 B'
  },
  {
    Ticker: 'NYMX',
    Company: 'Nymox Pharm Corp'
  },
  {
    Ticker: 'NYNY',
    Company: 'Empire Resorts Inc'
  },
  {
    Ticker: 'OBAS',
    Company: 'Optibase Ltd'
  },
  {
    Ticker: 'OBCI',
    Company: 'Ocean Bio Chem Inc'
  },
  {
    Ticker: 'OBLN',
    Company: 'Obalon Therapeutics Inc'
  },
  {
    Ticker: 'OBNK',
    Company: 'Origin Bancorp Inc'
  },
  {
    Ticker: 'OBSV',
    Company: 'Obseva Sa'
  },
  {
    Ticker: 'OCC',
    Company: 'Optical Cable Corp'
  },
  {
    Ticker: 'OCCI',
    Company: 'Ofs Credit Company Inc'
  },
  {
    Ticker: 'OCCIP',
    Company: 'Ofs Credit Co. Inc Pfd Ser A'
  },
  {
    Ticker: 'OCFC',
    Company: 'Oceanfirst Finl Corp'
  },
  {
    Ticker: 'OCGN',
    Company: 'Ocugen Inc'
  },
  {
    Ticker: 'OCSI',
    Company: 'Oaktree Strategic Income Corp'
  },
  {
    Ticker: 'OCSL',
    Company: 'Oaktree Specialty Lending Corp'
  },
  {
    Ticker: 'OCSLL',
    Company: 'Oaktree Specialty Lending Corp 6.125 Sn'
  },
  {
    Ticker: 'OCUL',
    Company: 'Ocular Therapeut'
  },
  {
    Ticker: 'ODFL',
    Company: 'Old Dominion Freig'
  },
  {
    Ticker: 'ODP',
    Company: 'Office Depot'
  },
  {
    Ticker: 'ODT',
    Company: 'Odonate Therapeutics Inc'
  },
  {
    Ticker: 'OESX',
    Company: 'Orion Energy Syst'
  },
  {
    Ticker: 'OFED',
    Company: 'Oconee Fed Fin Corp'
  },
  {
    Ticker: 'OFIX',
    Company: 'Orthofix Intl NV'
  },
  {
    Ticker: 'OFLX',
    Company: 'Omega Flex Inc'
  },
  {
    Ticker: 'OFS',
    Company: 'Ofs Capital Corp'
  },
  {
    Ticker: 'OFSSI',
    Company: 'Ofs Capital Corp 5.95% Notes Due 2026'
  },
  {
    Ticker: 'OFSSL',
    Company: 'Ofs Capital Corp 6.375% Notes Due 2025'
  },
  {
    Ticker: 'OFSSZ',
    Company: 'Ofs Capital Corp 6.50% Notes Due 2025'
  },
  {
    Ticker: 'OGI',
    Company: 'Organigram Hlds Inc'
  },
  {
    Ticker: 'OHAI',
    Company: 'Oha Investmnt Cp'
  },
  {
    Ticker: 'OIIM',
    Company: 'O2Micro Intl Ltd'
  },
  {
    Ticker: 'OKTA',
    Company: 'Okta Inc Cl A'
  },
  {
    Ticker: 'OLBK',
    Company: 'Old Line Bancshs MD'
  },
  {
    Ticker: 'OLD',
    Company: 'Long-Term Care ETF'
  },
  {
    Ticker: 'OLED',
    Company: 'Universal Display'
  },
  {
    Ticker: 'OLLI',
    Company: 'Ollies Bargain CS'
  },
  {
    Ticker: 'OMAB',
    Company: 'Grupo Aeroportuario'
  },
  {
    Ticker: 'OMCL',
    Company: 'Omnicell Inc'
  },
  {
    Ticker: 'OMER',
    Company: 'Omeros Corp'
  },
  {
    Ticker: 'OMEX',
    Company: 'Odyssey Marine Expl'
  },
  {
    Ticker: 'ON',
    Company: 'On Semiconductor'
  },
  {
    Ticker: 'ONB',
    Company: 'Old National Bncp'
  },
  {
    Ticker: 'ONCE',
    Company: 'Spark Therap'
  },
  {
    Ticker: 'ONCS',
    Company: 'Oncosec Medical'
  },
  {
    Ticker: 'ONCT',
    Company: 'Oncternal Therapeutics Inc'
  },
  {
    Ticker: 'ONCY',
    Company: 'Oncolytics Biotech Inc'
  },
  {
    Ticker: 'ONEQ',
    Company: 'Nasdaq Composite ETF'
  },
  {
    Ticker: 'ONTX',
    Company: 'Onconova Therapeutic'
  },
  {
    Ticker: 'ONTXW',
    Company: 'Onconova Therapeutics Inc'
  },
  {
    Ticker: 'ONVO',
    Company: 'Organovo Holdings'
  },
  {
    Ticker: 'OPB',
    Company: 'Opus Bank'
  },
  {
    Ticker: 'OPBK',
    Company: 'Open Bank'
  },
  {
    Ticker: 'OPES',
    Company: 'Opes Acquisition Corp'
  },
  {
    Ticker: 'OPESU',
    Company: 'Opes Acquisition Corp Unit'
  },
  {
    Ticker: 'OPESW',
    Company: 'Opes Acquisition Corp WT'
  },
  {
    Ticker: 'OPGN',
    Company: 'Opgen Inc'
  },
  {
    Ticker: 'OPGNW',
    Company: 'Opgen Inc WT'
  },
  {
    Ticker: 'OPHC',
    Company: 'Optimumbank Hlds'
  },
  {
    Ticker: 'OPI',
    Company: 'Office Properties Income Trust'
  },
  {
    Ticker: 'OPINI',
    Company: 'Office Properties Income Trust'
  },
  {
    Ticker: 'OPK',
    Company: 'Opko Health Inc'
  },
  {
    Ticker: 'OPNT',
    Company: 'Opiant Pharmaceuticals Inc'
  },
  {
    Ticker: 'OPOF',
    Company: 'Old Point Finl Cp'
  },
  {
    Ticker: 'OPRA',
    Company: 'Opera Ltd ADR'
  },
  {
    Ticker: 'OPRT',
    Company: 'Oportun Financial Corp'
  },
  {
    Ticker: 'OPRX',
    Company: 'Optimizerx Corp'
  },
  {
    Ticker: 'OPTN',
    Company: 'Optinose Inc'
  },
  {
    Ticker: 'OPTT',
    Company: 'Ocean Power Tech Inc'
  },
  {
    Ticker: 'ORBC',
    Company: 'Orbcomm Inc'
  },
  {
    Ticker: 'ORG',
    Company: 'Organics ETF'
  },
  {
    Ticker: 'ORGO',
    Company: 'Organogenesis Holdings Inc'
  },
  {
    Ticker: 'ORGS',
    Company: 'Orgenesis Inc'
  },
  {
    Ticker: 'ORIT',
    Company: 'Oritani Financial'
  },
  {
    Ticker: 'ORLY',
    Company: "O'Reilly Automotive"
  },
  {
    Ticker: 'ORMP',
    Company: 'Oramed Pharma'
  },
  {
    Ticker: 'ORRF',
    Company: 'Orrstown Finl Svcs'
  },
  {
    Ticker: 'ORSN',
    Company: 'Orisun Acquisition Corp'
  },
  {
    Ticker: 'ORSNR',
    Company: 'Orisun Acquisition Corp Right'
  },
  {
    Ticker: 'ORSNU',
    Company: 'Orisun Acquisition Corp Unit'
  },
  {
    Ticker: 'ORSNW',
    Company: 'Orisun Acquisition Corp WT'
  },
  {
    Ticker: 'ORTX',
    Company: 'Orchard Therapeutics Plc ADR'
  },
  {
    Ticker: 'OSBC',
    Company: 'Old Second Bancorp'
  },
  {
    Ticker: 'OSBCP',
    Company: 'Old Second Bncp'
  },
  {
    Ticker: 'OSIS',
    Company: 'O S I Systems Inc'
  },
  {
    Ticker: 'OSMT',
    Company: 'Osmotica Pharmaceuticals Plc'
  },
  {
    Ticker: 'OSN',
    Company: 'Ossen Innovation Company'
  },
  {
    Ticker: 'OSPN',
    Company: 'Onespan Inc'
  },
  {
    Ticker: 'OSS',
    Company: 'One Stop Systems Inc'
  },
  {
    Ticker: 'OSTK',
    Company: 'Overstock.com Inc'
  },
  {
    Ticker: 'OSUR',
    Company: 'Orasure Tech Inc'
  },
  {
    Ticker: 'OSW',
    Company: 'Onespaworld Holdings Ltd'
  },
  {
    Ticker: 'OTEL',
    Company: 'Otelco Cl A'
  },
  {
    Ticker: 'OTEX',
    Company: 'Open Text Cp'
  },
  {
    Ticker: 'OTIC',
    Company: 'Otonomy Inc'
  },
  {
    Ticker: 'OTLK',
    Company: 'Outlook Therapeutics Inc'
  },
  {
    Ticker: 'OTLKW',
    Company: 'Outlook Therapeutics Inc'
  },
  {
    Ticker: 'OTTR',
    Company: 'Otter Tail Corp'
  },
  {
    Ticker: 'OTTW',
    Company: 'Ottawa Saving Bcp IL'
  },
  {
    Ticker: 'OVBC',
    Company: 'Ohio Valley Banc Cp'
  },
  {
    Ticker: 'OVID',
    Company: 'Ovid Therapeutics Inc'
  },
  {
    Ticker: 'OVLY',
    Company: 'Oak Valley Bancp CA'
  },
  {
    Ticker: 'OXBR',
    Company: 'Oxbridge Ord Shrs'
  },
  {
    Ticker: 'OXBRW',
    Company: 'Oxbridge WT'
  },
  {
    Ticker: 'OXFD',
    Company: 'Oxford Immunotec'
  },
  {
    Ticker: 'OXLC',
    Company: 'Oxford Lane Capital'
  },
  {
    Ticker: 'OXLCM',
    Company: 'Oxford Lane Capital Corp Pfd Ser 2024'
  },
  {
    Ticker: 'OXLCO',
    Company: 'Oxford Lane Cap 7.5'
  },
  {
    Ticker: 'OXSQ',
    Company: 'Oxford Square Capital Corp'
  },
  {
    Ticker: 'OXSQL',
    Company: 'Oxford Square Capital Corp 6.50% Note Due 2024'
  },
  {
    Ticker: 'OXSQZ',
    Company: 'Oxford Square Capital Corp 6.25% Notes Due 2026'
  },
  {
    Ticker: 'OYST',
    Company: 'Oyster Point Pharma Inc'
  },
  {
    Ticker: 'OZK',
    Company: 'Bank Ozk'
  },
  {
    Ticker: 'PAAC',
    Company: 'Proficient Alpha Acquisition Corp'
  },
  {
    Ticker: 'PAACR',
    Company: 'Proficient Alpha Acquisition Corp Rights'
  },
  {
    Ticker: 'PAACU',
    Company: 'Proficient Alpha Acquisition Corp Unit'
  },
  {
    Ticker: 'PAACW',
    Company: 'Proficient Alpha Acquisition Corp WT'
  },
  {
    Ticker: 'PAAS',
    Company: 'Pan Amer Silver Corp'
  },
  {
    Ticker: 'PACB',
    Company: 'Pacific Biosciences'
  },
  {
    Ticker: 'PACQ',
    Company: 'Pure Acquisition Corp Cl A'
  },
  {
    Ticker: 'PACQU',
    Company: 'Pure Acquisition Corp Unit'
  },
  {
    Ticker: 'PACQW',
    Company: 'Pure Acquisition Corp WT'
  },
  {
    Ticker: 'PACW',
    Company: 'Pacwest Bancorp'
  },
  {
    Ticker: 'PAHC',
    Company: 'Phibro Anl Htlh A'
  },
  {
    Ticker: 'PANL',
    Company: 'Pangaea Logistics So'
  },
  {
    Ticker: 'PATI',
    Company: 'Patriot TR HD'
  },
  {
    Ticker: 'PATK',
    Company: 'Patrick Inds Inc'
  },
  {
    Ticker: 'PAVM',
    Company: 'Pavmed Inc'
  },
  {
    Ticker: 'PAVMW',
    Company: 'Pavmed Inc WT'
  },
  {
    Ticker: 'PAVMZ',
    Company: 'Pavmed Inc Series Z WT'
  },
  {
    Ticker: 'PAYS',
    Company: 'Paysign Inc'
  },
  {
    Ticker: 'PAYX',
    Company: 'Paychex Inc'
  },
  {
    Ticker: 'PBBI',
    Company: 'Pb Bancorp Inc'
  },
  {
    Ticker: 'PBCT',
    Company: "People's United Finl"
  },
  {
    Ticker: 'PBCTP',
    Company: 'Peoples Utd Finl Inc'
  },
  {
    Ticker: 'PBFS',
    Company: 'Pioneer Bancorp Inc'
  },
  {
    Ticker: 'PBHC',
    Company: 'Pathfinder Bcp MD'
  },
  {
    Ticker: 'PBIP',
    Company: 'Prudential Bancorp'
  },
  {
    Ticker: 'PBPB',
    Company: 'Potbelly Corp'
  },
  {
    Ticker: 'PBTS',
    Company: 'Powerbridge Technologies Co. Ltd'
  },
  {
    Ticker: 'PBYI',
    Company: 'Puma Biotechnology'
  },
  {
    Ticker: 'PCAR',
    Company: 'Paccar Inc'
  },
  {
    Ticker: 'PCB',
    Company: 'Pacific City Finl Cp'
  },
  {
    Ticker: 'PCH',
    Company: 'Potlatch Cp'
  },
  {
    Ticker: 'PCOM',
    Company: 'Points Intl Ltd'
  },
  {
    Ticker: 'PCRX',
    Company: 'Pacira Pharm Inc'
  },
  {
    Ticker: 'PCSB',
    Company: 'Pcsb Financial Corp'
  },
  {
    Ticker: 'PCTI',
    Company: 'Pc-Tel Inc'
  },
  {
    Ticker: 'PCTY',
    Company: 'Paylocity Hld Corp'
  },
  {
    Ticker: 'PCYG',
    Company: 'Park City Group'
  },
  {
    Ticker: 'PCYO',
    Company: 'Pure Cycle Corp'
  },
  {
    Ticker: 'PDBC',
    Company: 'Optimum Yield Diversified Commodity Strategy No'
  },
  {
    Ticker: 'PDCE',
    Company: 'Pdc Energy Inc'
  },
  {
    Ticker: 'PDCO',
    Company: 'Patterson Companies'
  },
  {
    Ticker: 'PDD',
    Company: 'Pinduoduo Inc ADR'
  },
  {
    Ticker: 'PDEV',
    Company: 'Principal International Multi-Factor Core Index'
  },
  {
    Ticker: 'PDEX',
    Company: 'Pro-Dex Inc'
  },
  {
    Ticker: 'PDFS',
    Company: 'Pdf Solutions Inc'
  },
  {
    Ticker: 'PDLB',
    Company: 'Pdl Community Bancorp'
  },
  {
    Ticker: 'PDLI',
    Company: 'Pdl Biopharma Inc'
  },
  {
    Ticker: 'PDP',
    Company: 'DWA Momentum Invesco ETF'
  },
  {
    Ticker: 'PDSB',
    Company: 'Pds Biotechnology Corp'
  },
  {
    Ticker: 'PEBK',
    Company: 'Peoples Bancorp NC'
  },
  {
    Ticker: 'PEBO',
    Company: 'Peoples Bancorp Inc'
  },
  {
    Ticker: 'PECK',
    Company: 'The Peck Company Inc'
  },
  {
    Ticker: 'PEGA',
    Company: 'Pegasystems Inc'
  },
  {
    Ticker: 'PEGI',
    Company: 'Pattern Energy Cl A'
  },
  {
    Ticker: 'PEIX',
    Company: 'Pacific Ethanol Inc'
  },
  {
    Ticker: 'PENN',
    Company: 'Penn Natl Gaming Inc'
  },
  {
    Ticker: 'PEP',
    Company: 'Pepsico Inc'
  },
  {
    Ticker: 'PERI',
    Company: 'Perion Network Ltd'
  },
  {
    Ticker: 'PESI',
    Company: 'Perma-Fix Envir'
  },
  {
    Ticker: 'PETQ',
    Company: 'Petiq Inc Cl A'
  },
  {
    Ticker: 'PETS',
    Company: 'Petmed Express Inc'
  },
  {
    Ticker: 'PETZ',
    Company: 'Tdh Holdings Inc'
  },
  {
    Ticker: 'PEY',
    Company: 'High Yield Equity Dividend Achievers Invesco ETF'
  },
  {
    Ticker: 'PEZ',
    Company: 'DWA Consumer Cyclicals Momentum Invesco ETF'
  },
  {
    Ticker: 'PFBC',
    Company: 'Preferred Bank LA'
  },
  {
    Ticker: 'PFBI',
    Company: 'Premier Finl Bncp'
  },
  {
    Ticker: 'PFF',
    Company: 'US Preferred Stock Ishares ETF'
  },
  {
    Ticker: 'PFG',
    Company: 'Principal Financial Group Inc'
  },
  {
    Ticker: 'PFI',
    Company: 'DWA Financial Momentum Invesco ETF'
  },
  {
    Ticker: 'PFIE',
    Company: 'Profire Energy'
  },
  {
    Ticker: 'PFIN',
    Company: 'P F Inds Inc A'
  },
  {
    Ticker: 'PFIS',
    Company: 'Peoples Finl Svcs Company'
  },
  {
    Ticker: 'PFLT',
    Company: 'Pennantpark Fltng Rt'
  },
  {
    Ticker: 'PFM',
    Company: 'Dividend Achievers Invesco ETF'
  },
  {
    Ticker: 'PFMT',
    Company: 'Performant Financial'
  },
  {
    Ticker: 'PFPT',
    Company: 'Proofpoint Inc'
  },
  {
    Ticker: 'PFSW',
    Company: 'Pfsweb Inc'
  },
  {
    Ticker: 'PGC',
    Company: 'Peapack Gladstone FI'
  },
  {
    Ticker: 'PGJ',
    Company: 'Golden Dragon China Invesco ETF'
  },
  {
    Ticker: 'PGNX',
    Company: 'Progenics Pharmaceuticals'
  },
  {
    Ticker: 'PGNY',
    Company: 'Progyny Inc'
  },
  {
    Ticker: 'PHAS',
    Company: 'Phasebio Pharmaceuticals Inc'
  },
  {
    Ticker: 'PHAT',
    Company: 'Phathom Pharmaceuticals Inc'
  },
  {
    Ticker: 'PHCF',
    Company: 'Puhui Wealth Investment Management Company'
  },
  {
    Ticker: 'PHIO',
    Company: 'Phio Pharmaceuticals Corp'
  },
  {
    Ticker: 'PHIOW',
    Company: 'Phio Pharmaceuticals Corp WT'
  },
  {
    Ticker: 'PHO',
    Company: 'Water Resources Invesco ETF'
  },
  {
    Ticker: 'PHUN',
    Company: 'Phunware Inc'
  },
  {
    Ticker: 'PHUNW',
    Company: 'Phunware Inc WT'
  },
  {
    Ticker: 'PI',
    Company: 'Impinj Inc'
  },
  {
    Ticker: 'PICO',
    Company: 'Pico Holdings Inc'
  },
  {
    Ticker: 'PID',
    Company: 'Intl Dividend Achievers Invesco ETF'
  },
  {
    Ticker: 'PIE',
    Company: 'DWA Emerging Markets Momentum Invesco ETF'
  },
  {
    Ticker: 'PIH',
    Company: '1347 Property Ins'
  },
  {
    Ticker: 'PIHPP',
    Company: '1347 Property Insurance Holdings Inc Pfd Ser A'
  },
  {
    Ticker: 'PINC',
    Company: 'Premier Inc Cl A'
  },
  {
    Ticker: 'PIO',
    Company: 'Global Water Invesco ETF'
  },
  {
    Ticker: 'PIRS',
    Company: 'Pieris Pharma'
  },
  {
    Ticker: 'PIXY',
    Company: 'Shiftpixy Inc'
  },
  {
    Ticker: 'PIZ',
    Company: 'DWA Developed Markets Momentum Invesco ETF'
  },
  {
    Ticker: 'PKBK',
    Company: 'Parke Bancorp Inc'
  },
  {
    Ticker: 'PKOH',
    Company: 'Park Ohio Hld Cp'
  },
  {
    Ticker: 'PKW',
    Company: 'Buyback Achievers Invesco ETF'
  },
  {
    Ticker: 'PLAB',
    Company: 'Photronics Inc'
  },
  {
    Ticker: 'PLAY',
    Company: "Dave & Buster's Ente"
  },
  {
    Ticker: 'PLBC',
    Company: 'Plumas Bancorp'
  },
  {
    Ticker: 'PLC',
    Company: 'Principal U.S. Large-Cap Multi-Factor Core Index'
  },
  {
    Ticker: 'PLCE',
    Company: "Children's Place Inc"
  },
  {
    Ticker: 'PLIN',
    Company: 'China Xiangtai Food Company'
  },
  {
    Ticker: 'PLL',
    Company: 'Piedmont Lithium Ltd ADR'
  },
  {
    Ticker: 'PLMR',
    Company: 'Palomar Holdings Inc'
  },
  {
    Ticker: 'PLPC',
    Company: 'Preformed Line Prd'
  },
  {
    Ticker: 'PLSE',
    Company: 'Pulse Biosciences CS'
  },
  {
    Ticker: 'PLUG',
    Company: 'Plug Power Inc'
  },
  {
    Ticker: 'PLUS',
    Company: 'Eplus Inc'
  },
  {
    Ticker: 'PLW',
    Company: '1-30 Laddered Treasury Invesco ETF'
  },
  {
    Ticker: 'PLXP',
    Company: 'Plx Pharma Inc'
  },
  {
    Ticker: 'PLXS',
    Company: 'Plexus Cp'
  },
  {
    Ticker: 'PLYA',
    Company: 'Playa Hotels & Resorts N.V.'
  },
  {
    Ticker: 'PMBC',
    Company: 'Pacific Mercantile'
  },
  {
    Ticker: 'PMD',
    Company: 'Psychemedics'
  },
  {
    Ticker: 'PME',
    Company: 'Pingtan Marine'
  },
  {
    Ticker: 'PMOM',
    Company: 'Principal Sustainable Momentum Index ETF'
  },
  {
    Ticker: 'PMTS',
    Company: 'CPI Card Group'
  },
  {
    Ticker: 'PNBK',
    Company: 'Patriot Natl Bncp'
  },
  {
    Ticker: 'PNFP',
    Company: 'Pinnacle Finl Ptnrs'
  },
  {
    Ticker: 'PNNT',
    Company: 'Pennantpark Investme'
  },
  {
    Ticker: 'PNNTG',
    Company: 'Pennantpark Investment Corp 5.50% Notes D'
  },
  {
    Ticker: 'PNQI',
    Company: 'Nasdaq Internet Invesco ETF'
  },
  {
    Ticker: 'PNRG',
    Company: 'Primeenergy Cp'
  },
  {
    Ticker: 'PNRL',
    Company: 'Paringa Resources Ltd ADR'
  },
  {
    Ticker: 'PNTG',
    Company: 'The Pennant Group Inc'
  },
  {
    Ticker: 'POAI',
    Company: 'Predictive Oncology Inc'
  },
  {
    Ticker: 'PODD',
    Company: 'Insulet Corp'
  },
  {
    Ticker: 'POLA',
    Company: 'Polar Power Inc'
  },
  {
    Ticker: 'POOL',
    Company: 'Pool Corp'
  },
  {
    Ticker: 'POPE',
    Company: 'Pope Resource Uts'
  },
  {
    Ticker: 'POTX',
    Company: 'Global X Cannabis ETF'
  },
  {
    Ticker: 'POWI',
    Company: 'Power Integratn'
  },
  {
    Ticker: 'POWL',
    Company: 'Powell Inds Inc'
  },
  {
    Ticker: 'PPBI',
    Company: 'Pacific Premier Bncp'
  },
  {
    Ticker: 'PPC',
    Company: "Pilgrim's Pride"
  },
  {
    Ticker: 'PPH',
    Company: 'Pharmaceutical Vaneck ETF'
  },
  {
    Ticker: 'PPHI',
    Company: 'Positive Physicians Holdings Inc'
  },
  {
    Ticker: 'PPIH',
    Company: 'Perma-Pipe International Holdings Inc'
  },
  {
    Ticker: 'PPSI',
    Company: 'Pioneer Pwr Sol'
  },
  {
    Ticker: 'PRAA',
    Company: 'Pra Group Inc'
  },
  {
    Ticker: 'PRAH',
    Company: 'Pra Health'
  },
  {
    Ticker: 'PRCP',
    Company: 'Perceptron Inc'
  },
  {
    Ticker: 'PRFT',
    Company: 'Perficient Inc'
  },
  {
    Ticker: 'PRFZ',
    Company: 'FTSE RAFI US 1500 Small- Mid Invesco ETF'
  },
  {
    Ticker: 'PRGS',
    Company: 'Progress Software'
  },
  {
    Ticker: 'PRGX',
    Company: 'Prgx Global Inc'
  },
  {
    Ticker: 'PRIM',
    Company: 'Primoris Services Cp'
  },
  {
    Ticker: 'PRMW',
    Company: 'Primo Water Corp'
  },
  {
    Ticker: 'PRN',
    Company: 'DWA Industrials Momentum Invesco ETF'
  },
  {
    Ticker: 'PRNB',
    Company: 'Principia Biopharma Inc'
  },
  {
    Ticker: 'PROF',
    Company: 'Profound Medical Corp'
  },
  {
    Ticker: 'PROV',
    Company: 'Provident Financial'
  },
  {
    Ticker: 'PRPH',
    Company: 'Prophase Labs Inc'
  },
  {
    Ticker: 'PRPL',
    Company: 'Purple Innovation Inc'
  },
  {
    Ticker: 'PRPO',
    Company: 'Precipio Inc'
  },
  {
    Ticker: 'PRQR',
    Company: 'Proqr Therapeutics'
  },
  {
    Ticker: 'PRSC',
    Company: 'Providence Svc Cp'
  },
  {
    Ticker: 'PRTA',
    Company: 'Prothena Corp'
  },
  {
    Ticker: 'PRTH',
    Company: 'Priority Techno Holdings Inc'
  },
  {
    Ticker: 'PRTK',
    Company: 'Paratek Pharma Inc'
  },
  {
    Ticker: 'PRTO',
    Company: 'Proteon Thera Com ST'
  },
  {
    Ticker: 'PRTS',
    Company: 'U.S. Auto Parts Netw'
  },
  {
    Ticker: 'PRVB',
    Company: 'Provention Bio Inc'
  },
  {
    Ticker: 'PRVL',
    Company: 'Prevail Therapeutics Inc'
  },
  {
    Ticker: 'PS',
    Company: 'Pluralsight Inc Cl A'
  },
  {
    Ticker: 'PSC',
    Company: 'Resources Prospect Ltd'
  },
  {
    Ticker: 'PSCC',
    Company: 'S&P Smallcap Consumer Staples Invesco ETF'
  },
  {
    Ticker: 'PSCD',
    Company: 'S&P Smallcap Consumer Discretionary Invesco ETF'
  },
  {
    Ticker: 'PSCE',
    Company: 'S&P Smallcap Energy Invesco ETF'
  },
  {
    Ticker: 'PSCF',
    Company: 'S&P Smallcap Financials Invesco ETF'
  },
  {
    Ticker: 'PSCH',
    Company: 'S&P Smallcap Health Care Invesco ETF'
  },
  {
    Ticker: 'PSCI',
    Company: 'S&P Smallcap Industrials Invesco ETF'
  },
  {
    Ticker: 'PSCM',
    Company: 'S&P Smallcap Materials Invesco ETF'
  },
  {
    Ticker: 'PSCT',
    Company: 'S&P Smallcap Information Technology Invesco ETF'
  },
  {
    Ticker: 'PSCU',
    Company: 'S&P Smallcap Utilities Invesco ETF'
  },
  {
    Ticker: 'PSDO',
    Company: 'Presidio Inc'
  },
  {
    Ticker: 'PSEC',
    Company: 'Prospect Capital Cp'
  },
  {
    Ticker: 'PSET',
    Company: 'Principal Price Setters ETF'
  },
  {
    Ticker: 'PSL',
    Company: 'DWA Consumer Staples Momentum Invesco ETF'
  },
  {
    Ticker: 'PSM',
    Company: 'Principal U.S. Small-Midcap Multi-Factor Core IN'
  },
  {
    Ticker: 'PSMT',
    Company: 'Pricesmart Inc'
  },
  {
    Ticker: 'PSNL',
    Company: 'Personalis Inc'
  },
  {
    Ticker: 'PSTI',
    Company: 'Pluristem Therapeutc'
  },
  {
    Ticker: 'PSTV',
    Company: 'Plus Therapeutics Inc'
  },
  {
    Ticker: 'PSTVZ',
    Company: 'Plus Therapeutics Inc WT'
  },
  {
    Ticker: 'PT',
    Company: 'Pintec Technology Holdings Ltd ADR'
  },
  {
    Ticker: 'PTACU',
    Company: 'Proptech Acquisition Corp'
  },
  {
    Ticker: 'PTC',
    Company: 'Ptc Inc'
  },
  {
    Ticker: 'PTCT',
    Company: 'Ptc Therapeutics'
  },
  {
    Ticker: 'PTE',
    Company: 'Polarityte Inc'
  },
  {
    Ticker: 'PTEN',
    Company: 'Patterson-Uti Energy'
  },
  {
    Ticker: 'PTF',
    Company: 'DWA Technology Momentum Invesco ETF'
  },
  {
    Ticker: 'PTGX',
    Company: 'Protagonist Therapeutics Inc'
  },
  {
    Ticker: 'PTH',
    Company: 'DWA Healthcare Momentum Invesco ETF'
  },
  {
    Ticker: 'PTI',
    Company: 'Proteostasis Ther'
  },
  {
    Ticker: 'PTLA',
    Company: 'Portola Pharma'
  },
  {
    Ticker: 'PTMN',
    Company: 'Portman Ridge Finance Corp'
  },
  {
    Ticker: 'PTNR',
    Company: 'Partner Comm Co. Ltd'
  },
  {
    Ticker: 'PTON',
    Company: 'Peloton Interactive Inc'
  },
  {
    Ticker: 'PTSI',
    Company: 'P A M Transport Sv'
  },
  {
    Ticker: 'PTVCA',
    Company: 'Protective Insurance Corp Cl A'
  },
  {
    Ticker: 'PTVCB',
    Company: 'Protective Insurance Corp Cl B'
  },
  {
    Ticker: 'PUB',
    Company: 'Peoples Utah'
  },
  {
    Ticker: 'PUI',
    Company: 'DWA Utilities Momentum Invesco ETF'
  },
  {
    Ticker: 'PULM',
    Company: 'Pulmatrix Inc'
  },
  {
    Ticker: 'PUYI',
    Company: 'Puyi Inc ADR'
  },
  {
    Ticker: 'PVAC',
    Company: 'Penn VA Corp'
  },
  {
    Ticker: 'PVAL',
    Company: 'Principal Contrarian Value Index ETF'
  },
  {
    Ticker: 'PVBC',
    Company: 'Provident Bancorp CS'
  },
  {
    Ticker: 'PWFL',
    Company: 'Powerfleet Inc'
  },
  {
    Ticker: 'PWOD',
    Company: 'Penns Woods Bancorp'
  },
  {
    Ticker: 'PXI',
    Company: 'DWA Energy Momentum Invesco ETF'
  },
  {
    Ticker: 'PXLW',
    Company: 'Pixelworks Inc'
  },
  {
    Ticker: 'PXS',
    Company: 'Pyxis Tankers'
  },
  {
    Ticker: 'PY',
    Company: 'Principal Shareholders Yield ETF'
  },
  {
    Ticker: 'PYPL',
    Company: 'Paypal Holdings'
  },
  {
    Ticker: 'PYZ',
    Company: 'DWA Basic Materials Momentum Invesco ETF'
  },
  {
    Ticker: 'PZZA',
    Company: "Papa John's Intl"
  },
  {
    Ticker: 'QABA',
    Company: 'Qtec First Trust ETF'
  },
  {
    Ticker: 'QADA',
    Company: 'Qad Inc'
  },
  {
    Ticker: 'QADB',
    Company: 'Q A D Inc Cl B'
  },
  {
    Ticker: 'QAT',
    Company: 'Qatar Ishares MSCI ETF'
  },
  {
    Ticker: 'QBAK',
    Company: 'Qualstar Corp'
  },
  {
    Ticker: 'QCLN',
    Company: 'Nasdaq Clean Edge Green Energy Index Fund FT'
  },
  {
    Ticker: 'QCOM',
    Company: 'Qualcomm Inc'
  },
  {
    Ticker: 'QCRH',
    Company: 'Qcr Holdings Inc'
  },
  {
    Ticker: 'QDEL',
    Company: 'Quidel Cp'
  },
  {
    Ticker: 'QFIN',
    Company: '360 Finance Inc ADR'
  },
  {
    Ticker: 'QIWI',
    Company: 'Qiwi Plc ADR'
  },
  {
    Ticker: 'QK',
    Company: 'Q&K International Group Limited ADR'
  },
  {
    Ticker: 'QLYS',
    Company: 'Qualys Inc'
  },
  {
    Ticker: 'QNST',
    Company: 'Quinstreet Inc'
  },
  {
    Ticker: 'QQEW',
    Company: 'Nasdaq-100 EW Index Fund FT'
  },
  {
    Ticker: 'QQQ',
    Company: 'Nasdaq QQQ Invesco ETF'
  },
  {
    Ticker: 'QQQX',
    Company: 'Nuveen Nasdaq 100'
  },
  {
    Ticker: 'QQXT',
    Company: 'Nasdaq-100 Ex-Tech Sector Indexsm Fund FT'
  },
  {
    Ticker: 'QRHC',
    Company: 'Quest Resource'
  },
  {
    Ticker: 'QRTEA',
    Company: 'Qurate Retail Group'
  },
  {
    Ticker: 'QRTEB',
    Company: 'Liberty Int Qvc Sr B'
  },
  {
    Ticker: 'QRVO',
    Company: 'Qorvo Inc'
  },
  {
    Ticker: 'QTEC',
    Company: 'Nasdaq-100 Technology Index Fund FT'
  },
  {
    Ticker: 'QTNT',
    Company: 'Quotient Ltd Ord'
  },
  {
    Ticker: 'QTRH',
    Company: 'Quarterhill Inc'
  },
  {
    Ticker: 'QTRX',
    Company: 'Quanterix Corp'
  },
  {
    Ticker: 'QTT',
    Company: 'Qutoutiao Inc ADR'
  },
  {
    Ticker: 'QUIK',
    Company: 'Quicklogic Corp'
  },
  {
    Ticker: 'QUMU',
    Company: 'Qumu Corp'
  },
  {
    Ticker: 'QURE',
    Company: 'Uniqure N.V.'
  },
  {
    Ticker: 'QYLD',
    Company: 'Horizons Nasdaq-100 Covered Call ETF'
  },
  {
    Ticker: 'RADA',
    Company: 'Rada Electr Inds Ltd'
  },
  {
    Ticker: 'RAIL',
    Company: 'Freightcar America'
  },
  {
    Ticker: 'RAND',
    Company: 'Rand Capital Cp'
  },
  {
    Ticker: 'RAPT',
    Company: 'Rapt Therapeutics Inc'
  },
  {
    Ticker: 'RARE',
    Company: 'Ultragenyx Pharmaceu'
  },
  {
    Ticker: 'RARX',
    Company: 'Ra Pharmaceuticals Inc'
  },
  {
    Ticker: 'RAVE',
    Company: 'Rave Restaurant Group'
  },
  {
    Ticker: 'RAVN',
    Company: 'Raven Industries Inc'
  },
  {
    Ticker: 'RBB',
    Company: 'Rbb Bancorp'
  },
  {
    Ticker: 'RBBN',
    Company: 'Ribbon Communications Inc'
  },
  {
    Ticker: 'RBCAA',
    Company: 'Republic Bancorp Inc'
  },
  {
    Ticker: 'RBCN',
    Company: 'Rubicon Technology'
  },
  {
    Ticker: 'RBKB',
    Company: 'Rhinebeck Bancorp Inc'
  },
  {
    Ticker: 'RBNC',
    Company: 'Reliant Bancorp Inc'
  },
  {
    Ticker: 'RBZ',
    Company: 'Reebonz Holding Ltd'
  },
  {
    Ticker: 'RCEL',
    Company: 'Avita Medical Limited American Depositary Shares'
  },
  {
    Ticker: 'RCII',
    Company: 'Rent-A-Center Inc'
  },
  {
    Ticker: 'RCKT',
    Company: 'Rocket Pharmaceuticals Inc'
  },
  {
    Ticker: 'RCKY',
    Company: 'Rocky Brands Inc'
  },
  {
    Ticker: 'RCM',
    Company: 'R1 Rcm Inc'
  },
  {
    Ticker: 'RCMT',
    Company: 'R C M Tech Inc'
  },
  {
    Ticker: 'RCON',
    Company: 'Recon Technology Ltd'
  },
  {
    Ticker: 'RDCM',
    Company: 'Radcom Ltd'
  },
  {
    Ticker: 'RDFN',
    Company: 'Redfin Corp'
  },
  {
    Ticker: 'RDHL',
    Company: 'Redhill Biophrma ADR'
  },
  {
    Ticker: 'RDI',
    Company: 'Reading Intl Cl A'
  },
  {
    Ticker: 'RDNT',
    Company: 'Radnet Inc'
  },
  {
    Ticker: 'RDUS',
    Company: 'Radius Health'
  },
  {
    Ticker: 'RDVT',
    Company: 'Red Violet Inc'
  },
  {
    Ticker: 'RDVY',
    Company: 'Rising Dividend Achievers ETF FT'
  },
  {
    Ticker: 'RDWR',
    Company: 'Radware Ltd'
  },
  {
    Ticker: 'REAL',
    Company: 'The Realreal Inc'
  },
  {
    Ticker: 'RECN',
    Company: 'Resources Connect'
  },
  {
    Ticker: 'REDU',
    Company: 'Rise Education Cayman Ltd ADR'
  },
  {
    Ticker: 'REED',
    Company: 'Reeds Inc'
  },
  {
    Ticker: 'REFR',
    Company: 'Research Frontiers'
  },
  {
    Ticker: 'REG',
    Company: 'Regency Centers Corp'
  },
  {
    Ticker: 'REGI',
    Company: 'Renewable Energy'
  },
  {
    Ticker: 'REGN',
    Company: 'Regeneron Pharmaceuticals'
  },
  {
    Ticker: 'REKR',
    Company: 'Rekor Systems Inc'
  },
  {
    Ticker: 'RELL',
    Company: 'Richardson Electrncs'
  },
  {
    Ticker: 'RELV',
    Company: 'Reliv Intl Inc'
  },
  {
    Ticker: 'REPH',
    Company: 'Recro Pharma Inc'
  },
  {
    Ticker: 'REPHV',
    Company: 'Recro Pharma Inc'
  },
  {
    Ticker: 'REPL',
    Company: 'Replimune Group Inc'
  },
  {
    Ticker: 'RESN',
    Company: 'Resonant Inc'
  },
  {
    Ticker: 'RETA',
    Company: 'Reata Pharma'
  },
  {
    Ticker: 'RETO',
    Company: 'Reto Eco-Solutions Inc'
  },
  {
    Ticker: 'REXN',
    Company: 'Rexahn Pharmaceuticals'
  },
  {
    Ticker: 'RFAP',
    Company: 'Riverfront Dyn Asia Pacific ETF FT'
  },
  {
    Ticker: 'RFDI',
    Company: 'Riverfront Dyn Developed Intl ETF FT'
  },
  {
    Ticker: 'RFEM',
    Company: 'Riverfront Dyn Emerging Markets ETF FT'
  },
  {
    Ticker: 'RFEU',
    Company: 'Riverfront Dyn Europe ETF FT'
  },
  {
    Ticker: 'RFIL',
    Company: 'RF Industries Ltd'
  },
  {
    Ticker: 'RGCO',
    Company: 'Rgc Resources Inc'
  },
  {
    Ticker: 'RGEN',
    Company: 'Repligen Cp'
  },
  {
    Ticker: 'RGLD',
    Company: 'Royal Gold Inc'
  },
  {
    Ticker: 'RGLS',
    Company: 'Regulus Therapeutics'
  },
  {
    Ticker: 'RGNX',
    Company: 'Regenxbio Inc'
  },
  {
    Ticker: 'RIBT',
    Company: 'Ricebran Technologie'
  },
  {
    Ticker: 'RICK',
    Company: 'RCI Hospitality Hold'
  },
  {
    Ticker: 'RIGL',
    Company: 'Rigel Pharmaceuticals'
  },
  {
    Ticker: 'RILY',
    Company: 'B. Riley Financl'
  },
  {
    Ticker: 'RILYG',
    Company: 'B. Riley Financial Inc 7.25% Senior Notes Due'
  },
  {
    Ticker: 'RILYH',
    Company: 'B. Riley Financial Inc 7.375% Senior Notes Due'
  },
  {
    Ticker: 'RILYI',
    Company: 'B. Riley Financial Inc 6.875% Senior Notes Due'
  },
  {
    Ticker: 'RILYL',
    Company: 'B. Riley Financial Inc'
  },
  {
    Ticker: 'RILYN',
    Company: 'B. Riley Financial Inc 6.50% Senior Notes Due 2'
  },
  {
    Ticker: 'RILYO',
    Company: 'B. Riley Financial Inc 6.75% Senior Notes Due'
  },
  {
    Ticker: 'RILYP',
    Company: 'B. Riley Financial Inc'
  },
  {
    Ticker: 'RILYZ',
    Company: 'B. Riley Financial Inc 7.50% Senior Notes Due'
  },
  {
    Ticker: 'RING',
    Company: 'Global Gold Miners Ishares MSCI ETF'
  },
  {
    Ticker: 'RIOT',
    Company: 'Riot Blockchain Inc'
  },
  {
    Ticker: 'RIVE',
    Company: 'Riverview Financial Corp'
  },
  {
    Ticker: 'RKDA',
    Company: 'Arcadia Bio'
  },
  {
    Ticker: 'RLMD',
    Company: 'Relmada Therapeutics Inc'
  },
  {
    Ticker: 'RMBI',
    Company: 'Richmond Mutual Bancorp Inc'
  },
  {
    Ticker: 'RMBL',
    Company: 'Smart Server Inc'
  },
  {
    Ticker: 'RMBS',
    Company: 'Rambus Inc'
  },
  {
    Ticker: 'RMCF',
    Company: 'Rocky MT Chocolate'
  },
  {
    Ticker: 'RMNI',
    Company: 'Rimini Street Inc'
  },
  {
    Ticker: 'RMR',
    Company: 'The Rmr Grp A'
  },
  {
    Ticker: 'RMTI',
    Company: 'Rockwell Medical IN'
  },
  {
    Ticker: 'RNDB',
    Company: 'Randolph Bancorp Inc'
  },
  {
    Ticker: 'RNDM',
    Company: 'First Trust Exchange-Traded Fund Vi First Trust'
  },
  {
    Ticker: 'RNDV',
    Company: 'First Trust Exchange-Traded Fund Vi First Trust'
  },
  {
    Ticker: 'RNEM',
    Company: 'First Trust Exchange-Traded Fund Vi First Trust'
  },
  {
    Ticker: 'RNET',
    Company: 'Rignet Inc'
  },
  {
    Ticker: 'RNLC',
    Company: 'First Trust Exchange-Traded Fund Vi First Trust'
  },
  {
    Ticker: 'RNMC',
    Company: 'First Trust Exchange-Traded Fund Vi Mid Cap US E'
  },
  {
    Ticker: 'RNSC',
    Company: 'First Trust Exchange-Traded Fund Vi First Trust'
  },
  {
    Ticker: 'RNST',
    Company: 'Renasant Corp'
  },
  {
    Ticker: 'RNWK',
    Company: 'Realnetworks Inc'
  },
  {
    Ticker: 'ROAD',
    Company: 'Construction Partners Inc Cl A'
  },
  {
    Ticker: 'ROBT',
    Company: 'FT Nasdaq Artificial Intelligence and Robotics E'
  },
  {
    Ticker: 'ROCK',
    Company: 'Gibraltar Ind Inc'
  },
  {
    Ticker: 'ROIC',
    Company: 'Retail Opp Invts Cp'
  },
  {
    Ticker: 'ROKU',
    Company: 'Roku Inc'
  },
  {
    Ticker: 'ROLL',
    Company: 'RBC Bearings Incorp'
  },
  {
    Ticker: 'ROSE',
    Company: 'Rosehill Resources Inc'
  },
  {
    Ticker: 'ROSEU',
    Company: 'Rosehill Resources Inc Unit'
  },
  {
    Ticker: 'ROSEW',
    Company: 'Rosehill Resources Inc'
  },
  {
    Ticker: 'ROST',
    Company: 'Ross Stores Inc'
  },
  {
    Ticker: 'RP',
    Company: 'Realpage Inc'
  },
  {
    Ticker: 'RPAY',
    Company: 'Repay Holdings Corp Cl A'
  },
  {
    Ticker: 'RPD',
    Company: 'Rapid7 Inc'
  },
  {
    Ticker: 'RRBI',
    Company: 'Red River Bancshares Inc'
  },
  {
    Ticker: 'RRGB',
    Company: 'Red Robin Gourmet Burgers Inc'
  },
  {
    Ticker: 'RRR',
    Company: 'Red Rock Resorts Inc'
  },
  {
    Ticker: 'RTH',
    Company: 'Retail ETF Vaneck'
  },
  {
    Ticker: 'RTIX',
    Company: 'Rti Surgical Inc'
  },
  {
    Ticker: 'RTLR',
    Company: 'Rattler Midstream LP Common Units'
  },
  {
    Ticker: 'RTRX',
    Company: 'Retrophin Inc'
  },
  {
    Ticker: 'RTTR',
    Company: 'Ritter Pharmac. Com'
  },
  {
    Ticker: 'RUBY',
    Company: 'Rubius Therapeutics Inc'
  },
  {
    Ticker: 'RUHN',
    Company: 'Ruhnn Holding Ltd ADR'
  },
  {
    Ticker: 'RUN',
    Company: 'Sunrun Inc'
  },
  {
    Ticker: 'RUSHA',
    Company: 'Rush Entpr Cl A'
  },
  {
    Ticker: 'RUSHB',
    Company: 'Rush Entpr Cl B'
  },
  {
    Ticker: 'RUTH',
    Company: "Ruth's Hospitality"
  },
  {
    Ticker: 'RVNC',
    Company: 'Revance Therapeutics'
  },
  {
    Ticker: 'RVSB',
    Company: 'Riverview Bancorp'
  },
  {
    Ticker: 'RWLK',
    Company: 'Rewalk Roboti Ord Sh'
  },
  {
    Ticker: 'RYAAY',
    Company: 'Ryanair Hlds Plc'
  },
  {
    Ticker: 'RYTM',
    Company: 'Rhythm Pharmaceuticals Inc'
  },
  {
    Ticker: 'SABR',
    Company: 'Sabre Corp'
  },
  {
    Ticker: 'SAEX',
    Company: 'Saexploration Hlds'
  },
  {
    Ticker: 'SAFM',
    Company: 'Sanderson Farms IN'
  },
  {
    Ticker: 'SAFT',
    Company: 'Safety Ins Group Inc'
  },
  {
    Ticker: 'SAGE',
    Company: 'Sage Therapeutic Com'
  },
  {
    Ticker: 'SAIA',
    Company: 'Saia Inc'
  },
  {
    Ticker: 'SAL',
    Company: 'Salisbury Bancorp'
  },
  {
    Ticker: 'SALM',
    Company: 'Salem Media Grp Inc'
  },
  {
    Ticker: 'SAMA',
    Company: 'Schultze Special Purpose Acquisition Corp'
  },
  {
    Ticker: 'SAMAU',
    Company: 'Schultze Special Purpose Acquisition Corp Unit'
  },
  {
    Ticker: 'SAMAW',
    Company: 'Schultze Special Purpose Acquisition Corp WT'
  },
  {
    Ticker: 'SAMG',
    Company: 'Silvercrest Ast Cm A'
  },
  {
    Ticker: 'SANM',
    Company: 'Sanmina Corp'
  },
  {
    Ticker: 'SANW',
    Company: 'S&W Seed Company'
  },
  {
    Ticker: 'SAQNU',
    Company: 'Software Acquisition Group Inc'
  },
  {
    Ticker: 'SASR',
    Company: 'Sandy Spring Bancorp'
  },
  {
    Ticker: 'SATS',
    Company: 'Echostar Corp'
  },
  {
    Ticker: 'SAUC',
    Company: 'Diversified Rstrt HD'
  },
  {
    Ticker: 'SAVA',
    Company: 'Cassava Sciences Inc'
  },
  {
    Ticker: 'SBAC',
    Company: 'SBA Communications'
  },
  {
    Ticker: 'SBBP',
    Company: 'Strongbridge Bio Ord'
  },
  {
    Ticker: 'SBBX',
    Company: 'Sussex Bancorp'
  },
  {
    Ticker: 'SBCF',
    Company: 'Seacoast Banking Cp'
  },
  {
    Ticker: 'SBFG',
    Company: 'Sb Financial Group'
  },
  {
    Ticker: 'SBFGP',
    Company: 'Sb Fin Grp Dep Shs'
  },
  {
    Ticker: 'SBGI',
    Company: 'Sinclair Brdcst A'
  },
  {
    Ticker: 'SBLK',
    Company: 'Star Bulk Carriers'
  },
  {
    Ticker: 'SBLKZ',
    Company: 'Star Bulk Carriers Corp 8.30% Senior Notes Due'
  },
  {
    Ticker: 'SBNY',
    Company: 'Signature Bank'
  },
  {
    Ticker: 'SBPH',
    Company: 'Spring Bank Ph CS'
  },
  {
    Ticker: 'SBRA',
    Company: 'Sabra REIT'
  },
  {
    Ticker: 'SBSI',
    Company: 'Southside Bancshares'
  },
  {
    Ticker: 'SBT',
    Company: 'Sterling Bancorp Inc [Southfield Mi]'
  },
  {
    Ticker: 'SBUX',
    Company: 'Starbucks Corp'
  },
  {
    Ticker: 'SCHL',
    Company: 'Scholastic Cp'
  },
  {
    Ticker: 'SCHN',
    Company: 'Schnitzer Steel A'
  },
  {
    Ticker: 'SCKT',
    Company: 'Socket Mobile'
  },
  {
    Ticker: 'SCON',
    Company: 'Superconductor Tech'
  },
  {
    Ticker: 'SCOR',
    Company: 'Comscore Inc'
  },
  {
    Ticker: 'SCPH',
    Company: 'SC Pharmaceuticals Inc'
  },
  {
    Ticker: 'SCPL',
    Company: 'Sciplay Corp Cl A'
  },
  {
    Ticker: 'SCSC',
    Company: 'Scansource Inc'
  },
  {
    Ticker: 'SCVL',
    Company: 'Shoe Carnival Inc'
  },
  {
    Ticker: 'SCWX',
    Company: 'Secureworks Corp CS'
  },
  {
    Ticker: 'SCYX',
    Company: 'Scynexis Inc'
  },
  {
    Ticker: 'SCZ',
    Company: 'EAFE Small-Cap Ishares MSCI ETF'
  },
  {
    Ticker: 'SDC',
    Company: 'Smiledirectclub Inc'
  },
  {
    Ticker: 'SDG',
    Company: 'Ishares MSCI Global Impact ETF'
  },
  {
    Ticker: 'SDVY',
    Company: 'FT Smid Cap Rising Dividend Achievers ETF'
  },
  {
    Ticker: 'SEAC',
    Company: 'Sea Change Intl IN'
  },
  {
    Ticker: 'SECO',
    Company: 'Secoo Holding ADR'
  },
  {
    Ticker: 'SEDG',
    Company: 'Solaredge Tech'
  },
  {
    Ticker: 'SEED',
    Company: 'Origin Agritech Ltd'
  },
  {
    Ticker: 'SEEL',
    Company: 'Seelos Therapeutics Inc'
  },
  {
    Ticker: 'SEIC',
    Company: 'Sei Investments Company'
  },
  {
    Ticker: 'SELB',
    Company: 'Selecta Biosciences'
  },
  {
    Ticker: 'SELF',
    Company: 'Global Self Storage'
  },
  {
    Ticker: 'SENEA',
    Company: 'Seneca Foods Cp A'
  },
  {
    Ticker: 'SENEB',
    Company: 'Seneca Foods Cp B'
  },
  {
    Ticker: 'SES',
    Company: 'Synthesis Energy Sys'
  },
  {
    Ticker: 'SESN',
    Company: 'Sesen Bio Inc'
  },
  {
    Ticker: 'SFBC',
    Company: 'Sound Financial'
  },
  {
    Ticker: 'SFBS',
    Company: 'Servisfirst Bancs'
  },
  {
    Ticker: 'SFET',
    Company: 'Safe-T Group Ltd ADR'
  },
  {
    Ticker: 'SFIX',
    Company: 'Stitch Fix Inc'
  },
  {
    Ticker: 'SFM',
    Company: 'Sprouts Farmers Mark'
  },
  {
    Ticker: 'SFNC',
    Company: 'Simmons First Natl'
  },
  {
    Ticker: 'SFST',
    Company: 'Southern First Bancs'
  },
  {
    Ticker: 'SG',
    Company: 'Sirius International Insurance Group'
  },
  {
    Ticker: 'SGA',
    Company: 'Saga Communications'
  },
  {
    Ticker: 'SGBX',
    Company: 'Sg Blocks Inc'
  },
  {
    Ticker: 'SGC',
    Company: 'Superior Uniform Group'
  },
  {
    Ticker: 'SGEN',
    Company: 'Seattle Genetics Inc'
  },
  {
    Ticker: 'SGH',
    Company: 'Smart Global Holdings Inc'
  },
  {
    Ticker: 'SGLB',
    Company: 'Sigma Labs Inc'
  },
  {
    Ticker: 'SGLBW',
    Company: 'Sigma Labs Inc WT'
  },
  {
    Ticker: 'SGMA',
    Company: 'Sigmatron Intl Inc'
  },
  {
    Ticker: 'SGMO',
    Company: 'Sangamo Therapeutics'
  },
  {
    Ticker: 'SGMS',
    Company: 'Scientific Games'
  },
  {
    Ticker: 'SGOC',
    Company: 'Sgoco Group Ltd'
  },
  {
    Ticker: 'SGRP',
    Company: 'Spar Group Inc'
  },
  {
    Ticker: 'SGRY',
    Company: 'Surgery Partners CS'
  },
  {
    Ticker: 'SHBI',
    Company: 'Shore Bancshares Inc'
  },
  {
    Ticker: 'SHEN',
    Company: 'Shenandoah Telecom'
  },
  {
    Ticker: 'SHIP',
    Company: 'Seanergy Maritime Hl'
  },
  {
    Ticker: 'SHIPW',
    Company: 'Seanergy Maritime Holdings Corp Cl A WT'
  },
  {
    Ticker: 'SHIPZ',
    Company: 'Seanergy Maritime Holdings Corp Cl B WT'
  },
  {
    Ticker: 'SHLO',
    Company: 'Shiloh Inds Inc'
  },
  {
    Ticker: 'SHOO',
    Company: 'Steven Maddens Ltd'
  },
  {
    Ticker: 'SHSP',
    Company: 'Sharpspring Inc'
  },
  {
    Ticker: 'SHV',
    Company: 'Short Treasury Bond Ishares ETF'
  },
  {
    Ticker: 'SHY',
    Company: '1-3 Year Treasury Bond Ishares ETF'
  },
  {
    Ticker: 'SIBN',
    Company: 'Si-Bone Inc'
  },
  {
    Ticker: 'SIC',
    Company: 'Select Interior Concepts Inc Cl A'
  },
  {
    Ticker: 'SIEB',
    Company: 'Siebert Fin Cp'
  },
  {
    Ticker: 'SIEN',
    Company: 'Sientra Inc'
  },
  {
    Ticker: 'SIFY',
    Company: 'Sify Techs Ltd Ads'
  },
  {
    Ticker: 'SIGA',
    Company: 'Siga Technologies Inc'
  },
  {
    Ticker: 'SIGI',
    Company: 'Selective Ins Group'
  },
  {
    Ticker: 'SILC',
    Company: 'Silicom Ltd'
  },
  {
    Ticker: 'SILK',
    Company: 'Silk Road Medical Inc'
  },
  {
    Ticker: 'SIMO',
    Company: 'Silicon Motion Techn'
  },
  {
    Ticker: 'SINA',
    Company: 'Sina Corp'
  },
  {
    Ticker: 'SINO',
    Company: 'Sino-Global Shipping'
  },
  {
    Ticker: 'SINT',
    Company: 'Sintx Technologies Inc'
  },
  {
    Ticker: 'SIRI',
    Company: 'Sirius XM Holdings'
  },
  {
    Ticker: 'SITM',
    Company: 'Sitime Corp'
  },
  {
    Ticker: 'SITO',
    Company: 'Sito Mobile'
  },
  {
    Ticker: 'SIVB',
    Company: 'Svb Financial Group'
  },
  {
    Ticker: 'SIVBP',
    Company: 'Svb Cap I 8.25% Pfd'
  },
  {
    Ticker: 'SKOR',
    Company: 'Flexshares Credit Scored US Corp Bond'
  },
  {
    Ticker: 'SKYS',
    Company: 'Sky Solar Holdings'
  },
  {
    Ticker: 'SKYW',
    Company: 'Skywest Inc'
  },
  {
    Ticker: 'SKYY',
    Company: 'Cloud Computing ETF FT'
  },
  {
    Ticker: 'SLAB',
    Company: 'Silicon Labs Inc'
  },
  {
    Ticker: 'SLCT',
    Company: 'Select Bancorp Inc'
  },
  {
    Ticker: 'SLDB',
    Company: 'Solid Biosciences Inc'
  },
  {
    Ticker: 'SLGG',
    Company: 'Super League Gaming Inc'
  },
  {
    Ticker: 'SLGL',
    Company: 'Sol-Gel Technologies Ltd'
  },
  {
    Ticker: 'SLGN',
    Company: 'Silgan Holdings'
  },
  {
    Ticker: 'SLIM',
    Company: 'Obesity ETF'
  },
  {
    Ticker: 'SLM',
    Company: 'SLM Corp'
  },
  {
    Ticker: 'SLMBP',
    Company: 'SLM Cp Srs B Pfd'
  },
  {
    Ticker: 'SLNO',
    Company: 'Soleno Therapeutics Inc'
  },
  {
    Ticker: 'SLP',
    Company: 'Simulations Plus Inc'
  },
  {
    Ticker: 'SLQD',
    Company: '0-5 Year Invst Grade Corp Bond Ishares ETF'
  },
  {
    Ticker: 'SLRC',
    Company: 'Solar Capital'
  },
  {
    Ticker: 'SLRX',
    Company: 'Salarius Pharmaceuticals Inc'
  },
  {
    Ticker: 'SLS',
    Company: 'Sellas Life Sciences Group Inc'
  },
  {
    Ticker: 'SLVO',
    Company: 'CS X-Links Silver Share'
  },
  {
    Ticker: 'SMBC',
    Company: 'Southern MO Bancorp'
  },
  {
    Ticker: 'SMBK',
    Company: 'Smartfinancial Inc'
  },
  {
    Ticker: 'SMCP',
    Company: 'Alphamark Actmg Smallcap ETF'
  },
  {
    Ticker: 'SMED',
    Company: 'Sharps Compliance'
  },
  {
    Ticker: 'SMH',
    Company: 'Semiconductor Vaneck ETF'
  },
  {
    Ticker: 'SMIT',
    Company: 'Schmitt Inds Inc'
  },
  {
    Ticker: 'SMMC',
    Company: 'South Mountain Merger Corp Cl A'
  },
  {
    Ticker: 'SMMCU',
    Company: 'South Mountain Merger Corp Unit'
  },
  {
    Ticker: 'SMMCW',
    Company: 'South Mountain Merger Corp WT'
  },
  {
    Ticker: 'SMMF',
    Company: 'Summit Financial Gp'
  },
  {
    Ticker: 'SMMT',
    Company: 'Summit Therapeu ADR'
  },
  {
    Ticker: 'SMPL',
    Company: 'The Simply Good Foods Company'
  },
  {
    Ticker: 'SMRT',
    Company: 'Stein Mart Inc'
  },
  {
    Ticker: 'SMSI',
    Company: 'Smith Micro Software'
  },
  {
    Ticker: 'SMTC',
    Company: 'Semtech Corp'
  },
  {
    Ticker: 'SMTX',
    Company: 'Smtc Corp'
  },
  {
    Ticker: 'SNBR',
    Company: 'Sleep Number Corp'
  },
  {
    Ticker: 'SNCA',
    Company: 'Seneca Biopharm Inc.'
  },
  {
    Ticker: 'SNCR',
    Company: 'Synchronoss Technolo'
  },
  {
    Ticker: 'SND',
    Company: 'Smart Sand Inc'
  },
  {
    Ticker: 'SNDE',
    Company: 'Sundance Energy Australia Ltd'
  },
  {
    Ticker: 'SNDL',
    Company: 'Sundial Growers Inc'
  },
  {
    Ticker: 'SNDX',
    Company: 'Syndax Pharma'
  },
  {
    Ticker: 'SNES',
    Company: 'Senestech Inc'
  },
  {
    Ticker: 'SNFCA',
    Company: 'Security Natl Finl'
  },
  {
    Ticker: 'SNGX',
    Company: 'Soligenix Inc'
  },
  {
    Ticker: 'SNGXW',
    Company: 'Soligenix Inc WT'
  },
  {
    Ticker: 'SNH',
    Company: 'Senior Housing Properties Trust'
  },
  {
    Ticker: 'SNHNI',
    Company: 'Senior Housing Properties Trust'
  },
  {
    Ticker: 'SNHNL',
    Company: 'Senior Housing Properties Trust'
  },
  {
    Ticker: 'SNLN',
    Company: 'Pyxis/Iboxx Senior Loan ETF'
  },
  {
    Ticker: 'SNNA',
    Company: 'Sienna Biopharmaceuticals Inc'
  },
  {
    Ticker: 'SNOA',
    Company: 'Sonoma Pharmaceuticals Inc'
  },
  {
    Ticker: 'SNOAW',
    Company: 'Sonoma Pharmaceuticals Inc'
  },
  {
    Ticker: 'SNPS',
    Company: 'Synopsys Inc'
  },
  {
    Ticker: 'SNSR',
    Company: 'G-X Internet of Things Thematic ETF'
  },
  {
    Ticker: 'SNSS',
    Company: 'Sunesis Pharmaceutic'
  },
  {
    Ticker: 'SNY',
    Company: 'Sanofi-Aventis S.A.'
  },
  {
    Ticker: 'SOCL',
    Company: 'G-X Social Media ETF'
  },
  {
    Ticker: 'SOHO',
    Company: 'Sotherly Hotels'
  },
  {
    Ticker: 'SOHOB',
    Company: 'Sotherly Hotels Inc 8.0% Series B Cumulative Re'
  },
  {
    Ticker: 'SOHON',
    Company: 'Sotherly Hotels Inc Perp Pfd Ser D'
  },
  {
    Ticker: 'SOHOO',
    Company: 'Sotherly Hotels LP'
  },
  {
    Ticker: 'SOHU',
    Company: 'Sohu.com Inc'
  },
  {
    Ticker: 'SOLO',
    Company: 'Electrameccanica Vehs Corp'
  },
  {
    Ticker: 'SOLOW',
    Company: 'Electrameccanica Vehicles Corp Ltd WT'
  },
  {
    Ticker: 'SOLY',
    Company: 'Soliton Inc'
  },
  {
    Ticker: 'SONA',
    Company: 'Southern National Bk'
  },
  {
    Ticker: 'SONG',
    Company: 'Akazoo S.A.'
  },
  {
    Ticker: 'SONGW',
    Company: 'Akazoo S.A. WT'
  },
  {
    Ticker: 'SONM',
    Company: 'Sonim Technologies Inc'
  },
  {
    Ticker: 'SONO',
    Company: 'Sonos Inc'
  },
  {
    Ticker: 'SORL',
    Company: 'Sorl Auto Parts Inc'
  },
  {
    Ticker: 'SOXX',
    Company: 'PHLX Semiconductor Ishares ETF'
  },
  {
    Ticker: 'SP',
    Company: 'SP Plus Corp'
  },
  {
    Ticker: 'SPAR',
    Company: 'Spartan Motors Inc'
  },
  {
    Ticker: 'SPCB',
    Company: 'Supercom Ltd'
  },
  {
    Ticker: 'SPEX',
    Company: 'Spherix Inc'
  },
  {
    Ticker: 'SPFI',
    Company: 'South Plains Financial Inc'
  },
  {
    Ticker: 'SPHS',
    Company: 'Sophiris Bio'
  },
  {
    Ticker: 'SPI',
    Company: 'Spi Energy Co. Ads'
  },
  {
    Ticker: 'SPKE',
    Company: 'Spark Energy'
  },
  {
    Ticker: 'SPKEP',
    Company: 'Spark Energy Inc'
  },
  {
    Ticker: 'SPLK',
    Company: 'Splunk Inc'
  },
  {
    Ticker: 'SPNE',
    Company: 'Seaspine Holdings'
  },
  {
    Ticker: 'SPNS',
    Company: 'Sapiens Intl Cp NV'
  },
  {
    Ticker: 'SPOK',
    Company: 'Spok Holdings Inc'
  },
  {
    Ticker: 'SPPI',
    Company: 'Spectrum Pharma Inc'
  },
  {
    Ticker: 'SPRO',
    Company: 'Spero Therapeutics Inc'
  },
  {
    Ticker: 'SPRT',
    Company: 'Support.com Inc'
  },
  {
    Ticker: 'SPSC',
    Company: 'Sps Commerce Inc'
  },
  {
    Ticker: 'SPTN',
    Company: 'Spartannash Company'
  },
  {
    Ticker: 'SPWH',
    Company: "Sportsman's Wareh"
  },
  {
    Ticker: 'SPWR',
    Company: 'Sunpower Corp'
  },
  {
    Ticker: 'SQBG',
    Company: 'Sequential Brands'
  },
  {
    Ticker: 'SQLV',
    Company: 'Legg Small-Cap Quality Value ETF'
  },
  {
    Ticker: 'SQQQ',
    Company: 'Ultrapro Short QQQ Proshares'
  },
  {
    Ticker: 'SRACU',
    Company: 'Stable Road Acquisition Corp'
  },
  {
    Ticker: 'SRAX',
    Company: 'Social Reality Inc'
  },
  {
    Ticker: 'SRCE',
    Company: '1st Source Corp'
  },
  {
    Ticker: 'SRCL',
    Company: 'Stericycle Inc'
  },
  {
    Ticker: 'SRDX',
    Company: 'Surmodics Inc'
  },
  {
    Ticker: 'SRET',
    Company: 'G-X Super Dividend REIT ETF'
  },
  {
    Ticker: 'SREV',
    Company: 'Servicesrce Intl Inc'
  },
  {
    Ticker: 'SRNE',
    Company: 'Sorrento Therpt'
  },
  {
    Ticker: 'SRPT',
    Company: 'Sarepta Therapeutics'
  },
  {
    Ticker: 'SRRA',
    Company: 'Sierra Oncology Inc'
  },
  {
    Ticker: 'SRRK',
    Company: 'Scholar Rock Holding Corp'
  },
  {
    Ticker: 'SRTS',
    Company: 'Sensus Healthcare Inc'
  },
  {
    Ticker: 'SRTSW',
    Company: 'Sensus Healthcare Inc WT'
  },
  {
    Ticker: 'SSB',
    Company: 'South State Cp'
  },
  {
    Ticker: 'SSBI',
    Company: 'Summit State Bank'
  },
  {
    Ticker: 'SSKN',
    Company: 'Strata Skin Scien'
  },
  {
    Ticker: 'SSNC',
    Company: 'SS&C Technologies'
  },
  {
    Ticker: 'SSNT',
    Company: 'Silversun Technologies Inc Cl A'
  },
  {
    Ticker: 'SSP',
    Company: 'E.W. Scripps Company'
  },
  {
    Ticker: 'SSPK',
    Company: 'Silver Spike Acquisition Corp Cl A'
  },
  {
    Ticker: 'SSPKU',
    Company: 'Silver Spike Acquisition Corp Units'
  },
  {
    Ticker: 'SSPKW',
    Company: 'Silver Spike Acquisition Corp WT'
  },
  {
    Ticker: 'SSRM',
    Company: 'Silver Standard Resources Inc'
  },
  {
    Ticker: 'SSSS',
    Company: 'Sutter Rock Capital Corp'
  },
  {
    Ticker: 'SSTI',
    Company: 'Shotspotter Inc'
  },
  {
    Ticker: 'SSYS',
    Company: 'Stratasys Ltd'
  },
  {
    Ticker: 'STAA',
    Company: 'Staar Surgical Company'
  },
  {
    Ticker: 'STAF',
    Company: 'Staffing 360 Solutions Inc'
  },
  {
    Ticker: 'STAY',
    Company: 'Extended Stay America Inc'
  },
  {
    Ticker: 'STBA',
    Company: 'S&T Bancorp Inc'
  },
  {
    Ticker: 'STCN',
    Company: 'Steel Connect Inc'
  },
  {
    Ticker: 'STFC',
    Company: 'State Auto Finl Corp'
  },
  {
    Ticker: 'STIM',
    Company: 'Neuronetics Inc'
  },
  {
    Ticker: 'STKL',
    Company: 'Sunopta Inc'
  },
  {
    Ticker: 'STKS',
    Company: 'The One Group'
  },
  {
    Ticker: 'STLD',
    Company: 'Steel Dynamics Inc'
  },
  {
    Ticker: 'STML',
    Company: 'Stemline Therapeutic'
  },
  {
    Ticker: 'STMP',
    Company: 'Stamps.com Inc'
  },
  {
    Ticker: 'STND',
    Company: 'Standard Financial'
  },
  {
    Ticker: 'STNE',
    Company: 'Stoneco Ltd Cl A'
  },
  {
    Ticker: 'STOK',
    Company: 'Stoke Therapeutics Inc'
  },
  {
    Ticker: 'STRA',
    Company: 'Strayer Education'
  },
  {
    Ticker: 'STRL',
    Company: 'Sterling Constructio'
  },
  {
    Ticker: 'STRM',
    Company: 'Streamline Health So'
  },
  {
    Ticker: 'STRO',
    Company: 'Sutro Biopharma Inc'
  },
  {
    Ticker: 'STRS',
    Company: 'Stratus Pptys Inc'
  },
  {
    Ticker: 'STRT',
    Company: 'Strattec Security'
  },
  {
    Ticker: 'STSA',
    Company: 'Satsuma Pharmaceuticals Inc'
  },
  {
    Ticker: 'STX',
    Company: 'Seagate Tech Ord Shs'
  },
  {
    Ticker: 'STXB',
    Company: 'Spirit of Texas Bancshares Inc'
  },
  {
    Ticker: 'SUMR',
    Company: 'Summer Infant Inc'
  },
  {
    Ticker: 'SUNS',
    Company: 'Solar Senior Capital'
  },
  {
    Ticker: 'SUNW',
    Company: 'Sunworks Inc'
  },
  {
    Ticker: 'SUPN',
    Company: 'Supernus Pharm'
  },
  {
    Ticker: 'SURF',
    Company: 'Surface Oncology Inc'
  },
  {
    Ticker: 'SUSB',
    Company: '1-5 Year USD Corp Bond Ishares ESG ETF'
  },
  {
    Ticker: 'SUSC',
    Company: 'USD Corp Bond Ishares ESG ETF'
  },
  {
    Ticker: 'SUSL',
    Company: 'Ishares ESG MSCI USA Leaders ETF'
  },
  {
    Ticker: 'SVBI',
    Company: 'Severn Bancorp Inc'
  },
  {
    Ticker: 'SVC',
    Company: 'Service Properties Trust'
  },
  {
    Ticker: 'SVMK',
    Company: 'Svmk Inc'
  },
  {
    Ticker: 'SVRA',
    Company: 'Savara Inc'
  },
  {
    Ticker: 'SVVC',
    Company: 'Firsthand Technology'
  },
  {
    Ticker: 'SWAV',
    Company: 'Shockwave Medical Inc'
  },
  {
    Ticker: 'SWIR',
    Company: 'Sierra Wireless IN'
  },
  {
    Ticker: 'SWKS',
    Company: 'Skyworks Solutions'
  },
  {
    Ticker: 'SWTX',
    Company: 'Springworks Therapeutics Inc'
  },
  {
    Ticker: 'SXTC',
    Company: 'China Sxt Pharmaceuticals Inc'
  },
  {
    Ticker: 'SY',
    Company: 'So-Young International Inc ADR'
  },
  {
    Ticker: 'SYBT',
    Company: 'Stock Yards Bancorp'
  },
  {
    Ticker: 'SYBX',
    Company: 'Synlogic Inc'
  },
  {
    Ticker: 'SYKE',
    Company: 'Sykes Enterprises'
  },
  {
    Ticker: 'SYNA',
    Company: 'Synaptics Inc'
  },
  {
    Ticker: 'SYNC',
    Company: 'Synacor Inc'
  },
  {
    Ticker: 'SYNH',
    Company: 'Syneos Health Inc'
  },
  {
    Ticker: 'SYNL',
    Company: 'Synalloy Cp'
  },
  {
    Ticker: 'SYPR',
    Company: 'Sypris Solutions'
  },
  {
    Ticker: 'SYRS',
    Company: 'Syros Pharmaceuticals'
  },
  {
    Ticker: 'TA',
    Company: 'Travelcenters of America Llc'
  },
  {
    Ticker: 'TACO',
    Company: 'Del Taco Rest'
  },
  {
    Ticker: 'TACOW',
    Company: 'Del Taco Rest WT'
  },
  {
    Ticker: 'TACT',
    Company: 'Transact Tech Inc'
  },
  {
    Ticker: 'TAIT',
    Company: 'Taitron Components'
  },
  {
    Ticker: 'TANH',
    Company: 'Tantech Holdings'
  },
  {
    Ticker: 'TANNI',
    Company: 'Travelcenters of America Llc'
  },
  {
    Ticker: 'TANNL',
    Company: 'Travelcenters of America Llc'
  },
  {
    Ticker: 'TANNZ',
    Company: 'Travelcenters of America Llc'
  },
  {
    Ticker: 'TAOP',
    Company: 'Taoping Inc'
  },
  {
    Ticker: 'TAST',
    Company: 'Carrols Rstrnt Group'
  },
  {
    Ticker: 'TATT',
    Company: 'T A T Tech Ltd'
  },
  {
    Ticker: 'TAYD',
    Company: 'Taylor Devices Inc'
  },
  {
    Ticker: 'TBBK',
    Company: 'The Bancorp Inc'
  },
  {
    Ticker: 'TBIO',
    Company: 'Translate Bio Inc'
  },
  {
    Ticker: 'TBK',
    Company: 'Triumph Bancorp'
  },
  {
    Ticker: 'TBLT',
    Company: 'Toughbuilt Industries Inc'
  },
  {
    Ticker: 'TBLTU',
    Company: 'Toughbuilt Industries Inc Cl A Units'
  },
  {
    Ticker: 'TBLTW',
    Company: 'Toughbuilt Industries Inc WT'
  },
  {
    Ticker: 'TBNK',
    Company: 'Territorial Bancorp'
  },
  {
    Ticker: 'TBPH',
    Company: 'Theravance Bio Ord'
  },
  {
    Ticker: 'TC',
    Company: 'Tuanche Ltd ADR'
  },
  {
    Ticker: 'TCBI',
    Company: 'Texas Capital Bncsh'
  },
  {
    Ticker: 'TCBIL',
    Company: 'Texas Cap 6.5 Sr NTS'
  },
  {
    Ticker: 'TCBIP',
    Company: 'Texas Capital'
  },
  {
    Ticker: 'TCBK',
    Company: 'Trico Bancshares'
  },
  {
    Ticker: 'TCCO',
    Company: 'Technical Commctn Cp'
  },
  {
    Ticker: 'TCDA',
    Company: 'Tricida Inc'
  },
  {
    Ticker: 'TCF',
    Company: 'TCF Financial Corp'
  },
  {
    Ticker: 'TCFC',
    Company: 'The Community Fin Cp'
  },
  {
    Ticker: 'TCFCP',
    Company: 'TCF Financial Corp'
  },
  {
    Ticker: 'TCMD',
    Company: 'Tactile Systems Technology Inc'
  },
  {
    Ticker: 'TCOM',
    Company: 'Trip.com Group Ltd'
  },
  {
    Ticker: 'TCON',
    Company: 'Tracon Pharma'
  },
  {
    Ticker: 'TCPC',
    Company: 'TCP Capital Corp'
  },
  {
    Ticker: 'TCRD',
    Company: 'THL Credit Inc'
  },
  {
    Ticker: 'TCRR',
    Company: 'Tcr2 Therapeutics Inc'
  },
  {
    Ticker: 'TCX',
    Company: 'Tucows Inc Cl A'
  },
  {
    Ticker: 'TDAC',
    Company: 'Trident Acquisitions Corp'
  },
  {
    Ticker: 'TDACU',
    Company: 'Trident Acquisitions Corp Units'
  },
  {
    Ticker: 'TDACW',
    Company: 'Trident Acquisitions Corp WT'
  },
  {
    Ticker: 'TDIV',
    Company: 'Nasdaq Technology Dividend Index Fund FT'
  },
  {
    Ticker: 'TEAM',
    Company: 'Atlassian Cls A Ord'
  },
  {
    Ticker: 'TECD',
    Company: 'Tech Data Cp'
  },
  {
    Ticker: 'TECH',
    Company: 'Bio-Techne Cp'
  },
  {
    Ticker: 'TECTP',
    Company: 'Tectonic Financial Inc 9.00% Fixed-To-Floating'
  },
  {
    Ticker: 'TEDU',
    Company: 'Tarena Intl ADR'
  },
  {
    Ticker: 'TELA',
    Company: 'Tela Bio Inc'
  },
  {
    Ticker: 'TELL',
    Company: 'Tellurian Inc'
  },
  {
    Ticker: 'TENB',
    Company: 'Tenable Holdings Inc'
  },
  {
    Ticker: 'TENX',
    Company: 'Tenax Therapeutics'
  },
  {
    Ticker: 'TER',
    Company: 'Teradyne Inc'
  },
  {
    Ticker: 'TERP',
    Company: 'Terraform Power Inc'
  },
  {
    Ticker: 'TESS',
    Company: 'Tessco Tech Inc'
  },
  {
    Ticker: 'TEUM',
    Company: 'Pareteum Corp'
  },
  {
    Ticker: 'TFFP',
    Company: 'Tff Pharmaceuticals Inc'
  },
  {
    Ticker: 'TFSL',
    Company: 'Tfs Financial Corp'
  },
  {
    Ticker: 'TGA',
    Company: 'Transglobe Energy Cp'
  },
  {
    Ticker: 'TGEN',
    Company: 'Tecogen Inc'
  },
  {
    Ticker: 'TGLS',
    Company: 'Tecnoglass Inc'
  },
  {
    Ticker: 'TGTX',
    Company: 'Tg Therapeuticscmn'
  },
  {
    Ticker: 'TH',
    Company: 'Target Hospitality Corp'
  },
  {
    Ticker: 'THBR',
    Company: 'Thunder Bridge Acquisition II Cl A'
  },
  {
    Ticker: 'THBRU',
    Company: 'Thunder Bridge Acquisition II Ltd. Units'
  },
  {
    Ticker: 'THBRW',
    Company: 'Thunder Bridge Acquisition II WT'
  },
  {
    Ticker: 'THCA',
    Company: 'Tuscan Holdings Corp II'
  },
  {
    Ticker: 'THCAU',
    Company: 'Tuscan Holdings Corp II Unit'
  },
  {
    Ticker: 'THCAW',
    Company: 'Tuscan Holdings Corp II WT'
  },
  {
    Ticker: 'THCB',
    Company: 'Tuscan Holdings Corp'
  },
  {
    Ticker: 'THCBU',
    Company: 'Tuscan Holdings Corp Unit'
  },
  {
    Ticker: 'THCBW',
    Company: 'Tuscan Holdings Corp WT'
  },
  {
    Ticker: 'THFF',
    Company: 'First Finl Corp [In]'
  },
  {
    Ticker: 'THMO',
    Company: 'Thermogenesis Holdings Inc.'
  },
  {
    Ticker: 'THOR',
    Company: 'Synthorx Inc'
  },
  {
    Ticker: 'THRM',
    Company: 'Gentherm Inc'
  },
  {
    Ticker: 'THTX',
    Company: 'Theratechnologies Inc'
  },
  {
    Ticker: 'THWWW',
    Company: 'Target Hospitality Corp WT'
  },
  {
    Ticker: 'TIBR',
    Company: 'Tiberius Acquisition Corp'
  },
  {
    Ticker: 'TIBRU',
    Company: 'Tiberius Acquisition Corp Units'
  },
  {
    Ticker: 'TIBRW',
    Company: 'Tiberius Acquisition WT'
  },
  {
    Ticker: 'TIGO',
    Company: 'Millicom International Cellular S.A.'
  },
  {
    Ticker: 'TIGR',
    Company: 'Up Fintech Holding Ltd ADR'
  },
  {
    Ticker: 'TILE',
    Company: 'Interface Inc'
  },
  {
    Ticker: 'TIPT',
    Company: 'Tiptree Fncl Cl A'
  },
  {
    Ticker: 'TITN',
    Company: 'Titan Machinery Inc'
  },
  {
    Ticker: 'TIVO',
    Company: 'Tivo Corp'
  },
  {
    Ticker: 'TKKS',
    Company: 'Tkk Symphony Acquisition Corp'
  },
  {
    Ticker: 'TKKSR',
    Company: 'Tkk Symphony Acquisition Corp Right'
  },
  {
    Ticker: 'TKKSU',
    Company: 'Tkk Symphony Acquisition Corp Unit'
  },
  {
    Ticker: 'TKKSW',
    Company: 'Tkk Symphony Acquisition Corp WT'
  },
  {
    Ticker: 'TLC',
    Company: 'Taiwan Liposome Company Ltd ADR'
  },
  {
    Ticker: 'TLF',
    Company: 'Tandy Leather Fctry'
  },
  {
    Ticker: 'TLGT',
    Company: 'Teligent'
  },
  {
    Ticker: 'TLND',
    Company: 'Talend S.A.'
  },
  {
    Ticker: 'TLRY',
    Company: 'Tilray Inc'
  },
  {
    Ticker: 'TLSA',
    Company: 'Tiziana Life Sciences Plc ADR'
  },
  {
    Ticker: 'TLT',
    Company: '20+ Year Treas Bond Ishares ETF'
  },
  {
    Ticker: 'TMDI',
    Company: 'Titan Medical Inc'
  },
  {
    Ticker: 'TMDX',
    Company: 'Transmedics Group Inc'
  },
  {
    Ticker: 'TMSR',
    Company: 'Tmsr Holding Company Ltd'
  },
  {
    Ticker: 'TMUS',
    Company: 'T-Mobile US'
  },
  {
    Ticker: 'TNAV',
    Company: 'Telenav Inc'
  },
  {
    Ticker: 'TNDM',
    Company: 'Tandem Diabetes Care'
  },
  {
    Ticker: 'TNXP',
    Company: 'Tonix Pharm Hld'
  },
  {
    Ticker: 'TOCA',
    Company: 'Tocagen Inc'
  },
  {
    Ticker: 'TOPS',
    Company: 'Top Ships Inc'
  },
  {
    Ticker: 'TORC',
    Company: 'Restorbio Inc'
  },
  {
    Ticker: 'TOTA',
    Company: 'Tottenham Acquisition I Ltd'
  },
  {
    Ticker: 'TOTAR',
    Company: 'Tottenham Acquisition I Ltd Right'
  },
  {
    Ticker: 'TOTAW',
    Company: 'Tottenham Acquisition I Ltd WT'
  },
  {
    Ticker: 'TOUR',
    Company: 'Tuniu Corporatio Ads'
  },
  {
    Ticker: 'TOWN',
    Company: 'Towne Bank'
  },
  {
    Ticker: 'TPCO',
    Company: 'Tribune Publishing Company'
  },
  {
    Ticker: 'TPIC',
    Company: 'Tpi Composites Inc'
  },
  {
    Ticker: 'TPTX',
    Company: 'Turning Point Therapeutics Inc'
  },
  {
    Ticker: 'TQQQ',
    Company: 'Ultrapro QQQ Proshares'
  },
  {
    Ticker: 'TRCB',
    Company: 'Two River Bncrp'
  },
  {
    Ticker: 'TRCH',
    Company: 'Torchlight Energy'
  },
  {
    Ticker: 'TREE',
    Company: 'Lendingtree Inc'
  },
  {
    Ticker: 'TRHC',
    Company: 'Tabula Rasa Healthcare Inc'
  },
  {
    Ticker: 'TRIB',
    Company: 'Trinity Bio ADR'
  },
  {
    Ticker: 'TRIL',
    Company: 'Trillium Therapeutic'
  },
  {
    Ticker: 'TRIP',
    Company: 'Tripadvisor Inc'
  },
  {
    Ticker: 'TRMB',
    Company: 'Trimble Navigation'
  },
  {
    Ticker: 'TRMD',
    Company: 'Torm Plc Cl A'
  },
  {
    Ticker: 'TRMK',
    Company: 'Trustmark Corp'
  },
  {
    Ticker: 'TRMT',
    Company: 'Tremont Mortgage Trust'
  },
  {
    Ticker: 'TRNS',
    Company: 'Transcat Inc'
  },
  {
    Ticker: 'TRNX',
    Company: 'Taronis Technologies Inc'
  },
  {
    Ticker: 'TROV',
    Company: 'Trovagene Inc'
  },
  {
    Ticker: 'TROW',
    Company: 'T Rowe Price Group'
  },
  {
    Ticker: 'TRPX',
    Company: 'Therapix Biosciences Ltd'
  },
  {
    Ticker: 'TRS',
    Company: 'Trimas Corp'
  },
  {
    Ticker: 'TRST',
    Company: 'Trustco Bank Corp NY'
  },
  {
    Ticker: 'TRUE',
    Company: 'Truecar Inc'
  },
  {
    Ticker: 'TRUP',
    Company: 'Trupanion Inc'
  },
  {
    Ticker: 'TRVG',
    Company: 'Trivago NV'
  },
  {
    Ticker: 'TRVI',
    Company: 'Trevi Therapeutics Inc'
  },
  {
    Ticker: 'TRVN',
    Company: 'Trevena Inc'
  },
  {
    Ticker: 'TSBK',
    Company: 'Timberland Bancorp'
  },
  {
    Ticker: 'TSC',
    Company: 'Tristate Capital Hld'
  },
  {
    Ticker: 'TSCAP',
    Company: 'Tristate Capital Holdings Inc Dep Shs Repstg 1/4'
  },
  {
    Ticker: 'TSCBP',
    Company: 'Tristate Capital Holdings Inc Fxdfr Prf Perpet'
  },
  {
    Ticker: 'TSCO',
    Company: 'Tractor Supply Company'
  },
  {
    Ticker: 'TSEM',
    Company: 'Tower Semiconductor'
  },
  {
    Ticker: 'TSG',
    Company: 'The Stars Group Inc'
  },
  {
    Ticker: 'TSLA',
    Company: 'Tesla Inc'
  },
  {
    Ticker: 'TSRI',
    Company: 'T S R Inc'
  },
  {
    Ticker: 'TTD',
    Company: 'Trade Desk Inc'
  },
  {
    Ticker: 'TTEC',
    Company: 'Teletech Hlds'
  },
  {
    Ticker: 'TTEK',
    Company: 'Tetra Tech Inc'
  },
  {
    Ticker: 'TTGT',
    Company: 'Techtarget Inc'
  },
  {
    Ticker: 'TTMI',
    Company: 'TTM Technologies'
  },
  {
    Ticker: 'TTNP',
    Company: 'Titan Pharma'
  },
  {
    Ticker: 'TTOO',
    Company: 'T2 Biosystems CS'
  },
  {
    Ticker: 'TTPH',
    Company: 'Tetraphase Pharmaceu'
  },
  {
    Ticker: 'TTTN',
    Company: 'Tigershares China-U.S. Internet Titans ETF'
  },
  {
    Ticker: 'TTWO',
    Company: 'Take-Two Interacti'
  },
  {
    Ticker: 'TUES',
    Company: 'Tuesday Morning Company'
  },
  {
    Ticker: 'TUR',
    Company: 'Turkey Ishares MSCI ETF'
  },
  {
    Ticker: 'TURN',
    Company: '180 Degree Capital Corp'
  },
  {
    Ticker: 'TUSA',
    Company: 'Total US Market Alphadex ETF FT'
  },
  {
    Ticker: 'TUSK',
    Company: 'Mammoth Energy Services Inc'
  },
  {
    Ticker: 'TVIX',
    Company: 'VS 2X VIX Short Term'
  },
  {
    Ticker: 'TVTY',
    Company: 'Tivity Health'
  },
  {
    Ticker: 'TW',
    Company: 'Tradeweb Markets Inc'
  },
  {
    Ticker: 'TWIN',
    Company: 'Twin Disc Inc'
  },
  {
    Ticker: 'TWMC',
    Company: 'Trans World Entertainment Corp'
  },
  {
    Ticker: 'TWNK',
    Company: 'Hostess Brands Inc'
  },
  {
    Ticker: 'TWNKW',
    Company: 'Gores Holdings WT'
  },
  {
    Ticker: 'TWOU',
    Company: '2U Inc'
  },
  {
    Ticker: 'TWST',
    Company: 'Twist Bioscience Corp'
  },
  {
    Ticker: 'TXG',
    Company: '10X Genomics Inc'
  },
  {
    Ticker: 'TXMD',
    Company: 'Therapeuticsmd Inc'
  },
  {
    Ticker: 'TXN',
    Company: 'Texas Instruments'
  },
  {
    Ticker: 'TXRH',
    Company: 'Texas Roadhouse Inc'
  },
  {
    Ticker: 'TYHT',
    Company: 'Shineco Inc'
  },
  {
    Ticker: 'TYME',
    Company: 'Tyme Technologies'
  },
  {
    Ticker: 'TZAC',
    Company: 'Tenzing Acquisition Corp'
  },
  {
    Ticker: 'TZACU',
    Company: 'Tenzing Acquisition Corp Units'
  },
  {
    Ticker: 'TZACW',
    Company: 'Tenzing Acquisition Corp WT'
  },
  {
    Ticker: 'TZOO',
    Company: 'Travelzoo Inc'
  },
  {
    Ticker: 'UAE',
    Company: 'UAE Ishares MSCI ETF'
  },
  {
    Ticker: 'UAL',
    Company: 'United Continental Holdings'
  },
  {
    Ticker: 'UBCP',
    Company: 'United Bncp Inc'
  },
  {
    Ticker: 'UBFO',
    Company: 'United Security Bcsh'
  },
  {
    Ticker: 'UBIO',
    Company: 'Ultrapro Biotechnology Proshares'
  },
  {
    Ticker: 'UBOH',
    Company: 'United Bancshares'
  },
  {
    Ticker: 'UBSI',
    Company: 'United Bkshs Inc'
  },
  {
    Ticker: 'UBX',
    Company: 'Unity Biotechnology Inc'
  },
  {
    Ticker: 'UCBI',
    Company: 'United Comm Banks'
  },
  {
    Ticker: 'UCFC',
    Company: 'United Community Fnl'
  },
  {
    Ticker: 'UCTT',
    Company: 'Ultra Clean Holdings'
  },
  {
    Ticker: 'UEIC',
    Company: 'Universal Elect IN'
  },
  {
    Ticker: 'UEPS',
    Company: 'Net 1 Ueps Techs Inc'
  },
  {
    Ticker: 'UFCS',
    Company: 'United Fire Group'
  },
  {
    Ticker: 'UFO',
    Company: 'Procure Space ETF'
  },
  {
    Ticker: 'UFPI',
    Company: 'Universal Forest Prd'
  },
  {
    Ticker: 'UFPT',
    Company: 'U F P Tech Inc'
  },
  {
    Ticker: 'UG',
    Company: 'United Guardian Inc'
  },
  {
    Ticker: 'UGLD',
    Company: 'VS 3X Gold'
  },
  {
    Ticker: 'UHAL',
    Company: 'A M E R C O'
  },
  {
    Ticker: 'UIHC',
    Company: 'United Insurance Hld'
  },
  {
    Ticker: 'ULBI',
    Company: 'Ultralife Corp'
  },
  {
    Ticker: 'ULH',
    Company: 'Universal Logis Hld'
  },
  {
    Ticker: 'ULTA',
    Company: 'Ulta Beauty Inc'
  },
  {
    Ticker: 'UMBF',
    Company: 'Ump Financial Corp'
  },
  {
    Ticker: 'UMPQ',
    Company: 'Umpqua Holdings Corp'
  },
  {
    Ticker: 'UMRX',
    Company: 'Unum Therapeutics Inc'
  },
  {
    Ticker: 'UNAM',
    Company: 'Unico American Cp'
  },
  {
    Ticker: 'UNB',
    Company: 'Union Bankshares Inc'
  },
  {
    Ticker: 'UNIT',
    Company: 'Uniti Group Inc'
  },
  {
    Ticker: 'UNTY',
    Company: 'Unity Bancorp Inc'
  },
  {
    Ticker: 'UONE',
    Company: 'Urban One Inc'
  },
  {
    Ticker: 'UONEK',
    Company: 'Urban One Inc'
  },
  {
    Ticker: 'UPLD',
    Company: 'Upland Software Inc'
  },
  {
    Ticker: 'UPWK',
    Company: 'Upwork Inc'
  },
  {
    Ticker: 'URBN',
    Company: 'Urban Outfitters Inc'
  },
  {
    Ticker: 'URGN',
    Company: 'Urogen Pharma Ltd'
  },
  {
    Ticker: 'UROV',
    Company: 'Urovant Sciences Ltd'
  },
  {
    Ticker: 'USAK',
    Company: 'U S A Truck Inc'
  },
  {
    Ticker: 'USAP',
    Company: 'Universal Stainles'
  },
  {
    Ticker: 'USAU',
    Company: 'U.S. Gold Corp'
  },
  {
    Ticker: 'USCR',
    Company: 'U S Concrete Inc'
  },
  {
    Ticker: 'USEG',
    Company: 'U S Energy Corp'
  },
  {
    Ticker: 'USIG',
    Company: 'Broad USD Investment Gr. Corp Bond Ishares ETF'
  },
  {
    Ticker: 'USIO',
    Company: 'Usio Inc'
  },
  {
    Ticker: 'USLB',
    Company: 'Russell 1000 Low Beta EW Invesco ETF'
  },
  {
    Ticker: 'USLM',
    Company: 'U S Lime & Mineral'
  },
  {
    Ticker: 'USLV',
    Company: 'VS 3X Silver'
  },
  {
    Ticker: 'USMC',
    Company: 'Principal U.S. Mega-Cap Multi-Factor Index ETF'
  },
  {
    Ticker: 'USOI',
    Company: 'Credit Suisse Ag Credit Suisse X-Links Crude Oil'
  },
  {
    Ticker: 'USWS',
    Company: 'U.S. Well Services Inc'
  },
  {
    Ticker: 'USWSW',
    Company: 'U.S. Well Services Inc WT'
  },
  {
    Ticker: 'UTHR',
    Company: 'United Therapeutic'
  },
  {
    Ticker: 'UTMD',
    Company: 'Utah Medical Prod'
  },
  {
    Ticker: 'UTSI',
    Company: 'Utstarcom Holdings C'
  },
  {
    Ticker: 'UVSP',
    Company: 'Univest Corp of Penn'
  },
  {
    Ticker: 'UXIN',
    Company: 'Uxin Ltd Ads'
  },
  {
    Ticker: 'VALU',
    Company: 'Value Line Inc'
  },
  {
    Ticker: 'VALX',
    Company: 'Validea Market Legends ETF'
  },
  {
    Ticker: 'VBFC',
    Company: 'Village Bank & Trust'
  },
  {
    Ticker: 'VBIV',
    Company: 'Vbi Vaccines Inc CS'
  },
  {
    Ticker: 'VBLT',
    Company: 'Vascular Biogen. Ord'
  },
  {
    Ticker: 'VBTX',
    Company: 'Veritex Holdings'
  },
  {
    Ticker: 'VC',
    Company: 'Visteon Corp'
  },
  {
    Ticker: 'VCEL',
    Company: 'Vericel Corproation'
  },
  {
    Ticker: 'VCIT',
    Company: 'Interm Corp Bond Vanguard'
  },
  {
    Ticker: 'VCLT',
    Company: 'Long-Term Corp Bond Vanguard'
  },
  {
    Ticker: 'VCNX',
    Company: 'Vaccinex Inc'
  },
  {
    Ticker: 'VCSH',
    Company: 'Sht-Term Corp Bond Vanguard'
  },
  {
    Ticker: 'VCTR',
    Company: 'Victory Capital Holdings Inc Cl A'
  },
  {
    Ticker: 'VCYT',
    Company: 'Veracyte Inc'
  },
  {
    Ticker: 'VECO',
    Company: 'Veeco Instrument'
  },
  {
    Ticker: 'VEON',
    Company: 'Veon Ltd'
  },
  {
    Ticker: 'VERB',
    Company: 'Verb Technology Company Inc'
  },
  {
    Ticker: 'VERBW',
    Company: 'Verb Technology Company Inc WT'
  },
  {
    Ticker: 'VERI',
    Company: 'Veritone Inc'
  },
  {
    Ticker: 'VERO',
    Company: 'Venus Concept Inc.'
  },
  {
    Ticker: 'VERU',
    Company: 'Veru Inc'
  },
  {
    Ticker: 'VERY',
    Company: 'Vericity Inc'
  },
  {
    Ticker: 'VETS',
    Company: 'Pacer Military Times Best Employers ETF'
  },
  {
    Ticker: 'VFF',
    Company: 'Village Farms Intl'
  },
  {
    Ticker: 'VGIT',
    Company: 'Interm Govt Bond Vanguard'
  },
  {
    Ticker: 'VGLT',
    Company: 'Long-Term Govt Bond Vanguard'
  },
  {
    Ticker: 'VGSH',
    Company: 'Sht-Term Govt Bond Vanguard'
  },
  {
    Ticker: 'VIA',
    Company: 'Viacom Inc Cl A'
  },
  {
    Ticker: 'VIAB',
    Company: 'Viacom Inc Cl B'
  },
  {
    Ticker: 'VIAC',
    Company: 'Viacomcbs Inc. Cl B'
  },
  {
    Ticker: 'VIACA',
    Company: 'Viacomcbs Inc. Cl A'
  },
  {
    Ticker: 'VIAV',
    Company: 'Viavi Solutions'
  },
  {
    Ticker: 'VICR',
    Company: 'Vicor Cp'
  },
  {
    Ticker: 'VIE',
    Company: 'Viela Bio Inc'
  },
  {
    Ticker: 'VIGI',
    Company: 'Intl Div Appreciation ETF Vanguard'
  },
  {
    Ticker: 'VIIX',
    Company: 'VS VIX Short Term'
  },
  {
    Ticker: 'VIOT',
    Company: 'Viomi Technology Co. Ltd ADR'
  },
  {
    Ticker: 'VIR',
    Company: 'Vir Biotechnology Inc'
  },
  {
    Ticker: 'VIRC',
    Company: 'Virco MFG Cp'
  },
  {
    Ticker: 'VIRT',
    Company: 'Virtu Financial Cm A'
  },
  {
    Ticker: 'VISL',
    Company: 'Vislink Technologies Inc'
  },
  {
    Ticker: 'VIVE',
    Company: 'Viveve Medical'
  },
  {
    Ticker: 'VIVO',
    Company: 'Meridian Bioscience'
  },
  {
    Ticker: 'VKTX',
    Company: 'Viking Thera'
  },
  {
    Ticker: 'VKTXW',
    Company: 'Viking Theraptcs WT'
  },
  {
    Ticker: 'VLGEA',
    Company: 'Village Super Mkt'
  },
  {
    Ticker: 'VLRX',
    Company: 'Valeritas Holdings Inc'
  },
  {
    Ticker: 'VLY',
    Company: 'Valley National Bancorp'
  },
  {
    Ticker: 'VLYPO',
    Company: 'Valley National Bancorp Pfd Series B'
  },
  {
    Ticker: 'VLYPP',
    Company: 'Valley National Bancorp Pfd Series A'
  },
  {
    Ticker: 'VMBS',
    Company: 'Mortgage-Backed Securities Vanguard'
  },
  {
    Ticker: 'VMD',
    Company: 'Viemed Healthcare Inc'
  },
  {
    Ticker: 'VNDA',
    Company: 'Vanda Pharmaceuticals'
  },
  {
    Ticker: 'VNET',
    Company: '21Vianet Group Inc'
  },
  {
    Ticker: 'VNOM',
    Company: 'Viper Energy LP'
  },
  {
    Ticker: 'VNQI',
    Company: 'Global Ex-US Real Estate Vanguard'
  },
  {
    Ticker: 'VOD',
    Company: 'Vodafone Grp Plc Ads'
  },
  {
    Ticker: 'VONE',
    Company: 'Russell 1000 Vanguard'
  },
  {
    Ticker: 'VONG',
    Company: 'Russell 1000 Growth Vanguard'
  },
  {
    Ticker: 'VONV',
    Company: 'Russell 1000 Value Vanguard'
  },
  {
    Ticker: 'VOXX',
    Company: 'Voxx Intl Corp'
  },
  {
    Ticker: 'VRA',
    Company: 'Vera Bradley'
  },
  {
    Ticker: 'VRAY',
    Company: 'Viewray Inc'
  },
  {
    Ticker: 'VRCA',
    Company: 'Verrica Pharmaceuticals Inc'
  },
  {
    Ticker: 'VREX',
    Company: 'Varex Imaging Corp'
  },
  {
    Ticker: 'VRIG',
    Company: 'Variable Rate Investment Grade Invesco ETF'
  },
  {
    Ticker: 'VRML',
    Company: 'Vermillion Inc'
  },
  {
    Ticker: 'VRNA',
    Company: 'Verona Pharma Plc ADR'
  },
  {
    Ticker: 'VRNS',
    Company: 'Varonis Systems Inc'
  },
  {
    Ticker: 'VRNT',
    Company: 'Verint Systems Inc'
  },
  {
    Ticker: 'VRRM',
    Company: 'Verra Mobility Corp'
  },
  {
    Ticker: 'VRSK',
    Company: 'Verisk Analytics Inc'
  },
  {
    Ticker: 'VRSN',
    Company: 'Verisign Inc'
  },
  {
    Ticker: 'VRTS',
    Company: 'Virtus Inv Partners'
  },
  {
    Ticker: 'VRTSP',
    Company: 'Virtus Investment Partners Inc Mandatory Pfd Con'
  },
  {
    Ticker: 'VRTU',
    Company: 'Virtusa Corp'
  },
  {
    Ticker: 'VRTX',
    Company: 'Vertex Pharmaceutic'
  },
  {
    Ticker: 'VSAT',
    Company: 'Viasat Inc'
  },
  {
    Ticker: 'VSDA',
    Company: 'Victory Dividend Accelerator ETF'
  },
  {
    Ticker: 'VSEC',
    Company: 'V S E Cp'
  },
  {
    Ticker: 'VSMV',
    Company: 'Victory Portfolios II Victoryshares US Multi-Fac'
  },
  {
    Ticker: 'VSTM',
    Company: 'Verastem Inc'
  },
  {
    Ticker: 'VTC',
    Company: 'Total Corporate Bond Vanguard ETF'
  },
  {
    Ticker: 'VTGN',
    Company: 'Vistagen Therap. Com'
  },
  {
    Ticker: 'VTHR',
    Company: 'Russell 3000 Vanguard'
  },
  {
    Ticker: 'VTIP',
    Company: 'Sht-Term Infl Protected Vanguard'
  },
  {
    Ticker: 'VTIQ',
    Company: 'Vectoiq Acquisition Corp'
  },
  {
    Ticker: 'VTIQU',
    Company: 'Vectoiq Acquisition Corp Unit'
  },
  {
    Ticker: 'VTIQW',
    Company: 'Vectoiq Acquisition Corp WT'
  },
  {
    Ticker: 'VTNR',
    Company: 'Vertex Energy'
  },
  {
    Ticker: 'VTSI',
    Company: 'Virtra Systems Inc'
  },
  {
    Ticker: 'VTVT',
    Company: 'Vtv Theraptcs Cl A'
  },
  {
    Ticker: 'VTWG',
    Company: 'Russell 2000 Growth Vanguard'
  },
  {
    Ticker: 'VTWO',
    Company: 'Russell 2000 Vanguard'
  },
  {
    Ticker: 'VTWV',
    Company: 'Russell 2000 Value Vanguard'
  },
  {
    Ticker: 'VUZI',
    Company: 'Vuzix Corp'
  },
  {
    Ticker: 'VVPR',
    Company: 'Vivopower International Plc'
  },
  {
    Ticker: 'VVUS',
    Company: 'Vivus Inc'
  },
  {
    Ticker: 'VWOB',
    Company: 'EM Govt Bond Vanguard'
  },
  {
    Ticker: 'VXRT',
    Company: 'Vaxart Inc'
  },
  {
    Ticker: 'VXUS',
    Company: 'Total Intl Stock ETF Vanguard'
  },
  {
    Ticker: 'VYGR',
    Company: 'Voyager Therapeut'
  },
  {
    Ticker: 'VYMI',
    Company: 'Intl High Divd Yld ETF Vanguard'
  },
  {
    Ticker: 'WABC',
    Company: 'Westamerica Bancorp'
  },
  {
    Ticker: 'WAFD',
    Company: 'Washington Federal'
  },
  {
    Ticker: 'WAFU',
    Company: 'Wah Fu Education Group Limited'
  },
  {
    Ticker: 'WASH',
    Company: 'Washington TR Bncorp'
  },
  {
    Ticker: 'WATT',
    Company: 'Energous Corp'
  },
  {
    Ticker: 'WB',
    Company: 'Weibo Corp Ads'
  },
  {
    Ticker: 'WBA',
    Company: 'Walgreens Boots Alliance'
  },
  {
    Ticker: 'WBND',
    Company: 'Western Asset Total Return ETF'
  },
  {
    Ticker: 'WCLD',
    Company: 'Wisdomtree Cloud Computing Fund'
  },
  {
    Ticker: 'WDAY',
    Company: 'Workday Inc'
  },
  {
    Ticker: 'WDC',
    Company: 'Western Digital Cp'
  },
  {
    Ticker: 'WDFC',
    Company: 'W D 40 Company'
  },
  {
    Ticker: 'WEBK',
    Company: 'Wellesley Bncp Inc'
  },
  {
    Ticker: 'WEN',
    Company: 'Wendys Company'
  },
  {
    Ticker: 'WERN',
    Company: 'Werner Enterprise'
  },
  {
    Ticker: 'WETF',
    Company: 'Wisdomtree Invstmnts'
  },
  {
    Ticker: 'WEYS',
    Company: 'Weyco Gp Inc'
  },
  {
    Ticker: 'WHF',
    Company: 'Whitehorse Finance'
  },
  {
    Ticker: 'WHFBZ',
    Company: 'Whitehorse Finance Inc 6.50% Notes Due 2025'
  },
  {
    Ticker: 'WHLM',
    Company: 'Wilhelmina Intl'
  },
  {
    Ticker: 'WHLR',
    Company: 'Wheeler Real Estate'
  },
  {
    Ticker: 'WHLRD',
    Company: 'Wheeler Real Estate Investment Trust Inc'
  },
  {
    Ticker: 'WHLRP',
    Company: 'Wheeler REIT B Pfd'
  },
  {
    Ticker: 'WIFI',
    Company: 'Boingo Wireless Inc'
  },
  {
    Ticker: 'WILC',
    Company: 'G Willi-Food Intl'
  },
  {
    Ticker: 'WINA',
    Company: 'Winmark Corp'
  },
  {
    Ticker: 'WINC',
    Company: 'Western Asset Short Duration Income ETF'
  },
  {
    Ticker: 'WING',
    Company: 'Wingstop Inc'
  },
  {
    Ticker: 'WINS',
    Company: 'Wins Finance Holdings Inc'
  },
  {
    Ticker: 'WIRE',
    Company: 'Encore Wire Cp'
  },
  {
    Ticker: 'WISA',
    Company: 'Summit Semiconductor Inc'
  },
  {
    Ticker: 'WIX',
    Company: 'Wix.com Ltd'
  },
  {
    Ticker: 'WKHS',
    Company: 'Workhorse Grp'
  },
  {
    Ticker: 'WLDN',
    Company: 'Willdan Group Inc'
  },
  {
    Ticker: 'WLFC',
    Company: 'Willis Lease Fin C'
  },
  {
    Ticker: 'WLTW',
    Company: 'Willis Towers WT'
  },
  {
    Ticker: 'WMGI',
    Company: 'Wright Med Grp NV'
  },
  {
    Ticker: 'WNEB',
    Company: 'Western New England Bancorp Inc'
  },
  {
    Ticker: 'WOOD',
    Company: 'Global Timber & Forestry Ishares ETF'
  },
  {
    Ticker: 'WORX',
    Company: 'Scworx Corp'
  },
  {
    Ticker: 'WPRT',
    Company: 'Westport Fuel Systems Inc'
  },
  {
    Ticker: 'WRLD',
    Company: 'World Acceptance Cp'
  },
  {
    Ticker: 'WRLS',
    Company: 'Pensare Acquisition Corp'
  },
  {
    Ticker: 'WRLSR',
    Company: 'Pensare Acquisition Corp 1/10 Stk'
  },
  {
    Ticker: 'WRLSW',
    Company: 'Pensare Acquisition Corp WT'
  },
  {
    Ticker: 'WRTC',
    Company: 'Wrap Technologies Inc'
  },
  {
    Ticker: 'WSBC',
    Company: 'Wesbanco Inc'
  },
  {
    Ticker: 'WSBF',
    Company: 'Waterstone Financial'
  },
  {
    Ticker: 'WSC',
    Company: 'Willscot Corp'
  },
  {
    Ticker: 'WSFS',
    Company: 'WSFS Financial Corp'
  },
  {
    Ticker: 'WSG',
    Company: 'Wanda Sports Group Company Ltd ADR'
  },
  {
    Ticker: 'WSTG',
    Company: 'Wayside Technology'
  },
  {
    Ticker: 'WSTL',
    Company: 'Westell Technologies'
  },
  {
    Ticker: 'WTBA',
    Company: 'West Bancorp'
  },
  {
    Ticker: 'WTER',
    Company: 'Alkaline Water Company Inc'
  },
  {
    Ticker: 'WTFC',
    Company: 'Wintrust Finl Corp'
  },
  {
    Ticker: 'WTFCM',
    Company: 'Wintrust Fin Pref D'
  },
  {
    Ticker: 'WTRE',
    Company: 'Watford Holdings Ltd'
  },
  {
    Ticker: 'WTREP',
    Company: 'Watford Holdings Ltd. 8.5%'
  },
  {
    Ticker: 'WTRH',
    Company: 'Waitr Holdings Inc'
  },
  {
    Ticker: 'WVE',
    Company: 'Wave Life Sci Ord Sh'
  },
  {
    Ticker: 'WVFC',
    Company: 'Wvs Financial Corp'
  },
  {
    Ticker: 'WVVI',
    Company: 'Willamette Valley'
  },
  {
    Ticker: 'WVVIP',
    Company: 'Willamette Valley Vineyards IN'
  },
  {
    Ticker: 'WW',
    Company: 'Weight Watchers International Inc'
  },
  {
    Ticker: 'WWD',
    Company: 'Woodward Inc'
  },
  {
    Ticker: 'WWR',
    Company: 'Westwater Resources'
  },
  {
    Ticker: 'WYNN',
    Company: 'Wynn Resorts Ltd'
  },
  {
    Ticker: 'XAIR',
    Company: 'Beyond Air Inc'
  },
  {
    Ticker: 'XBIO',
    Company: 'Xenetic Biosciences Inc'
  },
  {
    Ticker: 'XBIOW',
    Company: 'Xenetic Biosciences Inc WT'
  },
  {
    Ticker: 'XBIT',
    Company: 'Xbiotech Inc'
  },
  {
    Ticker: 'XCUR',
    Company: 'Exicure Inc'
  },
  {
    Ticker: 'XEL',
    Company: 'XCEL Energy Inc'
  },
  {
    Ticker: 'XELA',
    Company: 'Exela Technologies Inc'
  },
  {
    Ticker: 'XELB',
    Company: 'XCEL Brands'
  },
  {
    Ticker: 'XENE',
    Company: 'Xenon Pharmaceuticals Inc'
  },
  {
    Ticker: 'XENT',
    Company: 'Intersect Ent Inc'
  },
  {
    Ticker: 'XERS',
    Company: 'Xeris Pharmaceuticals Inc'
  },
  {
    Ticker: 'XFOR',
    Company: 'X4 Pharmaceuticals Inc'
  },
  {
    Ticker: 'XGN',
    Company: 'Exagen Inc'
  },
  {
    Ticker: 'XLNX',
    Company: 'Xilinx Inc'
  },
  {
    Ticker: 'XLRN',
    Company: 'Acceleron Pharma'
  },
  {
    Ticker: 'XNCR',
    Company: 'Xencor Inc'
  },
  {
    Ticker: 'XNET',
    Company: 'Xunlei Ltd ADR'
  },
  {
    Ticker: 'XOG',
    Company: 'Extraction Oil & Gas Inc'
  },
  {
    Ticker: 'XOMA',
    Company: 'Xoma Corp'
  },
  {
    Ticker: 'XON',
    Company: 'Intrexon Corp'
  },
  {
    Ticker: 'XONE',
    Company: 'The Exone Company'
  },
  {
    Ticker: 'XP',
    Company: 'XP Inc Cl A'
  },
  {
    Ticker: 'XPEL',
    Company: 'Xpel Technologies'
  },
  {
    Ticker: 'XPER',
    Company: 'Xperi Corp'
  },
  {
    Ticker: 'XRAY',
    Company: 'Dentsply Sirona Inc'
  },
  {
    Ticker: 'XSPA',
    Company: 'Xpresspa Group Inc'
  },
  {
    Ticker: 'XT',
    Company: 'Exponential Technologies Ishares ETF'
  },
  {
    Ticker: 'XTLB',
    Company: 'Xtl Biopharma Ads'
  },
  {
    Ticker: 'YAYO',
    Company: 'Yayyo Inc'
  },
  {
    Ticker: 'YGYI',
    Company: 'Youngevity International Inc'
  },
  {
    Ticker: 'YGYIP',
    Company: 'Youngevity International Inc 9.75% Series D Cum'
  },
  {
    Ticker: 'YI',
    Company: '111 Inc ADR'
  },
  {
    Ticker: 'YIN',
    Company: 'Yintech Investm Ads'
  },
  {
    Ticker: 'YJ',
    Company: 'Yunji Inc'
  },
  {
    Ticker: 'YLCO',
    Company: 'G-X Yieldco Index ETF'
  },
  {
    Ticker: 'YLDE',
    Company: 'Legg Clearbridge Dividend ESG ETF'
  },
  {
    Ticker: 'YMAB',
    Company: 'Y-Mabs Therapeutics Inc'
  },
  {
    Ticker: 'YNDX',
    Company: 'Yandex N.V.'
  },
  {
    Ticker: 'YORW',
    Company: 'York Water Company'
  },
  {
    Ticker: 'YRCW',
    Company: 'YRC Worldwide'
  },
  {
    Ticker: 'YTEN',
    Company: 'Yield10 Bioscience Inc'
  },
  {
    Ticker: 'YTRA',
    Company: 'Yatra Online Inc'
  },
  {
    Ticker: 'YVR',
    Company: 'Liquid Media Group Ltd'
  },
  {
    Ticker: 'YY',
    Company: 'YY Inc'
  },
  {
    Ticker: 'Z',
    Company: 'Zillow Group Cl C'
  },
  {
    Ticker: 'ZAGG',
    Company: 'Zagg Inc'
  },
  {
    Ticker: 'ZBIO',
    Company: 'Ultrapro Short Biotechnology Proshares'
  },
  {
    Ticker: 'ZBRA',
    Company: 'Zebra Technologies'
  },
  {
    Ticker: 'ZEAL',
    Company: 'Zealand Pharma A/S ADR'
  },
  {
    Ticker: 'ZEUS',
    Company: 'Olympic Steel Inc'
  },
  {
    Ticker: 'ZFGN',
    Company: 'Zafgen Inc'
  },
  {
    Ticker: 'ZG',
    Company: 'Zillow Grp Inc Cl A'
  },
  {
    Ticker: 'ZGNX',
    Company: 'Zogenix Inc'
  },
  {
    Ticker: 'ZION',
    Company: 'Zions Bancorp'
  },
  {
    Ticker: 'ZIONW',
    Company: 'Zions Bncrp WT'
  },
  {
    Ticker: 'ZIOP',
    Company: 'Ziopharm Oncology IN'
  },
  {
    Ticker: 'ZIV',
    Company: 'VS -1X VIX Mid Term'
  },
  {
    Ticker: 'ZIXI',
    Company: 'Zix Corp'
  },
  {
    Ticker: 'ZKIN',
    Company: 'Zk International Group Co. Ltd'
  },
  {
    Ticker: 'ZLAB',
    Company: 'Zai Lab Ltd'
  },
  {
    Ticker: 'ZM',
    Company: 'Zoom Video Communications Cl A'
  },
  {
    Ticker: 'ZN',
    Company: 'Zion Oil & Gas Inc'
  },
  {
    Ticker: 'ZNGA',
    Company: 'Zynga Inc Cl A'
  },
  {
    Ticker: 'ZS',
    Company: 'Zscaler Inc'
  },
  {
    Ticker: 'ZSAN',
    Company: 'Zosano Pharma'
  },
  {
    Ticker: 'ZUMZ',
    Company: 'Zumiez Inc'
  },
  {
    Ticker: 'ZVO',
    Company: 'Zovio Inc'
  },
  {
    Ticker: 'ZYNE',
    Company: 'Zynerba Pharma CS'
  },
  {
    Ticker: 'ZYXI',
    Company: 'Zynex Inc'
  }
];

export default TickerData;
