import React from 'react';
import { Link } from 'react-router-dom';
import './loggedOut.module.css';

const LandingPage = () => (
  <div style={{ padding: '50px' }} className="landing-page">
    <header className="header">
      <h3 style={{ fontSize: '70px' }} className="logo"></h3>
    </header>
    <main className="main-content">
      <h3 style={{ fontSize: '110px' }} className="section-title">
        What is QuantZilla?
      </h3>
      <p style={{ fontSize: '45px' }} className="section-description">
        QuantZilla is a powerful tool for visualizing trends in social data. With our intuitive
        interface and advanced analytics capabilities, you can uncover insights and make data-driven
        insights with ease.
      </p>
      <h3 style={{ fontSize: '110px', marginTop: '70px' }} className="section-title">
        Features
      </h3>
      <ul className="feature-list">
        <li className="feature">
          <h3 style={{ fontSize: '70px' }}>Real-time Sentiment Analysis Data</h3>
          <p style={{ fontSize: '45px' }} className="feature-description">
            Get instant access to real-time data from a variety of sources, including subreddits,
            twitter, financial markets, and more.
          </p>
        </li>
        <li className="feature">
          <h3 style={{ fontSize: '70px' }} className="feature-title">
            Advanced Visualization
          </h3>
          <p style={{ fontSize: '45px' }} className="feature-description">
            Create beautiful and informative charts and graphs to help you understand and
            communicate your data.
          </p>
        </li>
        <li className="feature">
          <h3 style={{ fontSize: '70px' }} className="feature-title">
            Datasets
          </h3>
          <p style={{ fontSize: '45px' }} className="feature-description">
            Organize your ideas in our exclusive dataset system. Layer the trend data we collect for
            you on top of cold hard market price action.
          </p>
        </li>
      </ul>

      <Link style={{ fontSize: '30px' }} to="/signup">
        Get Started
      </Link>
    </main>
    <footer className="footer">
      <p>Copyright 2022 QuantZilla</p>
      <nav className="footer-navigation">
        <Link style={{ fontSize: '50px', margin: '40px' }} to="/terms">
          Terms of Service
        </Link>
        <Link style={{ fontSize: '50px', margin: '40px' }} to="/privacy">
          Privacy Policy
        </Link>
      </nav>
    </footer>
  </div>
);

export default LandingPage;
