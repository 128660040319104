// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kbKHjk47OSGAHHY8G6eCUg\\=\\= {\n  width: 300px;\n  margin-top: 50px;\n}\n\n.AmW\\+dVqTPSMw-p8ZbUMikw\\=\\= {\n  border: 2px #f67d35;\n}\n", "",{"version":3,"sources":["webpack://src/frontend/components/createTrend/trends.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".eventsForm {\n  width: 300px;\n  margin-top: 50px;\n}\n\n.usersForm {\n  border: 2px #f67d35;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventsForm": "kbKHjk47OSGAHHY8G6eCUg==",
	"usersForm": "AmW+dVqTPSMw-p8ZbUMikw=="
};
export default ___CSS_LOADER_EXPORT___;
