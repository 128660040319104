import React, { useState } from 'react';
import Users from './Users';
import { Elements } from 'react-stripe-elements';
import { StripeProvider } from 'react-stripe-elements';

// wraps users component with Stripe. Important!!!
const UsersRoot = props => {
  return (
    <StripeProvider apiKey="pk_test_51JKPajJPswRDANVmJnfEOxOUTcNhkBsdjSNfnBV3QGRf0EMmWsRfw1914QAaS7tLzpJJhgddIkuD3zZ4H1DtUmS900bAhViy0n">
      <Elements>
        <Users />
      </Elements>
    </StripeProvider>
  );
};

export default UsersRoot;
