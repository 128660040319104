import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import showdown from 'showdown';
import mqtt from 'mqtt';
import { io } from 'socket.io-client';
import './terminal.module.css';
import './tachyons.module.min.css';
import './animate.min.css';
import { withStyles, makeStyles, Button } from '@material-ui/core';
import { useRefreshRate } from '../../../utils/refreshRate';

export const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = '';
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

const styles = () => ({
  closeButton: {
    float: 'left',
    backgroundColor: '#000',
    color: '#E02726',
    borderColor: '#E02726',
    fontFamily: 'Saira, sans-serif',
    marginRight: '0em',
    // marginLeft: '0.01em',
    // marginBottom: '5.2em',
    // marginTop: '0.01em',
    marginRight: '-85px',
    opacity: '0.7',
    // position: 'absolute',
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      borderColor: '#E02726',
      backgroundColor: '#E02726',
      color: '#fff',
    },
    '&:active': {
      backgroundColor: '#f26363',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  startButton: {
    float: 'left',
    backgroundColor: '#000',
    color: '#39ff14',
    borderColor: '#39ff14',
    fontFamily: 'Saira, sans-serif',
    marginRight: '0em',
    // marginLeft: '0.01em',
    // marginBottom: '5.2em',
    // marginTop: '0.01em',
    marginRight: '-85px',
    opacity: '0.9',
    // position: 'absolute',
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      borderColor: '#39ff14',
      backgroundColor: '#39ff14',
      color: '#000',
    },
    '&:active': {
      backgroundColor: '#39ff14',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
});

const converter = new showdown.Converter();

const validCmd = {
  help: `Supported commands:
  - help
  - ls
  - cat README (you're here)
  - start (starts mqtt stream)
  - stop (stops mqtt stream)
`,
  ls: 'README',
  'cat README': `Supported commands:
    - help
    - ls
    - cat README (you're here)
    - start (starts live data stream)
    - stop (stops live data stream)
  `,
  start: `Starting logger client connection...`,
  stop: `Stopping logger...`,
  done: 'Done.',
};

const startupMessages = [
  {
    content: 'Get started by typing *help*\n',
    type: 'message',
  },
];

const TerminalApp = props => {
  const { lockScroll, unlockScroll } = useScrollLock();
  const [messages, setMessages] = useState(
    props.startupMessages !== undefined ? props.startupMessages : [],
  );
  const [userInput, setUserInput] = useState(props.startupInput);
  const [updating, setUpdating] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [payload, setPayload] = useState(undefined);
  const [socketConnected, setSocketConnected] = useState(false);
  const [connection, setConnection] = useState(null);
  const [socketMessage, setSocketMessage] = useState(null);

  useEffect(() => {
    if (messages.length > 50) {
      // lockScroll();
      setMessages(messages.splice(messages.length, 50));
      // unlockScroll();
    }
  }, [messages]);

  useEffect(() => {
    connection?.on('disconnect', socket => {
      setMessages([{ content: 'disconnected', type: 'message' }]);
      // console.log('socket connected?? ::', socket.connected);
    });

    return () => {
      connection?.off(props.datasetId);
    };
  }, [connection]);

  const intervalData = useRefreshRate(socketMessage, 180);

  useEffect(() => {
    connection?.on(props.datasetId, (data, dataIndex2) => {
      // const messagesArr = messages;
      const socketMsg = {
        content: data + ' ' + dataIndex2,
        type: 'messsage',
      };

      setSocketMessage(socketMsg);
    });
    return () => {
      connection?.off(props.datasetId);
    };
  }, [connection]);

  useEffect(() => {
    if (socketMessage !== messages[messages.length - 1]) {
      // lockScroll();
      setMessages([...messages, intervalData]);
      setUserInput('');
      // unlockScroll();
    }
  }, [intervalData]);

  useEffect(() => {
    if (updating === true) {
      let duration = Math.floor(Math.random() * 300 + 1000);
      setTimeout(() => {
        setUpdating(false);
      }, duration);
    }
  }, [updating]);

  useEffect(() => {
    connection?.emit('refreshIntervalChange', props.globalRefreshState);
  }, [props.globalRefreshState]);

  const updateUserInput = input => {
    let sanitizedInput = encodeHTML(input);
    setUserInput(sanitizedInput);
  };

  const addMessages = (message, showSpinner = false) => {
    let messagesArr = messages;

    if (Array.isArray(message) == true) {
      message.map(msg => {
        messagesArr.push(msg);
      });
    } else {
      messagesArr.push(message);
    }

    setMessages(messagesArr);
    setUserInput('');
    setUpdating(showSpinner);

    // console.log('MESSAGES STATE', message);
  };

  useEffect(() => {
    if (props.started) {
      setIsStarted(true);
      initializeSocket();
      messages.push({ content: 'Done.', type: 'message' });
    } else {
      stopSocket();
      props.toggleLogger();
    }
  }, [props.started]);

  const initializeSocket = () => {
    // todo, when in PRODUCTION, make the DB create the link for the socket address for the dataset
    let socket_uri;
    if (process.env.NODE_ENV === 'production') {
      socket_uri = 'https://testing.media:3009';
    } else if (process.env.NODE_ENV === 'development') {
      socket_uri = 'http://192.168.1.65:3009';
    }
    setConnection(io(socket_uri));
    messages.push({ content: 'Connection Established', type: 'message' });
    // updateSocket();
  };

  // const updateSocket = () => {
  //   setSocketConnected(!socketConnected);
  // };

  const stopSocket = () => {
    messages.push({ content: 'Disconnected', type: 'message' });
    connection?.off(props.datasetId, connection?.disconnect);
    connection?.disconnect();
    // connection = null;
    setConnection(null);
    setIsStarted(false);
  };

  const doCommand = async (cmd, socketMessage, errorMessage) => {
    let messages = [{ content: cmd, type: 'command' }],
      showSpinner = false,
      responseIndex = Object.keys(validCmd).indexOf(cmd);

    if (responseIndex !== -1 && !socketMessage && !errorMessage) {
      let i = Object.keys(validCmd)[responseIndex];
      messages.push({ content: validCmd[i], type: 'message' });
      showSpinner = true;
    } else {
      messages.push({ content: cmd, type: 'error' });
    }

    if (cmd === 'clear') {
      setMessages([]);
      setUserInput('');
      return;
    } else if (cmd === 'start') {
      setIsStarted(true);
      initializeSocket();
      messages.push({ content: 'Done.', type: 'message' });
    } else if (cmd === 'stop') {
      stopSocket();
      messages.push({ content: 'Done.', type: 'message' });
    }

    addMessages(messages, showSpinner);
  };

  const started = connection;
  return (
    <TerminalUI
      connected={!!connection}
      stopSocket={stopSocket}
      startSocket={initializeSocket}
      setIsStarted={setIsStarted}
      classes={props.classes}
      input={userInput}
      messages={messages}
      updating={updating}
      updateUserInput={updateUserInput}
      doCommand={doCommand}
    />
  );
};

TerminalApp.propTypes = {
  datasetId: PropTypes.string,
  started: PropTypes.bool,
  toggleLogger: PropTypes.bool,
};

class TerminalUI extends React.Component {
  focusElement() {
    this.messagesInput.focus();
  }

  handleReturn(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      const node = ReactDOM.findDOMNode(this.messagesInput);
      this.props.doCommand(node.value);
    }
  }

  updateMessageOutput(event) {
    var node = ReactDOM.findDOMNode(this.messagesInput);
    this.props.updateUserInput(node.value);
  }

  render() {
    return (
      <div
        className="bg-black green center h5 pa3 overflow-x-hidden overflow-y-scroll"
        style={{
          height: '500px',
          width: 'auto',
          maxHeight: '300px',
          maxWidth: 'auto',
          marginBottom: '40px',
          boxShadow: '-10px -10px 22px -5px black',
          border: '2px solid white',
          borderRadius: '7px',
          backgroundColor: 'black',
          overflowY: 'scroll',
          overflowX: 'hidden',
          padding: '10px',
        }}
        onClick={this.focusElement.bind(this)}
      >
        {this.props.connected ? (
          <Button
            variant="outlined"
            size="small"
            className={this.props.classes.closeButton}
            onClick={() => this.props.stopSocket()}
          >
            Disconnect
          </Button>
        ) : (
          <Button
            variant="outlined"
            size="small"
            className={this.props.classes.startButton}
            onClick={() => {
              this.props.startSocket();
              this.props.setIsStarted(true);
            }}
          >
            CONNECT
          </Button>
        )}
        <pre
          className="border-box h-100 lh-copy ma0"
          style={{ height: '95%', width: '100%', color: '#39ff14', fontSize: '11px' }}
        >
          <TerminalHistory messages={this.props.messages} updating={this.props.updating} />
          <TerminalInput input={this.props.input} />
        </pre>

        <input
          type="text"
          value={this.props.input}
          ref={el => {
            this.messagesInput = el;
          }}
          onChange={this.updateMessageOutput.bind(this)}
          onKeyDown={this.handleReturn.bind(this)}
          className="absolute absolute-fill"
          style={{ zIndex: -1, opacity: 0 }}
        />
      </div>
    );
  }
}

class TerminalHistory extends React.Component {
  componentDidUpdate() {
    // this.scrollToBottom();
  }

  scrollToBottom() {
    const node = ReactDOM.findDOMNode(this.messagesEnd);
    node.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    return (
      <div className={'shidd'}>
        {this.props.children}
        {this.props.messages?.map((message, i) => {
          if (this.props.updating === true && i === this.props.messages.length - 1) {
            return <span className="dib loading-stepped">|</span>;
          }
          if (message?.type == 'command') {
            return <TerminalCommand message={message?.content} />;
          } else if (message?.type == 'error') {
            return <TerminalError message={message?.content} />;
          } else {
            return <TerminalMessage message={message?.content} />;
          }
        })}
        <div
          ref={el => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}

class TerminalMessage extends React.Component {
  render() {
    let message = converter.makeHtml(encodeHTML(this.props.message));
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: message }}></span>
      </div>
    );
  }
}

class TerminalError extends React.Component {
  render() {
    return (
      <div className="flex flex-row db monospace w-100">
        <span className="dib pr5 w1" style={{ flexBasis: '58px' }}>
          ~ QuantZilla:{' '}
        </span>
        <span className="flex-auto">
          {this.props.message}: command not found
          <br />
          type &quot;help&quot; to see options
        </span>
      </div>
    );
  }
}

class TerminalCommand extends React.Component {
  render() {
    return (
      <div className="flex flex-row db monospace w-100">
        <span className="dib pr2 w1" style={{ flexBasis: '4px' }}>
          $
        </span>
        <span className="flex-auto">{this.props.message}</span>
      </div>
    );
  }
}

class TerminalInput extends React.Component {
  render() {
    return (
      <div className="flex flex-row flex-column  db monospace w-100">
        <span className="dib pr2" style={{ flexBasis: '4px' }}>
          $
        </span>
        <span>{this.props.input}</span>
        <span className="animated infinite flash">|</span>
      </div>
    );
  }
}

function encodeHTML(s) {
  if (s) {
    let str = s;
    str = str.replace(/&/g, '&amp;');
    str = str.replace(/>/g, '&gt;');
    str = str.replace(/</g, '&lt;');
    str = str.replace(/"/g, '&quot;');
    str = str.replace(/'/g, '&#039;');
    return str;
  }
}

export default compose(
  withStyles(styles),
  connect(
    //mapStateToProps
    // eslint-disable-next-line no-unused-vars
    (state, ownProps) => ({
      // application-wide login auth state
      globalRefreshState: state.ux.globalRefreshState || 1000,
      globalTimeInterval: state.ux.globalTimeInterval,
    }),
    // mapDispatchToProps
    // eslint-disable-next-line no-unused-vars
    (dispatch, ownProps) => ({
      // setGlobalRefreshState: (refresh) => {
      //   dispatch(setGlobalRefreshStateAction(refresh));
      // },
      // setGlobalTimeInterval: (interval) => {
      //   dispatch(setGlobalTimeIntervalAction(interval));
      // },
    }),

    //mergeProps
    (stateProps, dispatchProps, ownProps) => {
      return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
      };
    },
  ),
)(TerminalApp);
