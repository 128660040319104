import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button } from 'semantic-ui-react';

const LinkButton = props => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    title,
    color,
    inverted,
    size,
    compact,
    animated,
    style,
    ...rest
  } = props;
  return (
    <Button
      {...rest} // `children` is just another prop!
      onClick={event => {
        onClick && onClick(event);
        history.push(to);
      }}
      size={size}
      inverted={inverted}
      compact={compact}
      color={color}
      animated={animated}
      style={style}
    >
      {title}
    </Button>
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default withRouter(LinkButton);
