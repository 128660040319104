export default class StateLoader {
  loadState() {
    try {
      let serializedState = localStorage.getItem('http://webengine.io:state');
      if (serializedState === null) {
        return this.initializeState();
      }
      return JSON.parse(serializedState);
    } catch (err) {
      console.error('Error loading state...', err);
      return this.initializeState();
    }
  }

  saveState(state) {
    try {
      let serializedState = JSON.stringify(state);
      localStorage.setItem('http://webengine.io:state', serializedState);
    } catch (err) {
      console.error('Error rehydrating state...', err);
    }
  }

  initializeState() {
    return {
      //initial empty state object
    };
  }
}
