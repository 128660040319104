// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ueq86QVLQr1dO9zrwOyZVg\\=\\= {\n  background: #fff;\n}\n", "",{"version":3,"sources":["webpack://src/frontend/components/dashboard/stockCharts/chart.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":[".dark {\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dark": "ueq86QVLQr1dO9zrwOyZVg=="
};
export default ___CSS_LOADER_EXPORT___;
