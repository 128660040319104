import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Form, Loader, Accordion, Icon, Modal, Header } from 'semantic-ui-react';
import classNames from 'classnames';
import { compose } from 'react-apollo';
import { injectStripe } from 'react-stripe-elements';
import { CardElement } from 'react-stripe-elements';
import withUsers from '../hoc/withUsers.js';
import withSnackbar from '../hoc/withSnackbar';
import { Redirect } from 'react-router-dom';
import classes from './users.module.css';
import Signup from './Signup';

class Users extends Component {
  static propTypes = {
    user: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
    insertUser: PropTypes.func.isRequired,
    snackbar: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      userId: null,
      activeIndex: '',
      cancelAccountModalOpen: false,
      token: '',
      redirectToHome: false,
      redirectToLogInSignUp: false,
      planId: null,
      phoneNumber: null,
    };
  }

  componentDidMount() {}

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  signup = async plan => {
    const { name, email, password, phoneNumber } = this.state;

    if (name.length === 0 || email.length === 0 || password.length === 0) {
      this.props.snackbar('blank-fields-signin', 'warning');
    } else {
      this.props.snackbar('logging-in-please-wait', 'info');
      const stripeToken = await this.props.stripe.createToken({
        name: name,
      });
      // // console.log('stripe token', stripeToken.token.id);
      this.props.snackbar('almost-done', 'warning');

      await this.props
        .insertUser(name, email, password, stripeToken.token.id, this.state.planId, phoneNumber)
        .then(response => {
          if (response) {
            console.error('Insert User Function Response -> ', response);
          }
          localStorage.setItem('token', response.insertUser.token);
          // // console.log('response insert User', response);
          this.setState({
            token: response.insertUser.token,
            redirectToHome: true,
          });

          this.props.logIn(true, response.insertUser.id);
          this.props.snackbar('thank-you-creating-account', 'success');
        });
    }
  };

  logOut = async () => {
    await this.props.logOut();
    this.props.snackbar('logged-out-successfully', 'error');
  };

  toggleCancelModal = () => {
    this.setState(prevState => ({ cancelAccountModalOpen: !prevState.cancelAccountModalOpen }));
  };

  logOutSignOut = async () => {
    await this.props.logOut();
    this.props.snackbar('logged-out-successfully-cancelled-account', 'error');
  };

  handleCancelAccount = async () => {
    await this.props.cancelAccount(this.state.email, this.state.password).then(response => {
      if (response) {
        this.toggleCancelModal();
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('userId');
      }
    });
    this.logOutSignOut();
  };

  changeName = value => {
    this.setState({ name: value });
  };

  changeEmail = value => {
    this.setState({ email: value });
  };

  changePassword = value => {
    this.setState({ password: value });
  };

  selectPlan = planId => {
    this.setState({ planId: planId });
  };

  changePhoneNumber = phoneNumber => {
    this.setState({ phoneNumber: phoneNumber });
  };

  render() {
    // // console.log('props users', this.props);
    const { name, email, password, activeIndex, phoneNumber } = this.state;

    const { redirectToHome, redirectToLogInSignUp } = this.state;
    if (redirectToHome) {
      return <Redirect to="/" />;
    }
    if (redirectToLogInSignUp) {
      return <Redirect to="/signup" />;
    }

    return (
      <div>
        {!this.props.isLoggedIn && (
          <Signup
            name={name}
            changeName={this.changeName}
            email={email}
            changeEmail={this.changeEmail}
            password={password}
            changePassword={this.changePassword}
            signup={this.signup}
            planId={this.state.planId}
            selectPlan={this.selectPlan}
            phoneNumber={phoneNumber}
            onChangePhoneNumber={this.changePhoneNumber}
          />
        )}

        {this.props.isLoadingUser && (
          <div className={classes.formDiv}>
            <Loader active inline="centered" />
          </div>
        )}
        {this.props.isLoadingUser === 'done' && (
          <div>
            <div className={classes.centeredDiv}>
              {this.props.isLoggedIn && (
                <p style={{ textAlign: 'center' }}>
                  Successfully Signed in. <br />
                  <Link style={{ textAlign: 'center' }} to="/mytrends">
                    Go To Trends
                  </Link>
                </p>
              )}
              {!this.props.isLoggedIn && (
                <p style={{ textAlign: 'center' }}>
                  You have cancelled your account. Resubscribe <br />
                  <a style={{ textAlign: 'center' }} href="/signup">
                    Here
                  </a>
                </p>
              )}
            </div>
          </div>
        )}

        {this.props.isLoggedIn && (
          <div className={classes.usersForm}>
            <h2>Sign Out</h2>
            <Button onClick={this.logOut}>Sign Out</Button>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleAccordionClick}
              >
                <Icon name="dropdown" />
                Additional Settings
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <Button color="youtube" onClick={this.toggleCancelModal}>
                  Cancel Account
                </Button>
              </Accordion.Content>
            </Accordion>

            <Modal
              size="tiny"
              dimmer={true}
              open={this.state.cancelAccountModalOpen}
              onClose={this.toggleCancelModal}
            >
              <Modal.Header>Cancel Account</Modal.Header>
              <Modal.Content scrolling>
                <div>
                  <Modal.Description>
                    <Header>Are you sure you want to cancel your subscription?</Header>
                  </Modal.Description>
                </div>

                <Form className={classes.usersForm}>
                  <h4>Type Your Email and Password to cancel your subscription</h4>
                  <Form.Field>
                    <label>Email</label>
                    <input
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                      type="text"
                      placeholder="Last Name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Password</label>
                    <input
                      value={password}
                      onChange={e => this.setState({ password: e.target.value })}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Field>
                  <Button
                    color={'red'}
                    onClick={e => {
                      this.handleCancelAccount();
                    }}
                  >
                    CANCEL SUBSCRIPTION
                  </Button>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon="question"
                  labelPosition="right"
                  content="Nevermind :)"
                  onClick={e => this.toggleCancelModal()}
                />
              </Modal.Actions>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withSnackbar, withUsers)(injectStripe(Users));
