import React, { useEffect, useState } from 'react';
import Chart from './Chart';
import equal from 'fast-deep-equal/react';

// import { getData } from './utils';

const ChartComponent = props => {
  if (props.data.length === 0) {
    return <div>select a ticker?</div>;
  }

  // key is needed to re-render the child component on props change as its a child of the data source
  // this wrapper is needed because of the this.container internal reference
  // in the chart component gets screwy for some unknown reason
  return (
    <Chart
      key={JSON.stringify(props)}
      type={'hybrid'}
      data={props.data}
      width={props.width}
      switches={props.switches}
    />
  );
};

export default ChartComponent;
