import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputTextEditor = (props, ref) => {
  const [tempText, setTempText] = useState(props.text);
  const [isTextDiffers, setIsTextDiffers] = useState(false);

  const activateEditing = event => {
    event.nativeEvent.stopImmediatePropagation();
    props.onEditStart && props.onEditStart();
  };

  const onInputTextDiffers = currentText => {
    const currTextDiffers = currentText !== props.text;

    if (isTextDiffers !== currTextDiffers) {
      setIsTextDiffers(currTextDiffers);
      props.onTextDiffers && props.onTextDiffers(currTextDiffers);
    }
  };

  const onTextChange = event => {
    event.nativeEvent.stopImmediatePropagation();
    !props.waitMode && setTempText(event.target.value);

    onInputTextDiffers(event.target.value);
  };

  if (!props.editMode) {
    tempText !== props.text && setTempText(props.text);
    isTextDiffers && setIsTextDiffers(false);
  }

  return (
    <textarea
      ref={ref}
      placeholder={props.placeholder}
      onClick={activateEditing}
      value={props.editMode || props.waitMode ? tempText : props.text}
      onChange={onTextChange}
      className={classNames(props.textAreaClass, props.editMode && props.editModeClass)}
      disabled={props.disabled}
      onBlur={props.onBlur}
    />
  );
};

const InputTextEditorForwardRef = React.forwardRef(InputTextEditor);

InputTextEditor.propTypes = {
  editMode: PropTypes.bool,
  waitMode: PropTypes.bool,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  onEditStart: PropTypes.func,
  onTextDiffers: PropTypes.func,
  textAreaClass: PropTypes.string,
  editModeClass: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
};

InputTextEditor.defaultProps = {
  editMode: false,
  placeholder: '',
  text: '',
};

export default InputTextEditorForwardRef;
