import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import CREATE_DATASET_MUTATION from '../graphql/mutations/createDataset.graphql';
import GET_SUBREDDITS from '../graphql/queries/getSubReddits.graphql';
import { Dropdown } from 'semantic-ui-react';
import withSnackbar from '../hoc/withSnackbar';
import graphqlFunctional from '../../utils/graphqlFunctional';
import _ from 'lodash';

class Reddit extends Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool,
    snackbar: PropTypes.func.isRequired,
    refetchDatasets: PropTypes.func.isRequired,
    createDataset: PropTypes.func.isRequired,
  };

  state = {
    loadingSubRedditSearch: null,
    options: null,
    dropdownValue: [],
  };

  handleSearchChange = async (e, { searchQuery }) => {
    const results = await graphqlFunctional.query({
      query: GET_SUBREDDITS,
      fetchPolicy: 'network-only',
      variables: {
        queryString: searchQuery,
      },
    });
    if (results.loading) {
      this.setState({ loadingSubRedditSearch: true });
    }
    // console.log('results', results.data.getSubReddits);
    const options = results.data.getSubReddits.map(item => {
      return { key: item, text: item, value: _.snakeCase(item) };
    });
    this.setState({ loadingSubRedditSearch: false, options: options });
    return results.data.getSubReddits;
  };

  handleDropdownChange = (e, { value }) => {
    this.setState({ dropdownValue: value });
  };

  render() {
    // console.log('state reddit component', this.state);
    return (
      <div style={{ marginRight: '5%' }}>
        <Dropdown
          fluid
          selection
          // multiple
          search
          options={this.state.options}
          value={this.state.dropdownValue}
          placeholder="Search for a sub-Reddit"
          onChange={this.handleDropdownChange}
          onSearchChange={this.handleSearchChange}
          disabled={this.state.isFetching}
          loading={this.state.isFetching}
        />
      </div>
    );
  }
}

export default compose(
  withSnackbar,
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const { ...restDispatchProps } = dispatchProps;
      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
      };
    },
  ),
  graphql(CREATE_DATASET_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        createDataset: async (userId, name, details, trendIds, trackedOutlets) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                name: name,
                details: details,
                trendIds: trendIds,
                trackedOutlets: trackedOutlets,
              },
            });
            await ownProps.snackbar('create-dataset-success', 'success');
            return response;
          } catch (e) {
            await ownProps.snackbar('create-dataset-error', 'success');
            console.error(e.message);
          }
        },
      };
    },
  }),
)(Reddit);
