import React, { Component } from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import classes from './navbar.module.css';

class Navbar extends Component {
  static propTypes = {
    user: PropTypes.string,
  };

  render() {
    return (
      <div>
        <div className={classes.area} />
        <nav className={classes.mainMenu}>
          <ul>
            <li
              className={classNames(this.props.location.pathname === '/' ? classes.liSelected : '')}
            >
              <Link to="/">
                <Icon name="home" size="big" className={classes.icon} />
                <span className={classes.navText}>Home</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/mytrends' ? classes.liSelected : '',
              )}
            >
              <Link to="/mytrends">
                <Icon name="file" size="big" className={classes.icon} />
                <span className={classes.navText}>Tracking</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/datasets' ? classes.liSelected : '',
              )}
            >
              <Link to="/datasets">
                <Icon name="folder" size="big" className={classes.icon} />
                <span className={classes.navText}>Datasets</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/Dashboard' ? classes.liSelected : '',
              )}
            >
              <Link to="/Dashboard">
                <Icon name="dashboard" size="big" className={classes.icon} />
                <span className={classes.navText}>Graph Board</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/discoverTrends' ? classes.liSelected : '',
              )}
            >
              <Link to="/discoverTrends">
                <Icon name="searchengin" size="big" className={classes.icon} />
                <span className={classes.navText}>Discover Trends</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/newtrend' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="plus square" size="big" className={classes.icon} />
                <span className={classes.navText}>Add a Keyword</span>
              </Link>
            </li>

            {/* ____________________________________________________________________________________________ */}

            <br />
            <br />

            <li
              className={classNames(
                this.props.location.pathname === '/reddit' ? classes.liSelected : '',
              )}
            >
              <Link to="/reddit">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Sub-Reddit Data</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Wikipedia Data</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>r/Crypto</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>r/wallstreetbets</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>New laws and bills being passed</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Government Spending</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Feer and Greed Index</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Corporate Lobbying</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Politician Trading Data</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Inflation Metrics</span>
              </Link>
            </li>

            <li
              className={classNames(
                this.props.location.pathname === '/pee' ? classes.liSelected : '',
              )}
            >
              <Link to="/newtrend">
                <Icon name="question" size="big" className={classes.icon} />
                <span className={classes.navText}>Lobbying and Govt. Bills Correlation</span>
              </Link>
            </li>
          </ul>

          <ul className={classes.logout}>
            <li
              className={classNames(
                this.props.location.pathname === '/signup' ? classes.liSelected : '',
              )}
            >
              <Link to="/signup">
                <Icon name="settings" size="big" className={classes.icon} />
                <span className={classes.navText}>Settings</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default compose(withRouter)(Navbar);
