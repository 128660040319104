import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import './AutoSuggest.module.css';
import TickerData from './tickers.js';
import { Client } from 'iexjs';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

class AutoSuggestTickers extends Component {
  static propTypes = {
    updateStats: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = suggestion => {
    if (suggestion.isAddNew) {
      return this.state.value;
    }
    return suggestion.Ticker;
  };

  renderSuggestion = suggestion => {
    if (suggestion.isAddNew) {
      return (
        <span>
          [+] Add new: <strong>{this.state.value}</strong>
        </span>
      );
    }
    return suggestion.Ticker;
  };

  returnCryptoSymbols = async () => {
    const token = 'pk_71dba0269bf7441ebfd3890b5a712d65';
    const version = 'v1';
    const client = new Client({ api_token: token, version: version });
    return await client.cryptoSymbolsList();
  };

  componentDidMount = async () => {
    const cryptoSymbols = await this.returnCryptoSymbols();
    this.setState({ cryptoSymbols: cryptoSymbols });
  };

  getSuggestions = async value => {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const { cryptoSymbols } = this.state;
    // // console.log('cryptosymbols', cryptoSymbols);

    for (const value of cryptoSymbols) {
      TickerData.push({ Ticker: value, Company: value });
    }

    // // console.log('TickerData', TickerData);

    const regex = new RegExp('^' + escapedValue, 'i');
    const suggestions = TickerData.filter(tick => regex.test(tick.Ticker));

    if (suggestions.length === 0) {
      return [{ isAddNew: true }];
    }

    // // console.log('suggestions', suggestions);
    return suggestions;
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    const suggies = await this.getSuggestions(value);
    this.setState({
      suggestions: suggies,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.isAddNew) {
      // console.log('Add new:', this.state.value);
    }
    const isCrypto = this.state.cryptoSymbols.includes(suggestion.Ticker);
    this.props.selectSuggestion(suggestion.Ticker, isCrypto);
    // this.props.updateStats(this.state.value);
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search a Stock Ticker',
      value: value,
      onChange: this.onChange,
    };

    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          inputProps={inputProps}
        />
        <style>{`
          .react-autosuggest__container {
            position: relative;
          }

          .react-autosuggest__input {
            width: 240px;
            height: 30px;
            padding: 10px 20px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 16px;
            border: 1px solid #aaa;
            border-radius: 4px;
            -webkit-appearance: none;
          }

          .react-autosuggest__input--focused {
            outline: none;
          }

          .react-autosuggest__input::-ms-clear {
            display: none;
          }

          .react-autosuggest__input--open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          .react-autosuggest__suggestions-container {
            display: none;
          }

          .react-autosuggest__suggestions-container--open {
            display: block;
            position: relative;
            top: -1px;
            width: auto;
            border: 1px solid #aaa;
            background-color: #fff;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 16px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            z-index: 2;
          }

          .react-autosuggest__suggestions-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
          }

          .react-autosuggest__suggestion {
            cursor: pointer;
            padding: 10px 20px;
          }

          .react-autosuggest__suggestion--highlighted {
            background-color: #ddd;
          }
        `}</style>
      </div>
    );
  }
}

export default AutoSuggestTickers;
