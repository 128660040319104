import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import withTrends from '../hoc/withTrends.js';
import withUsers from '../hoc/withUsers';
import { Link } from 'react-router-dom';
import { Button, Form, Message, Card, Grid, Accordion, Icon } from 'semantic-ui-react';
import axios from 'axios';
import classes from './trends.module.css';

class AddKeyword extends Component {
  static propTypes = {
    currentUser: PropTypes.string,
    trends: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        userId: PropTypes.string,
        name: PropTypes.string,
        details: PropTypes.string,
        date: PropTypes.string,
      }),
    ),
    insertTrend: PropTypes.func.isRequired,
    // refetchTrends: PropTypes.func,
    // loadingTrends: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      details: '',
      location: '',
      trendCreatedNotification: false,
      ticker: 'AAPL',
    };
  }

  componentDidMount() {
    this.getDropdownLinks();
  }

  getDropdownLinks = async () => {
    const req = {
      ticker: this.state.ticker,
    };
    let uri;
    if (process.env.NODE_ENV === 'production') {
      uri = 'https://testing.media:3001';
    } else if (process.env.NODE_ENV === 'development') {
      uri = 'http://localhost:3001';
    }
    const response = await axios.post(`${uri}/api/stocks/getStockNews`, req);
    let arr = [];
    response.data.map(mod => {
      arr.push({
        source: mod.source,
        headline: mod.headline,
        summary: mod.summary,
        url: mod.url,
        image: mod.image,
      });
      return null;
    });

    this.setState({ dropdownLinks: arr });
  };

  handleLinkClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeLinkIndex } = this.state;
    const newIndex = activeLinkIndex === index ? -1 : index;
    this.setState({ activeLinkIndex: newIndex });
  };

  createTrend = () => {
    const { name, details, date, location } = this.state;
    this.props.insertTrend(this.props.currentUser, name, details, new Date()).then(response => {
      this.setState(prevState => ({
        response: response,
        trendCreatedNotification: !prevState.trendCreatedNotification,
      }));
      // console.log('Create Keyword response: ', response);
    });
    this.props.refetchTrends();
  };

  handleDismiss = () => {
    this.setState(prevState => ({
      trendCreatedNotification: !prevState.trendCreatedNotification,
    }));
  };

  render() {
    // // console.log('props trends', this.props);
    const { name, details, date } = this.state;
    return (
      <Grid divided="vertically" style={{ maxHeight: '100%', maxWidth: '98%' }}>
        <h4>Add a keyword</h4>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Card style={{ padding: '15px' }}>
              <Form className={classes.usersForm}>
                <h2>Add A Keyword</h2>
                <Form.Field>
                  <label>Keyword:</label>
                  <input
                    value={name}
                    onChange={e => this.setState({ name: e.target.value })}
                    type="text"
                    placeholder="Name"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Details / Notes</label>
                  <textarea
                    value={details}
                    onChange={e => this.setState({ details: e.target.value })}
                    type="text"
                    placeholder="Add some notes here for you to remember later"
                  />
                </Form.Field>
                <Button style={{ float: 'right' }} onClick={this.createTrend}>
                  Submit
                </Button>
                {this.state.trendCreatedNotification && (
                  <Message onDismiss={this.handleDismiss} header="Keyword Created!">
                    Keyword added! View it <Link to="/">here</Link>
                  </Message>
                )}
              </Form>
            </Card>
          </Grid.Column>
          <Grid.Column>
            {/* <Accordion fluid style={{ backgroundColor: 'black' }} inverted styled>
              {this.state.dropdownLinks &&
                this.state.dropdownLinks.map((link, index) => (
                  <div key={index}>
                    <Accordion.Title
                      active={activeLinkIndex === index}
                      index={index}
                      onClick={this.handleLinkClick}
                    >
                      <Icon name="dropdown" />
                      {link.headline}
                    </Accordion.Title>
                    <Accordion.Content active={activeLinkIndex === index}>
                      <a href={link.url} target="_blank">
                        {'Link ->'}
                      </a>
                      <p style={{ color: '#39FF14', fontweight: '900' }}>Source: ({link.source})</p>
                      <p style={{ color: '#39FF14', fontweight: '900' }}>{link.summary}</p>
                    </Accordion.Content>
                  </div>
                ))}
            </Accordion> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default compose(withTrends, withUsers)(AddKeyword);
