import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { Grid, Button, Icon } from 'semantic-ui-react';
import DashboardItem from './DashboardItem';
import {
  setDashboardItem as setDashboardItemAction,
  removeDashboardItem as removeDashboardItemAction,
  removeAllDashboardItems as removeAllDashboardItemsAction,
} from '../redux/actions';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        tooltips: {
          mode: 'index',
          axis: 'y',
          intersect: true,
        },
        hover: {
          axis: 'x',
          intersect: false,
          mode: 'index',
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              minBarLength: 1,
              display: false,
              ticks: {
                stepSize: 1,
              },
              beginAtZero: false,
            },
          ],
          xAxes: [
            {
              ticks: {
                stepSize: 5,
                min: 0,
                beginAtZero: false,
              },
            },
          ],
        },
        maintainAspectRatio: true,
      },
      dashboardItems: 1,
    };
  }

  // getRandomArray = () => {
  //   if (this.randomArray && this.randomArray.length === 10) {
  //     return this.randomArray;
  //   }

  //   const length = //insert an array with any length here

  //   this.randomArray = [];

  //   for (let i = 0; i < 10; i++) {
  //     let randomNo = getRandomInt(0, length - 1);
  //     while (this.randomArray.includes(randomNo)) {
  //       randomNo = getRandomInt(0, length - 1);
  //     }
  //     this.randomArray.push(randomNo);
  //   }
  //   return this.randomArray;
  // };

  updateStats = () => {
    this.setState({
      datasetsBar: {
        labels: [
          '7wks ago',
          '6wks ago',
          '5wks ago',
          '4wks ago',
          '3wks ago',
          '2wks ago',
          'last week',
          'this week',
        ],
        datasets: [
          {
            label: '# Datasets Per Wk',
            backgroundColor: '#1b91f2',
            borderColor: '#1b91f2',
            borderWidth: 2,
            hoverBackgroundColor: '#2ecc71',
            hoverBorderColor: '#2ecc71',
            data: [32, 43, 432, 12, 14, 56, 75, 80],
          },
        ],
      },
    });
  };

  formatNumeral(x) {
    return numeral(x).format('0[.]0 a');
  }

  addDashboardItem = () => {
    let items = this.state.dashboardItems;
    items++;
    this.setState({ dashboardItems: items });
  };

  removeDashboardItem = () => {
    let items = this.state.dashboardItems;
    items--;
    this.setState({ dashboardItems: items });
  };

  renderDashboardItems = () => {
    let items = [];
    for (let i = 0; i < this.state.dashboardItems; i++) {
      items.push(
        <Grid.Column key={i}>
          <DashboardItem
            dashboardItemIndex={i}
            numberOfDashboardItems={this.state.dashboardItems}
            options={this.state.options}
            chartData={this.state.datasetsBar}
          />
          <hr />
          <br />
        </Grid.Column>,
      );
    }
    return items;
  };

  componentWillUnmount = async () => {
    await this.props.removeAllDashboardItems();
  };

  render() {
    // console.log('dashboard items from dashboard parent: ', this.props.dashboardItems);
    return (
      <div>
        <Button onClick={() => this.addDashboardItem()}>
          <Icon name="plus" />
          add graph
        </Button>
        <Button onClick={() => this.removeDashboardItem()}>
          <Icon name="minus" />
          remove graph
        </Button>
        <Grid
          stackable
          columns={this.state.dashboardItems <= 3 ? this.state.dashboardItems : 3}
          divided
          style={{
            justifyContent: 'center',
            paddingLeft: '1em',
            paddingRight: '1em',
            paddingTop: '2em',
          }}
        >
          <Grid.Row>{this.renderDashboardItems().map(item => item)}</Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default compose(
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
      cachedDatasets: state.ux.setCachedDatasets,
      dashboardItems: state.ux.dashboardItems.dashboardItems,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add in dispatch action functions here
      setDashboardItem: item => dispatch(setDashboardItemAction(item)),
      removeDashboardItem: item => dispatch(removeDashboardItemAction(item)),
      removeAllDashboardItems: item => dispatch(removeAllDashboardItemsAction(item)),
    }),
  ),
)(Dashboard);
