import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TrendModal from '../modal/TrendModal';
import TrendCard from '../cards/TrendCard';
import DeleteModal from '../modal/DeleteModal';
import { Grid, Loader, Pagination, Icon } from 'semantic-ui-react';

// fun: add news ticker with togglable categories / topics::
// https://github.com/ahmetcanaydemir/react-advanced-news-ticker

class AllTrends extends Component {
  static propTypes = {
    density: PropTypes.number,
    expandTrend: PropTypes.func,
    allTrends: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        name: PropTypes.string,
        details: PropTypes.string,
        date: PropTypes.string,
        // location: PropTypes.string,
      }),
    ),
    filter: PropTypes.bool,
    refetchAllTrends: PropTypes.func.isRequired,
    loadingAllTrends: PropTypes.bool,
    refetchTrends: PropTypes.func,
    trendCreatedNotification: PropTypes.shape({
      on: PropTypes.bool,
      id: PropTypes.string,
    }),
    handleDismissMessage: PropTypes.func,
    sortedPopularTrendCountNextTwoPages: PropTypes.number,
  };

  static defaultProps = {
    page: 1,
  };

  constructor(props) {
    super(props);
    this.state = {
      trendModalOpen: false,
      deleteModalOpen: false,
      trendForModal: {},
      trendCreatedNotification: false,
      transition: false,
    };
  }

  componentDidMount = async () => {
    await this.props.deselectDatasets();
  };

  componentDidUpdate = async () => {
    if (this.props.setRefetchTrends) {
      await this.props.refetchAllTrends();
      await this.props.cancelRefetchTrends();
    }
  };

  toggleTrendModal = trend => {
    this.setState(prevState => ({
      trendForModal: trend,
      trendModalOpen: !prevState.trendModalOpen,
    }));
  };

  toggleDeleteModal = trend => {
    this.setState(prevState => ({
      trendForModal: trend,
      deleteModalOpen: !prevState.deleteModalOpen,
    }));
  };

  rsvp = async id => {
    await this.props.rsvp(id);
    await this.props.refetchAllTrends();
    await this.props.snackbar('subscribe-to-trend-success', 'success');
    this.setState({ transition: true });
  };

  stopTracking = async (currentUser, trendId) => {
    await this.props.stopTracking(currentUser, trendId);
    await this.props.deselectTrends();
    await this.props.refetchAllTrends();
    await this.props.snackbar('stopped-tracking-trend', 'info');
  };

  deleteTrend = async (currentUser, trendId) => {
    await this.props.deleteTrend(currentUser, trendId);
    await this.props.deselectTrends();
    await this.props.refetchAllTrends();
    await this.props.snackbar('delete-trend-success', 'error');
  };

  handleTrendClick = async (selectedTrend, event) => {
    event.stopPropagation();
    const id = selectedTrend.id;
    if (this.props.selectedTrendIds.length === 0 || !event.shiftKey) {
      this.props.handleTrendItemClick(selectedTrend, event);
    } else {
      let selectedTrends = [];
      let backSet = [];
      let selectionState = 0;
      this.props.allTrends.forEach(trend => {
        // eslint-disable-next-line default-case
        switch (selectionState) {
          // find for start of selection
          case 0:
            if (trend.isSelected) {
              selectionState = 1;
            }
            if (trend.id === id) {
              selectedTrends.push(trend);
              selectionState = 2;
            }
            if (trend.isSelected && trend.id === id) {
              selectionState = 3;
            }
            break;
          // look for click
          case 1:
            selectedTrends.push(trend);
            if (trend.id === id) {
              selectionState = 3;
            }
            break;
          // look for end of selection
          case 2:
            if (trend.isSelected === false) {
              backSet.push(trend);
              selectionState = 3;
              return;
            }
            selectedTrends.push(trend);
            break;
          // selection done but keep track of trends in case user selecting backwards right to left
          case 3:
            backSet.push(trend);
            if (trend.isSelected) {
              selectedTrends = selectedTrends.concat(backSet);
              backSet = [];
            }
        }
      });

      // dont add ids that already exist in selected trends:
      selectedTrends = selectedTrends.filter(trend => {
        return this.props.selectedTrendIds.indexOf(trend.id) === -1;
      });
      this.props.handleShiftClick(selectedTrends);
    }
  };

  getTotalPages() {
    const itemsNextTwoPages = this.props.sortedPopularTrendCountNextTwoPages;
    const pagesLeftCount = itemsNextTwoPages / 15;
    let pagesLeft = 0;

    if (pagesLeftCount > 1) {
      pagesLeft = 2;
    }
    if (pagesLeftCount < 1) {
      pagesLeft = 1;
    }
    if (pagesLeftCount === 1) {
      pagesLeft = 1;
    }
    if (pagesLeftCount === 0) {
      pagesLeft = 0;
    }
    if (itemsNextTwoPages < 15) {
      pagesLeft = 0;
    }
    return this.props.page + pagesLeft;
  }

  render() {
    console.log(
      'sortedPopularTrendCountNextTwoPages',
      this.props.sortedPopularTrendCountNextTwoPages,
    );

    return (
      <React.Fragment>
        <Grid.Row columns={this.props.density || 5} style={{ justifyContent: 'center' }}>
          <React.Fragment>
            {/* iterate over available trends here */}
            {this.props.loadingAllTrends ||
              (this.props.allTrends.length === 0 && (
                <Loader name="spinner" active content="Loading" />
              ))}

            {!this.props.loadingAllTrends &&
              this.props.allTrends &&
              this.props.allTrends.map(trend => (
                <Grid.Column key={trend.id}>
                  <TrendCard
                    trend={trend}
                    selectTrend={this.handleTrendClick}
                    deselectTrends={this.props.deselectTrends}
                    isSelected={trend.isSelected}
                    loadingAllTrends={this.props.loadingAllTrends}
                    toggleTrendModal={e => this.toggleTrendModal(trend)}
                    toggleDeleteModal={e => this.toggleDeleteModal(trend)}
                    stopTracking={this.stopTracking}
                    rsvp={this.rsvp}
                  />
                  <br />
                </Grid.Column>
              ))}

            {this.state.trendForModal && (
              <TrendModal
                trend={this.state.trendForModal}
                toggle={this.toggleTrendModal}
                open={this.state.trendModalOpen}
              />
            )}
            <DeleteModal
              trend={this.state.trendForModal}
              toggle={this.toggleDeleteModal}
              open={this.state.deleteModalOpen}
              onDelete={() => this.deleteTrend(this.props.currentUser, this.state.trendForModal.id)}
            />
          </React.Fragment>
        </Grid.Row>
        <Grid.Row style={{ justifyContent: 'center' }}>
          <React.Fragment>
            <Pagination
              size="huge"
              // inverted
              color="blue"
              activePage={this.props.page}
              onPageChange={this.props.handlePaginationChange}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={this.getTotalPages()}
            />
          </React.Fragment>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

export default AllTrends;
