// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react';

/**
 * Set interval hook
 * @param {function} callback
 * @param {integer} refreshRate
 */
export const useInterval = (callback, refreshRate) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    let id = setInterval(tick, refreshRate);
    return () => clearInterval(id);
    // eslint-disable-next-line
  }, [refreshRate]);
};

/**
 * Throttle data stream with refresh rate
 * @param {object} data
 * @param {integer} refreshRate
 * @returns {object}
 */
export const useRefreshRate = (data, refreshRate) => {
  const [intervalData, setIntervalData] = useState({});

  useInterval(() => setIntervalData(data), refreshRate);

  return intervalData;
};
