// create menu that appears when assets are selected.
import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import DELETE_DATASET_MUTATION from '../graphql/mutations/deleteDataset.graphql';
import DELETE_TREND_MUTATION from '../graphql/mutations/deleteTrend.graphql';
import USER_BY_ID from '../graphql/queries/userById.graphql';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Icon, Menu, Button, Grid, Segment, Sticky } from 'semantic-ui-react';
import {
  setRefetchDatasetsTrue as setRefetchDatasetsTrueAction,
  cancelRefetchDatasets as cancelRefetchDatasetsAction,
  setRefetchTrendsTrue as setRefetchTrendsTrueAction,
  cancelRefetchTrends as cancelRefetchTrendsAction,
  deselectTrends as deselectTrendsAction,
  deselectDatasets as deselectDatasetsAction,
} from '../redux/actions';
import withSnackbar from '../hoc/withSnackbar';
import withDatasets from '../hoc/withDatasets';
import classes from './AssetsSelectedMenu.module.css';
import AddTrendsToDatasetDialog from '../modal/AddTrendsToDatasetDialog.js';
import DeleteMultiModal from '../modal/DeleteMultiModal';

// TODO: Update <Search> usage after its will be implemented

class AssetsSelectedMenu extends Component {
  static propTypes = {
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        name: PropTypes.string,
        details: PropTypes.string,
        date: PropTypes.string,
        // location: PropTypes.string,
      }),
    ),
    refetchDatasets: PropTypes.func,
    loadingDatasets: PropTypes.bool,
  };

  state = {
    IsAddTrendsToDatasetDialogOpen: false,
    deleteModalOpen: false,
  };

  isDisabled = () => {
    if (this.props.selectedDatasetIds.length > 0) {
      return false;
    }
    if (this.props.selectedTrendIds.length > 0) {
      return false;
    }
    return true;
  };

  handleToggleAddTrendsToDatasetDialog = dataset => {
    // // console.log('dataset', dataset);
    // // console.log('selectedTrends', this.props.selectedTrends);
    this.setState({
      IsAddTrendsToDatasetDialogOpen: !this.state.IsAddTrendsToDatasetDialogOpen,
      datasetToAddTo: dataset,
    });
  };

  toggleDeleteModal = trend => {
    this.setState(prevState => ({
      deleteModalOpen: !prevState.deleteModalOpen,
    }));
  };

  handleMultiDelete = async () => {
    const userId = this.props.currentUser;
    // // console.log('userId assets selected menu', userId);
    if (this.props.selectedDatasets.length > 0) {
      await this.props.snackbar('deleting-multiple-datasets', 'loading');
      for (const datasetId of this.props.selectedDatasetIds) {
        await this.props.deleteDataset(userId, datasetId);
      }
      this.props.setRefetchDatasetsTrue();
      this.props.snackbar('done', 'success');
      this.props.deselectDatasets();
    }

    if (this.props.selectedTrends.length > 0) {
      await this.props.snackbar('deleting-multiple-trends', 'loading');
      for (const trendId of this.props.selectedTrendIds) {
        await this.props.deleteTrend(userId, trendId);
      }
      this.props.setRefetchTrendsTrue();
      this.props.snackbar('done', 'success');
      this.props.deselectTrends();
    }
  };

  getItemsForDeleteMulti = () => {
    if (this.props.selectedDatasets.length > 0) {
      return this.props.selectedDatasets;
    } else if (this.props.selectedTrends.length > 0) {
      return this.props.selectedTrends;
    }
  };

  render() {
    // // console.log('this.props AssetsSelectedMenu', this.props);
    return (
      <div className={classes.sticky}>
        {/* {this.props.selectedTrendIds.length > 0 && ( */}
        <AddTrendsToDatasetDialog
          toggle={this.handleToggleAddTrendsToDatasetDialog}
          open={this.state.IsAddTrendsToDatasetDialogOpen}
          dataset={this.state.datasetToAddTo}
        />

        <DeleteMultiModal
          items={this.getItemsForDeleteMulti()} // only one set type of items may be selected at a time
          toggle={this.toggleDeleteModal}
          open={this.state.deleteModalOpen}
          onDelete={() => this.handleMultiDelete()}
        />

        <Menu
          inverted
          top="true"
          fixed="top"
          style={this.props.isLoggedIn ? { marginLeft: '200px', paddingRight: '200px' } : {}}
        >
          <Dropdown disabled={this.isDisabled()} item text="File" simple>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Icon name="dropdown" />
                <span className="text">Add Selected Items To Dataset</span>
                <Dropdown.Menu>
                  {this.props.datasets &&
                    this.props.datasets.map((dataset, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => this.handleToggleAddTrendsToDatasetDialog(dataset)}
                      >
                        {dataset.name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown.Item>
              <Dropdown.Item>Open Item In New Tab</Dropdown.Item>
              <Dropdown.Item>Save Selected Item Data...</Dropdown.Item>
              <Dropdown.Item>Edit Permissions</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown disabled={this.isDisabled()} item text="Edit">
            <Dropdown.Menu>
              <Dropdown.Item>Duplicate Items</Dropdown.Item>
              <Dropdown.Item>Rename Multiple</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Header>Export</Dropdown.Header>
              <Dropdown.Item>Export to CSV</Dropdown.Item>
              <Dropdown.Item>Export to PDF</Dropdown.Item>
              <Dropdown.Item>Export to Excel</Dropdown.Item>
              <Dropdown.Item>Save Selected Items Raw JSON Data...</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown disabled={this.isDisabled()} item text="Options">
            <Dropdown.Menu>
              <Dropdown.Item>Make Private</Dropdown.Item>
              <Button
                size="tiny"
                inverted
                color="red"
                style={{ width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                onClick={() => this.toggleDeleteModal()}
              >
                Delete Selected Items
              </Button>
            </Dropdown.Menu>
          </Dropdown>

          {!this.isDisabled() && (
            <Menu.Item
              position="left"
              header
              as="h5"
              style={{ color: 'orange', fontSize: '14px', paddingLeft: '10px' }}
            >
              {/* <Grid style={{ paddingTop: '20px', marginLeft: '0', marginRight: '0' }}> */}
              {/* <Grid.Row columns={1}> */}

              {/* <i style={{ color: 'red', fontSize: '14px', paddingLeft: '2px' }}> */}
              {this.props.selectedDatasetIds.length > 1 &&
                `${this.props.selectedDatasetIds.length} Datasets Selected`}
              {this.props.selectedDatasetIds.length === 1 &&
                `${this.props.selectedDatasetIds.length} Dataset Selected`}

              {this.props.selectedTrendIds.length > 1 &&
                `${this.props.selectedTrendIds.length} Trends Selected`}
              {this.props.selectedTrendIds.length === 1 &&
                `${this.props.selectedTrendIds.length} Trend Selected`}
              {/* </i> */}
              {/* </Grid.Row> */}
              {/* </Grid> */}
            </Menu.Item>
          )}

          {!this.props.loadingUserById && this.props.userById && (
            <Menu.Item position="right" as="p">
              Credits: {this.props.userById.credits.toFixed(2)}
            </Menu.Item>
          )}

          {!this.props.loadingUserById && this.props.userById && (
            <Menu.Item position="right" as="p">
              Hi, {this.props.userById.name}
            </Menu.Item>
          )}

          {!this.props.isLoggedIn && (
            <Menu.Item header position="right" as="p">
              <Link style={{ fontSize: '20px' }} to="/signup">
                Sign Up
              </Link>
            </Menu.Item>
          )}

          {!this.props.isLoggedIn && (
            <Menu.Item header position="right" as="p">
              <Link style={{ fontSize: '20px' }} to="/signin">
                Log In
              </Link>
            </Menu.Item>
          )}

          <Menu.Item position="right" header as="h4">
            {/* <Grid style={{ paddingTop: '20px', marginLeft: '0', marginRight: '0' }}> */}
            {/* <Grid.Row columns={1}> */}
            QuantZilla <br />
            <br />
            <p style={{ color: 'white', fontSize: '7px', paddingLeft: '2px' }}>
              {' '}
              by Coffee Technologies
            </p>
            {/* </Grid.Row> */}
            {/* </Grid> */}
          </Menu.Item>
        </Menu>
        {/* )} */}
      </div>
    );
  }
}

export default compose(
  withSnackbar,
  withDatasets,
  connect(
    // map state to props
    (state, ownProps) => ({
      selectedTrendIds: state.ux.selectedTrends.map(trend => trend.id),
      selectedTrends: state.ux.selectedTrends,
      selectedDatasets: state.ux.selectedDatasets,
      selectedDatasetIds: state.ux.selectedDatasets.map(dataset => dataset.id),
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add in dispatch action functions here
      setRefetchDatasetsTrue: () => dispatch(setRefetchDatasetsTrueAction()),
      cancelRefetchDatasets: () => dispatch(cancelRefetchDatasetsAction()),
      setRefetchTrendsTrue: () => dispatch(setRefetchTrendsTrueAction()),
      cancelRefetchTrends: () => dispatch(cancelRefetchTrendsAction()),
      // deselect
      deselectTrends: () => dispatch(deselectTrendsAction()),
      deselectDatasets: () => dispatch(deselectDatasetsAction()),
    }),
  ),
  graphql(DELETE_TREND_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        deleteTrend: async (userId, trendId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                trendId: trendId,
              },
            });
            return response;
          } catch (e) {
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(DELETE_DATASET_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        deleteDataset: async (userId, datasetId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                datasetId: datasetId,
              },
            });
            // await ownProps.snackbar('delete-dataset-success', 'success');
            return response;
          } catch (e) {
            // await ownProps.snackbar('delete-dataset-error', 'error');
            console.error(e.message);
          }
        },
      };
    },
  }),
  graphql(USER_BY_ID, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: ownProps.currentUser,
        },
      };
    },
    props: ({ data, ownProps }) => {
      let { error, refetch, loading, userById } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        userById: userById,
        refetchUserById: refetch,
        loadingUserById: loading,
      };
      return result;
    },
  }),
)(AssetsSelectedMenu);
