import USERS_QUERY from '../graphql/queries/getUsers.graphql';
import INSERT_USER from '../graphql/mutations/insertUser.graphql';
import SIGN_IN from '../graphql/mutations/signIn.graphql';
import CANCEL_ACCOUNT from '../graphql/mutations/cancelAccount.graphql';
import { logIn as logInAction, logOut as logOutAction } from '../redux/actions';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';

export default compose(
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add action functions from redux here
      logIn: (isLoggedIn, currentUser) => {
        dispatch(logInAction(isLoggedIn, currentUser));
      },
      logOut: (isLoggedIn, currentUser) => {
        dispatch(logOutAction(isLoggedIn, currentUser));
      },
    }),
  ),
  // Query
  graphql(USERS_QUERY, {
    options: ownProps => {
      return {
        fetchPolicy: 'cache-and-network',
      };
    },
    props: ({ data, ownProps }) => {
      const { error, refetch, loading, users = [] } = data;
      if (error) {
        console.error(error.message);
      }
      const result = {
        users: users,
        refetchUsers: refetch,
        loadingUsers: loading,
      };
      return result;
    },
  }),

  // mutation
  graphql(INSERT_USER, {
    props: ({ mutate, ownProps }) => {
      return {
        insertUser: async (name, email, password, token, planId, phoneNumber) => {
          // token is for stripe
          try {
            let response = await mutate({
              variables: {
                name: name,
                email: email,
                password: password,
                stripeToken: token,
                planId: planId,
                phoneNumber: phoneNumber,
              },
            });
            // await ownProps.toggleLoader(false);
            if (response.data) {
              // await ownProps.toggleLoader('done');
              return response.data;
            }
          } catch (e) {
            // console.log('error', e.message);
            ownProps.snackbar('custom-error', 'error', {
              message: e.message,
            });
            return e;
          }
        },
      };
    },
  }),

  graphql(SIGN_IN, {
    props: ({ mutate, ownProps }) => {
      return {
        signIn: async (email, password) => {
          // token is for stripe
          try {
            let response = await mutate({
              variables: {
                email: email,
                password: password,
              },
            });

            // using localstorage for auth in Users component
            if (response.data) {
              ownProps.snackbar('success-sign-in', 'success');
              return response.data;
            }
          } catch (e) {
            // console.log(e.message);
            ownProps.snackbar('custom-error', 'error', {
              message: e.message,
            });
            return e.message;
          }
        },
      };
    },
  }),
  graphql(CANCEL_ACCOUNT, {
    props: ({ mutate, ownProps }) => {
      return {
        cancelAccount: async (email, password) => {
          try {
            let response = await mutate({
              variables: {
                email: email,
                password: password,
              },
            });
            if (response) {
              return response.data;
            }
          } catch (e) {
            console.error(e);
            ownProps.snackbar('signin-error', e.message);
            return e;
          }
        },
      };
    },
  }),
);
