import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TrendModal from '../modal/TrendModal';
import TrendCard from '../cards/TrendCard';
import DeleteModal from '../modal/DeleteModal';

import { Grid, Pagination, Icon, Loader } from 'semantic-ui-react';

class AllRsvpTrends extends Component {
  static propTypes = {
    currentUser: PropTypes.string,
    rsvpTrends: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        name: PropTypes.string,
        details: PropTypes.string,
        date: PropTypes.string,
        // location: PropTypes.string,
      }),
    ),
    refetchRSVPTrends: PropTypes.func,
    loadingTrends: PropTypes.bool,
    page: PropTypes.number,
    rsvpTrendCountInNextTwoPages: PropTypes.number.isRequired,
  };

  static defaultProps = {
    page: 1,
  };

  state = {
    trendModalOpen: false,
    deleteModalOpen: false,
    trendForModal: {},
  };

  componentDidMount = async () => {
    await this.props.deselectDatasets();
  };

  componentDidUpdate = async () => {
    if (this.props.setRefetchTrends) {
      await this.props.refetchRSVPTrends();
      await this.props.cancelRefetchTrends();
    }
  };

  handleTrendClick = async (selectedTrend, event) => {
    event.stopPropagation();
    this.props.deselectDatasets();
    const id = selectedTrend.id;
    if (this.props.selectedTrendIds.length === 0 || !event.shiftKey) {
      this.props.handleTrendItemClick(selectedTrend, event);
    } else {
      let selectedTrends = [];
      let backSet = [];
      let selectionState = 0;
      this.props.rsvpTrends.forEach(trend => {
        // eslint-disable-next-line default-case
        switch (selectionState) {
          // find for start of selection
          case 0:
            if (trend.isSelected) {
              selectionState = 1;
            }
            if (trend.id === id) {
              selectedTrends.push(trend);
              selectionState = 2;
            }
            if (trend.isSelected && trend.id === id) {
              selectionState = 3;
            }
            break;
          // look for click
          case 1:
            selectedTrends.push(trend);
            if (trend.id === id) {
              selectionState = 3;
            }
            break;
          // look for end of selection
          case 2:
            if (trend.isSelected === false) {
              backSet.push(trend);
              selectionState = 3;
              return;
            }
            selectedTrends.push(trend);
            break;
          // selection done but keep track of trends in case user selecting backwards right to left
          case 3:
            backSet.push(trend);
            if (trend.isSelected) {
              selectedTrends = selectedTrends.concat(backSet);
              backSet = [];
            }
        }
      });

      // dont add ids that already exist in selected trends:
      selectedTrends = selectedTrends.filter(trend => {
        return this.props.selectedTrendIds.indexOf(trend.id) === -1;
      });
      // console.log('selectedTrends', selectedTrends);
      this.props.handleShiftClick(selectedTrends);
    }
  };

  stopTracking = async (currentUser, trendId) => {
    await this.props.stopTracking(currentUser, trendId);
    await this.props.refetchRSVPTrends();
    await this.props.snackbar('stopped-tracking-trend', 'info');
  };

  deleteTrend = async (currentUser, trendId) => {
    await this.props.deleteTrend(currentUser, trendId);
    await this.props.refetchRSVPTrends();
    await this.props.snackbar('delete-trend-success', 'error');
  };

  toggleTrendModal = trend => {
    this.setState(prevState => ({
      trendModalOpen: !prevState.trendModalOpen,
      trendForModal: trend,
    }));
  };

  toggleDeleteModal = trend => {
    this.setState(prevState => ({
      deleteModalOpen: !prevState.deleteModalOpen,
      trendForModal: trend,
    }));
  };

  getTotalPages() {
    const itemsNextTwoPages = this.props.rsvpTrendCountInNextTwoPages;
    const pagesLeftCount = itemsNextTwoPages / 15;
    let pagesLeft = 0;

    if (pagesLeftCount > 1) {
      pagesLeft = 2;
    }
    if (pagesLeftCount < 1) {
      pagesLeft = 1;
    }
    if (pagesLeftCount === 1) {
      pagesLeft = 1;
    }
    if (pagesLeftCount === 0) {
      pagesLeft = 0;
    }
    if (itemsNextTwoPages < 15) {
      pagesLeft = 0;
    }
    return this.props.page + pagesLeft;
  }

  render() {
    // console.log('total pages', this.getTotalPages());
    // console.log('this.props tracking page', this.props);
    return (
      <React.Fragment>
        <Grid.Row style={{ height: '95%' }} columns={this.props.density || 5}>
          {/* iterate over available trends here */}
          {this.props.loadingRsvpTrends && <Loader name="spinner" active content="Loading" />}

          {this.props.rsvpTrends &&
            !this.props.loadingRsvpTrends &&
            this.props.rsvpTrends.map(trend => (
              <Grid.Column key={trend.id}>
                <TrendCard
                  trend={trend}
                  toggleDeleteModal={e => this.toggleDeleteModal(trend)}
                  toggleTrendModal={e => this.toggleTrendModal(trend)}
                  selectTrend={this.handleTrendClick}
                  deselectTrends={this.props.deselectTrends}
                  isSelected={trend.isSelected}
                  loadingAllTrends={this.props.loadingRsvpTrends}
                  stopTracking={this.stopTracking}
                />
              </Grid.Column>
            ))}
          <TrendModal
            open={this.state.trendModalOpen}
            toggle={this.toggleTrendModal}
            trend={this.state.trendForModal}
          />
          <DeleteModal
            trend={this.state.trendForModal}
            toggle={this.toggleDeleteModal}
            open={this.state.deleteModalOpen}
            onDelete={e => this.deleteTrend(this.props.currentUser, this.state.trendForModal.id)}
          />
        </Grid.Row>
        <Grid.Row style={{ justifyContent: 'center' }}>
          <Pagination
            size="huge"
            // inverted
            color="blue"
            activePage={this.props.page}
            onPageChange={this.props.handlePaginationChange}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={this.getTotalPages()}
          />
        </Grid.Row>
      </React.Fragment>
    );
  }
}

export default AllRsvpTrends;
