import React, { Component } from 'react';
import { Sunburst } from 'react-vis';
import { LabelSeries } from 'react-vis';
import { Button } from 'semantic-ui-react';

const LABEL_STYLE = {
  fontSize: '15px',
  textAnchor: 'middle',
};
/**
 * Recursively work backwards from highlighted node to find path of valued nodes
 * @param {Object} node - the current node being considered
 * @returns {Array} an array of strings describing the key route to the current node
 */
function getKeyPath(node) {
  if (!node.parent) {
    return [];
  }
  // below here edit name to value to see the tweet name and tweet volume, refactor to be thiccer and show volume + name
  return [(node.data && node.data.name) || node.name].concat(getKeyPath(node.parent));
}

function getKeyPathForTweetVolume(node) {
  if (!node.parent) {
    return [];
  }
  return [(node.data && node.data.value) || node.value].concat(
    getKeyPathForTweetVolume(node.parent),
  );
}
/**
 * Recursively modify data depending on whether or not each cell has been selected by the hover/highlight
 * @param {Object} data - the current node being considered
 * @param {Object|Boolean} keyPath - a map of keys that are in the highlight path
 * if this is false then all nodes are marked as selected
 * @returns {Object} Updated tree structure
 */
function updateData(data, keyPath) {
  if (data.children) {
    data.children.map(child => updateData(child, keyPath));
  }
  // add a fill to all the uncolored cells
  const DIVERGING_COLOR_SCALE = ['#39ff14', '#39ff14', '#39ff14', '#39ff14'];
  if (!data.hex) {
    data.style = {
      fill: DIVERGING_COLOR_SCALE[5],
    };
  }
  data.style = {
    ...data.style,
    fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1,
  };
  return data;
}

class Burst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathValue: false,
      finalValue: 'Hover and click to select',
      clicked: false,
      selectedcity: '',
      selectedOnBurst: '',
      tweet_volume: '',
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: parseInt(window.innerWidth), height: window.innerHeight });
  };

  render() {
    // console.log('window', this.state);
    let selectedOnBurstHandler = this.props.selectedOnBurstHandler;
    const { clicked, finalValue } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Sunburst
          animation
          // className="basic-sunburst-example"
          hideRootNode
          onValueMouseOver={node => {
            if (clicked) {
              return;
            }
            const path = getKeyPath(node).reverse();
            const pathAsMap = path.reduce((res, row) => {
              res[row] = true;
              return res;
            }, {});
            this.setState({
              finalValue: path[path.length - 1],
              pathValue: path.join(' > '),
              data: updateData(this.props.datatrends, pathAsMap),
            });

            const path2 = getKeyPathForTweetVolume(node).reverse();
            // const pathAsMap2 = path2.reduce((res, row) => {
            //   res[row] = true;
            //   return res;
            // }, {});
            this.setState({
              tweet_volume: path2[path2.length - 1],
              pathValue: path2.join(' > '),
            });
            // console.log('chungy: ', this.state.tweet_volume);
            //this.state.tweet_volume now has the volume applied and can be lifted up.
          }}
          onValueMouseOut={() =>
            clicked
              ? () => {}
              : this.setState({
                  pathValue: false,
                  finalValue: false,
                  data: updateData(this.props.datatrends, false),
                })
          }
          onValueClick={() =>
            this.setState(
              {
                clicked: !clicked,
                selectedOnBurst: finalValue,
              },
              // console.log('final value', this.state.finalValue),
              // selectedOnBurstHandler passes finalValue up as prop to parent component
              selectedOnBurstHandler([
                finalValue,
                this.state.tweet_volume,
                this.props.selectedcity,
              ]),
            )
          }
          style={{
            stroke: '#ddd',
            strokeOpacity: 0.3,
            strokeWidth: '0.7',
          }}
          colorType="literal"
          getSize={d => d.value}
          getColor={d => d.hex}
          data={this.props.datatrends}
          height={this.state.width / 4}
          width={this.state.width / 4}
        >
          {/* <LabelSeries
              color="white"
              data={[{ x: 0, y: 0, label: finalValue, style: LABEL_STYLE }]}
            /> */}
        </Sunburst>
        {finalValue && (
          <div
            style={{
              fontColor: 'black',
              background: '#c0c0c0',
              borderRadius: '8px',
              fontSize: '20px',
              fontWeight: '800',
              position: 'absolute',
              padding: '10px',
            }}
          >
            {finalValue}
            <span>
              {finalValue !== 'Hover and click to select' && (
                <Button basic inverted style={{ margin: '10px' }} onClick={this.props.add}>
                  Add
                </Button>
              )}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default Burst;
