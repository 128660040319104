import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Icon, Card, Button, Segment, Checkbox, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import classes from './TrendCard.module.css';
import withSnackbar from '../hoc/withSnackbar';

class TrendCard extends Component {
  static propTypes = {
    expandTrend: PropTypes.func,
    trend: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      name: PropTypes.string,
      details: PropTypes.string,
      date: PropTypes.string,
    }).isRequired,
    snackbar: PropTypes.func,
    rsvp: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    currentUser: PropTypes.string,
    refetch: PropTypes.func,
    stopTracking: PropTypes.func,
    toggleDeleteModal: PropTypes.func,
    deleteTrend: PropTypes.func,
    selectTrend: PropTypes.func,
    deselectTrends: PropTypes.func,
    isSelected: PropTypes.bool,
  };

  state = {
    deleteModalOpen: false,
  };

  render() {
    // // console.log('props trendcard', this.props);
    return (
      <React.Fragment>
        <div
          className={classes.trendCards}
          // onDoubleClick={() => this.props.toggleTrendModal(this.props.trend)}
        >
          <Card
            className={classNames(
              classes.trendCard,
              this.props.isSelected ? classes.selected : classes.unselected,
            )}
          >
            <Checkbox
              onClick={event => {
                !this.props.isSelected
                  ? this.props.selectTrend(this.props.trend, event)
                  : this.props.deselectTrends();
              }}
              size="massive"
              className={classes.cardReusableCheckbox}
              checked={this.props.isSelected}
            />

            <Card.Content
              style={{ fontSize: '10px', padding: '4px', textAlign: 'center' }}
              header={
                this.props.trend.name.length > 10
                  ? this.props.trend.name.slice(0, 35) + '...'
                  : this.props.trend.name
              }
            />

            <Card.Content style={{ fontSize: '10px', lineHeight: '1em', padding: '3px' }}>
              {this.props.currentUser !== this.props.trend.userId && (
                <p style={{ color: 'rgb(0, 132, 255)' }}>Engine Trend</p>
              )}
              {this.props.currentUser === this.props.trend.userId && (
                <p style={{ color: 'green' }}>You added this</p>
              )}
            </Card.Content>

            <Card.Content style={{ fontSize: '10px', lineHeight: '1em', padding: '3px' }} extra>
              <b style={{ color: '#000' }}>Date Added:</b> <br />
              {this.props.trend.date}
            </Card.Content>

            <Card.Content style={{ fontSize: '10px', lineHeight: '1em', padding: '3px' }} extra>
              <Icon name="user" />
              <b>Trackers:</b> {this.props.trend.rsvp.length}
            </Card.Content>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '0',
                textAlign: 'center',
              }}
            >
              {!this.props.trend.rsvp.includes(this.props.currentUser) && (
                <Button
                  size="mini"
                  inverted
                  compact
                  color="green"
                  animated="fade"
                  style={{
                    margin: '2px',
                  }}
                  onClick={() => this.props.rsvp(this.props.trend.id)}
                >
                  <Button.Content visible>Track</Button.Content>
                  <Button.Content hidden>
                    <Icon name="arrow right" />
                    Track
                  </Button.Content>
                </Button>
              )}
              {this.props.trend.rsvp.includes(this.props.currentUser) && (
                <Button
                  size="mini"
                  // inverted
                  compact
                  color="blue"
                  // animated="fade"
                  style={{
                    margin: '2px',
                  }}
                  onClick={() =>
                    this.props.stopTracking(this.props.currentUser, this.props.trend.id)
                  }
                >
                  <Button.Content visible>Tracking</Button.Content>
                  {/* <Button.Content hidden>Stop Tracking</Button.Content> */}
                </Button>
              )}

              {this.props.isSelected && (
                <Button
                  size="mini"
                  inverted
                  compact
                  color="red"
                  animated="fade"
                  style={{
                    margin: '2px',
                  }}
                  onClick={e => this.props.toggleDeleteModal()}
                  disabled={this.props.currentUser !== this.props.trend.userId}
                >
                  <Button.Content visible>Delete</Button.Content>

                  <Button.Content hidden>
                    <Icon name="trash" />
                  </Button.Content>
                </Button>
              )}
            </div>
            <Button
              size="mini"
              compact
              inverted
              color="blue"
              style={{
                width: '100%',
                margin: '0',
              }}
              onClick={e => this.props.toggleTrendModal()}
            >
              <Icon name="expand" />
            </Button>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withSnackbar,
  connect(
    // map state to props
    (state, ownProps) => ({
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
    }),
    //mapDispatchToProps
    (dispatch, ownProps) => ({
      // add in dispatch action functions here
    }),
  ),
)(TrendCard);
