import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import {
  deselectDatasets as deselectDatasetsAction,
  deselectTrends as deselectTrendsAction,
} from '../redux/actions';
import PropTypes from 'prop-types';
import { Grid, Button, Icon, Header } from 'semantic-ui-react';
import styles from './styles';
import AllDatasets from './AllDatasetsContainer';

class Datasets extends Component {
  static propTypes = {
    currentUser: PropTypes.string,
  };

  state = {
    density: 5,
    activePage: 1,
    isCreateDatasetDialogOpen: false,
  };

  componentDidMount = async () => {
    await this.props.deselectTrends();
  };

  handlePaginationChange = async (e, { activePage }) => {
    this.setState({ activePage: Math.round(activePage) });
  };

  toggleCreateDatasetDialog = () => {
    this.setState({ isCreateDatasetDialogOpen: !this.state.isCreateDatasetDialogOpen });
  };

  render() {
    return (
      <div onClick={() => this.props.deselectDatasets()}>
        <Grid style={styles.grid}>
          <Grid.Row columns={2}>
            <Header as="h2">
              <Icon style={{ color: '#fff' }} name="line graph" />
              <Header.Content style={{ color: '#fff' }}>Datasets</Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row style={{ display: 'flex' }}>
            <span style={{ display: 'flex-end' }}>
              <Button onClick={() => this.toggleCreateDatasetDialog()}>
                <Icon name="plus" />
                Create a Dataset
              </Button>
            </span>
          </Grid.Row>
          <AllDatasets
            page={this.state.activePage}
            handlePaginationChange={this.handlePaginationChange}
            density={this.state.density}
            toggleCreateDatasetDialog={this.toggleCreateDatasetDialog}
            isCreateDatasetDialogOpen={this.state.isCreateDatasetDialogOpen}
          />
        </Grid>
      </div>
    );
  }
}

// compose with a HOC for all datasets.
export default compose(
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
        deselectDatasets: () => dispatch(deselectDatasetsAction()),
        deselectTrends: () => dispatch(deselectTrendsAction()),
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const { ...restDispatchProps } = dispatchProps;
      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
      };
    },
  ),
)(Datasets);
