export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
// trends
export const SELECT_TREND = 'SELECT_TREND';
export const MULTI_SELECT_TREND = 'MULTI_SELECT_TREND';
export const SHIFT_SELECT_TREND = 'SHIFT_SELECT_TREND';
export const DESELECT_TRENDS = 'DESELECT_TRENDS';
//datasets
export const SELECT_DATASET = 'SELECT_DATASET';
export const MULTI_SELECT_DATASET = 'MULTI_SELECT_DATASET';
export const SHIFT_SELECT_DATASET = 'SHIFT_SELECT_DATASET';
export const DESELECT_DATASETS = 'DESELECT_DATASETS';
// refetchers
export const SET_REFETCH_DATASETS_TRUE = 'SET_REFETCH_DATASETS_TRUE';
export const CANCEL_REFETCH_DATASETS = 'CANCEL_REFETCH_DATASETS';
export const SET_REFETCH_TRENDS_TRUE = 'SET_REFETCH_TRENDS_TRUE';
export const CANCEL_REFETCH_TRENDS = 'CANCEL_REFETCH_TRENDS';
// currently viewing dataset:
export const SELECT_CURRENTLY_VIEWING_DATASET = 'SELECT_CURRENTLY_VIEWING_DATASET';
export const CLEAR_CURRENTLY_VIEWING_DATASET = 'CLEAR_CURRENTLY_VIEWING_DATASET';

export const ALTER_LAST_SELECTION_STACK_ELEMENT = 'ALTER_LAST_SELECTION_STACK_ELEMENT';

export const SET_CACHED_DATASETS = 'SET_CACHED_DATASETS';
export const CLEAR_CACHED_DATASETS = 'CLEAR_CACHED_DATASETS';
export const SET_DASHBOARD_ITEM = 'SET_DASHBOARD_ITEM';
export const REMOVE_DASHBOARD_ITEM = 'REMOVE_DASHBOARD_ITEM';
export const REMOVE_ALL_DASHBOARD_ITEMS = 'REMOVE_ALL_DASHBOARD_ITEMS';
export const REFETCH_ON_RANGE_OR_INTERVAL_CHANGE = 'REFETCH_ON_RANGE_OR_INTERVAL_CHANGE';
export const CANCEL_REFETCH_ON_RANGE_OR_INTERVAL_CHANGE =
  'CANCEL_REFETCH_ON_RANGE_OR_INTERVAL_CHANGE';

export const setDashboardItem = dashboardItem => {
  return {
    type: SET_DASHBOARD_ITEM,
    dashboardItem,
  };
};

export const removeDashboardItem = id => {
  return {
    type: REMOVE_DASHBOARD_ITEM,
    id,
  };
};

export const removeAllDashboardItems = () => {
  return {
    type: REMOVE_ALL_DASHBOARD_ITEMS,
  };
};

// cached datasets for multi-lines overlays
export const setCachedDatasets = cachedDatasets => {
  return {
    type: SET_CACHED_DATASETS,
    cachedDatasets,
  };
};
export const clearCachedDatasets = () => {
  return {
    type: CLEAR_CACHED_DATASETS,
  };
};

export const refetchOnRangeOrIntervalChange = () => {
  return {
    type: REFETCH_ON_RANGE_OR_INTERVAL_CHANGE,
  };
};

export const cancelRefetchOnRangeOrIntervalChange = () => {
  return {
    type: CANCEL_REFETCH_ON_RANGE_OR_INTERVAL_CHANGE,
  };
};

// trends
export const selectTrend = trend => {
  return {
    type: SELECT_TREND,
    trend,
  };
};

export const multiSelectTrend = trend => {
  return {
    type: MULTI_SELECT_TREND,
    trend,
  };
};

export const shiftSelectTrend = trend => {
  return {
    type: SHIFT_SELECT_TREND,
    trend,
  };
};

export const deselectTrends = () => {
  return {
    type: DESELECT_TRENDS,
  };
};

// datasets
export const selectDataset = dataset => {
  return {
    type: SELECT_DATASET,
    dataset,
  };
};

export const multiSelectDataset = dataset => {
  return {
    type: MULTI_SELECT_DATASET,
    dataset,
  };
};

export const shiftSelectDataset = dataset => {
  return {
    type: SHIFT_SELECT_DATASET,
    dataset,
  };
};

export const deselectDatasets = () => {
  return {
    type: DESELECT_DATASETS,
  };
};

// snackbar
export const openSnackbar = (message, severity, messageArgs) => {
  return {
    type: OPEN_SNACKBAR,
    message: message instanceof Error ? message.message : message,
    severity: severity,
    messageArgs: messageArgs,
  };
};

export const closeSnackbar = () => {
  return {
    type: CLOSE_SNACKBAR,
  };
};

export const logIn = (isLoggedIn, currentUser) => {
  return {
    type: LOG_IN,
    isLoggedIn: isLoggedIn,
    currentUser: currentUser,
  };
};

export const logOut = () => {
  return {
    type: LOG_OUT,
  };
};

export const setRefetchDatasetsTrue = () => {
  return {
    type: SET_REFETCH_DATASETS_TRUE,
  };
};

export const cancelRefetchDatasets = () => {
  return {
    type: CANCEL_REFETCH_DATASETS,
  };
};

export const setRefetchTrendsTrue = () => {
  return {
    type: SET_REFETCH_TRENDS_TRUE,
  };
};

export const cancelRefetchTrends = () => {
  return {
    type: CANCEL_REFETCH_TRENDS,
  };
};

export const selectCurrentlyViewingDataset = id => {
  return {
    type: SELECT_CURRENTLY_VIEWING_DATASET,
    currentlyViewingDataset: id,
  };
};
export const clearCurrentlyViewingDataset = () => {
  return {
    type: CLEAR_CURRENTLY_VIEWING_DATASET,
  };
};

export const alterLastSelectionStackElement = (assetType, asset) => {
  return {
    type: ALTER_LAST_SELECTION_STACK_ELEMENT,
    assetType,
    asset,
  };
};
