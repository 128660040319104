import AllDatasets from './AllDatasets';
import { compose, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
  selectDataset as selectDatasetAction,
  multiSelectDataset as multiSelectDatasetAction,
  shiftSelectDataset as shiftSelectDatasetAction,
  deselectDatasets as deselectDatasetsAction,
  alterLastSelectionStackElement as alterLastSelectionStackElementAction,
  deselectTrends as deselectTrendsAction,
  setRefetchDatasetsTrue as setRefetchDatasetsTrueAction,
  cancelRefetchDatasets as cancelRefetchDatasetsAction,
} from '../redux/actions';
import DELETE_DATASET_MUTATION from '../graphql/mutations/deleteDataset.graphql';
import withSnackbar from '../hoc/withSnackbar';
import withDatasets from '../hoc/withDatasets';
import platform from 'platform';

export default compose(
  withSnackbar,
  withDatasets,
  connect(
    // map state to props
    (state, ownProps) => ({
      ...ownProps,
      isDatasetSelected: datasetId =>
        state.ux.selectedDatasets.find(dataset => dataset.id === datasetId) !== undefined,
      selectedDatasetIds: state.ux.selectedDatasets.map(dataset => dataset.id),
      isLoggedIn: state.ux.currentUser.isLoggedIn,
      currentUser: state.ux.currentUser.currentUser,
      setRefetchDatasets: state.ux.setRefetchDatasets,
    }),
    // mapDispatchToProps:
    (dispatch, ownProps) => {
      // add in dispatch action functions here
      return {
        ...ownProps,
        selectDataset: dataset => dispatch(selectDatasetAction(dataset)),
        multiSelectDataset: datasetId => dispatch(multiSelectDatasetAction(datasetId)),
        shiftSelectDataset: datasetIds => dispatch(shiftSelectDatasetAction(datasetIds)),
        deselectDatasets: () => dispatch(deselectDatasetsAction()),
        alterLastSelectionStackElement: (type, asset) => {
          dispatch(alterLastSelectionStackElementAction(type, asset));
        },
        deselectTrends: () => dispatch(deselectTrendsAction()),
        setRefetchDatasetsTrue: () => dispatch(setRefetchDatasetsTrueAction()),
        cancelRefetchDatasets: () => dispatch(cancelRefetchDatasetsAction()),
      };
    },
    // merge props
    (stateProps, dispatchProps, ownProps) => {
      const {
        deselectTrends,
        selectDataset,
        multiSelectDataset,
        shiftSelectDataset,
        alterLastSelectionStackElement,
        ...restDispatchProps
      } = dispatchProps;

      ownProps.datasets.map(dataset => {
        dataset.isSelected = stateProps.isDatasetSelected(dataset.id);
        return dataset;
      });

      return {
        ...ownProps,
        ...stateProps,
        ...restDispatchProps,
        deselectLastSelectionStackElement: () => alterLastSelectionStackElement('dataset', null),
        handleDatasetItemClick: (dataset, event) => {
          if (platform.os.family === 'OS X' && event.metaKey) {
            // command key for macOS
            multiSelectDataset(dataset);
          } else if (event.ctrlKey) {
            // windows
            multiSelectDataset(dataset);
          } else {
            selectDataset(dataset);
          }
        },
        handleShiftDatasetClick: ids => {
          shiftSelectDataset(ids);
        },
        deselectTrends: () => deselectTrends(),
      };
    },
  ),
  graphql(DELETE_DATASET_MUTATION, {
    props: ({ mutate, ownProps }) => {
      return {
        deleteDataset: async (userId, datasetId) => {
          try {
            const response = await mutate({
              variables: {
                userId: userId,
                datasetId: datasetId,
              },
            });
            await ownProps.snackbar('delete-dataset-success', 'success');
            return response;
          } catch (e) {
            await ownProps.snackbar('delete-dataset-error', 'error');
            console.error(e.message);
          }
        },
      };
    },
  }),
)(AllDatasets);
