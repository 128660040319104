import { combineReducers } from 'redux';
import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  LOG_IN,
  LOG_OUT,
  SELECT_TREND,
  MULTI_SELECT_TREND,
  SHIFT_SELECT_TREND,
  DESELECT_TRENDS,
  SELECT_DATASET,
  MULTI_SELECT_DATASET,
  SHIFT_SELECT_DATASET,
  DESELECT_DATASETS,
  SET_REFETCH_DATASETS_TRUE,
  CANCEL_REFETCH_DATASETS,
  SET_REFETCH_TRENDS_TRUE,
  CANCEL_REFETCH_TRENDS,
  SELECT_CURRENTLY_VIEWING_DATASET,
  CLEAR_CURRENTLY_VIEWING_DATASET,
  SET_CACHED_DATASETS,
  CLEAR_CACHED_DATASETS,
  SET_DASHBOARD_ITEM,
  REMOVE_DASHBOARD_ITEM,
  REMOVE_ALL_DASHBOARD_ITEMS,
  REFETCH_ON_RANGE_OR_INTERVAL_CHANGE,
  CANCEL_REFETCH_ON_RANGE_OR_INTERVAL_CHANGE,
  // PUSH_SELECTION_STACK,
  // POP_SELECTION_STACK,
  // RESET_SELECTION_STACK,
  // MERGE_LAST_TWO_SELECTION_STACK_ELEMENTS,
  // ALTER_LAST_SELECTION_STACK_ELEMENT,
} from '../actions';
// can import more large files of complex reducers with:
// import * as reducers from './reducers-etc...';

function setCachedDatasets(state = { cachedDatasets: [], refetch: false }, action) {
  switch (action.type) {
    case SET_CACHED_DATASETS: {
      return action.cachedDatasets;
    }
    case CLEAR_CACHED_DATASETS: {
      return [];
    }
    case REFETCH_ON_RANGE_OR_INTERVAL_CHANGE: {
      return {
        ...state,
        refetch: true,
      };
    }
    case CANCEL_REFETCH_ON_RANGE_OR_INTERVAL_CHANGE: {
      return {
        ...state,
        refetch: false,
      };
    }
    default: {
      return state;
    }
  }
}

function dashboardItems(state = [], action) {
  switch (action.type) {
    case SET_DASHBOARD_ITEM: {
      const newDashboardItems = state.dashboardItems;
      newDashboardItems.push(action.dashboardItem);
      return { ...state, dashboardItems: newDashboardItems };
    }
    case REMOVE_DASHBOARD_ITEM: {
      // if (!state.dashboardItems || !action.id) return state;
      const newDashboardItems = state.dashboardItems.filter(item => item.id !== action.id);
      return { ...state, dashboardItems: newDashboardItems };
    }
    case REMOVE_ALL_DASHBOARD_ITEMS: {
      return [];
    }
    default: {
      if (!state.dashboardItems) return { ...state, dashboardItems: [] };
      return state;
    }
  }
}

// trends =========================================================================================
function selectedTrends(state = [], action) {
  switch (action.type) {
    // begin
    case SELECT_TREND: {
      return [action.trend];
    }

    case MULTI_SELECT_TREND: {
      if (state.find(trend => trend.id === action.trend.id)) {
        return state.filter(trend => trend.id !== action.trend.id);
      }
      return [...state, action.trend];
    }

    case SHIFT_SELECT_TREND: {
      return [...state, ...action.trend];
    }

    case DESELECT_TRENDS: {
      return [];
    }

    default: {
      return state;
    }
    // end select trends
  }
}

// datasets =======================================================================================
function selectedDatasets(state = [], action) {
  switch (action.type) {
    // begin
    case SELECT_DATASET: {
      return [action.dataset];
    }

    case MULTI_SELECT_DATASET: {
      if (state.find(dataset => dataset.id === action.dataset.id)) {
        return state.filter(dataset => dataset.id !== action.dataset.id);
      }
      return [...state, action.dataset];
    }

    case SHIFT_SELECT_DATASET: {
      return [...state, ...action.dataset];
    }

    case DESELECT_DATASETS: {
      return [];
    }

    default: {
      return state;
    }
    // end select datasets
  }
}

function currentlyViewingDataset(state = { currentlyViewingDataset: null }, action) {
  switch (action.type) {
    // begin
    case SELECT_CURRENTLY_VIEWING_DATASET: {
      return action.currentlyViewingDataset;
    }
    case CLEAR_CURRENTLY_VIEWING_DATASET: {
      return null;
    }
    default: {
      return state;
    }
  }
}

function snackbar(state = { isOpen: false, message: '', severity: '', messageArgs: [] }, action) {
  switch (action.type) {
    case OPEN_SNACKBAR: {
      return {
        isOpen: true,
        message: action.message,
        severity: action.severity,
        messageArgs: action.messageArgs,
      };
    }
    case CLOSE_SNACKBAR: {
      return { ...state, isOpen: false };
    }
    default: {
      return state;
    }
  }
}

function currentUser(state = { isLoggedIn: false, currentUser: '' }, action) {
  switch (action.type) {
    case LOG_IN: {
      localStorage.setItem('currentUser', action.currentUser);
      return {
        isLoggedIn: true,
        currentUser: action.currentUser,
      };
    }
    case LOG_OUT: {
      localStorage.removeItem('currentUser');
      return { ...state, isLoggedIn: false, currentUser: null };
    }
    default: {
      return state;
    }
  }
}

function setRefetchDatasets(state = false, action) {
  switch (action.type) {
    case SET_REFETCH_DATASETS_TRUE: {
      return true;
    }
    case CANCEL_REFETCH_DATASETS: {
      return false;
    }
    default: {
      return state;
    }
  }
}

function setRefetchTrends(state = false, action) {
  switch (action.type) {
    case SET_REFETCH_TRENDS_TRUE: {
      return true;
    }
    case CANCEL_REFETCH_TRENDS: {
      return false;
    }
    default: {
      return state;
    }
  }
}

// function selectionStack(state = [], action) {
// switch (action.type) {
//   case PUSH_SELECTION_STACK:
//     return [].concat(state, { asset: action.asset, type: action.assetType });
//   case POP_SELECTION_STACK:
//     return state.slice(0, state.length - 1);
//   case RESET_SELECTION_STACK:
//     return [];
//   case MERGE_LAST_TWO_SELECTION_STACK_ELEMENTS:
//     if (state.length > 1 && state[state.length - 1].type === state[state.length - 2].type) {
//       const length = state.length;
//       return state.filter((e, i) => i !== length - 2);
//     }
//     return state;
//   case ALTER_LAST_SELECTION_STACK_ELEMENT:
//     return state.map((elem, i) =>
//       i === state.length - 1 && elem.type === action.assetType
//         ? { asset: action.asset, type: action.assetType }
//         : elem,
//     );
//   default: {
//     return state;
//   }
// }
// }

export default combineReducers({
  snackbar,
  currentUser,
  selectedTrends,
  selectedDatasets,
  setRefetchDatasets,
  setRefetchTrends,
  currentlyViewingDataset,
  setCachedDatasets,
  dashboardItems,
  // selectionStack,
  // ...reducers,
});
