import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import graphqlFunctional from '../../utils/graphqlFunctional';
import GET_TWITTER_STATS_BY_DATASET_ID_INTERVAL from '../graphql/queries/twitterStatsByDatasetIdAndInterval.graphql';
import './AutoSuggest.module.css';

class AutoSuggestDatasets extends Component {
  static propTypes = {
    // updateStats: PropTypes.func.isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        name: PropTypes.string,
        details: PropTypes.string,
        date: PropTypes.string,
        // location: PropTypes.string,
      }),
    ),
    // filter: PropTypes.bool,
    refetchDatasets: PropTypes.func.isRequired,
    loadingDatasets: PropTypes.bool,
    getStatsByDatasetIdFunctional: PropTypes.func.isRequired,
    setCachedDatasets: PropTypes.func.isRequired,
    cachedDatasets: PropTypes.array,
    // todo: create redux thing for cached datasets
    cachedDatasets: PropTypes.array,
  };

  constructor() {
    super();
    this.state = {
      value: [],
      cachedDatasets: [],
    };
  }

  getOptions = () => {
    let optinos = [];
    this.props.datasets?.forEach((dataset, index) => {
      // const stats = await this.props.getStatsByDatasetIdFunctional(dataset.id, dataset.name);
      optinos.push({
        key: index,
        text: dataset.name,
        value: dataset.id,
      });
    });
    // // console.log(optinos);
    return optinos;
  };

  handleChange = async (e, { value }) => {
    const currentDashboardObject = this.props.dashboardItems.find(
      item => item.id === this.props.dashboardItemId,
    );
    let cachedDatasets = [];
    // value is an array of dataset ids
    for await (const id of value) {
      const currentDataset = await this.props.datasets?.find(dataset => dataset.id === id);
      // functional query:
      const statItem = await graphqlFunctional.query({
        query: GET_TWITTER_STATS_BY_DATASET_ID_INTERVAL,
        variables: {
          datasetId: currentDataset.id,
          range: currentDashboardObject.range,
          interval: currentDashboardObject.interval,
        },
      });
      console.log('STAT ITEM', statItem);
      cachedDatasets.push({
        dashboardItemId: this.props.dashboardItemId,
        datasetId: currentDataset.id,
        datasetName: currentDataset.name,
        datasetStats: statItem.data.twitterStatsByDatasetIdAndInterval,
      });
    }
    this.setState({ value });
    await this.props.setCachedDatasets(cachedDatasets);
  };

  // handle time range change:
  componentDidUpdate = () => {
    if (this.props.refetchDashboardItemsOnRangeOrIntervalChange) {
      this.handleChange(null, this.state);
      this.props.cancelRefetchOnRangeOrIntervalChange();
    }
    console.log('refetch::', this.props.refetchDashboardItemsOnRangeOrIntervalChange);
  };

  render() {
    const { value } = this.state;
    // console.log('autoSuggest Datasets props:', this.props);
    // console.log('autoSuggest Datasets state:', this.state);

    return (
      <div>
        <Dropdown
          placeholder="Select a dataset"
          compact
          search
          selection
          clearable
          fluid
          multiple
          closeOnChange
          options={this.getOptions()}
          onChange={this.handleChange}
          value={value}
        />
      </div>
    );
  }
}

export default AutoSuggestDatasets;
